import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Testemonials from "../../../../../components/layouts/testimonials/Testemonials";
import TabBtnList from "../../../../../components/layouts/tabbuttons/DashboardBtnList";
import TabHandlerTextwithImage from "../../../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  cttreatment,
  imghy1,
  pamenucard6,
  LinksBanTreatmentCt,
  tabhandctc1,
  tabhandctc2,
  tabhandctc3,
  tabhandctc4,
} from "../../../../../data";
import HeadingWithBottomBar from "../../../../../components/reuseablecomponents/HeadingWithBottomBar";
import useMediaQuery from "../../../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Toggleform from "../../../../../components/layouts/toggleform/toggleform";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../../../utils/functions";

const RheumatoidArthritis = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);

  const stories = filterComponentData(component, "our_stories", lang);
  const enquiry = filterComponentData(
    component,
    "cupping_therapy_ct_make_an_enquiry_",
    lang
  );
  const therapy = filterComponentData(component, "rheumatoid_arthritis", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );
  const { t } = useTranslation("common");

  const LinksBanTreatmentCt = [
    {
      subLinkName: banners[56]?.subtitle?.split(">")[0]
        ? banners[56]?.subtitle?.split(">")[0]
        : t("kneepain.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[56]?.subtitle?.split(">")[1]
        ? banners[56]?.subtitle?.split(">")[1]
        : t("kneepain.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[56]?.subtitle?.split(">")[2]
        ? banners[56]?.subtitle?.split(">")[2]
        : t("kneepain.banner.title1"),
      subDash: ">",
      subLink: "/pain-management",
    },
    {
      subLinkName: banners[56]?.subtitle?.split(">")[3]
        ? banners[56]?.subtitle?.split(">")[3]
        : "Rheumatoid arthritis",
      subDash: "",
      subLink: "/rheumatoid-arthritis",
    },
  ];

  const isDesktoplg = useMediaQuery("(min-width: 992px)");

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.painRheumotoid ? item?.painRheumotoid : "[]")
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.painRheumotoid
            ? specialityMetaTitles?.painRheumotoid
            : "Rheumatoid arthritis"}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.painRheumotoid
              ? specialityMetaDesc?.painRheumotoid
              : "Rheumatoid arthritis"
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.painRheumotoid
              ? specialityMetaKeywords?.painRheumotoid
              : "Rheumatoid arthritis"
          }
        />
      </Helmet>
      <div className="">
        <BannerWithText
          heading={
            banners[56]?.heading ? banners[56]?.heading : "Rheumatoid arthritis"
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[56]?.image ? banners[56]?.image : banner
          })`}
          imgSrc={banners[56]?.image ? banners[56]?.image : banner}
          LinksBan={LinksBanTreatmentCt}
          height={isDesktoplg ? "300px" : "330px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={therapy?.image ? therapy?.image : pamenucard6}
          alt={
            specialityAltTags?.painRheumotoid
              ? specialityAltTags?.painRheumotoid
              : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={
            therapy?.name ? therapy?.name : "What is Rheumatoid arthritis"
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.desc ? (
                  <div dangerouslySetInnerHTML={{ __html: therapy?.desc }} />
                ) : (
                  <>
                    <p class="text-justify">
                      Rheumatoid arthritis is an autoimmune and inflammatory
                      disease, which means that your immune system attacks
                      healthy cells in your body by mistake, causing
                      inflammation (painful swelling) in the affected parts of
                      the body. RA mainly attacks the joints, usually many
                      joints at once.
                    </p>
                    <h2
                      style={{
                        textTransform: "capitalize",
                        color: "#333333",
                      }}
                      className="gray-headin"
                    >
                      Stem Cell Therapy For Rheumatoid arthritis
                    </h2>

                    <hr
                      style={{
                        borderBottom: "3px solid #AF5689",
                        margin: "10px 0px 15px 0px",
                        width: "80px",
                      }}
                    ></hr>
                    <p class="text-justify">
                      As a chronic autoimmune inflammatory disorder, Rheumatoid
                      Arthritis may be helped with mesenchymal stem cell
                      treatment. MSCs have been reported as an interesting
                      therapeutic cell candidate for the treatment of autoimmune
                      diseases such as Rheumatoid Arthritis (RA). Stem cells can
                      be administered intravenously to find and repair damaged
                      tissue throughout the body. When used in regards to
                      Rheumatoid Arthritis patients, stem cells may be able to
                      repair damaged tissue to reduce pain and increase
                      mobility. This effect can be attributed to the
                      anti-inflammatory properties of mesenchymal stem cells.
                    </p>
                  </>
                )}
                {/* <br/><br/>
            {t("cttherapy.textrev.discriptiontxt2")} */}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default RheumatoidArthritis;
