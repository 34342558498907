import React from "react";
import { Modal } from "react-bootstrap";
import { updateMedicine } from "../../../../store/actions/pharmacyActions";
import { useDispatch } from "react-redux";
// import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import DashboardBtnList from "../../../reuseablecomponents/DashboardBtnList";
import { useTranslation } from "react-i18next";
// import { rescheduleDoctorAppointment } from "../../../../Store/Actions/docotorActions";

const ConfirmAlertModal = (props) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const deleteHandler = () => {
    const apiData = {
      id: props?.data?.id,
      status: { id: 2, name: "inactive" },
    };
    dispatch(updateMedicine(apiData));
    props.onHide();
  };

  // {t("dashboards.alldashboardkeys.tablekeys.newpassword")}
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="dashboardBtnList-item-active">
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-white">
              {t("dashboards.alldashboardkeys.tablekeys.deletemedicine")}
            </span>
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true" className="text-white">
              ×
            </span>
            <span class="sr-only">
              {t("dashboards.alldashboardkeys.tablekeys.close")}
            </span>
          </button>
        </Modal.Header>

        <Modal.Body>
          <p>
            {t(
              "dashboards.alldashboardkeys.tablekeys.areyousurewanttodeletemed"
            )}
          </p>
          <>
            <div className="row">
              <div className="col pr-0">
                <DashboardBtnList
                  label={t("dashboards.alldashboardkeys.tablekeys.yes")}
                  className="btn btn-danger text-white"
                  onClick={deleteHandler}
                />
              </div>
              <div className="col">
                <DashboardBtnList
                  label={t("dashboards.alldashboardkeys.tablekeys.no")}
                  className="btn btn-success text-white"
                  onClick={props.onHide}
                />
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmAlertModal;
