import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  AssignOrderAction,
  pharmacyOrderDispatch,
  ViewDeliveryStaffAction,
} from "../../../../store/actions/pharmacyActions";
export default function AssignDeliveryBoy(props) {
  const assignOrderLoader = useSelector(
    (state) => state?.pharmacy?.assignLoader
  );
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  let currentOrder = props?.data;
  const [selectedDS, setSelectedDS] = useState("");
  const [time, setTime] = useState("");
  useEffect(() => {
    dispatch(ViewDeliveryStaffAction());
  }, [dispatch]);
  const deliveryStaff = useSelector((state) => state?.pharmacy?.deliveryStaff);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const apiData = {
      order_id: currentOrder?.id,
      user_id: selectedDS[0]?.id,
      assigned_time: moment(time).unix(),
    };
    if (selectedDS === "" && time === "") {
      toast.error(
        t("dashboards.alldashboardkeys.tablekeys.enterdetails"),
        "Error"
      );
      return;
    } else {
      let res = await dispatch(AssignOrderAction(apiData));

      if (res) {
        dispatch(
          pharmacyOrderDispatch({
            order_id: currentOrder?.id,
            delivery_status: { id: 3, name: "dispatched" },
          })
        );
        toast.success(
          t("dashboards.alldashboardkeys.tablekeys.successfullyassigned"),
          "Success"
        );

        props.handleClose();
        props.history.push("/pharmacy-dashboard");
      } else {
      }
    }
  };
  // {t("dashboards.alldashboardkeys.tablekeys.no")}
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="dashboardBtnList-item-active">
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-white">
              {t("dashboards.alldashboardkeys.tablekeys.assigndeliveryboy")}
            </span>
          </Modal.Title>
          <button type="button" class="close" onClick={props?.handleClose}>
            <span aria-hidden="true" className="text-white">
              ×
            </span>
            <span class="sr-only">
              {t("dashboards.alldashboardkeys.tablekeys.close")}
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-12">
                  <span className="label-name-login">
                    {t(
                      "dashboards.alldashboardkeys.tablekeys.selectdeliveryboy"
                    )}
                  </span>
                  <Form.Group>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      onChange={(selected) => {
                        setSelectedDS(selected);
                      }}
                      options={deliveryStaff}
                      placeholder=""
                      selected={selectedDS}
                    />
                  </Form.Group>
                </div>
              </div>
              <div
                className="row mb-3"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-12">
                  <span className="label-name-login">
                    {t("dashboards.alldashboardkeys.tablekeys.entertime")}
                  </span>
                  <Form.Group>
                    <input
                      className="w-100 input-login-modal rbt-input"
                      type="datetime-local"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>

              <input
                type="submit"
                value={t("dashboards.alldashboardkeys.tablekeys.dispatch")}
                className="btnSquare btn-block mx-auto"
                style={{ width: "85%", textAlign: "center" }}
              />
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
