import React, { useEffect, useState } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDepartment,
  getDoctorByDepartment,
} from "../../../../store/actions/patientActions";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const DoctorBoardModal = (props) => {
  const {t} = useTranslation("common");
  const departments = useSelector((state) => state?.patient?.departments);
  const doctors = useSelector((state) => state?.patient?.doctorsByDepartment);
  const [doctor, setDoctor] = useState([]);
  const [singleSelections, setSingleSelections] = useState([]);
  const dispatch = useDispatch();
  const loader = useSelector((state) => state?.logIn?.loader);

  useEffect(() => {
    if (departments === undefined || departments?.length < 1) {
      dispatch(getAllDepartment());
    }
  }, [dispatch, departments]);

  const changeHanler = (selected) => {
    if (selected.length > 0) {
      const apiData = {
        department_id: selected[0]?.department_id,
      };
      dispatch(getDoctorByDepartment(apiData));
    }
  };
  // {t("dashboards.alldashboardkeys.tablekeys.appointmentreschedule")}
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
          {t("dashboards.alldashboardkeys.tablekeys.adddocboard")}
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true">×</span>
            <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12">
            <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.department")}</span>
            <Form.Group>
              <Typeahead
                id="basic-typeahead-single"
                labelKey="name"
                onChange={(selected) => {
                  setSingleSelections(selected);
                  changeHanler(selected);
                }}
                options={departments}
                placeholder=""
                // selected={singleSelections}
              />
            </Form.Group>
          </div>
          <div className="col-lg-12">
            <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.doctors")}</span>

            <Form.Group>
              {loader ? (
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <Loader
                        height={20}
                        width={20}
                        type="Oval"
                        color="#1a4774"
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control type="text" disabled />
                </InputGroup>
              ) : (
                <Typeahead
                  id="basic-typeahead-single"
                  className="mb-3"
                  labelKey="name"
                  onChange={(selected) => {
                    setDoctor(selected);
                  }}
                  options={doctors}
                  placeholder=""
                  // selected={doctor}
                />
              )}
            </Form.Group>
          </div>
          <div
            className="btnSquare btn-block mx-auto"
            style={{ width: "85%", textAlign: "center" }}
            onClick={() => {
              props.setdoctorList(doctor);
              props.onHide();
            }}
          >
            {t("dashboards.alldashboardkeys.tablekeys.adddoc")}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DoctorBoardModal;
