import React from "react";
import { Link } from "react-router-dom";
import './DashboardBtnList.css'
const DashboardBtnListNot = ({
  label,
  className,
  bntStyle,
  labelStyle,
  onClick,
  iconclassname,
}) => {
  return (
    <div
      className={`dashboardBtnList-item ${className}  btn `}
      style={ bntStyle }
      onClick={onClick}
    >
      <i className={`${iconclassname}`} style={{color:'#565656'}}></i>
      <div style={{ labelStyle }} >&nbsp;{label}</div>
     </div>
    
  );
};

export default DashboardBtnListNot;
