import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../statics/icons/Toticell_logo.png";
import { forgotPassword } from "../../../store/actions/authActions";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
const ForgotPass = (props) => {
  const loader = useSelector((state) => state?.logIn?.authLoader);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const apiData = {
      email_address: data.email,
    };
    dispatch(forgotPassword(apiData));
  
  };
  return (
    <div className="container-fluid loginContainer ">
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-6  login-left-banner d-none d-md-flex  ">
          <img className="mx-auto" src={logo} alt="Toticell Logo" width={250} height={250} />
          <p>
            TotiCell, the world class regenerative healthcare center that is
            strategically located in the heart of Dhaka Metropolitan Mega city
            and approximately 5 minutes south of National Parliament House and
            only 50 minutes south of the Hazrat Shahjalal International Airport
            (HSIA). The board of directors and the scientific board behind
            TotiCell is committed to offer best practice of health care service
            in Bangladesh
          </p>
        </div>
        <div className="col-lg-8 col-md-6 col-12  center-children-class px-5 pb-3">
          <div className="container  center-children-class">
            <div className="col-lg-8 ">
              <h2 className="blue-heading">Welcome Back</h2>
              <strong>Enter Your Email Address Below And We'll get you back on track</strong>
              <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label for="firstName">EMAIL ADDRESS</label>
                  <input
                    className="dashboards-input"
                    id="email"
                    type="email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "this field is required field",
                      },
                    })}
                  />
                  {errors?.email?.message ? (
                    <div className="text-error">{errors?.email?.message}</div>
                  ) : (
                    ""
                  )}
                </div>
               
                <div className="mt-3 d-flex justify-content-between">
                  <p>
                   
                    <strong >
                      <Link className="blue-heading pointer" style={{textDecoration:'none'}} to="/login">Back to SignIn</Link>
                    </strong>
                  </p>
                  <button type="submit" className="loginBtn" disabled={loader}>
                    {loader ? (
                      <Loader
                        height={20}
                        width={20}
                        type="ThreeDots"
                        color="#fff"
                      />
                    ) : (
                      "Forget Password"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPass;
