import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { BASEURL } from "../../../../services/HttpProvider";
import { useDispatch, useSelector } from "react-redux";
import { myMedicalRecords } from "../../../../store/actions/patientActions";
import { useTranslation } from "react-i18next";

const UploadReportModal = (props) => {
  const dispatch = useDispatch()


  const { t } = useTranslation("common")
  const [file, setFile] = useState();
  const [loading, setloading] = useState(false);
  const user = useSelector((state) => state?.logIn?.user);

  const uploadFile = async (event) => {
    event.preventDefault();

    if (!loading) {
      setloading(true);
      let formdata = new FormData();
      const authToken = JSON.parse(localStorage.getItem("toticelltoken"));
      if (file === undefined || file === null) {
        setloading(false);

        toast.error(t("toastmessages.pleaseuploadreport"));
        return;
      } else {
        formdata.append("file", file[0]);
        formdata.append("id", user?.id);
        const endPoint =
          props?.viewData === "Prescription" ? "prescription" : "report";
        let res = await axios.post(
          `${BASEURL}upload/medical-record/${endPoint} `,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "x-session-key": authToken.key,
              "x-session-type": authToken.type,
            },
          }
        );
        if (res?.data?.response_code === 200) {
          toast.success(res?.data?.response_message);

          setloading(false);
          dispatch(myMedicalRecords({ id: user?.id }))
          // props?.history?.push("/lab-administrator");
          props?.onHide();
        } else {
          setloading(false);
          toast.error(res?.data?.response_message);
        }
      }
    }
  };
  // {t("dashboards.alldashboardkeys.tablekeys.cancel")}

  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="dashboardBtnList-item-active">
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-white"> {`${t("dashboards.alldashboardkeys.tablekeys.upload")} ${props?.viewData}`}</span>
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true" className="text-white">
              ×
            </span>
            <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            className="form-control"
            name="upload_file"
            onChange={(e) => {
              setFile(e.target.files);
            }}
          />
          <div className="w-100 d-flex justify-content-center">
            <div
              className="btn btn-success mt-3"
              onClick={(e) => {
                uploadFile(e);
              }}
            >
              {loading ? (
                <Loader height={20} width={20} type="ThreeDots" color="#fff" />
              ) : (
                t("dashboards.alldashboardkeys.tablekeys.upload")

              )}
            </div>
            <div
              className="btn btn-danger mt-3 ml-3"
              onClick={() => {
                props?.onHide();
              }}
            >
              {t("dashboards.alldashboardkeys.tablekeys.cancel")}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadReportModal;
