/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, FormLabel, InputGroup, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { acceptAppointmentAction, ResetSlots } from "../../../../store/actions/administratorActions";
import { getSlotByDateAndDoctor } from "../../../../store/actions/patientActions";
import moment from 'moment'
const SlotsModal = (props) => {
  const { t } = useTranslation("common")
  const dispatch = useDispatch();
  const [date, setDate] = useState();

  let slots = useSelector((state) => state?.patient?.slotsByDate);

  const [slotId, setslotId] = useState();
  const new_date = new Date()
  const [selectedSolt, setselectedSolt] = useState();

  const acceptReschedule = () => {
    if (date === undefined && selectedSolt === undefined) {
      toast.error(t("dashboards.alldashboardkeys.tablekeys.entervaliddetails"));
      props.onHide();

    } else {
      let apiData = {
        id: props?.data1,
        slot_id: slotId,
        date: date,
        status: { id: 2, name: "approved" },

      };
      let res = dispatch(acceptAppointmentAction(apiData));
      if (res) {
        setslotId("");
        setselectedSolt("");
        setDate("");
        dispatch(ResetSlots([]));
        props.onHide();
      } else {
        toast.error(t("dashboards.alldashboardkeys.tablekeys.notaccepted"));
      }
    }
  };

  useEffect(() => {
    let apiData = {
      id: props.data,

      date: date,
      timestamp: moment(new_date)
        .add(moment(new_date).utcOffset(), 'minutes')
        .utc()
        .unix(),
    };
    props?.show ? dispatch(getSlotByDateAndDoctor(apiData)) : null;
  }, [date]);

  useEffect(() => {
    getSlotId();
  }, [selectedSolt]);

  const getSlotId = () => {
    slots?.map((item) =>
      item?.duration === selectedSolt ? setslotId(item?.slot_id) : ""
    );
  };
  // {t("dashboards.alldashboardkeys.tablekeys.no")}

  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="dashboardBtnList-item-active">
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-white">{t("dashboards.alldashboardkeys.tablekeys.rescheduleappointment")}</span>
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true" className="text-white">
              ×
            </span>
            <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>
          </button>
        </Modal.Header>

        <Modal.Body>
          <input
            type="date"
            onChange={(e) => {
              setDate(e.target.value);
            }}
            className="form-control"
          />

          <FormLabel>{t("dashboards.alldashboardkeys.tablekeys.availableslots")}</FormLabel>

          <Form.Control
            as="select"
            className="input-login-modal"
            defaultValue={selectedSolt}
            onChange={(val) => setselectedSolt(val.target.value)}
          >
            <option disabled selected>Select Slot</option>
            {slots?.map((item, index) => (
              <option value={item?.duration} key={index + 1}>
                {item?.duration}
              </option>
            ))}
          </Form.Control>

          <input
            type="submit"
            value={t("dashboards.alldashboardkeys.tablekeys.submit")}
            className="btnSquare btn-block mx-auto"
            onClick={acceptReschedule}
            style={{ width: "85%", textAlign: "center" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SlotsModal;
