import React from "react";
import { Link } from "react-router-dom";
import "./LatestPostCard.css";
const LatestPostCard = (props) => {
  return (
    <div class="col-lg-4 col-md-6 mb-4">
      <Link to={props?.cardlink} class="bottom-post">
        <div class="post-thumbnail">
          <img
            src={props.img}
            alt={props.alt ? props.alt : props.cardHeading}
          />
        </div>
        <div class="caption">
          <h6 style={{ color: "#333", fontSize: "18px" }}>{props.heading}</h6>
          <p className="text-justify">{props.description}</p>
          <div class="rightside">
            <div class="span-fa">
              <p>
                <i class="fa fa-heart" aria-hidden="true">
                  &nbsp; {props.likes}
                </i>
              </p>
            </div>
            <div class="span-fa">
              <p>
                <i class="fa fa-comment" aria-hidden="true">
                  &nbsp; {props.msg}
                </i>
              </p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default LatestPostCard;
