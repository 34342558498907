import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import { getMyPatient } from "../../store/actions/docotorActions";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import { useTranslation } from "react-i18next";
// const data = [
//   { name: "test", id: 1, test: "DATA" },
//   { name: "test", id: 1, test: "YAHA DATA H" },
//   { name: "test", id: 1, test: "YAHA DATA H" },
//   { name: "test", id: 1, test: "YAHA DATA H" },
// ];
const DoctorMyPatient = (props) => {
  const { t } = useTranslation("common")
  const dispatch = useDispatch();
  const loader = useSelector((state) => state?.doctorReducer?.doctorLoading);
  const user = useSelector((state) => state?.logIn?.user);
  const myPatient = useSelector((state) => state?.doctorReducer?.myPatient);
  useEffect(() => {
    const apiData = {
      id: user?.id,
    };
    dispatch(getMyPatient(apiData));

    // eslint-disable-next-line
  }, []);

  const handleView = (value, item) => {
    if (value === t("dashboards.alldashboardkeys.tablekeys.viewbtn")) {
      props.history.push("/patient-details", item);
    } else if (value == "Record") {
      props.history.push('/patient-medical-records', item?.id)
    } else { }
  };

  return (
    <div className="w-100">
      <div className="p-3">
        <span className="dashboardHeadingText">{t("dashboards.alldashboardkeys.tablekeys.mypatient")}</span>
      </div>
      {loader ? (
        <LoderContainer />
      ) : (
        <BootstrapCustomTable
          columns={[
            { dataField: "name", text: t("dashboards.alldashboardkeys.tablekeys.name") },
            { dataField: "phone_number", text: t("dashboards.alldashboardkeys.tablekeys.phone") },
            { dataField: "email_address", text: t("dashboards.alldashboardkeys.tablekeys.email") },
            {
              formatter: (cell, row) => {
                return (
                  <>
                    <ActionBtn
                      options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn"), "Record"]}
                      handleChange={handleView}
                      data={row}
                    />

                  </>
                );
              },
              text: t("dashboards.alldashboardkeys.tablekeys.action"),
            },
          ]}
          tabledata={myPatient?.length > 0 ? myPatient : []}
        />
      )}
    </div>
  );
};

export default DoctorMyPatient;
