import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import DashboardBtn from "../../components/reuseablecomponents/DashboardBtn";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import { generatePayment, myPayment } from "../../store/actions/patientActions";
import {
  capitalize,
  checkPaidOrUnpaid,
  comaSeperated,
} from "../../utils/functions";
const PatientPayments = () => {
  const { t } = useTranslation("common")
  const [selectedTab, setselectedTab] = useState(t("dashboards.alldashboardkeys.tablekeys.pending"));
  const loader = useSelector((state) => state?.patient?.patientLoader);
  const tableLoader = useSelector((state) => state?.logIn?.authLoader);

  const payments = useSelector((state) => state?.patient?.payments);
  const user = useSelector((state) => state?.logIn?.user);
  const [tableData, settableData] = useState([]);
  const dispatch = useDispatch();
  const tableDataHandler = useCallback(
    async () => {
      const test = await dispatch(myPayment({ id: user?.id }));
      const filterData = test?.filter((item) => {
        return item?.status?.name === "unpaid" || item?.status?.name === "partial";
      });
      await settableData(filterData);
    },
    // eslint-disable-next-line
    []
  );
  useEffect(() => {
    tableDataHandler();
    // eslint-disable-next-line
  }, [dispatch]);
  const tabHandler = (item) => {
    setselectedTab(item);
    if (item === t("dashboards.alldashboardkeys.tablekeys.pending")) {
      const filterData = payments?.filter((item) => {
        return item?.status?.name === "unpaid" || item?.status?.name === "partial";
      });
      settableData(filterData);
    } else {
      const filterData = payments?.filter((item) => {
        return item?.status?.name === "paid";
      });
      settableData(filterData);
    }
  };
  const paymentHandler = async (data) => {
    let apiData = {
      id:
        data?.type === "appointment" ? data?.appointment?.id : data?.order?.id,
      type: data?.type,
      partial_payment: false

    };
    const res = await dispatch(generatePayment(apiData))
    if (res == false) {
      return;
    }
    dispatch(myPayment({ id: user?.id }))
    window.open(res);
    window.focus();
  };
  const handleChange = (value, data) => {
    if (value === "Pay") {
      data?.status?.name === 'paid' ? toast.error("Payment Is Already Paid", "Error") : paymentHandler(data)
    } else if (value == 'Partial Pay') {
      data?.status?.name === 'paid' ? toast.error("Payment Is Already Paid", "Error") : partialPaymentHandler(data)
    } else {
      data?.receipt_url == "" ? toast.error("Receipt is not available right now", "Error") : window.open(data?.receipt_url, "_blank")
    }
  }
  const partialPaymentHandler = async (data) => {
    if (data?.type === "appointment") {
      toast.error("Partial payment for appontment is not allowed", "Error")
      return;
    }
    let apiData = {
      id:
        data?.type === "appointment" ? data?.appointment?.id : data?.order?.id,
      type: data?.type,
      partial_payment: true
    };
    const res = await dispatch(generatePayment(apiData))
    if (res == false) {
      return;
    }
    window.open(res);
    window.focus();
    dispatch(myPayment({ id: user?.id }))
  };
  // t("dashboards.alldashboardkeys.tablekeys.successful")
  return (
    <>
      <div className="w-100">
        <div className="p-3">
          <span className="dashboardHeadingText">{t("dashboards.alldashboardkeys.tablekeys.payments")}</span>
        </div>
        <div className="row px-3 mb-3">
          {[t("dashboards.alldashboardkeys.tablekeys.pending"), t("dashboards.alldashboardkeys.tablekeys.successful")]?.map((item, index) => (
            <div className="col-lg-3 col-md-4 col-12 mt-2" key={index + 1}>
              <DashboardBtn
                label={item}
                active={selectedTab === item}
                onClick={tabHandler}
              />
            </div>
          ))}
        </div>
        {tableLoader ? (
          <LoderContainer />
        ) : (
          <>
            <BootstrapCustomTable
              columns={[
                {
                  formatter: (cell, row, index) => {
                    return <span>{capitalize(row?.type)}</span>;
                  },
                  dataField: "type",
                  text: t("dashboards.alldashboardkeys.tablekeys.type"),
                },
                {
                  formatter: (cell, row, index) => {
                    return <span>{row?.order?.type}</span>;
                  },
                  dataField: "order.type",
                  text: "Order Type",
                },
                {
                  formatter: (cell, row, index) => {
                    return <span>{comaSeperated(row?.tax)}</span>;
                  },
                  dataField: "tax",
                  text: t("dashboards.alldashboardkeys.tablekeys.tax"),
                },
                {
                  formatter: (cell, row, index) => {
                    return <span>{comaSeperated(row?.total_amount)}</span>;
                  },
                  dataField: "total_amount",
                  text: t("dashboards.alldashboardkeys.tablekeys.ammount"),
                },
                {
                  formatter: (cell, row, index) => {
                    return <span>{comaSeperated(row?.remaining_amount)}</span>;
                  },
                  dataField: "remaining_amount",
                  text: "Remaining Amount",
                },
                {
                  formatter: (cell, row, index) => {
                    return <span>{comaSeperated(row?.installments_amount)}</span>;
                  },
                  dataField: "installments_amount",
                  text: "Installment Amount",
                },
                {
                  formatter: (cell, row, index) => {
                    return <span>{comaSeperated(row?.paid_amount)}</span>;
                  },
                  dataField: "paid_amount",
                  text: "Paid Amount",
                },
                {
                  formatter: (cell, row, index) => {
                    return (
                      <span
                        style={{ color: checkPaidOrUnpaid(row?.status.name) }}
                      >
                        {row?.status.name?.toUpperCase()}
                      </span>
                    );
                  },
                  dataField: "status.name",
                  text: t("dashboards.alldashboardkeys.tablekeys.paymentstatus"),
                },
                {
                  formatter: (cell, row) => {
                    return (
                      <div className="row">
                        <div className="col">
                          {
                            row?.status?.id == 1 && row?.order?.type == "package" ?
                              <ActionBtn
                                options={[
                                  t("dashboards.alldashboardkeys.tablekeys.paybtn"),
                                  // t("dashboards.alldashboardkeys.tablekeys.receipt"),
                                ]}
                                handleChange={handleChange}
                                data={row}
                              /> : row?.status?.id == 1 && row?.order?.type != "package" ?
                                <ActionBtn
                                  options={[
                                    t("dashboards.alldashboardkeys.tablekeys.paybtn"),
                                    // t("dashboards.alldashboardkeys.tablekeys.receipt"),
                                    // t("dashboards.alldashboardkeys.tablekeys.partiallypay"),
                                  ]}
                                  handleChange={handleChange}
                                  data={row}
                                /> : row?.status?.id == 2 ? <ActionBtn
                                  options={[
                                    t("dashboards.alldashboardkeys.tablekeys.receipt"),
                                  ]}
                                  handleChange={handleChange}
                                  data={row}
                                /> : row?.status?.id == 4 && row?.order?.type == "package" ? < ActionBtn
                                  options={[
                                    t("dashboards.alldashboardkeys.tablekeys.receipt"),
                                  ]}
                                  handleChange={handleChange}
                                  data={row}
                                /> : < ActionBtn
                                  options={[
                                    t("dashboards.alldashboardkeys.tablekeys.paybtn"),

                                    t("dashboards.alldashboardkeys.tablekeys.receipt"),
                                  ]}
                                  handleChange={handleChange}
                                  data={row}
                                />
                          }
                        </div>
                      </div>
                    );
                  },

                  text: t("dashboards.alldashboardkeys.tablekeys.action"),
                },
              ]}
              tabledata={tableData || []}
            />
          </>
        )}
      </div>
    </>
  );
};

export default PatientPayments;
