import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import {
  phoneModalToggle,
  resendPhoneOtp,
} from "../../../../store/actions/authActions";
import "react-phone-input-2/lib/style.css";

import { updatePatient } from "../../../../store/actions/patientActions";
import { toast } from "react-toastify";
const AddPhoneNumber = (props) => {
  const { t } = useTranslation("common");
  const [phoneNumber, setPhoneNumber] = useState("");
  const user = useSelector((state) => state?.logIn?.user);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const apiData = {
      id: user?.id,
      phone_number: `+${phoneNumber}`,
    };
    let getResponse;
    if (phoneNumber?.length >= 12 && phoneNumber?.length <= 15) {
      getResponse = await dispatch(updatePatient(apiData));
    } else {
      toast.error("Phone Number is Invalid");
    }
    if (getResponse) {
      dispatch(
        resendPhoneOtp({ id: user?.id, phone_number: user?.phone_number })
      );
      dispatch(phoneModalToggle(true));
    } else {
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Add Phone Number
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true" className="text-white">
              ×
            </span>
            <span class="sr-only">
              {t("dashboards.alldashboardkeys.tablekeys.close")}
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
              <div class="row mt-3">
                <div className="col-12">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>
                        {t(
                          "dashboards.alldashboardkeys.tablekeys.contactnumber"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    <>
                      <PhoneInput
                        country={"bd"}
                        value={phoneNumber}
                        onChange={(phone) => setPhoneNumber(phone)}
                      />
                    </>
                  </div>
                </div>
              </div>

              <input
                type="submit"
                value="Add Phone Number"
                className="btnSquare btn-block mx-auto mt-2"
                style={{ width: "85%", textAlign: "center" }}
              />
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddPhoneNumber;
