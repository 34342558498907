import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import moment from "moment";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import { useTranslation } from "react-i18next";
import RescheduleModal from "../../components/layouts/modal/rescheduleModal/RescheduleModal";
import RefundModal from "../../components/layouts/modal/refundAmountModal/RefundModal";
import { cancelAppointment, patientAppointments } from "../../store/actions/patientActions";
import AlertRefund from "../../components/layouts/modal/AlertRefund/AlertRefund";

import { toast } from "react-toastify";
import { comaSeperated } from "../../utils/functions";
const PatientTodayAppointment = (props) => {
  const { t } = useTranslation("common")
  const user = useSelector((state) => state?.logIn?.user);
  const [showModal, setShowModal] = useState(false)
  const date = new Date()
  const time = date.getTime()
  const dispatch = useDispatch();
  const [show, setShow] = useState(false)
  const new_date = moment(new Date()).format('YYYY-MM-DD HH:mm');

  const [id, setID] = useState("")
  const appointments = useSelector(
    (state) => state?.patient?.appointments?.today
  );
  const [rescheduleModal, setrescheduleModal] = useState({
    show: false,
    data: {},
  });
  const handleChange = async (value, row) => {
    if (value == t("dashboards.alldashboardkeys.tablekeys.viewbtn")) {
      let item = { ...row, from: "TodayAppointment" };
      props?.history?.push("/patient-dashboard-appointment-details", { row, from: "TodayAppointment" });
    } else if (value == "Cancel") {
      let apiData = {
        appointment_id: row?.id, timestamp: new_date
      }
      let res = await dispatch(cancelAppointment(apiData))
      dispatch(patientAppointments({ id: user?.id }));

    } else if (value == "Cancel/Refund") {

      setID(row?.id)
      setShow(!show)
    }
    else {
      setrescheduleModal({
        open: true,
        data: row,
      });

    }
  };
  return (
    <>
      <div className="w-100">
        <div className="p-3">
          <span className="dashboardHeadingText">{t("dashboards.alldashboardkeys.tablekeys.todayappointment")}</span>
        </div>
        <BootstrapCustomTable
          columns={[
            {
              formatter: (cell, row, index) => {
                return <span>{index + 1}</span>;
              },
              text: "Sr.",
            },
            {
              formatter: (cell, row) => {
                return <span>{moment(row.date).format("DD/MM/YYYY")}</span>;
              },
              text: t("dashboards.alldashboardkeys.tablekeys.date"),
            },
            { dataField: "doctor_details.name", text: t("dashboards.alldashboardkeys.tablekeys.doctorname") },
            { dataField: "condition.name", text: "Condition" },
            {
              formatter: (cell, row) => {
                return <span>{`${row?.is_refunded ? comaSeperated(row?.refunded_amount) : '0'}`}</span>;
              },
              text: "Refund Amount",
            },
            {
              formatter: (cell, row) => {
                return <span style={{ color: row?.status?.name == "booked" ? 'red' : row?.status?.name == "confirmed" ? 'green' : '#af5689' }}>{row?.status?.name?.toUpperCase()}</span>;
              },
              text: "Status",
            },

            {
              formatter: (cell, row, index) => {
                return row?.status?.name == "booked" ? (
                  <ActionBtn
                    options={[
                      t("dashboards.alldashboardkeys.tablekeys.viewbtn"),
                      "Reschedule",
                      "Cancel",
                    ]}
                    handleChange={handleChange}
                    data={row}
                  />
                ) : row?.status?.name == "confirmed" &&
                  row?.consultation_type?.name == "online" ? (
                  <ActionBtn
                    options={[
                      t("dashboards.alldashboardkeys.tablekeys.viewbtn"),
                      "Reschedule",
                      "Cancel",
                    ]}
                    handleChange={handleChange}
                    data={row}
                  />
                ) : row?.status?.name == "confirmed" &&
                  row?.consultation_type?.name == "walk-in" ? (
                  <ActionBtn
                    options={[
                      t("dashboards.alldashboardkeys.tablekeys.viewbtn"),
                      "Cancel",
                      "Reschedule"
                    ]}
                    handleChange={handleChange}
                    data={row}
                  />
                ) : row?.status?.name == "completed" ? (
                  <ActionBtn
                    options={[
                      t("dashboards.alldashboardkeys.tablekeys.viewbtn"),
                    ]}
                    handleChange={handleChange}
                    data={row}
                  />
                ) : (
                  <ActionBtn
                    options={[
                      t("dashboards.alldashboardkeys.tablekeys.viewbtn"),

                    ]}
                    handleChange={handleChange}
                    data={row}
                  />
                );
              },
              text: t("dashboards.alldashboardkeys.tablekeys.action"),
            },
          ]}
          tabledata={appointments ? appointments : []}
        />
      </div>
      <RescheduleModal
        show={rescheduleModal?.open}
        data={rescheduleModal?.data}
        onHide={() => setrescheduleModal({ open: false, data: {} })}
        {...props}
      />
      <RefundModal
        show={show}
        id={id}
        onHide={() => setShow(false)}
      />
      <AlertRefund
        show={showModal}
        onHide={() => setShowModal(false)}

      />
    </>
  );
};

export default PatientTodayAppointment;
