import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerWithText from "../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Testemonials from "../../../components/layouts/testimonials/Testemonials";
import TabBtnList from "../../../components/layouts/tabbuttons/DashboardBtnList";
import TabHandlerTextwithImage from "../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  imgprp1,
  tabhandprp1,
  tabhandprp2,
  tabhandprp3,
  tabhandprp4,
  tabhandprp5,
} from "../../../data";
import HeadingWithBottomBar from "../../../components/reuseablecomponents/HeadingWithBottomBar";
import Toggleform from "../../../components/layouts/toggleform/toggleform";
import useMediaQuery from "../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { filterComponentData } from "../../../utils/functions";
import { useSelector } from "react-redux";

const PRP = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const [buttonState, setButtonState] = useState([]);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);
  const stories = filterComponentData(component, "our_stories", lang);
  const enquiry = filterComponentData(
    component,
    "platelet_rich_plasma_prp_make_an_enquiry",
    lang
  );
  const therapy = filterComponentData(
    component,
    "platelet_rich_plasma_prp_therapy",
    lang
  );
  const tabs = filterComponentData(
    component,
    "platelet_rich_plasma_prp_tabs",
    lang
  );
  const tablits = filterComponentData(
    component,
    "platelet_rich_plasma_prp_tab_list",
    lang
  );
  const treatmentMetaTitles = filterComponentData(
    component,
    "treatment_meta_titles",
    lang
  );
  const treatmentSchemaMarkup = filterComponentData(
    component,
    "treatment_schema_markups",
    lang
  );
  const treatmentMetaDesc = filterComponentData(
    component,
    "treatment_meta_descriptions",
    lang
  );
  const treatmentMetaKeywords = filterComponentData(
    component,
    "treatment_meta_keywords",
    lang
  );
  const treatmentAltTags = filterComponentData(
    component,
    "treatment_image_alt_tags",
    lang
  );
  const treatmentVerification = filterComponentData(
    component,
    "treatment_verification_meta_tags",
    lang
  );
  // console.log(tabs, "therapy", tablits);

  const { t } = useTranslation("common");

  const LinksBanTreatmentPrp = [
    {
      subLinkName: banners[6]?.subtitle?.split(">")[0]
        ? banners[6]?.subtitle?.split(">")[0]
        : t("prptherapy.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[6]?.subtitle?.split(">")[1]
        ? banners[6]?.subtitle?.split(">")[1]
        : t("prptherapy.banner.title1"),
      subDash: ">",
      subLink: "/treatment",
    },
    {
      subLinkName: banners[6]?.subtitle?.split(">")[2]
        ? banners[6]?.subtitle?.split(">")[2]
        : t("prptherapy.banner.title"),
      subDash: "",
      subLink: "/prp-therapy",
    },
  ];
  const prptreatment = [
    {
      condition: {
        tabicon: "",
        img: tabhandprp1,
        heading: t("prptherapy.ourspecialitytabs.btndetails1.headfirst"),
        description: "",
        description1: (
          <p style={{ marginBottom: "6px", fontSize: "15px" }}>
            {t("prptherapy.ourspecialitytabs.btndetails1.discriptiontxt1")}
          </p>
        ),
        linkchecks: [
          {
            title: t("prptherapy.ourspecialitytabs.btndetails1.list1"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails1.list2"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails1.list3"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails1.list4"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails1.list5"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails1.list6"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails1.list7"),
          },
        ],
      },
      how_prep: {
        tabicon: "",
        img: tabhandprp2,
        heading: t("prptherapy.ourspecialitytabs.btndetails2.headfirst"),
        description: "",
        description1: (
          <p style={{ marginBottom: "6px", fontSize: "15px" }}>
            {t("prptherapy.ourspecialitytabs.btndetails2.discriptiontxt1")}
          </p>
        ),
        linkchecks: [],
      },
      adv_treate: {
        tabicon: "",
        img: tabhandprp3,
        heading: t("prptherapy.ourspecialitytabs.btndetails3.headfirst"),
        description: "",
        description1: (
          <p style={{ marginBottom: "6px", fontSize: "15px" }}>
            {t("prptherapy.ourspecialitytabs.btndetails3.discriptiontxt1")}
          </p>
        ),
        linkchecks: [
          {
            title: t("prptherapy.ourspecialitytabs.btndetails3.list1"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails3.list2"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails3.list3"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails3.list4"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails3.list5"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails3.list6"),
          },
        ],
      },
      adv_prpthrapy: {
        tabicon: "",
        img: tabhandprp4,
        heading: t("prptherapy.ourspecialitytabs.btndetails4.headfirst"),
        description: "",
        description1: (
          <p style={{ marginBottom: "6px", fontSize: "15px" }}>
            {t("prptherapy.ourspecialitytabs.btndetails4.discriptiontxt1")}
          </p>
        ),
        linkchecks: [
          {
            title: t("prptherapy.ourspecialitytabs.btndetails4.list1"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails4.list2"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails4.list3"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails4.list4"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails4.list5"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails4.list6"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails4.list7"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails4.list8"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails4.list9"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails4.list10"),
          },
          {
            title: t("prptherapy.ourspecialitytabs.btndetails4.list11"),
          },
        ],
      },
      dur: {
        tabicon: "",
        img: tabhandprp5,
        heading: t("prptherapy.ourspecialitytabs.btndetails5.headfirst"),
        description: "",
        description1: (
          <p style={{ marginBottom: "6px", fontSize: "15px" }}>
            {t("prptherapy.ourspecialitytabs.btndetails5.discriptiontxt1")}
            <br />
            {t("prptherapy.ourspecialitytabs.btndetails5.discriptiontxt2")}
          </p>
        ),
        linkchecks: [],
      },
    },
  ];

  const [selectedTab1, setSelectedTab1] = useState(
    t("prptherapy.ourspecialitytabs.btn1")
  );
  const [selecteddata, setSelecteddata] = useState(
    tabs?.length < 1 ? prptreatment[0].condition : tabs[0]
  );
  const [toggle, setToggle] = useState(false);
  const tabledataHandler = async (item) => {
    setSelecteddata(item);
  };
  const populateButtonArray = () => {
    let test = [];
    tabs?.map((item) => {
      test?.push(item?.name);
    });
    setButtonState(test);
  };
  const buttonname = [
    t("prptherapy.ourspecialitytabs.btn1"),
    t("prptherapy.ourspecialitytabs.btn2"),
    t("prptherapy.ourspecialitytabs.btn3"),
    t("prptherapy.ourspecialitytabs.btn4"),
    t("prptherapy.ourspecialitytabs.btn5"),
  ];
  const tabHandler = (item) => {
    setSelectedTab1(item);
    if (item === t("prptherapy.ourspecialitytabs.btn1")) {
      tabledataHandler(prptreatment[0].condition);
    } else if (item === t("prptherapy.ourspecialitytabs.btn2")) {
      tabledataHandler(prptreatment[0].how_prep);
    } else if (item === t("prptherapy.ourspecialitytabs.btn3")) {
      tabledataHandler(prptreatment[0].adv_treate);
    } else if (item === t("prptherapy.ourspecialitytabs.btn4")) {
      tabledataHandler(prptreatment[0].adv_prpthrapy);
    } else if (item === t("prptherapy.ourspecialitytabs.btn5")) {
      tabledataHandler(prptreatment[0].dur);
    }
  };
  const tabHandlerTest = (item) => {
    setSelectedTab1(item);
    tabs?.filter((it) =>
      it?.name?.toLowerCase() == item?.toLowerCase()
        ? setSelecteddata(it)
        : null
    );
  };

  useEffect(() => {
    populateButtonArray();
    setSelecteddata(tabs?.length < 1 ? prptreatment[0].condition : tabs[0]);
  }, [lang, tabs?.length]);

  const isDesktoplg = useMediaQuery("(min-width: 992px)");
  const verificationsTags = treatmentVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.prpName,
      content: item?.prpContent,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}
        {treatmentSchemaMarkup?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(JSON.stringify(item?.prp ? item?.prp : "[]"))}
          </script>
        ))}
        <title>
          {treatmentMetaTitles?.prp
            ? treatmentMetaTitles?.prp
            : t("prptherapy.maintitle")}
        </title>
        <meta
          name="description"
          content={
            treatmentMetaDesc?.prp
              ? treatmentMetaDesc?.prp
              : t("prptherapy.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            treatmentMetaKeywords?.prp
              ? treatmentMetaKeywords?.prp
              : t("prptherapy.maintitle")
          }
        />
      </Helmet>
      <div className="">
        <BannerWithText
          heading={
            banners[6]?.heading
              ? banners[6]?.heading
              : t("prptherapy.banner.title")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[6]?.image ? banners[6]?.image : banner
          })`}
          imgSrc={banners[6]?.image ? banners[6]?.image : banner}
          LinksBan={LinksBanTreatmentPrp}
          height={isDesktoplg ? "300px" : "400px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          alt={treatmentAltTags?.prp ? treatmentAltTags?.prp : ""}
          imgStyle={{
            borderRadius: "4px",
            width: "100%",
            height: "340px",
            objectFit: "fill",
          }}
          imgSrc={therapy?.image ? therapy?.image : imgprp1}
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={
            therapy?.name ? therapy?.name : t("prptherapy.textrev.headfirst")
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.desc
                  ? therapy?.desc
                  : t("prptherapy.textrev.discriptiontxt1")}
              </p>
            </>
          }
          reverse={false}
        />
        {tablits?.length < 1 ? (
          <div className="mb-5">
            <TabHandlerTextwithImage
              SelectedButtons={
                <div className="my-4">
                  <div className="row">
                    {buttonname.map((item, index) => (
                      <div
                        className="col-lg-2 col-md-6 px-2 mb-3 d-flex d-inline-flex"
                        key={index + 1}
                        onClick={() => tabHandler(item)}
                      >
                        <TabBtnList
                          label={
                            item === t("prptherapy.ourspecialitytabs.btn1") ? (
                              <span>
                                {t("prptherapy.ourspecialitytabs.btn1f")} <br />{" "}
                                {t("prptherapy.ourspecialitytabs.btn1l")}
                              </span>
                            ) : item ===
                              t("prptherapy.ourspecialitytabs.btn2") ? (
                              <span>
                                {t("prptherapy.ourspecialitytabs.btn2f")} <br />{" "}
                                {t("prptherapy.ourspecialitytabs.btn2l")}
                              </span>
                            ) : item ===
                              t("prptherapy.ourspecialitytabs.btn3") ? (
                              <span>
                                {t("prptherapy.ourspecialitytabs.btn3f")} <br />{" "}
                                {t("prptherapy.ourspecialitytabs.btn3l")}
                              </span>
                            ) : item ===
                              t("prptherapy.ourspecialitytabs.btn4") ? (
                              <span>
                                {t("prptherapy.ourspecialitytabs.btn4f")} <br />{" "}
                                {t("prptherapy.ourspecialitytabs.btn4l")}
                              </span>
                            ) : item ===
                              t("prptherapy.ourspecialitytabs.btn5") ? (
                              <span>
                                {t("prptherapy.ourspecialitytabs.btn5")}
                              </span>
                            ) : (
                              ""
                            )
                          }
                          className={
                            selectedTab1 === item
                              ? "dashboardBtnList-item-active"
                              : "default-color-and-hover"
                          }
                          bntStyle={{ fontSize: "15px", borderRadius: "5px" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              }
              imgSrc={selecteddata?.img}
              imgStyle={{
                borderRadius: "4px",
                width: "100%",
                height: "340px",
                objectFit: "fill",
              }}
              hrlinecustome={
                <hr
                  style={{
                    borderBottom: "3px solid #AF5689",
                    margin: "10px 0px 15px 0px",
                    width: "80px",
                  }}
                ></hr>
              }
              headingFirst={selecteddata.heading}
              discription={<>{selecteddata?.description}</>}
              discription2={<>{selecteddata?.description1}</>}
              linkcustomli={
                <div class="span-department mb-5">
                  <ul>
                    {selecteddata?.linkchecks?.map((item) => {
                      return item?.titlelink ? (
                        <Link
                          to="#"
                          style={{ textDecoration: "none", color: "#333333" }}
                        >
                          <li>
                            <i class="fa fa-check"></i>
                            {item?.title}
                          </li>
                        </Link>
                      ) : (
                        <li>
                          <i class="fa fa-check"></i>
                          {item?.title}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              }
              reverse={false}
            />
          </div>
        ) : (
          <div className="mb-5">
            <TabHandlerTextwithImage
              SelectedButtons={[selecteddata]?.map((item_) => (
                <div className="my-4">
                  <div className="row">
                    {buttonState?.map((item, index) => (
                      <div
                        className="col-lg-3 col-md-6 px-2 mb-3 d-flex d-inline-flex"
                        key={index + 1}
                        onClick={() => tabHandlerTest(item)}
                      >
                        <TabBtnList
                          label={item}
                          className={
                            selectedTab1 === item
                              ? "dashboardBtnList-item-active"
                              : "default-color-and-hover"
                          }
                          bntStyle={{ fontSize: "15px", borderRadius: "5px" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              alt={treatmentAltTags?.prp ? treatmentAltTags?.prp : ""}
              imgSrc={selecteddata?.image}
              imgStyle={{
                borderRadius: "4px",
                width: "100%",
                height: "340px",
                objectFit: "fill",
              }}
              hrlinecustome={
                <hr
                  style={{
                    borderBottom: "3px solid #AF5689",
                    margin: "10px 0px 15px 0px",
                    width: "80px",
                  }}
                ></hr>
              }
              headingFirst={selecteddata?.heading}
              discription={<>{selecteddata?.desc}</>}
              // discription2={<>{selecteddata?.description1}</>}
              linkcustomli={
                <div class="span-department mb-5">
                  <ul>
                    {tablits?.map((item) => {
                      return selecteddata?.name?.toLowerCase() ==
                        item?.tab?.toLowerCase() ? (
                        <Link
                          to="#"
                          style={{ textDecoration: "none", color: "#333333" }}
                        >
                          <li>
                            <i class="fa fa-check"></i>
                            {item?.text}
                          </li>
                        </Link>
                      ) : null;
                    })}
                  </ul>
                </div>
              }
              reverse={false}
            />
          </div>
        )}

        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-12">
              <div class="section" onClick={() => setToggle(!toggle)}>
                <span class="makeinquirybt">
                  {enquiry?.button
                    ? enquiry?.button
                    : t("makeanenquiry.enquirybtn")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toggleform toggle={toggle} data={enquiry} />

      <div>
        <HeadingWithBottomBar heading={t("prptherapy.patientfeedhead")} />

        <Testemonials stories={stories} />
      </div>
    </>
  );
};

export default PRP;
