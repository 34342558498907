import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  returnRequest,
  returnRequestPicture,
} from "../../../../store/actions/patientActions";
export default function ReturnRequest(props) {
  const [url, seturl] = useState("");
  const loader = useSelector((state) => state?.patient?.patientLoader);
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    let apiData = {
      image: url,
      order_id: props?.orderData?.order_id,
      medicine_id: props?.orderData?.medicine_id,
      quantity: data?.quantity ? Number(data?.quantity) : props?.orderData?.quantity,
      reason: data?.reason,
      is_used: data?.isused,
      is_packaging_intact: data?.ispackaged,
    };
   
    let res = await dispatch(returnRequest(apiData));
    if (res) {
      toast.success("Request Created Successfully");
      props.history.push("patient-dashboard", { key: "fromOrderDetails" });
    }
  };
  const handleImageUpload = async (e) => {
    if (e.target.files?.length < 1) {
      toast.error("Please Select File");
      return;
    }
    let formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    let res = await dispatch(returnRequestPicture(formdata));
    if (!res) {
      return;
    }
    seturl(res);
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="dashboardBtnList-item-active">
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-white">{"Return Request"}</span>
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true" className="text-white">
              ×
            </span>
            <span class="sr-only">
              {t("dashboards.alldashboardkeys.tablekeys.close")}
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-12">
                  <span className="label-name-login">{"Select Image"}</span>
                  <Form.Group>
                    <input
                      className="input-login-modal"
                      type="file"
                      onChange={handleImageUpload}
                      required={false}
                    />
                    {/* {mediError ? (
                                            <div className="text-error">{mediError}</div>
                                        ) : (
                                            ""
                                        )} */}
                  </Form.Group>
                </div>
              </div>
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-6">
                  <span className="label-name-login">{"Enter Quantity"}</span>
                  <Form.Group>
                    <input
                      className="input-login-modal"
                      type="number"
                      {...register("quantity", {})}
                    />
                    {/* {mediError ? (
                                            <div className="text-error">{mediError}</div>
                                        ) : (
                                            ""
                                        )} */}
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <span className="label-name-login">{"Enter Reason"}</span>
                  <Form.Group>
                    <textarea
                      className="input-login-modal"
                      type="text"
                      {...register("reason", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                  </Form.Group>
                </div>
              </div>
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-6">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      style={{ width: "15px", height: "15px" }}
                      {...register("isused", {})}
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      {"Is Used"}
                    </label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      style={{ width: "15px", height: "15px" }}
                      {...register("ispackaged", {})}
                    />
                    <label className="form-check-label" htmlFor="exampleCheck2">
                      {"Is Packaged"}
                    </label>
                  </div>
                </div>
              </div>

              <input
                disabled={loader}
                type="submit"
                value={"Request Return"}
                className="btnSquare btn-block mx-auto"
                style={{ width: "85%", textAlign: "center", cursor: "pointer" }}
              />
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
