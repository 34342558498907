import React from "react";
import { Link } from "react-router-dom";

const Specialitycard = (props) => {
  return (
    <div
      className={`${props.className} col-lg-3 col-md-6 mb-3`}
      style={props.style}
    >
      <Link
        to={props.linkroute}
        class="bottom-post"
        style={{ textDecoration: "none" }}
      >
        <div class="profile-img">
          <div class="about-yoga-pic">
            <img
              src={props.img}
              alt={props.alt ? props?.alt : props.head1}
              height="250px"
              width="100%"
            />
          </div>
          <h2>{props.head1}</h2>
          <h6>{props.head2}</h6>
          <p>{props.discription}</p>
        </div>
      </Link>
    </div>
  );
};

export default Specialitycard;
