import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DoctorAppointmentReschedule from "../../components/layouts/modal/doctorAppointmentReschedule/DoctorAppointmentReschedule";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";

const DoctorPastAppointment = (props) => {
  const { t } = useTranslation("common")
  const loader = useSelector((state) => state?.patient?.patientLoader);
  const appointment = useSelector((state) => state?.patient?.appointments);
  const handleView = (value, row) => {
    if (value === t("dashboards.alldashboardkeys.tablekeys.viewbtn")) {
      props.history.push("/appointment-detail", { row, from: "past" });
    }
  };
  const [rescheduleModal, setrescheduleModal] = useState({
    show: false,
    data: {},
  });
  const handleChange = async (value, row) => {
    if (value === "View") {
      props?.history?.push("/appointment-detail", { row, from: "past" });
    }

    else if (value == "Reschedule") {
      setrescheduleModal({ show: true, data: row });
    }
  };
  const defaultSorting = [{
    dataField: "date",
    order: "asc",
  },]
  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.pastappointments")}</h4>
        </div>
      </div>

      <div className="row">
        {loader ? (
          <LoderContainer />
        ) : (
          <BootstrapCustomTable
            defaultsort={defaultSorting}
            columns={[
              {
                formatter: (cell, row, index) => {
                  return <span>{index + 1}</span>;
                },
                text: "Sr.",
              },
              {
                formatter: (cell, row) => {
                  return <span>{moment(row.date).format("DD/MM/YYYY")}</span>;
                },
                text: t("dashboards.alldashboardkeys.tablekeys.date"),
              },
              {
                formatter: (cell, row) => {
                  return <span>{`${row.slot.from} - ${row.slot.to}`}</span>;
                },
                text: t("dashboards.alldashboardkeys.tablekeys.time"),
              },



              { dataField: "patient_details.name", text: t("dashboards.alldashboardkeys.tablekeys.patientname") },
              { dataField: "condition.name", text: t("dashboards.alldashboardkeys.tablekeys.condition") },
              { dataField: "consultation_type.name", text: t("dashboards.alldashboardkeys.tablekeys.consultationtype") },
              {
                formatter: (cell, row) => {
                  return <span style={{ color: row?.status?.name == "booked" ? 'red' : row?.status?.name == "confirmed" ? 'green' : '#af5689' }}>{row?.status?.name?.toUpperCase()}</span>;
                },
                text: "Status",
              },
              {
                formatter: (cell, row, index) => {
                  let item = { ...row, from: "PastAppointment" };

                  return (
                    row?.status?.name == "booked" ?
                      <ActionBtn
                        options={["View", "Reschedule"]}
                        handleChange={handleChange}
                        data={row}
                      />
                      : row?.status?.name == "confirmed" && row?.consultation_type?.name == "online" ? <ActionBtn
                        options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn"), "Reschedule"]}
                        handleChange={handleChange}
                        data={row}
                      /> : row?.status?.name == "confirmed" && row?.consultation_type?.name == "walk-in" ? <ActionBtn
                        options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn"), "Reschedule"]}
                        handleChange={handleChange}
                        data={row}
                      /> : row?.status?.name == "completed" ? <ActionBtn
                        options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                        handleChange={handleChange}
                        data={row}
                      /> : <ActionBtn
                        options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                        handleChange={handleChange}
                        data={row}
                      />

                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.action"),
              },
            ]}
            tabledata={appointment?.past?.length > 0 ? appointment?.past : []}
          />
        )}
      </div>
      <DoctorAppointmentReschedule
        show={rescheduleModal?.show}
        data={rescheduleModal?.data}
        onHide={() => setrescheduleModal({ show: false, data: {} })}
        {...props}
      />
    </div>
  );
};
export default DoctorPastAppointment;
