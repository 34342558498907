import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  nemenucard4,
  banner,
  LinksBanSpecialityNeurSpinal,
} from "../../../../../data";
import { filterComponentData } from "../../../../../utils/functions";

const SpinalCordInjury = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const autism = filterComponentData(
    component,
    "speciality_neurological_rehabilitation_spinal_cord_injury",
    lang
  );
  const sutismList = filterComponentData(
    component,
    "speciality_neurological_rehabilitation_stem_cell_therapy_for_spinal_cord_list",
    lang
  );
  const stemcell = filterComponentData(
    component,
    "speciality_neurological_rehabilitation_stem_cell_therapy_for_spinal_cord_",
    lang
  );
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );
  const { t } = useTranslation("common");

  const LinksBanSpecialityNeurSpinal = [
    {
      subLinkName: banners[36]?.subtitle?.split(">")[0]
        ? banners[36]?.subtitle?.split(">")[0]
        : t("spinalcordinjury.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[36]?.subtitle?.split(">")[1]
        ? banners[36]?.subtitle?.split(">")[1]
        : t("spinalcordinjury.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[36]?.subtitle?.split(">")[2]
        ? banners[36]?.subtitle?.split(">")[2]
        : t("spinalcordinjury.banner.title1"),
      subDash: ">",
      subLink: "/neurological-rehabilitation",
    },
    {
      subLinkName: banners[36]?.subtitle?.split(">")[3]
        ? banners[36]?.subtitle?.split(">")[3]
        : t("spinalcordinjury.banner.title2"),
      subDash: "",
      subLink: "/spinal-cord-injury",
    },
  ];
  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(
                item?.neurologicalSpinal ? item?.neurologicalSpinal : "[]"
              )
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.neurologicalSpinal
            ? specialityMetaTitles?.neurologicalSpinal
            : t("spinalcordinjury.maintitle")}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.neurologicalSpinal
              ? specialityMetaDesc?.neurologicalSpinal
              : t("spinalcordinjury.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.neurologicalSpinal
              ? specialityMetaKeywords?.neurologicalSpinal
              : t("spinalcordinjury.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[36]?.heading
              ? banners[36]?.heading
              : t("spinalcordinjury.banner.title2")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[36]?.image ? banners[36]?.image : banner
          })`}
          imgSrc={banners[36]?.image ? banners[36]?.image : banner}
          LinksBan={LinksBanSpecialityNeurSpinal}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={autism?.image ? autism?.image : nemenucard4}
          alt={
            specialityAltTags?.neurologicalSpinal
              ? specialityAltTags?.neurologicalSpinal
              : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={
            autism?.heading
              ? autism?.heading
              : t("spinalcordinjury.textrev.headfirst")
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {autism?.description1
                  ? autism?.description1
                  : t("spinalcordinjury.textrev.discriptiontxt1")}
                <br />
                {autism?.description1
                  ? autism?.description1
                  : t("spinalcordinjury.textrev.discriptiontxt2")}
              </p>
              <br />
              <h2
                style={{ textTransform: "capitalize", color: "#333333" }}
                className="gray-headin"
              >
                {stemcell?.heading
                  ? stemcell?.heading
                  : t("spinalcordinjury.textrev.discriptiontxt3")}
              </h2>
              <hr
                style={{
                  borderBottom: "3px solid #AF5689",
                  margin: "10px 0px 15px 0px",
                  width: "80px",
                }}
              ></hr>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {stemcell?.description
                  ? stemcell?.description
                  : t("spinalcordinjury.textrev.discriptiontxt4")}
                {/* <br />
                {t("spinalcordinjury.textrev.discriptiontxt5")} */}
              </p>
              {sutismList?.length < 1 ? (
                <div class="span-department">
                  <ul>
                    <li>
                      <i class="fa fa-check"></i>
                      {t("spinalcordinjury.textrev.list1")}
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      {t("spinalcordinjury.textrev.list2")}
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      {t("spinalcordinjury.textrev.list3")}
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      {t("spinalcordinjury.textrev.list4")}
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      {t("spinalcordinjury.textrev.list5")}
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      {t("spinalcordinjury.textrev.list6")}
                    </li>
                  </ul>
                </div>
              ) : (
                sutismList?.map((item) => (
                  <div div class="span-department">
                    <ul>
                      <li>
                        <i class="fa fa-check"></i>
                        {item?.text}
                      </li>
                    </ul>
                  </div>
                ))
              )}
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default SpinalCordInjury;
