import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// import UploadReportModal from "../../Components/LayOuts/modals/uploadReportModal/UploadReportModal";
// import DashboardBtnList from "../../components/reuseablecomponents/DashboardBtnList";
import DashboardBtnList from "../../components/reuseablecomponents/DashboardBtnList";
import DashBoardMainHading from "../../components/reuseablecomponents/DashBoardMainHading";
import DetailTable from "../../components/reuseablecomponents/DetailTable";
import {
  // changeResportStatus,
  getTestResports,
} from "../../store/actions/nurseActions";
import { generatePayment } from "../../store/actions/patientActions";
import { checkPaidOrUnpaid, comaSeperated } from "../../utils/functions";
import ReturnRequest from "../../components/layouts/modal/returnRequestModal/ReturnRequest";
import { Button } from "react-bootstrap";

const PatientOrderDetails = (props) => {
  const loader = useSelector((state) => state?.patient?.patientLoader);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});

  const item1 = { key: "fromOrderDetails" };

  const { t } = useTranslation("common");
  const viewData = props.location.state;
  const item = { key: "fromOrderDetails" };
  //   const [openuploadModal, setopenuploadModal] = useState(false);
  // const [selectedReport, setSelectedReport] = useState("");
  //   const selectedReport = "";
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTestResports());
  }, [dispatch]);
  // t("dashboards.alldashboardkeys.tablekeys.prescription")

  const paymentHandler = async (data) => {
    let apiData = {
      id: data?.id,
      type: "order",
      partial_payment: false,
    };
    const res = await dispatch(generatePayment(apiData));
    if (!res) {
      return;
    }
    window.open(res);
    window.focus();
  };
  const payByCash = async (data) => {
    let apiData = {
      id: data?.id,
      type: "order",
      partial_payment: false,
    };
    const res = await dispatch(generatePayment(apiData));
    props.history.push('/patient-dashboard',item1)
    // if (!res) {
    //   return;
    // }
    // window.open(res);
    // window.focus();
  };
  const partialPaymentHandler = async (data) => {
    let apiData = {
      id: data?.id,
      type: "order",
      partial_payment: true,
    };
    const res = await dispatch(generatePayment(apiData));
    if (res == false) {
      return;
    }
    window.open(res);
    window.focus();
  };
  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading
        title={t("dashboards.alldashboardkeys.tablekeys.orderdetail")}
      />
      <div className="container">
        <div className="row center-children-class">
          <div className="col-xl-8 col-md-8 col-12 px-0">
            <div
              className=" btn btnSquare"
              style={{ width: "150px" }}
              onClick={() => {
                props?.history?.push("/patient-dashboard", item);
              }}
            >
              <i className="fas fa-arrow-left"></i>{" "}
              {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
            </div>
          </div>
        </div>
        <div className="row center-children-class mt-3  pb-5">
          <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
            <div className="d-flex justify-content-between">
              <h5>
                {t("dashboards.alldashboardkeys.tablekeys.patientdetail")}
              </h5>
              <div>
                <div>
                  <p>
                    {t("dashboards.alldashboardkeys.tablekeys.paymentstatus")} :
                    <strong
                      style={{
                        color: checkPaidOrUnpaid(viewData?.paid_status.name),
                      }}
                    >
                      {viewData?.paid_status?.name?.toUpperCase()}
                    </strong>
                  </p>
                </div>
                {viewData?.paid_status?.name === "unpaid" &&
                  viewData?.delivery_status?.name != "cancelled" ? (
                  loader ? (
                    <Loader
                      type="Oval"
                      height="40px"
                      width="20px"
                      className="text-center"
                      color="green"
                    />
                  ) : (
                    <>
                      <button
                        className="btnSquare w-100"
                        onClick={() => paymentHandler(viewData)}
                      >
                        {t("dashboards.alldashboardkeys.tablekeys.paybtn")}
                      </button>
                      <button
                        className="btnSquare w-100 mt-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => payByCash(viewData)}
                      >
                        Pay By Cash
                      </button>
                      {/* <button
                        className="btn btnSquare w-100  mt-1"
                        onClick={() => {
                          partialPaymentHandler(viewData);
                        }}
                      >
                        {t(
                          "dashboards.alldashboardkeys.tablekeys.partiallypay"
                        )}
                      </button> */}
                    </>
                  )
                ) :
                  !viewData?.receipt_url ? null : (
                    <button
                      className="btn btnSquare w-100"
                      onClick={() => window.open(viewData?.receipt_url, "_blank")}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.receipt")}
                    </button>
                  )}
              </div>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientname")}
                </span>
                <p>{viewData?.ordered_by?.name}</p>
              </li>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientemail")}
                </span>
                <p>{viewData?.ordered_by?.email_address}</p>
              </li>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientnumber")}
                </span>
                <p>{viewData?.ordered_by?.phone_number}</p>
              </li>
            </ul>
            <h5 className="mt-4">
              {t("dashboards.alldashboardkeys.tablekeys.orderdetail")}
            </h5>
            <ul style={{ listStyle: "none" }}>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.ordernumber")}
                </span>
                <p>{viewData?.order_id}</p>
              </li>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.deliverystatus")}
                </span>
                <p>{viewData?.delivery_status?.name}</p>
              </li>
            </ul>
            {viewData?.tests?.length > 0 ? (
              <>
                <h5>
                  {t("dashboards.alldashboardkeys.tablekeys.testdetails")}
                </h5>
                <DetailTable
                  tableHead={[
                    t("dashboards.alldashboardkeys.tablekeys.name"),
                    t("dashboards.alldashboardkeys.tablekeys.testid"),
                    t("dashboards.alldashboardkeys.tablekeys.quantity"),
                    t("dashboards.alldashboardkeys.tablekeys.price"),
                    t("dashboards.alldashboardkeys.tablekeys.action"),
                  ]}
                  data={viewData?.tests?.map((item) => {
                    return [
                      item?.test?.name,
                      item?.test?.test_id,
                      item?.quantity,
                      item?.test?.price,
                      // eslint-disable-next-line
                      <a
                        className={
                          item?.test?.report?.status?.name ===
                            "report uploaded" && item?.test?.report?.url != ""
                            ? "btn btnSquare w-100"
                            : "btn btn-block btn-light w-100 "
                        }
                        href={
                          item?.test?.report?.status?.name ===
                            "report uploaded" && item?.test?.report?.url != ""
                            ? item?.test?.report?.url
                            : null
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("dashboards.alldashboardkeys.tablekeys.viewreport")}
                      </a>,
                    ];
                  })}
                />
              </>
            ) : null}
            {viewData?.medicines?.length > 0 ? (
              <>
                <h5>
                  {t("dashboards.alldashboardkeys.tablekeys.medicinedetails")}
                </h5>
                <DetailTable
                  tableHead={[
                    t("dashboards.alldashboardkeys.tablekeys.name"),
                    t("dashboards.alldashboardkeys.tablekeys.medicineid"),
                    t("dashboards.alldashboardkeys.tablekeys.quantity"),
                    t("dashboards.alldashboardkeys.tablekeys.price"),
                    "Action",
                  ]}
                  data={viewData?.medicines?.map((item) => {
                    return [
                      item?.medicine?.name,
                      item?.medicine?.medicine_id,
                      item?.medicine?.quantity,
                      item?.medicine?.sales_price,
                      viewData?.delivery_status?.name == "delivered" ? (
                        <Button
                          className="btn btnSquare w-100"
                          onClick={() => {
                            setData({
                              order_id: viewData?.id,
                              medicine_id: item?.medicine?.id,
                              quantity:item?.medicine?.quantity
                            });
                            setShow(!show);
                          }}
                        >
                          {"Return"}
                        </Button>
                      ) : null,
                    ];
                  })}
                />
              </>
            ) : null}
            {viewData?.packages?.length > 0 ? (
              <>
                <h5>
                  {t("dashboards.alldashboardkeys.tablekeys.packagedetails")}
                </h5>
                <DetailTable
                  tableHead={[
                    t("dashboards.alldashboardkeys.tablekeys.name"),
                    t("dashboards.alldashboardkeys.tablekeys.tes"),
                    t("dashboards.alldashboardkeys.tablekeys.price"),
                  ]}
                  data={viewData?.packages?.map((item) => {
                    return [
                      item?.package?.name,
                      item?.package?.tests?.map((item) => (
                        <li>{item?.name}</li>
                      )),
                      item?.package?.price,
                    ];
                  })}
                />
              </>
            ) : null}
            {viewData?.paid_status?.name == "partial" ? (
              <>
                <ul style={{ float: "right", listStyle: "none" }}>
                  <li>
                    <span
                      className=""
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#787878",
                      }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.totalamount")} :{" "}
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {comaSeperated(viewData?.total_amount)}
                    </span>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#787878",
                      }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.paidamount")} :{" "}
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {comaSeperated(viewData?.paid_amount)}
                    </span>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#787878",
                      }}
                    >
                      {t(
                        "dashboards.alldashboardkeys.tablekeys.remainingamount"
                      )}{" "}
                      :{" "}
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {comaSeperated(viewData?.remaining_amount)}
                    </span>
                  </li>
                  {/* <li>
                    <span className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }} >Installment Amount : </span>

                    <span style={{ fontWeight: 'bold' }}>{viewData?.installments_amount}</span></li> */}
                </ul>
              </>
            ) : viewData?.paid_status?.name == "paid" ? (
              <ul style={{ float: "right", listStyle: "none" }}>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#787878",
                    }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.totalamount")} :{" "}
                  </span>

                  <span style={{ fontWeight: "bold" }}>
                    {comaSeperated(viewData?.total_amount)}
                  </span>
                </li>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#787878",
                    }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.paidamount")} :{" "}
                  </span>

                  <span style={{ fontWeight: "bold" }}>
                    {comaSeperated(viewData?.paid_amount)}
                  </span>
                </li>
              </ul>
            ) : viewData?.paid_status?.name == "unpaid" ? (
              <>
                <ul style={{ float: "right", listStyle: "none" }}>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#787878",
                      }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.totalamount")} :{" "}
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {comaSeperated(viewData?.total_amount)}
                    </span>
                  </li>

                    {/* <li>
                      <span
                        className="light-Color-Para"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#787878",
                        }}
                      >
                        {t(
                          "dashboards.alldashboardkeys.tablekeys.installmentamount"
                        )}{" "}
                        :{" "}
                      </span>

                      <span style={{ fontWeight: "bold" }}>
                        {comaSeperated(viewData?.installments_amount)}
                      </span>
                    </li> */}
                </ul>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {/* <UploadReportModal
        show={openuploadModal}
        onHide={() => setopenuploadModal(false)}
        viewData={selectedReport}
        {...props}
      /> */}
      <ReturnRequest
        show={show}
        orderData={data}
        onHide={() => setShow(false)}
        {...props}
      />
    </div>
  );
};

export default PatientOrderDetails;




// viewData?.paid_status?.name == "partial" &&
//                   viewData?.delivery_status?.name != "cancelled" ? (
//                   loader ? (
//                     <Loader
//                       type="Oval"
//                       height="40px"
//                       width="20px"
//                       className="text-center"
//                       color="green"
//                     />
//                   ) : viewData?.receipt_url == "" ? (
//                     <button
//                       className="btnSquare w-100"
//                       onClick={() => paymentHandler(viewData)}
//                     >
//                       {t("dashboards.alldashboardkeys.tablekeys.paybtn")}
//                     </button>
//                   ) : (
//                     <>
//                       <button
//                         className="btnSquare w-100 mb-1"
//                         onClick={() => paymentHandler(viewData)}
//                       >
//                         {t("dashboards.alldashboardkeys.tablekeys.paybtn")}
//                       </button>
//                       <button
//                         className="btnSquare w-100 mt-1"
//                         style={{ cursor: "pointer" }}
//                         onClick={() => {
//                           props.history.push("patient-dashboard", item1);
//                         }}
//                       >
//                         Pay By Cash
//                       </button>
//                       <button
//                         className="btn btnSquare w-100 mt-1"
//                         onClick={() =>
//                           window.open(viewData?.receipt_url, "_blank")
//                         }
//                       >
//                         {t("dashboards.alldashboardkeys.tablekeys.receipt")}
//                       </button>
//                     </>
//                   )
//                 ) :