import React from "react";
import { useTranslation } from "react-i18next";
import DashBoardMainHading from "../../components/reuseablecomponents/DashBoardMainHading";
import DetailTable from "../../components/reuseablecomponents/DetailTable";

const PatientDetails = (props) => {
  const {t} = useTranslation("common")
  const viewData = props.location.state;
  const item = { key: "PatientDetails" };

  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading title={t("dashboards.alldashboardkeys.tablekeys.patientdetail")} />
      <div className="container">
        <div className="row center-children-class">
          <div className="col-xl-8 col-md-8 col-12 px-0">
            <div
              className="btn btnSquare"
              style={{ width: "150px" }}
              onClick={() => {
                props.history.push("/doctor-dashboard", item);
              }}
            >
              <i className="fas fa-arrow-left"></i> {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
            </div>
          </div>
        </div>
        <div className="row center-children-class mt-3 pb-5">
          <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
            <div className="d-flex justify-content-between">
              <h5>{t("dashboards.alldashboardkeys.tablekeys.patientdetail")}</h5>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientname")}
                </span>
                <p>{viewData?.name}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientemail")}
                </span>
                <p>{viewData?.email_address}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                   {t("dashboards.alldashboardkeys.tablekeys.patientnumber")}
                </span>
                <p>{viewData?.phone_number}</p>
              </li>{" "}
            </ul>

            <div>
              {viewData?.appointment_details?.length > 0 ? (
                <div>
                  <h5>{t("dashboards.alldashboardkeys.tablekeys.appointmentdetail")}</h5>

                  {viewData?.appointment_details?.length > 0 ? (
                    <>
                      <DetailTable
                        tableHead={[
                          t("dashboards.alldashboardkeys.tablekeys.date")
                          ,
                          t("dashboards.alldashboardkeys.tablekeys.description")
                          ,
                          t("dashboards.alldashboardkeys.tablekeys.status")
                        ]}
                        data={viewData?.appointment_details?.map((item) => {
                          return [item?.date, item?.description, <span style={{color:item?.status == "booked" ? 'red' : item?.status == "confirmed" ? 'green' : '#af5689'}}>{item?.status?.toUpperCase()}</span>];
                        })}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;
