import React from "react";
import Loader from "react-loader-spinner";
const LoderContainer = () => {
  return (
    <div className="w-100 center-children-class m-5 p-5">
      <Loader height={80} width={80} type="Oval" color="#1a4774" />
    </div>
  );
};

export default LoderContainer;
