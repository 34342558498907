import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../components/layouts/bannerImgComponents/BannerImgComponents";
import BlogCard from "../../components/layouts/cards/BlogCard/BlogCard";
import Testemonials from "../../components/layouts/testimonials/Testemonials";
import { banner, LinksBanStories } from "../../data";
import { filterComponentData } from "../../utils/functions";
import platelet from "../../../src/statics/toticellimages/news1.jpg";
import svf from "../../../src/statics/toticellimages/latest/svs-condi.jpg";
import bmc from "../../../src/statics/toticellimages/latest/bmc-desc.jpg";
import {
  homecard1,
  homecard2,
  homecard3,
  homecard4,
  homecard5,
  homecard6,
  homecard7,
  homecard8,
} from "../../data";

const Blogs = ({ history }) => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);
  const blog = filterComponentData(component, "blog", lang);
  const blogMetaTags = filterComponentData(component, "blog_meta_tags", lang);
  const blogSchemaMarkups = filterComponentData(
    component,
    "blog_schema_markups",
    lang
  );
  const blogVerification = filterComponentData(
    component,
    "blog_verification_meta_tags",
    lang
  );

  const { t } = useTranslation("common");

  const LinksBanStories = [
    {
      subLinkName: banners[66]?.subtitle?.split(">")[0]
        ? banners[66]?.subtitle?.split(">")[0]
        : t("blog.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[66]?.subtitle?.split(">")[1]
        ? banners[66]?.subtitle?.split(">")[1]
        : t("blog.banner.title"),
      subDash: "",
      subLink: "/blogs",
    },
  ];
  const blogCardData = [
    {
      cardImage: platelet,
      cardHeading: t("blog.blogcards.card1.heading"),
      mainDesc: t("blog.blogcards.card1.description"),
      desc1: t("blog.blogcards.card1.discriptiontxt1"),
      desc2: t("blog.blogcards.card1.discriptiontxt2"),
      desc3: t("blog.blogcards.card1.discriptiontxt3"),
      desc4: t("blog.blogcards.card1.discriptiontxt4"),
      blogTitile: t("blog.blogcards.card1.blogTitle"),
    },
    {
      cardImage: svf,
      cardHeading: t("blog.blogcards.card2.heading"),
      mainDesc: t("blog.blogcards.card2.description"),
      desc1: t("blog.blogcards.card2.discriptiontxt1"),
      desc2: t("blog.blogcards.card2.discriptiontxt2"),
      desc3: t("blog.blogcards.card2.discriptiontxt3"),
      desc4: t("blog.blogcards.card2.discriptiontxt4"),
      blogTitile: t("blog.blogcards.card2.blogTitle"),
    },
    {
      cardImage: bmc,
      cardHeading: t("blog.blogcards.card3.heading"),
      mainDesc: t("blog.blogcards.card3.description"),
      desc1: t("blog.blogcards.card3.discriptiontxt1"),
      desc2: t("blog.blogcards.card3.discriptiontxt2"),
      desc3: t("blog.blogcards.card3.discriptiontxt3"),
      desc4: t("blog.blogcards.card3.discriptiontxt4"),
      blogTitile: t("blog.blogcards.card3.blogTitle"),
    },
  ];
  const verificationsTags = blogVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.name,
      content: item?.content,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}
        {blogSchemaMarkups?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(JSON.stringify(item?.blog ? item?.blog : "[]"))}
          </script>
        ))}
        <title>
          {blogMetaTags?.metaTitle
            ? blogMetaTags?.metaTitle
            : "Blogs - Toticell"}
        </title>
        <meta
          name="description"
          content={
            blogMetaTags?.metaDesc
              ? blogMetaTags?.metaDesc
              : t("blog.blogcards.card1.description")
          }
        />
        <meta
          name="keywords"
          content={blogMetaTags?.metaKey ? blogMetaTags?.metaKey : "blogs"}
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[66]?.heading ? banners[66]?.heading : t("blog.banner.title")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[66]?.image ? banners[66]?.image : banner
          })`}
          imgSrc={banners[66]?.image ? banners[66]?.image : banner}
          LinksBan={LinksBanStories}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        {/* <Testemonials stories={stories} /> */}
        {/* <LatestPostCard
          key={index + 1}
          alt={
            homeBannerAltTags?.latestPosts ? homeBannerAltTags?.latestPosts : ""
          }
          img={item?.img}
          heading={item?.heading}
          description={item?.discription}
          likes={item?.likes}
          msg={item?.msg}
          cardlink={item?.cardlink}
        /> */}
        <div className="card-section-home mt-5">
          <div className="container">
            <div className="row ">
              {blog?.length < 1
                ? blogCardData?.map((item) => (
                    <BlogCard
                      img={item?.cardImage}
                      alt={blogMetaTags?.imgAlt}
                      heading={item?.cardHeading}
                      description={item?.mainDesc}
                      onPressCard={() => history?.push("/blog-details", item)}
                    />
                  ))
                : blog?.map((item) => (
                    <BlogCard
                      img={item?.cardImage}
                      alt={blogMetaTags?.imgAlt}
                      heading={item?.cardHeading}
                      description={item?.mainDesc}
                      // value={item}
                      onPressCard={() => history?.push("/blog-details", item)}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
