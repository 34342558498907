import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../components/layouts/bannerImgComponents/BannerImgComponents";
import DashboardBtnList from "../../components/layouts/tabbuttons/DashboardBtnList";
import { banner, LinksBanMedia, videolinks } from "../../data";
import { filterComponentData } from "../../utils/functions";

const Media = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);
  const media_tabs = filterComponentData(component, "media_tabs", lang);
  const media_videos = filterComponentData(component, "media_videos", lang);
  const media_pictures = filterComponentData(component, "media_pictures", lang);
  let mediaAltTags = filterComponentData(component, "media_page_alt_tag", lang);
  const footerMetaTitles = filterComponentData(
    component,
    "footer_meta_titles",
    lang
  );
  const footerSchemaMarkups = filterComponentData(
    component,
    "footer_schema_markups",
    lang
  );
  const footerMetaDesc = filterComponentData(
    component,
    "footer_meta_descriptions",
    lang
  );
  const footerMetaKeywords = filterComponentData(
    component,
    "footer_meta_keywords",
    lang
  );
  const footerVerification = filterComponentData(
    component,
    "footer_verification_meta_tags",
    lang
  );
  const { t } = useTranslation("common");

  const LinksBanMedia = [
    {
      subLinkName: media_tabs[65]?.subtitle?.split(">")[0]
        ? banners[65]?.subtitle?.split(">")[0]
        : t("media.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[65]?.subtitle?.split(">")[1]
        ? banners[65]?.subtitle?.split(">")[1]
        : t("media.banner.title"),
      subDash: "",
      subLink: "/media",
    },
  ];

  //import All images from Client folder from static/images/clients
  // Note to add any image in client slider add image in client folder and be cautious for image size
  function importAll(data) {
    let images = {};
    data.keys().map((item, index) => {
      images[item.replace("./", "")] = data(item);
      return "";
    });
    return images;
  }

  const images = importAll(
    require.context(
      "../../statics/toticellimages/gallery",
      false,
      /\.(png|jpe?g|JPG|svg)$/
    )
  );
  const imagesData = Object.keys(images);

  const [selectedTab2, setSelectedTab2] = useState(
    media_tabs?.tab2 ? media_tabs?.tab2 : t("media.pictures")
  );

  const tabHandler2 = (item) => {
    setSelectedTab2(item);
  };

  const buttonname3 = [
    media_tabs?.tab2 ? media_tabs?.tab2 : t("media.pictures"),
    media_tabs?.tab1 ? media_tabs?.tab1 : t("media.videos"),
  ];
  const verificationsTags = footerVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.mediaName,
      content: item?.mediaContent,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}
        {footerSchemaMarkups?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(JSON.stringify(item?.media ? item?.media : "[]"))}
          </script>
        ))}
        <title>
          {footerMetaTitles?.media
            ? footerMetaTitles?.media
            : t("media.maintitle")}
        </title>
        <meta
          name="description"
          content={
            footerMetaDesc?.media ? footerMetaDesc?.media : t("media.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            footerMetaKeywords?.media
              ? footerMetaKeywords?.media
              : t("media.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[65]?.heading
              ? banners[65]?.heading
              : t("media.banner.title")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[65]?.image ? banners[65]?.image : banner
          })`}
          imgSrc={banner}
          LinksBan={LinksBanMedia}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container">
          <div className="row ">
            <div className="col-12 text-center">
              {buttonname3.map((item, index) => (
                <div
                  className="d-flex d-inline-flex my-3 "
                  key={index + 1}
                  onClick={() => tabHandler2(item)}
                >
                  <DashboardBtnList
                    label={item}
                    bntStyle={{
                      borderRadius:
                        index === 0
                          ? "50px 0px 0px 50px"
                          : index === buttonname3.length - 1
                          ? "0px 50px 50px 0px"
                          : "",
                    }}
                    className={
                      selectedTab2 === item
                        ? "dashboardBtnList-itemgallery-active px-4 py-2"
                        : "default-color-and-hovergallery px-4 py-2"
                    }
                  />
                </div>
              ))}
            </div>
          </div>
          {selectedTab2 === t("media.pictures") ||
          selectedTab2 === media_tabs?.tab2 ? (
            <div className="row ">
              {media_pictures?.length < 1
                ? imagesData?.map((item, index) => {
                    return (
                      <div class="col-lg-4 col-md-6 col-sm-12 mt-5 center-childrens">
                        <a data-fancybox="gallery" href={images[item].default}>
                          <img
                            width="350px"
                            height="250px"
                            // alt="gallery"
                            alt={
                              mediaAltTags?.media
                                ? mediaAltTags?.media
                                : "gallery"
                            }
                            src={images[item].default}
                          />
                        </a>
                      </div>
                    );
                  })
                : media_pictures?.map((item, index) => {
                    return (
                      <div class="col-lg-4 col-md-6 col-sm-12 mt-5 center-childrens">
                        <a data-fancybox="gallery" href={item?.image}>
                          <img
                            width="350px"
                            height="250px"
                            // alt="gallery"
                            alt={
                              mediaAltTags?.media
                                ? mediaAltTags?.media
                                : "gallery"
                            }
                            src={item?.image}
                          />
                        </a>
                      </div>
                    );
                  })}
            </div>
          ) : (
            <div className="row">
              {media_videos?.length < 1
                ? videolinks.map((item) => {
                    return (
                      <div class="col-lg-6 col-md-6 col-sm-12 my-4">
                        <iframe
                          width="560"
                          height="415"
                          src={item}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    );
                  })
                : media_videos.map((item) => {
                    return (
                      <div class="col-lg-6 col-md-6 col-sm-12 my-4">
                        <iframe
                          width="560"
                          height="415"
                          src={item?.video}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    );
                  })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Media;
