import React, { useEffect, useState } from "react";
import PastAppointments from "./DoctorPastAppointment";
import TodayAppointments from "./DoctorTodayAppointment";
import UpComingAppointments from "./DoctorUpcomingAppointment";
import { patientAppointments } from "../../store/actions/patientActions";
import DashboardBtnList from "../../components/reuseablecomponents/DashboardBtnList";
import { useDispatch, useSelector } from "react-redux";
import DoctorAppointmentReschedule from "../../components/layouts/modal/doctorAppointmentReschedule/DoctorAppointmentReschedule";
import { videoCallToken } from "../../store/actions/staticActions";
import DashboardBtn from "../../components/reuseablecomponents/DashboardBtn";
import { useTranslation } from "react-i18next";

const DoctorAppointmentContainer = (props) => {
  const {t} = useTranslation("common")
  const [selectedTab, setSelectedTab] = useState("");
  const [tableData, setTableData] = useState([]);
  const [rescheduleModal, setrescheduleModal] = useState({
    show: false,
    data: {},
  });
  const dispatch = useDispatch();
  // const loader = useSelector((state) => state?.logIn?.loader);
  const user = useSelector((state) => state?.logIn?.user);
  const appointment = useSelector((state) => state?.patient?.appointments);
  // const loader = useSelector((state) => state?.patient?.patientLoader);

  useEffect(() => {
    // const finalTableDate = appointment?.today?.map((_item, index) => {
    //   _item = { ..._item, from: "TodayAppointment" };
    //   return [
    //     index + 1,
    //     _item?.date,
    //     _item?.patient_details?.name,
    //     _item?.description,
    //     <>
    //       <div className="row">
    //         <div className="col">
    //           <DashboardBtnList
    //             label={"Start Appointment"}
    //             className="order-pay-btn"
    //             // routePath="/view-appointment"
    //             onClick={() => {
    //               props?.history?.push("/doctor-add-prescriptions", _item);
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </>,
    //   ];
    // });
    // setTableData(finalTableDate || []);

    // if (!user) {
    //   props?.history?.push("/");
    // }
    const apiData = {
      id: user?.id,
    };
    
    setSelectedTab(t("dashboards.alldashboardkeys.tablekeys.today’sappointments"));

    dispatch(patientAppointments(apiData));

    // eslint-disable-next-line
  }, [user]);
  const tabHandler = (item) => {
    setSelectedTab(item);
    if (item === t("dashboards.alldashboardkeys.tablekeys.today’sappointments")) {
      const finalTableDate = appointment?.today?.map((_item, index) => {
        _item = { ..._item, from: "TodayAppointment" };
        return [
          index + 1,
          _item?.date,
          _item?.patient_details?.name,
          _item?.description,
          <>
            <div className="row">
              <div className="col">
                <DashboardBtnList
                  label={t("dashboards.alldashboardkeys.tablekeys.startappointment")}
                  className="order-pay-btn"
                  // routePath="/view-appointment"
                  onClick={() => {
                    dispatch(
                      videoCallToken(false, { appointment_id: _item?.id })
                    );
                    props?.history?.push("/doctor-add-prescriptions", _item);
                  }}
                />
              </div>
            </div>
          </>,
        ];
      });
      setTableData(finalTableDate || []);
    } else if (item === t("dashboards.alldashboardkeys.tablekeys.upcomingappointment")) {
      const finalTableDate = appointment?.upcoming?.map((item, index) => {
        return [
          index + 1,
          item?.date,
          item?.patient_details?.name,
          item?.description,
          <>
            <div className="row">
              <div className="col pr-0">
                <DashboardBtnList
                  label={t("dashboards.alldashboardkeys.tablekeys.viewbtn")}
                  className=""
                  onClick={() => {
                    props.history.push("/appointment-detail", item);
                  }}
                />
              </div>
              <div className="col">
                <DashboardBtnList
                  label={t("dashboards.alldashboardkeys.tablekeys.reschedule")}
                  className="appointment-reschdule-btn"
                  onClick={() => setrescheduleModal({ show: true, data: item })}
                />
              </div>
            </div>
          </>,
        ];
      });
      setTableData(finalTableDate || []);
    } else if (item === t("dashboards.alldashboardkeys.tablekeys.pastappointments")) {
      const finalTableDate = appointment?.past?.map((item, index) => {
        return [
          index + 1,
          item?.date,
          item?.patient_details?.name,
          item?.description,
          <>
            <div className="row">
              <div className="col ">
                <DashboardBtnList
                  label={t("dashboards.alldashboardkeys.tablekeys.viewbtn")}
                  className="order-pay-btn"
                  onClick={() => {
                    props.history.push("/appointment-detail", item);
                  }}
                />
              </div>
            </div>
          </>,
        ];
      });
      setTableData(finalTableDate || []);
    }
  };
  
  return (
    <>
      <div>
        <div className="row">
          {[
            t("dashboards.alldashboardkeys.tablekeys.today’sappointments"),
            t("dashboards.alldashboardkeys.tablekeys.upcomingappointment"),
            t("dashboards.alldashboardkeys.tablekeys.pastappointments"),
          ].map((item, index) => (
            <div
              className="col-lg-3 col-md-4 col-12 mt-2"
              key={index + 1}
              onClick={() => tabHandler(item)}
            >
              <DashboardBtn
                label={item}
                labelStyle={selectedTab === item ? { color: "#fff" } : ""}
                active={selectedTab === item}
                className={
                  selectedTab === item
                    ? "dashboardBtnList-item-active py-2"
                    : "default-color-and-hover py-2"
                }
                onClick={() => tabHandler(item)}
              />
            </div>
          ))}
        </div>

        {selectedTab === t("dashboards.alldashboardkeys.tablekeys.today’sappointments") ? (
          <TodayAppointments tabledata={tableData} {...props} />
        ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.upcomingappointment") ? (
          <UpComingAppointments tabledata={tableData} {...props} />
        ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.pastappointments") ? (
          <PastAppointments tabledata={tableData} {...props} />
        ) : (
          ""
        )}
        <DoctorAppointmentReschedule
          show={rescheduleModal?.show}
          data={rescheduleModal?.data}
          onHide={() => setrescheduleModal({ show: false, data: {} })}
          {...props}
        />
      </div>
    </>
  );
};

export default DoctorAppointmentContainer;
