import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  nemenucard1,
  banner,
  LinksBanSpecialityNeuroAutism,
} from "../../../../../data";
import { filterComponentData } from "../../../../../utils/functions";

const Autism = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const autism = filterComponentData(
    component,
    "speciality_neurological_rehabilitation_autism_spectrum_disorder",
    lang
  );
  const sutismList = filterComponentData(
    component,
    "speciality_neurological_rehabilitation_autism_spectrum_disorder_list",
    lang
  );
  const stemcell = filterComponentData(
    component,
    "speciality_neurological_rehabilitation_stem_cell_therapy_for_autism",
    lang
  );
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );
  const { t } = useTranslation("common");
  const LinksBanSpecialityNeuroAutism = [
    {
      subLinkName: banners[33]?.subtitle?.split(">")[0]
        ? banners[33]?.subtitle?.split(">")[0]
        : t("autism.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[33]?.subtitle?.split(">")[1]
        ? banners[33]?.subtitle?.split(">")[1]
        : t("autism.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[33]?.subtitle?.split(">")[2]
        ? banners[33]?.subtitle?.split(">")[2]
        : t("autism.banner.title1"),
      subDash: ">",
      subLink: "/neurological-rehabilitation",
    },
    {
      subLinkName: banners[33]?.subtitle?.split(">")[3]
        ? banners[33]?.subtitle?.split(">")[3]
        : t("autism.banner.title2"),
      subDash: "",
      subLink: "/autism",
    },
  ];
  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(
                item?.neurologicalAutism ? item?.neurologicalAutism : "[]"
              )
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.neurologicalAutism
            ? specialityMetaTitles?.neurologicalAutism
            : t("autism.maintitle")}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.neurologicalAutism
              ? specialityMetaDesc?.neurologicalAutism
              : t("autism.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.neurologicalAutism
              ? specialityMetaKeywords?.neurologicalAutism
              : t("autism.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[33]?.heading
              ? banners[33]?.heading
              : t("autism.banner.title2")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[33]?.image ? banners[33]?.image : banner
          })`}
          imgSrc={banners[33]?.image ? banners[33]?.image : banner}
          LinksBan={LinksBanSpecialityNeuroAutism}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={autism?.image ? autism?.image : nemenucard1}
          alt={
            specialityAltTags?.neurologicalAutism
              ? specialityAltTags?.neurologicalAutism
              : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={
            autism?.heading ? autism?.heading : t("autism.textrev.headfirst")
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {autism?.description
                  ? autism?.description
                  : t("autism.textrev.discriptiontxt1")}
              </p>
              {sutismList?.length < 1 ? (
                <div class="span-department">
                  <ul>
                    <li>
                      <i class="fa fa-check"></i>
                      {t("autism.textrev.list1")}
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      {t("autism.textrev.list2")}
                    </li>
                  </ul>
                </div>
              ) : (
                sutismList?.map((item) => (
                  <div class="span-department">
                    <ul>
                      <li>
                        <i class="fa fa-check"></i>
                        {item?.name}
                      </li>
                    </ul>
                  </div>
                ))
              )}

              <br />
              <h2
                style={{ textTransform: "capitalize", color: "#333333" }}
                className="gray-headin"
              >
                {stemcell?.heading
                  ? stemcell?.heading
                  : t("autism.textrev.discriptiontxt2")}
              </h2>
              <hr
                style={{
                  borderBottom: "3px solid #AF5689",
                  margin: "10px 0px 15px 0px",
                  width: "80px",
                }}
              ></hr>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {stemcell?.description
                  ? stemcell?.description
                  : t("autism.textrev.discriptiontxt3")}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default Autism;
