import { combineReducers } from "redux";
import { pharmacyReducer } from "../reducers/pharmacyReducer";
import { authReducer } from "./authReducer";
import { patientReducer } from "./patientReducer";
import { administratorReducer } from "./administratorReducer";
import { staticReducer } from "./staticReducer";
import { doctorReducer } from "./doctorReducer";
import { nurseReducer } from "./nurseReducer";
import { cmsReducer } from "./cmsReducers";
const reducers = combineReducers({
  logIn: authReducer,
  static: staticReducer,
  patient: patientReducer,
  pharmacy: pharmacyReducer,
  administrator: administratorReducer,
  doctorReducer: doctorReducer,
  nurse: nurseReducer,
  cmsReducer: cmsReducer,
});
export default reducers;
