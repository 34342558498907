import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ActionBtn from '../../components/reuseablecomponents/ActionBtn'
import BootstrapCustomTable from '../../components/reuseablecomponents/BootstrapCustomTable'
import LoderContainer from '../../components/reuseablecomponents/LoderContainer'
import { approvereturnRequest, getreturnRequest } from '../../store/actions/administratorActions'

export default function ReturnRequest(props) {
    const dispatch = useDispatch()
    const loader = useSelector((state) => state.administrator.administrator_loader)
    const request = useSelector((state) => state.administrator.request)
    useEffect(() => {

        dispatch(getreturnRequest())
    }, [dispatch])

    const handleChange = async (value, item) => {
        if (value == "View") {
            props.history.push('/return-request-details', item)
        }
        else if (value == "Approve") {
            let apiData = {
                request_id: item?.id,
                status: { id: 2, name: "approved" }
            }
            let res = await dispatch(approvereturnRequest(apiData))
            if (res) {
                toast.success("Successfully Approved")
                dispatch(getreturnRequest())

            }
        } else if (value == "Decline") {
            let apiData = {
                request_id: item?.id,
                status: { id: 3, name: "rejected" }
            }
            let res = await dispatch(approvereturnRequest(apiData))
            if (res) {
                toast.success("Successfully Rejected")
                dispatch(getreturnRequest())

            }
        } else {

        }
    };
    return (
        <div>
            <div className="row mt-4">
                <div className="col">
                    <h4 className="patient-dashboard-heading">{"Request"}</h4>

                </div>
            </div>
            {loader ? (
                <LoderContainer />
            ) : (
                <>
                    <div className="row">
                        <div className="col-12">
                            <BootstrapCustomTable
                                columns={[
                                    { dataField: "request_id", text: "Request ID" },

                                    { dataField: "reason", text: "Reason" },
                                    { dataField: "order.order_id", text: "Order ID" },

                                    { dataField: "updated_by.name", text: "Create By" },
                                    {
                                        formatter: (cell, row, index) => {
                                            return (
                                                <span
                                                    style={{
                                                        color: row?.status.name == "pending" || row?.status.name == "rejected" ? 'red' : 'green',
                                                    }}
                                                >
                                                    {row?.status.name}
                                                </span>
                                            );
                                        },
                                        text: "Status",
                                    },
                                    {
                                        formatter: (cell, row, index) => {

                                            return (

                                                <ActionBtn

                                                    options={row?.status.name == "pending" ? [
                                                        "View", "Approve", "Decline"
                                                    ] : ["View"]}
                                                    handleChange={handleChange}
                                                    data={row}
                                                />

                                            );
                                        },
                                        text: "Actions",
                                    },

                                ]}
                                tabledata={request}
                            />
                        </div>
                    </div>
                </>
            )}

        </div>
    )
}
