import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { envelope, phone } from "../../../data";
import { appointmodal } from "../../../store/actions/authActions";
import useMediaQuery from "../../reuseablecomponents/MediaQueryHook";
import BookAppointmentModal from "../modal/BookAppointmentModal/BookAppointmentModal";
import { Button } from "react-bootstrap";

import { useTranslation } from "react-i18next";

import bnFlag from "../../../statics/icons/Flag-Bangladesh.jpg";
import saFlag from "../../../statics/icons/Saudi_Arabia.png";
import enFlag from "../../../statics/icons/uk.png";
import { setlocallang } from "../../../store/actions/staticActions";
import {
  readLanguageAction,
  SelectedLanguage,
} from "../../../store/actions/cmsAction";
import { Loader } from "react-bootstrap-typeahead";
import ReactFlagsSelect, { Bd } from "react-flags-select";
import { filterComponentData } from "../../../utils/functions";

const Header = (props) => {
  const { t, i18n } = useTranslation("common");
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const component = useSelector((state) => state?.cmsReducer?.components);
  const header = filterComponentData(component, "header", lang);

  let locvar =
    localStorage.getItem("lang") === null
      ? "US"
      : localStorage.getItem("lang").split("-")[1];

  useEffect(() => {}, [locvar]);

  const [display, setDisplay] = useState(true);

  const [selected, setSelected] = useState("US");

  const [Local, setLocal] = useState([]);
  const [customlocal, setCustomlocal] = useState([]);
  const [customlabel, setCustomlabel] = useState();

  const [langtoggle, setLangtoggle] = useState("en-US");

  const isDesktop = useMediaQuery("(min-width: 992px)");
  const appointModal = useSelector((state) => state?.logIn?.appointModal);
  const dispatch = useDispatch();

  const langs = useSelector((state) => state?.cmsReducer?.languages);

  const getids = () => {
    let localcode = [];
    let localcodebef = [];
    langs?.map((item) => {
      const firword = item?.locale;
      let id = firword.split("-")[1];
      let id0 = firword.split(id)[0];
      localcode?.push(id);
      localcodebef?.push(id0);
    });

    setLocal(localcode);
    setCustomlocal(localcodebef);
  };

  const customlabels = () => {
    let locallabel = {};
    langs?.map((item) => {
      const firword = item?.locale;
      let id = firword.split("-")[1];
      locallabel = {
        ...locallabel,
        [id]: item?.name,
      };
    });

    setCustomlabel(locallabel);
  };

  useEffect(() => {
    dispatch(readLanguageAction());
  }, []);

  useEffect(() => {
    getids();
    customlabels();
  }, [langs]);

  return (
    <>
      {isDesktop ? (
        <div style={{ backgroundColor: "#f2f2f2" }}>
          <div className="container containenavcustome">
            <div className="row">
              <div className="col-6 mt-3">
                <div className="top-nav">
                  <div className="section">
                    <div className="left-nav mx-4">
                      <div className="location-top">
                        <p className="text-blue">
                          <img
                            src={envelope}
                            style={{ width: "20px" }}
                            alt="mail icon"
                          />
                          &nbsp;{" "}
                          <a
                            style={{ textDecoration: "none", color: "#337ab7" }}
                            href={`mailto:${
                              header?.emailaddress
                                ? header?.emailaddress
                                : "info@toticell.com"
                            }`}
                          >
                            {header?.emailaddress
                              ? header?.emailaddress
                              : "info@toticell.com"}
                          </a>
                        </p>
                      </div>
                      <div className="location-top">
                        <p className="text-blue">
                          <img
                            src={phone}
                            style={{ width: "20px" }}
                            alt="phone icon"
                          />
                          &nbsp;{" "}
                          <span
                            className="roboto text-blue"
                            style={{ fontWeight: "500" }}
                          >
                            <a
                              style={{
                                textDecoration: "none",
                                color: "#337ab7",
                              }}
                              href={`tel:${
                                header?.contactnum
                                  ? header?.contactnum
                                  : "09606213234"
                              }`}
                            >
                              {" "}
                              {header?.contactnum
                                ? header?.contactnum
                                : "09606213234"}
                            </a>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 mt-3 d-flex justify-content-end">
                <div className="social-top">
                  <p>
                    <button
                      onClick={() => {
                        props?.history?.push("/signup", "- COVID 19");
                      }}
                      className="btn btn-yoga covid"
                    >
                      {header?.covid19 ? header?.covid19 : t("header.covid")}
                      <span className="roboto" style={{ fontSize: "15px" }}>
                        {header?.covid19 ? null : t("header.ninten")}
                      </span>
                    </button>
                  </p>
                </div>
                <div className="social-top mx-2">
                  <p>
                    <button
                      onClick={() => {
                        props?.history?.push("/signup", "- Tele-Consultation");
                      }}
                      className="btn btn-yoga telemedi"
                    >
                      {header?.teleconsul
                        ? header?.teleconsul
                        : t("header.teleconsultation")}
                    </button>
                  </p>
                </div>
                <div className="social-top mx-2">
                  <p>
                    <button
                      onClick={() => {
                        props?.history?.push("/signup", "- Appointment");
                      }}
                      className="btn btn-yoga telemedi"
                    >
                      {header?.appoint
                        ? header?.appoint
                        : t("header.appointment")}
                    </button>
                  </p>
                </div>

                <div className="social-top mx-2">
                  <p>
                    <Link
                      to="/internationalpatient"
                      className="btn btn-yoga telemedi"
                    >
                      {header?.internationalpatient
                        ? header?.internationalpatient
                        : t("header.intern")}
                    </Link>
                  </p>
                </div>
                <div className="social-top mx-2">
                  <p>
                    <Link to="/blogs" className="btn btn-yoga telemedi">
                      {/* {header?.internationalpatient
                        ? header?.internationalpatient
                        : t("header.intern")} */}
                      Blogs
                    </Link>
                  </p>
                </div>

                {/* {display ? <Loader
                  height={20}
                  width={20}
                  type="ThreeDots"
                  color="#af5689"
                /> : */}
                <div className="social-top mx-2 country-drop">
                  <ReactFlagsSelect
                    countries={Local}
                    selected={locvar}
                    optionsSize={13}
                    selectedSize={13}
                    customLabels={customlabel}
                    onSelect={(code) => {
                      localStorage.removeItem("lang");
                      dispatch(
                        SelectedLanguage(
                          langs.filter((item) => item?.locale.includes(code))[0]
                            ?.locale
                        )
                      );
                      localStorage.setItem(
                        "lang",
                        langs.filter((item) => item?.locale.includes(code))[0]
                          ?.locale
                      );
                      setSelected(code);
                    }}
                  />

                  {/* <div className="dropdown">
                    <select
                      style={{
                        color: "white",
                        background:
                          "linear-gradient(90deg, #476b8f 0%, #af5689 100%)",
                        fontWeight: "bold",
                        border: "3px solid",
                        borderColor: "white",
                        borderRadius: "15px",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      defaultValue={"en-US"}
                      onChange={(e) => {
                        setLangtoggle(e.target.value);
                        dispatch(SelectedLanguage(e.target.value));
                      }}
                    >
                      {langs?.map((item) => {
                        return (
                          <option className="opt p-3" value={item?.locale}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <BookAppointmentModal
        show={appointModal}
        onHide={() => dispatch(appointmodal(false))}
        {...props}
      />
    </>
  );
};

export default Header;
