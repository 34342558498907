import React from "react";
import { Link } from "react-router-dom";
const DashboardBtnList = ({
  label,
  className,
  bntStyle,
  labelStyle,
  onClick,
  routePath,
  notificationCount,
  shownotification,
}) => {
  return (
    <div
      className={`dashboardBtnList-item ${className}  btn `}
      style={{ bntStyle }}
      onClick={onClick}
    >
      {shownotification && notificationCount > 0 ? (
        <span
          style={{
            position: "absolute",
            top: "-10px",
            right: "0px",
            background: "red",
            padding: "5px 12px",
            borderRadius: "50%",
            color: "white",
          }}
        >
          {notificationCount}
        </span>
      ) : null}

      <div  style={{ labelStyle }}>{label}</div>
    </div>
  );
};

export default DashboardBtnList;
