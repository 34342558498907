import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import fail from "../../statics/toticellimages/cross.png"
import { getUser } from "../../store/actions/authActions";
const PaymentCancelled = (props) => {
  const userRole = JSON.parse(localStorage.getItem("toticelluserRole"));
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUser());

  }, [])

  const profileHandler = () => {
    if (userRole === "administrator") {
      props?.history.push("/administrator-dashboard");
    } else if (userRole === "doctor") {
      props?.history.push("/doctor-dashboard");
    } else if (userRole === "nurse") {
      props?.history.push("/nurse-dashboard");
    } else if (userRole === "pharmacy") {
      props?.history.push("/pharmacy-dashboard");
    } else if (userRole === "patient") {
      props?.history.push("/patient-dashboard");
    }
  };
  return (
    <>
      <Helmet>
        <title>
          Transaction Cancelled - Toticell
        </title>
      </Helmet>
      <div className="center-childrens" style={{ height: "100vh" }}>
        <div
          className="paymentFailBackground w-100 d-none d-lg-flex"
          style={{ height: "100vh", display: "flex", alignItems: "center" }}
        >
          <div className="col-lg-6 text-center ml-5 pl-5 d-none d-lg-block">
            <span style={{ color: "#af5689", fontSize: "70px" }}>
              Transaction Cancelled
            </span>
            <span
              style={{ display: "block", fontSize: "34px", fontWeight: "400" }}
              className="mb-4"
            >
              Something Went Wrong
            </span>
            <span
              style={{ display: "block" }}
              className="btn btn-primary  w-50 m-auto"
              onClick={profileHandler}
            >
              Home
            </span>
          </div>
        </div>
        <div className=" row  w-100 p-3 d-lg-none d-md-flex   ">
          <div className="col-md-6    containerWithShadow p-4  d-lg-none text-center m-auto ">
            <img
              src={fail}
              alt="success"
              style={{ width: "150px", height: "150px" }}
            />
            <span
              style={{ display: "block", color: "#af5689", fontSize: "30px" }}
            >
              Transaction Cancelled
            </span>
            <span
              style={{ display: "block", color: "#af5689", fontSize: "16px" }}
              className="py-3"
            >
              Something Went Wrong
            </span>
            <span
              style={{ display: "block" }}
              className="btn btn-primary mt-3  w-50 m-auto"
              onClick={profileHandler}
            >
              Home
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentCancelled;
