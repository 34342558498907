import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { rescheduleDoctorAppointment } from "../../../../store/actions/administratorActions";

const DoctorAppointmentReschedule = (props) => {
  const {t} = useTranslation("common")
  const [reson, setreson] = useState("");
  const dispatch = useDispatch();
  const clickHandler = () => {
    if (reson === "") {
      toast.error(t("dashboards.alldashboardkeys.tablekeys.pleaseprovideareason"));
    } else {
      const apiData = {
        id: props?.data?.id,
        description: reson,
      };
      dispatch(rescheduleDoctorAppointment(apiData));
      props?.onHide();
    }
  };
  // {t("dashboards.alldashboardkeys.tablekeys.no")}
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="dashboardBtnList-item-active">
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="text-white">{t("dashboards.alldashboardkeys.tablekeys.appointmentreschedule")}</span>
        </Modal.Title>
        <button type="button" class="close" onClick={props?.onHide}>
          <span aria-hidden="true" className="text-white">×</span>
          <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>
        </button>
      </Modal.Header>
        <Modal.Body>
          <p>{t("dashboards.alldashboardkeys.tablekeys.pleaseprovidereason")}</p>
          <textarea
            value={reson}
            className="w-100"
            style={{ maxHeight: "100px", minHeight: "100px" }}
            onChange={(e) => setreson(e.target.value)}
          />
          <input
            type="submit"
            value={t("dashboards.alldashboardkeys.tablekeys.rescheduleappointment")}
            className="btn btn-success btn-block"
            onClick={clickHandler}
            // style={{ width: "85%", textAlign: "center" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DoctorAppointmentReschedule;
