import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Specialitycard from "../../../../components/layouts/cards/SpeciallityCard/Specialitycard";
import {
  covid1,
  banner,
  LinksBanSpecialityCovid,
  comenucard1,
  comenucard2,
  comenucard3,
  comenucard4,
} from "../../../../data";
import { filterComponentData } from "../../../../utils/functions";

const CovidManagement = () => {
  const { t } = useTranslation("common");
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const cards = filterComponentData(
    component,
    "speciality_covid_menu_cards_",
    lang
  );
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );
  console.log("specialityAltTags", specialityAltTags);

  const LinksBanSpecialityCovid = [
    {
      subLinkName: banners[17]?.subtitle?.split(">")[0]
        ? banners[17]?.subtitle?.split(">")[0]
        : t("covidmanagement.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[17]?.subtitle?.split(">")[1]
        ? banners[17]?.subtitle?.split(">")[1]
        : t("covidmanagement.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[17]?.subtitle?.split(">")[2]
        ? banners[17]?.subtitle?.split(">")[2]
        : t("covidmanagement.banner.title1"),
      subDash: "",
      subLink: "/covid-19-management",
    },
  ];

  const covid1 = [
    {
      img: comenucard1,
      head1: t("covidmanagement.pagemenucard.card1.text"),
      linkroute: "/molecular-diagnosis",
    },
    {
      img: comenucard2,
      head1: t("covidmanagement.pagemenucard.card2.text"),
      linkroute: "/serology",
    },
    {
      img: comenucard3,
      head1: t("covidmanagement.pagemenucard.card3.text"),
      linkroute: "/convalescent-plasma-therapy",
    },
    {
      img: comenucard4,
      head1: t("covidmanagement.pagemenucard.card4.text"),
      linkroute: "/stem-cell-therapy",
    },
  ];

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.covidMain ? item?.covidMain : "[]")
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.covidMain
            ? specialityMetaTitles?.covidMain
            : t("covidmanagement.maintitle")}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.covidMain
              ? specialityMetaDesc?.covidMain
              : t("covidmanagement.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.covidMain
              ? specialityMetaKeywords?.covidMain
              : t("covidmanagement.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[17]?.heading
              ? banners[17]?.heading
              : t("covidmanagement.banner.title1")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[17]?.image ? banners[17]?.image : banner
          })`}
          imgSrc={banners[17]?.image ? banners[17]?.image : banner}
          LinksBan={LinksBanSpecialityCovid}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container">
          <div className="row ">
            {cards?.length < 1
              ? covid1.map((item, index) => (
                  <Specialitycard
                    img={item?.img}
                    alt={
                      specialityAltTags?.covidMain
                        ? specialityAltTags?.covidMain
                        : ""
                    }
                    head1={item?.head1}
                    linkroute={item?.linkroute}
                  />
                ))
              : cards?.map((item, index) => (
                  <Specialitycard
                    alt={
                      specialityAltTags?.covidMain
                        ? specialityAltTags?.covidMain
                        : ""
                    }
                    img={item?.image}
                    head1={item?.heading}
                    linkroute={item?.link}
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CovidManagement;
