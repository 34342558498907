import React, { useState } from "react";
import { useSelector } from "react-redux";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";

import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import { cancelAppointment, patientAppointments } from "../../store/actions/patientActions";
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useTranslation } from "react-i18next";
import { readPastAppointmentAction } from "../../store/actions/administratorActions";
import RefundModal from "../../components/layouts/modal/refundAmountModal/RefundModal";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import AlertRefund from "../../components/layouts/modal/AlertRefund/AlertRefund";

export default function AdministratorPastAppointment(props) {
    const { t } = useTranslation("common")
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [id, setID] = useState("")
    const [showModal, setShowModal] = useState(false)
    const date = new Date()

    const appointments = useSelector(
        (state) => state?.administrator?.past
    );
    const loader = useSelector((state) => state?.administrator?.administrator_loader);


    const handleChange = async (value, row) => {
        if (value === "View") {
            const item = { ...row, from: "PastAppointment" };
            props?.history?.push("/patient-dashboard-appointment-details", { row, from: "PastAppointment" });
        }
        else if (value == "Cancel") {
            let res = await dispatch(cancelAppointment({ appointment_id: row?.id }))
            dispatch(readPastAppointmentAction());
            
        } else if (value == "Cancel/Refund") {
            setID(row?.id)
            setShow(!show)

        } else {

        }
    };
    const bookAnAppointment = () => {
        props.history.push('/book-appointment')
    }
    const defaultSorting = [{
        dataField: "date",
        order: "asc",
    },]
    return (
        loader ? (
            <LoderContainer />
        ) : (
            <>
                <div className="w-100">
                    <div className="row">
                        <div className="col-6">
                            <div className="p-3">
                                <span className="dashboardHeadingText">{t("dashboards.alldashboardkeys.tablekeys.pastappointments")}</span>
                            </div>
                        </div>


                    </div>
                    <BootstrapCustomTable
                        defaultsort={defaultSorting}

                        columns={[
                            {
                                formatter: (cell, row, index) => {
                                    return <span>{index + 1}</span>;
                                },
                                text: "Sr.",
                            },
                            {

                                dataField: "date",
                                sort: true,
                                text: t("dashboards.alldashboardkeys.tablekeys.date"),
                            },
                            {
                                formatter: (cell, row) => {
                                    return <span>{`${row?.slot?.from} - ${row?.slot?.to}`}</span>;
                                },
                                text: t("dashboards.alldashboardkeys.tablekeys.time"),
                            },

                            { dataField: "doctor_details.name", text: t("dashboards.alldashboardkeys.tablekeys.doctorname") },
                            { dataField: "consultation_type.name", text: t("dashboards.alldashboardkeys.tablekeys.consultationtype") },
                            { dataField: "condition.name", text: "Condition" },

                            {
                                formatter: (cell, row) => {
                                    return <span style={{ color: row?.status?.name == "booked" ? 'red' : row?.status?.name == "confirmed" ? 'green' : '#af5689' }}>{row?.status?.name}</span>;
                                },
                                text: t("dashboards.alldashboardkeys.tablekeys.status"),
                            },
                            {
                                formatter: (cell, row, index) => {
                                    return (
                                        row?.status?.name == "booked"   ?
                                            <ActionBtn
                                                options={["Cancel"]}
                                                handleChange={handleChange}
                                                data={row}
                                            />
                                            : row?.status?.name == "confirmed" ? <ActionBtn
                                                options={["Cancel", "Cancel/Refund"]}
                                                handleChange={handleChange}
                                                data={row}
                                            /> : <ActionBtn
                                                options={[]}
                                                handleChange={handleChange}
                                                data={row}
                                            />

                                    );
                                },
                                text: t("dashboards.alldashboardkeys.tablekeys.action"),
                            },
                        ]}
                        tabledata={appointments ? appointments : []}
                    />
                </div>
                <RefundModal
                    show={show}
                    id={id}
                    onHide={() => setShow(false)}
                />
                <AlertRefund
                    show={showModal}
                    onHide={() => setShowModal(false)}

                />
            </>
        )

    )
}
