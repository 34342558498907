import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../components/layouts/bannerImgComponents/BannerImgComponents";
import PageMenuCard from "../../components/layouts/cards/PageMenuCard/PageMenuCard";
import {
  Aboutcard1,
  Aboutcard2,
  banner,
  LinksBanTreatment,
  treatmentcard,
} from "../../data";
import { filterComponentData } from "../../utils/functions";

const Treatment = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);
  const treatment = filterComponentData(component, "our_treatment_card", lang);
  const treatmentMetaTitles = filterComponentData(
    component,
    "treatment_meta_titles",
    lang
  );
  const treatmentMetaDesc = filterComponentData(
    component,
    "treatment_meta_descriptions",
    lang
  );
  const treatmentSchemaMarkup = filterComponentData(
    component,
    "treatment_schema_markups",
    lang
  );
  const treatmentMetaKeywords = filterComponentData(
    component,
    "treatment_meta_keywords",
    lang
  );
  const treatmentVerification = filterComponentData(
    component,
    "treatment_verification_meta_tags	",
    lang
  );
  const { t } = useTranslation("common");
  const LinksBanTreatment = [
    {
      subLinkName: banners[5]?.subtitle?.split(">")[0]
        ? banners[5]?.subtitle?.split(">")[0]
        : t("treatments.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[5]?.subtitle?.split(">")[1]
        ? banners[5]?.subtitle?.split(">")[1]
        : t("treatments.banner.title"),
      subDash: "",
      subLink: "/treatment",
    },
  ];
  const treatmentcard = [
    {
      id: 0,
      imageURL: Aboutcard2,
      text: t("treatments.pagemenucard.card1.text"),
      link: "/prp-therapy",
    },
    {
      id: 1,
      imageURL: Aboutcard2,
      text: t("treatments.pagemenucard.card2.text"),
      link: "/bmc-therapy",
    },
    {
      id: 2,
      imageURL: Aboutcard1,
      text: t("treatments.pagemenucard.card3.text"),
      link: "/svf-therapy",
    },
    {
      id: 3,
      imageURL: Aboutcard1,
      text: t("treatments.pagemenucard.card4.text"),
      link: "/msc-therapy",
    },
    {
      id: 4,
      imageURL: Aboutcard1,
      text: t("treatments.pagemenucard.card5.text"),
      link: "/mnc-therapy",
    },
    {
      id: 5,
      imageURL: Aboutcard1,
      text: t("treatments.pagemenucard.card6.text"),
      link: "/eswt-therapy",
    },
    {
      id: 6,
      imageURL: Aboutcard1,
      text: t("treatments.pagemenucard.card7.text"),
      link: "/hbot-therapy",
    },
    {
      id: 7,
      imageURL: Aboutcard1,
      text: t("treatments.pagemenucard.card8.text"),
      link: "/ct-therapy",
    },
    {
      id: 8,
      imageURL: Aboutcard1,
      text: "Platelet Lysate",
      link: "/platelet-lysate",
    },
    {
      id: 9,
      imageURL: Aboutcard1,
      text: "Hyperthermia Therapy",
      link: "/hyperthermia-therapy",
    },
  ];
  const verificationsTags = treatmentVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.mainTreatmentName,
      content: item?.mainTreatmentContent,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}

        {treatmentSchemaMarkup?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.mainTreatment ? item?.mainTreatment : "[]")
            )}
          </script>
        ))}
        <title>
          {treatmentMetaTitles?.mainTreatment
            ? treatmentMetaTitles?.mainTreatment
            : t("treatments.maintitle")}
        </title>
        <meta
          name="description"
          content={
            treatmentMetaDesc?.mainTreatment
              ? treatmentMetaDesc?.mainTreatment
              : t("speciality.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            treatmentMetaKeywords?.mainTreatment
              ? treatmentMetaKeywords?.mainTreatment
              : t("speciality.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={banners[5]?.heading ? banners[5]?.heading : `Treatment`}
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[5]?.image ? banners[5]?.image : banner
          })`}
          imgSrc={banners[5]?.image ? banners[5]?.image : banner}
          LinksBan={LinksBanTreatment}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container">
          <div className="row ">
            {treatment?.length < 1
              ? treatmentcard.map((item, index) => (
                  <PageMenuCard
                    className="col-lg-3 col-md-6"
                    key={index + 1}
                    img={item?.imageURL}
                    classNamemenucard={"pt-4"}
                    heading={item?.text}
                    linkroute={item?.link}
                  />
                ))
              : treatment?.map((item, index) => (
                  <PageMenuCard
                    className="col-lg-3 col-md-6"
                    key={index + 1}
                    img={item?.icon}
                    classNamemenucard={"pt-4"}
                    heading={item?.name}
                    linkroute={item?.link}
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Treatment;
