import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import { banner } from "../../../../../data";
import { filterComponentData } from "../../../../../utils/functions";

const CommunityBanking = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );
  const cards = filterComponentData(
    component,
    "speciality_cord_community_banking",
    lang
  );
  const { t } = useTranslation("common");
  const LinksBanSpecialityStcbCordBloodBanking = [
    {
      subLinkName: banners[40]?.subtitle?.split(">")[0]
        ? banners[40]?.subtitle?.split(">")[0]
        : t("communitybanking.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[40]?.subtitle?.split(">")[1]
        ? banners[40]?.subtitle?.split(">")[1]
        : t("communitybanking.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[40]?.subtitle?.split(">")[2]
        ? banners[40]?.subtitle?.split(">")[2]
        : t("communitybanking.banner.title1"),
      subDash: ">",
      subLink: "/stem-cell-banking",
    },
    {
      subLinkName: banners[40]?.subtitle?.split(">")[3]
        ? banners[40]?.subtitle?.split(">")[3]
        : t("communitybanking.banner.title2"),
      subDash: "",
      subLink: "/cord-blood-banking",
    },
  ];
  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.stemCommunity ? item?.stemCommunity : "[]")
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.stemCommunity
            ? specialityMetaTitles?.stemCommunity
            : t("communitybanking.maintitle")}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.stemCommunity
              ? specialityMetaDesc?.stemCommunity
              : t("communitybanking.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.stemCommunity
              ? specialityMetaKeywords?.stemCommunity
              : t("communitybanking.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[40]?.heading
              ? banners[40]?.heading
              : t("communitybanking.banner.title2")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[40]?.image ? banners[40]?.image : banner
          })`}
          imgSrc={banners[40]?.image ? banners[40]?.image : banner}
          LinksBan={LinksBanSpecialityStcbCordBloodBanking}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        {/* <TextAndImageSection
        imgCol="col-lg-12"
        imgHtml={
            
        }
        reverse={true}
      /> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>
                {cards?.description
                  ? cards?.description
                  : t("communitybanking.textrev.discriptiontxt1")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunityBanking;
