import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import DashboardBtnList from "../../components/reuseablecomponents/DashboardBtnList";
import { getAllOrders } from "../../store/actions/pharmacyActions";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import { checkPaidOrUnpaid, comaSeperated } from "../../utils/functions";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import { useTranslation } from "react-i18next";

const PharmacyOrders = (props) => {
  const { t } = useTranslation("common")
  const loader = useSelector((state) => state?.pharmacy?.loader);
  const orders = useSelector((state) => state?.pharmacy?.orders);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);
  const handleChange = (value, item) => {
    if (value === t("dashboards.alldashboardkeys.tablekeys.viewbtn")) {
      props.history.push("/pharmacy-Order-details", item);
    }
  };
  // {t("dashboards.alldashboardkeys.tablekeys.delete")}

  return (
    <>
      <div className="row mt-4">
        <div className="col-lg-9 col-md-8 col-xs-12">
          <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.order")}</h4>
        </div>
      </div>

      <div className="row">
        {loader ? (
          <div className="w-100 center-children-class">
            <LoderContainer />
          </div>
        ) : (
          <BootstrapCustomTable
            columns={[
              { dataField: "ordered_by.name", text: t("dashboards.alldashboardkeys.tablekeys.orderby") },
              { dataField: "ordered_by.phone_number", text: t("dashboards.alldashboardkeys.tablekeys.contactnumber") },
              { dataField: "delivery_status.name", text: t("dashboards.alldashboardkeys.tablekeys.deliverystatus") },
              {
                formatter: (cell, row, index) => {
                  return <span>{comaSeperated(row?.refunded_amount)}</span>;
                },
                text: "Refund Amount",
              },
              {
                formatter: (cell, row, index) => {
                  return <span>{comaSeperated(row?.total_amount)}</span>;
                },
                text: t("dashboards.alldashboardkeys.tablekeys.ammount"),
              },
              {
                formatter: (cell, row, index) => {
                  return (
                    <span
                      style={{
                        color: checkPaidOrUnpaid(row?.paid_status.name),
                      }}
                    >
                      {row?.paid_status.name?.toUpperCase()}
                    </span>
                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.paymentstatus"),
              },

              {
                formatter: (cell, row, index) => {
                  return (
                    <ActionBtn
                      options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                      handleChange={handleChange}
                      data={row}
                    />

                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.action"),
              },
            ]}
            tabledata={orders ? orders : []}
          />
        )}
      </div>
    </>
  );
};

export default PharmacyOrders;
