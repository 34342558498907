import { React, useState, useEffect } from "react";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import { useDispatch, useSelector } from "react-redux";
import { getdoctorRescheduleRequest } from "../../store/actions/administratorActions";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import SlotsModal from "../../components/layouts/modal/SlotModal/SlotsModal";
import DeclineReqscheduleRequest from "../../components/layouts/modal/rescheduleAppointmentRequestDecline/RescheduleAppointmentDeclineRequest";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import { useTranslation } from "react-i18next";

const AdministratorRescheduleContainer = (props) => {
  const { t } = useTranslation("common");
  const [show, setShow] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);

  const [currentSelectid, setCurrentSelectid] = useState("");
  const [currentSelectedrequstid, setCurrentSelectedrequstid] = useState("");
  const dispatch = useDispatch();
  const showModal = () => setShow(!show);
  const showDeclineModalToggle = () => setShowDeclineModal(!showDeclineModal);
  useEffect(() => {
    getDoctorRescheduleRequest();
    // eslint-disable-next-line
  }, [dispatch]);
  const getDoctorRescheduleRequest = async () => {
    await dispatch(getdoctorRescheduleRequest());
  };
  const doctorRescheduleRequest = useSelector(
    (state) => state.administrator.doctorRequests
  );
  const loading = useSelector(
    (state) => state.administrator.administrator_loader
  );

  const handleChange = (value, item) => {
    if (value === t("dashboards.alldashboardkeys.tablekeys.approve")) {
      setCurrentSelectid(item?.requested_by?.id);
      setCurrentSelectedrequstid(item?.id);
      showModal();
    } else if (value === t("dashboards.alldashboardkeys.tablekeys.decline")) {
      setCurrentSelectedrequstid(item?.id);
      setShowDeclineModal(true);
    }
    // props.history.push('',item)
  };
  const defaultSorting = [
    {
      dataField: "date",
      order: "asc",
    },
  ];
  return (
    <div className="w-100">
      <div className="p-3">
        <span className="dashboardHeadingText">
          {t("dashboards.alldashboardkeys.tablekeys.reschedule")}
        </span>
      </div>
      {loading ? (
        <LoderContainer />
      ) : (
        <BootstrapCustomTable
          columns={[
            {
              dataField: "description",
              text: t("dashboards.alldashboardkeys.tablekeys.reason"),
            },
            {
              dataField: "requested_by.name",
              text: t("dashboards.alldashboardkeys.tablekeys.requestbyname"),
            },
            {
              dataField: "requested_by.email_address",
              text: t("dashboards.alldashboardkeys.tablekeys.requestbyemail"),
            },

            {
              formatter: (cell, row, index) => {
                return (
                  <span
                    style={{
                      color: row?.status?.name === "approved" ? "green" : "red",
                    }}
                  >
                    {row?.status?.name?.toUpperCase()}
                  </span>
                );
              },
              text: t("dashboards.alldashboardkeys.tablekeys.status"),
            },
            {
              formatter: (cell, row, index) => {
                return (
                  <div className="">
                    {row?.status?.name === "pending" ? (
                      <ActionBtn
                        options={[
                          t("dashboards.alldashboardkeys.tablekeys.approve"),
                          t("dashboards.alldashboardkeys.tablekeys.decline"),
                        ]}
                        handleChange={handleChange}
                        data={row}
                      />
                    ) : // <select
                    //   className="action-select-border col-12 col-md-6 w-100"
                    //   onChange={(e) => handleChange(e, row)}
                    //   value="Action :"
                    // >
                    //   <option selected>Actions:</option>
                    //   <>
                    //     <option value="1">Approve</option>
                    //     <option value="2">Decline</option>
                    //   </>
                    // </select>
                    null}
                  </div>
                );
              },
              text: t("dashboards.alldashboardkeys.tablekeys.action"),
            },
          ]}
          tabledata={doctorRescheduleRequest}
          defaultsort={[{ dataField: "status.name", order: "asc" }]}
        />
      )}

      <SlotsModal
        show={show}
        onHide={showModal}
        data={currentSelectid}
        data1={currentSelectedrequstid}
        {...props}
      ></SlotsModal>
      <DeclineReqscheduleRequest
        show={showDeclineModal}
        onHide={showDeclineModalToggle}
        data={currentSelectedrequstid}
      />
    </div>
  );
};
export default AdministratorRescheduleContainer;
