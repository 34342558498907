import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import UploadReportModal from "../../components/layouts/modal/uploadReportModal copy/UploadReportModal";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import DashboardBtn from "../../components/reuseablecomponents/DashboardBtn";
import DashBoardMainHading from "../../components/reuseablecomponents/DashBoardMainHading";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import { myMedicalRecords } from "../../store/actions/patientActions";
const DoctorsPatientMedicalRecords = (props) => {
    const id = props.location.state
    const { t } = useTranslation("common")
    const [selectedTab, setselectedTab] = useState(t("dashboards.alldashboardkeys.tablekeys.prescription"));
    const loader = useSelector((state) => state?.patient?.patientLoader);
    const medicalRecords = useSelector((state) => state?.patient?.medicalRecords);
    const [tableData, settableData] = useState([]);
    const dispatch = useDispatch();
    let tableDataFormatPrescription = [];
    medicalRecords?.prescriptions?.map((item, index) =>
        tableDataFormatPrescription.push({
            sr: index,
            medical_record_id: item?.medical_record_id,
            created_at: moment.unix(item?.created_at)?.format("YYYY-MM-DD"),
            prescription_url: item?.prescription_url
        })
    );
    let tableDataFormatReports = [];
    medicalRecords?.reports?.map((item, index) =>
        tableDataFormatReports.push({
            sr: index,
            medical_record_id: item?.medical_record_id,
            created_at: moment.unix(item?.created_at)?.format("YYYY-MM-DD"),
            report_url: item?.report_url
        })
    );
    useEffect(() => {
        dispatch(myMedicalRecords({ id: id }));
        settableData(tableDataFormatPrescription);
        // eslint-disable-next-line
    }, [dispatch]);
    useEffect(() => {
        settableData(tableDataFormatPrescription);

    }, [medicalRecords])
    const tabHandler = (item) => {
        setselectedTab(item);
        if (item === t("dashboards.alldashboardkeys.tablekeys.prescription")) {
            settableData([])
            settableData(tableDataFormatPrescription);
        } else {
            settableData([])
            settableData(tableDataFormatReports);
        }
    };

    const handleChange = (value, data) => {
        if (value == "View") {

            if (selectedTab === t("dashboards.alldashboardkeys.tablekeys.prescription")) {
                window.open(data?.prescription_url);
                window.focus();
            }
            if (selectedTab === t("dashboards.alldashboardkeys.tablekeys.reports")) {
                window.open(data?.report_url);
                window.focus();
            }
        }
    };
    // t("dashboards.alldashboardkeys.tablekeys.reports")
    return (
        <>
            <div style={{ backgroundColor: "#f9f9f9" }}>
                <DashBoardMainHading title={t("dashboards.alldashboardkeys.tablekeys.appointmentdetail")} />
                <div className="container"> <div className="row ">
                    <div className="col-xl-8 col-md-8 col-12 px-0 mx-5">
                        <div
                            className="btn btnSquare"
                            style={{ width: "150px" }}
                            onClick={() => {
                                props.history.push("/doctor-dashboard");
                            }}
                        >
                            <i className="fas fa-arrow-left"></i> {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
                        </div>
                    </div>
                </div></div>
                <div className="row center-childrens mt-3 pb-5 center-children-class">
                    <div className="col-xl-8 col-md-8 col-12  p-4">
                        <div className="w-100">
                            <div className="p-3">
                                <span className="dashboardHeadingText">{t("dashboards.alldashboardkeys.tablekeys.medicalrecords")}</span>
                            </div>
                            <div className="row px-3 mb-3">
                                {[t("dashboards.alldashboardkeys.tablekeys.prescription"), t("dashboards.alldashboardkeys.tablekeys.reports")]?.map((item, index) => (
                                    <div className="col-lg-3 col-md-4 col-12 mt-2" key={index + 1}>
                                        <DashboardBtn
                                            label={item}
                                            active={selectedTab === item}
                                            onClick={tabHandler}
                                        />
                                    </div>
                                ))}
                            </div>
                            {loader ? (
                                <LoderContainer />
                            ) : (
                                <>
                                    <BootstrapCustomTable
                                        columns={[
                                            {
                                                formatter: (cell, row, index) => {
                                                    return <span>{index + 1}</span>;
                                                },
                                                dataField: "sr.",
                                                text: "Sr.",
                                            },
                                            { dataField: "medical_record_id", text: t("dashboards.alldashboardkeys.tablekeys.recordId") },
                                            { dataField: "created_at", text: t("dashboards.alldashboardkeys.tablekeys.date") },
                                            {
                                                formatter: (cell, row, index) => {
                                                    return (
                                                        <ActionBtn
                                                            options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                                                            handleChange={handleChange}
                                                            data={row}
                                                        />

                                                    );

                                                },
                                                text: t("dashboards.alldashboardkeys.tablekeys.action"),

                                            },
                                        ]}
                                        tabledata={tableData || []}
                                    />
                                </>
                            )}


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DoctorsPatientMedicalRecords;
