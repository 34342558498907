import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { BASEURL } from "../../../../services/HttpProvider";
const ApplyJobModal = (props) => {

  const {t} = useTranslation("common")

  const [file, setFile] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    let formdata = new FormData();
    if (file === undefined || file === null) {
      toast.error(t("toastmessages.pleaseuploadreport"));
      return;
    } else {
      formdata.append("file", file[0]);
      formdata.append(
        "email_body",
        `<Span>Name: ${data?.username}</Span> <br/> <Span>Email: ${data?.email}</Span> <br /> 
      <Span>Phone: ${data?.phone}</Span> <br />  <Span>Message: ${data?.message}</Span> <br />`
      );
      formdata.append("email_subject", `${data?.subject}`);
      let res = await axios.post(`${BASEURL}send/apply-job/email`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res?.data?.response_code === 200) {
        toast.success(t("toastmessages.emailsentsuccess"));
        props?.onHide();
      } else {
        toast.success(res?.data?.response_message);
      }
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
          {t("dashboards.alldashboardkeys.tablekeys.applyonline")}
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true">×</span>
            <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
              <div className="row">
                <div className="col-6">
                  <div class="form-group">
                    <input
                      type="text"
                      id="username"
                      name="username"
                      className="jobmodalInput"
                      placeholder={t("dashboards.alldashboardkeys.tablekeys.name")}
                      required="required"
                      {...register("username", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.username?.message ? (
                      <div className="text-error">
                        {errors?.username?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div class="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="jobmodalInput"
                      placeholder={t("dashboards.alldashboardkeys.tablekeys.email")}
                      required="required"
                      {...register("email", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.email?.message ? (
                      <div className="text-error">{errors?.email?.message}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div class="form-group">
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      className="jobmodalInput"
                      placeholder={t("dashboards.alldashboardkeys.tablekeys.mobilenumber")}
                      required="required"
                      {...register("phone", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.phone?.message ? (
                      <div className="text-error">{errors?.phone?.message}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div class="form-group">
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      className="jobmodalInput"
                      placeholder={t("dashboards.alldashboardkeys.tablekeys.applyforposition")}
                      required="required"
                      {...register("subject", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.subject?.message ? (
                      <div className="text-error">
                        {errors?.subject?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div class="form-group">
                    <input
                      type="file"
                      id="resume"
                      name="resume"
                      className="jobmodalInput"
                      placeholder={t("dashboards.alldashboardkeys.tablekeys.uploadresume")}
                      required="required"
                      style={{ paddingTop: "10px" }}
                      onChange={(e) => {
                        setFile(e.target.files);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div class="form-group">
                    <textarea
                      type="file"
                      id="message"
                      name="message"
                      className="jobmodalInput"
                      placeholder={t("dashboards.alldashboardkeys.tablekeys.message")}
                      required="required"
                      style={{ paddingTop: "10px", minHeight: "100px" }}
                      {...register("message", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.message?.message ? (
                      <div className="text-error">
                        {errors?.message?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <input
                type="submit"
                value={t("dashboards.alldashboardkeys.tablekeys.apply")}
                className="btnSquare btn-block mx-auto mt-3"
                style={{ width: "85%", textAlign: "center" }}
              />
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApplyJobModal;
