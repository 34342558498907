export const pharmacyConstants = {
  GET_ALL_MEDICINES_LOADING:'GET_ALL_MEDICINES_LOADING',
  GET_ALL_MEDICINES_SUCCESS: "GET_ALL_MEDICINES_SUCCESS",
  GET_ALL_MEDICINES_ERROR:'GET_ALL_MEDICINES_ERROR',
  GET_ALL_ORDERS_LOADING: "GET_ALL_ORDERS_LOADING",
  GET_ALL_ORDERS_SUCCESS:'GET_ALL_ORDERS_SUCCESS',
  GET_ALL_ORDERS_ERROR:'GET_ALL_ORDERS_ERROR',
  SET_SELECTED_MEDI : "SET_SELECTED_MEDI",
  ASSIGN_ORDER_LOADING:'ASSIGN_ORDER_LOADING',
  ASSIGN_ORDER_SUCESS:"ASSIGN_ORDER_SUCESS",
  VIEW_DELIVERY_STAFF_SUCCESS:'VIEW_DELIVERY_STAFF_SUCCESS',
  VIEW_DELIVERY_STAFF_LOADING:'VIEW_DELIVERY_STAFF_LOADING',

};
