import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Testemonials from "../../../../../components/layouts/testimonials/Testemonials";
import TabBtnList from "../../../../../components/layouts/tabbuttons/DashboardBtnList";
import TabHandlerTextwithImage from "../../../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  cttreatment,
  imghy1,
  res5,
  LinksBanTreatmentCt,
  tabhandctc1,
  tabhandctc2,
  tabhandctc3,
  tabhandctc4,
} from "../../../../../data";
import HeadingWithBottomBar from "../../../../../components/reuseablecomponents/HeadingWithBottomBar";
import useMediaQuery from "../../../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Toggleform from "../../../../../components/layouts/toggleform/toggleform";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../../../utils/functions";

const UlcerativeColitis = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );

  const stories = filterComponentData(component, "our_stories", lang);
  const enquiry = filterComponentData(
    component,
    "cupping_therapy_ct_make_an_enquiry_",
    lang
  );
  const therapy = filterComponentData(component, "ulcerative_colitis", lang);
  const { t } = useTranslation("common");

  const LinksBanTreatmentCt = [
    {
      subLinkName: banners[51]?.subtitle?.split(">")[0]
        ? banners[51]?.subtitle?.split(">")[0]
        : t("dryeyedisease.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[51]?.subtitle?.split(">")[1]
        ? banners[51]?.subtitle?.split(">")[1]
        : t("dryeyedisease.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[51]?.subtitle?.split(">")[2]
        ? banners[51]?.subtitle?.split(">")[2]
        : "Respiratory Diseases",
      subDash: ">",
      subLink: "/respiratory-diseases",
    },
    {
      subLinkName: banners[51]?.subtitle?.split(">")[3]
        ? banners[51]?.subtitle?.split(">")[3]
        : "Ulcerative Colitis",
      subDash: "",
      subLink: "/ulcerativecolitis",
    },
  ];

  const isDesktoplg = useMediaQuery("(min-width: 992px)");

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(
                item?.respiratoryUlcerative ? item?.respiratoryUlcerative : "[]"
              )
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.respiratoryUlcerative
            ? specialityMetaTitles?.respiratoryUlcerative
            : "Ulcerative Colitis"}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.specialityMain
              ? specialityMetaDesc?.specialityMain
              : "Ulcerative Colitis"
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.respiratoryUlcerative
              ? specialityMetaKeywords?.respiratoryUlcerative
              : "Ulcerative Colitis"
          }
        />
      </Helmet>
      <div className="">
        <BannerWithText
          heading={
            banners[51]?.heading ? banners[51]?.heading : "Ulcerative Colitis"
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[51]?.image ? banners[51]?.image : banner
          })`}
          imgSrc={banners[51]?.image ? banners[51]?.image : banner}
          LinksBan={LinksBanTreatmentCt}
          height={isDesktoplg ? "300px" : "330px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={therapy?.image ? therapy?.image : res5}
          alt={
            specialityAltTags?.respiratoryUlcerative
              ? specialityAltTags?.respiratoryUlcerative
              : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={
            therapy?.name ? therapy?.name : "What is Ulcerative Colitis"
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.desc ? (
                  <div dangerouslySetInnerHTML={{ __html: therapy?.desc }} />
                ) : (
                  <>
                    <p class="text-justify">
                      Ulcerative colitis is a chronic inflammatory bowel disease
                      (IBD) in which the inflammation and ulceration occur in
                      the large intestine and/or rectum.
                      <br />
                      In the normal functioning of GIT (Gastro-Intestinal
                      tract), the food passes from the mouth through the
                      esophagus in the stomach, then to the small intestine, and
                      further into the large intestine (also known as the
                      colon). The water content from the food is absorbed by the
                      colon and stored in the rectum for further bowel
                      movements, after which it is excreted as feces from the
                      anus.
                    </p>
                    <h2
                      style={{
                        textTransform: "capitalize",
                        color: "#333333",
                      }}
                      className="gray-headin"
                    >
                      Stem Cell Therapy for Ulcerative Colitis
                    </h2>

                    <hr
                      style={{
                        borderBottom: "3px solid #AF5689",
                        margin: "10px 0px 15px 0px",
                        width: "80px",
                      }}
                    ></hr>
                    <p class="text-justify">
                      MSCs have great therapeutic potential in regenerative
                      medicine due to their differentiation capacity and their
                      secretion of numerous bioactive molecules . There are a
                      number of studies focused on the attractive regenerative
                      properties of MSCs, and evidence indicating that MSCs can
                      promote regeneration of injured tissue. MSCs have low
                      immunogenicity and could migrate to the lesions of the
                      colon where they assisted in recovery, displaying high
                      therapeutic potential with regards to tissue repair and/or
                      the control of local inflammation
                      <br />
                      MSCs can reduce colonic inflammation by downregulating the
                      production of inflammatory mediators by mucosal immune
                      cells, and by increasing the levels of the anti
                      inflammatory cytokines.
                    </p>
                  </>
                )}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default UlcerativeColitis;
