import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import DashBoardMainHading from "../../components/reuseablecomponents/DashBoardMainHading";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import { patientAppointments } from "../../store/actions/administratorActions";

const AppointmentDetails = (props) => {
  const {t} = useTranslation("common")
  const dispatch = useDispatch();

  const appointmentDetails = useSelector(
    (state) => state?.administrator?.appointment
  );
  const loading = useSelector(
    (state) => state?.administrator?.administrator_loader
  );
  let patientData = props?.location?.state;
 

  // eslint-disable-next-line
  useEffect(() => {
    getPatientAppointement();
    // eslint-disable-next-line
  }, [dispatch]);
  const getPatientAppointement = () => {
    dispatch(patientAppointments(props?.location?.state?.id));
  };


  const handleChange = (value, item) => {
    if (value === t("dashboards.alldashboardkeys.tablekeys.viewbtn")) {
      props.history.push("/patient-appointment-details", { patientData, item });
    }
  };
  const defaultSorting = [{
    dataField: "date",
    order: "asc",
  },]
  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading title={t("dashboards.alldashboardkeys.adminmainheading1")} />
      <div className="container">
        <div className="w-100 px-0">
          <div className="row px-3 mb-2">
            <div className="col-xl-8 col-md-8 col-12 px-0">
              <div
                className="btn btnSquare"
                style={{ width: "150px" }}
                onClick={() => {
                  props.history.push("/administrator-dashboard");
                }}
              >
                <i className="fas fa-arrow-left"></i> {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
              </div>
            </div>
          </div>
          {/* <div className="mt-2 mb-2 px-0">
            <span className="dashboardHeadingText px-0">
              {props?.location?.state?.name} Appointment Details
            </span>
          </div> */}
          {loading ? (
            <LoderContainer />
          ) : (
            <BootstrapCustomTable
              columns={[
                {
                  formatter: (cell, row, index) => {
                    return <span>{index + 1}</span>;
                  },
                  text: "Sr.",
                },
                {
                  formatter: (cell, row, index) => {
                    return <span>{props?.location?.state?.name}</span>;
                  },
                  text: t("dashboards.alldashboardkeys.tablekeys.patientname"),
                },
                { dataField: "condition.name", text: t("dashboards.alldashboardkeys.tablekeys.condition") },
                { dataField: "date", text: t("dashboards.alldashboardkeys.tablekeys.date"),sort: true },
                { dataField: "status.name", text: t("dashboards.alldashboardkeys.tablekeys.status") },

                {
                  formatter: (cell, row, index) => {
                    return (
                      <ActionBtn
                        options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                        handleChange={handleChange}
                        data={row}
                      />
                    );
                  },
                  text: t("dashboards.alldashboardkeys.tablekeys.action"),
                },
              ]}
              tabledata={appointmentDetails || []}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointmentDetails;
