import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Testemonials from "../../../../../components/layouts/testimonials/Testemonials";
import TabBtnList from "../../../../../components/layouts/tabbuttons/DashboardBtnList";
import TabHandlerTextwithImage from "../../../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  cttreatment,
  imghy1,
  pamenucard5,
  LinksBanTreatmentCt,
  tabhandctc1,
  tabhandctc2,
  tabhandctc3,
  tabhandctc4,
} from "../../../../../data";
import HeadingWithBottomBar from "../../../../../components/reuseablecomponents/HeadingWithBottomBar";
import useMediaQuery from "../../../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Toggleform from "../../../../../components/layouts/toggleform/toggleform";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../../../utils/functions";

const TennisElbow = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const stories = filterComponentData(component, "our_stories", lang);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );

  const enquiry = filterComponentData(
    component,
    "cupping_therapy_ct_make_an_enquiry_",
    lang
  );
  const therapy = filterComponentData(component, "tennis_elbow", lang);
  const { t } = useTranslation("common");

  const LinksBanTreatmentCt = [
    {
      subLinkName: banners[55]?.subtitle?.split(">")[0]
        ? banners[55]?.subtitle?.split(">")[0]
        : t("kneepain.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[55]?.subtitle?.split(">")[1]
        ? banners[55]?.subtitle?.split(">")[1]
        : t("kneepain.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[55]?.subtitle?.split(">")[2]
        ? banners[55]?.subtitle?.split(">")[2]
        : t("kneepain.banner.title1"),
      subDash: ">",
      subLink: "/pain-management",
    },
    {
      subLinkName: banners[55]?.subtitle?.split(">")[3]
        ? banners[55]?.subtitle?.split(">")[3]
        : "Tennis Elbow",
      subDash: "",
      subLink: "/tennis-elbow",
    },
  ];

  const isDesktoplg = useMediaQuery("(min-width: 992px)");

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.painTennis ? item?.painTennis : "[]")
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.painTennis
            ? specialityMetaTitles?.painTennis
            : "Tennis Elbow"}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.painTennis
              ? specialityMetaDesc?.painTennis
              : "Tennis Elbow"
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.painTennis
              ? specialityMetaKeywords?.painTennis
              : "Tennis Elbow"
          }
        />
      </Helmet>
      <div className="">
        <BannerWithText
          heading={banners[55]?.heading ? banners[55]?.heading : "Tennis Elbow"}
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[55]?.image ? banners[55]?.image : banner
          })`}
          imgSrc={banners[55]?.image ? banners[55]?.image : banner}
          LinksBan={LinksBanTreatmentCt}
          height={isDesktoplg ? "300px" : "330px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={therapy?.image ? therapy?.image : pamenucard5}
          alt={
            specialityAltTags?.painTennis ? specialityAltTags?.painTennis : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={therapy?.name ? therapy?.name : "What is Tennis Elbow"}
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.desc ? (
                  <div dangerouslySetInnerHTML={{ __html: therapy?.desc }} />
                ) : (
                  <>
                    <p class="text-justify">
                      Tennis elbow occurs when there is a problem with the
                      tendon (called the extensor carpi radialis brevis muscle
                      tendon) that attaches to the outside part of the elbow
                      bone called the lateral epicondyle, thus giving tennis
                      elbow the medical name 'lateral epicondylitis. This tendon
                      is the attachment site of the muscle that functions to
                      cock the wrist back (called wrist extension).
                    </p>
                    <h2
                      style={{
                        textTransform: "capitalize",
                        color: "#333333",
                      }}
                      className="gray-headin"
                    >
                      Stem Cell Therapy For Tennis Elbow
                    </h2>

                    <hr
                      style={{
                        borderBottom: "3px solid #AF5689",
                        margin: "10px 0px 15px 0px",
                        width: "80px",
                      }}
                    ></hr>
                    <p class="text-justify">
                      TotiCell Ltd. Centre of Cellular & Genetic Medicine aims
                      to provide state-of-the-art autologous bone marrow
                      concentrate therapy & allogeneic mesenchymal stem cell
                      therapy for elbow pain using a comprehensive treatment
                      strategy. This therapy has successfully provided relief
                      for common types of elbow pain caused by injuries such as
                      broken elbow bones, torn tendons or ligaments, or an elbow
                      sprain. Stem cell injections can also repair damage to the
                      joint caused by repetitive motions, such as assembly line
                      or office work, tennis, and golf. Potential patients can
                      pursue stem cell therapy because it is safe, non-surgical
                      option, effective and help them heal and recover without
                      the risks and downtime of traditional surgery.
                      <br />
                      PRP injections are becoming a popular source of treatment
                      for <u>tennis elbow</u> (lateral epicondylitis) sufferers.
                      <br />
                      <br />
                      <span className="font-weight-bold">
                        The following are some of the benefits of using PRP
                        treatments for tennis elbow:
                      </span>
                    </p>
                    <div>
                      <div class="span-department">
                        <ul>
                          <li>
                            <i class="fa fa-check"></i>
                            Long-term, lasting solution to the pain of tennis
                            elbow (addresses the cause of your symptoms)
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Natural option – nothing artificial is introduced
                            into your body
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Uses your own blood, so there is no risk of
                            rejection by your immune system
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Simple, quick, and convenient in-office procedure
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
                {/* <br/><br/>
            {t("cttherapy.textrev.discriptiontxt2")} */}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default TennisElbow;
