import React from "react";

const ChatIframe = (props) => {
  const { user_id, appointment_id, board, name } = props
  console.log(user_id,"user_id",name,"name");
  console.log(`https://toticell.com/chat/chat?name=${user_id}&room=${appointment_id}&friendlyName=${name}`,"route");
  // appointment_id={viewData?.id}
  // board={false}
  // const appointment_id = props?.match?.params?.id;
  // const user_id = props?.match?.params?.user_id;
  //   const name = props?.match?.params?.name;
  // const board = props?.match?.params?.board;
  return (
    <div className="row" style={{ height: '90vh' }}>
      <div className="container center-children-class ">
        <div className="col-lg-10 col-md-12 containerWithShadow center-children-class py-3 p-0" style={{ height: '80vh' }}>
          <iframe
            className="chat-frame mt-5"
            src={`https://toticell.com/chat/chat?name=${user_id}&room=${appointment_id}&friendlyName=${name}`}
            // src={`https://chattoticell.herokuapp.com/?channel_id=${appointment_id}&user_id=${user_id}&doctors_board=${board}`}
            title="chatIframe"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ChatIframe;
