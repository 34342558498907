import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from 'moment'

import {
  getSlotByDateAndDoctor,
  reschedule,
} from "../../../../store/actions/patientActions";
import { disablePastDate } from "../../../../utils/functions";
const RescheduleModal = (props) => {
  const new_date = new Date()
  const { t } = useTranslation("common")
  const modeldata = props?.data;
  const slots = useSelector((state) => state?.patient?.slotsByDate);
  const [slot, setSlot] = useState([]);
  const [date, setDate] = useState(Date.now());
  const user = useSelector((state) => state?.logIn?.user);

  const dispatch = useDispatch();
  const clickHandler = () => {
    if (slot?.length === 1) {
      const apiData = {
        id: modeldata?.id,
        date: date,
        slot_id: slot[0]?.slot_id,
      };
      dispatch(reschedule(apiData, { id: user?.id }));
      props.onHide();
    } else {
      toast.info(t("dashboards.alldashboardkeys.tablekeys.selectslot"));
    }
  };
  const dateChangeHandler = (e) => {
    setDate(e.target.value);
    let today = new Date();
    let time = today.getHours() + ":" + today.getMinutes();
    const apiData = {
      id: modeldata?.doctor_details?.id,
      date: e.target.value,
      time: time,
      timestamp: moment(new_date)
        .add(moment(new_date).utcOffset(), 'minutes')
        .utc()
        .unix(),
    };
    dispatch(getSlotByDateAndDoctor(apiData));
  };
  // {t("dashboards.alldashboardkeys.tablekeys.no")}
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="dashboardBtnList-item-active">
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-white">{t("dashboards.alldashboardkeys.tablekeys.reschedulerequest")}</span>
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true" className="text-white">
              ×
            </span>
            <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>
          </button>
        </Modal.Header>

        <Modal.Body style={{ backgroundColor: "rgb(249, 249, 249)" }}>
          <div className="px-3">
            <div className="row center-childrens pb-2">
              <div className="col-12 containerWithShadow p-4">
                <ul style={{ listStyle: "none", paddingLeft: "0px" }}>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.datantime")}
                    </span>
                    <p>
                      {modeldata?.date} @ {modeldata?.slot?.time?.from} -{" "}
                      {modeldata?.slot?.time?.to}{" "}
                    </p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.doctorname")}
                    </span>
                    <p>{modeldata?.doctor_details?.name}</p>
                  </li>{" "}
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.doctoremail")}
                    </span>
                    <p>{modeldata?.doctor_details?.email_address}</p>
                  </li>{" "}
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.speciality")}
                      .
                    </span>
                    {modeldata?.doctor_details?.speciality?.map((item) => <p>{item?.name}</p>)}
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.consultationtype")}
                    </span>
                    <p>{modeldata?.consultation_type?.name}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row center-childrens pb-2">
              <div className="col-12 containerWithShadow pt-2 pb-3">
                <span className="label-name-login"> {t("dashboards.alldashboardkeys.tablekeys.date")}</span>
                <input
                  type="date"
                  min={disablePastDate()}
                  className="w-100 "
                  onChange={(e) => dateChangeHandler(e)}
                />
              </div>
            </div>
            <div className="row center-childrens pb-2">
              <div className="col-12 containerWithShadow py-2">
                <Form.Group>
                  <span className="label-name-login"> {t("dashboards.alldashboardkeys.tablekeys.Slot")}</span>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="duration"
                    onChange={(selected) => {
                      setSlot(selected);
                      // changeDoctorHanler(selected);
                    }}
                    options={slots}
                    placeholder=""
                    selected={slot}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          <div
            className="btn btn-block dashboardBtnList-item-active"
            onClick={() => clickHandler()}
          >
            <span className="text-white">{t("dashboards.alldashboardkeys.tablekeys.reschedule")}</span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RescheduleModal;
