import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import DoctorCard from "../../components/layouts/cards/DoctorsCard/DoctorCard";
import HomeCard from "../../components/layouts/cards/HomeCard/HomeCard";
import LatestPostCard from "../../components/layouts/cards/LatestPostCard/LatestPostCard";
import Slideshow from "../../components/layouts/carousel/Carousal";
import Sliders from "../../components/layouts/slidertest/Slider";
import TabBtnList from "../../components/layouts/tabbuttons/DashboardBtnList";
import HeadingWithBottomBar from "../../components/reuseablecomponents/HeadingWithBottomBar";
import TabHandlerTextwithImage from "../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../components/reuseablecomponents/TextAndImageSection";
import {
  CardDoctorData,
  CardPostData,
  homecard1,
  homecard2,
  homecard3,
  homecard4,
  homecard5,
  homecard6,
  homecard7,
  homecard8,
  img11,
  mobileappstore,
  mobilecard1,
  mobilegoogleplay,
  mobileqr,
  tabicon1,
  tabicon2,
  tabicon3,
  tabicon4,
  tabicon5,
  tabicon6,
  tabhandpic1,
  tabhandpic2,
  tabhandpic3,
  tabhandpic4,
  tabhandpic5,
  tabhandpic6,
  doccard1,
  doccard2,
  doccard4,
  doccard5,
  doccard6,
  doccard7,
  doccard8,
  post1,
  post2,
  post3,
  doctorFemale,
  doctorMale,
} from "../../data";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchPublicDoctor,
  setlocallang,
} from "../../store/actions/staticActions";
import Slider from "react-slick";
import { filterComponentData } from "../../utils/functions";

const Home = (props) => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const publicDoctors = useSelector((state) => state?.static?.publicDoctor);
  const { t, i18n } = useTranslation("common");
  const [buttonState, setButtonState] = useState([]);
  const [toggleRender, settoggleRender] = useState(false);

  const slider = filterComponentData(component, "home_main_slider", lang);
  const aboutUs = filterComponentData(component, "home_about_us", lang);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPublicDoctor());
  }, []);

  const specialityTab = filterComponentData(
    component,
    "our_speciality_tabs",
    lang
  );

  const specialityTabList = filterComponentData(
    component,
    "our_speciality_tabs_list",
    lang
  );

  const treatmentCards = filterComponentData(
    component,
    "our_treatement_cards",
    lang
  );
  const our_speciality_heading = filterComponentData(
    component,
    "our_speciality_heading",
    lang
  );
  const treatmentHeading = filterComponentData(
    component,
    "our_treatement_heading",
    lang
  );
  const doctors = filterComponentData(component, "our_doctor_cards", lang);
  const doctorsheading = filterComponentData(
    component,
    "our_doctor_heading",
    lang
  );
  const mobileapp = filterComponentData(
    component,
    "mobile_google_play_reverse_component",
    lang
  );
  const latestpostheading = filterComponentData(
    component,
    "latest_post_heading",
    lang
  );
  const latestpost = filterComponentData(component, "latest_post_cards", lang);
  const practice = filterComponentData(component, "our_practice_slider", lang);
  const metaTitle = filterComponentData(component, "home_meta_title", lang);
  const metaDescription = filterComponentData(
    component,
    "home_meta_description",
    lang
  );
  const metaKeywords = filterComponentData(
    component,
    "home_meta_keyword",
    lang
  );
  const homeSchemaMarkup = filterComponentData(
    component,
    "home_schema_markups",
    lang
  );
  let homeBannerAltTags = filterComponentData(
    component,
    "home_page_image_alt_tags",
    lang
  );
  const homeRelAttribute = filterComponentData(
    component,
    "home_page_rel_attribute",
    lang
  );
  const homeVerification = filterComponentData(
    component,
    "home_verification_meta_tags",
    lang
  );
  const populateButtonArray = () => {
    let test = [];
    specialityTab?.map((item) => {
      test?.push(item?.tab);
    });
    setButtonState(test);
  };

  // Home
  const CardHomeData = [
    {
      img: homecard1,
      btnlink: "/prp-therapy",
      heading: t("home.ourtreatmentcards.card1.heading"),
      discription: t("home.ourtreatmentcards.card1.description"),
    },
    {
      img: homecard2,
      btnlink: "/bmc-therapy",
      heading: t("home.ourtreatmentcards.card2.heading"),
      discription: t("home.ourtreatmentcards.card2.description"),
    },
    {
      img: homecard3,
      btnlink: "/svf-therapy",
      heading: t("home.ourtreatmentcards.card3.heading"),
      discription: t("home.ourtreatmentcards.card3.description"),
    },
    {
      img: homecard4,
      btnlink: "/msc-therapy",
      heading: t("home.ourtreatmentcards.card4.heading"),
      discription: t("home.ourtreatmentcards.card4.description"),
    },
    {
      img: homecard5,
      btnlink: "/mnc-therapy",
      heading: t("home.ourtreatmentcards.card5.heading"),
      discription: t("home.ourtreatmentcards.card5.description"),
    },
    {
      img: homecard6,
      btnlink: "/eswt-therapy",
      heading: t("home.ourtreatmentcards.card6.heading"),
      discription: t("home.ourtreatmentcards.card6.description"),
    },
    {
      img: homecard7,
      btnlink: "/hbot-therapy",
      heading: t("home.ourtreatmentcards.card7.heading"),
      discription: t("home.ourtreatmentcards.card7.description"),
    },
    {
      img: homecard8,
      btnlink: "/ct-therapy",
      heading: t("home.ourtreatmentcards.card8.heading"),
      discription: t("home.ourtreatmentcards.card8.description"),
    },
  ];
  //   HomeTabHandler
  const checklinkhomecovid = [
    {
      tabicon: tabicon1,
      img: tabhandpic1,
      heading: t("home.ourspecialitytabs.btncoviddetails.headfirst"),
      description: (
        <p style={{ marginBottom: "6px", fontSize: "15px" }}>
          {t("home.ourspecialitytabs.btncoviddetails.discriptiontxt1")}
        </p>
      ),
      description1: (
        <p style={{ marginBottom: "6px", fontSize: "15px" }}>
          {t("home.ourspecialitytabs.btncoviddetails.discriptiontxt2")}
        </p>
      ),
      linkchecks: [
        {
          title: t("home.ourspecialitytabs.btncoviddetails.list1"),
          titlelink: "/molecular-diagnosis",
        },
        {
          title: t("home.ourspecialitytabs.btncoviddetails.list2"),
          titlelink: "/serology",
        },
        {
          title: t("home.ourspecialitytabs.btncoviddetails.list3"),
          titlelink: "/convalescent-plasma-therapy",
        },
        {
          title: t("home.ourspecialitytabs.btncoviddetails.list4"),
          titlelink: "/stem-cell-therapy",
        },
      ],
    },
  ];

  const checklinkhomepainmanage = [
    {
      tabicon: tabicon2,
      img: tabhandpic2,
      heading: t("home.ourspecialitytabs.btnpaindetails.headfirst"),
      description: (
        <p style={{ marginBottom: "6px", fontSize: "15px" }}>
          {t("home.ourspecialitytabs.btnpaindetails.discriptiontxt1")}
        </p>
      ),
      description1: (
        <p style={{ marginBottom: "6px", fontSize: "15px" }}>
          {t("home.ourspecialitytabs.btnpaindetails.discriptiontxt2")}
        </p>
      ),
      linkchecks: [
        {
          title: t("home.ourspecialitytabs.btnpaindetails.list1"),
          titlelink: "/knee-pain",
        },
        {
          title: t("home.ourspecialitytabs.btnpaindetails.list2"),
          titlelink: "/back-pain",
        },
        {
          title: t("home.ourspecialitytabs.btnpaindetails.list3"),
          titlelink: "/frozen-shoulder-shoulder-pain",
        },
        {
          title: t("home.ourspecialitytabs.btnpaindetails.list4"),
          titlelink: "/plantar-fasciitis",
        },
      ],
    },
  ];

  const checklinkhomeaesthetic = [
    {
      tabicon: tabicon3,
      img: tabhandpic3,
      heading: t("home.ourspecialitytabs.btnaestheticdetails.headfirst"),
      description: (
        <p style={{ marginBottom: "6px", fontSize: "15px" }}>
          {t("home.ourspecialitytabs.btnaestheticdetails.discriptiontxt1")}
        </p>
      ),
      description1: (
        <p style={{ marginBottom: "6px", fontSize: "15px" }}>
          {t("home.ourspecialitytabs.btnaestheticdetails.discriptiontxt2")}
        </p>
      ),
      linkchecks: [
        {
          title: t("home.ourspecialitytabs.btnaestheticdetails.list1"),
          titlelink: "/erectile-dysfunction",
        },
        {
          title: t("home.ourspecialitytabs.btnaestheticdetails.list2"),
          titlelink: "/womens-intimate-health",
        },
        {
          title: t("home.ourspecialitytabs.btnaestheticdetails.list3"),
          titlelink: "/hair-loss",
        },
        {
          title: t("home.ourspecialitytabs.btnaestheticdetails.list4"),
          titlelink: "/facial-rejuvenation",
        },
      ],
    },
  ];

  const checklinkhomeneuro = [
    {
      tabicon: tabicon4,
      img: tabhandpic4,
      heading: t("home.ourspecialitytabs.btnneorologicaldetails.headfirst"),
      description: (
        <p style={{ marginBottom: "6px", fontSize: "15px" }}>
          {t("home.ourspecialitytabs.btnneorologicaldetails.discriptiontxt1")}
        </p>
      ),
      description1: (
        <p style={{ marginBottom: "6px", fontSize: "15px" }}>
          {t("home.ourspecialitytabs.btnneorologicaldetails.discriptiontxt2")}
        </p>
      ),
      linkchecks: [
        {
          title: t("home.ourspecialitytabs.btnneorologicaldetails.list1"),
          titlelink: "/autism",
        },
        {
          title: t("home.ourspecialitytabs.btnneorologicaldetails.list2"),
          titlelink: "/muscular-dystrophy",
        },
        {
          title: t("home.ourspecialitytabs.btnneorologicaldetails.list3"),
          titlelink: "/cerebral-palsy",
        },
        {
          title: t("home.ourspecialitytabs.btnneorologicaldetails.list4"),
          titlelink: "/spinal-cord-injury",
        },
        {
          title: t("home.ourspecialitytabs.btnneorologicaldetails.list5"),
          titlelink: "/downs-syndrome",
        },
      ],
    },
  ];

  const checklinkhomestem = [
    {
      tabicon: tabicon5,
      img: tabhandpic5,
      heading: t("home.ourspecialitytabs.btnstemcelldetails.headfirst"),
      description: (
        <p style={{ marginBottom: "6px", fontSize: "15px" }}>
          {t("home.ourspecialitytabs.btnstemcelldetails.discriptiontxt1")}
        </p>
      ),
      description1: (
        <p style={{ marginBottom: "6px", fontSize: "15px" }}>
          {t("home.ourspecialitytabs.btnstemcelldetails.discriptiontxt2")}
        </p>
      ),
      linkchecks: [
        {
          title: t("home.ourspecialitytabs.btnstemcelldetails.list1"),
          titlelink: "/cord-blood-banking",
        },
        {
          title: t("home.ourspecialitytabs.btnstemcelldetails.list2"),
          titlelink: "/cord-tissue-banking",
        },
        {
          title: t("home.ourspecialitytabs.btnstemcelldetails.list3"),
          titlelink: "/community-banking",
        },
        {
          title: t("home.ourspecialitytabs.btnstemcelldetails.list4"),
          titlelink: "/family-banking",
        },
      ],
    },
  ];

  const checklinkhomediabetes = [
    {
      tabicon: tabicon6,
      img: tabhandpic6,
      heading: t("home.ourspecialitytabs.btndiabetesdetails.headfirst"),
      description: (
        <p style={{ marginBottom: "6px", fontSize: "15px" }}>
          {t("home.ourspecialitytabs.btndiabetesdetails.discriptiontxt1")}
        </p>
      ),
      description1: (
        <p style={{ marginBottom: "6px", fontSize: "15px" }}>
          {t("home.ourspecialitytabs.btndiabetesdetails.discriptiontxt2")}
        </p>
      ),
      linkchecks: [
        {
          title: t("home.ourspecialitytabs.btndiabetesdetails.list1"),
          titlelink: "/diabetic-foot-ulcer",
        },
        {
          title: t("home.ourspecialitytabs.btndiabetesdetails.list2"),
          titlelink: "/dry-eye-disease",
        },
      ],
    },
  ];
  const CardDoctorData = [
    // {
    //   img: doccard1,
    //   heading: t("home.ourdoctorcards.card1.heading"),
    //   discription: t("home.ourdoctorcards.card1.description"),
    //   defaultname: "drRezual",
    // },
    {
      img: doctorFemale,
      heading: t("home.ourdoctorcards.card2.heading"),
      discription: t("home.ourdoctorcards.card2.description"),
      defaultname: "drKhalida",
    },
    {
      img: doctorFemale,
      heading: t("home.ourdoctorcards.card3.heading"),
      discription: t("home.ourdoctorcards.card3.description"),
      defaultname: "drAsma",
    },
    // {
    //   img: doctorMale,
    //   heading: t("home.ourdoctorcards.card4.heading"),
    //   discription: t("home.ourdoctorcards.card4.description"),
    //   defaultname: "drMaftun",
    // },
    {
      img: doctorMale,
      heading: t("home.ourdoctorcards.card5.heading"),
      discription: t("home.ourdoctorcards.card5.description"),
      defaultname: "drSyedur",
    },
    {
      img: doctorFemale,
      heading: t("home.ourdoctorcards.card6.heading"),
      discription: t("home.ourdoctorcards.card6.description"),
      defaultname: "drNazia",
    },
    {
      img: doctorFemale,
      heading: t("home.ourdoctorcards.card7.heading"),
      discription: t("home.ourdoctorcards.card7.description"),
      defaultname: "drTanzia",
    },
  ];

  const CardPostData = [
    {
      img: post1,
      cardlink: "/knee-pain",
      heading: t("home.ourpostcards.card1.heading"),
      discription: t("home.ourpostcards.card1.description"),
      msg: t("home.ourpostcards.card1.msg"),
      likes: t("home.ourpostcards.card1.likes"),
    },
    {
      img: post2,
      cardlink: "/facial-rejuvenation",
      heading: t("home.ourpostcards.card2.heading"),
      discription: t("home.ourpostcards.card2.description"),
      msg: t("home.ourpostcards.card2.msg"),
      likes: t("home.ourpostcards.card2.likes"),
    },
    {
      img: post3,
      cardlink: "/prp-therapy",
      heading: t("home.ourpostcards.card3.heading"),
      discription: t("home.ourpostcards.card3.description"),
      msg: t("home.ourpostcards.card3.msg"),
      likes: t("home.ourpostcards.card3.likes"),
    },
  ];

  const [selecteddata, setSelecteddata] = useState(
    specialityTab?.length < 1 ? checklinkhomecovid[0] : specialityTab[0]
  );
  const [selectedTab1, setSelectedTab1] = useState(
    buttonState?.length < 1
      ? t("home.ourspecialitytabs.btncovid")
      : buttonState[0]
  );

  useEffect(() => {
    populateButtonArray();
    setSelecteddata(
      specialityTab?.length < 1 ? checklinkhomecovid[0] : specialityTab[0]
    );
    setSelectedTab1(
      buttonState?.length < 1
        ? t("home.ourspecialitytabs.btncovid")
        : buttonState[0]
    );
  }, [lang, specialityTab?.length]);

  const buttonname = [
    t("home.ourspecialitytabs.btncovid"),
    t("home.ourspecialitytabs.btnpain"),
    t("home.ourspecialitytabs.btnaesthetic"),
    t("home.ourspecialitytabs.btnneorological"),
    t("home.ourspecialitytabs.btnstemcell"),
    t("home.ourspecialitytabs.btndiabetes"),
  ];

  const tabledataHandler = async (item) => {
    setSelecteddata(item);
  };

  // Tabhandler Medicine And Gift
  const tabHandler = (item) => {
    setSelectedTab1(item);
    if (item === t("home.ourspecialitytabs.btncovid")) {
      tabledataHandler(checklinkhomecovid[0]);
    } else if (item === t("home.ourspecialitytabs.btnpain")) {
      tabledataHandler(checklinkhomepainmanage[0]);
    } else if (item === t("home.ourspecialitytabs.btnaesthetic")) {
      tabledataHandler(checklinkhomeaesthetic[0]);
    } else if (item === t("home.ourspecialitytabs.btnneorological")) {
      tabledataHandler(checklinkhomeneuro[0]);
    } else if (item === t("home.ourspecialitytabs.btnstemcell")) {
      tabledataHandler(checklinkhomestem[0]);
    } else if (item === t("home.ourspecialitytabs.btndiabetes")) {
      tabledataHandler(checklinkhomediabetes[0]);
    }
  };

  const tabHandlerTest = (item) => {
    setSelectedTab1(item);

    specialityTab?.filter((it) =>
      it?.tab?.toLowerCase() == item?.toLowerCase() ? setSelecteddata(it) : null
    );
  };

  // const schemaData = {
  //   "@context": "http://schema.org",
  //   "@type": "Organization",
  //   name: "Your Company Name",
  //   url: "https://www.yourwebsite.com",
  //   logo: "https://www.yourwebsite.com/images/logo.png",
  //   contactPoint: [
  //     {
  //       "@type": "ContactPoint",
  //       telephone: "+1-800-123-4567",
  //       contactType: "customer service",
  //     },
  //   ],
  // };

  const verificationsTags = homeVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.name,
      content: item?.content,
    })
  );

  return (
    <>
      <Helmet>
        {verificationsTags}
        {homeSchemaMarkup?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(JSON.stringify(item?.home ? item?.home : "[]"))}
          </script>
        ))}
        <title>
          {metaTitle?.homeMetaTitle
            ? metaTitle?.homeMetaTitle
            : t("home.maintitle")}
        </title>
        <meta
          name="description"
          content={
            metaDescription?.home ? metaDescription?.home : t("home.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            metaKeywords?.home ? metaKeywords?.home : t("home.maintitle")
          }
        />
      </Helmet>
      <Sliders {...props} />
      {/* Home Page TextAndImageComponent */}
      <TextAndImageSection
        imgSrc={aboutUs?.image ? aboutUs?.image : img11}
        alt={homeBannerAltTags?.aboutUs ? homeBannerAltTags?.aboutUs : ""}
        headingFirst={
          aboutUs?.heading
            ? aboutUs?.heading
            : t("home.aboutrev.textrev.headfirst")
        }
        discription={
          <>
            <p
              style={{
                fontFamily: "Work Sans', sans-serif",
                lineHeight: "25px",
                marginTop: "20px",
              }}
              className="light-Color-Para"
            >
              {aboutUs?.text
                ? aboutUs?.text
                : t("home.aboutrev.textrev.discriptiontxt1")}
            </p>
          </>
        }
        buttoncustom={
          <>
            <Link to="/about" className="btn btnSquare">
              {aboutUs?.buttonname ? aboutUs?.buttonname : t("home.readmore")}
            </Link>
          </>
        }
        reverse={false}
      />

      <div className="frontcomponent_background mb-2">
        {/* heading component Start */}
        <HeadingWithBottomBar
          heading={
            treatmentHeading?.heading
              ? treatmentHeading?.heading
              : t("home.mainheading1")
          }
        />
        {/* heading component End */}

        {/* card Home Sction start */}
        <div className="card-section-home ">
          <div className="container">
            <div className="row ">
              {treatmentCards?.length < 1
                ? CardHomeData.map((item, index) => (
                    <HomeCard
                      key={index + 1}
                      imgSrc={item.img}
                      cardHeading={item?.heading}
                      description={item?.discription}
                      link={item?.btnlink}
                      buttoncustom={
                        <>
                          <Link
                            to={item?.btnlink}
                            className="btn btnSquareHomeCard"
                          >
                            {t("home.readmore")}
                          </Link>
                        </>
                      }
                    />
                  ))
                : treatmentCards?.map((item, index) => (
                    <HomeCard
                      key={index + 1}
                      imgSrc={item.iconimage}
                      cardHeading={item?.heading}
                      description={item?.description}
                      link={item?.link}
                      buttoncustom={
                        <>
                          <Link
                            to={item?.btnlink}
                            className="btn btnSquareHomeCard"
                          >
                            {t("home.readmore")}
                          </Link>
                        </>
                      }
                    />
                  ))}
            </div>
            {/* <div className="row mt-5">
              <div className="col-12 text-center">
                <Link
                  // style={{ width: "20%" }}
                  className="btn btnSquare"
                  to={{ pathname: "/treatment" }}
                >
                  {"View More"}
                </Link>
              </div>
            </div> */}
          </div>
        </div>
        {/* card Home Sction End */}
      </div>
      {toggleRender ? (
        <div className="tst">Loading ....</div>
      ) : (
        <div className="frontcomponent_background mb-2">
          {/* heading component Start */}
          <HeadingWithBottomBar
            heading={
              our_speciality_heading?.heading
                ? our_speciality_heading?.heading
                : t("home.mainheading2")
            }
          />
          {/* heading component End */}

          {specialityTab?.length < 1
            ? [selecteddata]?.map((obj) => {
                return (
                  <TabHandlerTextwithImage
                    SelectedButtons={
                      <div className="my-4">
                        <div className="row">
                          {buttonname?.map((item, index) => (
                            <div
                              className="col-xl-2 col-lg-4 col-md-6 mb-3 px-lg-0 d-flex d-inline-flex"
                              key={index + 1}
                              onClick={() => tabHandler(item)}
                            >
                              <TabBtnList
                                label={
                                  item ===
                                  t("home.ourspecialitytabs.btncovid") ? (
                                    <span>
                                      {t("home.ourspecialitytabs.btncovid1")}{" "}
                                      <br />
                                      {t("home.ourspecialitytabs.btncovid2")}
                                    </span>
                                  ) : item ===
                                    t("home.ourspecialitytabs.btnpain") ? (
                                    <span>
                                      {t("home.ourspecialitytabs.btnpain1")}{" "}
                                      <br />
                                      {t("home.ourspecialitytabs.btnpain2")}
                                    </span>
                                  ) : item ===
                                    t("home.ourspecialitytabs.btnaesthetic") ? (
                                    <span>
                                      {t(
                                        "home.ourspecialitytabs.btnaesthetic1"
                                      )}{" "}
                                      <br />
                                      {t(
                                        "home.ourspecialitytabs.btnaesthetic2"
                                      )}
                                    </span>
                                  ) : item ===
                                    t(
                                      "home.ourspecialitytabs.btnneorological"
                                    ) ? (
                                    <span>
                                      {t(
                                        "home.ourspecialitytabs.btnneorological1"
                                      )}{" "}
                                      <br />
                                      {t(
                                        "home.ourspecialitytabs.btnneorological2"
                                      )}
                                    </span>
                                  ) : item ===
                                    t("home.ourspecialitytabs.btnstemcell") ? (
                                    <span>
                                      {t("home.ourspecialitytabs.btnstemcell1")}{" "}
                                      <br />
                                      {t("home.ourspecialitytabs.btnstemcell2")}
                                    </span>
                                  ) : item ===
                                    t("home.ourspecialitytabs.btndiabetes") ? (
                                    <span>
                                      {t("home.ourspecialitytabs.btndiabetes")}{" "}
                                    </span>
                                  ) : (
                                    ""
                                  )
                                }
                                icon={
                                  item === t("home.ourspecialitytabs.btncovid")
                                    ? tabicon1
                                    : item ===
                                      t("home.ourspecialitytabs.btnpain")
                                    ? tabicon2
                                    : item ===
                                      t("home.ourspecialitytabs.btnaesthetic")
                                    ? tabicon3
                                    : item ===
                                      t(
                                        "home.ourspecialitytabs.btnneorological"
                                      )
                                    ? tabicon4
                                    : item ===
                                      t("home.ourspecialitytabs.btnstemcell")
                                    ? tabicon5
                                    : item ===
                                      t("home.ourspecialitytabs.btndiabetes")
                                    ? tabicon6
                                    : ""
                                }
                                // bntStyle={{

                                //     width:
                                //         index === 0
                                //             ? "200px"
                                //             : index === buttonname.length - 1
                                //                 ? "200px"
                                //                 : "",

                                // }}
                                className={
                                  selectedTab1 === item
                                    ? "dashboardBtnList-item-active"
                                    : "default-color-and-hover "
                                }
                                labelStyle={{ fontSize: "13px" }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                    alt={
                      homeBannerAltTags?.ourSpeciality
                        ? homeBannerAltTags?.ourSpeciality
                        : ""
                    }
                    imgSrc={obj.img}
                    imgStyle={{
                      borderRadius: "4px",
                      width: "100%",
                      height: "340px",
                      objectFit: "fill",
                    }}
                    hrlinecustome={<hr></hr>}
                    headingFirst={obj.heading}
                    discription={<>{obj.description}</>}
                    discription2={<>{obj.description1}</>}
                    linkcustomli={
                      <div class="span-department">
                        <ul>
                          {obj?.linkchecks?.map((item) => {
                            return item?.titlelink ? (
                              <Link
                                to={item?.titlelink}
                                style={{
                                  textDecoration: "none",
                                  color: "#333333",
                                }}
                              >
                                <li>
                                  <i class="fa fa-check"></i>
                                  {item?.title}
                                </li>
                              </Link>
                            ) : (
                              <li>
                                <i class="fa fa-check"></i>
                                {item?.title}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    }
                    reverse={false}
                  />
                );
              })
            : [selecteddata]?.map((item, index) => (
                <TabHandlerTextwithImage
                  SelectedButtons={
                    <div className="my-4">
                      <div className="row">
                        {buttonState?.map((_item, index) => (
                          <div
                            className="col-xl-2 col-lg-4 col-md-6 mb-3 px-lg-0 d-flex d-inline-flex"
                            key={index + 1}
                            onClick={() => tabHandlerTest(_item)}
                          >
                            <TabBtnList
                              label={
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: _item?.replace(" ", "<br />"),
                                  }}
                                ></span>
                              }
                              icon={
                                index == 0
                                  ? specialityTab[0]?.icon
                                  : index == 1
                                  ? specialityTab[1]?.icon
                                  : index == 2
                                  ? specialityTab[2]?.icon
                                  : index == 3
                                  ? specialityTab[3]?.icon
                                  : index == 4
                                  ? specialityTab[4]?.icon
                                  : index == 5
                                  ? specialityTab[5]?.icon
                                  : tabicon6
                              }
                              // bntStyle={{

                              //     width:
                              //         index === 0
                              //             ? "200px"
                              //             : index === buttonname.length - 1
                              //                 ? "200px"
                              //                 : "",

                              // }}
                              className={
                                selectedTab1 === _item
                                  ? "dashboardBtnList-item-active"
                                  : "default-color-and-hover "
                              }
                              labelStyle={{ fontSize: "13px" }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                  imgSrc={item?.image}
                  alt={
                    homeBannerAltTags?.ourSpeciality
                      ? homeBannerAltTags?.ourSpeciality
                      : ""
                  }
                  imgStyle={{
                    borderRadius: "4px",
                    width: "100%",
                    height: "340px",
                    objectFit: "fill",
                  }}
                  hrlinecustome={<hr></hr>}
                  headingFirst={item?.heading}
                  discription={item?.description}
                  // discription2={<>{obj.description1}</>}
                  toggle={true}
                  linkcustomli={
                    <div class="span-department">
                      <ul>
                        {specialityTabList?.map((item_) => {
                          return item?.tab == item_?.tab ? (
                            <Link
                              to={item_?.link}
                              style={{
                                textDecoration: "none",
                                color: "#333333",
                              }}
                            >
                              <li>
                                <i class="fa fa-check"></i>
                                {item_?.item}
                              </li>
                            </Link>
                          ) : null;
                        })}
                      </ul>
                    </div>
                  }
                  reverse={false}
                />
              ))}
        </div>
      )}

      <div className="frontcomponent_background mb-2" id="ourDoc">
        {/* heading component Start */}
        <HeadingWithBottomBar
          heading={
            doctorsheading?.heading
              ? doctorsheading?.heading
              : t("home.mainheading3")
          }
        />
        {/* heading component End */}

        {/*Our Doctor Cards */}
        <div className="card-section-home ">
          <div className="container">
            <div className="row ">
              {/* <Slider {...settings}> */}
              {doctors?.length < 1
                ? CardDoctorData?.map((item, index) => (
                    <DoctorCard
                      key={index + 1}
                      imgSrc={item.img}
                      alt={
                        homeBannerAltTags?.ourDoctors
                          ? homeBannerAltTags?.ourDoctors
                          : ""
                      }
                      cardHeading={item?.heading}
                      description={item?.discription}
                      defaultname={item?.defaultname}
                    />
                  ))
                : doctors?.map((item, index) => (
                    <DoctorCard
                      key={index + 1}
                      imgSrc={item.image}
                      cardHeading={item?.name}
                      description={item?.description}
                      defaultname={item?.name}
                      button={item?.buttonname}
                      data={item}
                    />
                  ))}
              {/* {doctors?.map((item, index) => (
                <DoctorCard
                  key={index + 1}
                  imgSrc={item.image}
                  cardHeading={item?.name}
                  description={item?.description}
                  defaultname={item?.name}
                  button={item?.buttonname}
                  data={item}
                />
              ))} */}
              {/* </Slider> */}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Qr Section Start */}

      <TextAndImageSection
        imgSrc={mobileapp?.image ? mobileapp?.image : mobilecard1}
        imgStyle={{
          height: "100%",
        }}
        hrTrue={true}
        textCol="col-lg-6 mt-5"
        discription={
          <>
            <h1 class="text-dark font-weight-bold">
              {mobileapp?.headingfirst
                ? mobileapp?.headingfirst
                : t("home.mobilerev.textrev.headfirst")}
              <br />
              {mobileapp?.headingsecond
                ? mobileapp?.headingsecond
                : t("home.mobilerev.textrev.headSecond")}
            </h1>
            <p
              style={{
                fontFamily: "Work Sans', sans-serif",
                lineHeight: "25px",
                marginTop: "20px",
              }}
              className="light-Color-Para"
            >
              {mobileapp?.description
                ? mobileapp?.description
                : t("home.mobilerev.textrev.discriptiontxt1")}
            </p>
          </>
        }
        buttoncustom={
          <>
            <div class="mt-4">
              <div className="container">
                <div className="row">
                  <div className="mt-4">
                    <a
                      href={mobileapp?.googlelink}
                      class="d-flex align-items-center btnRoundedAbout px-5 mb-3"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      // rel="noreferrer"
                      rel={
                        homeRelAttribute?.appStore
                          ? homeRelAttribute?.appStore
                          : "noreferrer"
                      }
                    >
                      <span
                        style={{
                          paddingRight: "15px",
                          paddingTop: "7px",
                        }}
                      >
                        <img src={mobileappstore} alt="mobileappstore icon" />
                      </span>
                      <div class="download-text text-left">
                        <small>
                          {" "}
                          {mobileapp?.downloadfromheading
                            ? mobileapp?.downloadfromheading
                            : t("home.mobilerev.textrev.btntext1")}
                        </small>
                        <h5 class="mb-0">
                          {mobileapp?.appstoreheading
                            ? mobileapp?.appstoreheading
                            : t("home.mobilerev.textrev.btntext2")}
                        </h5>
                      </div>
                    </a>

                    <a
                      className="d-flex align-items-center btnRoundedAbout px-5"
                      href={mobileapp?.googleplay}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      // rel="noreferrer"
                      rel={
                        homeRelAttribute?.playStore
                          ? homeRelAttribute?.playStore
                          : "noreferrer"
                      }
                    >
                      <span
                        style={{
                          paddingRight: "15px",
                          paddingTop: "7px",
                        }}
                      >
                        <img src={mobilegoogleplay} alt="Google Play" />
                      </span>
                      <div class="download-text text-left">
                        <small>
                          {mobileapp?.downloadfromheading
                            ? mobileapp?.downloadfromheading
                            : t("home.mobilerev.textrev.btntext1")}
                        </small>
                        <h5 class="mb-0">
                          {mobileapp?.googleplayheading
                            ? mobileapp?.googleplayheading
                            : t("home.mobilerev.textrev.btntext3")}
                        </h5>
                      </div>
                    </a>
                  </div>
                  <div className="ml-4">
                    <div>
                      {" "}
                      <img
                        alt="qr code icon"
                        src={mobileqr}
                        class="qrsection"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="action-btns "> <img src={mobileqr} width="100px" height="100px"  class="qrsection" /></div> */}
          </>
        }
        reverse={false}
      />

      {/* Mobile Qr Section End  */}

      <Slideshow slide={practice} />

      <div className="frontcomponent_background mb-3" id="ourFac">
        {/* heading component Start */}
        <HeadingWithBottomBar
          heading={
            latestpostheading?.heading
              ? latestpostheading?.heading
              : t("home.mainheading4")
          }
        />
        {/* heading component End */}
        {/*Our Doctor Cards */}
        {/*Our Doctor Cards */}
        <div className="card-section-home mt-5">
          <div className="container">
            <div className="row ">
              {latestpost?.length < 1
                ? CardPostData.map((item, index) => (
                    <LatestPostCard
                      key={index + 1}
                      alt={
                        homeBannerAltTags?.latestPosts
                          ? homeBannerAltTags?.latestPosts
                          : ""
                      }
                      img={item?.img}
                      heading={item?.heading}
                      description={item?.discription}
                      likes={item?.likes}
                      msg={item?.msg}
                      cardlink={item?.cardlink}
                    />
                  ))
                : latestpost?.map((item, index) => (
                    <LatestPostCard
                      key={index + 1}
                      alt={
                        homeBannerAltTags?.latestPosts
                          ? homeBannerAltTags?.latestPosts
                          : ""
                      }
                      img={item?.image}
                      heading={item?.heading}
                      description={item?.description}
                      likes={item?.heartnumber}
                      msg={item?.messagenumber}
                      cardlink={item?.cardlink}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
