
import React from 'react'
import './carousal.css'
import p1 from '../../../statics/icons/1.png'
import p2 from '../../../statics/icons/2.png'
import p3 from '../../../statics/icons/3.png'
import p4 from '../../../statics/icons/4.png'

import { Link } from 'react-router-dom'
import "./carousal.css";
import Slider from 'react-slick'
import { useTranslation } from 'react-i18next'



const Carousal = ({slide}) => {
  const {t} =  useTranslation("common")
  const data = [
    {
      id: 0,
      title: slide?.slide1 ? slide?.slide1 :t("home.ourpracticeslider.slide1"),
    },
    {
      id: 1,
      title: slide?.slider2 ? slide?.slider2 : t("home.ourpracticeslider.slide2"),
    },
    {
      id: 2,
      title: slide?.slide3 ? slide?.slide3 : t("home.ourpracticeslider.slide3"),
    },
    {
      id: 3,
      title: slide?.slide4 ? slide?.slide4 : t("home.ourpracticeslider.slide4"),
    },


  ];
  const autoInProperties = {
    indicators: true,
    durations: 1000,
    infinite: true,
    autoplay: true,
    arrows:false,
    dots: true,
    verticalSwiping: false,
    dotsClass:"vertical-dots",
    customPaging: function(i) {
      return (
      <div id="carousal d-flex flex-column">
        <Link to="#">
          <div className="container " >
            <div className="row">
              <div className="col-12" >
              <img alt="ButtonPic" src={i=== 0 ? slide?.icon1 ? slide?.icon1: p1 : i ===1 ? slide?.icon2 ? slide?.icon2: p2 : i===2 ? slide?.icon3 ? slide?.icon3:p3 : slide?.icon4 ? slide?.icon4:p4}/>
              {/* {i=== 0 ? <h5 className="text-white">GMP Facility</h5> : i ===1 ? <h5 className="text-white">GLP Facility</h5> : i===2 ? <h5 className="text-white">QC</h5> :<h5 className="text-white">R & D</h5>} */}
            </div>
            </div>
          </div>
        </Link>
        </div>
      );
    },
    swipeToSlide: true,
      beforeChange: function(currentSlide, nextSlide) {
      },
      afterChange: function(currentSlide) {
      }
    
  };
  return (
    <div className="main yoga-parallax" id="facility" style={{marginTop: '0px'}}>
    <div className="ovrlapsrtsp_prts">
      <div className="section">
        <div className="parallax-content">
          <h6 style={{textAlign: 'center'}}>{slide?.headingfirst ? slide?.headingfirst: t("home.ourpracticeslider.heading")}</h6>
          <h1 style={{textAlign: 'center', paddingBottom: '30px'}}>{slide?.headingsecond ? slide?.headingsecond:t("home.ourpracticeslider.heading1")}</h1>
        </div>
      </div>
      <Slider {...autoInProperties}>
        {data.map((each, index) => (
          <div key={index} style={{ width: "100%", height: "100%" }}>
            <div className="slider-wrapper">
              
              <div className="container">

                <div className="text-center text-white justify-content-center slider-overlay mb-5" style={{fontSize:'18px'}}>
                         {each.title}
                      </div>

                    </div>

                  </div>
                 
          </div>
        ))}
      </Slider>
    </div>
  </div>
  )
}

export default Carousal