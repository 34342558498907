import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Testemonials from "../../../../../components/layouts/testimonials/Testemonials";
import TabBtnList from "../../../../../components/layouts/tabbuttons/DashboardBtnList";
import TabHandlerTextwithImage from "../../../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  cttreatment,
  imghy1,
  nemenucard6,
  LinksBanTreatmentCt,
  tabhandctc1,
  tabhandctc2,
  tabhandctc3,
  tabhandctc4,
} from "../../../../../data";
import HeadingWithBottomBar from "../../../../../components/reuseablecomponents/HeadingWithBottomBar";
import useMediaQuery from "../../../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Toggleform from "../../../../../components/layouts/toggleform/toggleform";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../../../utils/functions";

const AlzheimerDisease = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const stories = filterComponentData(component, "our_stories", lang);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );

  const enquiry = filterComponentData(
    component,
    "cupping_therapy_ct_make_an_enquiry_",
    lang
  );
  const therapy = filterComponentData(component, "alzheimer_disease", lang);
  const { t } = useTranslation("common");

  const LinksBanTreatmentCt = [
    {
      subLinkName: banners[59]?.subtitle?.split(">")[0]
        ? banners[59]?.subtitle?.split(">")[0]
        : t("cerebralpalsy.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[59]?.subtitle?.split(">")[1]
        ? banners[59]?.subtitle?.split(">")[1]
        : t("cerebralpalsy.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[59]?.subtitle?.split(">")[2]
        ? banners[59]?.subtitle?.split(">")[2]
        : t("cerebralpalsy.banner.title1"),
      subDash: ">",
      subLink: "/neurological-rehabilitation",
    },
    {
      subLinkName: banners[59]?.subtitle?.split(">")[3]
        ? banners[59]?.subtitle?.split(">")[3]
        : "Alzheimer’s Disease",
      subDash: "",
      subLink: "/alzheimer-disease",
    },
  ];

  const isDesktoplg = useMediaQuery("(min-width: 992px)");

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(
                item?.neurologicalAlzhemeirs
                  ? item?.neurologicalAlzhemeirs
                  : "[]"
              )
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.neurologicalAlzhemeirs
            ? specialityMetaTitles?.neurologicalAlzhemeirs
            : "Alzheimer’s Disease"}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.neurologicalAlzhemeirs
              ? specialityMetaDesc?.neurologicalAlzhemeirs
              : "Alzheimer’s Disease"
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.neurologicalAlzhemeirs
              ? specialityMetaKeywords?.neurologicalAlzhemeirs
              : "Alzheimer’s Disease"
          }
        />
      </Helmet>
      <div className="">
        <BannerWithText
          heading={
            banners[59]?.heading ? banners[59]?.heading : "Alzheimer’s Disease"
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[59]?.image ? banners[59]?.image : banner
          })`}
          imgSrc={banners[59]?.image ? banners[59]?.image : banner}
          LinksBan={LinksBanTreatmentCt}
          height={isDesktoplg ? "300px" : "330px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={therapy?.image ? therapy?.image : nemenucard6}
          alt={
            specialityAltTags?.neurologicalAlzhemeirs
              ? specialityAltTags?.neurologicalAlzhemeirs
              : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={
            therapy?.name ? therapy?.name : "What is Alzheimer’s Disease"
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.desc ? (
                  <div dangerouslySetInnerHTML={{ __html: therapy?.desc }} />
                ) : (
                  <>
                    <p class="text-justify">
                      Alzheimer’s disease is the most common cause of dementia,
                      categorized by a steep decline in one’s ability to recall
                      memory, think clearly and ultimately function
                      independently. Alzheimer’s disease is a degenerative
                      condition that results in the loss of brain cells.
                      Generally, Alzheimer’s is associated with memory loss and
                      the loss of simple cognitive abilities, impairing one’s
                      ability to complete daily tasks
                    </p>
                    <h2
                      style={{
                        textTransform: "capitalize",
                        color: "#333333",
                      }}
                      className="gray-headin"
                    >
                      Stem Cell Therapy For Alzheimer’s Disease
                    </h2>

                    <hr
                      style={{
                        borderBottom: "3px solid #AF5689",
                        margin: "10px 0px 15px 0px",
                        width: "80px",
                      }}
                    ></hr>
                    <p class="text-justify">
                      Stem cell therapy is a unique approach to treating
                      Alzheimer’s Disease. At TotiCell we are formulating
                      autologous bone marrow concentrate therapy and allogeneic
                      stem cell therapy. Many studies have shown that stem cell
                      can find inflammation within the body and repair it. This
                      unique property of stem cells is what potentially makes
                      them a viable treatment for Alzheimer’s Disease.
                      <br />
                      It is increased the number of plaques and tangles within
                      the brain of an Alzheimer’s patient affect two essential
                      proteins: ‘amyloid-beta’ and ‘tau.’
                      <br />
                      Stem cell treatment for Alzheimer's Disease aims to
                      replace the damaged cells with healthy stem cells which
                      can grow on their own, hence, creating new healthy brain
                      cells.
                      <br />
                      Stem cell therapy for Alzheimer's may be able to:
                    </p>
                    <div>
                      <div class="span-department">
                        <ul>
                          <li>
                            <i class="fa fa-check"></i>
                            Improve functional memory
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Regenerate neurons
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Improve overall functional recovery
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Replace damaged cells with health cells
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
                {/* <br/><br/>
            {t("cttherapy.textrev.discriptiontxt2")} */}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default AlzheimerDisease;
