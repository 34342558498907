import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import DashboardBtnList from "../../components/reuseablecomponents/DashboardBtnList";
import { getAllOrders } from "../../store/actions/pharmacyActions";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import { checkPaidOrUnpaid, comaSeperated } from "../../utils/functions";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import { useTranslation } from "react-i18next";
import RefundModal from "../../components/layouts/modal/refundAmountModal/RefundModal";
import { cancelOrder } from "../../store/actions/patientActions";
import { toast } from "react-toastify";

const PatientOrders = (props) => {
    const { t } = useTranslation("common")
    const [show, setShow] = useState(false)
    const [id, setID] = useState("")
    const loader = useSelector((state) => state?.pharmacy?.loader);
    const orders = useSelector((state) => state?.pharmacy?.orders);
    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(getAllOrders());
    }, [dispatch]);
    const handleChange = async (value, item) => {
        if (value === "View") {
            props.history.push("/patient-order-details", item);
        } else if (value == "Cancel") {
            if (item?.delivery_status?.name == "processing" && item?.paid_status?.name == "paid") {
                let res = await dispatch(cancelOrder({ order_id: item?.id, is_returned: true }))
                if (res) dispatch(getAllOrders());

            } else if (item?.delivery_status?.name == "processing" && item?.paid_status?.name == "unpaid") {
                let res = await dispatch(cancelOrder({ order_id: item?.id, is_returned: true }))
                if (res) dispatch(getAllOrders());

            } else if (item?.delivery_status?.name == "delivered" && item?.paid_status?.name == "unpaid") {
                let res = await dispatch(cancelOrder({ order_id: item?.id, is_returned: false }))
                if (res) dispatch(getAllOrders());
            }
            else if (item?.delivery_status?.name == "delivered" && item?.paid_status?.name == "paid") {
                let res = await dispatch(cancelOrder({ order_id: item?.id, is_returned: false }))
                if (res) dispatch(getAllOrders());

            }
            else if (item?.delivery_status?.name == "dispatched" && item?.paid_status?.name == "paid") {
                let res = await dispatch(cancelOrder({ order_id: item?.id, is_returned: false }))
                if (res) dispatch(getAllOrders());

            } else {
                let res = await dispatch(cancelOrder({ order_id: item?.id, is_returned: false }))
                if (res) dispatch(getAllOrders());
            }
        }
        else if (value == "Cancel/Refund") {
            setID(item?.id)
            setShow(!show)

        }
    };
    return (
        <>
            <div className="row mt-4">
                <div className="col-lg-9 col-md-8 col-xs-12">
                    <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.order")}</h4>
                </div>
            </div>

            <div className="row">
                {loader ? (
                    <div className="w-100 center-children-class">
                        <LoderContainer />
                    </div>
                ) : (
                    <BootstrapCustomTable
                        columns={[
                            { dataField: "order_id", text: "Order Id" },
                            { dataField: "type", text: "Type" },
                            
                            { dataField: "date", text: t("dashboards.alldashboardkeys.tablekeys.date") },
                            { dataField: "ordered_by.name", text: t("dashboards.alldashboardkeys.tablekeys.orderby") },
                            { dataField: "ordered_by.phone_number", text: t("dashboards.alldashboardkeys.tablekeys.contactnumber") },

                            {
                                formatter: (cell, row, index) => {
                                    return <span>{comaSeperated(row?.total_amount)}</span>;
                                },
                                text: t("dashboards.alldashboardkeys.tablekeys.ammount"),
                            },
                            {
                                formatter: (cell, row, index) => {
                                    return (
                                        <span
                                            style={{
                                                color: row?.delivery_status.name == "cancelled" ? 'red' : 'green',
                                            }}
                                        >
                                            {row?.delivery_status.name?.toUpperCase()}
                                        </span>
                                    );
                                },
                                text: "Delivery Status",
                            },
                            {
                                formatter: (cell, row, index) => {
                                    return (
                                        <span
                                            style={{
                                                color: checkPaidOrUnpaid(row?.paid_status.name),
                                            }}
                                        >
                                            {row?.paid_status.name}
                                        </span>
                                    );
                                },
                                text: t("dashboards.alldashboardkeys.tablekeys.paymentstatus"),
                            },

                            {
                                formatter: (cell, row, index) => {
                                    return (
                                        ((row?.paid_status?.name == "paid" || row?.paid_status?.name == "partial") && row?.delivery_status?.name != "cancelled") ?
                                            <ActionBtn
                                                options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn"), "Cancel", "Cancel/Refund"]}

                                                handleChange={handleChange}
                                                data={row}
                                            /> : row?.paid_status?.name == "unpaid" && row?.delivery_status?.name != "cancelled" ? <ActionBtn
                                                options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn"), "Cancel"]}

                                                handleChange={handleChange}
                                                data={row}
                                            /> : <ActionBtn
                                                options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}

                                                handleChange={handleChange}
                                                data={row}
                                            />

                                    );
                                },
                                text: t("dashboards.alldashboardkeys.tablekeys.action"),
                            },
                        ]}
                        tabledata={orders ? orders : []}
                    />
                )}
            </div>
            <RefundModal
                show={show}
                id={id}
                onHide={() => setShow(false)}
                order={true}
            />
        </>
    );
};

export default PatientOrders;
