import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import { getMyDoctors } from "../../store/actions/patientActions";
const PatientMyDoctorContainer = (props) => {
  const {t} = useTranslation("common")
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.logIn?.user);
  const myDoctors = useSelector((state) => state?.patient?.myDoctors);
  const loader = useSelector((state) => state?.patient?.patientLoader);
  useEffect(() => {
    const apiData = {
      id: user?.id,
    };
    dispatch(getMyDoctors(apiData));

    // eslint-disable-next-line
  }, []);
  const handleChange = (value, data) => {
    props?.history?.push("patient-dashboard-doctor-details", data);
  };
  return (
    <>
      <div className="w-100">
        <div className="p-3">
          <span className="dashboardHeadingText">{t("dashboards.alldashboardkeys.tablekeys.mydoctors")}</span>
        </div>
        {loader ? (
          <LoderContainer />
        ) : (
          <>
            <BootstrapCustomTable
              columns={[
                { dataField: "name", text: t("dashboards.alldashboardkeys.tablekeys.name") },
                { dataField: "phone_number", text: t("dashboards.alldashboardkeys.tablekeys.phone") },
                { dataField: "email_address", text: t("dashboards.alldashboardkeys.tablekeys.email") },
                {
                  formatter: (cell, row, index) => {
                    return (
                      <ActionBtn
                        options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                        handleChange={handleChange}
                        data={row}
                      />
                      // <div className="w-100  center-children-class">
                      //   <select
                      //     className="action-select-border  w-100"
                      //     onChange={(e) => handleChange(e.target.value, row)}
                      //     value="Action :"
                      //   >
                      //     <option selected>Actions:</option>
                      //     <option value="1">View</option>
                      //   </select>
                      // </div>
                    );
                  },
                  text: t("dashboards.alldashboardkeys.tablekeys.action"),
                },
              ]}
              tabledata={myDoctors}
            />
          </>
        )}
      </div>
    </>
  );
};

export default PatientMyDoctorContainer;
