import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  comenucard2,
  banner,
  LinksBanSpecialityCovidSerology,
} from "../../../../../data";
import { filterComponentData } from "../../../../../utils/functions";

const Serology = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const serology = filterComponentData(
    component,
    "speciality_covid_serology",
    lang
  );
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );

  const { t } = useTranslation("common");

  const LinksBanSpecialityCovidSerology = [
    {
      subLinkName: banners[19]?.subtitle?.split(">")[0]
        ? banners[19]?.subtitle?.split(">")[0]
        : t("serology.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[19]?.subtitle?.split(">")[1]
        ? banners[19]?.subtitle?.split(">")[1]
        : t("serology.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[19]?.subtitle?.split(">")[2]
        ? banners[19]?.subtitle?.split(">")[2]
        : t("serology.banner.title1"),
      subDash: ">",
      subLink: "/covid-19-management",
    },
    {
      subLinkName: banners[19]?.subtitle?.split(">")[3]
        ? banners[19]?.subtitle?.split(">")[3]
        : t("serology.banner.title2"),
      subDash: "",
      subLink: "/serology",
    },
  ];
  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.covidSerlogy ? item?.covidSerlogy : "[]")
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.covidSerlogy
            ? specialityMetaTitles?.covidSerlogy
            : t("serology.maintitle")}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.covidSerlogy
              ? specialityMetaDesc?.covidSerlogy
              : t("serology.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.covidSerlogy
              ? specialityMetaKeywords?.covidSerlogy
              : t("serology.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[19]?.heading
              ? banners[19]?.heading
              : t("serology.banner.title2")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[19]?.image ? banners[19]?.image : banner
          })`}
          imgSrc={banners[19]?.image ? banners[19]?.image : banner}
          LinksBan={LinksBanSpecialityCovidSerology}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={serology?.image ? serology?.image : comenucard2}
          alt={
            specialityAltTags?.covidSerlogy
              ? specialityAltTags?.covidSerlogy
              : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={
            serology?.heading
              ? serology?.heading
              : t("serology.textrev.headfirst")
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {serology?.description
                  ? serology?.description
                  : t("serology.textrev.discriptiontxt1")}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default Serology;
