import React, { useEffect, useState } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDepartment,
  getDoctorByDepartment,
} from "../../../../store/actions/patientActions";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const RecomendedDoctorBoardModal = (props) => {
  const { t } = useTranslation("common");
  const user = useSelector((state) => state?.logIn?.user);

  const [doctor, setDoctor] = useState([]);
  const loader = useSelector((state) => state?.logIn?.loader);
  const [doctors, setDoctors] = useState([]);

  useEffect(() => {
    setDoctors(props?.doctor?.filter((item) => item?.id !== user?.id));
  }, [props?.doctor]);
  

  // {t("dashboards.alldashboardkeys.tablekeys.appointmentreschedule")}
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {"Add Recomended Doctor"}
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true">×</span>
            <span class="sr-only">
              {t("dashboards.alldashboardkeys.tablekeys.close")}
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>
         
          <div className="col-lg-12">
            <span className="label-name-login">
              {t("dashboards.alldashboardkeys.tablekeys.doctors")}
            </span>

            <Form.Group>
              {loader ? (
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <Loader
                        height={20}
                        width={20}
                        type="Oval"
                        color="#1a4774"
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control type="text" disabled />
                </InputGroup>
              ) : (
                <Typeahead
                  id="basic-typeahead-single"
                  className="mb-3"
                  labelKey="name"
                  onChange={(selected) => {
                    setDoctor(selected);
                  }}
                  options={doctors}
                  placeholder=""
                  // selected={doctor}
                />
              )}
            </Form.Group>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="btnSquare btn-block mx-auto"
                style={{ width: "85%", textAlign: "center" }}
                onClick={() => {
                  props.setdoctorList(doctor);
                  props.onHide();
                }}
              >
                {t("dashboards.alldashboardkeys.tablekeys.adddoc")}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RecomendedDoctorBoardModal;
