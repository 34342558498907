import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Testemonials from "../../../../../components/layouts/testimonials/Testemonials";
import TabBtnList from "../../../../../components/layouts/tabbuttons/DashboardBtnList";
import TabHandlerTextwithImage from "../../../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  cttreatment,
  imghy1,
  res3,
  LinksBanTreatmentCt,
  tabhandctc1,
  tabhandctc2,
  tabhandctc3,
  tabhandctc4,
} from "../../../../../data";
import HeadingWithBottomBar from "../../../../../components/reuseablecomponents/HeadingWithBottomBar";
import useMediaQuery from "../../../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Toggleform from "../../../../../components/layouts/toggleform/toggleform";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../../../utils/functions";

const AnkylosingSpondylitis = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const stories = filterComponentData(component, "our_stories", lang);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );

  const enquiry = filterComponentData(
    component,
    "cupping_therapy_ct_make_an_enquiry_",
    lang
  );
  const therapy = filterComponentData(
    component,
    "ankylosing_spondylitis",
    lang
  );
  const { t } = useTranslation("common");

  const LinksBanTreatmentCt = [
    {
      subLinkName: banners[49]?.subtitle?.split(">")[0]
        ? banners[49]?.subtitle?.split(">")[0]
        : t("dryeyedisease.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[49]?.subtitle?.split(">")[1]
        ? banners[49]?.subtitle?.split(">")[1]
        : t("dryeyedisease.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[49]?.subtitle?.split(">")[2]
        ? banners[49]?.subtitle?.split(">")[2]
        : "Respiratory Diseases",
      subDash: ">",
      subLink: "/respiratory-diseases",
    },
    {
      subLinkName: banners[49]?.subtitle?.split(">")[3]
        ? banners[49]?.subtitle?.split(">")[3]
        : "Ankylosing Spondylitis",
      subDash: "",
      subLink: "/ankylosingspondylitis",
    },
  ];

  const isDesktoplg = useMediaQuery("(min-width: 992px)");

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(
                item?.respiratoryAnkylosing ? item?.respiratoryAnkylosing : "[]"
              )
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.respiratoryAnkylosing
            ? specialityMetaTitles?.respiratoryAnkylosing
            : "Ankylosing Spondylitis"}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.respiratoryAnkylosing
              ? specialityMetaDesc?.respiratoryAnkylosing
              : "Ankylosing Spondylitis"
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.respiratoryAnkylosing
              ? specialityMetaKeywords?.respiratoryAnkylosing
              : "Ankylosing Spondylitis"
          }
        />
      </Helmet>
      <div className="">
        <BannerWithText
          heading={
            banners[49]?.heading
              ? banners[49]?.heading
              : "Ankylosing Spondylitis"
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[49]?.image ? banners[49]?.image : banner
          })`}
          imgSrc={banners[49]?.image ? banners[49]?.image : banner}
          LinksBan={LinksBanTreatmentCt}
          height={isDesktoplg ? "300px" : "330px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={therapy?.image ? therapy?.image : res3}
          alt={
            specialityAltTags?.respiratoryAnkylosing
              ? specialityAltTags?.respiratoryAnkylosing
              : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={
            therapy?.name
              ? therapy?.name
              : "What is Ankylosing spondylitis (AS)"
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.desc ? (
                  <div dangerouslySetInnerHTML={{ __html: therapy?.desc }} />
                ) : (
                  <>
                    <p class="text-justify">
                      Ankylosing spondylitis (AS) is an inflammatory rheumatoid
                      disease categorized within spondyloarthropathies (SpA) and
                      manifested by chronic spinal arthritis. Several innate and
                      adaptive immune cells and secreted-mediators have been
                      indicated to play a role in AS pathogenesis.
                    </p>
                    <h2
                      style={{
                        textTransform: "capitalize",
                        color: "#333333",
                      }}
                      className="gray-headin"
                    >
                      Sign & Symptoms
                    </h2>

                    <hr
                      style={{
                        borderBottom: "3px solid #AF5689",
                        margin: "10px 0px 15px 0px",
                        width: "80px",
                      }}
                    ></hr>

                    <div>
                      <div class="span-department">
                        <ul>
                          <li>
                            <i class="fa fa-check"></i>
                            Lower back pain and stiffness.
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Hip pain
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Joint pain.
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Neck pain.
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Difficulty breathing.
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Fatigue.
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Loss of appetite and unexplained weight loss.
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Abdominal pain and diarrhea
                          </li>
                        </ul>
                      </div>
                    </div>

                    <h2
                      style={{
                        textTransform: "capitalize",
                        color: "#333333",
                      }}
                      className="gray-headin"
                    >
                      Causes & Risk Factors
                    </h2>

                    <hr
                      style={{
                        borderBottom: "3px solid #AF5689",
                        margin: "10px 0px 15px 0px",
                        width: "80px",
                      }}
                    ></hr>

                    <div>
                      <div class="span-department">
                        <ul>
                          <li>
                            <i class="fa fa-check"></i>
                            Age.
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Sex
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Genes.
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Environmental causes
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Race
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Family History.
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Inflammatory bowel disease
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Uveitis
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default AnkylosingSpondylitis;
