import React from "react";
import { capitalize } from "../../utils/functions";

const DashboardBtn = ({ label, onClick, active }) => {
  return (
    <div
      className={active ? "dashboardBtn-active" : "dashboardBtn"}
      onClick={() => onClick(label)}
    >
      <span>{capitalize(label)}</span>
    </div>
  );
};

export default DashboardBtn;
