import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DashboardBtn from "../../components/reuseablecomponents/DashboardBtn";
import DashBoardMainHading from "../../components/reuseablecomponents/DashBoardMainHading";
import DetailTable from "../../components/reuseablecomponents/DetailTable";
// import MedicineTab from "../doctordashboard/MedicineTab";
// import TestTab from "../doctordashboard/TestTab";

const PatientAppointmentDetails = (props) => {
  const { t } = useTranslation("common")
  let patientData = props?.location?.state?.patientData;
  let appointmentDat = props?.location?.state?.item;
  const [selectedTab, setselectedTab] = useState(t("dashboards.alldashboardkeys.tablekeys.medicine"));
  const tabHandler = (item) => {
    setselectedTab(item);
  };
  useEffect(() => {
    setselectedTab(t("dashboards.alldashboardkeys.tablekeys.medicine"));
  }, []);
  return (
    // t("dashboards.alldashboardkeys.tablekeys.action")
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading title={t("dashboards.alldashboardkeys.tablekeys.appointmentdetail")} />
      <div className="container">
        <div className="row center-children-class">
          <div className="col-xl-8 col-md-8 col-12 px-0">
            <div
              className="btn btnSquare"
              style={{ width: "150px" }}
              onClick={() => {
                props.history.push("/administrator-dashboard");
              }}
            >
              <i className="fas fa-arrow-left"></i> {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
            </div>
          </div>
        </div>
        <div className="row center-childrens mt-3 pb-5 center-children-class">
          <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
            <div className="d-flex justify-content-between">
              <h5>{t("dashboards.alldashboardkeys.tablekeys.patientdetail")}</h5>
              <span>
                {t("dashboards.alldashboardkeys.tablekeys.paymentstatus")} :
                <strong
                  style={{
                    color:
                      appointmentDat?.status?.name === "booked"
                        ? "red"
                        : "green",
                  }}
                >
                  {appointmentDat?.status?.name === "booked"
                    ? "UNPAID"
                    : "PAID"}
                </strong>
              </span>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.datantime")}
                </span>
                <p>{appointmentDat?.date}</p>
              </li>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientname")}
                </span>
                <p>{patientData?.name}</p>
              </li>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientemail")}
                </span>
                <p>{patientData?.email_address}</p>
              </li>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.age")}
                </span>
                <p>{patientData?.age}</p>
              </li>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.description")}
                </span>
                <p>{appointmentDat?.description}</p>
              </li>
            </ul>

            <div>
              <div>
                <div className="row">
                  {[
                    t("dashboards.alldashboardkeys.tablekeys.medicine")
                    ,
                    t("dashboards.alldashboardkeys.tablekeys.tes")
                  ].map((item, index) => (
                    <div
                      className="col-lg-4 mt-2"
                      key={index + 1}
                      onClick={() => tabHandler(item)}
                    >
                      <DashboardBtn
                        label={item}
                        labelStyle={
                          selectedTab === item ? { color: "#fff" } : ""
                        }
                        active={selectedTab === item}
                        className={
                          selectedTab === item
                            ? "dashboardBtnList-item-active py-2"
                            : "default-color-and-hover py-2"
                        }
                        onClick={() => tabHandler(item)}
                      />
                    </div>
                  ))}
                </div>

                {selectedTab === t("dashboards.alldashboardkeys.tablekeys.medicine") ? (
                  appointmentDat?.prescription?.length === 0 ? (
                    <h4
                      className="text-center mt-3"
                      style={{ fontSize: "20px", fontWeight: "bold" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.nomedicineadded")}
                    </h4>
                  ) : (
                    // <MedicineTab {...props} data={false}/>
                    <DetailTable
                      tableHead={[
                        t("dashboards.alldashboardkeys.tablekeys.name"),
                        t("dashboards.alldashboardkeys.tablekeys.duration"),
                        t("dashboards.alldashboardkeys.tablekeys.morningdose"),
                        t("dashboards.alldashboardkeys.tablekeys.eveningdose"),
                        t("dashboards.alldashboardkeys.tablekeys.nightdose"),
                      ]}
                      data={appointmentDat?.prescription?.medicines?.map(
                        (item) => {
                          return [
                            item?.medicine?.name,
                            item?.duration,
                            item?.dose[0],
                            item?.dose[1],
                            item?.dose[2],
                          ];
                        }
                      )}
                    />
                  )
                ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.tes") ? (
                  <DetailTable
                    tableHead={[
                      "ID"
                      ,
                      t("dashboards.alldashboardkeys.tablekeys.name")
                      ,
                      t("dashboards.alldashboardkeys.tablekeys.price"),
                      ,
                      t("dashboards.alldashboardkeys.tablekeys.action")
                    ]}
                    data={appointmentDat?.prescription?.tests?.map((item) => {
                      return [
                        item?.test_id,
                        item?.name,
                        item?.price,
                        Object.keys(item?.report).length > 1 ? (
                          // eslint-disable-next-line
                          <a
                            className={
                              item?.report?.status?.name === "report uploaded"
                                ? "btn btn-block order-pay-btn text-white"
                                : "btn btn-block btn-light  "
                            }
                            href={
                              item?.report?.status?.name === "report uploaded"
                                ? item?.report?.url
                                : null
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t("dashboards.alldashboardkeys.tablekeys.viewreport")}
                          </a>
                        ) : (
                          t("dashboards.alldashboardkeys.tablekeys.notavailable")
                        ),
                      ];
                    })}
                  />
                ) : // : selectedTab === "Follow Up" ? (
                  //   <FollowUpTab {...props} />
                  // )
                  null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PatientAppointmentDetails;
