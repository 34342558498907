import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../statics/icons/Toticell_logo.png";
import { loginUser } from "../../../store/actions/authActions";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import useMediaQuery from "../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { filterComponentData } from "../../../utils/functions";
const LoginPage = (props) => {
  const { t } = useTranslation("common");
  const loader = useSelector((state) => state?.logIn?.authLoader);
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const small = useMediaQuery("(min-width: 1450px)");
  const vsmall = useMediaQuery("(min-width: 380px)");
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);

  const loginRelAttribute = filterComponentData(
    component,
    "login_page_rel_attritbute",
    lang
  );
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const apiData = {
      email_address: data.email,
      password: data.password,
      fcm_token: localStorage.getItem("toticellfcm"),
    };
    const type = await dispatch(loginUser(apiData));
    if (type === "doctor") {
      props.history.push("/doctor-dashboard");
    } else if (type === "patient") {
      props.history.push("/patient-dashboard");
    } else if (type === "administrator") {
      props.history.push("/administrator-dashboard");
    } else if (type === "nurse") {
      props.history.push("/nurse-dashboard");
    } else if (type === "pharmacy") {
      props.history.push("/pharmacy-dashboard");
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {t("dashboards.alldashboardkeys.tablekeys.signintoticell")}
        </title>
      </Helmet>
      <div className="container-fluid loginContainer ">
        <div className="row">
          <div
            className={`col-xl-4 col-lg-4 col-md-6  login-left-banner d-none d-md-flex ${
              isDesktop ? "rightborder" : ""
            }`}
          >
            <img
              className="mx-auto"
              src={logo}
              alt="Toticell Logo"
              width={250}
              height={250}
            />
            <p
              style={{
                textAlign: "justify",
                color: "#21496a",
                fontSize: "16px",
              }}
            >
              {t("dashboards.alldashboardkeys.tablekeys.authtxt")}
            </p>
          </div>

          <div className="col-lg-8 col-md-6 col-12  center-children-class px-5 pb-3">
            <div className="container  center-children-class">
              <div className={!small ? "col-lg-10" : "col-lg-8"}>
                <h2 className="blue-heading">
                  {" "}
                  {t("dashboards.alldashboardkeys.tablekeys.welcomeback")}
                </h2>
                <strong>
                  {t("dashboards.alldashboardkeys.tablekeys.logintomanage")}
                </strong>
                <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <label for="firstName">
                      {t("dashboards.alldashboardkeys.tablekeys.emailaddress")}
                    </label>
                    <input
                      className="dashboards-input"
                      id="email"
                      type="email"
                      {...register("email", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.email?.message ? (
                      <div className="text-error">{errors?.email?.message}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mt-3">
                    <div className="container">
                      <div className="row">
                        <span className="col-lg-6 p-0 text-left">
                          {t("dashboards.alldashboardkeys.tablekeys.password")}
                        </span>
                        <span className="col-lg-6 p-0 text-right">
                          <Link
                            className="blue-heading pointer"
                            style={{ textDecoration: "none" }}
                            to="/forgotpass"
                          >
                            {t(
                              "dashboards.alldashboardkeys.tablekeys.forgotpassword"
                            )}{" "}
                            ?
                          </Link>
                        </span>
                      </div>
                    </div>

                    <input
                      className="dashboards-input"
                      id="password"
                      type="password"
                      {...register("password", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.password?.message ? (
                      <div
                        className="text-error"
                        // style={{ marginTop: isDesktop ? "20px" : "" }}
                      >
                        {errors?.password?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <p>
                    {t(
                      "dashboards.alldashboardkeys.tablekeys.dontthaveanaccount"
                    )}
                    &nbsp;
                    <strong>
                      <Link
                        className="blue-heading pointer"
                        style={{ textDecoration: "none" }}
                        to="/signup"
                      >
                        {t("dashboards.alldashboardkeys.tablekeys.signup")}
                      </Link>
                    </strong>
                  </p>
                  <div className="mt-3">
                    <div className="row d-flex justify-content-end">
                      <div
                        className="col-lg-4 text-left p-0"
                        style={{ marginTop: "5px" }}
                      >
                        {!small ? (
                          ""
                        ) : (
                          <button
                            type="submit"
                            className="loginBtn px-3 py-2 "
                            disabled={loader}
                            style={{ margin: "0px 0px 0px 8px" }}
                          >
                            {loader ? (
                              <Loader
                                height={20}
                                width={20}
                                type="ThreeDots"
                                color="#000"
                              />
                            ) : (
                              t("dashboards.alldashboardkeys.tablekeys.signin")
                            )}
                          </button>
                        )}
                      </div>
                      {!small ? (
                        <>
                          <div class="container-fluid full-width">
                            <div
                              class="row-fluid"
                              style={{ display: !vsmall ? "grid" : "" }}
                            >
                              <button
                                type="submit"
                                className="btn pull-left loginBtn mb-2 mr-2"
                                disabled={loader}
                                style={{ margin: "0px 0px 0px 8px" }}
                              >
                                {loader ? (
                                  <Loader
                                    height={20}
                                    width={20}
                                    type="ThreeDots"
                                    color="#000"
                                  />
                                ) : (
                                  t(
                                    "dashboards.alldashboardkeys.tablekeys.signin"
                                  )
                                )}
                              </button>
                              <button class="btn pull-right loginBtn mx-2 mb-2">
                                <a
                                  href="https://accounts.google.com/o/oauth2/v2/auth?client_id=847803953515-v5kvci1k4h661cc1jeivsbkjmnkcujk8.apps.googleusercontent.com&redirect_uri=https://www.toticell.com/auth-number-verification&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile&response_type=code&access_type=offline&prompt=consent"
                                  className="p-0 float-left"
                                  rel={
                                    loginRelAttribute?.google
                                      ? loginRelAttribute?.google
                                      : ""
                                  }
                                  target="_blank"
                                  style={{
                                    textDecoration: "none",
                                    color: "#AF5689",
                                  }}
                                >
                                  <img
                                    width="15px"
                                    style={{
                                      marginBottom: "3px",
                                      marginRight: "12px",
                                    }}
                                    alt="Google login"
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                                  />
                                  Google
                                </a>
                              </button>
                              <button class="btn pull-right loginBtn">
                                <a
                                  href="https://www.facebook.com/v12.0/dialog/oauth?client_id=1186216795452228&redirect_uri=https://www.toticell.com/auth-number-verification-facebook&scope=email,user_friends&response_type=token&auth_type=rerequest&display=popup"
                                  className="p-0 float-left"
                                  rel={
                                    loginRelAttribute?.facebook
                                      ? loginRelAttribute?.facebook
                                      : ""
                                  }
                                  target="_blank"
                                  style={{
                                    textDecoration: "none",
                                    color: "#AF5689",
                                  }}
                                >
                                  <img
                                    width="15px"
                                    style={{
                                      marginBottom: "3px",
                                      marginRight: "12px",
                                    }}
                                    alt="Google login"
                                    src="https://upload.wikimedia.org/wikipedia/commons/f/fb/Facebook_icon_2013.svg"
                                  />
                                  Facebook
                                </a>
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="col-lg-8 my-3 text-right px-4">
                          <a
                            href="https://accounts.google.com/o/oauth2/v2/auth?client_id=847803953515-v5kvci1k4h661cc1jeivsbkjmnkcujk8.apps.googleusercontent.com&redirect_uri=https://www.toticell.com/auth-number-verification&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile&response_type=code&access_type=offline&prompt=consent"
                            className="loginBtn mr-2 px-3 py-2"
                            target="_blank"
                            rel={
                              loginRelAttribute?.google
                                ? loginRelAttribute?.google
                                : ""
                            }
                            style={{ textDecoration: "none", color: "#AF5689" }}
                          >
                            <img
                              width="15px"
                              style={{
                                marginBottom: "3px",
                                marginRight: "5px",
                              }}
                              alt="Google login"
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                            />
                            Google
                          </a>
                          <a
                            href="https://www.facebook.com/v12.0/dialog/oauth?client_id=1186216795452228&redirect_uri=https://www.toticell.com/auth-number-verification-facebook&scope=email,user_friends&response_type=token&auth_type=rerequest&display=popup"
                            className="loginBtn mx-2 px-3 py-2"
                            target="_blank"
                            rel={
                              loginRelAttribute?.facebook
                                ? loginRelAttribute?.facebook
                                : ""
                            }
                            style={{ textDecoration: "none", color: "#AF5689" }}
                          >
                            <img
                              width="15px"
                              style={{
                                marginBottom: "3px",
                                marginRight: "5px",
                              }}
                              alt="Google login"
                              src="https://upload.wikimedia.org/wikipedia/commons/f/fb/Facebook_icon_2013.svg"
                            />
                            Facebook
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
