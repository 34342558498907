import React, { useEffect } from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import {
  addPhoneModalToggle,
  emailModalToggle,
  phoneModalToggle,
  sendPhoneOtp,
} from "../store/actions/authActions";
import Loader from "../components/reuseablecomponents/LoderContainer";
import AddPhoneNumber from "../components/layouts/modal/AddPhoneNumberModal/AddPhoneNumber";
import PhoneConfirmModal from "../components/layouts/modal/phoneConfirmModal/PhoneConfirmModal";
import EmailConfirmModal from "../components/layouts/modal/emailConfirmModal/EmailConfirmModal";
import { registerUserGoogle } from "../store/actions/authActions";
import { toast } from "react-toastify";

const GoogleSignup = (props) => {
  const params = queryString.parse(props.location.search);
  const phoneModal = useSelector((state) => state?.logIn?.addPhoneModal);
  const usernumber = useSelector((state) => state?.logIn?.userPhoneNum);
  const verifyphoneModal = useSelector((state) => state?.logIn?.phoneModal);
  const user = useSelector((state) => state?.logIn?.user);
  const emailModal = useSelector((state) => state?.logIn?.emailModal);
  const dispatch = useDispatch();
  const code = params.code;
  const apiData = {
    oauth_code: code,
  };
  useEffect(async () => {
    let success = await dispatch(registerUserGoogle(apiData));
    if (success == "fail") {
      props.history.push("/");
    } else if (success == "exist") {
      localStorage.removeItem("user");
      localStorage.removeItem("userRole");

      toast.error("User Already Exist");
      props.history.push("/");
    } else {
      if (success?.phone_number == "") {
        dispatch(addPhoneModalToggle(true));
      } else if (success?.phone_number_is_verified) {
        props.history.push("/");
      } else if (!success?.phone_number_is_verified) {
        dispatch(
          sendPhoneOtp({ id: success?.id, phone_number: success?.phone_number })
        );
        dispatch(phoneModalToggle(true));
      } else {
      }
    }
  }, []);
  return (
    <>
      {user ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <div></div>
      )}

      <AddPhoneNumber
        show={phoneModal}
        onHide={() => dispatch(addPhoneModalToggle(false))}
        {...props}
      />
      <PhoneConfirmModal
        register={true}
        show={verifyphoneModal}
        onHide={() => dispatch(phoneModalToggle(false))}
        {...props}
      />
      <EmailConfirmModal
        show={emailModal}
        onHide={() => dispatch(emailModalToggle(false))}
        {...props}
      />
    </>
  );
};

export default GoogleSignup;
