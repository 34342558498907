import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Testemonials from "../../../../../components/layouts/testimonials/Testemonials";
import TabBtnList from "../../../../../components/layouts/tabbuttons/DashboardBtnList";
import TabHandlerTextwithImage from "../../../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  cttreatment,
  imghy1,
  res4,
  LinksBanTreatmentCt,
  tabhandctc1,
  tabhandctc2,
  tabhandctc3,
  tabhandctc4,
} from "../../../../../data";
import HeadingWithBottomBar from "../../../../../components/reuseablecomponents/HeadingWithBottomBar";
import useMediaQuery from "../../../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Toggleform from "../../../../../components/layouts/toggleform/toggleform";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../../../utils/functions";

const MultipleSclerosis = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const stories = filterComponentData(component, "our_stories", lang);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );

  const enquiry = filterComponentData(
    component,
    "cupping_therapy_ct_make_an_enquiry_",
    lang
  );
  const therapy = filterComponentData(component, "multiple_sclerosis", lang);
  const { t } = useTranslation("common");

  const LinksBanTreatmentCt = [
    {
      subLinkName: banners[50]?.subtitle?.split(">")[0]
        ? banners[50]?.subtitle?.split(">")[0]
        : t("dryeyedisease.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[50]?.subtitle?.split(">")[1]
        ? banners[50]?.subtitle?.split(">")[1]
        : t("dryeyedisease.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[50]?.subtitle?.split(">")[2]
        ? banners[50]?.subtitle?.split(">")[2]
        : "Respiratory Diseases",
      subDash: ">",
      subLink: "/respiratory-diseases",
    },
    {
      subLinkName: banners[50]?.subtitle?.split(">")[3]
        ? banners[50]?.subtitle?.split(">")[3]
        : "Multiple Sclerosis",
      subDash: "",
      subLink: "/lungfibrosis",
    },
  ];

  const isDesktoplg = useMediaQuery("(min-width: 992px)");

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(
                item?.respiratoryMultiple ? item?.respiratoryMultiple : "[]"
              )
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.respiratoryMultiple
            ? specialityMetaTitles?.respiratoryMultiple
            : "Multiple Sclerosis"}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.respiratoryMultiple
              ? specialityMetaDesc?.respiratoryMultiple
              : "Multiple Sclerosis"
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.respiratoryMultiple
              ? specialityMetaKeywords?.respiratoryMultiple
              : "Multiple Sclerosis"
          }
        />
      </Helmet>
      <div className="">
        <BannerWithText
          heading={
            banners[50]?.heading ? banners[50]?.heading : "Multiple Sclerosis"
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[50]?.image ? banners[50]?.image : banner
          })`}
          imgSrc={banners[50]?.image ? banners[50]?.image : banner}
          LinksBan={LinksBanTreatmentCt}
          height={isDesktoplg ? "300px" : "330px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={therapy?.image ? therapy?.image : res4}
          alt={
            specialityAltTags?.respiratoryMultiple
              ? specialityAltTags?.respiratoryMultiple
              : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={
            therapy?.name ? therapy?.name : "What is Multiple Sclerosis"
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.desc ? (
                  <div dangerouslySetInnerHTML={{ __html: therapy?.desc }} />
                ) : (
                  <>
                    <p class="text-justify">
                      Multiple sclerosis (MS) is a condition that can affect the
                      brain and spinal cord, causing a wide range of potential
                      symptoms, including problems with vision, arm or leg
                      movement, sensation or balance.
                      <br />
                      It's a lifelong condition that can sometimes cause serious
                      disability, although it can occasionally be mild.
                    </p>
                    <h2
                      style={{
                        textTransform: "capitalize",
                        color: "#333333",
                      }}
                      className="gray-headin"
                    >
                      Stem Cell Therapy for Multiple Sclerosis
                    </h2>

                    <hr
                      style={{
                        borderBottom: "3px solid #AF5689",
                        margin: "10px 0px 15px 0px",
                        width: "80px",
                      }}
                    ></hr>
                    <p class="text-justify">
                      Stem cells can turn into different kinds of cells in your
                      body. hUC-MSCs make stem cell therapy can help improve a
                      person's symptoms and slow down the progression of the
                      disease.
                      <br />
                      Mesenchymal stem cells (MSCs) can combat inflammation
                      within the body. It helps to regulate the immune system &
                      prevents further myelin degradation.
                    </p>
                  </>
                )}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default MultipleSclerosis;
