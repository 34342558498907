import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../HomeCard/homeCard.css";
const DoctorCard = (props) => {
  const { t } = useTranslation("common");

  return (
    <div
      className={
        props?.btnshow ? "text-center" : "col-md-6 col-lg-4 mt-5 text-center"
      }
    >
      <div class="project-image">
        <div class="magnific-imgs ">
          <img
            src={props.imgSrc}
            alt={props?.alt ? props?.alt : props.cardHeading}
            className={
              props?.round ? `rounded-circle ${props?.className} ` : ""
            }
            style={{
              height: props?.round ? "200px" : "320px",
              width: props?.round ? "200px" : "",
            }}
          />
          <h3 className="text-center" style={{ whiteSpace: "pre-wrap" }}>
            {props?.cardHeading}{" "}
          </h3>
          <span class="designation" style={{ whiteSpace: "pre-wrap" }}>
            {props?.description}
          </span>{" "}
          {props?.btnshow ? (
            ""
          ) : (
            <Link
              style={{ width: "80px" }}
              className="btn btnSquare"
              to={{ pathname: "/our-doctors", state: props?.data }}
            >
              {props?.button ? props?.button : t("home.bio")}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default DoctorCard;
