import React, { useState } from "react";
import "./navbar.css";
import logo from "../../../statics/icons/Toticell_logo1.png";
import { Link } from "react-router-dom";
import SearchBar from "../searchComponent/SearchBar";
import { allarray } from "../../../data";
import profileLogo from "../../../statics/toticellimages/profile-logo.png";

import useMediaQuery from "../../reuseablecomponents/MediaQueryHook";

import { useDispatch, useSelector } from "react-redux";
import {
  emailModalToggle,
  logoutUser,
  phoneModalToggle,
} from "../../../store/actions/authActions";
import EmailConfirmModal from "../modal/emailConfirmModal/EmailConfirmModal";
import PhoneConfirmModal from "../modal/phoneConfirmModal/PhoneConfirmModal";
import ChangePasswordModal from "../modal/changePasswordModal/ChangePasswordModal";
import { getallNotification } from "../../../store/actions/staticActions";
import NotificationModal from "../modal/notificationModal/NotificationModal";
import { useTranslation } from "react-i18next";
import { readLanguageAction } from "../../../store/actions/cmsAction";
import { filterComponentData } from "../../../utils/functions";

const Navbar = (props) => {
  const { t } = useTranslation("common");
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const component = useSelector((state) => state?.cmsReducer?.components);
  const header = filterComponentData(component, "header", lang);
  
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const emailModal = useSelector((state) => state?.logIn?.emailModal);
  const userRole = useSelector((state) => state?.logIn?.userRole);
  const phoneModal = useSelector((state) => state?.logIn?.phoneModal);
  const [notificationModal, setNotificationModal] = useState(false);
  const [showchangepassword, setshowchangepassword] = useState(false);
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logoutUser());
    dispatch(readLanguageAction())
    props?.history?.push("/");
  };
  const user = useSelector((state) => state?.logIn?.user);
  const profileHandler = () => {
    if (userRole === "administrator") {
      props?.history.push("/administrator-dashboard");
    } else if (userRole === "doctor") {
      props?.history.push("/doctor-dashboard");
    } else if (userRole === "nurse") {
      props?.history.push("/nurse-dashboard");
    } else if (userRole === "pharmacy") {
      props?.history.push("/pharmacy-dashboard");
    } else if (userRole === "patient") {
      props?.history.push("/patient-dashboard");
    }
  };

  const userProfileHandler = () => {
    if (userRole === "administrator") {
      props?.history.push("/administrator-dashboard-profile");
    } else if (userRole === "doctor") {
      props?.history.push("/doctor-dashboard-profile");
    } else if (userRole === "nurse") {
      props?.history.push("/nurse-dashboard-profile");
    }
    // else if (userRole === "pharmacy") {
    //   props?.history.push("/pharmacy-dashboard-profile");
    // }
    else if (userRole === "patient") {
      props?.history.push("/patient-dashboard-profile");
    }
  };

  const AllmergeData = [];
  allarray.map((item) => {
    return AllmergeData.push(item);
  });

  return (
    <>
      <nav className="navbar navbar-expand-xl navbar-light bg-white sticky-top custom-nav ">
        <div className="container containenavcustome">
          <Link className="nav-logo" to="/">
            <img src={header?.logo ? header?.logo:logo} alt="logo" />
            {isDesktop ? (
              <span>
                {header?.logoText1 ? header?.logoText1:t("navbar.navhead1")} &amp;
                <br /> {header?.logoText2 ? header?.logoText2:t("navbar.navhead2")}
              </span>
            ) : (
              ""
            )}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li
                className="nav-item mb-2 dropdown mb-2"
                style={{ marginRight: "10px" }}
              >
                <Link
                  className="nav-link"
                  to="/about"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="text-uppercase">{header?.about ? header?.about:t("navbar.navtxt1")}</span>
                  <span class="submenu-indicator">
                    <span class="submenu-indicator-chevron"></span>
                  </span>
                </Link>

                <div className="h-100" style={{ cursor: "pointer" }}></div>

                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/abouttoti">
                    {header?.aboutoti ? header?.aboutoti:t("navbar.navtxt1drop.navtxtdrop1")}
                  </Link>
                  <Link className="dropdown-item" to="/our-management">
                    {header?.managment ? header?.managment:t("navbar.navtxt1drop.navtxtdrop2")}
                  </Link>
                  <Link className="dropdown-item" to="/our-mission">
                    {header?.mission ? header?.mission:t("navbar.navtxt1drop.navtxtdrop3")}
                  </Link>
                  <Link className="dropdown-item" to="/our-doctors">
                    {header?.doctor ? header?.doctor:t("navbar.navtxt1drop.navtxtdrop4")}
                  </Link>
                </div>
              </li>

              <li
                className="nav-item mb-2 dropdown mb-2"
                style={{ marginRight: "10px" }}
              >
                <Link
                  className="nav-link"
                  to="/treatment"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="text-uppercase">{header?.treatment ? header?.treatment:t("navbar.navtxt2")}</span>
                  <span class="submenu-indicator">
                    <span class="submenu-indicator-chevron"></span>
                  </span>
                </Link>
                <div className="h-100" style={{ cursor: "pointer" }}></div>

                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/prp-therapy">
                    {header?.prp ? header?.prp:t("navbar.navtxt2drop.navtxtdrop1")}
                  </Link>
                  <Link className="dropdown-item" to="/platelet-lysate">
                      {header?.pl ? header?.pl:'Platelet Lysate'}
                  </Link>
                 
                  <Link className="dropdown-item" to="/svf-therapy">
                    {header?.svf ? header?.svf:t("navbar.navtxt2drop.navtxtdrop3")}
                  </Link>
                  <Link className="dropdown-item" to="/msc-therapy">
                    {header?.msc ? header?.msc:t("navbar.navtxt2drop.navtxtdrop4")}
                  </Link>
                  <Link className="dropdown-item" to="/mnc-therapy">
                    {header?.mnc ? header?.mnc:t("navbar.navtxt2drop.navtxtdrop5")}
                  </Link>
                  <Link className="dropdown-item" to="/eswt-therapy">
                    {header?.eswt ? header?.eswt:t("navbar.navtxt2drop.navtxtdrop6")}
                  </Link>
                  <Link className="dropdown-item" to="/hbot-therapy">
                    {header?.hbot ? header?.hbot:t("navbar.navtxt2drop.navtxtdrop7")}
                  </Link>
                  <Link className="dropdown-item" to="/ct-therapy">
                    {header?.ct ? header?.ct:t("navbar.navtxt2drop.navtxtdrop8")}
                  </Link>
                  <Link className="dropdown-item" to="/bmc-therapy">
                    {header?.bmc ? header?.bmc:t("navbar.navtxt2drop.navtxtdrop2")}
                  </Link>
                  
                  <Link className="dropdown-item" to="/hyperthermia-therapy">
                      {header?.ht ? header?.ht:'Hyperthermia Therapy'}
                  </Link>
                </div>
              </li>

              <li
                className="nav-item mb-2 dropdown mb-2"
                style={{ marginRight: "10px" }}
              >
                <Link
                  className="nav-link"
                  to="/Speciality"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="text-uppercase">{header?.speciality ? header?.speciality:t("navbar.navtxt3")}</span>
                  <span class="submenu-indicator">
                    <span class="submenu-indicator-chevron"></span>
                  </span>
                </Link>
                <div className="h-100" style={{ cursor: "pointer" }}></div>

                <ul class="dropdown-menu">
                  {/* First DropDown Option Nested Menu */}

                  <li>
                    <Link class="dropdown-item" to="/covid-19-management">
                      <span className="text-capitalize">
                        {header?.covid ? header?.covid:t("navbar.navtxt3drop.navtxtdrop1")}
                      </span>
                      <span class="submenu-indicator">
                        <span
                          class={
                            isDesktop
                              ? "submenu_indicator_chevron_custom"
                              : "submenu-indicator-chevron"
                          }
                        ></span>
                      </span>
                    </Link>
                    <ul class="submenu dropdown-menu">
                      <li>
                        <Link class="dropdown-item" to="/molecular-diagnosis">
                          {header?.m0oleculardiagnosis ? header?.m0oleculardiagnosis:t("navbar.navtxt3drop.navtxtdropsub1.navtxtdrop1")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/serology">
                          {header?.serology ? header?.serology:t("navbar.navtxt3drop.navtxtdropsub1.navtxtdrop2")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          class="dropdown-item"
                          to="/convalescent-plasma-therapy"
                        >
                          {header?.cpt ? header?.cpt:t("navbar.navtxt3drop.navtxtdropsub1.navtxtdrop3")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/stem-cell-therapy">
                          {header?.sct ? header?.sct:t("navbar.navtxt3drop.navtxtdropsub1.navtxtdrop4")}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* Second DrpMenu */}
                  <li>
                    <Link class="dropdown-item" to="/pain-management">
                      <span className="text-capitalize">
                        {" "}
                        {header?.pain ? header?.pain:t("navbar.navtxt3drop.navtxtdrop2")}
                      </span>
                      <span class="submenu-indicator">
                        <span
                          class={
                            isDesktop
                              ? "submenu_indicator_chevron_custom"
                              : "submenu-indicator-chevron"
                          }
                        ></span>
                      </span>
                    </Link>
                    <ul class="submenu dropdown-menu">
                      <li>
                        <Link class="dropdown-item" to="/knee-pain">
                          {header?.kneepain ? header?.kneepain:t("navbar.navtxt3drop.navtxtdropsub2.navtxtdrop1")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/back-pain">
                          {header?.backpain ? header?.backpain:t("navbar.navtxt3drop.navtxtdropsub2.navtxtdrop2")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          class="dropdown-item"
                          to="/frozen-shoulder-shoulder-pain"
                        >
                          {header?.sholderpain ? header?.sholderpain:t("navbar.navtxt3drop.navtxtdropsub2.navtxtdrop3")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/plantar-fasciitis">
                          {header?.heelpain ? header?.heelpain:t("navbar.navtxt3drop.navtxtdropsub2.navtxtdrop4")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/tennis-elbow">
                        {header?.tenniselbow ? header?.tenniselbow:'Tennis Elbow'}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/rheumatoid-arthritis">
                        {header?.arthritis ? header?.arthritis:'Rheumatoid Arthritis'}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/carpal-tunnel-syndrome">
                       {header?.carpal ? header?.carpal:'Carpal Tunnel Syndrome'}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* Third Menu */}
                  <li>
                    <Link class="dropdown-item" to="/asthetic-medicine">
                      <span className="text-capitalize">
                        {header?.Aestheticm ? header?.Aestheticm:t("navbar.navtxt3drop.navtxtdrop3")}
                      </span>
                      <span class="submenu-indicator">
                        <span
                          class={
                            isDesktop
                              ? "submenu_indicator_chevron_custom"
                              : "submenu-indicator-chevron"
                          }
                        ></span>
                      </span>
                    </Link>
                    <ul class="submenu dropdown-menu">
                      <li>
                        <Link class="dropdown-item" to="/erectile-dysfunction">
                          {header?.erectile ? header?.erectile:t("navbar.navtxt3drop.navtxtdropsub3.navtxtdrop1")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          class="dropdown-item"
                          to="/womens-intimate-health"
                        >
                          {header?.womenhealth ? header?.womenhealth:t("navbar.navtxt3drop.navtxtdropsub3.navtxtdrop2")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/hair-loss">
                          {header?.hairloss ? header?.hairloss:t("navbar.navtxt3drop.navtxtdropsub3.navtxtdrop3")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/facial-rejuvenation">
                          {header?.facialrejo ? header?.facialrejo:t("navbar.navtxt3drop.navtxtdropsub3.navtxtdrop4")}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* Fourth Menu */}
                  <li>
                    <Link
                      class="dropdown-item"
                      to="/neurological-rehabilitation"
                    >
                      <span className="text-capitalize">
                        {header?.nr ? header?.nr:t("navbar.navtxt3drop.navtxtdrop4")}
                      </span>
                      <span class="submenu-indicator">
                        <span
                          class={
                            isDesktop
                              ? "submenu_indicator_chevron_custom"
                              : "submenu-indicator-chevron"
                          }
                        ></span>
                      </span>
                    </Link>
                    <ul class="submenu dropdown-menu">
                      <li>
                        <Link class="dropdown-item" to="/autism">
                          {header?.autism ? header?.autism:t("navbar.navtxt3drop.navtxtdropsub4.navtxtdrop1")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/muscular-dystrophy">
                          {header?.musculardy ? header?.musculardy:t("navbar.navtxt3drop.navtxtdropsub4.navtxtdrop2")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/cerebral-palsy">
                          {header?.cerebralpalsy ? header?.cerebralpalsy:t("navbar.navtxt3drop.navtxtdropsub4.navtxtdrop3")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/spinal-cord-injury">
                          {header?.spinalcord ? header?.spinalcord:t("navbar.navtxt3drop.navtxtdropsub4.navtxtdrop4")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/downs-syndrome">
                          {header?.downsyndrome ? header?.downsyndrome:t("navbar.navtxt3drop.navtxtdropsub4.navtxtdrop5")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/alzheimer-disease">
                        {header?.alizheimer ? header?.alizheimer:'Alzheimer’s Disease'}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/motor-neuron-disease">
                        {header?.motor ? header?.motor:'Motor Neuron Disease'}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* Fifth Menu */}
                  <li>
                    <Link class="dropdown-item" to="/stem-cell-banking">
                      <span className="text-capitalize">
                        {header?.scb ? header?.scb:t("navbar.navtxt3drop.navtxtdrop5")}
                      </span>
                      <span class="submenu-indicator">
                        <span
                          class={
                            isDesktop
                              ? "submenu_indicator_chevron_custom"
                              : "submenu-indicator-chevron"
                          }
                        ></span>
                      </span>
                    </Link>
                    <ul class="submenu dropdown-menu">
                      <li>
                        <Link class="dropdown-item" to="/cord-blood-banking">
                          {header?.cordblood ? header?.cordblood:t("navbar.navtxt3drop.navtxtdropsub5.navtxtdrop1")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/cord-tissue-banking">
                          {header?.cordtissue ? header?.cordtissue:t("navbar.navtxt3drop.navtxtdropsub5.navtxtdrop2")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/community-banking">
                          {header?.communitybanking ? header?.communitybanking:t("navbar.navtxt3drop.navtxtdropsub5.navtxtdrop3")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/family-banking">
                          {header?.familybanking ? header?.familybanking:t("navbar.navtxt3drop.navtxtdropsub5.navtxtdrop4")}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* Sixth Menu */}
                  <li>
                    <Link class="dropdown-item" to="/diabetes">
                      <span className="text-capitalize">
                        {header?.diabetes ? header?.diabetes:t("navbar.navtxt3drop.navtxtdrop6")}
                      </span>
                      <span class="submenu-indicator">
                        <span
                          class={
                            isDesktop
                              ? "submenu_indicator_chevron_custom"
                              : "submenu-indicator-chevron"
                          }
                        ></span>
                      </span>
                    </Link>
                    <ul class="submenu dropdown-menu">
                      <li>
                        <Link class="dropdown-item" to="/diabetic-foot-ulcer">
                          {header?.foodulcre ? header?.foodulcre:t("navbar.navtxt3drop.navtxtdropsub6.navtxtdrop1")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/dry-eye-disease">
                          {header?.dryeye ? header?.dryeye:t("navbar.navtxt3drop.navtxtdropsub6.navtxtdrop2")}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/type2-diabetes-mellitus">
                        {header?.diabetesmel ? header?.diabetesmel:'Type 2 Diabetes Mellitus'}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* Seventh Menu */}
                  <li>
                    <Link class="dropdown-item" to="/respiratory-diseases">
                      <span className="text-capitalize">
                      {header?.rd ? header?.rd:'Respiratory Diseases'}
                      </span>
                      <span class="submenu-indicator">
                        <span
                          class={
                            isDesktop
                              ? "submenu_indicator_chevron_custom"
                              : "submenu-indicator-chevron"
                          }
                        ></span>
                      </span>
                    </Link>
                    <ul class="submenu dropdown-menu">
                      <li>
                        <Link class="dropdown-item" to="/asthma">
                        {header?.asthema ? header?.asthema:'Asthma'}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/lungfibrosis">
                        {header?.lungfib ? header?.lungfib:'Lung Fibrosis'}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/ankylosingspondylitis">
                        {header?.ankylosing ? header?.ankylosing:'Ankylosing Spondylitis'}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/multiplesclerosis">
                        {header?.multiplescl ? header?.multiplescl:'Multiple Sclerosis'}
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/ulcerativecolitis">
                        {header?.ulcer ? header?.ulcer:'Ulcerative Colitis'}
                      </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li className="nav-item mb-2" style={{ marginRight: "10px" }}>
                <Link className="nav-link " to="/evidence">
                  <span className="text-uppercase">
                    {" "}
                    {header?.evidence ? header?.evidence:t("navbar.navtxt4")}
                  </span>
                </Link>
              </li>
              <li className="nav-item mb-2" style={{ marginRight: "10px" }}>
                <Link className="nav-link " to="/stories">
                  <span className="text-uppercase">
                    {header?.storeies ? header?.storeies:t("navbar.navtxt41")}

                  </span>
                </Link>
              </li>
              <li className="nav-item mb-2" style={{ marginRight: "10px" }}>
                <Link className="nav-link " to="/contact-us">
                  <span className="text-uppercase">{header?.contact ? header?.contact:t("navbar.navtxt5")}</span>
                </Link>
              </li>
              {/* <li className="nav-item mb-3" style={{ marginRight: "10px" }}>

                <Link className="nav-link " to="/login">
                  <span className="text-uppercase"><i class="fa fa-user"></i> Login</span>
                </Link>
              </li> */}

              {user ? (
                <>
                  <li className="nav-item dropdown p-0 mb-2 ">
                    <div
                      className="nav-link p-0"
                      id="navbarDropdown"
                      role="button"
                      to="#"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={profileLogo}
                        alt="user"
                        width="25"
                        height="25"
                        className="rounded-circle p-0"
                      />
                      <span class="submenu-indicator">
                        <span class="submenu-indicator-chevron"></span>
                      </span>
                    </div>
                    <div className="h-100" style={{ cursor: "pointer" }}></div>

                    <div
                      className="dropdown-menu dropdown-menu-right profile-nav-dropdown"
                      aria-labelledby="navbarDropdown"
                    >
                      <div
                        to="#"
                        className="dropdown-item-user "
                        style={{ textDecoration: "none", color: "#af5689" }}
                      // onClick={() => profileHandler()}
                      >
                        <span className="">{user?.name}</span>
                      </div>
                      <hr className="my-0 mx-3 p-0" />
                      <Link
                        to="#"
                        className="dropdown-item "
                        onClick={() => profileHandler()}
                      >
                        <i className="fa fa-id-card "></i>&nbsp;&nbsp;{t("navbar.navtxt6")}
                      </Link>
                      {userRole === "patient" || userRole === "doctor" ? (
                        <Link
                          to="#"
                          className="dropdown-item "
                          onClick={() => userProfileHandler()}
                        // style={{ color:'#0066b3' ,fontWeight:'600' }}
                        >
                          <i className="fa fa-user "></i>
                          &nbsp;&nbsp;&nbsp;&nbsp;{t("navbar.navprof")}
                        </Link>
                      ) : null}

                      {/* <Link className="dropdown-item " to="#">
                        <i className="fa fa-bell "></i> &nbsp;Notifications
                      </Link> */}
                   

                      {/* Change Password */}
                      {user?.register_channel?.id == 1 ? <Link
                        to="#"
                        className="dropdown-item "
                        onClick={() => {
                          setshowchangepassword(true);
                        }}
                      >
                        <i className="fa fa-key"></i>
                        &nbsp;&nbsp;{t("navbar.navchangpas")}
                      </Link> : null}
                      <div
                        className="dropdown-item navmenu_custome_li"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setNotificationModal(true);
                          dispatch(getallNotification({ id: user?.id }));
                        }}
                      >
                        <i
                          className="fas fa-bell"
                          style={{ fontSize: "14.5px" }}
                        ></i>
                        &nbsp;&nbsp; {t("navbar.navtxt7")}
                      </div>

                      <Link
                        to="#"
                        className="dropdown-item "
                        onClick={() => logoutHandler()}
                      // style={{ color:'#0066b3' ,fontWeight:'600' }}
                      >
                        <i className="fa fa-sign-out "></i>
                        &nbsp;&nbsp;&nbsp;{t("navbar.navtxt8")}
                      </Link>
                      {/* Profile */}
                    </div>
                  </li>
                </>
              ) : (
                <li className="nav-item mb-3">
                  <Link className="nav-link" to="/login">
                    <span
                      className="text-uppercase font-weight-bold p-2 "
                      style={{
                        border: "2px solid #af5689",
                        color: "#af5689",
                        fontSize: "12px",
                        borderRadius: "5px",
                      }}
                    >
                      <i class="fa fa-user"></i> {header?.loginButton ? header?.loginButton:  t("navbar.navbtnlogtxt")}
                    </span>
                  </Link>
                </li>
              )}

              <li className="nav-item mx-3" style={{ marginRight: "10px" }}>
                <SearchBar placeholder="Search" data={AllmergeData} />
              </li>
            </ul>
          </div>
          <EmailConfirmModal
            show={emailModal}
            onHide={() => dispatch(emailModalToggle(false))}
            {...props}
          />
          <PhoneConfirmModal
            show={phoneModal}
            onHide={() => dispatch(phoneModalToggle(false))}
            {...props}
          />
          <ChangePasswordModal
            show={showchangepassword}
            onHide={() => setshowchangepassword(false)}
            {...props}
          />
          <NotificationModal
            show={notificationModal}
            onHide={() => setNotificationModal(false)}
            {...props}
          />
        </div>
      </nav>
    </>
  );
};

export default Navbar;
