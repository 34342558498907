import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerWithText from "../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Testemonials from "../../../components/layouts/testimonials/Testemonials";
import TabBtnList from "../../../components/layouts/tabbuttons/DashboardBtnList";
import TabHandlerTextwithImage from "../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  cttreatment,
  imghy1,
  LinksBanTreatmentCt,
  tabhandctc1,
  tabhandctc2,
  tabhandctc3,
  tabhandctc4,
} from "../../../data";
import HeadingWithBottomBar from "../../../components/reuseablecomponents/HeadingWithBottomBar";
import useMediaQuery from "../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Toggleform from "../../../components/layouts/toggleform/toggleform";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../utils/functions";

const HyperthermiaTherapy = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);

  const stories = filterComponentData(component, "our_stories", lang);
  const enquiry = filterComponentData(
    component,
    "cupping_therapy_ct_make_an_enquiry_",
    lang
  );
  const treatmentMetaTitles = filterComponentData(
    component,
    "treatment_meta_titles",
    lang
  );
  const treatmentMetaDesc = filterComponentData(
    component,
    "treatment_meta_descriptions",
    lang
  );
  const treatmentSchemaMarkup = filterComponentData(
    component,
    "treatment_schema_markups",
    lang
  );
  const treatmentMetaKeywords = filterComponentData(
    component,
    "treatment_meta_keywords",
    lang
  );
  const treatmentAltTags = filterComponentData(
    component,
    "treatment_image_alt_tags",
    lang
  );
  const treatmentVerification = filterComponentData(
    component,
    "treatment_verification_meta_tags",
    lang
  );
  const therapy = filterComponentData(component, "hyperthermia_therapy", lang);
  const { t } = useTranslation("common");

  const LinksBanTreatmentCt = [
    {
      subLinkName: banners[15]?.subtitle?.split(">")[0]
        ? banners[15]?.subtitle?.split(">")[0]
        : t("cttherapy.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[15]?.subtitle?.split(">")[1]
        ? banners[15]?.subtitle?.split(">")[1]
        : t("cttherapy.banner.title1"),
      subDash: ">",
      subLink: "/treatment",
    },
    {
      subLinkName: banners[15]?.subtitle?.split(">")[2]
        ? banners[15]?.subtitle?.split(">")[2]
        : "Hyperthermia Therapy",
      subDash: "",
      subLink: "/platelet-lysate",
    },
  ];

  const [toggle, setToggle] = useState(false);

  const isDesktoplg = useMediaQuery("(min-width: 992px)");
  const verificationsTags = treatmentVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.hyperthermiaName,
      content: item?.hyperthermiaContent,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}
        {treatmentSchemaMarkup?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.hyperthermia ? item?.hyperthermia : "[]")
            )}
          </script>
        ))}
        <title>
          {treatmentMetaTitles?.hyperthermia
            ? treatmentMetaTitles?.hyperthermia
            : "Hyperthermia Therapy"}
        </title>
        <meta
          name="description"
          content={
            treatmentMetaDesc?.hyperthermia
              ? treatmentMetaDesc?.hyperthermia
              : "Hyperthermia Therapy"
          }
        />
        <meta
          name="keywords"
          content={
            treatmentMetaKeywords?.hyperthermia
              ? treatmentMetaKeywords?.hyperthermia
              : "Hyperthermia Therapy"
          }
        />
      </Helmet>
      <div className="">
        <BannerWithText
          heading={
            banners[15]?.heading ? banners[15]?.heading : "Hyperthermia Therapy"
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[15]?.image ? banners[15]?.image : banner
          })`}
          imgSrc={banners[15]?.image ? banners[15]?.image : banner}
          LinksBan={LinksBanTreatmentCt}
          height={isDesktoplg ? "300px" : "330px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={therapy?.image ? therapy?.image : imghy1}
          imgCol="col-lg-5"
          alt={
            treatmentAltTags?.hyperthermia ? treatmentAltTags?.hyperthermia : ""
          }
          textCol="col-lg-7"
          headingFirst={therapy?.name ? therapy?.name : "Hyperthermia Therapy"}
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.desc ? (
                  <div dangerouslySetInnerHTML={{ __html: therapy?.desc }} />
                ) : (
                  <>
                    <p class="text-justify">
                      Hyperthermia is heat treatment, which is a variety of
                      forms, has been used in medicine for thousands of year. In
                      All hyperthermia methods, the temperature of tissue/body
                      is elevated artificially with the aim of receiving
                      therapeutic benefits.
                      <br />
                      The infrared radiation used has a high proportion of short
                      wavelengths (infrared A) close to the range of light and
                      is radiated along with light.
                    </p>
                    <div>
                      <h2
                        style={{
                          textTransform: "capitalize",
                          color: "#333333",
                        }}
                        className="gray-headin"
                      >
                        Benefits of Hyperthermia:
                      </h2>

                      <hr
                        style={{
                          borderBottom: "3px solid #AF5689",
                          margin: "10px 0px 15px 0px",
                          width: "80px",
                        }}
                      ></hr>

                      <div class="span-department">
                        <ul>
                          <li>
                            <i class="fa fa-check"></i>
                            Enhanced tissue perfusion
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Relaxation of skeletal muscles
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Reduction of soft tissue
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Beneficial method of pain treatment
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
                {/* <br/><br/>
            {t("cttherapy.textrev.discriptiontxt2")} */}
              </p>
            </>
          }
          reverse={false}
        />

        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-12">
              <div class="section" onClick={() => setToggle(!toggle)}>
                <span class="makeinquirybt">
                  {enquiry?.button
                    ? enquiry?.button
                    : t("makeanenquiry.enquirybtn")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toggleform toggle={toggle} data={enquiry} />
      <div>
        <HeadingWithBottomBar heading={t("cttherapy.patientfeedhead")} />

        <Testemonials stories={stories} />
      </div>
    </>
  );
};

export default HyperthermiaTherapy;
