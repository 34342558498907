export const doctorConstants = {
  MY_PATIENTS: "MY_PATIENTS",
  ALL_MEDICINES: "ALL_MEDICINES",
  ALL_TESTS: "ALL_TESTS",
  MY_BOARDS: "MY_BOARDS",
  DOCTOR_LOADER: 'DOCTOR_LOADER',
  TEST_LOADER: 'TEST_LOADER',
  UPDATE_BOARD: "UPDATE_BOARD"


};

export const signUpConstansts = {
  SHOW_MODAL: "SHOW_MODAL",
  REGISTER_PATIENT: "REGISTER_PATIENT",
}