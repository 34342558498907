import React from "react";

const TextAndImageSection = (props) => {
  const ImgDiv = (
    <div className={props.imgCol ? props.imgCol : "col-lg-6"}>
      <img
        // src="https://storage.googleapis.com/totticell-fa701.appspot.com/images/4e665e91-6cd5-4c85-b111-4e6e1532babb_2022_02_27-15_34.jpg"
        src={props.imgSrc}
        alt={
          props?.alt
            ? props?.alt
            : props.imgHtml || !props.imgSrc
            ? ""
            : props.headingFirst
        }
        style={props.imgStyle}
        className="img-fluid"
      />
      {props.imgHtml}
    </div>
  );
  const textDiv = (
    <div className={props.textCol ? props.textCol : "col-lg-6 mt-3"}>
      <div className="heading-wraper">
        <h2
          style={{
            textTransform: "capitalize",
            color: props.stylehead ? props.stylehead : "#333333",
          }}
          className="gray-headin"
        >
          {props.imgopt ? (
            <img
              src={props.imgopt}
              alt={props.imgHtml || !props.imgSrc ? "" : props.headingFirst}
              style={{ width: "70px", height: "60px" }}

              // className="img-fluid"
            />
          ) : (
            ""
          )}{" "}
          {props.headingFirst} <br /> {props.headingSec}
        </h2>

        {props.hrTrue ? (
          <></>
        ) : (
          <hr
            style={{
              borderBottom: "3px solid #AF5689",
              margin: "10px 0px 15px 0px",
              width: "80px",
            }}
          ></hr>
        )}
      </div>
      <div className="text-wraper text-justify">{props.discription}</div>
      <div>{props.buttoncustom}</div>
    </div>
  );
  return (
    <div className="container mt-5">
      <div className="row">
        {props.reverse ? (
          <>
            {ImgDiv}
            {textDiv}
          </>
        ) : (
          <>
            {textDiv}
            {ImgDiv}
          </>
        )}
      </div>
    </div>
  );
};

export default TextAndImageSection;
