import React from "react";

const ChatIframe = (props) => {
 const  {user_id,appointment_id,board,name} = props
  // appointment_id={viewData?.id}
  // board={false}
  // const appointment_id = props?.match?.params?.id;
  // const user_id = props?.match?.params?.user_id;
  //   const name = props?.match?.params?.name;
  // const board = props?.match?.params?.board;
  
  return (
    <div className="row center-children-class" style={{height:'100vh'}}>
        <div className="col-lg-10 containerWithShadow center-childrens py-1 p-0" style={{height:'80vh'}}>
          <iframe
            className="chat-frame mt-5"
            src={`https://toticell.com/chat/chat?name=${user_id}&room=${appointment_id}&friendlyName=${name}`}
            // src={`https://chattoticell.herokuapp.com/?channel_id=${appointment_id}&user_id=${user_id}&doctors_board=${board}`}
            title="chatIframe"
          ></iframe>
        </div>
    </div>
  );
};

export default ChatIframe;
