import React from "react";
import "./testemonials.css";
import img1 from "../../../statics/toticellimages/Abu-Ahmed-Kadir.jpg";
import img2 from "../../../statics/toticellimages/Riad-Md-Abrar.jpg";
import img3 from "../../../statics/toticellimages/Farzana-Siddiqi-Rose.jpg";
import { useTranslation } from "react-i18next";

const Testemonials = ({ stories }) => {
  const { t } = useTranslation("common");
  const testemonialsData = [
    {
      img: img1,
      review: t("testimonials.card1.review"),
      name: t("testimonials.card1.name"),
      link: "#",
    },
    {
      img: img2,
      review: t("testimonials.card2.review"),
      name: t("testimonials.card2.name"),
      link: "#",
    },
    {
      img: img3,
      review: t("testimonials.card3.review"),
      name: t("testimonials.card3.name"),
      link: "#",
    },
  ];
  return (
    <div className="container">
      <div className="row">
        {stories?.length < 1
          ? testemonialsData.map((item, index) => (
              <div className="col-lg-4">
                <div
                  className="center-children-class main-testemonail"
                  key={index + 1}
                >
                  <div className="avatar">
                    <img
                      width="100%"
                      height="100%"
                      src={item.img}
                      alt="testemonial"
                      className="testemonial-img"
                    />
                  </div>
                  <p className="testemonial-review mt-4">{item.review}</p>
                  <p className="light-Color-Para">{item.name}</p>
                  <div class="customers-rating">
                    <div class="inner-stars">
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </div>
                    <div class="inner-stars">
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </div>
                    <div class="inner-stars">
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </div>
                    <div class="inner-stars">
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </div>
                    <div class="inner-stars">
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : stories?.map((item, index) => (
              <div className="col-lg-4">
                <div
                  className="center-children-class main-testemonail"
                  key={index + 1}
                >
                  <div className="avatar">
                    <img
                      src={item.image}
                      alt="testemonial"
                      className="testemonial-img"
                    />
                  </div>
                  <p className="testemonial-review mt-4">{item.review}</p>
                  <p className="light-Color-Para">{item.name}</p>
                  <div class="customers-rating">
                    <div class="inner-stars">
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </div>
                    <div class="inner-stars">
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </div>
                    <div class="inner-stars">
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </div>
                    <div class="inner-stars">
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </div>
                    <div class="inner-stars">
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default Testemonials;
