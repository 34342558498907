import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import { stmenucard1, banner } from "../../../../../data";
import { filterComponentData } from "../../../../../utils/functions";

const CordBloodBanking = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const cards = filterComponentData(
    component,
    "speciality_cord_blood_banking",
    lang
  );
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );
  const { t } = useTranslation("common");
  const LinksBanSpecialityStcbCordBloodBanking = [
    {
      subLinkName: banners[38]?.subtitle?.split(">")[0]
        ? banners[38]?.subtitle?.split(">")[0]
        : t("cordbloodbanking.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[38]?.subtitle?.split(">")[1]
        ? banners[38]?.subtitle?.split(">")[1]
        : t("cordbloodbanking.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[38]?.subtitle?.split(">")[2]
        ? banners[38]?.subtitle?.split(">")[2]
        : t("cordbloodbanking.banner.title1"),
      subDash: ">",
      subLink: "/stem-cell-banking",
    },
    {
      subLinkName: banners[38]?.subtitle?.split(">")[3]
        ? banners[38]?.subtitle?.split(">")[3]
        : t("cordbloodbanking.banner.title2"),
      subDash: "",
      subLink: "/cord-blood-banking",
    },
  ];
  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.stemCordBlood ? item?.stemCordBlood : "[]")
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.stemCordBlood
            ? specialityMetaTitles?.stemCordBlood
            : t("cordbloodbanking.maintitle")}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.stemCordBlood
              ? specialityMetaDesc?.stemCordBlood
              : t("cordbloodbanking.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.stemCordBlood
              ? specialityMetaKeywords?.stemCordBlood
              : t("cordbloodbanking.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[38]?.heading
              ? banners[38]?.heading
              : t("cordbloodbanking.banner.title2")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[38]?.image ? banners[38]?.image : banner
          })`}
          imgSrc={banners[38]?.image ? banners[38]?.image : banner}
          LinksBan={LinksBanSpecialityStcbCordBloodBanking}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={cards?.image ? cards?.image : stmenucard1}
          alt={
            specialityAltTags?.stemCordBlood
              ? specialityAltTags?.stemCordBlood
              : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={
            cards?.heading
              ? cards?.heading
              : t("cordbloodbanking.textrev.headfirst")
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {cards?.desc1
                  ? cards?.desc1
                  : t("cordbloodbanking.textrev.discriptiontxt1")}
                <br />
                <br />
                {cards?.desc2
                  ? cards?.desc2
                  : t("cordbloodbanking.textrev.discriptiontxt2")}
                <br />
                <br />
                {cards?.desc3
                  ? cards?.desc3
                  : t("cordbloodbanking.textrev.discriptiontxt3")}
                <br />
                <br />
              </p>
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default CordBloodBanking;
