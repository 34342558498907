import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
// import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import DashboardBtnList from "../../../reuseablecomponents/DashboardBtnList";
import { acceptAppointmentAction } from "../../../../store/actions/administratorActions";
import { useTranslation } from "react-i18next";
// import { rescheduleDoctorAppointment } from "../../../../Store/Actions/docotorActions";

const DeclineReqscheduleRequest = (props) => {
  const {t} = useTranslation("common")
  const dispatch = useDispatch();

  const declineHandler = () => {
    dispatch(
      acceptAppointmentAction({
        id: props?.data,
        status: { id: 3, name: "rejected" },
      })
    );
    props.onHide();
  };
  // {t("dashboards.alldashboardkeys.tablekeys.resendphoneverificationotp")}

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="dashboardBtnList-item-active">
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-white">{t("dashboards.alldashboardkeys.tablekeys.declinereschedulerequest")}</span>
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true" className="text-white">×</span>
            <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>{t("dashboards.alldashboardkeys.tablekeys.declinereq")}</p>
          <>
            <div className="row">
              <div className="col pr-0">
                <DashboardBtnList
                  label={t("dashboards.alldashboardkeys.tablekeys.yes")}
                  className="btn btn-danger"
                  onClick={declineHandler}
                />
              </div>
              <div className="col">
                <DashboardBtnList
                  label={t("dashboards.alldashboardkeys.tablekeys.no")}
                  className="btn btn-success"
                  onClick={props.onHide}
                />
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeclineReqscheduleRequest;
