import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
const PrivateRoute = ({ component: Component, routelink, ...rest }) => {
  const { t } = useTranslation("common")
  const user = JSON.parse(localStorage.getItem("toticelluser"));
  const userRole = JSON.parse(localStorage.getItem("toticelluserRole"));




  return (
    <Route
      {...rest}
      render={(props) =>
        user && routelink === userRole ? (
          <Component {...props} />
        ) : (
          <>
            {toast.error(t("toastmessages.unauthorizedaccess"))}
            <Redirect to="/" />
          </>
        )
      }
    />
  );
};

export default PrivateRoute;
