import React, { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BannerWithText from "../../../components/layouts/bannerImgComponents/BannerImgComponents";
import DoctorCard from "../../../components/layouts/cards/DoctorsCard/DoctorCard";
import TabBtnList from "../../../components/layouts/tabbuttons/DashboardBtnList";
import HeadingWithBottomBar from "../../../components/reuseablecomponents/HeadingWithBottomBar";
import TextAndImageSection from "../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  doccard1,
  doccard2,
  doccard4,
  doccard5,
  doccard6,
  doccard7,
  doccard8,
  doctorFemale,
  doctorMale,
  LinksBanOurDoctors,
  ourDoctortab,
} from "../../../data";
import { filterComponentData } from "../../../utils/functions";
import { toast } from "react-toastify";
import {
  fetchPublicDoctor,
  fetchStaticData,
} from "../../../store/actions/staticActions";
import { useDispatch } from "react-redux";
import LoderContainer from "../../../components/reuseablecomponents/LoderContainer";
const OurDoctors = (props) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const component = useSelector((state) => state?.cmsReducer?.components);
  const viewData = props?.location?.state;
  const publicDoctor = useSelector((state) => state?.static?.publicDoctor);
  const loading = useSelector((state) => state?.static?.loader);
  const user = useSelector((state) => state?.logIn?.user);
  const userRole = useSelector((state) => state?.logIn?.userRole);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);
  const doctor = filterComponentData(component, "our_doctor", lang);
  const aboutImageAltTags = filterComponentData(
    component,
    "about_image_alt_tags",
    lang
  );
  const aboutSchemaMarkups = filterComponentData(
    component,
    "about_schema_markups",
    lang
  );
  const doctorheading = filterComponentData(
    component,
    "about_our_doctor_header",
    lang
  );
  const AboutMetaTitles = filterComponentData(
    component,
    "about_meta_titles",
    lang
  );
  const AboutMetaDesc = filterComponentData(
    component,
    "about_meta_descriptions",
    lang
  );
  const AboutMetaKeywords = filterComponentData(
    component,
    "about_meta_keywords",
    lang
  );
  const AboutVerifications = filterComponentData(
    component,
    "about_verification_meta_tags",
    lang
  );
  const [buttonState, setButtonState] = useState([]);
  const populateButtonArray = () => {
    let test = [];
    publicDoctor?.map((item) => {
      test?.push(item?.name);
    });
    setButtonState(test);
  };
  const ourDoctortab = [
    {
      doc2: {
        mainhead: t("ourdoctors.labteamhandler.buttonname2detail.subHeading"),
        img: doctorFemale,
        heading: (
          <>
            {t("ourdoctors.labteamhandler.buttonname2detail.headfirst")}
            <br />
            {t("ourdoctors.labteamhandler.buttonname2detail.headsecond")}
          </>
        ),
        description: "",
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkchecks: [
          {
            title: t("ourdoctors.labteamhandler.buttonname2detail.list1"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname2detail.list2"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname2detail.list3"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname2detail.list4"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname2detail.list5"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname2detail.list6"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname2detail.list7"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname2detail.list8"),
          },
        ],
      },
      doc4: {
        mainhead: t("ourdoctors.labteamhandler.buttonname3detail.subHeading"),
        img: doctorFemale,
        heading: t("ourdoctors.labteamhandler.buttonname3detail.headfirst"),
        description: "",
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkchecks: [
          {
            title: t("ourdoctors.labteamhandler.buttonname3detail.list1"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname3detail.list2"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname3detail.list3"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname3detail.list4"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname3detail.list5"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname3detail.list6"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname3detail.list7"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname3detail.list8"),
          },
        ],
      },

      doc6: {
        mainhead: t("ourdoctors.labteamhandler.buttonname5detail.subHeading"),
        img: doctorMale,
        heading: t("ourdoctors.labteamhandler.buttonname5detail.headfirst"),
        description: "",
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkchecks: [
          {
            title: t("ourdoctors.labteamhandler.buttonname5detail.list1"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname5detail.list2"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname5detail.list3"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname5detail.list4"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname5detail.list5"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname5detail.list6"),
          },
        ],
      },
      doc7: {
        mainhead: t("ourdoctors.labteamhandler.buttonname6detail.subHeading"),
        img: doctorFemale,
        heading: t("ourdoctors.labteamhandler.buttonname6detail.headfirst"),
        description: "",
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkchecks: [
          {
            title: t("ourdoctors.labteamhandler.buttonname6detail.list1"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname6detail.list2"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname6detail.list3"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname6detail.list4"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname6detail.list5"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname6detail.list6"),
          },
        ],
      },
      doc8: {
        mainhead: t("ourdoctors.labteamhandler.buttonname7detail.subHeading"),
        img: doctorFemale,
        heading: t("ourdoctors.labteamhandler.buttonname7detail.headfirst"),
        description: "",
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkchecks: [
          {
            title: t("ourdoctors.labteamhandler.buttonname7detail.list1"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname7detail.list2"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname7detail.list3"),
          },
          {
            title: t("ourdoctors.labteamhandler.buttonname7detail.list4"),
          },
        ],
      },
    },
  ];
  const [selecteddata, setSelecteddata] = useState(viewData);

  // const functFilter = () => {
  //   return doctor?.filter((item) => {
  //     if (item?.name?.toLowerCase() == viewData?.toLowerCase()) {
  //       return item;
  //     }
  //   });
  // };
  useEffect(() => {
    if (user) {
      dispatch(fetchStaticData());
    }
    populateButtonArray();
    if (viewData === undefined) {
      setSelecteddata(publicDoctor[0]);
    }
  }, [lang, publicDoctor?.length]);
  const LinksBanOurDoctors = [
    {
      subLinkName: banners[4]?.subtitle?.split(">")[0]
        ? banners[4]?.subtitle?.split(">")[0]
        : t("ourdoctors.banner.title1"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[4]?.subtitle?.split(">")[1]
        ? banners[4]?.subtitle?.split(">")[1]
        : t("ourdoctors.banner.subHeading"),
      subDash: "",
      subLink: "/our-doctors",
    },
  ];
  // About Our Doctors TabHandler Data

  const [selectedTab1, setSelectedTab1] = useState(viewData?.name);

  const tabledataHandler = async (item) => {
    setSelecteddata(item);
  };

  useEffect(() => {
    dispatch(fetchPublicDoctor());
    // eslint-disable-next-line
  }, []);

  const buttonname = [
    // t("ourdoctors.labteamhandler.buttonname1"),
    t("ourdoctors.labteamhandler.buttonname2"),
    t("ourdoctors.labteamhandler.buttonname3"),
    // t("ourdoctors.labteamhandler.buttonname4"),
    t("ourdoctors.labteamhandler.buttonname5"),
    t("ourdoctors.labteamhandler.buttonname6"),
    t("ourdoctors.labteamhandler.buttonname7"),
  ];
  const tabHandler = (item) => {
    setSelectedTab1(item);
    // if (item === t("ourdoctors.labteamhandler.buttonname1")) {
    //   tabledataHandler(ourDoctortab[0].doc1);
    // } else
    if (item === t("ourdoctors.labteamhandler.buttonname2")) {
      tabledataHandler(ourDoctortab[0].doc2);
    } else if (item === t("ourdoctors.labteamhandler.buttonname3")) {
      tabledataHandler(ourDoctortab[0].doc4);
      // } else if (item === t("ourdoctors.labteamhandler.buttonname4")) {
      //   tabledataHandler(ourDoctortab[0].doc5);
    } else if (item === t("ourdoctors.labteamhandler.buttonname5")) {
      tabledataHandler(ourDoctortab[0].doc6);
    } else if (item === t("ourdoctors.labteamhandler.buttonname6")) {
      tabledataHandler(ourDoctortab[0].doc7);
    } else if (item === t("ourdoctors.labteamhandler.buttonname7")) {
      tabledataHandler(ourDoctortab[0].doc8);
    }
  };

  const tabHandlerTest = (item) => {
    setSelectedTab1(item?.name?.toLowerCase());
    setSelecteddata(item);
  };

  const appointmentHandler = () => {
    if (!user) {
      props.history.push("/login");
    } else if (user && userRole == "patient") {
      if (selecteddata?.id) {
        props.history.push("/patient-book-appointment", selecteddata);
      } else {
        props.history.push("/patient-dashboard", {
          key: "fromBookAppointment",
        });
      }
    } else if (user && userRole !== "patient") {
      toast.error("Please Login As A Patient");
    }
  };
  const verificationsTags = AboutVerifications?.map((item) =>
    React.createElement("meta", {
      name: item?.ourDoctorsName,
      content: item?.ourDoctorsContent,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}

        {aboutSchemaMarkups?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.ourDoctors ? item?.ourDoctors : "[]")
            )}
          </script>
        ))}
        <title>
          {AboutMetaTitles?.ourDoctors
            ? AboutMetaTitles?.ourDoctors
            : t("ourdoctors.maintitle")}
        </title>
        <meta
          name="description"
          content={
            AboutMetaDesc?.ourDoctors
              ? AboutMetaDesc?.ourDoctors
              : t("ourdoctors.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            AboutMetaKeywords?.ourDoctors
              ? AboutMetaKeywords?.ourDoctors
              : t("ourdoctors.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[4]?.heading
              ? banners[4]?.heading
              : t("ourdoctors.banner.title")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[4]?.image ? banners[4]?.image : banner
          })`}
          imgSrc={banners[4]?.image ? banners[4]?.image : banner}
          LinksBan={LinksBanOurDoctors}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <HeadingWithBottomBar
          heading={
            doctorheading?.heading
              ? doctorheading?.heading
              : selecteddata?.mainhead
          }
        />

        <TextAndImageSection
          alt={aboutImageAltTags?.regenerativeConsultants}
          imgSrc={selecteddata?.display_picture}
          imgStyle={{
            height: "80%",
            width: "350px",
          }}
          imgCol="col-lg-5 d-flex justify-content-end"
          textCol="col-lg-7"
          headingFirst={selecteddata?.name}
          discription={
            <>
              <p
                style={{ textTransform: "capitalize" }}
                className="light-Color-Para mt-4"
              >
                <div>
                  <div>{selecteddata?.designation}</div>
                  <div>
                    {selecteddata?.speciality?.map((item, idx) => (
                      <span>
                        {idx === 0 ? null : ", "}
                        {item?.name}
                      </span>
                    ))}
                  </div>
                  <div>
                    {selecteddata?.departments?.map((item) => (
                      <span>{item?.name}</span>
                    ))}
                  </div>
                </div>
              </p>
            </>
          }
          buttoncustom={
            <>
              <div className="btn btnSquare" onClick={appointmentHandler}>
                {t("ourdoctors.bookappointment")}
              </div>
            </>
          }
          reverse={false}
        />
        {loading ? (
          <LoderContainer />
        ) : (
          <div className="container mt-5">
            <div className="row">
              {publicDoctor?.map((item, index) => (
                <div
                  className="col-md-6 col-lg-4 pt-4"
                  key={index + 1}
                  onClick={() => tabHandlerTest(item)}
                >
                  <TabBtnList
                    carddoc={true}
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      })
                    }
                    label={buttonState?.map((item_) =>
                      item_?.toLowerCase() == item?.name?.toLowerCase() ? (
                        <DoctorCard
                          className={
                            selectedTab1 === item?.name?.toLowerCase()
                              ? "dashboardBtnList-item-doc-active"
                              : "default-color-and-hover-doc"
                          }
                          alt={aboutImageAltTags?.regenerativeConsultants}
                          round={true}
                          imgSrc={item?.display_picture}
                          cardHeading={item?.name}
                          btnshow={true}
                          description={
                            <div>
                              <div>{item?.designation}</div>
                              <div>
                                {item?.speciality?.map((item, idx) => (
                                  <span>
                                    {idx === 0 ? null : ", "}
                                    {item?.name}
                                  </span>
                                ))}
                              </div>
                              <div>
                                {item?.departments?.map((item) => (
                                  <span>{item?.name}</span>
                                ))}
                              </div>
                            </div>
                          }
                        />
                      ) : null
                    )}
                    bntStyle={{ fontSize: "15px", borderRadius: "5px" }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OurDoctors;
