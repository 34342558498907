import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { BASEURL } from "../../../../services/HttpProvider";

const UploadReportModal = (props) => {
  const {t} = useTranslation("common")
  const [uploadLoader, setUploadLoader] = useState(false)
  const [file, setFile] = useState();
  const uploadFile = async (event) => {
    event.preventDefault();

    let formdata = new FormData();
    const authToken = JSON.parse(localStorage.getItem("toticelltoken"));
    if (file === undefined || file === null) {
      toast.error(t("toastmessages.pleaseuploadreport"));
      return;
    } else {
      setUploadLoader(true)
      formdata.append("report", file[0]);
      formdata.append("id", props?.viewData?.test?.report?.id);
      let res = await axios.post(
        BASEURL + "user/upload/test-report",
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-session-key": authToken.key,
            "x-session-type": authToken.type,
          },
        }
      );
      setUploadLoader(true)

      if (res?.data?.response_code === 200) {

        toast.success(t("toastmessages.reportuploadedsuccess"));
      setUploadLoader(false)

        props?.history?.push("/nurse-dashboard");

        props?.onHide();
      } else {
      setUploadLoader(false)

        toast.success(res?.data?.response_message);
      }
    }
  };
  // {t("dashboards.alldashboardkeys.tablekeys.cancel")}
  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="dashboardBtnList-item-active">
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-white" style={{ color: "#fff" }}>
            {t("dashboards.alldashboardkeys.tablekeys.uploadreport")}
            </span>
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span
              aria-hidden="true"
              className="text-white"
              style={{ color: "#fff" }}
            >
              ×
            </span>
            <span class="sr-only"> {t("dashboards.alldashboardkeys.tablekeys.close")}</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter">
            <input
              type="file"
              className="form-control"
              name="upload_file"
              onChange={(e) => {
                setFile(e.target.files);
              }}
            />
            <div className="w-100 d-flex justify-content-center">
              <span
                className="btn btn-success mt-3"
                onClick={(e) => {
                  uploadFile(e);
                }}
              >
                {uploadLoader ? (
                  <Loader
                    height={20}
                    width={20}
                    type="ThreeDots"
                    color="#fff"
                  />
                ) : (
                  t("dashboards.alldashboardkeys.tablekeys.upload")
                )}
              </span>
              <div
                className="btn btn-danger mt-3 ml-3"
                onClick={() => {
                  props?.onHide();
                }}
              >
                {t("dashboards.alldashboardkeys.tablekeys.cancel")}
              </div>
            </div>
          </Modal.Title>
          {/* <UploadFIle viewData={props?.viewData} /> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadReportModal;
