import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Testemonials from "../../../../../components/layouts/testimonials/Testemonials";
import TabBtnList from "../../../../../components/layouts/tabbuttons/DashboardBtnList";
import TabHandlerTextwithImage from "../../../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  cttreatment,
  imghy1,
  res1,
  LinksBanTreatmentCt,
  tabhandctc1,
  tabhandctc2,
  tabhandctc3,
  tabhandctc4,
} from "../../../../../data";
import HeadingWithBottomBar from "../../../../../components/reuseablecomponents/HeadingWithBottomBar";
import useMediaQuery from "../../../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Toggleform from "../../../../../components/layouts/toggleform/toggleform";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../../../utils/functions";

const Asthma = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );
  const stories = filterComponentData(component, "our_stories", lang);
  const enquiry = filterComponentData(
    component,
    "cupping_therapy_ct_make_an_enquiry_",
    lang
  );
  const therapy = filterComponentData(component, "asthma", lang);
  const { t } = useTranslation("common");

  const LinksBanTreatmentCt = [
    {
      subLinkName: banners[47]?.subtitle?.split(">")[0]
        ? banners[47]?.subtitle?.split(">")[0]
        : t("dryeyedisease.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[47]?.subtitle?.split(">")[1]
        ? banners[47]?.subtitle?.split(">")[1]
        : t("dryeyedisease.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[47]?.subtitle?.split(">")[2]
        ? banners[47]?.subtitle?.split(">")[2]
        : "Respiratory Diseases",
      subDash: ">",
      subLink: "/respiratory-diseases",
    },
    {
      subLinkName: banners[47]?.subtitle?.split(">")[3]
        ? banners[47]?.subtitle?.split(">")[3]
        : "Asthma",
      subDash: "",
      subLink: "/asthma",
    },
  ];

  const isDesktoplg = useMediaQuery("(min-width: 992px)");

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(
                item?.respiratoryAshtama ? item?.respiratoryAshtama : "[]"
              )
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.respiratoryAshtama
            ? specialityMetaTitles?.respiratoryAshtama
            : "Asthma"}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.respiratoryAshtama
              ? specialityMetaDesc?.respiratoryAshtama
              : "Asthma"
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.respiratoryAshtama
              ? specialityMetaKeywords?.respiratoryAshtama
              : "Asthma"
          }
        />
      </Helmet>
      <div className="">
        <BannerWithText
          heading={banners[47]?.heading ? banners[47]?.heading : "Asthma"}
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[47]?.image ? banners[47]?.image : banner
          })`}
          imgSrc={banners[47]?.image ? banners[47]?.image : banner}
          LinksBan={LinksBanTreatmentCt}
          height={isDesktoplg ? "300px" : "330px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={therapy?.image ? therapy?.image : res1}
          alt={
            specialityAltTags?.respiratoryAshtama
              ? specialityAltTags?.respiratoryAshtama
              : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={therapy?.name ? therapy?.name : "What is Asthma"}
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.desc ? (
                  <div dangerouslySetInnerHTML={{ __html: therapy?.desc }} />
                ) : (
                  <>
                    <p class="text-justify">
                      Asthma is a condition in which your airways narrow and
                      swell and may produce extra mucus. This can make breathing
                      difficult and trigger coughing, a whistling sound
                      (wheezing) when you breathe out and shortness of breath.
                    </p>
                    <h2
                      style={{
                        textTransform: "capitalize",
                        color: "#333333",
                      }}
                      className="gray-headin"
                    >
                      Stem Cell Treatment for Asthma
                    </h2>

                    <hr
                      style={{
                        borderBottom: "3px solid #AF5689",
                        margin: "10px 0px 15px 0px",
                        width: "80px",
                      }}
                    ></hr>
                    <p class="text-justify">
                      Chronic asthma is defined by inflammation, overproduction
                      of mucus, and airway remodeling. It has been shown that
                      mesenchymal stem cells (MSCs) have the ability to decrease
                      inflammation in injured areas, and promote healing. These
                      specific stem cells can reverse airway remodeling in
                      patients with severe asthma. A recent study was able to
                      show MSCs decreasing chronic inflammation
                    </p>
                  </>
                )}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default Asthma;
