import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashBoardMainHading from "../../components/reuseablecomponents/DashBoardMainHading";
import {
  pharmacyOrderDispatch,
  pharmacyPay,
} from "../../store/actions/pharmacyActions";
import DetailTable from "../../components/reuseablecomponents/DetailTable";
import { toast } from "react-toastify";
import AssignDeliveryBoy from "../../components/layouts/modal/AssignDeliveryBoy/AssignDeliveryModal";
import { comaSeperated } from "../../utils/functions";
import Loader from "react-loader-spinner";
import {
  cancelOrder,
  generatePayment,
} from "../../store/actions/patientActions";
import { useTranslation } from "react-i18next";
import useMediaQuery from "../../components/reuseablecomponents/MediaQueryHook";

const PaharmacyOrderDetail = (props) => {
  const { t } = useTranslation("common");
  const viewData = props.location.state;
  const loader = useSelector((state) => state?.logIn?.authLoader);

  const item = { key: "fromOrderDetails" };
  const [tabledata, settabledata] = useState([]);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const showModal = () => setShow(!show);
  const [currentOrder, setCurrentOrder] = useState(null);

  const isDesktop = useMediaQuery("(min-width: 992px)");

  const finalDataArray = (viewData) => {
    let dataArray = [];
    viewData?.medicines?.map((item) =>
      dataArray.push({
        type: "medicines",
        price: `${item?.medicine?.sales_price} BDT`,
        name: item?.medicine?.name,
        quantity: item?.medicine?.quantity,
      })
    );
    viewData?.packages?.map((item) =>
      dataArray.push({ type: "packages", price: item?.price, name: item?.name })
    );
    viewData?.tests?.map((item) =>
      dataArray.push({ type: "tests", price: item?.price, name: item?.name })
    );
    dataArray.push({
      type: "",
      name: "",
      price: (
        <>
          <span style={{ fontWeight: "bold" }}>
            {t("dashboards.alldashboardkeys.tablekeys.grandtotal")}
          </span>{" "}
          : <span>{viewData?.total_amount}</span>
          {t("dashboards.alldashboardkeys.tablekeys.btd")}
        </>
      ),
    });
    settabledata(dataArray);
  };
  const getTableData = useCallback(() => {
    finalDataArray(viewData);
  }, [viewData]);
  useEffect(() => {
    getTableData();
  }, [getTableData]);
  const pharmacyPartialPay = async () => {
    let res = await dispatch(
      pharmacyPay({ id: viewData?.id, partial_payment: true })
    );
    if (res) {
      props.history.push("/pharmacy-dashboard");
    }
  };
  const pharmacyFullPay = async () => {
    let res = await dispatch(
      pharmacyPay({ id: viewData?.id, partial_payment: false })
    );
    if (res) {
      props.history.push("/pharmacy-dashboard");
    }
  };
  return (
    <>
      <div style={{ backgroundColor: "#f9f9f9" }}>
        <DashBoardMainHading
          title={t("dashboards.alldashboardkeys.tablekeys.orderdetail")}
        />
        <div className="container ">
          <div className="row center-children-class">
            <div
              className="col-xl-8 col-md-8 col-12 px-0 mb-3
          "
            >
              <div
                className="btn btnSquare"
                style={{ width: "150px" }}
                onClick={() => {
                  props.history.push("/pharmacy-dashboard", item);
                }}
              >
                <i className="fas fa-arrow-left"></i>{" "}
                {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
              </div>
            </div>
          </div>
          <div className="row center-children-class">
            <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
              <div
                className={!isDesktop ? "" : "d-flex justify-content-between"}
              >
                <h5>
                  {t("dashboards.alldashboardkeys.tablekeys.customerdetail")}
                </h5>
                <p
                  style={{
                    color:
                      viewData?.paid_status?.name === "unpaid"
                        ? "red"
                        : "green",
                  }}
                >
                  <span style={{ color: "black" }}>
                    {t("dashboards.alldashboardkeys.tablekeys.status")}:
                  </span>{" "}
                  {viewData?.paid_status?.name?.toUpperCase()}
                  <br />
                  <span
                    style={{
                      color:
                        viewData?.delivery_status?.name !== "dispatched"
                          ? "red"
                          : "green",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      {t(
                        "dashboards.alldashboardkeys.tablekeys.deliverystatus"
                      )}
                      :
                    </span>{" "}
                    <span
                      style={{
                        color:
                          viewData?.delivery_status?.name == "processing"
                            ? "red"
                            : "green",
                      }}
                    >
                      {viewData?.delivery_status?.name?.toUpperCase()}
                    </span>
                  </span>
                  <br />
                  <span>
                    <span style={{ color: "black" }}>
                      {t(
                        "dashboards.alldashboardkeys.tablekeys.assignmentstatus"
                      )}
                      :
                    </span>{" "}
                    <span
                      style={{ color: viewData?.is_assigned ? "green" : "red" }}
                    >
                      {viewData?.is_assigned
                        ? viewData?.delivery_boy?.name?.toUpperCase()
                        : "NOT ASSIGNED"}
                    </span>
                  </span>
                </p>
              </div>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.customername")}
                  </span>
                  <p>{viewData?.ordered_by?.name}</p>
                </li>{" "}
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.customernumber")}
                  </span>
                  <p>{viewData?.ordered_by?.phone_number}</p>
                </li>{" "}
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.customeremail")}
                  </span>
                  <p>{viewData?.ordered_by?.email_address}</p>
                </li>
              </ul>
              <h5>{t("dashboards.alldashboardkeys.tablekeys.orderdetail")}</h5>
              {
                <DetailTable
                  tableHead={[
                    t("dashboards.alldashboardkeys.tablekeys.type"),
                    t("dashboards.alldashboardkeys.tablekeys.name"),
                    t("dashboards.alldashboardkeys.tablekeys.quantity"),
                    t("dashboards.alldashboardkeys.tablekeys.price"),
                  ]}
                  data={tabledata?.map((item) => {
                    return [
                      item?.type,
                      item?.name,
                      item?.quantity,
                      item?.price,
                    ];
                  })}
                />
              }
              {viewData?.paid_status?.name == "partial" ? (
                <>
                  <ul style={{ float: "right", listStyle: "none" }}>
                    <li>
                      <span
                        className=""
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#787878",
                        }}
                      >
                        {t("dashboards.alldashboardkeys.tablekeys.totalamount")}{" "}
                        :{" "}
                      </span>

                      <span style={{ fontWeight: "bold" }}>
                        {comaSeperated(viewData?.total_amount)}
                      </span>
                    </li>
                    <li>
                      <span
                        className="light-Color-Para"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#787878",
                        }}
                      >
                        {t("dashboards.alldashboardkeys.tablekeys.paidamount")}{" "}
                        :{" "}
                      </span>

                      <span style={{ fontWeight: "bold" }}>
                        {comaSeperated(viewData?.paid_amount)}
                      </span>
                    </li>
                    <li>
                      <span
                        className="light-Color-Para"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#787878",
                        }}
                      >
                        {t(
                          "dashboards.alldashboardkeys.tablekeys.remainingamount"
                        )}{" "}
                        :{" "}
                      </span>

                      <span style={{ fontWeight: "bold" }}>
                        {comaSeperated(viewData?.remaining_amount)}
                      </span>
                    </li>
                    {/* <li>
                    <span className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }} >Installment Amount : </span>

                    <span style={{ fontWeight: 'bold' }}>{viewData?.installments_amount}</span></li> */}
                  </ul>
                </>
              ) : viewData?.paid_status?.name == "paid" ? (
                <ul style={{ float: "right", listStyle: "none" }}>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#787878",
                      }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.totalamount")} :{" "}
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {comaSeperated(viewData?.total_amount)}
                    </span>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#787878",
                      }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.paidamount")} :{" "}
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {comaSeperated(viewData?.paid_amount)}
                    </span>
                  </li>
                </ul>
              ) : viewData?.paid_status?.name == "unpaid" ? (
                <>
                  <ul style={{ float: "right", listStyle: "none" }}>
                    <li>
                      <span
                        className="light-Color-Para"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#787878",
                        }}
                      >
                        {t("dashboards.alldashboardkeys.tablekeys.totalamount")}{" "}
                        :{" "}
                      </span>

                      <span style={{ fontWeight: "bold" }}>
                        {comaSeperated(viewData?.total_amount)}
                      </span>
                    </li>

                    <li>
                      <span
                        className="light-Color-Para"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#787878",
                        }}
                      >
                        {t(
                          "dashboards.alldashboardkeys.tablekeys.installmentamount"
                        )}{" "}
                        :{" "}
                      </span>

                      <span style={{ fontWeight: "bold" }}>
                        {comaSeperated(viewData?.installments_amount)}
                      </span>
                    </li>
                  </ul>
                </>
              ) : null}
              <div className="col-xl-12 col-md-12 col-12  d-flex justify-content-end">
                {viewData?.paid_status?.name === "unpaid" ? (
                  <></>
                ) : viewData?.delivery_status?.name === "processing" &&
                  viewData?.paid_status?.name === "paid" ? (
                  <div
                    className="btn btnSquare"
                    style={{ width: "150px", color: "#fff" }}
                    onClick={() => {
                      setCurrentOrder(viewData);
                      showModal();
                      // dispatch(
                      //   pharmacyOrderDispatch({
                      //     order_id: viewData?.id,
                      //     delivery_status: { id: 3, name: "dispatched" },
                      //   })
                      // );
                      // props.history.push("/pharmacy-dashboard", item);
                    }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.dispatch")}
                  </div>
                ) : viewData?.paid_status?.name == "partial" ? (
                  <></>
                ) : null}
              </div>
              <div className="col-xl-12 col-md-12 col-12  d-flex justify-content-end mt-2">
                {!viewData?.is_returned &&
                viewData?.delivery_status?.name == "cancelled" ? (
                  <button
                    className="btn btnSquare "
                    onClick={async () => {
                      let res = await dispatch(
                        cancelOrder({
                          order_id: viewData?.id,
                          is_returned: true,
                        })
                      );
                      if (res) {
                        props.history.push("/pharmacy-dashboard", {
                          key: "fromOrderDetails",
                        });
                      }
                    }}
                  >
                    Mark Return
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AssignDeliveryBoy
        show={show}
        handleClose={showModal}
        data={currentOrder}
        history={props?.history}
      />
    </>
  );
};

export default PaharmacyOrderDetail;

// loader ? <Loader type='Oval' height={30} width={60} color="#af5689" className="text-center" /> :

//                       <div
//                         className="btn btnSquare mt-1 mb-2 mr-1"
//                         style={{ width: "150px", color: "#fff" }}
//                         onClick={pharmacyFullPay}
//                       >
//                         {`${t(
//                         "dashboards.alldashboardkeys.tablekeys.paybtn"
//                       )}  (${comaSeperated(viewData?.total_amount)}) `}

//                       </div>

// (
//   loader ? <Loader type='Oval' height={30} width={60} color="#af5689" className="text-center" /> :
//     <>
//       <div
//         className="btn btnSquare mt-3 mr-1"
//         style={{ width: "150px", color: "#fff" }}
//         onClick={pharmacyFullPay}
//       >
//         {`${t(
//           "dashboards.alldashboardkeys.tablekeys.paybtn"
//         )}  (${comaSeperated(viewData?.total_amount)}) `}

//       </div>
//       <div
//         className="btn btnSquare mt-3"
//         style={{ color: "#fff" }}
//         onClick={pharmacyPartialPay}
//       >
//         {`${t(
//           "dashboards.alldashboardkeys.tablekeys.partiallypay"
//         )}  (${comaSeperated(viewData?.installments_amount)}) `}

//       </div>
//     </>
// )
