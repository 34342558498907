import moment from 'moment';
import React from 'react'
import { useTranslation } from 'react-i18next';
import DashBoardMainHading from '../../components/reuseablecomponents/DashBoardMainHading';

export default function TargetDetails(props) {
    const {t} = useTranslation("common")
    const viewData = props?.location?.state
    return (
        <div style={{ backgroundColor: "#f9f9f9" }}>
            <DashBoardMainHading title={t("dashboards.alldashboardkeys.tablekeys.targetdetail")} />
            <div className="container">
                <div className="row center-children-class mb-4 ">
                    <div className="col-xl-8 col-md-8 col-12 px-0">
                        <div
                            className="btn btnSquare "
                            style={{ width: "150px" }}
                            onClick={() => {
                                props.history.push("/administrator-dashboard");
                            }}
                        >
                            <i className="fas fa-arrow-left"></i> {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
                        </div>
                    </div>
                </div>
                <div className="row center-childrens mt-3 pb-5 center-children-class">
                    <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
                        <div className="d-flex justify-content-between">
                            <h5>{t("dashboards.alldashboardkeys.tablekeys.bdedetail")}</h5>
                            <div>
                                <div>
                                    <p>
                                    {t("dashboards.alldashboardkeys.tablekeys.paymentstatus")} :{" "}
                                        <strong
                                            style={{
                                                color:
                                                    viewData?.status?.name === "assigned"
                                                        ? "green"
                                                        : "red",
                                            }}
                                        >
                                            {viewData?.status?.name?.toUpperCase()}
                                        </strong>
                                    </p>
                                </div>

                            </div>
                        </div>
                        <ul style={{ listStyle: "none" ,listStyleType:'none'}}>
                            <li >
                                <span
                                    className="light-Color-Para"
                                    style={{ fontSize: "12px", fontWeight: "500" }}
                                >
                                    {t("dashboards.alldashboardkeys.tablekeys.assigntoname")}
                                </span>
                                <p>{viewData?.assigned_to?.name}</p>
                            </li>{" "}
                            <li>
                                <span
                                    className="light-Color-Para"
                                    style={{ fontSize: "12px", fontWeight: "500" }}
                                >
                                    {t("dashboards.alldashboardkeys.tablekeys.assigntoemail")}
                                </span>
                                <p>{viewData?.assigned_to?.email_address}</p>
                            </li>{" "}
                            <li>
                                <span
                                    className="light-Color-Para"
                                    style={{ fontSize: "12px", fontWeight: "500" }}
                                >
                                    {t("dashboards.alldashboardkeys.tablekeys.assigntonumber")}
                                </span>
                                <p>{viewData?.assigned_to?.phone_number}</p>
                            </li>
                            <li>
                                <span
                                    className="light-Color-Para"
                                    style={{ fontSize: "12px", fontWeight: "500" }}
                                >
                                    {t("dashboards.alldashboardkeys.tablekeys.assigntogender")}
                                </span>
                                <p>{viewData?.assigned_to?.gender?.name}</p>
                            </li>

                        </ul>
                        <h5 className="mt-4"> {t("dashboards.alldashboardkeys.tablekeys.targetdetail")}</h5>
                        <ul style={{listStyle:'none'}}>
                            {" "}
                            <li>
                                <span
                                    className="light-Color-Para"
                                    style={{ fontSize: "12px", fontWeight: "500" }}
                                >
                                    {t("dashboards.alldashboardkeys.tablekeys.startdate")}
                                </span>
                                <p>{moment.unix(viewData?.start_date).format('YYYY-MM-DD')}</p>
                            </li>
                            <li>
                                <span
                                    className="light-Color-Para"
                                    style={{ fontSize: "12px", fontWeight: "500" }}
                                >
                                    {t("dashboards.alldashboardkeys.tablekeys.enddate")}
                                </span>
                                <p>{moment.unix(viewData?.end_date).format('YYYY-MM-DD')}</p>
                            </li>
                            <li>
                                <span
                                    className="light-Color-Para"
                                    style={{ fontSize: "12px", fontWeight: "500" }}
                                >
                                    {t("dashboards.alldashboardkeys.tablekeys.ammount")}
                                    
                                </span>
                                <p>{viewData?.amount}</p>
                            </li>
                            <li>
                                <span
                                    className="light-Color-Para"
                                    style={{ fontSize: "12px", fontWeight: "500" }}
                                >
                                    {t("dashboards.alldashboardkeys.tablekeys.noofdoctorvisits")}
                                </span>
                                <p>{viewData?.number_of_doctor_visits}</p>
                            </li>
                            <li>
                                <span
                                    className="light-Color-Para"
                                    style={{ fontSize: "12px", fontWeight: "500" }}
                                >
                                    {t("dashboards.alldashboardkeys.tablekeys.noofpakages")}
                                </span>
                                <p>{viewData?.number_of_packages}</p>
                            </li>
                            <li>
                                <span
                                    className="light-Color-Para"
                                    style={{ fontSize: "12px", fontWeight: "500" }}
                                >
                                    {t("dashboards.alldashboardkeys.tablekeys.nooftest")}
                                </span>
                                <p>{viewData?.number_of_tests}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
