import React from 'react';
import useMediaQuery from '../../../reuseablecomponents/MediaQueryHook';

const FacilitiesCard = (props) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return (
    <>
    <div className="col-lg-12">
        <div className='row'>
        <div className={`col-sm-2`}>
        <img
        src={props?.imgSrc}
        width={"80px"}
        height={"70px"}
        />
        </div>
        <div className="col-sm-10">
        <h3 style={{color:'#970165'}}>{props?.head}</h3>
        <p>{props?.text}</p>
        </div>
        </div>
    </div>
    </>
  )
};

export default FacilitiesCard;
