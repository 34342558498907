import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Testemonials from "../../../../../components/layouts/testimonials/Testemonials";
import TabBtnList from "../../../../../components/layouts/tabbuttons/DashboardBtnList";
import TabHandlerTextwithImage from "../../../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  cttreatment,
  imghy1,
  pamenucard7,
  LinksBanTreatmentCt,
  tabhandctc1,
  tabhandctc2,
  tabhandctc3,
  tabhandctc4,
} from "../../../../../data";
import HeadingWithBottomBar from "../../../../../components/reuseablecomponents/HeadingWithBottomBar";
import useMediaQuery from "../../../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Toggleform from "../../../../../components/layouts/toggleform/toggleform";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../../../utils/functions";

const CarpalTunnelSyndrome = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const stories = filterComponentData(component, "our_stories", lang);
  const enquiry = filterComponentData(
    component,
    "cupping_therapy_ct_make_an_enquiry_",
    lang
  );
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );

  const therapy = filterComponentData(
    component,
    "carpal_tunnel_syndrome",
    lang
  );
  const { t } = useTranslation("common");

  const LinksBanTreatmentCt = [
    {
      subLinkName: banners[57]?.subtitle?.split(">")[0]
        ? banners[57]?.subtitle?.split(">")[0]
        : t("kneepain.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[57]?.subtitle?.split(">")[1]
        ? banners[57]?.subtitle?.split(">")[1]
        : t("kneepain.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[57]?.subtitle?.split(">")[2]
        ? banners[57]?.subtitle?.split(">")[2]
        : t("kneepain.banner.title1"),
      subDash: ">",
      subLink: "/pain-management",
    },
    {
      subLinkName: banners[57]?.subtitle?.split(">")[3]
        ? banners[57]?.subtitle?.split(">")[3]
        : "Carpal Tunnel Syndrome",
      subDash: "",
      subLink: "/carpal-tunnel-syndrome",
    },
  ];

  const isDesktoplg = useMediaQuery("(min-width: 992px)");

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.painCarpal ? item?.painCarpal : "[]")
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.painCarpal
            ? specialityMetaTitles?.painCarpal
            : "Carpal Tunnel Syndrome"}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.painCarpal
              ? specialityMetaDesc?.painCarpal
              : "Carpal Tunnel Syndrome"
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.painCarpal
              ? specialityMetaKeywords?.painCarpal
              : "Carpal Tunnel Syndrome"
          }
        />
      </Helmet>
      <div className="">
        <BannerWithText
          heading={
            banners[57]?.heading
              ? banners[57]?.heading
              : "Carpal Tunnel Syndrome"
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[57]?.image ? banners[57]?.image : banner
          })`}
          imgSrc={banners[57]?.image ? banners[57]?.image : banner}
          LinksBan={LinksBanTreatmentCt}
          height={isDesktoplg ? "300px" : "330px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={therapy?.image ? therapy?.image : pamenucard7}
          alt={
            specialityAltTags?.painCarpal ? specialityAltTags?.painCarpal : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={
            therapy?.name ? therapy?.name : "What is Carpal Tunnel Syndrome"
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.desc ? (
                  <div dangerouslySetInnerHTML={{ __html: therapy?.desc }} />
                ) : (
                  <>
                    <p class="text-justify">
                      Carpal tunnel syndrome is when the median nerve is
                      compressed as it passes through the carpal tunnel. The
                      carpal tunnel is an opening in your wrist that is formed
                      by the carpal bones on the bottom of the wrist and the
                      transverse carpal ligament across the top of the wrist.
                      The median nerve provides sensory and motor functions to
                      the thumb and 3 middle fingers. If it gets compressed or
                      irritated, you may have symptoms.
                    </p>
                    <h2
                      style={{
                        textTransform: "capitalize",
                        color: "#333333",
                      }}
                      className="gray-headin"
                    >
                      Stem Cell Therapy For Carpal Tunnel Syndrome
                    </h2>

                    <hr
                      style={{
                        borderBottom: "3px solid #AF5689",
                        margin: "10px 0px 15px 0px",
                        width: "80px",
                      }}
                    ></hr>
                    <p class="text-justify">
                      TotiCell has established its cGMP facilitated human cell
                      culture laboratory aims to treat Carpal tunnel syndrome i.
                      Stem cell therapy works to provide patients with pain
                      relief by repairing the damaged tissue where pain is being
                      experienced. By taking stem cells from your body and
                      redirecting them to where extra support is needed, the
                      cells are working collectively together to repair the
                      tissue, thus alleviating the unwanted pain.
                      <br />
                      <br />
                      PRP is another innovative method for relieving carpal
                      tunnel syndrome pain. The plasma contains
                      highly-concentrated healing properties that ignite the
                      repair of the damaged median nerve.
                    </p>
                  </>
                )}
                {/* <br/><br/>
            {t("cttherapy.textrev.discriptiontxt2")} */}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default CarpalTunnelSyndrome;
