import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerWithText from "../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Testemonials from "../../../components/layouts/testimonials/Testemonials";
import TabBtnList from "../../../components/layouts/tabbuttons/DashboardBtnList";
import TabHandlerTextwithImage from "../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  cttreatment,
  imgplatelet1,
  LinksBanTreatmentCt,
  tabhandctc1,
  tabhandctc2,
  tabhandctc3,
  tabhandctc4,
} from "../../../data";
import HeadingWithBottomBar from "../../../components/reuseablecomponents/HeadingWithBottomBar";
import useMediaQuery from "../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Toggleform from "../../../components/layouts/toggleform/toggleform";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../utils/functions";

const PlateletLysate = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);
  const stories = filterComponentData(component, "our_stories", lang);
  const enquiry = filterComponentData(
    component,
    "cupping_therapy_ct_make_an_enquiry_",
    lang
  );
  const therapy = filterComponentData(component, "platelet_lysate", lang);
  const treatmentMetaTitles = filterComponentData(
    component,
    "treatment_meta_titles",
    lang
  );
  const treatmentSchemaMarkup = filterComponentData(
    component,
    "treatment_schema_markups",
    lang
  );
  const treatmentMetaDesc = filterComponentData(
    component,
    "treatment_meta_descriptions",
    lang
  );
  const treatmentMetaKeywords = filterComponentData(
    component,
    "treatment_meta_keywords",
    lang
  );
  const treatmentAltTags = filterComponentData(
    component,
    "treatment_image_alt_tags",
    lang
  );
  const treatmentVerification = filterComponentData(
    component,
    "treatment_verification_meta_tags",
    lang
  );
  const { t } = useTranslation("common");

  const LinksBanTreatmentCt = [
    {
      subLinkName: banners[7]?.subtitle?.split(">")[0]
        ? banners[7]?.subtitle?.split(">")[0]
        : t("cttherapy.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[7]?.subtitle?.split(">")[1]
        ? banners[7]?.subtitle?.split(">")[1]
        : t("cttherapy.banner.title1"),
      subDash: ">",
      subLink: "/treatment",
    },
    {
      subLinkName: banners[7]?.subtitle?.split(">")[2]
        ? banners[7]?.subtitle?.split(">")[2]
        : "Platelet Lysate",
      subDash: "",
      subLink: "/platelet-lysate",
    },
  ];

  const [toggle, setToggle] = useState(false);

  const isDesktoplg = useMediaQuery("(min-width: 992px)");
  const verificationsTags = treatmentVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.plateletLysateName,
      content: item?.plateletLysateContent,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}
        {treatmentSchemaMarkup?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.plateletLysate ? item?.plateletLysate : "[]")
            )}
          </script>
        ))}
        <title>
          {treatmentMetaTitles?.plateletLysate
            ? treatmentMetaTitles?.plateletLysate
            : "Platelet Lysate"}
        </title>
        <meta
          name="description"
          content={
            treatmentMetaDesc?.plateletLysate
              ? treatmentMetaDesc?.plateletLysate
              : "Platelet Lysate"
          }
        />
        <meta
          name="keywords"
          content={
            treatmentMetaKeywords?.plateletLysate
              ? treatmentMetaKeywords?.plateletLysate
              : "Platelet Lysate"
          }
        />
      </Helmet>
      <div className="">
        <BannerWithText
          heading={
            banners[7]?.heading ? banners[7]?.heading : "Platelet Lysate"
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[7]?.image ? banners[7]?.image : banner
          })`}
          imgSrc={banners[7]?.image ? banners[7]?.image : banner}
          LinksBan={LinksBanTreatmentCt}
          height={isDesktoplg ? "300px" : "330px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={therapy?.image ? therapy?.image : imgplatelet1}
          alt={
            treatmentAltTags?.plateletLysate
              ? treatmentAltTags?.plateletLysate
              : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={
            therapy?.name ? therapy?.name : "What is Platelet Lysate"
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.desc ? (
                  <div dangerouslySetInnerHTML={{ __html: therapy?.desc }} />
                ) : (
                  <>
                    <p class="text-justify">
                      Platelet Lysate is a highly specialized derivative of
                      Platelet Rich Plasma (PRP). Normal PRP isolates platelets
                      from the blood which slowly release growth factors into
                      the site of injury.
                      <br />
                      Platelet lysate is an acellular compound composed of
                      platelet proteins while also being void of cellular
                      debris, thus making the lysate rich with growth factors.
                      It is extracted through lysis of the plasma membrane of
                      platelets. Because white cell antigens are low in prepared
                      lysate, there is a minimized likelihood of immune
                      responses.
                    </p>
                    <div>
                      <h2
                        style={{
                          textTransform: "capitalize",
                          color: "#333333",
                        }}
                        className="gray-headin"
                      >
                        Conditions Treated:
                      </h2>

                      <hr
                        style={{
                          borderBottom: "3px solid #AF5689",
                          margin: "10px 0px 15px 0px",
                          width: "80px",
                        }}
                      ></hr>

                      <div class="span-department">
                        <ul>
                          <li>
                            <i class="fa fa-check"></i>
                            Wound healing
                          </li>
                          <li>
                            <i class="fa fa-check"></i>Lung Injuries (Such as
                            COPD)
                          </li>
                          <li>
                            <i class="fa fa-check"></i>Treatment of ocular
                            graft-versus-host disease
                          </li>
                          <li>
                            <i class="fa fa-check"></i>Osteoarthritis
                          </li>
                          <li>
                            <i class="fa fa-check"></i>Parkinson’s disease
                          </li>
                          <li>
                            <i class="fa fa-check"></i>Tendon regeneration
                          </li>
                          <li>
                            <i class="fa fa-check"></i>Infertility
                          </li>
                          <li>
                            <i class="fa fa-check"></i>Androgenetic alopecia
                          </li>
                          <li>
                            <i class="fa fa-check"></i>Nerve repair and
                            regenerative tissue, such as bone regeneration.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
                {/* <br/><br/>
            {t("cttherapy.textrev.discriptiontxt2")} */}
              </p>
            </>
          }
          reverse={false}
        />

        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-12">
              <div class="section" onClick={() => setToggle(!toggle)}>
                <span class="makeinquirybt">
                  {enquiry?.button
                    ? enquiry?.button
                    : t("makeanenquiry.enquirybtn")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toggleform toggle={toggle} data={enquiry} />
      <div>
        <HeadingWithBottomBar heading={t("cttherapy.patientfeedhead")} />

        <Testemonials stories={stories} />
      </div>
    </>
  );
};

export default PlateletLysate;
