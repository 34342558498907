import { signInConstansts } from "../constants/authConstants";
import { patientConstants } from "../constants/PatientConstants";
const initialState = {
  appointments: {},
  error: false,
  doctorsByDepartment: [],
  slotsByDate: [],
  rescheduleModal: { open: false, data: {} },
  myDoctors: [],
  prescriptions: [],
  purchasedPackages: [],
  payments: [],
  reports: [],
  patientLoader: false,
  medicalRecords: {},
  gatewayUrl: "",
  doctorLocation: []
};

export const patientReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case patientConstants.PATIENT_LOADER:
      return { ...state, patientLoader: payload };
    case patientConstants.PATIENT_APPOINTMENTS:
      return { ...state, appointments: payload };
    case patientConstants.GET_DEPARTMENT:
      return { ...state, departments: payload };
    case patientConstants.GET_DOCTOR_BY_DEPARTMENT_SUCCESS:
      return { ...state, doctorsByDepartment: payload };
    case patientConstants.GET_SLOTS_BY_DATE:
      return { ...state, slotsByDate: payload };
    case patientConstants.RESCHEDULE_MODAL:
      return { ...state, rescheduleModal: payload };
    case patientConstants.MY_DOCTORS:
      return { ...state, myDoctors: payload };
    case patientConstants.GET_PATIENT_PRESCRIPTIONS:
      return { ...state, prescriptions: payload };
    case patientConstants.GENERATE_PAYMENT:
      return { ...state, gatewayUrl: payload };
    case patientConstants.MY_REPORTS:
      return { ...state, reports: payload };
    case patientConstants.MY_PAYMENT:
      return { ...state, payments: payload };
    case patientConstants.PURCHASED_PACKAGES:
      return { ...state, purchasedPackages: payload };
    case patientConstants.MEDICAL_RECORDS_PATIENT:
      return { ...state, medicalRecords: payload };
    case patientConstants.READ_DOCTOR_LOCATION_SUCCESS:
      return { ...state, doctorLocation: payload }
    case signInConstansts.LOG_OUT_USER:
      return initialState;
    default:
      return state;
  }
};
