import { toast } from "react-toastify";
import apiServices from "../../services/requestHandler";
import { signInConstansts } from "../constants/authConstants";
import { staticConstants } from "../constants/staticConstants";
import i18next from "i18next";

export const fetchStaticData = () => async (dispatch) => {
  const response = await apiServices.getStaticData();
  dispatch({
    type: staticConstants.FETCH_STATIC_DATA,
    payload: response?.data?.response_data?.static_data,
  });
};

export const setStaticData = (staticData) => {
  return {
    type: staticConstants.SET_STATIC_DATA,
    payload: staticData,
  };
};

export const testCategory = () => async (dispatch) => {
  const response = await apiServices.getCategory();
  dispatch({
    type: staticConstants.GET_TEST_CATEGORY,
    payload: response?.data?.response_data?.category,
  });
};
export const getTestData = (data) => async (dispatch) => {
  const response = await apiServices.getTestData(data);
  dispatch({
    type: staticConstants.GET_TEST_DATA,
    payload: response?.data?.response_data?.test,
  });
};

export const getPackages = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: true,
    });
    const response = await apiServices.getPackages(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      dispatch({
        type: staticConstants.GET_PACKAGES,
        payload: response?.data?.response_data?.package,
      });
    } else {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast.error(response?.data?.response_message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const videoCallToken =
  (doctor = false, data) =>
  async (dispatch) => {
    if (doctor) {
      const response = await apiServices.videoCallTokenBoard(data);
      if (response?.data?.response_code === 200) {
        localStorage.setItem("toticellroomToken", response?.data?.response_data?.token);
        return "true";
      } else {
        toast.error(response?.data?.response_message);
        return "false";
      }
    } else {
      const response = await apiServices.videoCallToken(data);
      if (response?.data?.response_code === 200) {
        localStorage.setItem("toticellroomToken", response?.data?.response_data?.token);
        return "true";
      } else {
        toast.error(response?.data?.response_message);
        return "false";
      }
    }
  };
export const getChatToken = (data) => async (dispatch) => {
  const response = await apiServices.getChatToken(data);
  if (response?.data?.response_code === 200) {
    localStorage.setItem("toticellchatToken", response?.data?.response_data?.token);
    return "success";
  } else {
    return "fail";
  }
};

export const joinChatToken = (docotorboard, data) => async (dispatch) => {
  let response;
  if (docotorboard) {
    response = await apiServices.joinChatTokenBoard(data);
  } else {
    response = await apiServices.joinChatToken(data);
  }
  if (response?.data?.response_code === 200) {
    localStorage.setItem("toticellchatToken", response?.data?.response_data?.token);
    return "success";
  } else {
    toast.error(response?.data?.response_message);
    return "fail";
  }
};

export const getallNotification = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.SET_LOADER,
    payload: true,
  });

  const response = await apiServices.getallNotification(data);
  if (response?.data?.response_code === 200) {
    const newNotification =
      // eslint-disable-next-line
      await response?.data?.response_data?.notifications?.filter((item) => {
        if (!item?.is_seen) {
          return item;
        }
      });
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: false,
    });
    dispatch({
      type: staticConstants.ALL_NOTIFICATIONS,
      payload: response?.data?.response_data?.notifications,
    });
    dispatch({
      type: staticConstants.NEW_NOTIFICATIONS,
      payload: newNotification,
    });
    dispatch({
      type: staticConstants.NOTIFICATION_COUNT,
      payload: newNotification.length,
    });
  } else {
    toast.error(response?.data?.response_message);
  }
};
export const seeAllNotification = (data) => async (dispatch) => {
  const response = await apiServices.seeAllNotification(data);
  if (response?.response_code === 200) {
    dispatch({
      type: staticConstants.NEW_NOTIFICATIONS,
      payload: response?.response_data?.notifications,
    });
    dispatch({
      type: staticConstants.NOTIFICATION_COUNT,
      payload: 0,
    });
  } else {
    toast.error(response?.data?.response_message);
  }
};

export const selectedAppointment = (data) => {
  return {
    type: staticConstants.SELECTED_APPOINTMENT,
    payload: data,
  };
};
export const setWebLoader = (data) => {
  return {
    type: staticConstants.WEB_LOADER,
    payload: data,
  };
};

export const conatctUse = (data) => async (dispatch) => {
  const response = await apiServices.conatctUse(data);
  if (response?.data?.response_code === 200) {
    toast.info(i18next.t("common:toastmessages.emailsentsuccess"));
    return "true"
  } else {
    toast.error(response?.data?.response_message);
    return "false"
  }
};

export const setlocallang = (data) => {
  return {
    type: staticConstants.LOCALLANG,
    payload: data,
  };
};


export const fetchPublicDoctor = () => async (dispatch) => {
  dispatch({
    type: staticConstants.LOADING,
    payload: true,
  });
  const response = await apiServices.getPublicDoctor();
  if (response?.data?.response_code === 200) {
    dispatch({
      type: staticConstants.FETCH_PUBLIC_DOCTOR,
      payload: response?.data?.response_data?.doctors,
    });
    dispatch({
      type: staticConstants.LOADING,
      payload: false,
    });
  } else {
    dispatch({
      type: staticConstants.LOADING,
      payload: false,
    });
  }
};
