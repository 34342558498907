import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardBtnList from "../../components/reuseablecomponents/DashboardBtn";
import Loader from "react-loader-spinner";
import {
  // getChatToken,
  videoCallToken,
} from "../../store/actions/staticActions";
import DetailTable from "../../components/reuseablecomponents/DetailTable";
import DashBoardMainHading from "../../components/reuseablecomponents/DashBoardMainHading";
import ChatIframe from "../../services/twillio/ChatIframe";
import { generatePayment } from "../../store/actions/patientActions";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { capitalize } from "../../utils/functions";

const PatientDashboardAppointmentDetails = (props) => {
  const { t } = useTranslation("common");
  const viewData = props.location.state?.row;
  console.log("viewData", viewData);

  const from = props.location.state?.from;
  const loader = useSelector((state) => state?.patient?.patientLoader);

  const [loadingVideo, setloadingVideo] = useState(false);
  // const [loadingChat, setloadingChat] = useState(false);
  // const user = useSelector((state) => state?.logIn?.user);
  const [showchat, setshowchat] = useState(false);
  const user = useSelector((state) => state?.logIn?.user);

  const item = { key: "FromViewAppointment" };
  const item1 = { key: "fromOrderDetails" };
  const dispatch = useDispatch();
  const paymentHandler = async (data) => {
    let apiData = {
      id: data?.id,
      type: "appointment",
    };
    const res = await dispatch(generatePayment(apiData));
    window.open(res);
    window.focus();
  };
  const payByCash = async (data) => {
    console.log("data", data);
    let apiData = {
      id: data?.id,
      type: "appointment",
    };
    const res = await dispatch(generatePayment(apiData));
    // window.open(res);
    // window.focus();
  };
  const callHandler = async () => {
    setloadingVideo(true);
    const res = await dispatch(videoCallToken(false, { id: viewData?.id }));
    if (res === "true") {
      setloadingVideo(false);
      const win = window.open(`/call/${viewData?.id}`, "_blank");
      win.focus();
    } else {
      setloadingVideo(false);
    }
  };

  const chatHandler = async () => {
    setshowchat(true);
  };
  // t("dashboards.alldashboardkeys.tablekeys.orders")

  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading
        title={t("dashboards.alldashboardkeys.tablekeys.appointmentdetail")}
      />
      {showchat ? (
        <>
          <div className="container">
            <div className="row center-children-class">
              <div className="col-lg-10 col-md-12">
                <div
                  className="btn btnSquare"
                  style={{ width: "150px" }}
                  onClick={() => {
                    setshowchat(false);
                  }}
                >
                  <i className="fas fa-arrow-left"></i>{" "}
                  {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
                </div>
              </div>
            </div>

            <ChatIframe
              user_id={user?.id}
              appointment_id={viewData?.id}
              board={false}
              name={user?.name}
            />
          </div>
        </>
      ) : (
        <>
          <div className="container">
            <div className="row center-children-class">
              <div className="col-xl-8 col-md-8 col-12 px-0">
                <div
                  className="btn btnSquare"
                  style={{ width: "150px" }}
                  onClick={() => {
                    props.history.push("/patient-dashboard", item);
                  }}
                >
                  <i className="fas fa-arrow-left"></i>{" "}
                  {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
                </div>
              </div>
            </div>
            <div className="row center-children-class mt-3 pb-5">
              <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
                <div className="d-flex justify-content-between">
                  <h5>
                    {t("dashboards.alldashboardkeys.tablekeys.doctordetail")}
                  </h5>
                  <span>
                    {t("dashboards.alldashboardkeys.tablekeys.paymentstatus")} :{" "}
                    <strong
                      style={{
                        color:
                          viewData?.status?.name === "booked"
                            ? "red"
                            : viewData?.status?.name == "confirmed"
                            ? "green"
                            : "#af5689",
                      }}
                    >
                      {viewData?.status?.name === "booked" ? "UNPAID" : "PAID"}
                    </strong>
                  </span>
                </div>
                <ul style={{ listStyle: "none", paddingLeft: "0px" }}>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.datantime")}
                    </span>
                    <p style={{ fontWeight: "600" }}>
                      {viewData?.date} @ {viewData?.slot.time?.from} -{" "}
                      {viewData?.slot.time?.to}{" "}
                    </p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.doctorname")}
                    </span>
                    <p>{viewData?.doctor_details?.name}</p>
                  </li>{" "}
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.doctoremail")}
                    </span>
                    <p>{viewData?.doctor_details?.email_address}</p>
                  </li>{" "}
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {"Department"}
                    </span>
                    <p>
                      {viewData?.doctor_details?.departments?.length < 1
                        ? "None"
                        : viewData?.doctor_details?.departments?.map((item) => {
                            return <p>{item}</p>;
                          })}
                    </p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.speciality")}
                    </span>
                    <p>
                      {viewData?.doctor_details?.speciality.length < 1
                        ? "None"
                        : viewData?.doctor_details?.speciality.map((item) => {
                            return <p>{capitalize(item?.name)}</p>;
                          })}
                    </p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      {t(
                        "dashboards.alldashboardkeys.tablekeys.consultationtype"
                      )}
                    </span>
                    <p style={{ fontWeight: "600" }}>
                      {viewData?.consultation_type?.name?.toUpperCase()}
                    </p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.city")}
                    </span>
                    <p style={{ fontWeight: "600" }}>
                      {viewData?.city?.toUpperCase()}
                    </p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      {"Description"}
                    </span>
                    <p style={{ fontWeight: "600" }}>{viewData?.description}</p>
                  </li>
                </ul>
                {viewData?.status?.name == "booked" &&
                (from == "TodayAppointment" ||
                  from == "upcoming" ||
                  from == "PastAppointment") ? (
                  <div className="row">
                    <div className="col">
                      {viewData?.consultation_type?.name == "walk-in" ? (
                        loader ? (
                          <Loader
                            type="Oval"
                            height="40px"
                            width="20px"
                            className="text-center"
                            color="green"
                          />
                        ) : (
                          <div className="row">
                            <div className="col-6">
                              <DashboardBtnList
                                label={
                                  from == "PastAppointment" &&
                                  viewData?.status?.name == "booked"
                                    ? "Cancelled"
                                    : t("Pay By Cash")
                                }
                                className="order-pay-btn"
                                onClick={() => {
                                  from == "PastAppointment" &&
                                  viewData?.status?.name == "booked"
                                    ? toast.error("Appointment is Cancelled")
                                    : // props.history.push("patient-dashboard", item1)
                                      payByCash(viewData);
                                }}
                              />
                            </div>
                            <div className="col-6">
                              <DashboardBtnList
                                label={
                                  from == "PastAppointment" &&
                                  viewData?.status?.name == "booked"
                                    ? "Cancelled"
                                    : t(
                                        "dashboards.alldashboardkeys.tablekeys.paybtn"
                                      )
                                }
                                className="order-pay-btn"
                                onClick={() => {
                                  from == "PastAppointment" &&
                                  viewData?.status?.name == "booked"
                                    ? toast.error("Appointment is Cancelled")
                                    : paymentHandler(viewData);
                                }}
                              />
                            </div>
                          </div>
                        )
                      ) : loader ? (
                        <Loader
                          type="Oval"
                          height="40px"
                          width="20px"
                          className="text-center"
                          color="green"
                        />
                      ) : (
                        <DashboardBtnList
                          label={
                            from == "PastAppointment" &&
                            viewData?.status?.name == "booked"
                              ? "Cancelled"
                              : t(
                                  "dashboards.alldashboardkeys.tablekeys.paybtn"
                                )
                          }
                          className="order-pay-btn"
                          onClick={() => {
                            from == "PastAppointment" &&
                            viewData?.status?.name == "booked"
                              ? toast.error("Appointment is Cancelled")
                              : paymentHandler(viewData);
                          }}
                        />
                      )}
                    </div>
                  </div>
                ) : null}

                <div>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    {from == "TodayAppointment" &&
                    viewData?.status?.name == "confirmed" ? (
                      <div
                        className="btn btn-success"
                        style={{ float: "right" }}
                        onClick={callHandler}
                      >
                        {loadingVideo ? (
                          <Loader
                            height={20}
                            width={20}
                            type="ThreeDots"
                            color="#fff"
                          />
                        ) : (
                          <>
                            <i
                              class="fa fa-phone"
                              aria-hidden="true"
                              style={{ fontSize: "13px" }}
                            ></i>{" "}
                            {t("dashboards.alldashboardkeys.tablekeys.callbtn")}
                          </>
                        )}
                      </div>
                    ) : null}
                    {(from == "TodayAppointment" ||
                      from == "PastAppointment") &&
                    (viewData?.status?.name == "confirmed" ||
                      viewData?.status?.name == "completed") ? (
                      <div
                        className="btn btn-success"
                        style={{ float: "right" }}
                        onClick={chatHandler}
                      >
                        <i
                          className="fas fa-comment-alt"
                          style={{ fontSize: "13px" }}
                        >
                          {" "}
                        </i>{" "}
                        {t("dashboards.alldashboardkeys.tablekeys.chatbtn")}
                      </div>
                    ) : null}
                  </div>

                  {viewData?.prescription?.id ? (
                    <div>
                      <h5>
                        {t(
                          "dashboards.alldashboardkeys.tablekeys.appointmentdetail"
                        )}
                      </h5>
                      <div>
                        <div className="w-100 containerWithShadow p-2 mb-4 mt-4">
                          <span
                            className="light-Color-Para ml-2"
                            style={{ fontSize: "18px", fontWeight: "500" }}
                          >
                            {t(
                              "dashboards.alldashboardkeys.tablekeys.diagnoses"
                            )}
                          </span>
                          <p className="ml-2 mt-2">
                            {viewData?.prescription?.diagnosis}
                          </p>
                        </div>
                      </div>

                      {viewData?.prescription?.tests?.length > 0 ? (
                        <>
                          <span
                            className="light-Color-Para ml-2"
                            style={{ fontSize: "18px", fontWeight: "500" }}
                          >
                            {t(
                              "dashboards.alldashboardkeys.tablekeys.testdetails"
                            )}
                          </span>
                          <DetailTable
                            tableHead={[
                              "ID",
                              t("dashboards.alldashboardkeys.tablekeys.name"),
                              t("dashboards.alldashboardkeys.tablekeys.price"),
                              t("dashboards.alldashboardkeys.tablekeys.action"),
                            ]}
                            data={viewData?.prescription?.tests?.map((item) => {
                              return [
                                item?.test_id,
                                item?.name,
                                item?.price, // eslint-disable-next-line
                                <a
                                  className={
                                    item?.report?.status?.name ===
                                    "report uploaded"
                                      ? "btn btnSquare "
                                      : "btn btn-block btn-light  "
                                  }
                                  href={
                                    item?.report?.status?.name ===
                                    "report uploaded"
                                      ? item?.report?.url
                                      : null
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {t(
                                    "dashboards.alldashboardkeys.tablekeys.viewreport"
                                  )}
                                </a>,
                              ];
                            })}
                          />
                        </>
                      ) : (
                        ""
                      )}
                      {viewData?.prescription?.medicines.length > 0 ? (
                        <>
                          <span
                            className="light-Color-Para ml-2"
                            style={{ fontSize: "18px", fontWeight: "500" }}
                          >
                            Medicine Details
                          </span>
                          <DetailTable
                            tableHead={[
                              t("dashboards.alldashboardkeys.tablekeys.name"),
                              t(
                                "dashboards.alldashboardkeys.tablekeys.duration"
                              ),
                              t(
                                "dashboards.alldashboardkeys.tablekeys.morningdose"
                              ),
                              t(
                                "dashboards.alldashboardkeys.tablekeys.eveningdose"
                              ),
                              t(
                                "dashboards.alldashboardkeys.tablekeys.nightdose"
                              ),
                            ]}
                            data={viewData?.prescription?.medicines?.map(
                              (item) => {
                                return [
                                  item?.medicine?.name,
                                  item?.duration,
                                  item?.dose[0],
                                  item?.dose[1],
                                  item?.dose[2],
                                ];
                              }
                            )}
                          />
                        </>
                      ) : (
                        ""
                      )}
                      {viewData?.prescription?.follow_up_dates.length > 0 ? (
                        <>
                          <span
                            className="light-Color-Para ml-2"
                            style={{ fontSize: "18px", fontWeight: "500" }}
                          >
                            {t(
                              "dashboards.alldashboardkeys.tablekeys.followupdates"
                            )}
                          </span>
                          <DetailTable
                            tableHead={[
                              "S.No",
                              t("dashboards.alldashboardkeys.tablekeys.date"),
                            ]}
                            data={viewData?.prescription?.follow_up_dates?.map(
                              (item, index) => {
                                return [index, item];
                              }
                            )}
                          />
                        </>
                      ) : (
                        ""
                      )}
                      {viewData?.recommended_doctors?.length > 0 ? (
                        <>
                          <span
                            className="light-Color-Para ml-2"
                            style={{ fontSize: "18px", fontWeight: "500" }}
                          >
                            {"Recomended Doctors"}
                          </span>
                          <DetailTable
                            tableHead={["S.No", "Name", "Email"]}
                            data={viewData?.recommended_doctors?.map(
                              (item, index) => {
                                return [index, item?.name, item?.email_address];
                              }
                            )}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PatientDashboardAppointmentDetails;
