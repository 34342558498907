import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Specialitycard from "../../../../components/layouts/cards/SpeciallityCard/Specialitycard";
import {
  banner,
  dimenucard1,
  dimenucard2,
  dimenucard3,
} from "../../../../data";
import { filterComponentData } from "../../../../utils/functions";

const Diabetes = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );

  const cards = filterComponentData(
    component,
    "speciality_diabetes_cards",
    lang
  );
  const { t } = useTranslation("common");

  const LinksBanSpecialityDiabetes = [
    {
      subLinkName: banners[42]?.subtitle?.split(">")[0]
        ? banners[42]?.subtitle?.split(">")[0]
        : t("diabetes.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[42]?.subtitle?.split(">")[1]
        ? banners[42]?.subtitle?.split(">")[1]
        : t("diabetes.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[42]?.subtitle?.split(">")[2]
        ? banners[42]?.subtitle?.split(">")[2]
        : t("diabetes.banner.title1"),
      subDash: "",
      subLink: "/diabetes",
    },
  ];

  const diabetes1 = [
    {
      img: dimenucard1,
      head1: t("diabetes.pagemenucard.card1.text"),
      linkroute: "/diabetic-foot-ulcer",
    },
    {
      img: dimenucard2,
      img: dimenucard3,
      head1: t("diabetes.pagemenucard.card2.text"),
      linkroute: "/dry-eye-disease",
    },
    {
      img: dimenucard3,
      head1: "Type 2 Diabetes Mellitus",
      linkroute: "/type2-diabetes-mellitus",
    },
  ];

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.diabetiesMain ? item?.diabetiesMain : "[]")
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.diabetiesMain
            ? specialityMetaTitles?.diabetiesMain
            : t("diabetes.maintitle")}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.diabetiesMain
              ? specialityMetaDesc?.diabetiesMain
              : t("diabetes.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.diabetiesMain
              ? specialityMetaKeywords?.diabetiesMain
              : t("diabetes.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[42]?.heading
              ? banners[42]?.heading
              : t("diabetes.banner.title1")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[42]?.image ? banners[42]?.image : banner
          })`}
          imgSrc={banners[42]?.image ? banners[42]?.image : banner}
          LinksBan={LinksBanSpecialityDiabetes}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container">
          <div className="row ">
            {cards?.length < 1
              ? diabetes1.map((item, index) => (
                  <Specialitycard
                    img={item?.img}
                    alt={
                      specialityAltTags?.diabetiesMain
                        ? specialityAltTags?.diabetiesMain
                        : ""
                    }
                    head1={item?.head1}
                    linkroute={item?.linkroute}
                  />
                ))
              : cards.map((item, index) => (
                  <Specialitycard
                    img={item?.image}
                    alt={
                      specialityAltTags?.diabetiesMain
                        ? specialityAltTags?.diabetiesMain
                        : ""
                    }
                    head1={item?.heading}
                    linkroute={item?.link}
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Diabetes;
