import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AddTarget from '../../components/layouts/modal/addTargetModal/addTarget'
import EditTarget from '../../components/layouts/modal/addTargetModal/editTarget'
import ActionBtn from '../../components/reuseablecomponents/ActionBtn'
import BootstrapCustomTable from '../../components/reuseablecomponents/BootstrapCustomTable'
import DashboardBtnList from '../../components/reuseablecomponents/DashboardBtnList'
import Loader from '../../components/reuseablecomponents/LoderContainer'
import { readTargetAction } from '../../store/actions/administratorActions'

export default function AssignTarget(props) {
    const {t} = useTranslation("common")
    const [targetModal, setTargetModal] = useState(false)
    const [targetModalEdit, setTargetModalEdit] = useState(false)
    const target = useSelector(state => state?.administrator?.target)

    const [bdeTarget, setBdeTarget] = useState(null)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(readTargetAction())
    }, [dispatch])
    const loading = useSelector(state => state?.administrator?.administrator_loader)
    const test = () => {
        setTargetModal(true)
    }
    const handleChange = (value, item) => {
        if (value === t("dashboards.alldashboardkeys.tablekeys.viewdetail")) {
            props?.history?.push("/target-details", item);
        } else if (value === t("dashboards.alldashboardkeys.tablekeys.editTarget")) {
            setTargetModalEdit(true)
            setBdeTarget(item)
        } else {

        }
    }
    return (
        <>
            <div>
                <div className="row">
                    <div className="col-6">
                        <div className="p-3">
                            <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.alltargets")}</h4>

                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex justify-content-end">
                        <div
                            style={{ height: '60%', width: '50%' }}
                            className="btn btnSquare mt-0"
                            onClick={() => {
                                setTargetModal(true)
                            }}
                        >
                            {t("dashboards.alldashboardkeys.tablekeys.addtargets")}
                           
                        </div>
                        {/* <DashboardBtnList
                            label={"Add Target"}
                            className="order-pay-btn w-50"
                            onClick={
                                () => setTargetModal(true)
                            }
                        /> */}
                    </div>

                </div>
                {loading ?
                    <Loader /> : <div className="row">
                        <BootstrapCustomTable
                            columns={[
                                {
                                    formatter: (cell, row, index) => {
                                        return <span>{index + 1}</span>;
                                    },
                                    text: "Sr.",
                                },
                                { dataField: "target_id", text: t("dashboards.alldashboardkeys.tablekeys.targetid"),sort: true },


                                {
                                    formatter: (cell, row, index) => {
                                        return <span>{moment.unix(row?.start_date)?.format('YYYY-MM-DD')}</span>;
                                    },
                                    dataField: "start_date",
                                    text: t("dashboards.alldashboardkeys.tablekeys.startdate"),
                                },
                                {
                                    formatter: (cell, row, index) => {
                                        return <span>{moment.unix(row?.end_date)?.format('YYYY-MM-DD')}</span>;
                                    },
                                    dataField: "end_date",
                                    text: t("dashboards.alldashboardkeys.tablekeys.enddate"),
                                },


                                { dataField: "assigned_to.name", text: t("dashboards.alldashboardkeys.tablekeys.assigntoname") },
                                { dataField: "amount", text: t("dashboards.alldashboardkeys.tablekeys.ammount") },
                                {
                                    formatter: (cell, row, index) => {
                                        return <span style={{ color: row?.status?.name == "assigned" ? 'green' : 'red' }}>{row?.status?.name?.toUpperCase()}</span>;
                                    },
                                    text: t("dashboards.alldashboardkeys.tablekeys.status"),
                                },

                                {
                                    formatter: (cell, row) => {
                                        return (
                                            <ActionBtn
                                                options={[
                                                    t("dashboards.alldashboardkeys.tablekeys.viewdetail")
                                                    ,
                                                    t("dashboards.alldashboardkeys.tablekeys.editTarget")
                                                ]}
                                                handleChange={handleChange}
                                                data={row}
                                            />

                                        );
                                    },
                                    text: t("dashboards.alldashboardkeys.tablekeys.action"),
                                },
                            ]}
                            tabledata={target || []}
                        />
                    </div>
                }

            </div>
            <AddTarget
                show={targetModal}
                onHide={() => setTargetModal(false)}

            />
            <EditTarget
                show={targetModalEdit}
                onHide={() => setTargetModalEdit(false)}
                setBdeTarget={setBdeTarget}
                bdeTarget={bdeTarget}

            />
        </>
    )
}
