export const staticConstants = {
  FETCH_STATIC_DATA: "  FETCH_STATIC_DATA",
  SET_STATIC_DATA: "SET_STATIC_DATA",
  GET_TEST_CATEGORY: "GET_TEST_CATEGORY",
  GET_TEST_DATA: "GET_TEST_DATA",
  GET_PACKAGES: "GET_PACKAGES",
  NOTIFICATION: "NOTIFICATION",
  SELECTED_APPOINTMENT: "SELECTED_APPOINTMENT",
  ALL_NOTIFICATIONS: "ALL_NOTIFICATIONS",
  NEW_NOTIFICATIONS: "NEW_NOTIFICATIONS",
  NOTIFICATION_COUNT: "NOTIFICATION_COUNT",
  LOCALLANG: "LOCALLANG",
  LOADING:'LOADING',
  FETCH_PUBLIC_DOCTOR:'FETCH_PUBLIC_DOCTOR',
  WEB_LOADER:"WEB_LOADER"
 
};
