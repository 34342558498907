import { doctorConstants } from "../constants/doctorConstants";
const initialState = {
  myPatient: [],
  allMedicines: [],
  allTests: [],
  myBoards: [],
  doctorLoading: false,
  testLoader: false
};
export const doctorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case doctorConstants.DOCTOR_LOADER:
      return { ...state, doctorLoading: payload };
    case doctorConstants.MY_PATIENTS:
      return { ...state, myPatient: payload };
    case doctorConstants.ALL_MEDICINES:
      return { ...state, allMedicines: payload };
    case doctorConstants.MY_BOARDS:
      return { ...state, myBoards: payload };
    case doctorConstants.ALL_TESTS:
      return { ...state, allTests: payload };
    case doctorConstants.TEST_LOADER:
      return { ...state, testLoader: payload };
    // case signInConstansts.LOG_OUT_USER:
    //   return initialState;
    default:
      return state;
  }
};
