
/* eslint-disable */
import * as firebase from 'firebase/app';
// import { getMessaging } from "firebase/messaging/sw";
import * as test from 'firebase/messaging';
const initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCOgSLi4q_j8Yrd4h-SQ18BCqVuNNKN204",
  authDomain: "totticell-fa701.firebaseapp.com",
  projectId: "totticell-fa701",
  storageBucket: "totticell-fa701.appspot.com",
  messagingSenderId: "847803953515",
  appId: "1:847803953515:web:f24b0fbd35fdbf5245dc2e",
  measurementId: "G-DK6B7D4RF3"
});
const messaging = test.getMessaging(initializedFirebaseApp);
export { messaging, test };
