import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import AlertRefund from '../../components/layouts/modal/AlertRefund/AlertRefund';
import RefundModal from '../../components/layouts/modal/refundAmountModal/RefundModal';
import ActionBtn from '../../components/reuseablecomponents/ActionBtn';
import BootstrapCustomTable from '../../components/reuseablecomponents/BootstrapCustomTable';
import { readTodaysAppointmentAction } from '../../store/actions/administratorActions';
import { cancelAppointment } from '../../store/actions/patientActions';

export default function AdministratorTodayAppointment(props) {
    const { t } = useTranslation("common")
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const date = new Date()
    const [id, setID] = useState("")
    const appointments = useSelector(
        (state) => state?.administrator?.today
    );
    const user = useSelector((state) => state?.logIn?.user);
    const handleChange = async (value, row) => {
        if (value == t("dashboards.alldashboardkeys.tablekeys.viewbtn")) {
            props?.history?.push("/patient-dashboard-appointment-details", { row, from: "TodayAppointment" });
        }
        else if (value == "Cancel") {
            let res = await dispatch(cancelAppointment({ appointment_id: row?.id }))
            dispatch(readTodaysAppointmentAction());

        }
        else if (value == "Cancel/Refund") {
            // let [hour, minutes] = row?.slot?.from?.split(":")

            // if ((hour - date.getHours()) < 6) {
            // setShowModal(!showModal)
            // } else {
            setID(row?.id)
            setShow(!show)
            // }

        }
        else {

        }

    };

    const defaultSorting = [{
        dataField: "date",
        order: "asc",
    },]
    return (
        <>
            <div className="w-100">
                <div className="row">
                    <div className="col-6">
                        <div className="p-3">
                            <span className="dashboardHeadingText">{t("dashboards.alldashboardkeys.tablekeys.todayappointment")}</span>
                        </div>
                    </div>


                </div>


                <BootstrapCustomTable
                    defaultsort={defaultSorting}

                    columns={[
                        {
                            formatter: (cell, row, index) => {
                                return <span>{index + 1}</span>;
                            },
                            text: "Sr.",
                        },
                        {

                            dataField: "date",
                            sort: true,
                            text: t("dashboards.alldashboardkeys.tablekeys.date"),
                        },

                        {
                            formatter: (cell, row) => {
                                return <span>{`${row?.slot?.from} - ${row?.slot?.to}`}</span>;
                            },
                            text: t("dashboards.alldashboardkeys.tablekeys.time"),
                        },

                        { dataField: "doctor_details.name", text: t("dashboards.alldashboardkeys.tablekeys.doctorname") },
                        { dataField: "consultation_type.name", text: t("dashboards.alldashboardkeys.tablekeys.consultationtype") },
                        { dataField: "condition.name", text: "Condition" },

                        {
                            formatter: (cell, row) => {
                                return <span style={{ color: row?.status?.name == "booked" ? 'red' : row?.status?.name == "confirmed" ? 'green' : '#970165' }}>{row?.status?.name?.toUpperCase()}</span>;

                            },
                            text: t("dashboards.alldashboardkeys.tablekeys.status"),
                        },


                        {
                            formatter: (cell, row, index) => {
                                return (
                                    row?.status?.name == "booked" ? <ActionBtn
                                        options={["Cancel"]}
                                        handleChange={handleChange}
                                        data={row}
                                    /> : row?.status?.name == "confirmed" ? <ActionBtn
                                        options={["Cancel", "Cancel/Refund"]}
                                        handleChange={handleChange}
                                        data={row}
                                    /> : <ActionBtn
                                        options={[]}
                                        handleChange={handleChange}
                                        data={row}
                                    />

                                );
                            },
                            text: t("dashboards.alldashboardkeys.tablekeys.action"),
                        },
                    ]}
                    tabledata={appointments ? appointments : []}
                />
            </div>
            <RefundModal
                show={show}
                id={id}
                onHide={() => setShow(false)}
            />
            <AlertRefund
                show={showModal}
                onHide={() => setShowModal(false)}

            />
        </>
    )
}
