import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Specialitycard from "../../../../components/layouts/cards/SpeciallityCard/Specialitycard";
import {
  banner,
  stmenucard1,
  stmenucard2,
  stmenucard3,
  stmenucard4,
} from "../../../../data";
import { filterComponentData } from "../../../../utils/functions";

const StemCellBanking = () => {
  const { t } = useTranslation("common");
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );

  const cards = filterComponentData(
    component,
    "speciality_stem_cell_banking_cards",
    lang
  );
  const LinksBanSpecialityStcb = [
    {
      subLinkName: banners[37]?.subtitle?.split(">")[0]
        ? banners[37]?.subtitle?.split(">")[0]
        : t("stemcellbanking.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[37]?.subtitle?.split(">")[1]
        ? banners[37]?.subtitle?.split(">")[1]
        : t("stemcellbanking.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[37]?.subtitle?.split(">")[2]
        ? banners[37]?.subtitle?.split(">")[2]
        : t("stemcellbanking.banner.title1"),
      subDash: "",
      subLink: "/stem-cell-banking",
    },
  ];
  const stemcell1 = [
    {
      img: stmenucard1,
      head1: t("stemcellbanking.pagemenucard.card1.text"),
      linkroute: "/cord-blood-banking",
    },
    {
      img: stmenucard2,
      head1: t("stemcellbanking.pagemenucard.card2.text"),
      linkroute: "/cord-tissue-banking",
    },
    {
      img: stmenucard3,
      head1: t("stemcellbanking.pagemenucard.card3.text"),
      linkroute: "/community-banking",
    },
    {
      img: stmenucard4,
      head1: t("stemcellbanking.pagemenucard.card4.text"),
      linkroute: "/family-banking",
    },
  ];
  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(JSON.stringify(item?.stemMain ? item?.stemMain : "[]"))}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.stemMain
            ? specialityMetaTitles?.stemMain
            : t("stemcellbanking.maintitle")}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.stemMain
              ? specialityMetaDesc?.stemMain
              : t("stemcellbanking.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.stemMain
              ? specialityMetaKeywords?.stemMain
              : t("stemcellbanking.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[37]?.heading
              ? banners[37]?.heading
              : t("stemcellbanking.banner.title1")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[37]?.image ? banners[37]?.image : banner
          })`}
          imgSrc={banners[37]?.image ? banners[37]?.image : banner}
          LinksBan={LinksBanSpecialityStcb}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container">
          <div className="row ">
            {cards?.length < 1
              ? stemcell1.map((item, index) => (
                  <Specialitycard
                    img={item?.img}
                    alt={
                      specialityAltTags?.stemMain
                        ? specialityAltTags?.stemMain
                        : ""
                    }
                    head1={item?.head1}
                    linkroute={item?.linkroute}
                  />
                ))
              : cards.map((item, index) => (
                  <Specialitycard
                    img={item?.image}
                    alt={
                      specialityAltTags?.stemMain
                        ? specialityAltTags?.stemMain
                        : ""
                    }
                    head1={item?.heading}
                    linkroute={item?.link}
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default StemCellBanking;
