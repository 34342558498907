import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";

import {
  addMedicine,
  updateMedicine,
} from "../../../../store/actions/pharmacyActions";
import { fetchStaticData } from "../../../../store/actions/staticActions";
import { useTranslation } from "react-i18next";

const AddPharmacyMedicine = (props) => {
  const { t } = useTranslation("common");
  const selectedMedi = useSelector((state) => state?.pharmacy?.selectedMedi);

  const [stateMedi, setstateMedi] = useState({});
  const [checkBox, setcheckBox] = useState(false);

  const dispatch = useDispatch();
  const mediTypeStaticData = useSelector((state) => state?.static?.staticData?.medicine_type);
  const mediRouteStaticData = useSelector((state) => state?.static?.staticData?.medicine_route);

  const mediUnitStaticData = useSelector((state) => state?.static?.staticData?.medicine_unit_of_measure);



  useEffect(() => {

    dispatch(fetchStaticData());
    if (selectedMedi?.show) {
      setstateMedi(selectedMedi?.data);
      setcheckBox(selectedMedi?.data?.discountable);
    } else {
      setstateMedi({});
      setcheckBox(false);
    }
  }, [selectedMedi, dispatch]);
  const onSubmit = (data) => {
    const apiDataForCreate = {
      name: stateMedi?.name,
      formula: stateMedi?.formula,
      currency: { id: 1, name: "bdt" },
      quantity: Number(stateMedi?.quantity),
      item_id: stateMedi?.item_id,
      unit_of_measure: stateMedi?.unit_of_measure[0]?.name,
      route: stateMedi?.route[0]?.name,
      medicine_type: stateMedi?.medicine_type[0]?.name,
      sales_price: Number(stateMedi?.sales_price),
      purchase_price: Number(stateMedi?.purchase_price),
      discountable: checkBox,
      manufacturer_name: stateMedi?.manufacturer_name

    };
    const apiDataForEdit = {
      name: selectedMedi?.data?.name,
      formula: selectedMedi?.data?.formula,
      currency: { id: 1, name: "bdt" },
      quantity: Number(selectedMedi?.data?.quantity),
      item_id: selectedMedi?.data?.item_id,
      unit_of_measure: selectedMedi?.data?.unit_of_measure,
      route: selectedMedi?.data?.route,
      medicine_type: selectedMedi?.data?.medicine_type,
      sales_price: Number(selectedMedi?.data?.sales_price),
      purchase_price: Number(selectedMedi?.data?.purchase_price),
      discountable: checkBox,
    };
    if (selectedMedi?.show) {
      dispatch(updateMedicine({ ...apiDataForEdit, id: stateMedi?.id }));
      setstateMedi({});
    } else if (!selectedMedi?.show) {
      setstateMedi({})

      dispatch(addMedicine(apiDataForCreate));
    }
    props.onHide();
  };
  // {t("dashboards.alldashboardkeys.tablekeys.addmedicine")}

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {selectedMedi?.show ? t("dashboards.alldashboardkeys.tablekeys.updatemedicine") : t("dashboards.alldashboardkeys.tablekeys.addmedicine")}
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true">×</span>
            <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            <div className="row">
              <div className="col-lg-12">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.medname")}</span>
                <input
                  className="input-login-modal"
                  type="text"
                  value={stateMedi?.name || ""}
                  onChange={(e) =>
                    setstateMedi({ ...stateMedi, name: e.target.value })
                  }
                />
              </div>
              <div className="col-lg-12">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.medformulaname")}</span>
                <input
                  className="input-login-modal"
                  type="text"
                  value={stateMedi?.formula || ""}
                  onChange={(e) =>
                    setstateMedi({
                      ...stateMedi,
                      formula: e.target.value,
                    })
                  }
                />
              </div>

              <div className="col-lg-6">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.medquantity")}</span>
                <input
                  className="input-login-modal"
                  type="number"
                  value={stateMedi?.quantity || 0}
                  // defaultValue={stateMedi?.quantity || 0}
                  onChange={(e) => {
                    if (e.target.value > 0) {
                      setstateMedi({
                        ...stateMedi,
                        quantity: e.target.value,
                      });
                    }
                  }}
                />
              </div>

              <div className="col-lg-6">
                <span className="label-name-login">
                  {t("dashboards.alldashboardkeys.tablekeys.medtypetablet")}
                </span>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  placeholder=""
                  selected={stateMedi?.medicine_type || ""}
                  onChange={(selected) => {
                    setstateMedi({
                      ...stateMedi,
                      medicine_type: selected,
                    });
                  }}
                  options={mediTypeStaticData}
                />
                {/* {mediError ? (
                      <div className="text-error">{mediError}</div>
                    ) : (
                      ""
                    )} */}
                {/* <input
                  className="input-login-modal"
                  value={stateMedi?.medicine_type || ""}
                  type="text"
                  onChange={(e) =>
                    setstateMedi({
                      ...stateMedi,
                      medicine_type: e.target.value,
                    })
                  }
                /> */}
              </div>

              <div className="col-lg-6">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.purchaseprice")}</span>
                <input
                  className="input-login-modal"
                  value={stateMedi?.purchase_price || ""}
                  type="number"
                  onChange={(e) =>
                    setstateMedi({
                      ...stateMedi,
                      purchase_price: e.target.value,
                    })
                  }
                />
              </div>

              <div className="col-lg-6">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.sellprice")}</span>
                <input
                  className="input-login-modal"
                  type="number"
                  value={stateMedi?.sales_price || ""}
                  onChange={(e) =>
                    setstateMedi({
                      ...stateMedi,
                      sales_price: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-lg-12">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.routeformedicine")}</span>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  placeholder=""
                  selected={stateMedi?.route || ""}
                  onChange={(selected) => {
                    setstateMedi({
                      ...stateMedi,
                      route: selected,
                    })
                  }}
                  options={mediRouteStaticData}
                />

                {/* <input
                  className="input-login-modal"
                  type="text"
                  value={stateMedi?.route || ""}
                  onChange={(e) =>
                    setstateMedi({
                      ...stateMedi,
                      route: e.target.value,
                    })
                  }
                /> */}
              </div>
              <div className="col-lg-6 mt-3">
                <span className="label-name-login">{"Manufacturer Name"}</span>
                <input
                  className="input-login-modal"
                  type="text"
                  value={stateMedi?.manufacturer_name || ""}
                  onChange={(e) =>
                    setstateMedi({
                      ...stateMedi,
                      manufacturer_name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-lg-6 mt-3">
                <span className="label-name-login">
                  {t("dashboards.alldashboardkeys.tablekeys.unitofmeasure")}
                </span>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  placeholder=""
                  selected={stateMedi?.unit_of_measure || []}
                  onChange={(selected) => {
                    setstateMedi({
                      ...stateMedi,
                      unit_of_measure: selected,
                    })
                  }}
                  options={mediUnitStaticData}
                />
                {/* <input
                  className="input-login-modal"
                  type="text"
                  value={stateMedi?.unit_of_measure || ""}
                  onChange={(e) =>
                    setstateMedi({
                      ...stateMedi,
                      unit_of_measure: e.target.value,
                    })
                  }
                /> */}
              </div>
              <div className="col-lg-6">
                <span className="label-name-login">{"ID"}</span>
                <input
                  className="input-login-modal"
                  type="text"
                  value={stateMedi?.item_id || ""}
                  onChange={(e) =>
                    setstateMedi({
                      ...stateMedi,
                      item_id: e.target.value,
                    })
                  }
                />
              </div>

            </div>
          </div>
          <div className="row px-3">
            <div className="form-check">
              <input
                type="checkbox"
                checked={checkBox}
                value={checkBox}
                onChange={() => setcheckBox(!checkBox)}
                className="form-check-input"
                id="exampleCheck1"
                style={{ width: "15px", height: "15px" }}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                {t("dashboards.alldashboardkeys.tablekeys.discountable")}
              </label>
            </div>
          </div>
          <input
            type="submit"
            value={selectedMedi?.show ? t("dashboards.alldashboardkeys.tablekeys.updatemedicine") : t("dashboards.alldashboardkeys.tablekeys.addmedicine")}
            className=" btn btnSquare btn-block mx-auto mt-3"
            style={{ textAlign: "center" }}
            onClick={onSubmit}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddPharmacyMedicine;
