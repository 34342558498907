import i18next from "i18next";
import homecard1 from "./statics/toticellimages/Platelet.png";
import homecard2 from "./statics/toticellimages/Bone-Marrow.png";
import homecard3 from "./statics/toticellimages/Vascular-Fraction.png";
import homecard4 from "./statics/toticellimages/Mesenchymal-Cell.png";
import homecard5 from "./statics/toticellimages/Mononuclear-Cell.png";
import homecard6 from "./statics/toticellimages/Shockwave.png";
import homecard7 from "./statics/toticellimages/Oxygen-Therapy.png";
import homecard8 from "./statics/toticellimages/Cupping-Therapy.png";

// doctor Card
import doccard1 from "./statics/toticellimages/doctors/Dr. Rezaul Karim.jpg";
import doccard2 from "./statics/toticellimages/doctors/khalida.jpg";
import doccard4 from "./statics/toticellimages/doctors/Tasnim Khan.jpg";
import doccard5 from "./statics/toticellimages/doctors/Dr Maftun.JPG";
import doccard6 from "./statics/toticellimages/doctors/Dr Nazia.jpg";
import doccard7 from "./statics/toticellimages/doctors/Dr Tina.jpeg";
import doccard8 from "./statics/toticellimages/doctors/user.jpg";

// Home Mobile Pic
import mobilecard1 from "./statics/toticellimages/app-mobile-image.png";
import mobileappstore from "./statics/toticellimages/apple.png";
import mobilegoogleplay from "./statics/toticellimages/play-store.png";
import mobileqr from "./statics/toticellimages/qr_code.png";

// Post Card
import post1 from "./statics/toticellimages/news1.jpg";
import post2 from "./statics/toticellimages/news2.jpg";
import post3 from "./statics/toticellimages/news3.jpg";

//icons header
import envelope from "./statics/icons/icons-envelope.png";
import phone from "./statics/icons/icons-phone.png";

// TabhandlerComponent
import tabhandpic1 from "./statics/toticellimages/Covid-Management.jpg";
import tabhandpic2 from "./statics/toticellimages/Pain-Management.jpg";
import tabhandpic3 from "./statics/toticellimages/aesthetic-medicine.jpg";
import tabhandpic4 from "./statics/toticellimages/Neurological-Rehabilitation.jpg";
import tabhandpic5 from "./statics/toticellimages/stem-cell.jpg";
import tabhandpic6 from "./statics/toticellimages/Dry-Eye-Disease.jpg";

import tabicon1 from "./statics/icons/covid-19.png";
import tabicon2 from "./statics/icons/doctor.png";
import tabicon3 from "./statics/icons/nurce.png";
import tabicon4 from "./statics/icons/emergency-2.png";
import tabicon5 from "./statics/icons/stem-cell.png";
import tabicon6 from "./statics/icons/emergency-ambulance.png";
import { Link } from "react-router-dom";

// Tabhandler Images Treatment PRP
import tabhandprp1 from "./statics/toticellimages/latest/prp-treat.jpg";
import tabhandprp2 from "./statics/toticellimages/latest/prp-prepaid.jpg";
import tabhandprp3 from "./statics/toticellimages/latest/prp-advice.jpg";
import tabhandprp4 from "./statics/toticellimages/latest/prp-after.jpg";
import tabhandprp5 from "./statics/toticellimages/latest/prp-duration.jpg";

// Tabhandler Images Treatment BMC
import tabhandbmc1 from "./statics/toticellimages/latest/bmc-condition.jpg";
import tabhandbmc2 from "./statics/toticellimages/latest/bmc-prepaired.jpg";
import tabhandbmc3 from "./statics/toticellimages/latest/bmc-treatment.jpg";
import tabhandbmc4 from "./statics/toticellimages/latest/bmc-after.jpg";
import tabhandbmc5 from "./statics/toticellimages/latest/bmc-duration.jpg";

// Tabhandler Images Treatment SVF
import tabhandsvfc1 from "./statics/toticellimages/latest/svs-condi.jpg";
import tabhandsvfc2 from "./statics/toticellimages/latest/svs-prepaid.jpg";
import tabhandsvfc3 from "./statics/toticellimages/latest/svs-advice.jpg";
import tabhandsvfc4 from "./statics/toticellimages/latest/svs-after.jpg";
import tabhandsvfc5 from "./statics/toticellimages/latest/svs-duration.jpg";

// Tabhandler Images Treatment MSC
import tabhandmscc1 from "./statics/toticellimages/Condition treated.jpeg";
import tabhandmscc2 from "./statics/toticellimages/How is prepared.jpg";
import tabhandmscc3 from "./statics/toticellimages/advoce before treatment.jpg";
import tabhandmscc4 from "./statics/toticellimages/advice after treatment.jpg";
import tabhandmscc5 from "./statics/toticellimages/duration.jpg";

// Tabhandler Images Treatment MNC
// import tabhandmncc1 from "";
// import tabhandmncc2 from "";
// import tabhandmncc3 from "";
// import tabhandmncc4 from "";
// import tabhandmncc5 from "";

// Tabhandler Images Treatment ESWT
import tabhandeswtc1 from "./statics/toticellimages/condition treated.jpg";
import tabhandeswtc2 from "./statics/toticellimages/how does it work.jpg";
import tabhandeswtc3 from "./statics/toticellimages/benefits.jpg";
import tabhandeswtc4 from "./statics/toticellimages/Side effects.jpg";

// Tabhandler Images Treatment HBOT
import tabhandhbotc1 from "./statics/toticellimages/condition-treated.jpg";
import tabhandhbotc2 from "./statics/toticellimages/how-does-it-work.jpg";
import tabhandhbotc3 from "./statics/toticellimages/Benefits-hbt.jpg";
import tabhandhbotc4 from "./statics/toticellimages/Side-effects-hbt.jpg";

// Tabhandler Images Treatment CT
import tabhandctc1 from "./statics/toticellimages/Conditions-Treated.jpg";
import tabhandctc2 from "./statics/toticellimages/How-does-cupping-therapy-work.jpg";
import tabhandctc3 from "./statics/toticellimages/BenefitCT.jpg";
import tabhandctc4 from "./statics/toticellimages/Side-Effects.jpg";

//Asthetic Speciality
import asmenucard1 from "./statics/toticellimages/erectile.jpg";
import asmenucard2 from "./statics/toticellimages/What-is-Female-Sexual-Dysfunction.jpg";
import asmenucard3 from "./statics/toticellimages/What-is-Hair-loss.jpg";
import asmenucard4 from "./statics/toticellimages/What-is-facial-skin-health.jpg";

//Asthetic Erectile D Speciality
import asedmenucard1 from "./statics/toticellimages/Sign-and-symptoms-erec.jpg";
import asedmenucard2 from "./statics/toticellimages/erectile-3.jpg";
import asedmenucard4 from "./statics/toticellimages/erectile-4.jpg";

//Asthetic Women Speciality
import aswomenucard1 from "./statics/toticellimages/sign-and-symptoms-wo.jpg";
import aswomenucard2 from "./statics/toticellimages/causes-w.jpg";
import aswomenucard4 from "./statics/toticellimages/how-o--shot-prp-works.jpg";

//Asthetic Hair Speciality
import ashamenucard1 from "./statics/toticellimages/Patchy-Hairloss.jpg";
import ashamenucard2 from "./statics/toticellimages/Causes-of-hair-loss.jpg";
import ashamenucard4 from "./statics/toticellimages/How-it-works-b.jpg";

//Asthetic Facial Rej Speciality
import asfamenucard1 from "./statics/toticellimages/Sign-and-symptomsfac.jpg";
import asfamenucard2 from "./statics/toticellimages/cause-and-risk-factors.jpeg";
import asfamenucard4 from "./statics/toticellimages/facial-3.jpeg";
import asfamenucard5 from "./statics/toticellimages/facial-4.jpeg";

//Covid-19  Speciality
import comenucard1 from "./statics/toticellimages/molecular diagnosis.jpg";
import comenucard2 from "./statics/toticellimages/Serology.jpg";
import comenucard3 from "./statics/toticellimages/Convalescent Plasma Therapy.jpg";
import comenucard4 from "./statics/toticellimages/stem cell therapy.jpg";

//Pain  Speciality
import pamenucard1 from "./statics/toticellimages/What-is-knee-pain.jpg";
import pamenucard2 from "./statics/toticellimages/what-is-back-pain.jpg";
import pamenucard3 from "./statics/toticellimages/What-is-shoulder.jpg";
import pamenucard4 from "./statics/toticellimages/what-is-heel-pain.jpg";
import pamenucard5 from "./statics/toticellimages/newchanges/Tennis Elbow.jpg";
import pamenucard6 from "./statics/toticellimages/newchanges/Rheumatoid arthritis.jpg";
import pamenucard7 from "./statics/toticellimages/newchanges/Carpal Tunnel Syndrome.jpg";

//Pain Knee Speciality
import paknmenucard1 from "./statics/toticellimages/sign-and-symptoms-knee.jpg";
import paknmenucard2 from "./statics/toticellimages/case.jpg";
import paknmenucard4 from "./statics/toticellimages/How-It-Works-knee.jpg";

//Pain Back Speciality
import pabamenucard1 from "./statics/toticellimages/Sign-&-symptoms-back.jpg";
import pabamenucard2 from "./statics/toticellimages/causes-backpain.jpg";
import pabamenucard4 from "./statics/toticellimages/how-it-works-back.jpg";

//Pain Shoulder Speciality
import pashouldmenucard1 from "./statics/toticellimages/sign-symptoms-shoulder.jpg";
import pashouldmenucard2 from "./statics/toticellimages/Causes.jpg";
import pashouldmenucard4 from "./statics/toticellimages/How-it-works-sh.jpg";

//Pain Heel Speciality
import paheelmenucard1 from "./statics/toticellimages/sign-and-symptoms1.jpg";
import paheelmenucard2 from "./statics/toticellimages/Causes-&-Risk-Factors.jpg";
import paheelmenucard4 from "./statics/toticellimages/How-it-works.jpg";

//Neurological  Speciality
import nemenucard1 from "./statics/toticellimages/autim.jpg";
import nemenucard2 from "./statics/toticellimages/musical.jpg";
import nemenucard3 from "./statics/toticellimages/cerebral-palsy.jpg";
import nemenucard4 from "./statics/toticellimages/Spinal Cord Injury.jpg";
import nemenucard5 from "./statics/toticellimages/Down Syndrome.jpg";
import nemenucard6 from "./statics/toticellimages/newchanges/Alzheimer's Disease.jpg";
import nemenucard7 from "./statics/toticellimages/newchanges/Motor Neuron Disease.jpg";

//Stem Cell   Speciality
import stmenucard1 from "./statics/toticellimages/cord-blood.jpg";
import stmenucard2 from "./statics/toticellimages/cord-tissue-banking.jpg";
import stmenucard3 from "./statics/toticellimages/empty.jpg";
import stmenucard4 from "./statics/toticellimages/empty.jpg";

//Diabetes Speciality
import dimenucard1 from "./statics/toticellimages/what-is-diabetic-foot-ulcer.jpg";
import dimenucard2 from "./statics/toticellimages/Dry-eye-disease1.jpg";
import dimenucard3 from "./statics/toticellimages/newchanges/Type 2 diabetes Mellitus.jpg";
import dimenucard31 from "./statics/toticellimages/newchanges/Type 2 Diabetes.png";

//Diabetes Foot Ulcer Speciality
import difumenucard1 from "./statics/toticellimages/dibiteise-singtum.jpg";
import difumenucard2 from "./statics/toticellimages/causes1.jpg";
import difumenucard4 from "./statics/toticellimages/How-it-works.jpg";

// About Images
import Aboutcard1 from "./statics/icons/iconmenu1.png";
import Aboutcard2 from "./statics/icons/iconmenu2.png";
import Aboutcard3 from "./statics/icons/iconmenu3.png";

// AboutUs Charimen Picture
import Charimen from "./statics/toticellimages/Managing-Director.jpg";
import Zakir from "./statics/toticellimages/dr-zakir.jpg";

// TextAndImageComponentPictures
import img11 from "./statics/toticellimages/img11.png";
//prp
import imgprp1 from "./statics/toticellimages/latest/prp-desc.jpg";
//bmc
import imgbmc1 from "./statics/toticellimages/latest/bmc-desc.jpg";
//svf
import imgsvf1 from "./statics/toticellimages/latest/svs-desc.jpg";
//msc
import imgmsc1 from "./statics/toticellimages/Description.jpg";
//mnc
import imgmnc1 from "./statics/toticellimages/PRP-Therapy.jpg";
//eswt
import imgeswt1 from "./statics/toticellimages/ESWT.jpg";
//Hbot
import imgehbot1 from "./statics/toticellimages/hb-defi.jpg";
//CT
import imgct1 from "./statics/toticellimages/cp-1.jpg";

import imgplatelet1 from "./statics/toticellimages/newchanges/Platelet Lysate-Picture.jpg";
import imghy1 from "./statics/toticellimages/newchanges/Hyperthermia Therapy.jpg";

import res1 from "./statics/toticellimages/newchanges/Asthma.jpg";
import res2 from "./statics/toticellimages/newchanges/Lung Fibrosis.jpg";
import res3 from "./statics/toticellimages/newchanges/Ankylosing Spondylitis.jpg";
import res4 from "./statics/toticellimages/newchanges/Multiple Sclerosis.jpg";
import res5 from "./statics/toticellimages/newchanges/Ulcerative colitis.jpg";




// Banner Image
import banner from "./statics/toticellimages/abt.jpg";

import doctorMale from "./statics/icons/doctor-2.png"

import doctorFemale from "./statics/icons/doctor-1.png"

//DoctorPic
export { doccard1 };
export { doccard2 };
export { doccard4 };
export { doccard5 };
export { doccard6 };
export { doccard7 };
export { doccard8 };

export { imgplatelet1 }
export { imghy1 }


;
//Header Icon
export { envelope };
export { phone };

//home Mobile card
export { mobilecard1 };
export { mobileappstore };
export { mobilegoogleplay };
export { mobileqr };

// Home Export TabHandler Icon
export { tabicon1 };
export { tabicon2 };
export { tabicon3 };
export { tabicon4 };
export { tabicon5 };
export { tabicon6 };

export { Aboutcard1 };
export { Aboutcard2 };
export { Aboutcard3 };

// Banner Image
export { banner };

// About Chairmen Image
export { Charimen };
export { Zakir };

//Home
export { img11 };
//Treatment Prp
export { imgprp1 };
//Treatment Bmc
export { imgbmc1 };
//Treatment Svf
export { imgsvf1 };
//Treatment Msc
export { imgmsc1 };
//Treatment Mnc
export { imgmnc1 };
//Treatment Eswt
export { imgeswt1 };
//Treatment Hbot
export { imgehbot1 };
//Treatment CT
export { imgct1 };

// Covid Speciality
export { comenucard1 };
export { comenucard2 };
export { comenucard3 };
export { comenucard4 };

// Pain Speciality
export { pamenucard1 };
export { pamenucard2 };
export { pamenucard3 };
export { pamenucard4 };
export { pamenucard5 };
export { pamenucard6 };
export { pamenucard7 };

// Asthetic Speciality
export { asmenucard1 };
export { asmenucard2 };
export { asmenucard3 };
export { asmenucard4 };

//Neurological  Speciality
export { nemenucard1 };
export { nemenucard2 };
export { nemenucard3 };
export { nemenucard4 };
export { nemenucard5 };
export { nemenucard6 };
export { nemenucard7 };

//Stem Cell   Speciality
export { stmenucard1 };
export { stmenucard2 };
export { stmenucard3 };
export { stmenucard4 };

//Diabetes Speciality
export { dimenucard1 };
export { dimenucard2 };
export { dimenucard3 };
export { dimenucard31 };

export {res1};
export {res2};
export {res3};
export {res4};
export {res5};

export { homecard1 };
export { homecard2 };
export { homecard3 };
export { homecard4 };
export { homecard5 };
export { homecard6 };
export { homecard7 };
export { homecard8 };

export { tabhandpic1 };
export { tabhandpic2 };
export { tabhandpic3 };
export { tabhandpic4 };
export { tabhandpic5 };
export { tabhandpic6 };

export { post1 };
export { post2 };
export { post3 };

export { tabhandprp1 };
export { tabhandprp2 };
export { tabhandprp3 };
export { tabhandprp4 };
export { tabhandprp5 };


export { tabhandbmc1 };
export { tabhandbmc2 };
export { tabhandbmc3 };
export { tabhandbmc4 };
export { tabhandbmc5 };


export { tabhandctc1 };
export { tabhandctc2 };
export { tabhandctc3 };
export { tabhandctc4 };


export { tabhandeswtc1 };
export { tabhandeswtc2 };
export { tabhandeswtc3 };
export { tabhandeswtc4 };

export { tabhandhbotc1 };
export { tabhandhbotc2 };
export { tabhandhbotc3 };
export { tabhandhbotc4 };

export { tabhandmscc1 };
export { tabhandmscc2 };
export { tabhandmscc3 };
export { tabhandmscc4 };
export { tabhandmscc5 };


export { tabhandsvfc1 };
export { tabhandsvfc2 };
export { tabhandsvfc3 };
export { tabhandsvfc4 };
export { tabhandsvfc5 };


export { asedmenucard1 };
export { asedmenucard2 };
export { asedmenucard4 };


export { ashamenucard1 };
export { ashamenucard2 };
export { ashamenucard4 };



export { asfamenucard1 };
export { asfamenucard2 };
export { asfamenucard4 };
export { asfamenucard5 };


export { aswomenucard1 };
export { aswomenucard2 };
export { aswomenucard4 };


export { pabamenucard1 };
export { pabamenucard2 };
export { pabamenucard4 };


export { paheelmenucard1 };
export { paheelmenucard2 };
export { paheelmenucard4 };

export { paknmenucard1 };
export { paknmenucard2 };
export { paknmenucard4 };

export { pashouldmenucard1 };
export { pashouldmenucard2 };
export { pashouldmenucard4 };


export { difumenucard1 };
export { difumenucard2 };
export { difumenucard4 };


export {doctorMale};
export {doctorFemale};


// PRP Treatement TabHandler Data



// SVF Treatement TabHandler Data


// MSC Treatement TabHandler Data

// MSC Treatement TabHandler Data


// ESWT Treatement TabHandler Data


// HBOT Treatement TabHandler Data

// CT Treatement TabHandler Data


// Speciality Pain Knee Tabhandler Data

// Speciality Pain Back Tabhandler Data

// Speciality Pain Shoulder Tabhandler Data

// Speciality Pain Heel  Tabhandler Data

// Speciality Pain Asthetic Ed  Tabhandler Data


// Speciality Pain Asthetic Women  Tabhandler Data


// Speciality Pain Asthetic Hair  Tabhandler Data

// Speciality Pain Asthetic Hair  Tabhandler Data

// Speciality Pain Heel  Tabhandler Data

// About Page ==============================================
export const aboutcard = [
  {
    id: 0,
    imageURL: Aboutcard2,
    text: "About TotiCell",
    link: "/abouttoti",
  },
  {
    id: 1,
    imageURL: Aboutcard2,
    text: "Management",
    link: "/our-management",
  },
  {
    id: 2,
    imageURL: Aboutcard1,
    text: "Mission",
    link: "/our-mission",
  },
  {
    id: 3,
    imageURL: Aboutcard1,
    text: "Our Doctors",
    link: "/our-doctors",
  },
];

// Treatement Page ==============================================

// Speciality Page ==============================================

// Banner Links Data All Start ============================

//======>   About Inner Pages Banner Data

//======>   About Inner Pages Banner Data

//======>  About Inner Page Our Doctor Data

//======>   About Inner Pages Banner Data

// Treatment Main Page Banner Data

// Treatment PRP Page Banner

// Treatment BMC Page Banner

// Treatment SVF Page Banner

// Treatment MSC Page Banner

// Treatment MNC Page Banner

// Treatment ESWT Page Banner

// Treatment HBOT Page Banner
// Treatment CT Page Banner

// Speciality Main Page Banner Data

// Speciality Covid Banner Data

// Speciality Covid Molecular Banner Data

// Speciality Covid Sero Banner Data

// Speciality Covid Ctp Banner Data

// Speciality Covid Scth Banner Data

// Speciality Pain Banner Data
// Speciality Pain Knee Banner Data

// Speciality Pain Back Banner Data

// Speciality Pain Frozen Shoulder Banner Data

// Speciality Pain Planter Shoulder Banner Data


// Speciality Asthetic Banner Data


// Speciality Asthetic Erec Banner Data

// Speciality Asthetic Women Banner Data

// Speciality Asthetic Hair Banner Data

// Speciality Asthetic Facial Banner Data


// Speciality Neuro Banner Data

// Speciality Autism Banner Data

// Speciality  Muscular Dystrophy Banner Data

// Speciality  Cerebral Palsy Dystrophy Banner Data

// Speciality  Spinal Cord Injury Palsy Dystrophy Banner Data

// Speciality  Down’s Syndrome Dystrophy Banner Data


// Speciality Stem Cell Banner Data


// Speciality Stem Cell CordBloodBanking Banner Data

// Speciality Stem Cell CordTissueBanking Banner Data
export const LinksBanSpecialityStcbCordTissueBanking = [
  {
    subLinkName: "Home",
    subDash: ">",
    subLink: "/",
  },
  {
    subLinkName: "Speciality",
    subDash: ">",
    subLink: "/Speciality",
  },
  {
    subLinkName: "Stem Cell Banking",
    subDash: ">",
    subLink: "/stem-cell-banking",
  },
  {
    subLinkName: "Cord Tissue Banking",
    subDash: "",
    subLink: "/cord-tissue-banking",
  },
];
// Speciality Stem Cell Community Banking Banner Data
export const LinksBanSpecialityStcbCommunity = [
  {
    subLinkName: "Home",
    subDash: ">",
    subLink: "/",
  },
  {
    subLinkName: "Speciality",
    subDash: ">",
    subLink: "/Speciality",
  },
  {
    subLinkName: "Stem Cell Banking",
    subDash: ">",
    subLink: "/stem-cell-banking",
  },
  {
    subLinkName: "Community Banking",
    subDash: "",
    subLink: "/community-banking",
  },
];
// Speciality Stem Cell Family Banking Banner Data

// Speciality Diabetes Data

// Speciality Diabetes Foot Ulcer Data

// Speciality Diabetes Foot Ulcer Data


// Evidence Main Page Banner Data

// Knowledge Main Page Banner Data

// Internation Patient Main Page Banner Data

// Careers Main Page Banner Data

// Media Page Banner Data

// Stories Main Page Banner Data


// Contact Us Main Page Banner Data

// Banner Links Data All End ============================

// About Mission Page

//Speciality Menu Card Data

//Asthetic Menu Card Data

//Covid Menu Card Data

//Pain Menu Card Data

//Nero Menu Card Data

//Stem Cell Menu Card Data

//Stem Cell Menu Card Data


// Search Merge Array
export const allarray = [
  {
    subLinkName: "About",
    subDash: "",
    subLink: "/about",
  },
  {
    subLinkName: "Treatment",
    subDash: "",
    subLink: "/treatment",
  },
  {
    subLinkName: "Speciality",
    subDash: "",
    subLink: "/Speciality",
  },
  {
    subLinkName: "About Us",
    subDash: "",
    subLink: "/abouttoti",
  },
  {
    subLinkName: "Doctors",
    subDash: "",
    subLink: "/our-doctors",
  },
  {
    subLinkName: "Our Management",
    subDash: "",
    subLink: "/our-management",
  },
  {
    subLinkName: "Our Mission",
    subDash: "",
    subLink: "/our-mission",
  },
  {
    subLinkName: "Platelet Rich Plasma (PRP) Therapy",
    subDash: "",
    subLink: "/prp-therapy",
  },
  {
    subLinkName: "Bone Marrow Cell (BMC) Therapy",
    subDash: "",
    subLink: "/bmc-therapy",
  },
  {
    subLinkName: "Stromal Vascular Fraction (SVF) Therapy",
    subDash: "",
    subLink: "/svf-therapy",
  },
  {
    subLinkName: "Mononuclear Cell (MNC) Therapy",
    subDash: "",
    subLink: "/msc-therapy",
  },
  {
    subLinkName: "Mesenchymal Stem Cell (MSC) Therapy",
    subDash: "",
    subLink: "/mnc-therapy",
  },
  {
    subLinkName: "Extracorporeal Shockwave Therapy (ESWT)",
    subDash: "",
    subLink: "/eswt-therapy",
  },
  {
    subLinkName: "Hyperbaric Oxygen Therapy (HBOT)",
    subDash: "",
    subLink: "/hbot-therapy",
  },
  {
    subLinkName: "Cupping Therapy (CT)",
    subDash: "",
    subLink: "/ct-therapy",
  },
  {
    subLinkName: "Covid-19 Management",
    subDash: "",
    subLink: "/covid-19-management",
  },
  {
    subLinkName: "Molecular Diagnosis",
    subDash: "",
    subLink: "/molecular-diagnosis",
  },
  {
    subLinkName: "Serology",
    subDash: "",
    subLink: "/serology",
  },
  {
    subLinkName: "Convalescent Plasma Therapy",
    subDash: "",
    subLink: "/convalescent-plasma-therapy",
  },
  {
    subLinkName: "Stem Cell Therapy",
    subDash: "",
    subLink: "/stem-cell-therapy",
  },
  {
    subLinkName: "Pain Management",
    subDash: "",
    subLink: "/pain-management",
  },
  {
    subLinkName: "Knee Pain",
    subDash: "",
    subLink: "/knee-pain",
  },
  {
    subLinkName: "Back Pain",
    subDash: "",
    subLink: "/back-pain",
  },
  {
    subLinkName: "Frozen Shoulder/ Shoulder Pain",
    subDash: "",
    subLink: "/frozen-shoulder-shoulder-pain",
  },
  {
    subLinkName: "Plantar Fasciitis (Heel Pain)",
    subDash: "",
    subLink: "/plantar-fasciitis",
  },
  {
    subLinkName: "Asthetic Medicine",
    subDash: "",
    subLink: "/asthetic-medicine",
  },
  {
    subLinkName: "Erectile Dysfunction (ED)",
    subDash: "",
    subLink: "/erectile-dysfunction",
  },
  
  {
    subLinkName: "Women’s Intimate Health",
    subDash: "",
    subLink: "/womens-intimate-health",
  },
  {
    subLinkName: "Hair Loss",
    subDash: "",
    subLink: "/hair-loss",
  },
  {
    subLinkName: "Facial Rejuvenation",
    subDash: "",
    subLink: "/facial-rejuvenation",
  },
  {
    subLinkName: "Neurological Rehabilitation",
    subDash: "",
    subLink: "/neurological-rehabilitation",
  },
  {
    subLinkName: "Autism",
    subDash: "",
    subLink: "/autism",
  },
  {
    subLinkName: "Muscular Dystrophy",
    subDash: "",
    subLink: "/muscular-dystrophy",
  },
  {
    subLinkName: "Cerebral Palsy",
    subDash: "",
    subLink: "/cerebral-palsy",
  },
  {
    subLinkName: "Spinal Cord Injury",
    subDash: "",
    subLink: "/spinal-cord-injury",
  },
  {
    subLinkName: "Down’s Syndrome",
    subDash: "",
    subLink: "/downs-syndrome",
  },
  {
    subLinkName: "Stem Cell Banking",
    subDash: "",
    subLink: "/stem-cell-banking",
  },
  {
    subLinkName: "Cord Tissue Banking",
    subDash: "",
    subLink: "/cord-blood-banking",
  },
 
  {
    subLinkName: "Family Banking",
    subDash: "",
    subLink: "/family-banking",
  },
  {
    subLinkName: "Diabetes",
    subDash: "",
    subLink: "/diabetes",
  },
  {
    subLinkName: "Diabetic Foot Ulcer",
    subDash: "",
    subLink: "/diabetic-foot-ulcer",
  },
  {
    subLinkName: "Dry Eye Disease",
    subDash: "",
    subLink: "/dry-eye-disease",
  },
  {
    subLinkName: "Evidence",
    subDash: "",
    subLink: "/evidence",
  },
  {
    subLinkName: "Stories",
    subDash: "",
    subLink: "/stories",
  },
  {
    subLinkName: "Contact Us",
    subDash: "",
    subLink: "/contact-us",
  },
  {
    subLinkName: "Knowledge Center",
    subDash: "",
    subLink: "/knowledge",
  },
  {
    subLinkName: "Career",
    subDash: "",
    subLink: "/career",
  },
];



export const videolinks = [
  "https://www.youtube.com/embed/j1UJDB7Ravc",
  "https://www.youtube.com/embed/IF3q9eTkdwo",
  "https://www.youtube.com/embed/pf18BrH7ZxM",
  "https://www.youtube.com/embed/4r5rJ4vcbjw",
];
