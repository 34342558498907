import React, { useEffect, useState } from "react";
import BannerWithText from "../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Testemonials from "../../../components/layouts/testimonials/Testemonials";
import TabBtnList from "../../../components/layouts/tabbuttons/DashboardBtnList";
import TabHandlerTextwithImage from "../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  mnctreatment,
  imgmnc1,
  LinksBanTreatmentMnc,
} from "../../../data";
import HeadingWithBottomBar from "../../../components/reuseablecomponents/HeadingWithBottomBar";
import useMediaQuery from "../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Toggleform from "../../../components/layouts/toggleform/toggleform";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../utils/functions";
import { Link } from "react-router-dom";

const MNC = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const [buttonState, setButtonState] = useState([]);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);

  const stories = filterComponentData(component, "our_stories", lang);
  const enquiry = filterComponentData(
    component,
    "mononuclear_cell_mnc_make_an_enquiry_",
    lang
  );
  const therapy = filterComponentData(
    component,
    "mononuclear_cell_mnc_therapy",
    lang
  );
  const tabs = filterComponentData(
    component,
    "mononuclear_cell_mnc_tabs",
    lang
  );
  const tablits = filterComponentData(
    component,
    "mononuclear_cell_mnc_tab_list",
    lang
  );
  const treatmentMetaTitles = filterComponentData(
    component,
    "treatment_meta_titles",
    lang
  );
  const treatmentSchemaMarkup = filterComponentData(
    component,
    "treatment_schema_markups",
    lang
  );
  const treatmentAltTags = filterComponentData(
    component,
    "treatment_image_alt_tags",
    lang
  );
  const treatmentMetaDesc = filterComponentData(
    component,
    "treatment_meta_descriptions",
    lang
  );
  const treatmentMetaKeywords = filterComponentData(
    component,
    "treatment_meta_keywords",
    lang
  );
  const treatmentVerification = filterComponentData(
    component,
    "treatment_verification_meta_tags",
    lang
  );

  const { t } = useTranslation("common");

  const LinksBanTreatmentMnc = [
    {
      subLinkName: banners[10]?.subtitle?.split(">")[0]
        ? banners[10]?.subtitle?.split(">")[0]
        : t("mnctherapy.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[10]?.subtitle?.split(">")[1]
        ? banners[10]?.subtitle?.split(">")[1]
        : t("mnctherapy.banner.title1"),
      subDash: ">",
      subLink: "/treatment",
    },
    {
      subLinkName: banners[10]?.subtitle?.split(">")[2]
        ? banners[10]?.subtitle?.split(">")[2]
        : t("mnctherapy.banner.title"),
      subDash: "",
      subLink: "/mnc-therapy",
    },
  ];

  const mnctreatment = [
    {
      condition: {
        tabicon: "",
        img: "",
        heading: "",
        description: t(
          "mnctherapy.ourspecialitytabs.btndetails1.discriptiontxt1"
        ),
        description1: "",
        linkchecks: [],
      },
      how_prep: {
        tabicon: "",
        img: "",
        heading: "",
        description: t(
          "mnctherapy.ourspecialitytabs.btndetails2.discriptiontxt1"
        ),
        description1: "",
        linkchecks: [],
      },
      adv_treate: {
        tabicon: "",
        img: "",
        heading: "",
        description: t(
          "mnctherapy.ourspecialitytabs.btndetails3.discriptiontxt1"
        ),
        description1: "",
        linkchecks: [],
      },
      adv_prpthrapy: {
        tabicon: "",
        img: "",
        heading: "",
        description: t(
          "mnctherapy.ourspecialitytabs.btndetails4.discriptiontxt1"
        ),
        description1: "",
        linkchecks: [],
      },
      dur: {
        tabicon: "",
        img: "",
        heading: "",
        description: t(
          "mnctherapy.ourspecialitytabs.btndetails5.discriptiontxt1"
        ),
        description1: "",
        linkchecks: [],
      },
    },
  ];

  const [selectedTab1, setSelectedTab1] = useState(
    t("mnctherapy.ourspecialitytabs.btn1")
  );
  const [selecteddata, setSelecteddata] = useState(
    tabs?.length < 1 ? mnctreatment[0].condition : tabs[0]
  );
  const [toggle, setToggle] = useState(false);

  const tabledataHandler = async (item) => {
    setSelecteddata(item);
  };
  const populateButtonArray = () => {
    let test = [];
    tabs?.map((item) => {
      test?.push(item?.name);
    });
    setButtonState(test);
  };
  const tabHandlerTest = (item) => {
    setSelectedTab1(item);
    tabs?.filter((it) =>
      it?.name?.toLowerCase() == item?.toLowerCase()
        ? setSelecteddata(it)
        : null
    );
  };
  useEffect(() => {
    populateButtonArray();
    setSelecteddata(tabs?.length < 1 ? mnctreatment[0].condition : tabs[0]);
  }, [lang, tabs?.length]);

  const buttonname = [
    t("mnctherapy.ourspecialitytabs.btn1"),
    t("mnctherapy.ourspecialitytabs.btn2"),
    t("mnctherapy.ourspecialitytabs.btn3"),
    t("mnctherapy.ourspecialitytabs.btn4"),
    t("mnctherapy.ourspecialitytabs.btn5"),
  ];
  const tabHandler = (item) => {
    setSelectedTab1(item);
    if (item === t("mnctherapy.ourspecialitytabs.btn1")) {
      tabledataHandler(mnctreatment[0].condition);
    } else if (item === t("mnctherapy.ourspecialitytabs.btn2")) {
      tabledataHandler(mnctreatment[0].how_prep);
    } else if (item === t("mnctherapy.ourspecialitytabs.btn3")) {
      tabledataHandler(mnctreatment[0].adv_treate);
    } else if (item === t("mnctherapy.ourspecialitytabs.btn4")) {
      tabledataHandler(mnctreatment[0].adv_prpthrapy);
    } else if (item === t("mnctherapy.ourspecialitytabs.btn5")) {
      tabledataHandler(mnctreatment[0].dur);
    }
  };

  const isDesktoplg = useMediaQuery("(min-width: 992px)");
  const verificationsTags = treatmentVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.mncName,
      content: item?.mncContent,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}
        {treatmentSchemaMarkup?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(JSON.stringify(item?.mnc ? item?.mnc : "[]"))}
          </script>
        ))}
        <title>
          {treatmentMetaTitles?.mnc
            ? treatmentMetaTitles?.mnc
            : t("mnctherapy.maintitle")}
        </title>
        <meta
          name="description"
          content={
            treatmentMetaDesc?.mnc
              ? treatmentMetaDesc?.mnc
              : t("mnctherapy.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            treatmentMetaKeywords?.mnc
              ? treatmentMetaKeywords?.mnc
              : t("mnctherapy.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[10]?.heading
              ? banners[10]?.heading
              : t("mnctherapy.banner.title")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[10]?.image ? banners[10]?.image : banner
          })`}
          imgSrc={banners[10]?.image ? banners[10]?.image : banner}
          LinksBan={LinksBanTreatmentMnc}
          height={isDesktoplg ? "300px" : "400px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={therapy?.image ? therapy?.image : imgmnc1}
          imgCol="col-lg-5"
          alt={treatmentAltTags?.mnc ? treatmentAltTags?.mnc : ""}
          textCol="col-lg-7"
          headingFirst={
            therapy?.name ? therapy?.name : t("mnctherapy.textrev.headfirst")
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.desc
                  ? therapy?.desc
                  : t("mnctherapy.textrev.discriptiontxt1")}
              </p>
            </>
          }
          reverse={false}
        />
        {tablits?.length < 1 ? (
          <div className="mb-5">
            <TabHandlerTextwithImage
              SelectedButtons={
                <div className="my-4">
                  <div className="row">
                    {buttonname.map((item, index) => (
                      <div
                        className="col-lg-2 col-md-6 px-2 mb-3 d-flex d-inline-flex"
                        key={index + 1}
                        onClick={() => tabHandler(item)}
                      >
                        <TabBtnList
                          label={
                            item === t("mnctherapy.ourspecialitytabs.btn1") ? (
                              <span>
                                {t("mnctherapy.ourspecialitytabs.btn1f")} <br />{" "}
                                {t("mnctherapy.ourspecialitytabs.btn1l")}
                              </span>
                            ) : item ===
                              t("mnctherapy.ourspecialitytabs.btn2") ? (
                              <span>
                                {t("mnctherapy.ourspecialitytabs.btn2f")} <br />{" "}
                                {t("mnctherapy.ourspecialitytabs.btn2l")}
                              </span>
                            ) : item ===
                              t("mnctherapy.ourspecialitytabs.btn3") ? (
                              <span>
                                {t("mnctherapy.ourspecialitytabs.btn3f")} <br />{" "}
                                {t("mnctherapy.ourspecialitytabs.btn3l")}
                              </span>
                            ) : item ===
                              t("mnctherapy.ourspecialitytabs.btn4") ? (
                              <span>
                                {t("mnctherapy.ourspecialitytabs.btn4f")} <br />{" "}
                                {t("mnctherapy.ourspecialitytabs.btn4l")}
                              </span>
                            ) : item ===
                              t("mnctherapy.ourspecialitytabs.btn5") ? (
                              <span>
                                {t("mnctherapy.ourspecialitytabs.btn5")}
                              </span>
                            ) : (
                              ""
                            )
                          }
                          className={
                            selectedTab1 === item
                              ? "dashboardBtnList-item-active"
                              : "default-color-and-hover"
                          }
                          bntStyle={{ fontSize: "15px", borderRadius: "5px" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              }
              textCol="col-lg-12"
              imgHtml=""
              alt={treatmentAltTags?.mnc ? treatmentAltTags?.mnc : ""}
              discription={<>{selecteddata?.description}</>}
              discription2={<>{selecteddata?.description1}</>}
              reverse={false}
            />
          </div>
        ) : (
          <div className="mb-5">
            <TabHandlerTextwithImage
              SelectedButtons={[selecteddata]?.map((item_) => (
                <div className="my-4">
                  <div className="row">
                    {buttonState?.map((item, index) => (
                      <div
                        className="col-lg-3 col-md-6 px-2 mb-3 d-flex d-inline-flex"
                        key={index + 1}
                        onClick={() => tabHandlerTest(item)}
                      >
                        <TabBtnList
                          label={item}
                          className={
                            selectedTab1 === item
                              ? "dashboardBtnList-item-active"
                              : "default-color-and-hover"
                          }
                          bntStyle={{ fontSize: "15px", borderRadius: "5px" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              alt={treatmentAltTags?.mnc ? treatmentAltTags?.mnc : ""}
              imgSrc={selecteddata?.image}
              imgStyle={{
                borderRadius: "4px",
                width: "100%",
                height: "340px",
                objectFit: "fill",
              }}
              hrlinecustome={
                <hr
                  style={{
                    borderBottom: "3px solid #AF5689",
                    margin: "10px 0px 15px 0px",
                    width: "80px",
                  }}
                ></hr>
              }
              headingFirst={selecteddata?.name}
              discription={<>{selecteddata?.desc}</>}
              // discription2={<>{selecteddata?.description1}</>}
              // linkcustomli={
              //   <div class="span-department mb-5">
              //     <ul>
              //       {tablits?.map((item) => {
              //         return selecteddata?.name?.toLowerCase() == item?.tab?.toLowerCase() ? (
              //           <Link
              //             to="#"
              //             style={{ textDecoration: "none", color: "#333333" }}
              //           >
              //             <li>
              //               <i class="fa fa-check"></i>
              //               {item?.text}
              //             </li>
              //           </Link>
              //         ) : (
              //           null
              //         );
              //       })}
              //     </ul>
              //   </div>
              // }
              reverse={false}
            />
          </div>
        )}

        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-12">
              <div class="section" onClick={() => setToggle(!toggle)}>
                <span class="makeinquirybt">
                  {enquiry?.button
                    ? enquiry?.button
                    : t("makeanenquiry.enquirybtn")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toggleform toggle={toggle} data={enquiry} />

      <div>
        <HeadingWithBottomBar heading={t("mnctherapy.patientfeedhead")} />

        <Testemonials stories={stories} />
      </div>
    </>
  );
};

export default MNC;
