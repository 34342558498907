import React, { useState, useEffect, useRef } from "react";
import "./videoStyle.css";
import stopVideoIcon from "../../statics/icons/camera_stop.png";
import startVideoIcon from "../../statics/icons/camera_start.png";
import micOnIcon from "../../statics/icons/mic_start.png";
import micOffIcon from "../../statics/icons/mic_stop.png";
import endCall from "../../statics/icons/callEnd.png";
import userProfile from "../../statics/toticellimages/user-profile.png";
const Participant = ({ participant, isDoctor, room, islocal }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [audioState, setaudioState] = useState(true);
  const [videoState, setvideoState] = useState(true);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));
    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };
    const trachRemoteDisable = (track) => {
      if (track?.kind === "video") {
        setvideoState(false);
      }
    };
    const trachRemoteEnable = (track) => {
      if (track?.kind === "video") {
        setvideoState(true);
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);
    participant.on("trackDisabled", trachRemoteDisable);
    participant.on("trackEnabled", trachRemoteEnable);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
    // eslint-disable-next-line
  }, [participant]);
  const muteHandler = () => {
    setaudioState(false);
    if (room !== null) {
      room?.localParticipant.audioTracks?.forEach((track) => {
        track.track.disable();
      });
    }
  };
  const unMuteHandler = () => {
    setaudioState(true);
    if (room !== null) {
      room?.localParticipant.audioTracks?.forEach((track) => {
        track.track.enable();
      });
    }
  };
  const stopVideoHandler = () => {
    // setvideoState(false);
    if (room !== null) {
      room?.localParticipant.videoTracks?.forEach((track) => {
        track.track.disable();
      });
    }
  };
  const startVideoHandler = () => {
    // setvideoState(true);
    if (room !== null) {
      room?.localParticipant.videoTracks?.forEach((track) => {
        track.track.enable();
      });
    }
  };
  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <>
      <div className="innerContainer" style={{ position: "relative" }}>
        <div
          className="col controlsHoverActive p-2"
          style={{
            // background: " linear-gradient(90deg, #476b8f 0%, #1a4774 100%)",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: "5px",
              left: "8px",
              background: " linear-gradient(90deg, #476b8f 0%, #1a4774 100%)",
              padding: "4px",
              width: "97%",
              paddingLeft: "10px",
              zIndex: "1",
            }}
          >
            <span
              style={{
                color: "white",
                fontSize: "18px",
                fontWeight: "500",
                letterSpacing: ".2rem",
              }}
            >
              {participant.identity}
            </span>
          </div>

          <video ref={videoRef} autoPlay={true} width="100%" />
          {!videoState ? (
            <div
              className="center-children-class"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "101.7%",
                height: " -webkit-fill-available",
                background: "black",
              }}
            >
              <div
                className="center-children-class"
                id={"stopVideo"}
                style={{
                  borderRadius: "50%",
                  width: "100px",
                  height: "100px",
                  background:
                    "linear-gradient(90deg, #476b8f 0%, #1a4774 100%)",
                }}
              >
                <img src={userProfile} alt="User" />
              </div>
            </div>
          ) : null}

          {/* <video ref={videoRef} autoPlay={true} width="100%" /> */}
          <audio ref={audioRef} autoPlay={true} muted={false} />
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              left: "0px",
              zIndex: "100",
              width: "100%",
              display: "none",
            }}
            className="controlsHover"
          >
            {islocal ? (
              <div className="container bg-dark">
                <div className="controlsConatainer d-flex justify-content-around">
                  {videoState ? (
                    <div
                      className="  center-children-class"
                      id={"stopVideo"}
                      onClick={stopVideoHandler}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={startVideoIcon}
                        alt="Stop Video"
                        style={{
                          height: "60px",
                          width: "60px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className="  center-children-class"
                      id={"stopVideo"}
                      onClick={startVideoHandler}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={stopVideoIcon}
                        alt="Stop Video"
                        style={{
                          height: "60px",
                          width: "60px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  )}

                  <img
                    src={endCall}
                    alt="Stop Video"
                    style={{ height: "60px", width: "60px", cursor: "pointer" }}
                    onClick={() => {
                      window.self.close();
                    }}
                  />
                  {audioState ? (
                    <div
                      className="center-children-class"
                      id={"stopVideo"}
                      onClick={muteHandler}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={micOnIcon}
                        alt="Stop Video"
                        style={{
                          height: "60px",
                          width: "60px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className=" center-children-class"
                      id={"stopVideo"}
                      onClick={unMuteHandler}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={micOffIcon}
                        alt="Stop Video"
                        style={{
                          height: "60px",
                          width: "60px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Participant;
