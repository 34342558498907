import { toast } from "react-toastify";
import apiServices from "../../services/requestHandler";
import { doctorConstants } from "../constants/doctorConstants";
import { signInConstansts } from "../constants/authConstants";
import i18next from "i18next";

export const getMyPatient = (data) => async (dispatch) => {
  dispatch({
    type: doctorConstants.DOCTOR_LOADER,
    payload: true,
  });
  try {
    const response = await apiServices.getMyPatient(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: doctorConstants.DOCTOR_LOADER,
        payload: false,
      });
      dispatch({
        type: doctorConstants.MY_PATIENTS,
        payload: response?.data?.response_data?.patient,
      });
    } else {
      dispatch({
        type: doctorConstants.DOCTOR_LOADER,
        payload: false,
      });
      toast.error(response?.data?.response_message);
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const getAllMedicine = () => async (dispatch) => {
  try {
    const response = await apiServices.getAllMedicine();
    if (response?.data?.response_code === 200) {
      dispatch({
        type: doctorConstants.ALL_MEDICINES,
        payload: response?.data?.response_data?.medicine,
      });
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const getAllTests = () => async (dispatch) => {
  try {
    dispatch({
      type: doctorConstants.TEST_LOADER,
      payload: true,
    });
    const response = await apiServices.getAllTests();
    if (response?.data?.response_code === 200) {
      dispatch({
        type: doctorConstants.TEST_LOADER,
        payload: false,
      });
      dispatch({
        type: doctorConstants.ALL_TESTS,
        payload: response?.data?.response_data?.test,
      });
    } else {
      dispatch({
        type: doctorConstants.TEST_LOADER,
        payload: false,
      });
      toast.error(response?.data?.response_message);
    }
  } catch (error) {
    dispatch({
      type: doctorConstants.TEST_LOADER,
      payload: false,
    });
    console.log("Login error", error);
  }
};

export const addprescription = (data) => async (dispatch) => {
  try {
    const response = await apiServices.addprescription(data);
    if (response?.data?.response_code === 200) {
      toast.success(i18next.t("common:toastmessages.appointmentcompleted"));
      return "success";
    } else {
      toast.error(response?.data?.response_message);
      return "false";
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const rescheduleDoctorAppointment = (data) => async (dispatch) => {
  try {
    const response = await apiServices.rescheduleDoctorAppointment(data);
    if (response?.data?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.rescheduleappointment"));
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const doctorUpdate = (data) => async (dispatch) => {
  try {
    const response = await apiServices.doctorUpdate(data);
    if (response?.response_code === 200) {
      // toast.info(i18next.t("common:toastmessages.userupdatedsuccess"));
      toast.info(response?.response_message)
      dispatch({
        type: signInConstansts.SIGN_IN,
        payload: response?.response_data?.doctor,
      });
      return true;
    } else {
      toast.error(response?.response_message);
      return false;
    }
  } catch (error) {
    console.log("Login error", error);
  }
};
export const createDoctorBoardAction = (data) => async (dispatch) => {
  try {
    const response = await apiServices.createDoctorBoard(data);
    if (response?.data?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.doctorboardcreated"));
      return "success";
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const myDoctorBoard = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.SET_LOADER,
    payload: true,
  });
  try {
    const response = await apiServices.myDoctorBoard(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      dispatch({
        type: doctorConstants.MY_BOARDS,
        payload: response?.data?.response_data?.doctors_board,
      });
    } else {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast.error(response?.data?.response_message);
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const updateBoardStatus = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: true,
    });
    const response = await apiServices.updateDoctorBoard(data);
    if (response?.response_code === 200) {

      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      dispatch({
        type: doctorConstants.UPDATE_BOARD,
        payload: response?.data?.response_data?.doctors_board,
      });
      return true
    } else {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast.error(response?.response_message);
      return false
    }
  } catch (error) {
    console.log("Login error", error);
  }
};
export const CloseBoardStatus = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: true,
    });
    const response = await apiServices.closeDoctorBoard(data);
    if (response?.data?.response_code === 200) {

      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      dispatch({
        type: doctorConstants.UPDATE_BOARD,
        payload: response?.data?.response_data?.doctors_board,
      });
      return true
    } else {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast.error(response?.data?.response_message);
      return false
    }
  } catch (error) {
    console.log("Login error", error);
  }
};