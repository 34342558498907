import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../../components/layouts/bannerImgComponents/BannerImgComponents";
import TextAndImageSection from "../../../components/reuseablecomponents/TextAndImageSection";
import { banner, LinksBanAboutUs } from "../../../data";
import { filterComponentData } from "../../../utils/functions";

const AboutToticell = () => {
  const { t } = useTranslation("common");
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const about = filterComponentData(component, "abouttoticell", lang);
  const video = filterComponentData(component, "all_video_links", lang);
  const videolink = video.filter((item) => item?.tabname == "aboutus");
  const banners = filterComponentData(component, "banner", lang);
  const AboutMetaTitles = filterComponentData(
    component,
    "about_meta_titles",
    lang
  );
  const aboutSchemaMarkups = filterComponentData(
    component,
    "about_schema_markups",
    lang
  );
  const AboutMetaDesc = filterComponentData(
    component,
    "about_meta_descriptions",
    lang
  );
  const AboutMetaKeywords = filterComponentData(
    component,
    "about_meta_keywords",
    lang
  );
  const AboutVerifications = filterComponentData(
    component,
    "about_verification_meta_tags",
    lang
  );
  const LinksBanAboutUs = [
    {
      subLinkName: banners[1]?.subtitle?.split(">")[0]
        ? banners[1]?.subtitle?.split(">")[0]
        : t("abouttoti.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[1]?.subtitle?.split(">")[1]
        ? banners[1]?.subtitle?.split(">")[1]
        : t("abouttoti.banner.title"),
      subDash: ">",
      subLink: "/about",
    },
    {
      subLinkName: banners[1]?.subtitle?.split(">")[2]
        ? banners[1]?.subtitle?.split(">")[2]
        : t("abouttoti.banner.title1"),
      subDash: "",
      subLink: "/abouttoti",
    },
  ];
  const verificationsTags = AboutVerifications?.map((item) =>
    React.createElement("meta", {
      name: item?.aboutToticellName,
      content: item?.aboutToticellContent,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}
        {aboutSchemaMarkups?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.aboutToticell ? item?.aboutToticell : "[]")
            )}
          </script>
        ))}
        <title>
          {AboutMetaTitles?.aboutToticell
            ? AboutMetaTitles?.aboutToticell
            : t("abouttoti.maintitle")}
        </title>
        <meta
          name="description"
          content={
            AboutMetaDesc?.aboutToticell
              ? AboutMetaDesc?.aboutToticell
              : t("abouttoti.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            AboutMetaKeywords?.aboutToticell
              ? AboutMetaKeywords?.aboutToticell
              : t("abouttoti.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[1]?.heading
              ? banners[1]?.heading
              : t("abouttoti.textrev.subHeading")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[1]?.image ? banners[1]?.image : banner
          })`}
          imgSrc={banners[1]?.image ? banners[1]?.image : banner}
          LinksBan={LinksBanAboutUs}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container">
          <div className="row">
            <TextAndImageSection
              textCol="col-lg-12"
              headingFirst={
                about?.heading
                  ? about?.heading
                  : t("abouttoti.textrev.headfirst")
              }
              headingSec={
                about?.heading ? null : t("abouttoti.textrev.headsecond")
              }
              discription={
                <>
                  <p>
                    {about?.desc1
                      ? about?.desc1
                      : t("abouttoti.textrev.discriptiontxt1")}
                  </p>

                  <p>
                    {about?.desc2
                      ? about?.desc2
                      : t("abouttoti.textrev.discriptiontxt2")}
                  </p>

                  <p>
                    {about?.desc3
                      ? about?.desc3
                      : t("abouttoti.textrev.discriptiontxt3")}
                  </p>

                  <p>
                    {about?.desc4
                      ? about?.desc4
                      : t("abouttoti.textrev.discriptiontxt4")}
                  </p>

                  {/* <p>{about?.desc1 ? about?.desc1: t("abouttoti.textrev.discriptiontxt5")}</p> */}
                </>
              }
            />

            <TextAndImageSection
              textCol="col-lg-12"
              hrTrue={true}
              discription={
                <>
                  <iframe
                    width="100%"
                    height="450"
                    src={
                      videolink[0]?.videolink
                        ? videolink[0]?.videolink
                        : "https://drive.google.com/file/d/1KLrwUBd6Y7EkSD1UDxZHKpA29qnas-Zd/preview"
                    }
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </>
              }
              reverse={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutToticell;
