import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Specialitycard from "../../../../components/layouts/cards/SpeciallityCard/Specialitycard";
import {
  asmenucard1,
  asmenucard2,
  asmenucard3,
  asmenucard4,
  asthetic1,
  banner,
  LinksBanSpecialityAsthetic,
} from "../../../../data";
import { filterComponentData } from "../../../../utils/functions";

const AstheticMedicine = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const asthetic = filterComponentData(
    component,
    "speciality_asthetic_medicine_card",
    lang
  );
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );

  const { t } = useTranslation("common");

  const LinksBanSpecialityAsthetic = [
    {
      subLinkName: banners[27]?.subtitle?.split(">")[0]
        ? banners[27]?.subtitle?.split(">")[0]
        : t("asthetic.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[27]?.subtitle?.split(">")[1]
        ? banners[27]?.subtitle?.split(">")[1]
        : t("asthetic.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[27]?.subtitle?.split(">")[2]
        ? banners[27]?.subtitle?.split(">")[2]
        : t("asthetic.banner.title1"),
      subDash: "",
      subLink: "/asthetic-medicine",
    },
  ];

  const asthetic1 = [
    {
      img: asmenucard1,
      head1: t("asthetic.pagemenucard.card1.text"),
      linkroute: "/erectile-dysfunction",
    },
    {
      img: asmenucard2,
      head1: t("asthetic.pagemenucard.card2.text"),
      linkroute: "/womens-intimate-health",
    },
    {
      img: asmenucard3,
      head1: t("asthetic.pagemenucard.card3.text"),
      linkroute: "/hair-loss",
    },
    {
      img: asmenucard4,
      head1: t("asthetic.pagemenucard.card4.text"),
      linkroute: "/facial-rejuvenation",
    },
  ];

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.aestheticMain ? item?.aestheticMain : "[]")
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.aestheticMain
            ? specialityMetaTitles?.aestheticMain
            : t("asthetic.maintitle")}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.aestheticMain
              ? specialityMetaDesc?.aestheticMain
              : t("asthetic.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.aestheticMain
              ? specialityMetaKeywords?.aestheticMain
              : t("asthetic.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[27]?.heading
              ? banners[27]?.heading
              : t("asthetic.banner.title1")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[27]?.image ? banners[27]?.image : banner
          })`}
          imgSrc={banners[27]?.image ? banners[27]?.image : banner}
          LinksBan={LinksBanSpecialityAsthetic}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container">
          <div className="row ">
            {asthetic?.length < 1
              ? asthetic1.map((item, index) => (
                  <Specialitycard
                    img={item?.img}
                    alt={
                      specialityAltTags?.aestheticMain
                        ? specialityAltTags?.aestheticMain
                        : ""
                    }
                    head1={item?.head1}
                    linkroute={item?.linkroute}
                  />
                ))
              : asthetic?.map((item, index) => (
                  <Specialitycard
                    img={item?.image}
                    alt={
                      specialityAltTags?.aestheticMain
                        ? specialityAltTags?.aestheticMain
                        : ""
                    }
                    head1={item?.heading}
                    linkroute={item?.route}
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AstheticMedicine;
