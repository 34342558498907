import { toast } from "react-toastify";
import apiServices from "../../services/requestHandler";
import { nurseConstants } from "../constants/nurseConstants";
export const getLabTests = () => async (dispatch) => {
  dispatch({
    type: nurseConstants.NURSE_LOADER,
    payload: true,
  });
  try {
    const response = await apiServices.getAllOrders();

    if (response?.data?.response_code === 200) {
      dispatch({
        type: nurseConstants.GET_ALL_TESTS,
        payload: response?.data?.response_data?.order,
      });
      dispatch({
        type: nurseConstants.NURSE_LOADER,
        payload: false,
      });
    } else {
      toast.error(response?.data?.response_message);
      dispatch({
        type: nurseConstants.NURSE_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const changeResportStatus = (data, id) => async (dispatch) => {
  try {
    const response = await apiServices.changeResportStatus(data);
    if (response?.response_code === 200) {
      toast.success(response?.response_message);

      if (id) {
        return response?.response_data?.report;
      }
    } else {
      toast.error(response?.response_message);
    }
  } catch (error) {
    console.log(error);
  }
};
export const getTestResports = () => async (dispatch) => {
  await apiServices.getTestResports();
};
