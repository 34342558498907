import React from 'react'
import { Link } from 'react-router-dom'

const PageMenuCard = (props) => {
    return (
        <div className={`${props.className}`} style={props.style}>
        <Link to={props.linkroute} class="bottom-post" style={{textDecoration:'none'}}>
         
        <div className={`our-speciality ${props.classNamemenucard}`}>
            <img src={props.img} alt={props.heading}/>
                <h4>{props.heading}</h4>
                <p>{props.text}</p>
        </div> 

        </Link>
      </div>
    )
}

export default PageMenuCard

