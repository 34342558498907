export const patientConstants = {
  PATIENT_LOADER: "PATIENT_LOADER",
  UPDATE_PROFILE_PATIENT: "UPDATE_PROFILE_PATIENT",
  PATIENT_APPOINTMENTS: "PATIENT_APPOINTMENTS",
  SET_ERROR: "SET_ERROR",
  GET_DEPARTMENT: "GET_DEPARTMENT",
  GET_DOCTOR_BY_DEPARTMENT_LOAD: "GET_DOCTOR_BY_DEPARTMENT_LOAD",
  GET_DOCTOR_BY_DEPARTMENT_SUCCESS: "GET_DOCTOR_BY_DEPARTMENT_SUCCESS",
  GET_SLOTS_BY_DATE: "GET_SLOTS_BY_DATE",
  RESCHEDULE_MODAL: "RESCHEDULE_MODAL",
  MY_DOCTORS: "MY_DOCTORS",
  GET_PATIENT_PRESCRIPTIONS: "GET_PATIENT_PRESCRIPTIONS",
  PURCHASED_PACKAGES: "PURCHASED_PACKAGES",
  MY_PAYMENT: "MY_PAYMENT",
  MY_REPORTS: "MY_REPORTS",
  MEDICAL_RECORDS_PATIENT: "MEDICAL_RECORDS_PATIENT",
  GENERATE_PAYMENT: "GENERATE_PAYMENT",
  READ_DOCTOR_LOCATION_SUCCESS: "READ_DOCTOR_LOCATION_SUCCESS"

};
