import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../utils/functions";
const DeliveryPolicy = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const deliveryPolicy = filterComponentData(
    component,
    "delivery_policy",
    lang
  );
  const footerMetaTitles = filterComponentData(
    component,
    "footer_meta_titles",
    lang
  );

  const footerSchemaMarkups = filterComponentData(
    component,
    "footer_schema_markups",
    lang
  );

  const footerMetaDesc = filterComponentData(
    component,
    "footer_meta_descriptions",
    lang
  );
  const footerMetaKeywords = filterComponentData(
    component,
    "footer_meta_keywords",
    lang
  );
  const footerVerification = filterComponentData(
    component,
    "footer_verification_meta_tags",
    lang
  );
  const verificationsTags = footerVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.deliveryPolicyName,
      content: item?.deliveryPolicyContent,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}
        {footerSchemaMarkups?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.deliveryPolicy ? item?.deliveryPolicy : "[]")
            )}
          </script>
        ))}
        <title>
          {footerMetaTitles?.deliveryPolicy
            ? footerMetaTitles?.deliveryPolicy
            : "Delivery Policy -Toticell"}
        </title>
        <meta
          name="description"
          content={
            footerMetaDesc?.deliveryPolicy
              ? footerMetaDesc?.deliveryPolicy
              : "Delivery Policy -Toticell"
          }
        />
        <meta
          name="keywords"
          content={
            footerMetaKeywords?.deliveryPolicy
              ? footerMetaKeywords?.deliveryPolicy
              : "Delivery Policy -Toticell"
          }
        />
      </Helmet>
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-12 my-5 text-justify">
            {deliveryPolicy.length < 1 ? (
              <>
                <div className="row">
                  <div className="col-12 my-3">
                    <h1
                      className="text-center"
                      style={{ color: "#af5689", fontWeight: "600" }}
                    >
                      Delivery Policy
                    </h1>
                    <div className="text-start">
                      <h2 style={{ color: "#af5689" }}>Deliveries</h2>
                    </div>
                    <div className="text-start">
                      <p>
                        All items{" "}
                        <span style={{ fontWeight: 700 }}>
                          &nbsp;(Medicine or Health related product)&nbsp;
                        </span>{" "}
                        are available for delivery within Dhaka Metropolitan
                        City area only for a flat rate of Tk.100, except when
                        special offers apply. We do not deliver any product
                        beyond the Dhaka Metropolitan City area
                      </p>

                      <p>
                        {" "}
                        Deliveries are dispatched, at the latest, on the next
                        working day after the order has been placed, and you
                        should receive your online order within two to five
                        working days. Over 90% of our orders are delivered
                        within two days of being shipped
                      </p>

                      <p>
                        Once your order has been shipped you will be emailed
                        notifying you of this. You can check the status of your
                        order in your account area when you are logged in
                      </p>

                      <p>
                        Your order will be sent through Local courier. If you
                        want to track your order after it’s been shipped, please
                        feel free to contact them directly at{" "}
                        <span style={{ fontWeight: 800, color: "#2dd16a" }}>
                          &nbsp;09606213234 or 10670.
                        </span>{" "}
                        If you supplied your mobile number or email address
                        during user registration or the checkout process, they
                        will contact you by text or email on the morning of
                        delivery anyway. In the unlikely event that an item
                        <span style={{ fontWeight: 700 }}>
                          &nbsp;(Medicine or Health related product){" "}
                        </span>
                        you have ordered is out of stock, we will notify you
                        immediately to inform you of any possible delays
                      </p>

                      <p>
                        Please check the contents of your package on arrival.
                        Should any items be damaged during shipment, it is
                        important that you notify upon opening the package.
                        Also, please keep all packaging materials from the
                        shipment.
                      </p>

                      <p>
                        Please note that while we will do our best to deliver
                        all orders as promptly as possible, we can’t guarantee
                        on time delivery during Festival days (Eid-ul-Fitr and
                        Eid-ul-Azha).Deliveries over the mentioned holiday time
                        may be delayed due to local courier closures.
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="text-start">
                      <h2 style={{ color: "#af5689" }}>Contact Details</h2>
                    </div>
                    <div className="text-start">
                      <p>
                        Toticell Health & Research.
                        <br />
                        <span>
                          Telephone:{" "}
                          <span style={{ fontWeight: "600" }}>
                            09606213234 or 10670
                          </span>
                        </span>
                        <br />
                        <span></span>
                        <span>
                          Email:&nbsp;
                          <a href="mailto:info@toticell.com">
                            info@toticell.com
                          </a>
                        </span>
                        <br />
                        <span>
                          Web:&nbsp;
                          <a href="https://toticell.com">
                            https://toticell.com
                          </a>
                        </span>
                      </p>
                    </div>
                    <div className="text-start">
                      <h2 style={{ color: "#af5689" }}>Payments</h2>
                    </div>
                    <div className="text-start">
                      <p>
                        We accept Debit, Visa and MasterCard payments. Beside
                        this we accept Mobile Banking and Net banking Payment
                        also. All our payments are securely processed through
                        Payment gateway
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div id="cms-content">
                {/* <div className="container"> */}
                <h1
                  style={{ color: "#af5689", fontWeight: 600 }}
                  className="text-center"
                >
                  Delivery Policy
                </h1>
                {/* </div> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: deliveryPolicy?.description,
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default DeliveryPolicy;
