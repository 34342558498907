import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import DashboardBtn from "../../components/reuseablecomponents/DashboardBtn";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import { getLabTests } from "../../store/actions/nurseActions";
import { checkPaidOrUnpaid } from "../../utils/functions";

const NurseDashboardTests = (props) => {
  const {t} = useTranslation("common")
  const dispatch = useDispatch();
  const loader = useSelector((state) => state?.nurse?.nurseLoader);
  const tests = useSelector((state) => state?.nurse?.tests);

  useEffect(() => {
    dispatch(getLabTests());
  }, [dispatch]);
  // t("dashboards.alldashboardkeys.tablekeys.tes")

  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.alltest")}</h4>
        </div>
      </div>
      {loader ? (
        <LoderContainer />
      ) : (
        <>
          <div className="row">
            <div className="col-12 ">
              <BootstrapCustomTable
                columns={[
                  {
                    formatter: (cell, row, index) => {
                      return <span>{index + 1}</span>;
                    },
                    text: "Sr.",
                  },
                  { dataField: "order_id", text: t("dashboards.alldashboardkeys.tablekeys.orderid") },
                  { dataField: "ordered_by.email_address", text: t("dashboards.alldashboardkeys.tablekeys.email") },
                  { dataField: "ordered_by.name", text: t("dashboards.alldashboardkeys.tablekeys.name") },
                  { dataField: "ordered_by.phone_number", text: t("dashboards.alldashboardkeys.tablekeys.phone") },
                  {
                    formatter: (cell, row, index) => {
                      return (
                        <span
                          style={{
                            color: checkPaidOrUnpaid(row?.paid_status?.name),
                          }}
                        >
                          {row?.paid_status?.name?.toUpperCase()}
                        </span>
                      );
                    },
                    text: t("dashboards.alldashboardkeys.tablekeys.paymentstatus"),
                  },
                  {
                    formatter: (cell, row) => {
                      return (
                        <>
                          <DashboardBtn
                            label={t("dashboards.alldashboardkeys.tablekeys.viewdetail")}
                            className="appointment-reschdule-btn"
                            onClick={() => {
                              props?.history?.push(
                                "/nurse-test-details",
                                row
                              );
                            }}
                          />
                        </>
                      );
                    },
                    text: t("dashboards.alldashboardkeys.tablekeys.action"),
                  },
                ]}
                tabledata={tests}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NurseDashboardTests;
