import React, { useEffect, useState } from "react";
import DashboardHeaderAndTile from "../../components/layouts/dashboardheaderandtile/DashboardHeaderAndTile";
import PatientAppointmentContainer from "./PatientAppointmentContainer";
import PatientMyDoctorContainer from "./PatientMyDoctorContainer";
import prescription from "../../statics/icons/prescription.svg";
import appointmentBook from "../../statics/icons/appointment-book.png";
import PatientPrescriptionContainer from "./PatientPrescriptionContainer";
import PatientPayments from "./PatientPayments";
import appointment from "../../statics/icons/date.png";
import PatientBookAppointment from "./PatientBookAppointment";
import MyDoctors from "../../statics/icons/first-aid-kit.png";
import payment from "../../statics/icons/payment.png";
import medical from "../../statics/icons/medical.png";
import PatientMedicalRecords from "./PatientMedicalRecords";
import cart from "../../statics/icons/cart.svg";
import PatientOrders from "./PatientOrders";
import req from '../../statics/icons/iconmonstr-flask-13-72.png'

import { fetchStaticData } from "../../store/actions/staticActions";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { setUserRoleFromLocal } from "../../store/actions/authActions";
import MyReports from "./MyReports";
const PatientDashboard = (props) => {
  const { t } = useTranslation("common");
  const [selectedtile, setselectedtile] = useState(
    t("dashboards.alldashboardkeys.tablekeys.appointment")
  );
  const FromView = props?.location?.state?.key;
  const dispatch = useDispatch();
  const tabHandler = (item) => {
    setselectedtile(item);
  };
  const userRole = useSelector((state) => state.logIn.userRole);

  useEffect(() => {
    dispatch(setUserRoleFromLocal(userRole));

    if (FromView === "fixedRight") {
      setselectedtile("Appointment");
    }
    if (FromView === "FromViewAppointment") {
      setselectedtile(t("dashboards.alldashboardkeys.tablekeys.appointment"));
    } else if (FromView === "fromPrescription") {
      setselectedtile(t("dashboards.alldashboardkeys.tablekeys.prescription"));
    } else if (FromView === "fromOrderDetails") {
      setselectedtile(t("dashboards.alldashboardkeys.tablekeys.orders"));
    } else if (FromView === "doctorDetails") {
      setselectedtile(t("dashboards.alldashboardkeys.tablekeys.mydoctor"));
    } else if (FromView === "fromBookAppointment") {
      setselectedtile(
        t("dashboards.alldashboardkeys.tablekeys.bookappointment")
      );
    }
    dispatch(fetchStaticData());
    // eslint-disable-next-line
  }, [FromView]);
  // {t("dashboards.alldashboardkeys.tablekeys.bookappointment")}
  return (
    <div>
      <Helmet>
        <title>{t("dashboards.alldashboardkeys.maintitle")}</title>
      </Helmet>
      {/* t("dashboards.alldashboardkeys.tablekeys.orders") */}
      <DashboardHeaderAndTile
        heading={t("dashboards.alldashboardkeys.patientmainheading1")}
        options={[
          t("dashboards.alldashboardkeys.tablekeys.appointment"),
          t("dashboards.alldashboardkeys.tablekeys.prescription"),
          t("dashboards.alldashboardkeys.tablekeys.mydoctor"),
          t("dashboards.alldashboardkeys.tablekeys.bookappointment"),
          t("dashboards.alldashboardkeys.tablekeys.payments"),
          t("dashboards.alldashboardkeys.tablekeys.addmedicalrecord"),
          t("dashboards.alldashboardkeys.tablekeys.orders"),
          "Reports",
        ]}
        icons={[
          appointment,
          prescription,
          MyDoctors,
          appointmentBook,
          payment,
          medical,
          cart,
          req
        ]}
        tabHandler={tabHandler}
        value={selectedtile}
      />
      <div className="container mt-5">
        {selectedtile ===
        t("dashboards.alldashboardkeys.tablekeys.appointment") ? (
          <PatientAppointmentContainer {...props} />
        ) : selectedtile ===
          t("dashboards.alldashboardkeys.tablekeys.prescription") ? (
          <PatientPrescriptionContainer {...props} />
        ) : selectedtile ===
          t("dashboards.alldashboardkeys.tablekeys.mydoctor") ? (
          <PatientMyDoctorContainer {...props} />
        ) : selectedtile ===
          t("dashboards.alldashboardkeys.tablekeys.payments") ? (
          <PatientPayments {...props} />
        ) : selectedtile ===
          t("dashboards.alldashboardkeys.tablekeys.bookappointment") ? (
          <PatientBookAppointment {...props} />
        ) : selectedtile ===
          t("dashboards.alldashboardkeys.tablekeys.addmedicalrecord") ? (
          <PatientMedicalRecords />
        ) : selectedtile ===
          t("dashboards.alldashboardkeys.tablekeys.orders") ? (
          <PatientOrders {...props} />
        ) : selectedtile === "Reports" ? (
          <MyReports />
        ) : null}
      </div>
    </div>
  );
};

export default PatientDashboard;
