import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../components/layouts/bannerImgComponents/BannerImgComponents";
import { banner, LinksBanEvidence } from "../../data";
import { filterComponentData } from "../../utils/functions";

const Evidence = () => {
  const { t } = useTranslation("common");

  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);
  const evidenceMetaTitle = filterComponentData(
    component,
    "evidence_meta_title",
    lang
  );
  const evidenceSchemaMarkup = filterComponentData(
    component,
    "evidence_schema_markups",
    lang
  );
  const evidenceMetaDesc = filterComponentData(
    component,
    "evidence_meta_description",
    lang
  );
  const evidenceMetaKeywords = filterComponentData(
    component,
    "evidence_meta_keyword",
    lang
  );
  const evidenceVerification = filterComponentData(
    component,
    "evidence_verification_meta_tags",
    lang
  );

  const LinksBanEvidence = [
    {
      subLinkName: banners[52]?.subtitle?.split(">")[0]
        ? banners[52]?.subtitle?.split(">")[0]
        : t("evidence.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[52]?.subtitle?.split(">")[1]
        ? banners[52]?.subtitle?.split(">")[1]
        : t("evidence.banner.title"),
      subDash: "",
      subLink: "/evidence",
    },
  ];

  const video = filterComponentData(component, "all_video_links", lang);
  const videolink = video.filter((item) => item?.tabname == "evidence");

  const videolinks1 = [
    "https://drive.google.com/file/d/1CjDVZsSUhuQz02P52iuOCUI79FpA_qYm/preview",
    "https://drive.google.com/file/d/1cJVj37DrjRB4y1X4CHiue_CWIXRwFJTW/preview",
  ];

  const verificationsTags = evidenceVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.name,
      content: item?.content,
    })
  );

  return (
    <>
      <Helmet>
        {verificationsTags}
        {evidenceSchemaMarkup?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(JSON.stringify(item?.evidence ? item?.evidence : "[]"))}
          </script>
        ))}
        <title>
          {evidenceMetaTitle?.evidenceMetaTitle
            ? evidenceMetaTitle?.evidenceMetaTitle
            : t("evidence.maintitle")}
        </title>
        <meta
          name="description"
          content={
            evidenceMetaDesc?.evidence
              ? evidenceMetaDesc?.evidence
              : t("evidence.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            evidenceMetaKeywords?.evidence
              ? evidenceMetaKeywords?.evidence
              : t("evidence.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[52]?.heading
              ? banners[52]?.heading
              : t("evidence.banner.title")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[52]?.image ? banners[52]?.image : banner
          })`}
          imgSrc={banners[52]?.image ? banners[52]?.image : banner}
          LinksBan={LinksBanEvidence}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container">
          <div className="row">
            {video.length < 1
              ? videolinks1.map((item) => {
                  return (
                    <div class="col-lg-6 col-md-6 col-sm-12 my-4">
                      <iframe
                        width="560"
                        height="415"
                        src={item}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  );
                })
              : videolink.map((v) => {
                  return (
                    <div class="col-lg-6 col-md-6 col-sm-12 my-4">
                      <iframe
                        width="560"
                        height="415"
                        src={v.videolink}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Evidence;
