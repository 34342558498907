import { cmsConstants } from "../constants/cmsConstant";

import { signInConstansts } from "../constants/authConstants";

const initialState = {
  components: [],
  language: '',
  languages: []
};
export const cmsReducer = (state = initialState, { type, payload }) => {

  switch (type) {

    case cmsConstants.READ_COMPONENT:
      return { ...state, components: payload }
    case cmsConstants.LANGUAGE_SELECTED:
      return { ...state, language: payload }
    case cmsConstants.READ_LANG:
      return { ...state, languages: payload }
    case signInConstansts.LOG_OUT_USER:
      return initialState;

    default:
      return state;
  }
};
