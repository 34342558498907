import React, { useEffect, useState } from "react";
import Participant from "./Participant";
import userProfile from "../../statics/toticellimages/user-profile.png";
import "./videoStyle.css";
const Room = ({ room }) => {
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.participants.forEach(participantConnected);
    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);

  const remoteParticipants = participants.map((participant) => (
    <Participant
      key={participant.sid}
      participant={participant}
      isDoctor={true}
    />
  ));
  const membersCount = remoteParticipants.length > 3 ? 3 : 2;
  const gridROwCount = Math.ceil(membersCount / 3 );

  return (
    <div className="bg-dark container my-5 " style={{ height: "fit-content" , paddingBottom:'20px' , textAlign:'center' }}>
      <div
        className="wrapper"
        style={{
          gridTemplateColumns: `repeat(${membersCount},1fr)`,
          gridTemplateRows: `repeat(${gridROwCount},1fr)`,
        }}
      >
        {room ? (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            room={room}
            islocal={true}
          />
        ) : (
          ""
        )}
        {remoteParticipants.length > 0 ? (
          remoteParticipants
        ) : (
          <div className="innerContainer">
            <div className="col" style={{height:'100%'}}>
              {" "}
              <div
                className="center-children-class"
                style={{
                  width: "100%",
                  height: "100%",
                  background: "black",
                }}
              >
                <div
                  className="center-children-class"
                  id={"stopVideo"}
                  style={{
                    borderRadius: "50%",
                    width: "100px",
                    height: "100px",
                    background:
                      "linear-gradient(90deg, #476b8f 0%, #1a4774 100%)",
                  }}
                >
                  <img src={userProfile} alt="User" />
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "2px",
                  left: "15px",
                  background: "#ebecf0",
                  padding: "4px",
                  width: "95%",
                  paddingLeft: "10px",
                  zIndex: "1",
                }}
              >
                <span
                  style={{
                    color: "#808080",
                    fontSize: "18px",
                    fontWeight: "500",
                    letterSpacing: ".2rem",
                  }}
                >
                  Connecting...
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Room;
