import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Specialitycard from "../../../../components/layouts/cards/SpeciallityCard/Specialitycard";
import { banner, res1, res2, res3, res4, res5 } from "../../../../data";
import { filterComponentData } from "../../../../utils/functions";

const RespiratoryDiseases = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const cards = filterComponentData(component, "respiratory_diseases", lang);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );

  const { t } = useTranslation("common");

  const LinksBanSpecialityDiabetes = [
    {
      subLinkName: banners[46]?.subtitle?.split(">")[0]
        ? banners[46]?.subtitle?.split(">")[0]
        : t("diabetes.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[46]?.subtitle?.split(">")[1]
        ? banners[46]?.subtitle?.split(">")[1]
        : t("diabetes.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[46]?.subtitle?.split(">")[2]
        ? banners[46]?.subtitle?.split(">")[2]
        : "Respiratory Diseases",
      subDash: "",
      subLink: "/respiratory-diseases",
    },
  ];

  const diabetes1 = [
    {
      img: res1,
      head1: "Asthma",
      linkroute: "/asthma",
    },
    {
      img: res2,
      head1: "Lung Fibrosis",
      linkroute: "/lungfibrosis",
    },
    {
      img: res3,
      head1: "Ankylosing Spondylitis",
      linkroute: "/ankylosingspondylitis",
    },
    {
      img: res4,
      head1: "Multiple Sclerosis",
      linkroute: "/multiplesclerosis",
    },
    {
      img: res5,
      head1: "Ulcerative Colitis",
      linkroute: "/ulcerativecolitis",
    },
  ];

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(
                item?.respiratoryMain ? item?.respiratoryMain : "[]"
              )
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.respiratoryMain
            ? specialityMetaTitles?.respiratoryMain
            : "Respiratory Diseases"}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.respiratoryMain
              ? specialityMetaDesc?.respiratoryMain
              : "Respiratory Diseases"
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.respiratoryMain
              ? specialityMetaKeywords?.respiratoryMain
              : "Respiratory Diseases"
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[46]?.heading ? banners[46]?.heading : "Respiratory Diseases"
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[46]?.image ? banners[46]?.image : banner
          })`}
          imgSrc={banners[46]?.image ? banners[46]?.image : banner}
          LinksBan={LinksBanSpecialityDiabetes}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container">
          <div className="row ">
            {cards?.length < 1
              ? diabetes1.map((item, index) => (
                  <Specialitycard
                    img={item?.img}
                    alt={
                      specialityAltTags?.respiratoryMain
                        ? specialityAltTags?.respiratoryMain
                        : ""
                    }
                    head1={item?.head1}
                    linkroute={item?.linkroute}
                  />
                ))
              : cards.map((item, index) => (
                  <Specialitycard
                    img={item?.image}
                    alt={
                      specialityAltTags?.respiratoryMain
                        ? specialityAltTags?.respiratoryMain
                        : ""
                    }
                    head1={item?.heading}
                    linkroute={item?.link}
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default RespiratoryDiseases;
