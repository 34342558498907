import { toast } from "react-toastify";
// import ToastNotification from "../../components/reuseablecomponents/ToastNotification";
import apiServices from "../../services/requestHandler";
import { signInConstansts } from "../constants/authConstants";
import { pharmacyConstants } from "../constants/pharmacyConstants";
import i18next from 'i18next' 

export const getAllMedicines = (data) => async (dispatch) => {
  dispatch({
    type: pharmacyConstants.GET_ALL_MEDICINES_LOADING,
    payload: true,
  });
  try {
    const response = await apiServices.getAllMedicines();
    if (response?.data?.response_code === 200) {
      dispatch({
        type: pharmacyConstants.GET_ALL_MEDICINES_LOADING,
        payload: false,
      });
      dispatch({
        type: pharmacyConstants.GET_ALL_MEDICINES_SUCCESS,
        payload: response?.data?.response_data?.medicine,
      });
    } else {
      dispatch({
        type: pharmacyConstants.GET_ALL_MEDICINES_ERROR,
        payload: false,
      });
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const addMedicine = (data) => async (dispatch) => {
  try {
    const response = await apiServices.addMedicine({ medicines: [data] });
    console.log(response);
    if (response?.data?.response_code === 200) {
      dispatch(getAllMedicines());
      toast(response?.data?.response_message);

      // <ToastNotification content={response?.data?.response_message} type="warning" />;
    } else {

      // <ToastNotification content={response?.data?.response_message} type="error" />;
      toast.warning(response?.data?.response_message);
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const updateMedicine = (data) => async (dispatch) => {
  try {
    const response = await apiServices.updateMedicine(data);
    if (response?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.medicineupdatedsuccess"));
      dispatch(getAllMedicines());
    } else {
      toast.success(response?.response_message)

    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const getAllOrders = (data) => async (dispatch) => {
  try {
    dispatch({
      type: pharmacyConstants.GET_ALL_ORDERS_LOADING,
      payload: true,
    });
    const response = await apiServices.getAllOrders(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: pharmacyConstants.GET_ALL_ORDERS_LOADING,
        payload: false,
      });
      dispatch({
        type: pharmacyConstants.GET_ALL_ORDERS_SUCCESS,
        payload: response?.data?.response_data?.order,
      });
    } else {
      dispatch({
        type: pharmacyConstants.GET_ALL_ORDERS_ERROR,
        payload: false,
      });
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const pharmacyPay = (data) => async (dispatch) => {

  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: true,
    });
    // eslint-disable-next-line
    const response = await apiServices.pharmacyPay(data);
    if (response?.response_code === 200) {
      dispatch(getAllOrders());
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast.success(response?.response_message);

      return true
    } else {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast.error(response?.response_message);
      return false
    }
  } catch (error) {
    console.log("Login error", error);
  }
};
export const pharmacyOrderDispatch = (data) => async (dispatch) => {
  // dispatch({
  //   type: signInConstansts.SET_LOADER,
  //   payload: true,
  // });
  try {
    // eslint-disable-next-line
    const response = await apiServices.pharmacyOrderDispatch(data);
    dispatch(getAllOrders());
    if (response?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.successfullydispatched"));

      dispatch({
        type: pharmacyConstants.GET_ALL_ORDERS_SUCCESS,
        payload: response?.response_data?.order,
      });

    } else {
      toast.error(response?.response_message);
    }
  } catch (error) {
    console.log("Login error", error);
  }
};


export const SelectedMedicinde = (data) => {
  return {
    type: pharmacyConstants.SET_SELECTED_MEDI,
    payload: data,
  };
};


export const AssignOrderAction = (data) => async (dispatch) => {
  try {
    dispatch({
      type: pharmacyConstants.ASSIGN_ORDER_LOADING,
      payload: true,
    });
    let res = await apiServices.AssignDeliveryBody(data);
    if (res?.response_code === 200) {
      dispatch({
        type: pharmacyConstants.ASSIGN_ORDER_LOADING,
        payload: false,
      });

      dispatch({
        type: pharmacyConstants.ASSIGN_ORDER_SUCESS,
        payload: res?.response_data?.order_delivery,
      });
      return true;
    } else {
      dispatch({
        type: pharmacyConstants.ASSIGN_ORDER_LOADING,
        payload: false,
      });

      toast.error(res?.response_message)
      return false;
    }
  } catch { }
};

export const ViewDeliveryStaffAction = () => async (dispatch) => {
  try {
    dispatch({
      type: pharmacyConstants.VIEW_DELIVERY_STAFF_LOADING,
      payload: true,
    });
    let res = await apiServices.ReadDeliveryStaff();
    if (res?.data?.response_code === 200) {
      dispatch({
        type: pharmacyConstants.VIEW_DELIVERY_STAFF_LOADING,
        payload: false,
      });
      dispatch({
        type: pharmacyConstants.VIEW_DELIVERY_STAFF_SUCCESS,
        payload: res?.data?.response_data?.delivery,
      });
      return true
    } else {
      return false
    }
  } catch { }
};

