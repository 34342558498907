// import { signInConstansts } from "../Constants/loginConstant";
import { pharmacyConstants } from "../constants/pharmacyConstants";

const initialState = {
  medicines: [],
  orders: [],
  loader: false,
  deliveryStaff:[],
  assignLoader:false,
  assignedOrder:{}
};
export const pharmacyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case pharmacyConstants.GET_ALL_MEDICINES_LOADING:
      return { ...state, loader: payload };
    case pharmacyConstants.GET_ALL_MEDICINES_SUCCESS:
      return { ...state, medicines: payload };
    case pharmacyConstants.GET_ALL_MEDICINES_ERROR:
      return { ...state, loader: payload };
    case pharmacyConstants.GET_ALL_ORDERS_LOADING:
      return { ...state, loader: payload };
    case pharmacyConstants.GET_ALL_ORDERS_SUCCESS:
      return { ...state, orders: payload };
    case pharmacyConstants.GET_ALL_ORDERS_ERROR:
      return { ...state, loader: payload };
    case pharmacyConstants.SET_SELECTED_MEDI:
      return { ...state, selectedMedi: payload };
    case pharmacyConstants.VIEW_DELIVERY_STAFF_LOADING:
      return { ...state, loader: payload };
    case pharmacyConstants.VIEW_DELIVERY_STAFF_SUCCESS:
      return { ...state, deliveryStaff: payload };
    case pharmacyConstants.ASSIGN_ORDER_LOADING:
      return { ...state, assignLoader: payload };
    case pharmacyConstants.ASSIGN_ORDER_SUCESS:
      return { ...state, assignedOrder: payload };
    // case signInConstansts.LOG_OUT_USER:
    //   return initialState;
    default:
      return state;
  }
};
