import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddMedicinesPrescriptionModal from "../../components/layouts/modal/addMedicinePrescription/AddPrescriptionMedicineModal";
import DoctorBoardModal from "../../components/layouts/modal/doctorBoardModal/DoctorBoardModal";
import DashboardBtnList from "../../components/reuseablecomponents/DashboardBtnList";
import DashBoardMainHading from "../../components/reuseablecomponents/DashBoardMainHading";
import {
  addprescription,
  createDoctorBoardAction,
  getAllMedicine,
} from "../../store/actions/docotorActions";
import { patientAppointments } from "../../store/actions/patientActions";
import {
  // joinChatToken,
  videoCallToken,
} from "../../store/actions/staticActions";
import Loader from "react-loader-spinner";
import ChatIframe from "../../services/twillio/ChatIframe";

import DetailTable from "../../components/reuseablecomponents/DetailTable";
import AddTestModal from "../../components/layouts/modal/addTestModal/AddTestModal";
import DashboardBtn from "../../components/reuseablecomponents/DashboardBtn";
import { useTranslation } from "react-i18next";
import FollowUp from "../../components/layouts/modal/AddFollowUp/AddFollowUpModal";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
const DoctorAddPrescription = (props) => {
  const { t } = useTranslation("common");
  const [showDiagnosis, setShowDiagnosis] = useState(false);
  const [showMedi, setShowMedi] = useState(false);
  const [showTest, setShowTest] = useState(false);
  const [doctorModal, setdoctorModal] = useState(false);
  const [doctorList, setdoctorList] = useState([]);
  const [diagnosis, setDiagnosis] = useState("");
  const [followupModal, setfollowupModal] = useState(false);
  const [selectedFollowup, setselectedFollowup] = useState([]);

  const [selectedMedi, setSelectedMedi] = useState([]);
  const [selectedTest, setSelectedTest] = useState([]);

  // const allTests = useSelector((state) => state?.doctorReducer?.allTests);
  const user = useSelector((state) => state?.logIn?.user);
  const [BoardCreated, setBoardCreated] = useState(false);
  const [boardLoader, setBoardLoader] = useState(false);

  const dispatch = useDispatch();
  const [loadingVideo, setloadingVideo] = useState(false);
  const [showchat, setshowchat] = useState(false);
  // eslint-disable-next-line
  const [loadingChat, setloadingChat] = useState(false);

  const viewData = props.location.state.row;
  const key = props.location.state.from;

  const item = { key: "FromAddPrescrition" };
  const [selectedTab, setselectedTab] = useState(
    t("dashboards.alldashboardkeys.tablekeys.medicine")
  );
  const uniqueMedi = selectedMedi.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      selectedMedi.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
  const uniqueTest = selectedTest.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      selectedTest.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
  const uniqueDoctor = doctorList.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      doctorList.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
  let uniqueArray = selectedFollowup.filter(function (item, pos) {
    return selectedFollowup.indexOf(item) == pos;
  });
  const tabHandler = (item) => {
    setselectedTab(item);
  };
  useEffect(() => {
    dispatch(getAllMedicine());

    // dispatch(getAllTests());
  }, [dispatch]);

  const modalHandler = (value) => {
    setShowMedi(value);
  };
  const doctorAddHandler = (item) => {
    setdoctorList([...doctorList, ...item]);
  };
  const callHandler = async () => {
    setloadingVideo(true);
    const res = await dispatch(videoCallToken(false, { id: viewData?.id }));
    if (res === "true") {
      setloadingVideo(false);
      const win = window.open(`/call/${viewData?.id}`, "_blank");
      win.focus();
    } else {
      setloadingVideo(false);
    }
  };
  // eslint-disable-next-line
  const chatHandler = async () => {
    // setloadingChat(true);
    // const res = await dispatch(joinChatToken(false, { id: viewData?.id }));
    // if (res === "success") {
    //   setloadingChat(false);
    //   localStorage.setItem("ChatUser", viewData?.patient_details?.name);
    //   const win = window.open(
    //     `/chat/${viewData?.id}/${user?.id}/${viewData?.doctor_details?.name}/false`,
    //     "_blank"
    //   );
    //   win.focus();
    // } else {
    //   setloadingChat(false);
    // }
    setshowchat(true);
  };
  const clickHandlerPrescription = async () => {
    if (
      (selectedMedi?.length < 1 || selectedTest?.length < 1) && !diagnosis
    ) {
      toast.error(
        "Please Add Diagnosis, Medicine and Test To Finish Appointment"
      );
    } else {
      const apiData = {
        appointment_id: viewData?.id,
        tests: selectedTest.map((item) => item?.id),
        medicines: selectedMedi,
        diagnosis: diagnosis,
        follow_up_dates: selectedFollowup,
      };
      const response = await dispatch(addprescription(apiData));
      if (response === "success") {
        const apiData = {
          id: user?.id,
        };
        dispatch(patientAppointments(apiData));
        props.history.push("/doctor-dashboard", item);
      }
    }
  };
  const createDoctorBoard = async () => {
    setBoardLoader(true);
    const apiData = {
      appointment_id: viewData?.id,
      doctor_ids: doctorList?.map((item) => item?.id),
    };
    const res = await dispatch(createDoctorBoardAction(apiData));
    if (res === "success") {
      setBoardCreated(true);
      setBoardLoader(false);
    }
    setBoardLoader(false);
  };
  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading
        title={t("dashboards.alldashboardkeys.tablekeys.appointmentdetail")}
      />
      {showchat ? (
        <>
          <div className="container">
            <div className="row center-children-class">
              <div className="col-lg-10 col-md-12">
                <div
                  className="btn btnSquare"
                  style={{ width: "150px" }}
                  onClick={() => {
                    setshowchat(false);
                  }}
                >
                  <i className="fas fa-arrow-left"></i>{" "}
                  {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
                </div>
              </div>
            </div>

            <ChatIframe
              user_id={user?.id}
              appointment_id={viewData?.id}
              board={false}
              name={user?.name}
            />
          </div>
        </>
      ) : (
        <>
          <div className="container">
            <div className="row ">
              <div className="col-xl-8 col-md-8 col-12 px-0 mb-3">
                <div
                  className="btn btnSquare"
                  style={{ width: "150px" }}
                  onClick={() => {
                    props.history.push("/doctor-dashboard");
                  }}
                >
                  <i className="fas fa-arrow-left"></i>{" "}
                  {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
                </div>
              </div>
            </div>
            <div className="row center-childrens  pb-5">
              <div className="col-xl-12 col-md-12 col-12 containerWithShadow p-4">
                <h5>
                  {t("dashboards.alldashboardkeys.tablekeys.patientdetail")}
                </h5>
                <div className="row ">
                  <div className="col-6 ">
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        <span
                          className="light-Color-Para"
                          style={{ fontSize: "12px", fontWeight: "500" }}
                        >
                          {t("dashboards.alldashboardkeys.tablekeys.datantime")}
                        </span>
                        <p style={{ fontWeight: "600" }}>
                          {viewData?.date} @ {viewData?.slot.time?.from} -{" "}
                          {viewData?.slot.time?.to}{" "}
                        </p>
                      </li>
                      <li>
                        <span
                          className="light-Color-Para"
                          style={{ fontSize: "12px", fontWeight: "500" }}
                        >
                          {t(
                            "dashboards.alldashboardkeys.tablekeys.patientname"
                          )}
                        </span>
                        <p>{viewData?.patient_details?.name}</p>
                      </li>{" "}
                      <li>
                        <span
                          className="light-Color-Para"
                          style={{ fontSize: "12px", fontWeight: "500" }}
                        >
                          {t(
                            "dashboards.alldashboardkeys.tablekeys.patientemail"
                          )}
                        </span>
                        <p>{viewData?.patient_details?.email_address}</p>
                      </li>{" "}
                      <li>
                        <span
                          className="light-Color-Para"
                          style={{ fontSize: "12px", fontWeight: "500" }}
                        >
                          {t("dashboards.alldashboardkeys.tablekeys.age")}
                        </span>
                        <p>{viewData?.patient_details?.age}</p>
                      </li>
                      <li>
                        <span
                          className="light-Color-Para"
                          style={{ fontSize: "12px", fontWeight: "500" }}
                        >
                          {t(
                            "dashboards.alldashboardkeys.tablekeys.description"
                          )}
                        </span>
                        <p>{viewData?.description}</p>
                      </li>
                      <li>
                        <span
                          className="light-Color-Para"
                          style={{ fontSize: "12px", fontWeight: "600" }}
                        >
                          {t(
                            "dashboards.alldashboardkeys.tablekeys.consultationtype"
                          )}
                        </span>
                        <p style={{ fontWeight: "600" }}>
                          {viewData?.consultation_type?.name?.toUpperCase()}
                        </p>
                      </li>
                      <li>
                        <span
                          className="light-Color-Para"
                          style={{ fontSize: "12px", fontWeight: "600" }}
                        >
                          {t("dashboards.alldashboardkeys.tablekeys.city")}
                        </span>
                        <p style={{ fontWeight: "600" }}>
                          {viewData?.city?.toUpperCase()}
                        </p>
                      </li>
                    </ul>
                  </div>

                  <div className="col-12 "></div>
                </div>

                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  {key === "today" ? (
                    <div
                      className="btn btn-success"
                      style={{ float: "right" }}
                      onClick={callHandler}
                    >
                      {loadingVideo ? (
                        <Loader
                          height={20}
                          width={20}
                          type="ThreeDots"
                          color="#fff"
                        />
                      ) : (
                        <>
                          <i
                            class="fa fa-phone"
                            aria-hidden="true"
                            style={{ fontSize: "13px" }}
                          ></i>
                          {t("dashboards.alldashboardkeys.tablekeys.callbtn")}
                        </>
                      )}
                    </div>
                  ) : null}

                  {key === "today" ? (
                    <div
                      className="btn btn-success ml-2"
                      style={{ float: "right" }}
                      onClick={chatHandler}
                    >
                      {loadingChat ? (
                        <Loader
                          height={20}
                          width={20}
                          type="ThreeDots"
                          color="#fff"
                        />
                      ) : (
                        <>
                          <i
                            className="fas fa-comment-alt"
                            style={{ fontSize: "13px" }}
                          ></i>
                          {t("dashboards.alldashboardkeys.tablekeys.chatbtn")}
                        </>
                      )}
                    </div>
                  ) : null}
                </div>
                <br />
                <br />
                <div className="row">
                  <div className="col-lg-10 col-md-8">
                    <div className="row">
                      {[
                        t("dashboards.alldashboardkeys.tablekeys.medicine"),
                        t("dashboards.alldashboardkeys.tablekeys.tes"),
                        t("dashboards.alldashboardkeys.tablekeys.diagnoses"),
                        t("dashboards.alldashboardkeys.tablekeys.doctorboard"),
                        "Follow Up",
                      ].map((item, index) => (
                        <div
                          className="col-lg-3 mt-2"
                          key={index + 1}
                          onClick={() => tabHandler(item)}
                        >
                          <DashboardBtn
                            label={item}
                            active={selectedTab === item}
                            labelStyle={
                              selectedTab === item ? { color: "#fff" } : ""
                            }
                            className={
                              selectedTab === item
                                ? "dashboardBtnList-item-active py-2"
                                : "default-color-and-hover py-2"
                            }
                            onClick={() => tabHandler(item)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {selectedTab ===
                t("dashboards.alldashboardkeys.tablekeys.diagnoses") ? (
                  showDiagnosis ? (
                    <>
                      <div className="row">
                        <div className="col-lg-6 col-md-8 col-12 mt-3">
                          <div className="containerWithShadow w-100 p-3 mt-2">
                            <textarea
                              className="w-100 p-3"
                              value={diagnosis}
                              style={{ height: "90px" }}
                              onChange={(e) => setDiagnosis(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-6 mt-2">
                          <input
                            type="submit"
                            className="btn btn-success w-100"
                            value={t(
                              "dashboards.alldashboardkeys.tablekeys.addbtn"
                            )}
                            onClick={() => setShowDiagnosis(false)}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <h5 className="mt-3">
                        {t("dashboards.alldashboardkeys.tablekeys.diagnose")}
                      </h5>

                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowDiagnosis(true)}
                        className="btn btn-outline-success"
                      >
                        {diagnosis.length > 0
                          ? `+ ${t(
                              "dashboards.alldashboardkeys.tablekeys.editdiagnose"
                            )}`
                          : `+ ${t(
                              "dashboards.alldashboardkeys.tablekeys.adddiagnose"
                            )}`}
                      </span>
                      <br />
                      <div className="containerWithShadow w-50 p-3 mt-2">
                        <span>{diagnosis}</span>
                      </div>
                    </>
                  )
                ) : selectedTab ===
                  t("dashboards.alldashboardkeys.tablekeys.medicine") ? (
                  <>
                    <h5 className="mt-3">
                      {" "}
                      {t("dashboards.alldashboardkeys.tablekeys.medicine")}
                    </h5>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => modalHandler(true)}
                      className="btn btn-outline-success mb-2"
                    >
                      {`+ ${t(
                        "dashboards.alldashboardkeys.tablekeys.addmedicine"
                      )}`}
                    </span>
                    <br />
                    {selectedMedi.length > 0 ? (
                      <>
                        {" "}
                        <DetailTable
                          tableHead={[
                            t("dashboards.alldashboardkeys.tablekeys.medname"),
                            t(
                              "dashboards.alldashboardkeys.tablekeys.morningdose"
                            ),
                            t(
                              "dashboards.alldashboardkeys.tablekeys.afternoondose"
                            ),
                            t(
                              "dashboards.alldashboardkeys.tablekeys.nightdose"
                            ),
                            t("dashboards.alldashboardkeys.tablekeys.durdose"),
                            t("dashboards.alldashboardkeys.tablekeys.action"),
                          ]}
                          data={uniqueMedi.map((item, index) => {
                            return [
                              item?.name,
                              item?.dose[0],
                              item?.dose[1],
                              item?.dose[2],
                              item?.duration,
                              <>
                                {" "}
                                <div className="row">
                                  <div className="col">
                                    <Button
                                      className="btn btn-danger"
                                      onClick={() => {
                                        setSelectedMedi(
                                          uniqueMedi?.filter(
                                            (_item) => _item !== item
                                          )
                                        );
                                      }}
                                    >
                                      {t(
                                        "dashboards.alldashboardkeys.tablekeys.remove"
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </>,
                            ];
                          })}
                        />
                      </>
                    ) : null}
                  </>
                ) : selectedTab ===
                  t("dashboards.alldashboardkeys.tablekeys.tes") ? (
                  <>
                    <h5 className="mt-3">
                      {t("dashboards.alldashboardkeys.tablekeys.tes")}
                    </h5>

                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowTest(true)}
                      className="btn btn-outline-success"
                    >
                      {`+ ${t(
                        "dashboards.alldashboardkeys.tablekeys.addtest"
                      )}`}
                    </span>

                    <br />

                    <div className="w-50">
                      <DetailTable
                        tableHead={[
                          "SNo .",
                          t("dashboards.alldashboardkeys.tablekeys.testname"),
                          t("dashboards.alldashboardkeys.tablekeys.action"),
                        ]}
                        data={uniqueTest.map((item, index) => {
                          return [
                            index,
                            item?.name,
                            <>
                              {" "}
                              <div className="row">
                                <div className="col">
                                  <Button
                                    className="btn btn-danger"
                                    onClick={() => {
                                      setSelectedTest(
                                        uniqueTest?.filter(
                                          (_item) => _item !== item
                                        )
                                      );
                                    }}
                                  >
                                    {t(
                                      "dashboards.alldashboardkeys.tablekeys.remove"
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </>,
                          ];
                        })}
                      />
                    </div>
                  </>
                ) : selectedTab ===
                  t("dashboards.alldashboardkeys.tablekeys.doctorboard") ? (
                  <>
                    <h5 className="my-3">
                      {t("dashboards.alldashboardkeys.tablekeys.doctorboard")}
                    </h5>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setdoctorModal(true)}
                      className="btn btn-outline-success"
                    >
                      {`+ ${t(
                        "dashboards.alldashboardkeys.tablekeys.adddocboard"
                      )}`}
                    </span>
                    {uniqueDoctor.length > 0 ? (
                      <div className="w-50">
                        <DetailTable
                          tableHead={[
                            "SNo .",
                            t(
                              "dashboards.alldashboardkeys.tablekeys.doctorname"
                            ),
                            t("dashboards.alldashboardkeys.tablekeys.action"),
                          ]}
                          data={uniqueDoctor.map((item, index) => {
                            return [
                              index,
                              item?.name,

                              <>
                                {" "}
                                <div className="row">
                                  <div className="col">
                                    <Button
                                      className="btn btn-danger"
                                      onClick={() => {
                                        const test = [...uniqueDoctor];
                                        test.splice(index, 1);
                                        setdoctorList(test);
                                      }}
                                    >
                                      {t(
                                        "dashboards.alldashboardkeys.tablekeys.remove"
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </>,
                            ];
                          })}
                        />
                      </div>
                    ) : null}
                    {doctorList.length > 0 && !BoardCreated ? (
                      <span
                        className="btn btn-primary mt-2"
                        onClick={() => createDoctorBoard()}
                      >
                        {boardLoader ? (
                          <Loader
                            height={20}
                            width={20}
                            type="ThreeDots"
                            color="#fff"
                          />
                        ) : (
                          t("dashboards.alldashboardkeys.tablekeys.createboard")
                        )}
                      </span>
                    ) : null}
                  </>
                ) : selectedTab === "Follow Up" ? (
                  <>
                    <h5 className="my-3">Follow Up</h5>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setfollowupModal(true)}
                      className="btn btn-outline-success"
                    >
                      {"+ ADD Follow Up"}
                    </span>
                    {uniqueArray.length > 0 ? (
                      <div className="w-50">
                        <DetailTable
                          tableHead={[
                            "SNo .",
                            t("dashboards.alldashboardkeys.tablekeys.date"),
                            t("dashboards.alldashboardkeys.tablekeys.action"),
                          ]}
                          data={uniqueArray.map((item, index) => {
                            return [
                              index,
                              item,

                              <>
                                {" "}
                                <div className="row">
                                  <div className="col">
                                    <DashboardBtn
                                      label={t(
                                        "dashboards.alldashboardkeys.tablekeys.remove"
                                      )}
                                      onClick={async () => {
                                        const test = [...uniqueArray];
                                        test.splice(index, 1);
                                        await setselectedFollowup(test);
                                      }}
                                    />
                                  </div>
                                </div>
                              </>,
                            ];
                          })}
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}

                <AddMedicinesPrescriptionModal
                  show={showMedi}
                  onHide={() => modalHandler(!showMedi)}
                  selectedMedi={selectedMedi}
                  setSelectedMedi={setSelectedMedi}
                  {...props}
                />
                <DoctorBoardModal
                  show={doctorModal}
                  onHide={() => setdoctorModal(!doctorModal)}
                  setdoctorList={doctorAddHandler}
                  {...props}
                />
                <AddTestModal
                  show={showTest}
                  onHide={() => setShowTest(!showTest)}
                  selectedTest={selectedTest}
                  setSelectedTest={setSelectedTest}
                  {...props}
                />
                <FollowUp
                  show={followupModal}
                  onHide={() => setfollowupModal(!followupModal)}
                  selectedFollowup={selectedFollowup}
                  setselectedFollowup={setselectedFollowup}
                  {...props}
                />
                {doctorList?.name}
                <div className="col-xl-12 col-md-12 col-12  d-flex justify-content-end">
                  <div
                    className="btn btnSquare"
                    onClick={clickHandlerPrescription}
                  >
                    {t(
                      "dashboards.alldashboardkeys.tablekeys.finishappointment"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DoctorAddPrescription;
