import { React, useState, useEffect } from "react";
// import DashboardBtnList from "../../components/reuseablecomponents/DashboardBtnList";
import { useDispatch, useSelector } from "react-redux";
import RemoveDoctorAdministratorModal from "../../components/layouts/modal/removeDoctorAdministratorModal/RemoveDoctorAdministratorModal";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import AddSlotModal from "../../components/layouts/modal/addSlotsModal/AddSlotsModal";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import { getadministratorDoctor } from "../../store/actions/administratorActions";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import { useTranslation } from "react-i18next";

const AdministratorDoctorContainer = (props) => {
  const { t } = useTranslation("common")
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [addSlotModal, setsAddSlotModal] = useState(false);
  const [removeDoctorModal, setRemoveDoctorModal] = useState(false);
  // eslint-disable-next-line

  // eslint-disable-next-line
  const [selectedDoctor, setselectedDoctor] = useState({});
  // eslint-disable-next-line

  const administratorDoctor = useSelector(
    (state) => state?.administrator?.doctor
  );
  const loader = useSelector(
    (state) => state?.administrator?.administrator_loader
  );
  useEffect(() => {
    if (administratorDoctor.length < 1) {
      dispatch(getadministratorDoctor());
    }
    // eslint-disable-next-line
  }, []);
  const handleChange = (value, item) => {
    if (value === t("dashboards.alldashboardkeys.tablekeys.viewbtn")) {
      props.history.push("/administrator-doctor-details", item);
    } else if (value === t("dashboards.alldashboardkeys.tablekeys.addslot")) {
      setselectedDoctor(item);
      setsAddSlotModal(true);
    } else {
      setselectedDoctor(item);
      setRemoveDoctorModal(true);
    }
  };
  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.alldoctors")}</h4>
        </div>
      </div>
      {loader ? (
        <LoderContainer />
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <BootstrapCustomTable
                columns={[
                  { dataField: "name", text: t("dashboards.alldashboardkeys.tablekeys.name") },

                  // { dataField: "speciality.name", text: t("dashboards.alldashboardkeys.tablekeys.speciality") },
                  { dataField: "date_of_birth", text: t("dashboards.alldashboardkeys.tablekeys.dateofbirth") },

                  { dataField: "email_address", text: t("dashboards.alldashboardkeys.tablekeys.emailaddress") },
                  { dataField: "gender.name", text: t("dashboards.alldashboardkeys.tablekeys.gender") },

                  {
                    formatter: (cell, row, index) => {
                      return (
                        <ActionBtn
                          options={[
                            t("dashboards.alldashboardkeys.tablekeys.viewbtn")
                            ,
                            t("dashboards.alldashboardkeys.tablekeys.addslot")
                            ,
                            t("dashboards.alldashboardkeys.tablekeys.remove")
                          ]}
                          handleChange={handleChange}
                          data={row}
                        />
                      );
                    },
                    text: t("dashboards.alldashboardkeys.tablekeys.action"),
                  },
                ]}
                tabledata={administratorDoctor}
              />
            </div>
          </div>
        </>
      )}
      <AddSlotModal
        show={addSlotModal}
        viewData={selectedDoctor}
        onHide={() => setsAddSlotModal(false)}
        {...props}
      />
      <RemoveDoctorAdministratorModal
        show={removeDoctorModal}
        onHide={() => setRemoveDoctorModal(false)}
        selecteddoctor={selectedDoctor}
        {...props}
      />
    </div>
  );
};
export default AdministratorDoctorContainer;
