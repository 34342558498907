import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import UploadReportModal from "../../components/layouts/modal/uploadReportModal copy/UploadReportModal";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import DashboardBtn from "../../components/reuseablecomponents/DashboardBtn";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import {
  deleteMedicalRecordAction,
  myMedicalRecords,
} from "../../store/actions/patientActions";
const PatientMedicalRecords = (props) => {
  const { t } = useTranslation("common");
  const [selectedTab, setselectedTab] = useState(
    t("dashboards.alldashboardkeys.tablekeys.prescription")
  );

  const loader = useSelector((state) => state?.patient?.patientLoader);
  const user = useSelector((state) => state?.logIn?.user);
  const medicalRecords = useSelector((state) => state?.patient?.medicalRecords);
  const [tableData, settableData] = useState([]);
  const [uploadModal, setuploadModal] = useState({ show: false, from: "" });
  const dispatch = useDispatch();
  let tableDataFormatPrescription = [];
  medicalRecords?.prescriptions?.map((item, index) =>
    tableDataFormatPrescription.push({
      sr: index,
      medical_record_id: item?.medical_record_id,
      created_at: moment.unix(item?.created_at)?.format("YYYY-MM-DD"),
      prescription_url: item?.prescription_url,
      id: item?.id,
    })
  );
  let tableDataFormatReports = [];
  medicalRecords?.reports?.map((item, index) =>
    tableDataFormatReports.push({
      sr: index,
      medical_record_id: item?.medical_record_id,
      created_at: moment.unix(item?.created_at)?.format("YYYY-MM-DD"),
      report_url: item?.report_url,
      id: item?.id,
    })
  );
  useEffect(() => {
    dispatch(myMedicalRecords({ id: user?.id }));
    settableData(tableDataFormatPrescription);
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    settableData(tableDataFormatPrescription);
  }, [medicalRecords]);
  const tabHandler = (item) => {
    setselectedTab(item);
    if (item === t("dashboards.alldashboardkeys.tablekeys.prescription")) {
      settableData(tableDataFormatPrescription);
    } else {
      settableData(tableDataFormatReports);
    }
  };
  const clickHandler = () => {
    if (
      selectedTab === t("dashboards.alldashboardkeys.tablekeys.prescription")
    ) {
      setuploadModal({
        show: true,
        from: t("dashboards.alldashboardkeys.tablekeys.prescription"),
      });
    } else if (
      selectedTab === t("dashboards.alldashboardkeys.tablekeys.reports")
    ) {
      setuploadModal({
        show: true,
        from: t("dashboards.alldashboardkeys.tablekeys.reports"),
      });
    }
  };
  const handleChange = async (value, data) => {
    if (value == "View") {
      if (
        selectedTab === t("dashboards.alldashboardkeys.tablekeys.prescription")
      ) {
        window.open(data?.prescription_url);
        window.focus();
      }
      if (selectedTab === t("dashboards.alldashboardkeys.tablekeys.reports")) {
        window.open(data?.report_url);
        window.focus();
      }
    } else if (value == "Delete") {
      let res = await dispatch(
        deleteMedicalRecordAction({
          medical_record_id: data?.id,
          is_active: false,
        })
      );
      if (res) dispatch(myMedicalRecords({ id: user?.id }));
    }
  };
  return (
    <>
      <div className="w-100">
        <div className="p-3">
          <span className="dashboardHeadingText">
            {t("dashboards.alldashboardkeys.tablekeys.medicalrecords")}
          </span>
        </div>
        <div className="row px-3 mb-3">
          {[
            t("dashboards.alldashboardkeys.tablekeys.prescription"),
            t("dashboards.alldashboardkeys.tablekeys.reports"),
          ]?.map((item, index) => (
            <div className="col-lg-3 col-md-4 col-12 mt-2" key={index + 1}>
              <DashboardBtn
                label={item}
                active={selectedTab === item}
                onClick={tabHandler}
              />
            </div>
          ))}
        </div>
        {loader ? (
          <LoderContainer />
        ) : (
          <>
            <BootstrapCustomTable
              columns={[
                {
                  formatter: (cell, row, index) => {
                    return <span>{index + 1}</span>;
                  },
                  dataField: "sr.",
                  text: "Sr.",
                },
                {
                  dataField: "medical_record_id",
                  text: t("dashboards.alldashboardkeys.tablekeys.recordId"),
                },
                {
                  dataField: "created_at",
                  text: t("dashboards.alldashboardkeys.tablekeys.date"),
                },
                {
                  formatter: (cell, row, index) => {
                    return (
                      <ActionBtn
                        options={[
                          "View",
                          "Delete",
                        ]}
                        handleChange={handleChange}
                        data={row}
                      />
                    );
                  },
                },
              ]}
              tabledata={tableData || []}
            />
          </>
        )}
        <div className="btn btnSquare mb-5" onClick={clickHandler}>
          {selectedTab ===
          t("dashboards.alldashboardkeys.tablekeys.prescription")
            ? t("dashboards.alldashboardkeys.tablekeys.addprescriptions")
            : t("dashboards.alldashboardkeys.tablekeys.addreport")}
        </div>
        <UploadReportModal
          show={uploadModal.show}
          onHide={() => setuploadModal(false)}
          viewData={uploadModal.from}
          {...props}
        />
      </div>
    </>
  );
};

export default PatientMedicalRecords;
