import React from "react";
import borderimg from '../../statics/toticellimages/bords.png'
const HeadingWithBottomBar = (props) => {
  return (
    <div className="text-center mt-5">
      <p className="sub-heading">{props.subHeading}</p>
      <h2 style={{ color: "#444",fontWeight: "600" }}>{props.heading}</h2>
      <img src={borderimg} style={{width:'auto', margin:'auto' , float:'none' , display:'block' , marginTop: '13px'}} alt={props.heading}/>
      <p
        className="max-600 mt-4"
        style={{ color: "#787878", fontWeight: "400" }}
      >
        {props.discription}
      </p>
    </div>
  );
};

export default HeadingWithBottomBar;
