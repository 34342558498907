import React from "react";
import DashBoardMainHading from "../../reuseablecomponents/DashBoardMainHading";
import DashboardTile from "../../reuseablecomponents/DashboardTile";
const DashboardHeaderAndTile = ({
  heading,
  options,
  tabHandler,
  value,
  icons,
}) => {
  return (
    <>
      <DashBoardMainHading title={heading} />
      <div className="container">
        <div className="row">
          {options?.map((item, index) => (
            <div className="col-lg-4 col-md-6 col-12 mt-2" key={index + 1}>
              <DashboardTile
                label={item}
                icon={icons[index]}
                active={item === value}
                onClick={tabHandler}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DashboardHeaderAndTile;
