import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
// const allMedicines = [
//   { label: "Brufen", value: "Brufen", key: 1 },
//   { label: "Panadol", value: "Panadol", key: 2 },
//   { label: "Solifen", value: "Solifen", key: 3 },
// ];
const AddMedicinesPrescriptionModal = (props) => {
  const { t } = useTranslation("common");
  const allMedicines = useSelector(
    (state) => state?.doctorReducer?.allMedicines
  );
  const [mediSelection, setMediSelection] = useState([]);
  const [mediError, setmediError] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },reset 
  } = useForm();
  const onSubmit = async (data) => {
    if (mediSelection.length === 0) {
      setmediError("This Is A Required Field");
    } else {
      if (
        Number(data?.morningDose) < 0 ||
        Number(data?.afternoonDosage) < 0 ||
        Number(data?.nightDosage) < 0 ||
        Number(data?.duration) < 0
      ) {
        toast.error("Please Enter Valid Quantity");
      } else {
        const medicineData = {
          id: mediSelection[0]?.id,
          dose: [
            Number(data?.morningDose),
            Number(data?.afternoonDosage),
            Number(data?.nightDosage),
          ],
          duration: Number(data?.duration),
          name: mediSelection[0]?.name,
        };
        props.setSelectedMedi([...props?.selectedMedi, medicineData]);
        setMediSelection([])
      reset();
        props.onHide();
      }
    }
  };
  // {t("dashboards.alldashboardkeys.tablekeys.doctors")}
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("dashboards.alldashboardkeys.tablekeys.addmedicine")}
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true">×</span>
            <span class="sr-only">
              {t("dashboards.alldashboardkeys.tablekeys.close")}
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-12">
                  <span className="label-name-login">
                    {t(
                      "dashboards.alldashboardkeys.tablekeys.selectedmedicine"
                    )}
                  </span>
                  <Form.Group>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      placeholder=""
                      selected={mediSelection}
                      onChange={(selected) => {
                        setMediSelection(selected);
                      }}
                      options={allMedicines}
                    />
                    {mediError ? (
                      <div className="text-error">{mediError}</div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <span className="label-name-login">
                    {t("dashboards.alldashboardkeys.tablekeys.morning")}
                  </span>
                  <Form.Group>
                    <input
                      className="input-login-modal"
                      type="number"
                      min={0}
                      max={9}
                      style={{
                        width: "100%",
                      }}
                      {...register("morningDose", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.morningDose?.message ? (
                      <div className="text-error">
                        {errors?.morningDose?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <span className="label-name-login">
                    {t("dashboards.alldashboardkeys.tablekeys.afternoon")}
                  </span>
                  <Form.Group>
                    <input
                      className="input-login-modal"
                      min={0}
                      type="number"
                      style={{
                        width: "100%",
                      }}
                      {...register("afternoonDosage", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.afternoonDosage?.message ? (
                      <div className="text-error">
                        {errors?.afternoonDosage?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <span className="label-name-login">
                    {t("dashboards.alldashboardkeys.tablekeys.night")}
                  </span>
                  <Form.Group>
                    <input
                      className="input-login-modal"
                      type="number"
                      min={0}
                      style={{
                        width: "100%",
                      }}
                      {...register("nightDosage", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.nightDosage?.message ? (
                      <div className="text-error">
                        {errors?.nightDosage?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <span className="label-name-login">
                    {t("dashboards.alldashboardkeys.tablekeys.durdose")}
                  </span>
                  <Form.Group>
                    <input
                      className="input-login-modal"
                      type="number"
                      min={0}
                      {...register("duration", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.duration?.message ? (
                      <div className="text-error">
                        {errors?.duration?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
              </div>
              <input
                type="submit"
                value={t("dashboards.alldashboardkeys.tablekeys.addmedicine")}
                className="btnSquare btn-block mx-auto"
                style={{ width: "85%", textAlign: "center" }}
              />
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddMedicinesPrescriptionModal;
