import { nurseConstants } from "../constants/nurseConstants";
import { signInConstansts } from "../constants/authConstants";

const initialState = {
  tests: [],
  nurseLoader : false
};
export const nurseReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case nurseConstants.GET_ALL_TESTS:
      return { ...state, tests: payload };
      case nurseConstants.NURSE_LOADER:
        return { ...state, nurseLoader: payload };
    case signInConstansts.LOG_OUT_USER:
      return initialState;
    default:
      return state;
  }
};
