import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BannerWithText from "../../../components/layouts/bannerImgComponents/BannerImgComponents";
import PageMenuCard from "../../../components/layouts/cards/PageMenuCard/PageMenuCard";
import TabHandlerTextwithImage from "../../../components/reuseablecomponents/TabHandlerTextwithImage";
import {
  Aboutcard1,
  Aboutcard2,
  Aboutcard3,
  banner,
  LinksBanMission,
  mission1,
  tabhandpic2,
  value1,
} from "../../../data";
import { filterComponentData } from "../../../utils/functions";

const Mission = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const mission = filterComponentData(component, "our_mission_heading", lang);
  const value = filterComponentData(component, "our_value_header", lang);
  const misionlist = filterComponentData(component, "our_mission_list", lang);
  const visioncard = filterComponentData(component, "our_vision_card", lang);
  const aboutcard = filterComponentData(component, "our_value_about_us", lang);
  const valuelist = filterComponentData(component, "our_value_list", lang);
  const banners = filterComponentData(component, "banner", lang);
  const AboutMetaTitles = filterComponentData(
    component,
    "about_meta_titles",
    lang
  );
  const aboutSchemaMarkups = filterComponentData(
    component,
    "about_schema_markups",
    lang
  );
  const AboutMetaDesc = filterComponentData(
    component,
    "about_meta_descriptions",
    lang
  );
  const AboutMetaKeywords = filterComponentData(
    component,
    "about_meta_keywords",
    lang
  );
  const AboutVerifications = filterComponentData(
    component,
    "about_verification_meta_tags",
    lang
  );
  const { t } = useTranslation("common");
  const LinksBanMission = [
    {
      subLinkName: banners[3]?.subtitle?.split(">")[0]
        ? banners[3]?.subtitle?.split(">")[0]
        : t("ourmisson.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[3]?.subtitle?.split(">")[1]
        ? banners[3]?.subtitle?.split(">")[1]
        : t("ourmisson.banner.title1"),
      subDash: ">",
      subLink: "/about",
    },
    {
      subLinkName: banners[3]?.subtitle?.split(">")[2]
        ? banners[3]?.subtitle?.split(">")[2]
        : t("ourmisson.banner.title"),
      subDash: "",
      subLink: "/our-mission",
    },
  ];
  const mission1 = [
    {
      tabicon: "",
      img: tabhandpic2,
      heading: t("ourmisson.textrev.headfirst"),
      description: t("ourmisson.textrev.discriptiontxt1"),
      description1: "",
      linkchecks: [
        {
          title: t("ourmisson.textrev.list1"),
        },
        {
          title: t("ourmisson.textrev.list2"),
          titlelink: "#",
        },
        {
          title: t("ourmisson.textrev.list3"),
          titlelink: "#",
        },
        {
          title: t("ourmisson.textrev.list4"),
          titlelink: "#",
        },
        {
          title: t("ourmisson.textrev.list5"),
          titlelink: "#",
        },
        {
          title: t("ourmisson.textrev.list6"),
          titlelink: "#",
        },
      ],
    },
  ];
  const value1 = [
    {
      tabicon: "",
      img: tabhandpic2,
      heading: t("ourmisson.textrev1.headfirst"),
      description: t("ourmisson.textrev1.discriptiontxt1"),
      description1: "",
      linkchecks: [
        {
          title: t("ourmisson.textrev1.list1"),
          titlelink: "#",
        },

        {
          title: t("ourmisson.textrev1.list2"),
          titlelink: "#",
        },
        {
          title: t("ourmisson.textrev1.list3"),
          titlelink: "#",
        },
        {
          title: t("ourmisson.textrev1.list4"),
          titlelink: "#",
        },
      ],
    },
  ];
  const verificationsTags = AboutVerifications?.map((item) =>
    React.createElement("meta", {
      name: item?.ourMissionName,
      content: item?.ourMissionContent,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}

        {aboutSchemaMarkups?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.ourMission ? item?.ourMission : "[]")
            )}
          </script>
        ))}
        <title>
          {AboutMetaTitles?.ourMission
            ? AboutMetaTitles?.ourMission
            : t("ourmisson.maintitle")}
        </title>
        <meta
          name="description"
          content={
            AboutMetaDesc?.ourMission
              ? AboutMetaDesc?.ourMission
              : t("ourmisson.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            AboutMetaKeywords?.ourMission
              ? AboutMetaKeywords?.ourMission
              : t("ourmisson.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[3]?.heading
              ? banners[3]?.heading
              : t("ourmisson.banner.title")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[3]?.image ? banners[3]?.image : banner
          })`}
          imgSrc={banners[3]?.image ? banners[3]?.image : banner}
          LinksBan={LinksBanMission}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        {mission1.map((item, index) => {
          return (
            <TabHandlerTextwithImage
              imgCol="col-lg-6"
              textCol="col-lg-6 mt-5"
              imgHtml={
                <>
                  <PageMenuCard
                    img={
                      visioncard[0]?.image ? visioncard[0]?.image : Aboutcard3
                    }
                    heading={
                      visioncard[0]?.heading
                        ? visioncard[0]?.heading
                        : t("ourmisson.textrev.pagecardhead1")
                    }
                    linkroute="#"
                    text={
                      visioncard[0]?.desc
                        ? visioncard[0]?.desc
                        : t("ourmisson.textrev.pagecardtxt1")
                    }
                  />
                  <PageMenuCard
                    img={
                      visioncard[1]?.image ? visioncard[1]?.image : Aboutcard2
                    }
                    heading={
                      visioncard[1]?.heading
                        ? visioncard[1]?.heading
                        : t("ourmisson.textrev.pagecardhead2")
                    }
                    linkroute="#"
                    text={
                      visioncard[1]?.desc
                        ? visioncard[1]?.desc
                        : t("ourmisson.textrev.pagecardtxt2")
                    }
                  />
                </>
              }
              hrlinecustome={
                <hr
                  style={{
                    borderBottom: "3px solid #AF5689",
                    margin: "10px 0px 15px 0px",
                    width: "80px",
                  }}
                ></hr>
              }
              headingFirst={mission?.heading ? mission?.heading : item.heading}
              discription={
                <div className="mb-3">
                  {mission?.desc ? mission?.desc : item.description}
                </div>
              }
              linkcustomli={
                <div class="span-department">
                  {misionlist?.length < 1 ? (
                    <ul>
                      {item?.linkchecks?.map((item) => {
                        return item?.titlelink ? (
                          <Link
                            to="#"
                            style={{ textDecoration: "none", color: "#333333" }}
                          >
                            <li>
                              <i class="fa fa-check"></i>
                              {item?.title}
                            </li>
                          </Link>
                        ) : (
                          <li>
                            <i class="fa fa-check"></i>
                            {item?.title}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <ul>
                      {misionlist?.map((item) => (
                        <Link
                          to="#"
                          style={{ textDecoration: "none", color: "#333333" }}
                        >
                          <li>
                            <i class="fa fa-check"></i>
                            {item?.text}
                          </li>
                        </Link>
                      ))}
                    </ul>
                  )}
                </div>
              }
              reverse={false}
            />
          );
        })}
        {value1.map((item, index) => {
          return (
            <TabHandlerTextwithImage
              imgCol="col-lg-6 "
              textCol="col-lg-6"
              imgHtml={
                <>
                  <PageMenuCard
                    img={aboutcard?.image ? aboutcard?.image : Aboutcard1}
                    heading={
                      aboutcard?.heading
                        ? aboutcard?.heading
                        : t("ourmisson.textrev1.pagecardhead1")
                    }
                    linkroute="#"
                    text={
                      aboutcard?.desc
                        ? aboutcard?.desc
                        : t("ourmisson.textrev.pagecardtxt1")
                    }
                  />
                </>
              }
              hrlinecustome={
                <hr
                  style={{
                    borderBottom: "3px solid #AF5689",
                    margin: "10px 0px 15px 0px",
                    width: "80px",
                  }}
                ></hr>
              }
              headingFirst={value?.heading ? value?.heading : item.heading}
              discription={
                <div className="mb-3">
                  {value?.desc ? value?.desc : item.description}
                </div>
              }
              linkcustomli={
                <div class="span-department">
                  {valuelist?.length < 1 ? (
                    <ul>
                      {item?.linkchecks?.map((item) => {
                        return item?.titlelink ? (
                          <Link
                            to="#"
                            style={{ textDecoration: "none", color: "#333333" }}
                          >
                            <li>
                              <i class="fa fa-check"></i>
                              {item?.title}
                            </li>
                          </Link>
                        ) : (
                          <li>
                            <i class="fa fa-check"></i>
                            {item?.title}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <ul>
                      {valuelist?.map((item) => (
                        <Link
                          to="#"
                          style={{ textDecoration: "none", color: "#333333" }}
                        >
                          <li>
                            <i class="fa fa-check"></i>
                            {item?.text}
                          </li>
                        </Link>
                      ))}
                    </ul>
                  )}
                </div>
              }
              reverse={false}
            />
          );
        })}
      </div>
    </>
  );
};

export default Mission;
