import React from "react";
import { Link } from "react-router-dom";

const TabHandlerTextwithImage = (props) => {
  const ImgDiv =
    props.imgSrc === "" ? (
      <div></div>
    ) : (
      <div className={props.imgCol ? props.imgCol : "col-lg-5"}>
        <img
          src={props.imgSrc}
          alt={props?.alt ? props?.alt : ""}
          style={props.imgStyle}
          className=""
        />
        {props.imgHtml}
      </div>
    );
  const textDiv = (
    <div className={props.textCol ? props.textCol : "col-lg-7 mt-3"}>
      <div className="heading-wraper">
        <h3
          style={{ textTransform: "capitalize", color: "#666666" }}
          className="gray-headin"
        >
          {props.headingFirst}
        </h3>
        {props.hrlinecustome}
      </div>
      {props?.toggle ? (
        <div
          className="text-wraper text-justify"
          dangerouslySetInnerHTML={{ __html: props?.discription }}
        />
      ) : (
        <>
          <div className="text-wraper text-justify">{props.discription}</div>
          <div className="text-wraper text-justify">{props.discription2}</div>
        </>
      )}

      <div>{props.linkcustomli}</div>
      {props.linkname ? (
        <Link
          to={props.linkroute}
          style={{ textDecoration: "none", fontWeight: "500" }}
        >
          {props.linkname}
        </Link>
      ) : (
        ""
      )}
    </div>
  );
  return (
    <div className="container mt-5">
      <div className="">{props.SelectedButtons}</div>
      <div className="row">
        {props.reverse ? (
          <>
            {ImgDiv}
            {textDiv}
          </>
        ) : (
          <>
            {textDiv}
            {ImgDiv}
          </>
        )}
      </div>
    </div>
  );
};

export default TabHandlerTextwithImage;
