import React, { useEffect, useState } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllDepartment,
    getDoctorByDepartment,
} from "../../../../store/actions/patientActions";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { disablePastDate } from "../../../../utils/functions";

const FollowUp = (props) => {
    const {t} = useTranslation("common")
    const dispatch = useDispatch();
    const loader = useSelector((state) => state?.logIn?.loader);
    const [date, setDate] = useState('')


   
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                    {t("dashboards.alldashboardkeys.tablekeys.addfollowup")}
                    </Modal.Title>
                    <button type="button" class="close" onClick={props?.onHide}>
                        <span aria-hidden="true">×</span>
                        <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12">

                    </div>
                    <div className="col-lg-12">
                        <span className="label-name-login" style={{ fontWeight: 'bold' }}>{t("dashboards.alldashboardkeys.tablekeys.selectdate")}</span>
                        <Form.Group>
                            <input
                                className="w-100 mb-2"
                                style={{ borderRadius: '5px' }}
                                id="basic-typeahead-single"
                                min={disablePastDate()}
                                type="date"
                                onChange={(e) => setDate(e.target.value)}
                            />
                        </Form.Group>


                    </div>
                    <div
                        className="btnSquare btn-block mx-auto"
                        style={{ width: "95%", textAlign: "center" }}
                        onClick={() => {
                            props.setselectedFollowup([...props?.selectedFollowup, date]);
                            props.onHide();
                        }}
                    >
                        {t("dashboards.alldashboardkeys.tablekeys.addfollowup")}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default FollowUp;
