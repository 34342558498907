import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Testemonials from "../../../../../components/layouts/testimonials/Testemonials";
import TabBtnList from "../../../../../components/layouts/tabbuttons/DashboardBtnList";
import TabHandlerTextwithImage from "../../../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  cttreatment,
  imghy1,
  dimenucard3,
  dimenucard31,
  LinksBanTreatmentCt,
  tabhandctc1,
  tabhandctc2,
  tabhandctc3,
  tabhandctc4,
} from "../../../../../data";
import HeadingWithBottomBar from "../../../../../components/reuseablecomponents/HeadingWithBottomBar";
import useMediaQuery from "../../../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Toggleform from "../../../../../components/layouts/toggleform/toggleform";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../../../utils/functions";

const Type2DiabetesMellitus = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const stories = filterComponentData(component, "our_stories", lang);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );

  const enquiry = filterComponentData(
    component,
    "cupping_therapy_ct_make_an_enquiry_",
    lang
  );
  const therapy = filterComponentData(
    component,
    "type_2_diabetes_mellitus",
    lang
  );
  const { t } = useTranslation("common");

  const LinksBanTreatmentCt = [
    {
      subLinkName: banners[45]?.subtitle?.split(">")[0]
        ? banners[45]?.subtitle?.split(">")[0]
        : t("dryeyedisease.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[45]?.subtitle?.split(">")[1]
        ? banners[45]?.subtitle?.split(">")[1]
        : t("dryeyedisease.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[45]?.subtitle?.split(">")[2]
        ? banners[45]?.subtitle?.split(">")[2]
        : t("dryeyedisease.banner.title1"),
      subDash: ">",
      subLink: "/diabetes",
    },
    {
      subLinkName: banners[45]?.subtitle?.split(">")[3]
        ? banners[45]?.subtitle?.split(">")[3]
        : "Type 2 Diabetes Mellitus",
      subDash: "",
      subLink: "/type2-diabetes-mellitus",
    },
  ];

  const isDesktoplg = useMediaQuery("(min-width: 992px)");

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(
                item?.diabetiesMellitus ? item?.diabetiesMellitus : "[]"
              )
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.diabetiesMellitus
            ? specialityMetaTitles?.diabetiesMellitus
            : "Type 2 Diabetes Mellitus"}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.diabetiesMellitus
              ? specialityMetaDesc?.diabetiesMellitus
              : "Type 2 Diabetes Mellitus"
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.diabetiesMellitus
              ? specialityMetaKeywords?.diabetiesMellitus
              : "Type 2 Diabetes Mellitus"
          }
        />
      </Helmet>
      <div className="">
        <BannerWithText
          heading={
            banners[45]?.heading
              ? banners[45]?.heading
              : "Type 2 Diabetes Mellitus"
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[45]?.image ? banners[45]?.image : banner
          })`}
          imgSrc={banners[45]?.image ? banners[45]?.image : banner}
          LinksBan={LinksBanTreatmentCt}
          height={isDesktoplg ? "300px" : "330px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          // imgSrc={therapy?.image ? therapy?.image : dimenucard3}
          imgCol="col-lg-5"
          alt={
            specialityAltTags?.diabetiesMellitus
              ? specialityAltTags?.diabetiesMellitus
              : ""
          }
          textCol="col-lg-7"
          headingFirst={
            therapy?.name ? therapy?.name : "What is Type 2 Diabetes Mellitus"
          }
          imgHtml={
            <>
              <img
                src={therapy?.image ? therapy?.image : dimenucard3}
                className="img-fluid"
              />

              <img
                src={therapy?.image1 ? therapy?.image1 : dimenucard31}
                className="mt-3 img-fluid"
              />
            </>
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.desc ? (
                  <div dangerouslySetInnerHTML={{ __html: therapy?.desc }} />
                ) : (
                  <>
                    <p class="text-justify">
                      Type 2 diabetes is a common condition that causes the
                      level of sugar (glucose) in the blood to become too high.
                      It can cause symptoms like excessive thirst, needing to
                      pee a lot and tiredness. It can also increase your risk of
                      getting serious problems with your eyes, heart and nerves.
                    </p>
                    <h2
                      style={{
                        textTransform: "capitalize",
                        color: "#333333",
                      }}
                      className="gray-headin"
                    >
                      How does stem cell therapy help for Type 2 Diabetes
                    </h2>

                    <hr
                      style={{
                        borderBottom: "3px solid #AF5689",
                        margin: "10px 0px 15px 0px",
                        width: "80px",
                      }}
                    ></hr>
                    <p class="text-justify">
                      For people suffering from Type 2 diabetes, stem cell
                      therapy can offer a respite from the symptoms of the
                      disease. Although stem cell therapy cannot cure it,
                      receiving stem cell therapy for diabetes Type 2 from
                      TotiCell Cell, has the potential to improve a patient’s
                      quality of life significantly by reducing symptoms and
                      complications related to Type 2 diabetes, as well as
                      slowing its progression.
                      <br />
                      For patients receiving stem cell diabetes treatment, it is
                      possible to see improvements in any one or multiple
                      disease-related complications such as stabilization of
                      blood sugar levels, lower blood sugar levels, frequent
                      urination, fatigue, poor wound healing, etc
                    </p>
                  </>
                )}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default Type2DiabetesMellitus;
