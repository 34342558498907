import React from "react";
import { Link } from "react-router-dom";
import "./BlogCard.css";
const BlogCard = (props) => {
  return (
    <div class="col-lg-4 col-md-6 mb-4">
      <div class="blog-bottom-post" onClick={() => props?.onPressCard()}>
        <div class="blog-post-thumbnail">
          <img
            src={props.img}
            alt={props.alt ? props.alt : props.cardHeading}
            // onClick={() => props?.history?.push("/blog-details", props?.value)}
          />
        </div>
        <div class="blog-caption mb-5">
          <h6 style={{ color: "#333", fontSize: "18px" }}>{props.heading}</h6>
          <p className="text-justify truncate-text-6">{props.description}</p>
          {/* <div class="rightside">
            <div class="span-fa">
              <p>
                <i class="fa fa-heart" aria-hidden="true">
                  &nbsp; {props.likes}
                </i>
              </p>
            </div>
            <div class="span-fa">
              <p>
                <i class="fa fa-comment" aria-hidden="true">
                  &nbsp; {props.msg}
                </i>
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
