import React, { useEffect } from "react";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  asmenucard4,
  banner,
  LinksBanSpecialityAstheticFacial,
  asthetic_facial_treatment,
  asfamenucard4,
  asfamenucard5,
  asfamenucard2,
  asfamenucard1,
} from "../../../../../data";

import { useState } from "react";
import TabHandlerTextwithImage from "../../../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TabBtnList from "../../../../../components/layouts/tabbuttons/DashboardBtnList";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../../../utils/functions";

const FacialRejuvenation = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const [buttonState, setButtonState] = useState([]);
  const [offerData, setOfferData] = useState([]);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );

  const offer = filterComponentData(
    component,
    "speciality_asthetic_medicine_facial_rejuvenation_offer_tab_",
    lang
  );
  const therapy = filterComponentData(
    component,
    "speciality_asthetic_medicine_facial_rejuvenation",
    lang
  );
  const tabs = filterComponentData(
    component,
    "speciality_asthetic_medicine_facial_rejuvenation_tab",
    lang
  );
  const tablits = filterComponentData(
    component,
    "speciality_asthetic_medicine_facial_rejuvenation_tab_list",
    lang
  );
  const { t } = useTranslation("common");
  const video = filterComponentData(component, "all_video_links", lang);
  const videolink = video.filter(
    (item) => item?.tabname == "facialrejuvenation"
  );
  const LinksBanSpecialityAstheticFacial = [
    {
      subLinkName: banners[31]?.subtitle?.split(">")[0]
        ? banners[31]?.subtitle?.split(">")[0]
        : t("facialrejuvenation.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[31]?.subtitle?.split(">")[1]
        ? banners[31]?.subtitle?.split(">")[1]
        : t("facialrejuvenation.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[31]?.subtitle?.split(">")[2]
        ? banners[31]?.subtitle?.split(">")[2]
        : t("facialrejuvenation.banner.title1"),
      subDash: ">",
      subLink: "/asthetic-medicine",
    },
    {
      subLinkName: banners[31]?.subtitle?.split(">")[3]
        ? banners[31]?.subtitle?.split(">")[3]
        : t("facialrejuvenation.banner.title2"),
      subDash: "",
      subLink: "/facial-rejuvenation",
    },
  ];

  const asthetic_facial_treatment = [
    {
      sign_sym: {
        tabicon: "",
        img: asfamenucard1,
        heading: t(
          "facialrejuvenation.ourspecialitytabs.btndetails1.headfirst"
        ),
        description: "",
        description1: (
          <p style={{ marginBottom: "6px", fontSize: "15px" }}>
            {t(
              "facialrejuvenation.ourspecialitytabs.btndetails1.discriptiontxt1"
            )}
          </p>
        ),
        linkchecks: [
          {
            title: t("facialrejuvenation.ourspecialitytabs.btndetails1.list1"),
          },
          {
            title: t("facialrejuvenation.ourspecialitytabs.btndetails1.list2"),
          },
          {
            title: t("facialrejuvenation.ourspecialitytabs.btndetails1.list3"),
          },
          {
            title: t("facialrejuvenation.ourspecialitytabs.btndetails1.list4"),
          },
          {
            title: t("facialrejuvenation.ourspecialitytabs.btndetails1.list5"),
          },
        ],
      },
      cause_risk: {
        tabicon: "",
        img: asfamenucard2,
        heading: t(
          "facialrejuvenation.ourspecialitytabs.btndetails2.headfirst"
        ),
        description: "",
        description1: (
          <p style={{ marginBottom: "6px", fontSize: "15px" }}>
            {t(
              "facialrejuvenation.ourspecialitytabs.btndetails2.discriptiontxt1"
            )}
          </p>
        ),
        linkchecks: [
          {
            title: t("facialrejuvenation.ourspecialitytabs.btndetails2.list1"),
          },
          {
            title: t("facialrejuvenation.ourspecialitytabs.btndetails2.list2"),
          },
          {
            title: t("facialrejuvenation.ourspecialitytabs.btndetails2.list3"),
          },
          {
            title: t("facialrejuvenation.ourspecialitytabs.btndetails2.list4"),
          },
          {
            title: t("facialrejuvenation.ourspecialitytabs.btndetails2.list5"),
          },
        ],
        linkname: t(
          "facialrejuvenation.ourspecialitytabs.btndetails2.linkname"
        ),
        linkroute: "/signup",
      },
      what_we_offer: {
        tabicon: "",
        img: "",
        heading: t(
          "facialrejuvenation.ourspecialitytabs.btndetails3.headfirst"
        ),
        description: "",
        description1: (
          <>
            <p style={{ marginBottom: "6px", fontSize: "15px" }}>
              {t(
                "facialrejuvenation.ourspecialitytabs.btndetails3.discriptiontxt1"
              )}
            </p>
            <h3
              style={{ textTransform: "capitalize", color: "#666666" }}
              className="gray-headin"
            >
              {t(
                "facialrejuvenation.ourspecialitytabs.btndetails3.discriptiontxt2"
              )}
            </h3>
            <hr
              style={{
                borderBottom: "3px solid #AF5689",
                margin: "10px 0px 15px 0px",
                width: "80px",
              }}
            ></hr>
            <p style={{ marginBottom: "6px", fontSize: "15px" }}>
              {t(
                "facialrejuvenation.ourspecialitytabs.btndetails3.discriptiontxt3"
              )}
              <br />
              <br />
              {t(
                "facialrejuvenation.ourspecialitytabs.btndetails3.discriptiontxt4"
              )}
              <br />
            </p>
            <div class="span-department mb-5">
              <ul>
                <li>
                  <i class="fa fa-check"></i>
                  {t("facialrejuvenation.ourspecialitytabs.btndetails3.list1")}
                </li>
                <li>
                  <i class="fa fa-check"></i>
                  {t("facialrejuvenation.ourspecialitytabs.btndetails3.list2")}
                </li>
                <li>
                  <i class="fa fa-check"></i>
                  {t("facialrejuvenation.ourspecialitytabs.btndetails3.list3")}
                </li>
              </ul>
            </div>

            <h3
              style={{ textTransform: "capitalize", color: "#666666" }}
              className="gray-headin"
            >
              {t(
                "facialrejuvenation.ourspecialitytabs.btndetails3.discriptiontxt5"
              )}
            </h3>
            <hr
              style={{
                borderBottom: "3px solid #AF5689",
                margin: "10px 0px 15px 0px",
                width: "80px",
              }}
            ></hr>
            <p style={{ marginBottom: "6px", fontSize: "15px" }}>
              {t(
                "facialrejuvenation.ourspecialitytabs.btndetails3.discriptiontxt6"
              )}
              <br />
              <br />
              {t(
                "facialrejuvenation.ourspecialitytabs.btndetails3.discriptiontxt7"
              )}
            </p>

            <h3
              style={{ textTransform: "capitalize", color: "#666666" }}
              className="gray-headin"
            >
              {t(
                "facialrejuvenation.ourspecialitytabs.btndetails3.discriptiontxt8"
              )}
            </h3>
            <hr
              style={{
                borderBottom: "3px solid #AF5689",
                margin: "10px 0px 15px 0px",
                width: "80px",
              }}
            ></hr>
            <p style={{ marginBottom: "6px", fontSize: "15px" }}>
              {t(
                "facialrejuvenation.ourspecialitytabs.btndetails3.discriptiontxt9"
              )}
              <span className="font-weight-bold">
                {t(
                  "facialrejuvenation.ourspecialitytabs.btndetails3.discriptiontxt10"
                )}
              </span>
              {t(
                "facialrejuvenation.ourspecialitytabs.btndetails3.discriptiontxt11"
              )}
              <span className="font-weight-bold">
                {t(
                  "facialrejuvenation.ourspecialitytabs.btndetails3.discriptiontxt12"
                )}
              </span>
              {t(
                "facialrejuvenation.ourspecialitytabs.btndetails3.discriptiontxt13"
              )}
              <span className="font-weight-bold">
                {t(
                  "facialrejuvenation.ourspecialitytabs.btndetails3.discriptiontxt14"
                )}
              </span>
              .
              <br />
            </p>
          </>
        ),
        linkname: t(
          "facialrejuvenation.ourspecialitytabs.btndetails3.linkname"
        ),
        linkroute: "/signup",
        linkchecks: [],
      },
      benefit_stem_cell: {
        tabicon: "",
        img: asfamenucard4,
        heading: t(
          "facialrejuvenation.ourspecialitytabs.btndetails4.headfirst"
        ),
        description: (
          <p style={{ marginBottom: "6px", fontSize: "15px" }}>
            {t(
              "facialrejuvenation.ourspecialitytabs.btndetails4.discriptiontxt1"
            )}
          </p>
        ),
        description1: (
          <img
            src={asfamenucard5}
            alt={t("facialrejuvenation.ourspecialitytabs.btn4")}
            style={{ width: "80%", marginBottom: "15px" }}
          />
        ),
        linkname: t(
          "facialrejuvenation.ourspecialitytabs.btndetails4.linkname"
        ),
        linkroute: "/signup",
        linkchecks: [],
      },
    },
  ];

  const [selectedTab1, setSelectedTab1] = useState(
    t("facialrejuvenation.ourspecialitytabs.btn1")
  );
  const [selecteddata, setSelecteddata] = useState(
    tabs?.length < 1 ? asthetic_facial_treatment[0]?.sign_sym : tabs[0]
  );
  const populateButtonArray = () => {
    let test = [];
    tabs?.map((item) => {
      test?.push(item?.heading);
    });
    test?.push("What We Offer");
    setButtonState(test);
  };
  const tabHandlerTest = (item) => {
    if (item == "What We Offer") {
      setOfferData(offer);
      setSelectedTab1(item);
    } else {
      setSelectedTab1(item);
      tabs?.filter((it) =>
        it?.heading?.toLowerCase() == item?.toLowerCase()
          ? setSelecteddata(it)
          : null
      );
    }
  };
  useEffect(() => {
    populateButtonArray();
    setSelecteddata(
      tabs?.length < 1 ? asthetic_facial_treatment[0]?.sign_sym : tabs[0]
    );
  }, [lang, tabs?.length]);
  const tabledataHandler = async (item) => {
    setSelecteddata(item);
  };
  const buttonname = [
    t("facialrejuvenation.ourspecialitytabs.btn1"),
    t("facialrejuvenation.ourspecialitytabs.btn2"),
    t("facialrejuvenation.ourspecialitytabs.btn3"),
    t("facialrejuvenation.ourspecialitytabs.btn4"),
  ];
  const tabHandler = (item) => {
    setSelectedTab1(item);
    if (item === t("facialrejuvenation.ourspecialitytabs.btn1")) {
      tabledataHandler(asthetic_facial_treatment[0].sign_sym);
    } else if (item === t("facialrejuvenation.ourspecialitytabs.btn2")) {
      tabledataHandler(asthetic_facial_treatment[0].cause_risk);
    } else if (item === t("facialrejuvenation.ourspecialitytabs.btn3")) {
      tabledataHandler(asthetic_facial_treatment[0].what_we_offer);
    } else if (item === t("facialrejuvenation.ourspecialitytabs.btn4")) {
      tabledataHandler(asthetic_facial_treatment[0].benefit_stem_cell);
    }
  };
  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(
                item?.aestheticFacial ? item?.aestheticFacial : "[]"
              )
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.aestheticFacial
            ? specialityMetaTitles?.aestheticFacial
            : t("facialrejuvenation.maintitle")}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.aestheticFacial
              ? specialityMetaDesc?.aestheticFacial
              : t("facialrejuvenation.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.aestheticFacial
              ? specialityMetaKeywords?.aestheticFacial
              : t("facialrejuvenation.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[31]?.heading
              ? banners[31]?.heading
              : t("facialrejuvenation.banner.title2")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[31]?.image ? banners[31]?.image : banner
          })`}
          imgSrc={banners[31]?.image ? banners[31]?.image : banner}
          LinksBan={LinksBanSpecialityAstheticFacial}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={therapy?.image ? therapy?.image : asmenucard4}
          alt={
            specialityAltTags?.aestheticFacial
              ? specialityAltTags?.aestheticFacial
              : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={
            therapy?.heading
              ? therapy?.heading
              : t("facialrejuvenation.textrev.headfirst")
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.description
                  ? therapy?.description
                  : t("facialrejuvenation.textrev.discriptiontxt1")}
              </p>
            </>
          }
          reverse={false}
        />

        <TextAndImageSection
          textCol="col-lg-12"
          hrTrue={true}
          discription={
            <>
              <iframe
                width="100%"
                height="450"
                src={
                  videolink[0]?.videolink
                    ? videolink[0]?.videolink
                    : "https://drive.google.com/file/d/1CG4ZfzsE-eFwktAeK38wZkJ_Echuw2yP/preview"
                }
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </>
          }
          reverse={false}
        />

        {tablits?.length < 1 ? (
          <div className="mb-5">
            <TabHandlerTextwithImage
              SelectedButtons={
                <div className="my-4">
                  <div className="row">
                    {buttonname.map((item, index) => (
                      <div
                        className="col-lg-3 col-md-6 px-2 mb-3 d-flex d-inline-flex"
                        key={index + 1}
                        onClick={() => tabHandler(item)}
                      >
                        <TabBtnList
                          label={
                            item ===
                            t("facialrejuvenation.ourspecialitytabs.btn1") ? (
                              <span>
                                {t("facialrejuvenation.ourspecialitytabs.btn1")}
                              </span>
                            ) : item ===
                              t("facialrejuvenation.ourspecialitytabs.btn2") ? (
                              <span>
                                {t("facialrejuvenation.ourspecialitytabs.btn2")}
                              </span>
                            ) : item ===
                              t("facialrejuvenation.ourspecialitytabs.btn3") ? (
                              <span>
                                {t("facialrejuvenation.ourspecialitytabs.btn3")}
                              </span>
                            ) : item ===
                              t("facialrejuvenation.ourspecialitytabs.btn4") ? (
                              <span>
                                {t("facialrejuvenation.ourspecialitytabs.btn4")}
                              </span>
                            ) : (
                              ""
                            )
                          }
                          className={
                            selectedTab1 === item
                              ? "dashboardBtnList-item-active"
                              : "default-color-and-hover "
                          }
                          bntStyle={{ fontSize: "15px", borderRadius: "5px" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              }
              imgSrc={selecteddata?.img}
              textCol={
                selectedTab1 === t("facialrejuvenation.ourspecialitytabs.btn3")
                  ? "col-lg-12"
                  : "col-lg-7 mt-3"
              }
              imgStyle={{
                borderRadius: "4px",
                width: "100%",
                height: "340px",
                objectFit: "fill",
              }}
              hrlinecustome={
                <hr
                  style={{
                    borderBottom: "3px solid #AF5689",
                    margin: "10px 0px 15px 0px",
                    width: "80px",
                  }}
                ></hr>
              }
              headingFirst={selecteddata.heading}
              discription={<>{selecteddata?.description}</>}
              discription2={<>{selecteddata?.description1}</>}
              linkcustomli={
                <>
                  <div class="span-department mb-5">
                    <ul>
                      {selecteddata?.linkchecks?.map((item) => {
                        return item?.titlelink ? (
                          <Link
                            to="#"
                            style={{ textDecoration: "none", color: "#333333" }}
                          >
                            <li>
                              <i class="fa fa-check"></i>
                              {item?.title}
                            </li>
                          </Link>
                        ) : (
                          <li>
                            <i class="fa fa-check"></i>
                            {item?.title}
                          </li>
                        );
                      })}
                    </ul>

                    <Link
                      to={selecteddata?.linkroute}
                      className="tabhandlerhoverlink"
                    >
                      {selecteddata?.linkname}
                    </Link>
                  </div>
                </>
              }
              reverse={false}
            />
          </div>
        ) : selectedTab1 != "What We Offer" ? (
          <div className="mb-5">
            <TabHandlerTextwithImage
              SelectedButtons={[selecteddata]?.map((item_) => (
                <div className="my-4">
                  <div className="row">
                    {buttonState?.map((item, index) => (
                      <div
                        className="col-lg-3 col-md-6 px-2 mb-3 d-flex d-inline-flex"
                        key={index + 1}
                        onClick={() => tabHandlerTest(item)}
                      >
                        <TabBtnList
                          label={
                            lang == "bn-BD" && item == "What We Offer"
                              ? "আমাদের সেবা-সমূহঃ"
                              : item
                          }
                          className={
                            selectedTab1 === item
                              ? "dashboardBtnList-item-active"
                              : "default-color-and-hover"
                          }
                          bntStyle={{ fontSize: "15px", borderRadius: "5px" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              imgSrc={selecteddata?.image}
              imgStyle={{
                borderRadius: "4px",
                width: "100%",
                height: "340px",
                objectFit: "fill",
              }}
              hrlinecustome={
                <hr
                  style={{
                    borderBottom: "3px solid #AF5689",
                    margin: "10px 0px 15px 0px",
                    width: "80px",
                  }}
                ></hr>
              }
              headingFirst={selecteddata?.heading}
              discription={selecteddata?.desc}
              toggle={true}
              // discription2={<>{selecteddata?.description1}</>}
              linkcustomli={
                <div class="span-department mb-5">
                  <ul>
                    {tablits?.map((item) => {
                      return selecteddata?.heading?.toLowerCase() ==
                        item?.tab?.toLowerCase() ? (
                        <Link
                          to="#"
                          style={{ textDecoration: "none", color: "#333333" }}
                        >
                          <li>
                            <i class="fa fa-check"></i>
                            {item?.iten}
                          </li>
                        </Link>
                      ) : null;
                    })}
                  </ul>
                  <span
                    dangerouslySetInnerHTML={{ __html: selecteddata?.link }}
                  />
                </div>
              }
              reverse={false}
            />
          </div>
        ) : (
          <div className="mb-5">
            <TabHandlerTextwithImage
              SelectedButtons={[selecteddata]?.map((item_) => (
                <div className="my-4">
                  <div className="row">
                    {buttonState?.map((item, index) => (
                      <div
                        className="col-lg-3 col-md-6 px-2 mb-3 d-flex d-inline-flex"
                        key={index + 1}
                        onClick={() => tabHandlerTest(item)}
                      >
                        <TabBtnList
                          label={
                            lang == "bn-BD" && item == "What We Offer"
                              ? "আমাদের সেবা-সমূহঃ"
                              : item
                          }
                          className={
                            selectedTab1 === item
                              ? "dashboardBtnList-item-active"
                              : "default-color-and-hover"
                          }
                          bntStyle={{ fontSize: "15px", borderRadius: "5px" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              reverse={false}
            />

            {offerData?.map((item) => (
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h3
                      className="gray-headin"
                      style={{
                        textTransform: "capitalize",
                        color: "rgb(102, 102, 102)",
                      }}
                    >
                      {item?.heading}
                    </h3>
                    <hr
                      style={{
                        borderBottom: "3px solid rgb(175, 86, 137)",
                        margin: "10px 0px 15px",
                        width: "80px",
                      }}
                    ></hr>
                    <p
                      style={{ marginBottom: "6px", fontSize: "15px" }}
                      dangerouslySetInnerHTML={{ __html: item?.desc }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default FacialRejuvenation;
