import React from "react";
import './DashboardBtnList.css'

const DashboardBtnList = ({
  label,
  className,
  bntStyle,
  onClick,
  icon,
  carddoc
}) => {
  return (
    <>
      {carddoc ?
        <>
          <div
            className={`${className} p-3 btn `}
            style={bntStyle}
            onClick={onClick}
          >
            {label}
          </div>
        </>
        :
        <>
          <div
            className={`dashboardBtnList-item ${className} p-3 btn `}
            style={bntStyle}
            onClick={onClick}
          >


            {icon ?
              (<div className="row">
                <div className="col-4 text-right">
                  <span class="facility_ico ">
                    <img
                      src={icon}
                      style={{ width: "100%" }}
                      alt="tab icon"
                    />

                  </span>
                </div>
                <div className="col-8 text-left">
                  {label}
                </div>
              </div>) : (
                <div className="text-center" >
                  {label}
                </div>
              )
            }


          </div>
        </>
      }
    </>


  );
};

export default DashboardBtnList;
