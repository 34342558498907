import { React, useState } from "react";
import DashboardHeaderAndTile from "../../components/layouts/dashboardheaderandtile/DashboardHeaderAndTile";
import patient from "../../statics/icons/patient.svg";
import doctor from "../../statics/icons/first-aid-kit.svg";
import reschedule from "../../statics/icons/rescheduling.svg";
import target from "../../statics/icons/medical.png";
import cart from "../../statics/icons/cart.svg";

import appointment from "../../statics/icons/date.png";

import AdministratorDoctorContainer from "./AdministratorDoctorContainer";
import AdministratorPatientContainer from "./AdministratorPatientContainer";
import AdministratorRescheduleContainer from "./AdministratorRescheduleContainer";
import { Helmet } from "react-helmet";
import AssignTarget from "./AssignTarget";
import { useTranslation } from "react-i18next";
import PatientOrders from "./Order";
import AdministratorDashboardAppointmentContainer from "./AdministratorDashboardAppointmentContainer";
import req from '../../statics/icons/payment.png'
import ReturnRequest from "./ReturnRequest";
const AdministratorDashboard = (props) => {
  const { t } = useTranslation("common")
  const [selectedtile, setselectedtile] = useState(t("dashboards.alldashboardkeys.tablekeys.doctors"));
  const tabHandler = (item) => {
    setselectedtile(item);
  };
  return (
    <>
      <Helmet>
        <title>{t("dashboards.alldashboardkeys.maintitle")}</title>
      </Helmet>
      <div>
        <DashboardHeaderAndTile
          heading={t("dashboards.alldashboardkeys.adminmainheading1")}
          options={[
            t("dashboards.alldashboardkeys.tablekeys.doctors")
            ,
            t("dashboards.alldashboardkeys.tablekeys.patient")
            ,
            t("dashboards.alldashboardkeys.tablekeys.reschedule")
            ,
            t("dashboards.alldashboardkeys.tablekeys.targettab"),
            "Request"
            , t("dashboards.alldashboardkeys.tablekeys.orders")
            , "Appointment"


          ]}
          icons={[doctor, patient, reschedule, target, req, cart, appointment]}
          tabHandler={tabHandler}
          value={selectedtile}
        />
        <div className="container mt-5">
          {selectedtile === t("dashboards.alldashboardkeys.tablekeys.doctors") ? (
            <AdministratorDoctorContainer {...props} />
          ) : selectedtile === t("dashboards.alldashboardkeys.tablekeys.patient") ? (
            <AdministratorPatientContainer {...props} />
          ) : selectedtile === t("dashboards.alldashboardkeys.tablekeys.reschedule") ? (
            <AdministratorRescheduleContainer {...props} />
          ) : selectedtile === t("dashboards.alldashboardkeys.tablekeys.targettab") ?
            <AssignTarget {...props} /> :
            selectedtile === t("dashboards.alldashboardkeys.tablekeys.orders") ?
              <PatientOrders {...props} /> : selectedtile === "Request" ? <ReturnRequest {...props} /> : selectedtile == "Appointment" ? <AdministratorDashboardAppointmentContainer {...props} /> : null}
        </div>
      </div>
    </>
  );
};

export default AdministratorDashboard;
