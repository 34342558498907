import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { seeAllNotification } from "../../../../store/actions/staticActions";
import Loader from "react-loader-spinner";
import NotificationTab from "../../../reuseablecomponents/NotificationTab";
import DashboardBtnListNot from "../../DashboardBtnList/DashboardBtnList";
import { useTranslation } from "react-i18next";

const NotificationModal = (props) => {
  const {t} = useTranslation("common")
  const [selectedTab, setSelectedTab] = useState(t("dashboards.alldashboardkeys.tablekeys.allnotifications"));
  const loading = useSelector((state) => state?.logIn?.loader);

  const allnotification = useSelector(
    (state) => state?.static?.allnotification
  );
  const notificationcount = useSelector(
    (state) => state?.static?.notificationcount
  );
  const newnotification = useSelector(
    (state) => state?.static?.newnotification
  );
  const dispatch = useDispatch();
  useEffect(() => {
    setSelectedTab(t("dashboards.alldashboardkeys.tablekeys.allnotifications"));
  }, []);

  const tabHandler = async (item) => {
    setSelectedTab(item);
    if(item === t("dashboards.alldashboardkeys.tablekeys.recentnotification")){
      const apiData = newnotification?.map(item => {
        return item?.id
      })
      dispatch(seeAllNotification({notification_ids : apiData}))
    }
  };
  // {t("dashboards.alldashboardkeys.tablekeys.recentnotification")}
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          props?.onHide();
          setSelectedTab(t("dashboards.alldashboardkeys.tablekeys.allnotifications"));
          // setnotificationData(notification);
        }}
      >
        <Modal.Header  style={{backgroundColor:'#AF5689' ,color:'white'}}>
          
          <Modal.Title id="contained-modal-title-vcenter"style={{color:'white'}} >
          {t("dashboards.alldashboardkeys.tablekeys.notifications")}
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true">×</span>
            <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>
          </button>
        </Modal.Header>
        <Modal.Body
          style={{ height: "50vh", overflowY: "scroll", background: "#f9f9f9" }}
        >
          <div className="row">
            <div className="col-12">
              {[t("dashboards.alldashboardkeys.tablekeys.recentnotification"),t("dashboards.alldashboardkeys.tablekeys.allnotifications"),].map((item, index) => (
                <div
                  className="d-flex d-inline-flex col-6 p-1"
                  key={index + 1}
                  onClick={() => tabHandler(item)}
                >
                  <DashboardBtnListNot
                    label={item}
                    labelStyle={selectedTab === item ? { color: "#fff" } : ""}
                    className={
                      selectedTab === item
                        ? "dashboardBtnList-item-active py-2"
                        : "default-color-and-hover py-2"
                    }
                    shownotification={item === t("dashboards.alldashboardkeys.tablekeys.recentnotification")}
                    notificationCount={notificationcount}
                  />
                </div>
              ))}
            </div>
          </div>
          <div>
            {loading ? (
              <div className="mt-5 center-childrens">
                <Loader height={100} width={100} type="Puff" color="#1a4774" />
              </div>
            ) : (
              <div>
                {allnotification?.length > 0 &&
                selectedTab === t("dashboards.alldashboardkeys.tablekeys.allnotifications") ? (
                  allnotification?.map((item, index) => (
                    <>
                      {item === undefined ? null : (
                        <NotificationTab
                          title={item?.title}
                          decription={item?.message_body}
                          key={index + 1}
                        />
                      )}
                    </>
                  ))
                ) : newnotification?.length > 0 &&
                  selectedTab === t("dashboards.alldashboardkeys.tablekeys.recentnotification") ? (
                  newnotification?.map((item, index) => (
                    <>
                      {item === undefined ? null : (
                        <NotificationTab
                          title={item?.title}
                          decription={item?.message_body}
                          key={index + 1}
                        />
                      )}
                    </>
                  ))
                ) : (
                  <div
                    className="text-center mt-5 pt-5"
                    style={{ height: "100%" }}
                  >
                    <span
                      className=""
                      style={{ fontSize: "20px",fontWeight:'600' ,color:'#AF5689'}}
                    >
                       {t("dashboards.alldashboardkeys.tablekeys.nonotificationstoshow")}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NotificationModal;
