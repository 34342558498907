import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import DashboardBtn from "../../components/reuseablecomponents/DashboardBtn";
import DashboardBtnList from "../../components/reuseablecomponents/DashboardBtnList";
import Loader from "../../components/reuseablecomponents/LoderContainer";
import { myDoctorBoard } from "../../store/actions/docotorActions";
import moment from "moment";
const MyBoard = (props) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state?.logIn?.authLoader);
  const [selectedTab, setselectedTab] = useState("Past Boards");

  const user = useSelector((state) => state?.logIn?.user);
  const myBoards = useSelector((state) => state?.doctorReducer?.myBoards);
  const [dataTable, setdataTable] = useState(myBoards?.past);
  const [selectedBoards, setSelectedBoards] = useState("Past Boards");
  useEffect(() => {
    setdataTable(myBoards?.past);
    setSelectedBoards("Past Boards");

    const apiData = {
      id: user?.id,
    };
    dispatch(myDoctorBoard(apiData));

    // eslint-disable-next-line
  }, []);
  const tableDataHandler = (item) => {
    setSelectedBoards(item)
    if (item === "Past Boards") {
      setdataTable(myBoards?.past);
    } else if (item === "New Boards") {
      setdataTable(myBoards?.today);
    }
  };
  useEffect(() => {
    setdataTable(myBoards?.past);

  }, [myBoards?.length])
  return (
    <div className="container">
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">My Board</h4>
        </div>
      </div>
      <div className="row mb-3 ">
        {[
          "Past Boards",
          "New Boards"
        ].map((item, index) => (
          <div
            className="col-lg-3 col-md-4 col-12 mt-2"
            key={index + 1}
            onClick={() => tableDataHandler(item)}
          >
            <DashboardBtn
              label={item}
              labelStyle={selectedBoards === item ? { color: "#fff" } : ""}
              active={selectedBoards === item}
              className={
                selectedBoards === item
                  ? "dashboardBtnList-item-active  py-2"
                  : "default-color-and-hover  py-2"
              }
              onClick={() => tableDataHandler(item)}
            />
          </div>
        ))}
      </div>
      <div className="row">
        {loader ? (
          <Loader />
        ) : (
          <BootstrapCustomTable
            columns={[
              { dataField: "board_id", text: "Board ID" },
              { dataField: "created_by.name", text: "Created By" },
              {
                formatter: (cell, row) => {
                  return <span>{moment.unix(row.created_at).format("DD/MM/YYYY")}</span>;
                },
                text: "Created At",
              },

              {
                formatter: (cell, row) => {
                  return row?.doctors?.map((item) => {
                    return <li>{item?.name}</li>;
                  });
                },
                text: "Doctors",
              },
              {
                formatter: (cell, row) => {
                  return (
                    <>
                      <button
                        className="btn btnSquare"
                        onClick={() => {
                          const item = { ...row, from: selectedBoards };
                          props?.history?.push("/boards-details", item);
                        }}
                      >View Details</button>
                    </>
                  );
                },
                text: "Action",
              },
            ]}
            tabledata={dataTable?.length > 0 ? dataTable : []}
          />
        )}
      </div>
    </div>
  );
};

export default MyBoard;
