import React,{useState} from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { removerDoctotByAdmin } from "../../../../store/actions/administratorActions";
import DashboardBtnList from "../../../reuseablecomponents/DashboardBtnList";

const RemoveDoctorAdministratorModal = (props) => {
  const [reason, setReason] = useState(null);

  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const removerDoctorHandler = () => {
    const apiData = {
      id: props.selecteddoctor?.id,
      description: reason,
    };
    dispatch(removerDoctotByAdmin(apiData));
    props?.onHide();
  };
  // {t("dashboards.alldashboardkeys.tablekeys.resendphoneverificationotp")}
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="dashboardBtnList-item-active">
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="text-white">
            {t("dashboards.alldashboardkeys.tablekeys.confirmation")}
          </span>
        </Modal.Title>
        <button type="button" class="close" onClick={props?.onHide}>
          <span aria-hidden="true" className="text-white">
            ×
          </span>
          <span class="sr-only">
            {t("dashboards.alldashboardkeys.tablekeys.close")}
          </span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <p>{t("dashboards.alldashboardkeys.tablekeys.areyousureyouwantto")}</p>

        <textarea
          className="w-100 p-2"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <div className="row">
          <div className="col-6">
            <DashboardBtnList
              label={t("dashboards.alldashboardkeys.tablekeys.yes")}
              className="btn btn-success"
              onClick={removerDoctorHandler}
            />
          </div>
          <div className="col-6">
            <DashboardBtnList
              label={t("dashboards.alldashboardkeys.tablekeys.no")}
              className="btn btn-danger"
              onClick={props.onHide}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveDoctorAdministratorModal;
