import { signInConstansts } from "../constants/authConstants";
const initialState = {
  showSignUpModal: false,
  user: null,
  error: false,
  emailModal: false,
  phoneModal: false,
  appointModal: false,
  userRole: "",
  authLoader: false,
  userResponse: {},
  addPhoneModal: false,
  userPhoneNum: ''
};
export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case signInConstansts.SET_LOADER:
      return { ...state, authLoader: payload };
    case signInConstansts.EMAIL_MODAL:
      return { ...state, emailModal: payload };
    case signInConstansts.ADD_PHONE_MODAL:
      return { ...state, addPhoneModal: payload };
    case signInConstansts.USER_TYPE:
      return { ...state, userRole: payload };
    case signInConstansts.SET_USER_ROLE_FROM_LOCAL:
      return { ...state, userRole: payload };
    case signInConstansts.PHONE_MODAL:
      return { ...state, phoneModal: payload };
    case signInConstansts.SIGN_IN:
      return { ...state, user: payload };
    case signInConstansts.GET_USER_FROM_LOCAL:
      return { ...state, user: payload };
    case signInConstansts.EMAIL_VERIFY:
      return { ...state, user: payload };
    case signInConstansts.Number_VERIFY:
      return { ...state, user: payload };
    case signInConstansts.LOG_OUT_USER:
      return initialState;
    case signInConstansts.SET_ERROR:
      return { ...state, error: payload };
    case signInConstansts.REGISTER_PATIENT:
      return { ...state, userResponse: payload };
    case signInConstansts.APPOINT_MODAL:
      return { ...state, appointModal: payload };
    case signInConstansts.GOOGLE_USER_LOGIN:
      return { ...state, user: payload };
    case signInConstansts.USER_PHONE_NUMBER:
      return { ...state, userPhoneNum: payload };
    default:
      return state;
  }
};
