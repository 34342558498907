import React from "react";
import { capitalize } from "../../utils/functions";

const DashboardTile = ({ label, icon, onClick, active }) => {
  return (
    <div
      className={active ? "tile-btn-active" : "tile-btn"}
      onClick={() => onClick(label)}
    >
      <div
        className={
          label.toLowerCase() == "Add Medical Record".toLowerCase()
            ? "ml-3 text-center w-75"
            : "ml-3 text-center w-50"
        }
        style={{
          position: "relative",
          left: "5%",
        }}
      >
        {capitalize(label)}
      </div>
      <img
        src={icon}
        alt="tabicon"
        style={{ position: "absolute", left: "10%" }}
      />
    </div>
  );
};

export default DashboardTile;
