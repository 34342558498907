import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../components/layouts/bannerImgComponents/BannerImgComponents";
import PageMenuCard from "../../components/layouts/cards/PageMenuCard/PageMenuCard";
import useMediaQuery from "../../components/reuseablecomponents/MediaQueryHook";
import {
  Aboutcard1,
  Aboutcard2,
  banner,
  LinksBanSpeciality,
  specialitycard,
} from "../../data";
import { filterComponentData } from "../../utils/functions";

const Speciality = () => {
  const { t } = useTranslation("common");
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const cards = filterComponentData(component, "speciality_cards", lang);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const LinksBanSpeciality = [
    {
      subLinkName: banners[16]?.subtitle?.split(">")[0]
        ? banners[16]?.subtitle?.split(">")[0]
        : t("speciality.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[16]?.subtitle?.split(">")[1]
        ? banners[16]?.subtitle?.split(">")[1]
        : t("speciality.banner.title"),
      subDash: "",
      subLink: "/Speciality",
    },
  ];

  const specialitycard = [
    {
      id: 0,
      imageURL: Aboutcard2,
      text: t("speciality.pagemenucard.card1.text"),
      link: "/covid-19-management",
    },
    {
      id: 1,
      imageURL: Aboutcard2,
      text: t("speciality.pagemenucard.card2.text"),
      link: "/pain-management",
    },
    {
      id: 2,
      imageURL: Aboutcard1,
      text: t("speciality.pagemenucard.card3.text"),
      link: "/asthetic-medicine",
    },
    {
      id: 3,
      imageURL: Aboutcard1,
      text: t("speciality.pagemenucard.card4.text"),
      link: "/neurological-rehabilitation",
    },
    {
      id: 4,
      imageURL: Aboutcard1,
      text: t("speciality.pagemenucard.card5.text"),
      link: "/stem-cell-banking",
    },
    {
      id: 5,
      imageURL: Aboutcard1,
      text: t("speciality.pagemenucard.card6.text"),
      link: "/diabetes",
    },
    {
      id: 5,
      imageURL: Aboutcard1,
      text: "Respiratory Diseases",
      link: "/respiratory-diseases",
    },
  ];

  // const [indexno, setIndexno] = useState();
  const isDesktop = useMediaQuery("(min-width: 992px)");

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.specialityMain ? item?.specialityMain : "[]")
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.specialityMain
            ? specialityMetaTitles?.specialityMain
            : t("speciality.maintitle")}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.specialityMain
              ? specialityMetaDesc?.specialityMain
              : t("speciality.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.specialityMain
              ? specialityMetaKeywords?.specialityMain
              : t("speciality.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[16]?.heading
              ? banners[16]?.heading
              : t("speciality.banner.title")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[16]?.image ? banners[16]?.image : banner
          })`}
          imgSrc={banners[16]?.image ? banners[16]?.image : banner}
          LinksBan={LinksBanSpeciality}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container">
          <div className="row">
            {cards?.length < 1
              ? specialitycard.map((item, index) => {
                  return (
                    <PageMenuCard
                      // className={index===4 ? isDesktop ? 'col-lg-3 col-md-6 offset-3' : 'col-lg-3 col-md-6' : 'col-lg-3 col-md-6'}
                      className="col-lg-3 col-md-6"
                      key={index + 1}
                      img={item?.imageURL}
                      classNamemenucard={"pt-4"}
                      heading={item?.text}
                      linkroute={item?.link}
                    />
                  );
                })
              : cards?.map((item, index) => {
                  return (
                    <PageMenuCard
                      className="col-lg-3 col-md-6"
                      key={index + 1}
                      img={item?.icon}
                      classNamemenucard={"pt-4"}
                      heading={item?.heading}
                      linkroute={item?.link}
                    />
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Speciality;
