import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Testemonials from "../../../../../components/layouts/testimonials/Testemonials";
import TabBtnList from "../../../../../components/layouts/tabbuttons/DashboardBtnList";
import TabHandlerTextwithImage from "../../../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  cttreatment,
  imghy1,
  nemenucard7,
  LinksBanTreatmentCt,
  tabhandctc1,
  tabhandctc2,
  tabhandctc3,
  tabhandctc4,
} from "../../../../../data";
import HeadingWithBottomBar from "../../../../../components/reuseablecomponents/HeadingWithBottomBar";
import useMediaQuery from "../../../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Toggleform from "../../../../../components/layouts/toggleform/toggleform";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../../../utils/functions";

const MotorNeuronDisease = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const stories = filterComponentData(component, "our_stories", lang);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );
  const enquiry = filterComponentData(
    component,
    "cupping_therapy_ct_make_an_enquiry_",
    lang
  );
  const therapy = filterComponentData(component, "motor_neuron_disease", lang);
  const { t } = useTranslation("common");

  const LinksBanTreatmentCt = [
    {
      subLinkName: banners[60]?.subtitle?.split(">")[0]
        ? banners[60]?.subtitle?.split(">")[0]
        : t("cerebralpalsy.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[60]?.subtitle?.split(">")[1]
        ? banners[60]?.subtitle?.split(">")[1]
        : t("cerebralpalsy.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[60]?.subtitle?.split(">")[2]
        ? banners[60]?.subtitle?.split(">")[2]
        : t("cerebralpalsy.banner.title1"),
      subDash: ">",
      subLink: "/neurological-rehabilitation",
    },
    {
      subLinkName: banners[60]?.subtitle?.split(">")[3]
        ? banners[60]?.subtitle?.split(">")[3]
        : "Motor Neuron Disease",
      subDash: "",
      subLink: "/motor-neuron-disease",
    },
  ];

  const isDesktoplg = useMediaQuery("(min-width: 992px)");

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(
                item?.neurologicalMotor ? item?.neurologicalMotor : "[]"
              )
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.neurologicalMotor
            ? specialityMetaTitles?.neurologicalMotor
            : "Motor Neuron Disease"}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.neurologicalMotor
              ? specialityMetaDesc?.neurologicalMotor
              : "Motor Neuron Disease"
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.neurologicalMotor
              ? specialityMetaKeywords?.neurologicalMotor
              : "Motor Neuron Disease"
          }
        />
      </Helmet>
      <div className="">
        <BannerWithText
          heading={
            banners[60]?.heading ? banners[60]?.heading : "Motor Neuron Disease"
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[60]?.image ? banners[60]?.image : banner
          })`}
          imgSrc={banners[60]?.image ? banners[60]?.image : banner}
          LinksBan={LinksBanTreatmentCt}
          height={isDesktoplg ? "300px" : "330px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={therapy?.image ? therapy?.image : nemenucard7}
          alt={
            specialityAltTags?.neurologicalMotor
              ? specialityAltTags?.neurologicalMotor
              : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={
            therapy?.name ? therapy?.name : "What is Motor Neuron Disease"
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.desc ? (
                  <div dangerouslySetInnerHTML={{ __html: therapy?.desc }} />
                ) : (
                  <>
                    <p class="text-justify">
                      The motor neuron diseases (MNDs) are a group of
                      progressive neurological disorders that destroy cells that
                      control essential muscle activity such as speaking,
                      walking, breathing, and swallowing. They are a rare but
                      severe form of neurodegenerative disease.
                    </p>
                    <h2
                      style={{
                        textTransform: "capitalize",
                        color: "#333333",
                      }}
                      className="gray-headin"
                    >
                      Stem Cell Therapy for Motor Neuron Disease
                    </h2>

                    <hr
                      style={{
                        borderBottom: "3px solid #AF5689",
                        margin: "10px 0px 15px 0px",
                        width: "80px",
                      }}
                    ></hr>
                    <p class="text-justify">
                      Stem cells showcase a dimensional role in renewing the
                      lost motor neurons. Renewing of neurons can be obtained
                      through a latent supply of bone marrow cells. It includes
                      both hematopoietic as well as mesenchymal progenitor cells
                      and it can transfer itself to the spinal cord and infuse
                      effectively in the motor neuron disease region.
                      <br />
                      It has constantly been proven that cellular therapy is a
                      safe treatment for motor neuron disease treatment in India
                      and been used extensively.
                    </p>
                  </>
                )}
                {/* <br/><br/>
            {t("cttherapy.textrev.discriptiontxt2")} */}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default MotorNeuronDisease;
