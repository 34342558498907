import { administratorConstants } from "../constants/administratorConstants";
const initialState = {
  patients: [],
  doctor: [],
  doctorRequests: [],
  rescheduleRequest: [],
  orders: [],
  administrator_loader: false,
  appointment: [],
  request: [],
  today: [],
  past: [],
  upcoming: [],
};
export const administratorReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case administratorConstants.ADMINISTRATOR_LOADER:
      return { ...state, administrator_loader: payload };
    case administratorConstants.PATIENT_APPOINTMENTS:
      return { ...state, appointment: payload };
    case administratorConstants.PATIENTS:
      return { ...state, patients: payload };
    case administratorConstants.DOCTOR:
      return { ...state, doctor: payload };
    case administratorConstants.DOCTOR_REQUESTS:
      return { ...state, doctorRequests: payload };
    case administratorConstants.UPDATE_RESCHEDULE_REQUEST:
      return { ...state, rescheduleRequest: payload };
    case administratorConstants.ORDER_BY_EMAIL:
      return { ...state, orders: payload };
    case administratorConstants.READ_TARGET:
      return { ...state, target: payload };
    case administratorConstants.READ_BDE:
      return { ...state, bde: payload };
    case administratorConstants.ASSIGN_TARGET:
      return { ...state, targetCreate: payload };
    case administratorConstants.RETURN_REQUEST:
      return { ...state, request: payload };
    case administratorConstants.TODAYS_APPOINTMENT:
      return { ...state, today: payload };
    case administratorConstants.UPCOMING_APPOINTMENT:
      return { ...state, upcoming: payload };
    case administratorConstants.PAST_APPOINTMENT:
      return { ...state, past: payload };
    default:
      return state;
  }
};
