import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../../components/layouts/bannerImgComponents/BannerImgComponents";
import TextAndImageSection from "../../../components/reuseablecomponents/TextAndImageSection";
import { banner, Charimen, LinksBanManagement, Zakir } from "../../../data";
import { filterComponentData } from "../../../utils/functions";

const Management = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const managment = filterComponentData(component, "our_managment", lang);
  const { t } = useTranslation("common");
  const banners = filterComponentData(component, "banner", lang);
  const aboutImageAltTags = filterComponentData(
    component,
    "about_image_alt_tags",
    lang
  );
  const AboutMetaKeywords = filterComponentData(
    component,
    "about_meta_keywords",
    lang
  );
  const aboutSchemaMarkups = filterComponentData(
    component,
    "about_schema_markups",
    lang
  );
  const AboutVerifications = filterComponentData(
    component,
    "about_verification_meta_tags",
    lang
  );
  const LinksBanManagement = [
    {
      subLinkName: banners[2]?.subtitle?.split(">")[0]
        ? banners[2]?.subtitle?.split(">")[0]
        : t("ourmanagement.banner.title1"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[2]?.subtitle?.split(">")[1]
        ? banners[2]?.subtitle?.split(">")[1]
        : t("ourmanagement.banner.subHeading"),
      subDash: ">",
      subLink: "/about",
    },
    {
      subLinkName: banners[2]?.subtitle?.split(">")[2]
        ? banners[2]?.subtitle?.split(">")[2]
        : t("ourmanagement.banner.title"),
      subDash: "",
      subLink: "/our-management",
    },
  ];
  const verificationsTags = AboutVerifications?.map((item) =>
    React.createElement("meta", {
      name: item?.ourManagementName,
      content: item?.ourManagementContent,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}

        <title></title>
        {aboutSchemaMarkups?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.ourManagement ? item?.ourManagement : "[]")
            )}
          </script>
        ))}
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[2]?.heading
              ? banners[2]?.heading
              : t("ourmanagement.banner.title")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[2]?.image ? banners[2]?.image : banner
          })`}
          imgSrc={banners[2]?.image ? banners[2]?.image : banner}
          LinksBan={LinksBanManagement}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />
        {managment?.length < 1 ? (
          <>
            <div className="container">
              <div className="row">
                <TextAndImageSection
                  headingFirst={t("ourmanagement.textrev.headfirst")}
                  textCol="col-lg-9"
                  discription={
                    <>
                      <p>{t("ourmanagement.textrev.discriptiontxt1")}</p>

                      <p>
                        <b>{t("ourmanagement.textrev.subHeading")}</b>{" "}
                        {t("ourmanagement.textrev.discriptiontxt2")}
                      </p>

                      <p>{t("ourmanagement.textrev.discriptiontxt3")}</p>

                      <p>{t("ourmanagement.textrev.discriptiontxt4")}</p>

                      <p>
                        <b>{t("ourmanagement.textrev.subHeading")}</b>{" "}
                        {t("ourmanagement.textrev.discriptiontxt5")}
                      </p>
                    </>
                  }
                  imgCol="col-lg-3"
                  imgHtml={
                    <div class="profile-img">
                      <div class="about-yoga-pic">
                        <img
                          src={Charimen}
                          alt="Md. Faizur Rahman"
                          width="100%"
                        />
                      </div>
                      <h2>{t("ourmanagement.textrev.directorname")}</h2>
                      <h6>{t("ourmanagement.textrev.directorpos")}</h6>
                      <p>{t("ourmanagement.textrev.totildt")}</p>
                    </div>
                  }
                />
              </div>
            </div>
            <div className="container">
              <div className="row">
                <TextAndImageSection
                  headingFirst={t("ourmanagement.textrev1.headfirst")}
                  textCol="col-lg-9"
                  discription={
                    <>
                      <p>{t("ourmanagement.textrev1.discriptiontxt1")}</p>

                      <p>{t("ourmanagement.textrev1.discriptiontxt2")}</p>

                      <p>{t("ourmanagement.textrev1.discriptiontxt3")}</p>

                      <p>{t("ourmanagement.textrev1.discriptiontxt4")}</p>

                      <p>{t("ourmanagement.textrev1.discriptiontxt5")}</p>
                    </>
                  }
                  imgCol="col-lg-3"
                  imgHtml={
                    <div class="profile-img">
                      <div class="about-yoga-pic">
                        <img
                          src={Zakir}
                          alt="Md Zakir Hossain (Ph.D)"
                          width="100%"
                        />
                      </div>
                      <h2>{t("ourmanagement.textrev1.mdzakir")}</h2>
                      <h6>{t("ourmanagement.textrev1.position")}</h6>
                      <p>
                        {t("ourmanagement.textrev1.labdirector")}
                        <br />
                        {t("ourmanagement.textrev1.toticellltddhaka")}
                        <br />
                        <b>{t("ourmanagement.textrev1.email")}</b> :{" "}
                        {t("ourmanagement.textrev1.emailadd")}
                        <br />
                        <b>{t("ourmanagement.textrev1.tel")}</b> :{" "}
                        {t("ourmanagement.textrev1.phonenumber")}
                      </p>
                    </div>
                  }
                />
              </div>
            </div>
          </>
        ) : (
          managment?.map((item) => (
            <div className="container">
              {console.log("item?.image", item?.image)}
              <div className="row">
                <TextAndImageSection
                  headingFirst={item?.heading}
                  textCol="col-lg-9"
                  discription={
                    <>
                      <p
                        dangerouslySetInnerHTML={{ __html: item?.description1 }}
                      />

                      <p
                        dangerouslySetInnerHTML={{ __html: item?.description2 }}
                      />

                      <p
                        dangerouslySetInnerHTML={{ __html: item?.description3 }}
                      />

                      <p
                        dangerouslySetInnerHTML={{ __html: item?.description4 }}
                      />

                      <p
                        dangerouslySetInnerHTML={{ __html: item?.description5 }}
                      />
                    </>
                  }
                  imgCol="col-lg-3"
                  imgHtml={
                    <div class="profile-img">
                      <div class="about-yoga-pic">
                        <img
                          src={item?.image}
                          // alt="Md. Faizur Rahman"
                          alt={
                            aboutImageAltTags?.aboutManagementDirector
                              ? aboutImageAltTags?.aboutManagementDirector
                              : "Md. Faizur Rahman "
                          }
                          width="100%"
                        />
                      </div>
                      <h2>{item?.username}</h2>
                      <h6>{item?.userdesignation}</h6>
                      <p dangerouslySetInnerHTML={{ __html: item?.company }} />
                    </div>
                  }
                />
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Management;
