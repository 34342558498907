import React from "react";
import { useTranslation } from "react-i18next";
import DashBoardMainHading from "../../components/reuseablecomponents/DashBoardMainHading";
import DetailTable from "../../components/reuseablecomponents/DetailTable";

const AdministratoDoctor = (props) => {
  const { t } = useTranslation("common");
  const viewData = props?.location?.state;
  let _obj = viewData.timings;
  let _arr = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  let sorted_obj = {};
  for (let i = 0; i < _arr.length; i++) {
    if (_obj.hasOwnProperty(_arr[i])) {
      sorted_obj[_arr[i]] = _obj[_arr[i]];
    }
  }
  const entries = Object.entries(sorted_obj);
  return (
    <>
      {/* {t("dashboards.alldashboardkeys.tablekeys.addslot")} */}
      <div style={{ backgroundColor: "#f9f9f9" }}>
        <DashBoardMainHading
          title={t("dashboards.alldashboardkeys.tablekeys.doctordetail")}
        />
        <div className="container">
          <div className="row center-children-class mb-4 ">
            <div className="col-xl-8 col-md-8 col-12 px-0">
              <div
                className="btn btnSquare "
                style={{ width: "150px" }}
                onClick={() => {
                  props.history.push("/administrator-dashboard");
                }}
              >
                <i className="fas fa-arrow-left"></i>{" "}
                {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
              </div>
            </div>
          </div>
          <div className="row center-childrens mt-3 pb-5 center-children-class">
            <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
              <h5>{t("dashboards.alldashboardkeys.tablekeys.doctordetail")}</h5>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.doctorname")}
                  </span>
                  <p>{viewData?.name}</p>
                </li>{" "}
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.doctoremail")}
                  </span>
                  <p>{viewData?.email_address}</p>
                </li>{" "}
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.speciality")}
                  </span>
                  <br />
                  {viewData?.speciality?.map((item, idx) => (
                    <span>{idx === 0 ? item?.name : ", " + item?.name}</span>
                  ))}
                </li>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.department")}
                  </span>
                  <p>{viewData?.departments[0]?.name}</p>
                </li>
              </ul>

              <DetailTable
                tableHead={[
                  t("dashboards.alldashboardkeys.tablekeys.day"),
                  t("dashboards.alldashboardkeys.tablekeys.from"),
                  t("dashboards.alldashboardkeys.tablekeys.to"),
                ]}
                data={entries.map((item) => {
                  // console.log("item", item);
                  // console.log(item[0]);
                  return [
                    item[0],
                    item[1].from,
                    item[1].to,
                    // viewData?.timings[item].from,
                    // viewData?.timings[item].to,
                  ];
                })}
                // data={Object?.keys(viewData?.timings)?.map((item) => {
                //   return [
                //     item,
                //     viewData?.timings[item]?.from,
                //     viewData?.timings[item]?.to,
                //   ];
                // })}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdministratoDoctor;
