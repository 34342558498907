import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import moment from "moment";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import { useTranslation } from "react-i18next";
import RefundModal from "../../components/layouts/modal/refundAmountModal/RefundModal";
import { toast } from "react-toastify";
import { cancelAppointment, patientAppointments } from "../../store/actions/patientActions";
import AlertRefund from "../../components/layouts/modal/AlertRefund/AlertRefund";
import RescheduleModal from "../../components/layouts/modal/rescheduleModal/RescheduleModal";
import { comaSeperated } from "../../utils/functions";

const PatientPastAppointment = (props) => {
  const [show, setShow] = useState(false)
  const [id, setID] = useState("")
  const user = useSelector((state) => state?.logIn?.user);
  const date = new Date()
  const time = date.getTime()
  const new_date = moment(new Date()).format('YYYY-MM-DD HH:mm');

  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation("common");
  const [rescheduleModal, setrescheduleModal] = useState({
    show: false,
    data: {},
  });
  const appointments = useSelector(
    (state) => state?.patient?.appointments?.past
  );
  const handleChange = async (value, row) => {

    if (value === "View") {
      const item = { ...row, from: "PastAppointment" };
      props?.history?.push("/patient-dashboard-appointment-details", { row, from: "PastAppointment" });
    }
    else if (value == "Cancel") {
      let res = await dispatch(cancelAppointment({ appointment_id: row?.id, timestamp: new_date }))
      dispatch(patientAppointments({ id: user?.id }));


    } else if (value == "Cancel/Refund") {
      let [hour, minutes] = row?.slot?.from?.split(":")

      if ((hour - date.getHours()) < 6) {
        setShowModal(!showModal)
      } else {
        setID(row?.id)
        setShow(!show)
      }
    } else if (value == "Reschedule") {
      setrescheduleModal({
        open: true,
        data: row,
      });

    }
  };
  // t("dashboards.alldashboardkeys.tablekeys.paymentstatus")
  return (
    <>
      <div className="w-100">
        <div className="p-3">
          <span className="dashboardHeadingText">{t("dashboards.alldashboardkeys.tablekeys.pastappointments")}</span>
        </div>
        <BootstrapCustomTable
          columns={[
            {
              formatter: (cell, row, index) => {
                return <span>{index + 1}</span>;
              },
              text: "Sr.",
            },
            {
              formatter: (cell, row) => {
                return <span>{moment(row.date).format("DD/MM/YYYY")}</span>;
              },
              text: t("dashboards.alldashboardkeys.tablekeys.date"),
            },
            {
              formatter: (cell, row) => {
                return <span>{`${row?.slot?.from} - ${row?.slot?.to}`}</span>;
              },
              text: t("dashboards.alldashboardkeys.tablekeys.time"),
            },
            // { dataField: "slot.from slot.to", text: "Time" },

            { dataField: "doctor_details.name", text: t("dashboards.alldashboardkeys.tablekeys.doctorname") },
            { dataField: "consultation_type.name", text: t("dashboards.alldashboardkeys.tablekeys.consultationtype") },
            { dataField: "condition.name", text: "Condition" },
            {
              formatter: (cell, row) => {
                return <span>{`${row?.is_refunded ? comaSeperated(row?.refunded_amount) : '0'}`}</span>;
              },
              text: "Refund Amount",
            },
            {
              formatter: (cell, row) => {
                return <span style={{ color: row?.status?.name == "booked" ? 'red' : row?.status?.name == "confirmed" ? 'green' : '#af5689' }}>{row?.status?.name?.toUpperCase()}</span>;
              },
              text: t("dashboards.alldashboardkeys.tablekeys.status"),
            },
            {
              formatter: (cell, row, index) => {
                return (
                  row?.status?.name == "booked" ?
                    <ActionBtn
                      options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn"),"Cancel", "Reschedule"]}
                      handleChange={handleChange}
                      data={row}
                    />
                    : row?.status?.name == "confirmed" && row?.consultation_type?.name == "online" ? <ActionBtn
                      options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn"), "Reschedule", "Cancel"]}
                      handleChange={handleChange}
                      data={row}
                    /> : row?.status?.name == "confirmed" && row?.consultation_type?.name == "walk-in" ? <ActionBtn
                      options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn"), "Reschedule", "Cancel"]}
                      handleChange={handleChange}
                      data={row}
                    /> : row?.status?.name == "completed" ? <ActionBtn
                      options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                      handleChange={handleChange}
                      data={row}
                    /> : <ActionBtn
                      options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                      handleChange={handleChange}
                      data={row}
                    />

                );
              },
              text: t("dashboards.alldashboardkeys.tablekeys.action"),
            },
          ]}
          tabledata={appointments ? appointments : []}
        />
      </div>
      <RescheduleModal
        show={rescheduleModal?.open}
        data={rescheduleModal?.data}
        onHide={() => setrescheduleModal({ open: false, data: {} })}
        {...props}
      />
      <RefundModal
        show={show}
        id={id}
        onHide={() => setShow(false)}
      />
      <AlertRefund
        show={showModal}
        onHide={() => setShowModal(false)}

      />
    </>
  );
};

export default PatientPastAppointment;
