import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  comenucard1,
  banner,
  LinksBanSpecialityCovidMolecular,
} from "../../../../../data";
import { filterComponentData } from "../../../../../utils/functions";

const MolecularDiagnosis = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );

  const molecular = filterComponentData(
    component,
    "speciality_molecular_diagnosis",
    lang
  );
  const { t } = useTranslation("common");
  const LinksBanSpecialityCovidMolecular = [
    {
      subLinkName: banners[18]?.subtitle?.split(">")[0]
        ? banners[18]?.subtitle?.split(">")[0]
        : t("moleculardiagnostic.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[18]?.subtitle?.split(">")[1]
        ? banners[18]?.subtitle?.split(">")[1]
        : t("moleculardiagnostic.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[18]?.subtitle?.split(">")[2]
        ? banners[18]?.subtitle?.split(">")[2]
        : t("moleculardiagnostic.banner.title1"),
      subDash: ">",
      subLink: "/covid-19-management",
    },
    {
      subLinkName: banners[18]?.subtitle?.split(">")[3]
        ? banners[18]?.subtitle?.split(">")[3]
        : t("moleculardiagnostic.banner.title2"),
      subDash: "",
      subLink: "/molecular-diagnosis",
    },
  ];
  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.covidMolecular ? item?.covidMolecular : "[]")
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.covidMolecular
            ? specialityMetaTitles?.covidMolecular
            : t("moleculardiagnostic.maintitle")}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.covidMolecular
              ? specialityMetaDesc?.covidMolecular
              : t("moleculardiagnostic.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.covidMolecular
              ? specialityMetaKeywords?.covidMolecular
              : t("moleculardiagnostic.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[18]?.heading
              ? banners[18]?.heading
              : t("moleculardiagnostic.banner.title2")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[18]?.image ? banners[18]?.image : banner
          })`}
          imgSrc={banners[18]?.image ? banners[18]?.image : banner}
          LinksBan={LinksBanSpecialityCovidMolecular}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={molecular?.image ? molecular?.image : comenucard1}
          alt={
            specialityAltTags?.covidMolecular
              ? specialityAltTags?.covidMolecular
              : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={
            molecular?.heading
              ? molecular?.heading
              : t("moleculardiagnostic.textrev.headfirst")
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {molecular?.description
                  ? molecular?.description
                  : t("moleculardiagnostic.textrev.discriptiontxt1")}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default MolecularDiagnosis;
