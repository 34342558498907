import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../components/layouts/bannerImgComponents/BannerImgComponents";
import ApplyJobModal from "../../components/layouts/modal/applyJobModal/ApplyJobModal";
import TextAndImageSection from "../../components/reuseablecomponents/TextAndImageSection";
import { banner, LinksBanCareers, careersdatalist } from "../../data";
import { filterComponentData } from "../../utils/functions";

const Careers = (props) => {
  const { t } = useTranslation("common");
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const career = filterComponentData(component, "careers", lang);
  const banners = filterComponentData(component, "banner", lang);
  const footerMetaTitles = filterComponentData(
    component,
    "footer_meta_titles",
    lang
  );
  const footerSchemaMarkups = filterComponentData(
    component,
    "footer_schema_markups",
    lang
  );
  const footerMetaDesc = filterComponentData(
    component,
    "footer_meta_descriptions",
    lang
  );
  const footerMetaKeywords = filterComponentData(
    component,
    "footer_meta_keywords",
    lang
  );
  const footerVerification = filterComponentData(
    component,
    "footer_verification_meta_tags",
    lang
  );
  const LinksBanCareers = [
    {
      subLinkName: banners[62]?.subtitle?.split(">")[0]
        ? banners[62]?.subtitle?.split(">")[0]
        : t("careers.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[62]?.subtitle?.split(">")[1]
        ? banners[62]?.subtitle?.split(">")[1]
        : t("careers.banner.title"),
      subDash: "",
      subLink: "/career",
    },
  ];

  const careersdatalist = [
    {
      list1: [
        t("careers.list1.txt1"),
        t("careers.list1.txt2"),
        t("careers.list1.txt3"),
        t("careers.list1.txt4"),
        t("careers.list1.txt5"),
        t("careers.list1.txt6"),
        t("careers.list1.txt7"),
      ],
      list2: [
        t("careers.list2.txt1"),
        t("careers.list2.txt2"),
        t("careers.list2.txt3"),
        t("careers.list2.txt4"),
        t("careers.list2.txt5"),
        t("careers.list2.txt6"),
      ],
      list3: [t("careers.list3.txt1")],
      list4: [
        t("careers.list4.txt1"),
        t("careers.list4.txt2"),
        t("careers.list4.txt3"),
      ],

      list6: [t("careers.list6.txt1")],
      list7: [
        t("careers.list7.txt1"),
        t("careers.list7.txt2"),
        t("careers.list7.txt3"),
        t("careers.list7.txt4"),
        t("careers.list7.txt5"),
        t("careers.list7.txt6"),
        t("careers.list7.txt7"),
        t("careers.list7.txt8"),
      ],
      list8: [
        t("careers.list8.txt1"),
        t("careers.list8.txt2"),
        t("careers.list8.txt3"),
        t("careers.list8.txt4"),
        t("careers.list8.txt5"),
        t("careers.list8.txt6"),
      ],
      list9: [t("careers.list9.txt1")],
      list10: [t("careers.list10.txt1")],
      list11: [t("careers.list11.txt1")],
      list12: [
        t("careers.list12.txt1"),
        t("careers.list12.txt2"),
        t("careers.list12.txt3"),
        t("careers.list12.txt4"),
        t("careers.list12.txt5"),
        t("careers.list12.txt6"),
        t("careers.list12.txt7"),
        t("careers.list12.txt8"),
        t("careers.list12.txt9"),
        t("careers.list12.txt10"),
        t("careers.list12.txt11"),
      ],
      list13: [t("careers.list13.txt1"), t("careers.list13.txt2")],
    },
  ];

  const [showJobModal, setshowJobModal] = useState({ show: false, data: {} });
  const verificationsTags = footerVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.careerName,
      content: item?.careerContent,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}
        {footerSchemaMarkups?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(JSON.stringify(item?.career ? item?.career : "[]"))}
          </script>
        ))}
        <title>
          {footerMetaTitles?.career
            ? footerMetaTitles?.career
            : t("careers.maintitle")}
        </title>
        <meta
          name="description"
          content={
            footerMetaDesc?.career
              ? footerMetaDesc?.career
              : t("careers.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            footerMetaKeywords?.career
              ? footerMetaKeywords?.career
              : t("careers.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[62]?.heading
              ? banners[62]?.heading
              : t("careers.banner.title")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[62]?.image ? banners[62]?.image : banner
          })`}
          imgSrc={banner}
          LinksBan={LinksBanCareers}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />
        {career?.description ? (
          <div
            dangerouslySetInnerHTML={{
              __html: career?.description,
            }}
          ></div>
        ) : (
          <TextAndImageSection
            textCol="col-lg-12"
            headingFirst={t("careers.jobopertunity")}
            discription={
              <>
                <p
                  style={{
                    fontFamily: "Work Sans', sans-serif",
                    lineHeight: "25px",
                    marginTop: "20px",
                  }}
                  className="light-Color-Para mb-3"
                >
                  {t("careers.futuredreams")}
                </p>
                <h2
                  style={{
                    fontWeight: "300",
                    textTransform: "capitalize",
                    color: "#AF5689",
                  }}
                  className="gray-headin"
                >
                  {t("careers.positiondoctor")}
                </h2>
                <p
                  style={{
                    fontFamily: "Work Sans', sans-serif",
                    lineHeight: "25px",
                    marginTop: "20px",
                  }}
                  className="light-Color-Para mb-3"
                >
                  {t("careers.txt1")}
                  <br />
                  <br />
                  {t("careers.txt2")}
                </p>
                <h3
                  style={{
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  className="gray-headin mb-3"
                >
                  {t("careers.txt3")}
                </h3>
                <div class="span-department mb-5">
                  <ul>
                    <li>
                      <i class="fa fa-check"></i>
                      {t("careers.txt4")}
                    </li>
                  </ul>
                </div>
                <h3
                  style={{
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  className="gray-headin mb-3"
                >
                  {t("careers.txt5")}
                </h3>
                <div class="span-department mb-5">
                  <ul>
                    {careersdatalist[0]?.list1?.map((item) => {
                      return (
                        <li>
                          <i class="fa fa-check"></i>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <h3
                  style={{
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  className="gray-headin mb-3"
                >
                  {t("careers.txt6")}
                </h3>
                <div class="span-department mb-5">
                  <ul>
                    {careersdatalist[0]?.list2?.map((item) => {
                      return (
                        <li>
                          <i class="fa fa-check"></i>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <h3
                  style={{
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  className="gray-headin mb-3"
                >
                  {t("careers.txt7")}
                </h3>
                <div class="span-department mb-5">
                  <ul>
                    {careersdatalist[0]?.list3?.map((item) => {
                      return (
                        <li>
                          <i class="fa fa-check"></i>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <h3
                  style={{
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  className="gray-headin mb-3"
                >
                  {t("careers.txt8")}
                </h3>
                <div class="span-department mb-5">
                  <ul>
                    {careersdatalist[0]?.list4?.map((item) => {
                      return (
                        <li>
                          <i class="fa fa-check"></i>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <p className="text-center">
                  {t("careers.txt9")}
                  <a href="mailto:info@toticell.com">info@toticell.com </a>
                  <br />
                  <b>{t("careers.txt10")}</b>
                  <br />
                  {t("careers.txt11")}
                  <br />
                  {t("careers.txt12")}
                  <br />
                  {t("careers.txt13")}
                  <br />
                  {t("careers.txt14")}
                  <br />
                  <b>{t("careers.txt10")}</b>
                </p>
                <div class="row">
                  <div class="col-md-12 center-children-class ">
                    <p>
                      <input
                        type="submit"
                        onClick={() => {
                          setshowJobModal({ show: true, data: {} });
                        }}
                        class="btnRoundedAbout px-4 py-2"
                        value="Apply Online"
                        style={{ cursor: "pointer" }}
                      />
                    </p>
                  </div>
                </div>
                <h2
                  style={{
                    fontWeight: "300",
                    textTransform: "capitalize",
                    color: "#AF5689",
                  }}
                  className="gray-headin"
                >
                  {t("careers.txt15")}
                </h2>
                <p
                  style={{
                    fontFamily: "Work Sans', sans-serif",
                    lineHeight: "25px",
                    marginTop: "20px",
                  }}
                  className="light-Color-Para mb-3"
                >
                  {t("careers.txt1")}
                  <br />
                  <br />
                  {t("careers.txt2")}
                </p>
                <h3
                  style={{
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  className="gray-headin mb-3"
                >
                  {t("careers.txt3")}
                </h3>

                <div class="span-department mb-5">
                  <ul>
                    {careersdatalist[0]?.list6?.map((item) => {
                      return (
                        <li>
                          <i class="fa fa-check"></i>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <h3
                  style={{
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  className="gray-headin mb-3"
                >
                  {t("careers.txt5")}
                </h3>
                <div class="span-department mb-5">
                  <ul>
                    {careersdatalist[0]?.list7?.map((item) => {
                      return (
                        <li>
                          <i class="fa fa-check"></i>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <h3
                  style={{
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  className="gray-headin mb-3"
                >
                  {t("careers.txt6")}
                </h3>
                <div class="span-department mb-5">
                  <ul>
                    {careersdatalist[0]?.list8?.map((item) => {
                      return (
                        <li>
                          <i class="fa fa-check"></i>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <h3
                  style={{
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  className="gray-headin mb-3"
                >
                  {t("careers.txt7")}
                </h3>
                <div class="span-department mb-5">
                  <ul>
                    {careersdatalist[0]?.list9?.map((item) => {
                      return (
                        <li>
                          <i class="fa fa-check"></i>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <h3
                  style={{
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  className="gray-headin mb-3"
                >
                  {t("careers.txt8")}
                </h3>
                <div class="span-department mb-5">
                  <ul>
                    {careersdatalist[0]?.list10?.map((item) => {
                      return (
                        <li>
                          <i class="fa fa-check"></i>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <p className="text-center">
                  {t("careers.txt9")}
                  <a href="mailto:info@toticell.com">info@toticell.com </a>
                  <br />
                  <b>{t("careers.txt10")}</b>
                  <br />
                  {t("careers.txt11")}
                  <br />
                  {t("careers.txt12")}
                  <br />
                  {t("careers.txt13")}
                  <br />
                  {t("careers.txt14")}
                  <br />
                  <b>{t("careers.txt10")}</b>
                </p>
                <div class="row">
                  <div class="col-md-12 center-children-class ">
                    <p>
                      <input
                        type="submit"
                        class="btnRoundedAbout px-4 py-2"
                        value="Apply Online"
                        onClick={() => {
                          setshowJobModal({ show: true, data: {} });
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </p>
                  </div>
                </div>

                <h2
                  style={{
                    fontWeight: "300",
                    textTransform: "capitalize",
                    color: "#AF5689",
                  }}
                  className="gray-headin"
                >
                  {t("careers.txt16")}
                </h2>
                <h3
                  style={{
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  className="gray-headin mb-3"
                >
                  {t("careers.txt17")}
                </h3>
                <p
                  style={{
                    fontFamily: "Work Sans', sans-serif",
                    lineHeight: "25px",
                    marginTop: "20px",
                  }}
                  className="light-Color-Para mb-3"
                >
                  {t("careers.txt18")}
                </p>
                <h3
                  style={{
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  className="gray-headin mb-3"
                >
                  {t("careers.txt19")}
                </h3>

                <div class="span-department mb-5">
                  <ul>
                    {careersdatalist[0]?.list11?.map((item) => {
                      return (
                        <li>
                          <i class="fa fa-check"></i>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <h3
                  style={{
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  className="gray-headin mb-3"
                >
                  {t("careers.txt20")}
                </h3>
                <div class="span-department mb-5">
                  <ul>
                    {careersdatalist[0]?.list12?.map((item) => {
                      return (
                        <li>
                          <i class="fa fa-check"></i>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <h3
                  style={{
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  className="gray-headin mb-3"
                >
                  {t("careers.txt21")}
                </h3>
                <div class="span-department mb-5">
                  <ul>
                    {careersdatalist[0]?.list9?.map((item) => {
                      return (
                        <li>
                          <i class="fa fa-check"></i>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <h3
                  style={{
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  className="gray-headin mb-3"
                >
                  {t("careers.txt22")}
                </h3>
                <div class="span-department mb-5">
                  <ul>
                    {careersdatalist[0]?.list10?.map((item) => {
                      return (
                        <li>
                          <i class="fa fa-check"></i>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <p className="text-center">
                  {t("careers.txt9")}
                  <a href="mailto:info@toticell.com">info@toticell.com </a>
                  <br />
                  <b>{t("careers.txt10")}</b>
                  <br />
                  {t("careers.txt11")}
                  <br />
                  {t("careers.txt12")}
                  <br />
                  {t("careers.txt13")}
                  <br />
                  {t("careers.txt14")}
                  <br />
                  <b>{t("careers.txt10")}</b>
                </p>
                <div class="row">
                  <div class="col-md-12 center-children-class ">
                    <p>
                      <input
                        type="submit"
                        class="btnRoundedAbout px-4 py-2"
                        value={t(
                          "dashboards.alldashboardkeys.tablekeys.applyonline"
                        )}
                        onClick={() => {
                          setshowJobModal({ show: true, data: {} });
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </p>
                  </div>
                </div>
                <div class="span-department mb-5">
                  <ul>
                    {careersdatalist[0]?.list13?.map((item) => {
                      return (
                        <li>
                          <i class="fa fa-check"></i>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </>
            }
            reverse={false}
          />
        )}
      </div>
      <ApplyJobModal
        show={showJobModal.show}
        onHide={() => setshowJobModal({ show: false, data: {} })}
        {...props}
      />
    </>
  );
};

export default Careers;
