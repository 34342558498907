/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardBtn from "../../components/reuseablecomponents/DashboardBtn";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import { useTranslation } from "react-i18next";
import { readPastAppointmentAction, readTodaysAppointmentAction, readUpcomingAppointmentAction } from "../../store/actions/administratorActions";
import AdministratorTodayAppointment from "./AdministratorTodayAppointment";
import AdministratorPastAppointment from "./AdministratorPastAppointment";
import AdministratorUpcomingAppointment from "./AdministratorUpcomingAppointment";

const AdministratorDashboardAppointmentContainer = (props) => {
    const { t } = useTranslation("common")
    const [selectedTab, setselectedTab] = useState(t("dashboards.alldashboardkeys.tablekeys.today"));
    const loader = useSelector((state) => state?.administrator?.administrator_loader);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(readTodaysAppointmentAction())
        dispatch(readPastAppointmentAction())
        dispatch(readUpcomingAppointmentAction())

    }, []);
    const tabHandler = (item) => {
        setselectedTab(item);
    };
    return (
        <div>
            {loader ? (
                <LoderContainer />
            ) : (
                <>
                    <div className="row">
                        {[t("dashboards.alldashboardkeys.tablekeys.today"), t("dashboards.alldashboardkeys.tablekeys.upcoming"), t("dashboards.alldashboardkeys.tablekeys.past")]?.map((item, index) => (
                            <div className="col-lg-3 col-md-4 col-12 mt-2" key={index + 1}>
                                <DashboardBtn
                                    label={item}
                                    active={selectedTab === item}
                                    onClick={tabHandler}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        {/* t("dashboards.alldashboardkeys.tablekeys.past") */}
                        {selectedTab === t("dashboards.alldashboardkeys.tablekeys.today") ? (
                            <AdministratorTodayAppointment {...props} />
                        ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.upcoming") ? (
                            <AdministratorUpcomingAppointment {...props} />
                        ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.past") ? (
                            <AdministratorPastAppointment {...props} />
                        ) : null}
                    </div>
                </>
            )}
        </div>
    );
};

export default AdministratorDashboardAppointmentContainer;
