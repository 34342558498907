import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import UploadReportModal from "../../components/layouts/modal/uploadReportModal/UploadReportModal";
import DashboardBtn from "../../components/reuseablecomponents/DashboardBtn";
import DashboardBtnList from "../../components/reuseablecomponents/DashboardBtnList";
// import { useDispatch } from "react-redux";
import DashBoardMainHading from "../../components/reuseablecomponents/DashBoardMainHading";
import DetailTable from "../../components/reuseablecomponents/DetailTable";
import {
  changeResportStatus,
  getTestResports,
} from "../../store/actions/nurseActions";
import { pharmacyPay } from "../../store/actions/pharmacyActions";
import { comaSeperated } from "../../utils/functions";

const ViewTestDetails = (props) => {
  // let viewData = props.location.state;
  const {t} = useTranslation("common")
  const item = { key: "fromPrescription" };
  const loader = useSelector((state) => state?.logIn?.authLoader);

  const [openuploadModal, setopenuploadModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  const [viewData, setviewData] = useState(props.location.state);

  const dispatch = useDispatch();
  useEffect(() => {
    setSelectedReport(props.location.state);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    dispatch(getTestResports());
  }, [dispatch]);
  // t("dashboards.alldashboardkeys.tablekeys.action")
  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading title={t("dashboards.alldashboardkeys.tablekeys.testdetails")} />
      <div className="container">
        <div className="row center-children-class">
          <div className="col-xl-8 col-md-8 col-12 px-0">
            <div
              className="btn btnSquare"
              style={{ width: "150px" }}
              onClick={() => {
                if (viewData?.from === "administrator") {
                  props?.history?.push("/administrator", item);
                } else {
                  props?.history?.push("/nurse-dashboard", item);
                }
              }}
            >
              <i className="fas fa-arrow-left"></i> {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
            </div>
          </div>
        </div>
        <div className="row center-children-class mt-3  pb-5">
          <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
            <h5>
            {t("dashboards.alldashboardkeys.tablekeys.patientdetail")}
              </h5>
            <ul style={{ listStyle: "none" }}>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientname")}
                </span>
                <p>{viewData?.ordered_by?.name}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientemail")}
                </span>
                <p>{viewData?.ordered_by?.email_address}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientnumber")}
                </span>
                <p>{viewData?.ordered_by?.phone_number}</p>
              </li>
            </ul>

            {viewData?.tests?.length > 0 ? (
              <>
                <DetailTable
                  tableHead={[
                    t("dashboards.alldashboardkeys.tablekeys.name")
                    ,
                    t("dashboards.alldashboardkeys.tablekeys.testid")
                    ,
                    t("dashboards.alldashboardkeys.tablekeys.price")
                    ,
                    t("dashboards.alldashboardkeys.tablekeys.action")
                  ]}
                  data={viewData?.tests?.map((item, index) => {
                    return [
                      item?.test?.name,
                      item?.test?.test_id,
                      item?.test?.price,
                      <>
                        {item?.test?.report?.status?.name === "pending" &&
                          (viewData?.paid_status?.name === "paid" || viewData?.paid_status?.name === "partial") ? (
                          <DashboardBtn
                            label={t("dashboards.alldashboardkeys.tablekeys.collectsample")}
                            className="order-pay-btn"
                            onClick={async () => {
                              const data = await dispatch(
                                changeResportStatus(
                                  {
                                    report_ids: [item?.test?.report?.id],
                                    status: { id: 2, name: "sample collected" },
                                  },
                                  viewData?.id
                                )
                              );
                              let testArray = [...viewData?.tests];
                              testArray[index] = {
                                ...viewData?.tests[index],
                                test: {
                                  ...viewData?.tests[index].test,
                                  report: data[0],
                                },
                              };

                              setviewData({
                                ...viewData,
                                tests: testArray,
                              });
                            }}
                          />
                        ) : item?.test?.report?.status?.name ===
                          "sample collected" &&
                          (viewData?.paid_status?.name === "paid" || viewData?.paid_status?.name === "partial") ? (
                          <div
                            className="btnSquare text-center"
                            style={{ cursor: 'pointer' }}

                            onClick={async () => {
                              const data = await dispatch(
                                changeResportStatus(
                                  {
                                    report_ids: [item?.test?.report?.id],
                                    status: { id: 3, name: "sample submitted" },
                                  },
                                  viewData?.id
                                )
                              );
                              let testArray = [...viewData?.tests];
                              testArray[index] = {
                                ...viewData?.tests[index],
                                test: {
                                  ...viewData?.tests[index].test,
                                  report: data[0],
                                },
                              };

                              setviewData({
                                ...viewData,
                                tests: testArray,
                              });

                              // props?.history?.push("/lab-administrator", item);
                            }}
                          >{t("dashboards.alldashboardkeys.tablekeys.submitsample")}</div>
                        ) : item?.test?.report?.status?.name ===
                          "sample submitted" &&
                          (viewData?.paid_status?.name === "paid" || viewData?.paid_status?.name === "partial") ? (
                          <div
                            style={{ cursor: 'pointer' }}


                            className="btnSquare text-center"
                            onClick={() => {
                              setSelectedReport(item);
                              setopenuploadModal(true);
                            }}
                          >
                            {t("dashboards.alldashboardkeys.tablekeys.submitreport")}
                          </div>
                        ) : item?.test?.report?.status?.name ===
                        "report uploaded" &&
                        (viewData?.paid_status?.name === "paid" || viewData?.paid_status?.name === "partial") ?(
                          <a
                            className="btn btnSquare w-100"
                            href={item?.test?.report?.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t("dashboards.alldashboardkeys.tablekeys.viewreport")}
                          </a>
                        ) : null}
                      </>,
                    ];
                  })}
                />
              </>
            ) : (
              <div className="text-center"> {t("dashboards.alldashboardkeys.tablekeys.noprescribedtest")}</div>
            )}
            {viewData?.paid_status?.name == "partial" ?
              <>
                <ul style={{ float: 'right', listStyle: 'none' }}>
                  <li>
                    <span className=""
                      style={{ fontSize: "16px", fontWeight: "500", color: '#787878' }} >{t("dashboards.alldashboardkeys.tablekeys.totalamount")} : </span>

                    <span style={{ fontWeight: 'bold' }}>{comaSeperated(viewData?.total_amount)}</span></li>
                  <li>
                    <span className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500", color: '#787878' }} >{t("dashboards.alldashboardkeys.tablekeys.paidamount")} : </span>

                    <span style={{ fontWeight: 'bold' }}>{comaSeperated(viewData?.paid_amount)}</span></li>
                  <li>
                    <span className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500", color: '#787878' }} >{t("dashboards.alldashboardkeys.tablekeys.remainingamount")} : </span>

                    <span style={{ fontWeight: 'bold' }}>{comaSeperated(viewData?.remaining_amount)}</span></li>
                  {/* <li>
                    <span className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }} >Installment Amount : </span>

                    <span style={{ fontWeight: 'bold' }}>{viewData?.installments_amount}</span></li> */}



                </ul>

              </>
              : viewData?.paid_status?.name == "paid" ?
                <ul style={{ float: 'right', listStyle: 'none' }}>
                  <li>
                    <span className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500", color: '#787878' }} >{t("dashboards.alldashboardkeys.tablekeys.totalamount")} : </span>

                    <span style={{ fontWeight: 'bold' }}>{comaSeperated(viewData?.total_amount)}</span></li>
                  <li>
                    <span className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500", color: '#787878' }} >{t("dashboards.alldashboardkeys.tablekeys.paidamount")} : </span>

                    <span style={{ fontWeight: 'bold' }}>{comaSeperated(viewData?.paid_amount)}</span></li>
                </ul>
                : viewData?.paid_status?.name == "unpaid" ?
                  <>
                    <ul style={{ float: 'right', listStyle: 'none' }}>
                      <li>
                        <span className="light-Color-Para"
                          style={{ fontSize: "16px", fontWeight: "500", color: '#787878' }} >{t("dashboards.alldashboardkeys.tablekeys.totalamount")} : </span>

                        <span style={{ fontWeight: 'bold' }}>{comaSeperated(viewData?.total_amount)}</span></li>

                      <li>
                        <span className="light-Color-Para"
                          style={{ fontSize: "16px", fontWeight: "500", color: '#787878' }} >{t("dashboards.alldashboardkeys.tablekeys.installmentamount")} : </span>

                        <span style={{ fontWeight: 'bold' }}>{comaSeperated(viewData?.installments_amount)}</span></li>




                    </ul>

                  </>
                  : null}
          </div>
        </div>
      </div>
      <UploadReportModal
        show={openuploadModal}
        onHide={() => setopenuploadModal(false)}
        viewData={selectedReport}
        {...props}
      />
    </div>
  );
};

export default ViewTestDetails;







// <div className="d-flex justify-content-between">
// <div className="col-lg-8">
//   <h5>{t("dashboards.alldashboardkeys.tablekeys.testdetails")}</h5>
// </div>
// <div className="col-lg-4 mb-3">
//   {viewData?.tests?.length > 0 &&
//     viewData?.paid_status?.name === "unpaid" ? (
//     <>
//       {loader ? <Loader
//         height={20}
//         width={20}
//         type="Oval"
//         color="#1a4774"
//         className="text-center"
//       /> :
//         <><DashboardBtn
//         label={`${t("dashboards.alldashboardkeys.tablekeys.paybtn")} (${comaSeperated(viewData?.total_amount)}) `}
//           className="btn btnSquare w-100"
//           onClick={async () => {
//             const data = await dispatch(
//               pharmacyPay({ id: viewData?.id, partial_payment: false }, true, viewData?.id)
//             );

//             if (data) {
//               toast.success("Successfully Paid", "Success")

//               props?.history?.push("/nurse-dashboard");

//             }


//           }}
//         />
//           <div style={{ height: '5px' }} />
//           <DashboardBtn
//            label={`${t("dashboards.alldashboardkeys.tablekeys.partiallypay")}  (${comaSeperated(viewData?.installments_amount)}) `}
//             className="btn btnSquare w-100  mb-1"
//             onClick={async () => {
//               const data = await dispatch(
//                 pharmacyPay({ id: viewData?.id, partial_payment: true }, true, viewData?.id)
//               );
//               if (data) {
//                 toast.success("Successfully Paid", "Success")

//                 props?.history?.push("/nurse-dashboard");

//               }


//             }}
//           />
//         </>}


//     </>
//   ) : viewData?.paid_status?.name === "partial" ?
//     loader ? <Loader
//       height={20}
//       width={20}
//       type="Oval"
//       color="#1a4774"
//       className="text-center"

//     /> : <DashboardBtn
//     label={`${t("dashboards.alldashboardkeys.tablekeys.paybtn")} (${comaSeperated(viewData?.total_amount)}) `}

//       className="btn btnSquare w-100 mb-1"
//       onClick={async () => {
//         const data = await dispatch(
//           pharmacyPay({ id: viewData?.id, partial_payment: false }, true, viewData?.id)
//         );
//         if (data) {
//           props?.history?.push("/nurse-dashboard");

//         }
//         // if (viewData?.from) {
//         //   setviewData({ ...data, from: "administrator" });
//         // }
//         // else {
//         //   setviewData(data);
//         // }

//       }}
//     />
//     : null}
// </div>
// </div>