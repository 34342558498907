import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";
import DashboardHeaderAndTile from "../../components/layouts/dashboardheaderandtile/DashboardHeaderAndTile";
import nurse from "../../statics/icons/nurse.svg"
import NurseDashboardTests from "./NurseDashboardTests";

const NurseDashboard = (props) => {
  const {t} = useTranslation("common")
  // const FromSlots = props?.location?.state?.key;
  const [selectedTab, setSelectedTab] = useState();
  // const userRole = useSelector((state) => state?.logIn?.userRole);

  const tabHandler = (item) => {
    setSelectedTab(item);
  };
  useEffect(() => {
 
    tabHandler(t("dashboards.alldashboardkeys.tablekeys.tes"));
    // eslint-disable-next-line
  }, []);
  // t("dashboards.alldashboardkeys.tablekeys.tes")
  return (
    <>
       <Helmet>
        <title>{t("dashboards.alldashboardkeys.maintitle")}</title>
      </Helmet>
    <div style={{ backgroundColor: "#f9f9f9" }}>
   
  
        <DashboardHeaderAndTile
          heading={t("dashboards.alldashboardkeys.nursemainheading1")}
          options={[t("dashboards.alldashboardkeys.tablekeys.tes")]}
          icons={[nurse]}
          tabHandler={tabHandler}
          value={selectedTab}
        />
      <div className="container">
        {selectedTab === t("dashboards.alldashboardkeys.tablekeys.tes") ? (
          <>
            <div className="pb-5">
              <NurseDashboardTests {...props} />
            </div>
          </>
        ) : null}
      </div>
    </div>
    </>
  );
};

export default NurseDashboard;
