import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
// import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import { cancelOrder } from "../../store/actions/patientActions";

import { getAllOrders } from "../../store/actions/pharmacyActions";
import { checkPaidOrUnpaid, comaSeperated } from "../../utils/functions";

const PatientOrders = (props) => {
  const { t } = useTranslation("common");

  // const loader = useSelector((state) => state?.patient?.patientLoader);
  const orders = useSelector((state) => state?.pharmacy?.orders);
  const loader = useSelector((state) => state?.pharmacy?.loader);
  const [show, setShow] = useState(false);
  const [id, setID] = useState("");
  const user = useSelector((state) => state?.logIn?.user);

  const dispatch = useDispatch();
  useEffect(() => {
    const apiData = {
      id: user?.id,
    };
    if (orders?.length < 1) {
      dispatch(getAllOrders(apiData));
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = async (value, row) => {
    if (value === "View") {
      let item = { ...row, from: "patientOrders" };
      props?.history?.push("/Order-details", item);
    } else if (value == "Cancel") {
      if (
        row?.delivery_status?.name == "processing" &&
        row?.paid_status?.name == "paid"
      ) {
        let res = await dispatch(
          cancelOrder({ order_id: row?.id, is_returned: true })
        );
        if (res) dispatch(getAllOrders({ id: user?.id }));
      } else if (
        row?.delivery_status?.name == "processing" &&
        row?.paid_status?.name == "unpaid"
      ) {
        let res = await dispatch(
          cancelOrder({ order_id: row?.id, is_returned: true })
        );
        if (res) dispatch(getAllOrders({ id: user?.id }));
      } else if (
        row?.delivery_status?.name == "delivered" &&
        row?.paid_status?.name == "unpaid"
      ) {
        let res = await dispatch(
          cancelOrder({ order_id: row?.id, is_returned: false })
        );
        if (res) dispatch(getAllOrders({ id: user?.id }));
      } else if (
        row?.delivery_status?.name == "delivered" &&
        row?.paid_status?.name == "paid"
      ) {
        let res = await dispatch(
          cancelOrder({ order_id: row?.id, is_returned: false })
        );
        if (res) dispatch(getAllOrders({ id: user?.id }));
      } else if (
        row?.delivery_status?.name == "dispatched" &&
        row?.paid_status?.name == "paid"
      ) {
        let res = await dispatch(
          cancelOrder({ order_id: row?.id, is_returned: false })
        );
        if (res) dispatch(getAllOrders({ id: user?.id }));
      } else {
        let res = await dispatch(
          cancelOrder({ order_id: row?.id, is_returned: false })
        );
        if (res) dispatch(getAllOrders({ id: user?.id }));
      }
    } else if (value == "Cancel/Refund") {
      setID(row?.id);
      setShow(!show);
    }
  };
  const defaultSorting = [
    {
      dataField: "date",
      order: "asc",
    },
  ];
  // t("dashboards.alldashboardkeys.tablekeys.myorder")
  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">
            {" "}
            {t("dashboards.alldashboardkeys.tablekeys.myorder")}
          </h4>
        </div>
      </div>
      <div className="row">
        {loader ? (
          <LoderContainer />
        ) : (
          <BootstrapCustomTable
            columns={[
              {
                formatter: (cell, row, index) => {
                  return <span>{index + 1}</span>;
                },
                text: "Sr.",
              },
              {
                dataField: "order_id",
                text: t("dashboards.alldashboardkeys.tablekeys.orderid"),
              },
              { dataField: "type", text: "Type" },

              {
                formatter: (cell, row, index) => {
                  return <span>{comaSeperated(row?.total_amount)}</span>;
                },
                text: t("dashboards.alldashboardkeys.tablekeys.ammount"),
              },
              {
                formatter: (cell, row, index) => {
                  return <span>{comaSeperated(row?.remaining_amount)}</span>;
                },
                dataField: "remaining_amount",
                text: "Remaining Amount",
              },
              {
                formatter: (cell, row, index) => {
                  return <span>{comaSeperated(row?.installments_amount)}</span>;
                },
                dataField: "installments_amount",
                text: "Installment Amount",
              },

              {
                formatter: (cell, row, index) => {
                  return <span>{comaSeperated(row?.paid_amount)}</span>;
                },
                dataField: "paid_amount",
                text: "Paid Amount",
              },
              {
                formatter: (cell, row) => {
                  return (
                    <span>{`${
                      row?.is_refunded ? row?.refunded_amount : "0"
                    }`}</span>
                  );
                },
                text: "Refund Amount",
              },
              {
                formatter: (cell, row, index) => {
                  return (
                    <span
                      style={{
                        color:
                          row?.delivery_status.name == "cancelled"
                            ? "red"
                            : "green",
                      }}
                    >
                      {row?.delivery_status.name?.toUpperCase()}
                    </span>
                  );
                },
                text: "Delivery Status",
              },

              {
                formatter: (cell, row, index) => {
                  return (
                    <span
                      style={{
                        color: checkPaidOrUnpaid(row?.paid_status.name),
                      }}
                    >
                      {row?.paid_status.name?.toUpperCase()}
                    </span>
                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.paymentstatus"),
              },
              {
                formatter: (cell, row, index) => {
                  return ((row?.paid_status?.name == "paid" ||
                    row?.paid_status?.name == "partial") &&
                    row?.delivery_status?.name == "processing") ||
                    row?.delivery_status?.name == "dispatched" ||
                    row?.delivery_status?.name == "pending" ? (
                    <ActionBtn
                      // options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                      options={[
                        t("dashboards.alldashboardkeys.tablekeys.viewbtn"),
                        "Cancel",
                      ]}
                      handleChange={handleChange}
                      data={row}
                    />
                  ) : (row?.paid_status?.name == "unpaid" &&
                      row?.delivery_status?.name == "processing") ||
                    row?.delivery_status?.name == "dispatched" ||
                    row?.delivery_status?.name == "pending" ? (
                    <ActionBtn
                      // options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                      options={[
                        t("dashboards.alldashboardkeys.tablekeys.viewbtn"),
                        "Cancel",
                      ]}
                      handleChange={handleChange}
                      data={row}
                    />
                  ) : row?.paid_status?.name == "unpaid" &&
                    row?.delivery_status?.name != "delivered" ? (
                    <ActionBtn
                      // options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                      options={[
                        t("dashboards.alldashboardkeys.tablekeys.viewbtn"),
                      ]}
                      handleChange={handleChange}
                      data={row}
                    />
                  ) : (
                    <ActionBtn
                      // options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                      options={[
                        t("dashboards.alldashboardkeys.tablekeys.viewbtn"),
                      ]}
                      handleChange={handleChange}
                      data={row}
                    />
                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.action"),
              },
            ]}
            tabledata={orders}
          />
        )}
      </div>
    </div>
  );
};

export default PatientOrders;
