import { React, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import 'react-phone-input-2/lib/style.css'

import { Form } from "react-bootstrap";
import logo from "../../statics/toticellimages/profile-logo.png";
import { useDispatch, useSelector } from "react-redux";
import DashboardBtnList from "../../components/reuseablecomponents/DashboardBtnList";
import { fetchStaticData } from "../../store/actions/staticActions";
import { updatePatient } from "../../store/actions/patientActions";
import axios from "axios";
import { BASEURL } from "../../services/HttpProvider";
import { setUser } from "../../store/actions/authActions";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../utils/functions";
import countryList from "react-select-country-list";

export default function PatientProfile() {
  const {t} = useTranslation("common")
  const user = useSelector((state) => state?.logIn?.user);
  const [loader, setLoader] = useState(false);
  const [alternatePhone, setAlternatePhone] = useState(user?.alternate_phone_number);
  const options = useMemo(() => countryList().getData(), []);


  const dispatch = useDispatch();
  const staticData = useSelector((state) => state?.static?.staticData);
  const [editProfile, setEditProfile] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  useEffect(() => {
    dispatch(fetchStaticData());
  }, [dispatch]);
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm();
  const onSubmitHandler = (data) => {
    setEditProfile(false);
    const apiData = {
      id: user?.id,
      name: data?.name,
      address: {
        house_number: data?.housenumber || "-",
        villa_or_apartment: "-",
        area: "-",
        street: data?.street || "-",
        city: data?.city || "-",
        country: data?.country || "-",
        pobox: "-",
      },
      gender: JSON.parse(data?.gender),
      //   date_of_birth: "1998-08-05",

      nationality: JSON.parse(data?.nationality),

      communication_mode:
        data?.commmunicationMode === "sms"
          ? { id: 1, name: "sms" }
          : { id: 2, name: "email" },

      alternate_phone_number: "+" + alternatePhone ? alternatePhone : user?.alternate_phone_number,
      nic_passport: data?.nicPassport,
      description: "",
      social_profiles: {},
      payment_details: {},
      preferred_delivery_time: {},
      date_of_birth:data?.date_of_birth

    };
    dispatch(updatePatient(apiData));
  };
  const fileSelectHandler = (e) => {
    const file = e.target.files;
    setSelectedFile(file[0]);
  };
  const uploadHandler = async () => {
    let formdata = new FormData();
    const authToken = JSON.parse(localStorage.getItem("toticelltoken"));
    if (
      selectedFile === undefined ||
      selectedFile === null ||
      selectedFile === ""
    ) {
      toast.error(t("toastmessages.pleaseselectimage"));
      return;
    } else {
      setLoader(true)

      formdata.append("display_picture", selectedFile);
      formdata.append("id", user?.id);
      let res = await axios.post(
        `${BASEURL}upload/user/display-picture`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-session-key": authToken.key,
            "x-session-type": authToken.type,
          },
        }
      );
      if (res?.data?.response_code === 200) {
        dispatch(setUser(res?.data?.response_data?.user));
        setLoader(false)

        toast.success(res?.data?.response_message);

        setSelectedFile("");
      } else {
        setLoader(false)

        toast.error(res?.data?.response_message);
      }
      setLoader(false)

    }
  };
  return (
    <div class="container rounded bg-white mt-5 mb-5 ">
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div class="row">
          <div class="col-md-3 border-right">
            <div class="d-flex flex-column align-items-center text-center p-3 pt-5">
              <img alt="alt" class="rounded-circle mt-5" width={150} height={150} style={{ borderRadius: '50%' }} src={user?.display_picture !== "" ? user?.display_picture : logo} />
              <span class="font-weight-bold">{user?.name}</span>
              <span class="text-black-50">{user?.email_address}</span>
            </div>
              {selectedFile ? (
                <>
                  <span>{selectedFile?.name}</span>
                  &nbsp;
                  <span
                    style={{
                      fontSize: "16px",
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={() => setSelectedFile("")}
                    className="btn btn-danger px-4 mr-1"
                  >
                    x
                  </span>
                  <span className="btn btn-primary" onClick={uploadHandler}>
                  {t("dashboards.alldashboardkeys.tablekeys.upload")}
                  </span>
                </>
              ) : (
                <input
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="image/png, image/jpeg"
                  onChange={fileSelectHandler}
                />
              )}
          </div>
          <div class="col-md-9 border-right containerWithShadow">
            <div class="p-3 py-5">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h4 class="text-right">{t("dashboards.alldashboardkeys.tablekeys.profilesetting")}</h4>
              </div>
              <div class="row mt-2">
                <div className="col-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>{t("dashboards.alldashboardkeys.tablekeys.name")}</span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <input
                          style={{ color: 'black' }}

                          className="input-login-modal"
                          defaultValue={user?.name}
                          {...register("name", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        />
                        {errors?.name?.message ? (
                          <div className="text-error">
                            {errors?.name?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">{user?.name}</span>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>{t("dashboards.alldashboardkeys.tablekeys.email")}</span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <input
                          style={{ color: 'black' }}

                          className="input-login-modal"
                          value={user?.email_address}
                          disabled
                        />
                      </>
                    ) : (
                      <span className="mb-2">{user?.email_address}</span>
                    )}
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div className="col-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>{t("dashboards.alldashboardkeys.tablekeys.contactnumber")}</span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>

                        <PhoneInput
                          country={'bd'}
                          value={user?.phone_number}
                          disabled={true}
                        // onChange={(phone) => setRelativPhoneNumber(phone)}
                        />
                        {/* <input
                          style={{color:'black'}}

                          className="input-login-modal"
                          value={user?.phone_number}
                          disabled
                        /> */}
                      </>
                    ) : (
                      <span className="mb-2">{user?.phone_number}</span>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>{t("dashboards.alldashboardkeys.tablekeys.street")}</span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <input
                          style={{ color: 'black' }}

                          className="input-login-modal"
                          defaultValue={user?.address?.street}
                          {...register("street", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        />
                        {errors?.street?.message ? (
                          <div className="text-error">
                            {errors?.street?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">{user?.address?.street}</span>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>{t("dashboards.alldashboardkeys.tablekeys.city")}</span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <input
                          style={{ color: 'black' }}

                          className="input-login-modal"
                          defaultValue={user?.address?.city}
                          {...register("city", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        />
                        {errors?.city?.message ? (
                          <div className="text-error">
                            {errors?.city?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">{user?.address?.city}</span>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>{t("dashboards.alldashboardkeys.tablekeys.country")}</span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                      <Form.Control
                        as="select"
                        style={{ color: "black" }}
                        className="input-login-modal"
                        defaultValue={user?.address?.country}
                        custom
                        {...register("country", {
                          required: {
                            value: false,
                            message: "this field is required field",
                          },
                        })}
                      >
                        {options?.map((item, index) => (
                          <option value={item?.label}>{item?.label}</option>
                        ))}
                      </Form.Control>
                      {errors?.country?.message ? (
                        <div className="text-error">
                          {errors?.country?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                    ) : (
                      <span className="mb-2">{user?.address?.country}</span>
                    )}
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>{t("dashboards.alldashboardkeys.tablekeys.alternatephonenumber")}</span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <PhoneInput
                          // country={'bd'}
                          value={user?.alternate_phone_number}
                          onChange={(phone) => setAlternatePhone(phone)}

                        />
                        {/* <input
                          style={{ color: 'black' }}

                          className="input-login-modal"
                          defaultValue={user?.alternate_phone_number}
                          {...register("alternatePhone", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        /> */}
                        {errors?.alternatePhone?.message ? (
                          <div className="text-error">
                            {errors?.alternatePhone?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">
                        {user?.alternate_phone_number}
                      </span>
                    )}
                  </div>
                </div>
                <div class="col-md-6">
                  <div className="patient-dashboard-text-field-label">
                    <span style={{ fontWeight: "bold" }}>{t("dashboards.alldashboardkeys.tablekeys.nationality")}</span>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <Form.Control
                          as="select"
                          style={{ color: 'black' }}

                          className="input-login-modal"
                          defaultValue={user?.nationality?.name}
                          custom
                          {...register("nationality", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        >
                          {staticData?.user_nationality?.map((item, index) => (
                            <option value={JSON.stringify(item)}>
                              {item?.name}
                            </option>
                          ))}
                        </Form.Control>

                        {errors?.nationality?.message ? (
                          <div className="text-error">
                            {errors?.nationality?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">{capitalize(user?.nationality?.name)}</span>
                    )}
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div className="col-md-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>{t("dashboards.alldashboardkeys.tablekeys.gender")}</span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <Form.Control
                          as="select"
                          style={{ color: 'black' }}

                          className="input-login-modal"
                          defaultValue={user?.gender?.name}
                          custom
                          {...register("gender", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        >
                          {staticData?.gender?.map((item, index) => (
                            <option
                              value={JSON.stringify(item)}
                              key={index + 1}
                            >
                              {item?.name}
                            </option>
                          ))}
                        </Form.Control>

                        {errors?.gender?.message ? (
                          <div className="text-error">
                            {errors?.gender?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">{capitalize(user?.gender?.name)}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>{"Date Of Birth"}</span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <input
                          style={{ color: 'black' }}
                          type="date"
                          className="input-login-modal"
                          defaultValue={user?.date_of_birth}
                          {...register("date_of_birth", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        />
                        {errors?.date_of_birth?.message ? (
                          <div className="text-error">
                            {errors?.date_of_birth?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">{user?.date_of_birth}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>{t("dashboards.alldashboardkeys.tablekeys.nicpassport")}</span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <input
                          style={{ color: 'black' }}

                          className="input-login-modal"
                          defaultValue={user?.nic_passport}
                          {...register("nicPassport", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        />
                        {errors?.nicPassport?.message ? (
                          <div className="text-error">
                            {errors?.nicPassport?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">{user?.nic_passport}</span>
                    )}
                  </div>
                </div>
               
              </div>
            </div>
            <div class="mt-2 text-center">
              <div className="row my-2">
                <div className="col-lg-2 col-md-4 col-sm-12 px-0 mx-2">
                  {editProfile ? (
                    <input
                      type="submit"
                      value={t("dashboards.alldashboardkeys.tablekeys.updateprofile")}
                      className="btn btnSquare"
                    // onClick={() => setEditProfile(false)}
                    />
                  ) : (
                    <button
                      className="btn btnSquare w-100 ml-2"
                      onClick={() => setEditProfile(true)}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.editprofile")}
                    </button>
                  )} 
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
