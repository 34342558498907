import React, { useState } from "react";
import { Link } from "react-router-dom";
import useMediaQuery from "../../reuseablecomponents/MediaQueryHook";
import "./SearchBar.css";
import ReactTooltip from "react-tooltip";

function SearchBar({ placeholder, data }) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const isDesktop = useMediaQuery('(min-width: 992px)');
  const isMacDesktop = useMediaQuery('(max-width: 1300px)');

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
   
    const newFilter = data.filter((value) => {
      return value?.subLinkName.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  return (
    <>
   <ReactTooltip id="title required" effect="solid" place="bottom">
        Enter Something To Search..
      </ReactTooltip>
      <div className="search">
        <div className="input-group mb-3" 
        style={{width: isDesktop ?  isMacDesktop ? '170px' : '220px' : '270px'}}

        >
          <input
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            className="form-control form-control_custom"
            type="text"
            data-tip
            data-for="title required"
            placeholder={placeholder}
            value={wordEntered}
            onChange={handleFilter}
          />
          <div className="input-group-append">
            {filteredData.length === 0 ? (
              
              <span
            className="input-group-text input_text_custom"
            id="basic-addon2"
            style={{cursor: 'pointer'}}
          >
            <i className="fa fa-search" ></i>
          </span>
            ) : (

                <span
                className="input-group-text input_text_custom"
                id="basic-addon2"
                style={{cursor: 'pointer'}}
              >
                <i className="fa fa-close"  onClick={clearInput}></i>
              </span>
            )}
        </div>
        </div>
        {filteredData.length !== 0 && (
          <div className="dataResult">
            {filteredData.slice(0, 15).map((value, key) => {
              return (
                <Link
                  to={value.subLink}
                  style={{ textDecoration: "none" }}
                  className="dataItem"
                >
                    
                  <p>{value.subLinkName}</p>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default SearchBar;
