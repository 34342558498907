import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../components/layouts/bannerImgComponents/BannerImgComponents";
import { banner, LinksBanInternational } from "../../data";
import { filterComponentData } from "../../utils/functions";

const InternationalPatient = () => {
  const { t } = useTranslation("common");
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const banners = filterComponentData(component, "banner", lang);
  const internationalPatientMetaTitle = filterComponentData(
    component,
    "international_patient_meta_title",
    lang
  );
  const internationalPatientSchema = filterComponentData(
    component,
    "international_patient_schema_markups",
    lang
  );
  const internationalPatientMetaDesc = filterComponentData(
    component,
    "international_patient_meta_description",
    lang
  );
  const internationalPatientMetaKeyword = filterComponentData(
    component,
    "international_patient_meta_keywords",
    lang
  );
  const internationalPatientVerification = filterComponentData(
    component,
    "international_patient_verification_meta_tags",
    lang
  );
  const LinksBanInternational = [
    {
      subLinkName: banners[61]?.subtitle?.split(">")[0]
        ? banners[61]?.subtitle?.split(">")[0]
        : t("internationalpatients.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[61]?.subtitle?.split(">")[1]
        ? banners[61]?.subtitle?.split(">")[1]
        : t("internationalpatients.banner.title"),
      subDash: "",
      subLink: "/internationalpatient",
    },
  ];
  const verificationsTags = internationalPatientVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.name,
      content: item?.content,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}
        {internationalPatientSchema?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.intPatient ? item?.intPatient : "[]")
            )}
          </script>
        ))}
        <title>
          {internationalPatientMetaTitle?.internationalMetaTitle
            ? internationalPatientMetaTitle?.internationalMetaTitle
            : t("internationalpatients.maintitle")}
        </title>
        <meta
          name="description"
          content={
            internationalPatientMetaDesc?.intPatient
              ? internationalPatientMetaDesc?.intPatient
              : t("internationalpatients.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            internationalPatientMetaKeyword?.intPatient
              ? internationalPatientMetaKeyword?.intPatient
              : t("internationalpatients.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[61]?.heading
              ? banners[61]?.heading
              : t("internationalpatients.banner.title")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[61]?.image ? banners[61]?.image : banner
          })`}
          imgSrc={banner}
          LinksBan={LinksBanInternational}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container">
          <div className="row ">
            <div className="col-lg-12 my-5">
              {t("internationalpatients.txt")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InternationalPatient;
