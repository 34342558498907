import React from "react";
import "./fixedRL.css";
import covid from "../../../statics/toticellimages/coronavirus.png";
import useMediaQuery from "../../reuseablecomponents/MediaQueryHook";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../utils/functions";
const FixedRL = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const footer = filterComponentData(component, "footer", lang);
  const fixedLeftRelAttribute = filterComponentData(
    component,
    "fixed_left_rel_attribute_tags",
    lang
  );
  const isDesktoplg = useMediaQuery("(min-width: 768px)");
  const { t } = useTranslation("common");
  return (
    <div>
      {isDesktoplg ? (
        <div>
          <ul id="social_side_links">
            <li>
              <a
                // rel="noreferrer"
                rel={
                  fixedLeftRelAttribute?.linkedin
                    ? fixedLeftRelAttribute?.linkedin
                    : "noreferrer"
                }
                className="twitter"
                href={
                  footer?.linkedin
                    ? footer?.linkedin
                    : "https://www.linkedin.com/in/toticellbd"
                }
              >
                <i className="fa fa-linkedin" />
              </a>
            </li>
            <li>
              <a
                // rel="noreferrer"
                rel={
                  fixedLeftRelAttribute?.linkedin
                    ? fixedLeftRelAttribute?.linkedin
                    : "noreferrer"
                }
                className="facebook"
                href={
                  footer?.facebook
                    ? footer?.facebook
                    : "https://www.facebook.com/toticell.bd"
                }
              >
                <i className="fa fa-facebook" />
              </a>
            </li>
            <li>
              <a
                className="twitter"
                rel={
                  fixedLeftRelAttribute?.twitter
                    ? fixedLeftRelAttribute?.twitter
                    : "noreferrer"
                }
                href={
                  footer?.twitter
                    ? footer?.twitter
                    : "https://twitter.com/ToticellR"
                }
              >
                <i className="fa fa-twitter" />
              </a>
            </li>
            <li>
              <a
                // rel="noreferrer"
                rel={
                  fixedLeftRelAttribute?.instagram
                    ? fixedLeftRelAttribute?.instagram
                    : "noreferrer"
                }
                className="instagram"
                href={
                  footer?.insta
                    ? footer?.insta
                    : "https://www.instagram.com/toticellbd/"
                }
              >
                <i className="fa fa-instagram" />
              </a>
            </li>
            <li>
              <a
                // rel="noreferrer"
                rel={
                  fixedLeftRelAttribute?.youtube
                    ? fixedLeftRelAttribute?.youtube
                    : "noreferrer"
                }
                className="youtube"
                href={
                  footer?.youtube
                    ? footer?.youtube
                    : "https://www.youtube.com/channel/UCSkTmrgEzVvjTcBF0fsb4Og"
                }
              >
                <i className="fa fa-youtube-play" />
              </a>
            </li>
          </ul>
          <a
            rel="noreferrer"
            href="tel:09606213234"
            className="float"
            title="Hotline: 0960 6213 234"
          >
            <i className="fa fa-phone my-float" />
          </a>
          <div className="label-container" style={{ display: "none" }}>
            <div className="label-text ">
              <span>Hotline</span> 0960 6213 234
            </div>
            <i className="fa fa-play label-arrow" />
          </div>
          <div className="avatar-whatsapp">
            <a
              // rel="noreferrer"
              rel={
                fixedLeftRelAttribute?.whatsapp
                  ? fixedLeftRelAttribute?.whatsapp
                  : "noreferrer"
              }
              href="https://api.whatsapp.com/send?phone=+8801958073604&amp;text=Say%20Hello!&amp;source=&amp;data="
              target="_blank"
            >
              <i className="fa fa-whatsapp" />
            </a>
          </div>
          <div className="covidmnpt">
            <a
              // rel="noreferrer"
              rel={
                fixedLeftRelAttribute?.covidinfo
                  ? fixedLeftRelAttribute?.covidinfo
                  : "noreferrer"
              }
              href="https://www.worldometers.info/coronavirus/?utm_campaign=homeAdvegas1"
            >
              <img src={covid} alt="covid" style={{ width: "63px" }} />
              <span>
                {footer?.covid19slider
                  ? footer?.covid19slider
                  : t("fixedRight.covidinfo")}
              </span>
            </a>
          </div>
          <div
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
            class="cd-top cd-is-visible cd-fade-out"
          >
            Top
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FixedRL;
