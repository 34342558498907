import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import moment from "moment";
import { patientPrescriptions } from "../../store/actions/patientActions";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import { useTranslation } from "react-i18next";
const PatientPrescriptionContainer = (props) => {
  const {t} = useTranslation("common")
  const prescriptions = useSelector((state) => state?.patient?.prescriptions);
  const loader = useSelector((state) => state?.patient?.patientLoader);
  const user = useSelector((state) => state?.logIn?.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      patientPrescriptions({
        id: user?.id,
      })
    );
    // eslint-disable-next-line
  }, []);
  const handleChange = (value, data) => {
    props?.history?.push("/patient-prescription-details", data);
  };
  // t("dashboards.alldashboardkeys.tablekeys.action")
  return (
    <>
      {loader ? (
        <LoderContainer />
      ) : (
        <>
          <div className="w-100">
            <div className="p-3">
              <span className="dashboardHeadingText">{t("dashboards.alldashboardkeys.tablekeys.prescription")}</span>
            </div>
            <BootstrapCustomTable
              columns={[
                {
                  formatter: (cell, row, index) => {
                    return <span>{index + 1}</span>;
                  },
                  text: "Sr.",
                },
                {
                  formatter: (cell, row) => {
                    return <span>{moment(row.date).format("DD/MM/YYYY")}</span>;
                  },
                  text:t("dashboards.alldashboardkeys.tablekeys.date"),
                },
                { dataField: "doctor_details.name", text: t("dashboards.alldashboardkeys.tablekeys.doctorname") },
                { dataField: "diagnosis", text: t("dashboards.alldashboardkeys.tablekeys.description") },
                {
                  formatter: (cell, row, index) => {
                    return (
                      <ActionBtn
                        options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                        handleChange={handleChange}
                        data={row}
                      />
                      // <div className="w-100  center-children-class">
                      //   <select
                      //     className="action-select-border  w-100"
                      //     onChange={(e) => handleChange(e.target.value, row)}
                      //     value="Action :"
                      //   >
                      //     <option selected>Actions:</option>
                      //     <option value="1">View</option>
                      //   </select>
                      // </div>
                    );
                  },
                  text: t("dashboards.alldashboardkeys.tablekeys.action"),
                },
              ]}
              tabledata={prescriptions}
            />
          </div>
        </>
      )}
    </>
  );
};

export default PatientPrescriptionContainer;
