import React, { useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import logo from "../../../statics/icons/Toticell_logo1.png";
import payp from "../../../statics/icons/payp1.png";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useMediaQuery from "../../reuseablecomponents/MediaQueryHook";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../utils/functions";

const Footer = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const footer = filterComponentData(component, "footer", lang);
  const footerRelAttribute = filterComponentData(
    component,
    "footer_rel_attribute_tags",
    lang
  );
  const { t } = useTranslation("common");
  const [NewsLetter, setNewsLetter] = useState("");
  const newsLetterHandler = () => {
    toast.success(t("toastmessages.subscribednewsletter"));
    setNewsLetter("");
  };
  const isDesktop = useMediaQuery("(min-width: 992px)");

  return (
    <>
      <div className="backg">
        <div className="container">
          <footer className="text-center text-lg-start text-white">
            <div className="container p-1">
              <div className="row mt-4">
                <div class={isDesktop ? "col-md-4" : "col-md-6"}>
                  <div class="footer-logo d-flex justify-content-start">
                    <img
                      src={footer?.logo ? footer?.logo : logo}
                      alt="Toticell Logo"
                      style={{ width: "166px" }}
                    />
                  </div>
                  <div className="about-footer text-justify">
                    <p>{footer?.desc ? footer?.desc : t("footer.text1")}</p>
                    <h4 style={{ color: "#fff", fontSize: "18px", margin: "" }}>
                      <i class="fa fa-phone"></i>{" "}
                      {footer?.emergencycase
                        ? footer?.emergencycase
                        : t("footer.footemergency")}{" "}
                      &nbsp;
                      <Link to="#" style={{ textDecoration: "none" }}>
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          {footer?.contact
                            ? footer?.contact
                            : t("footer.footertxt5")}
                        </span>
                      </Link>
                    </h4>
                  </div>
                  <div class="social-footer  ">
                    <div className="d-flex justify-content-start">
                      <h6 style={{ color: "#fff", fontSize: "18px" }}>
                        {footer?.followus
                          ? footer?.followus
                          : t("footer.text2")}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-start">
                      <div class="social-items">
                        <a
                          // rel="noreferrer"
                          rel={
                            footerRelAttribute?.linkedin
                              ? footerRelAttribute?.linkedin
                              : "noreferrer"
                          }
                          href={
                            footer?.linkedin
                              ? footer?.linkedin
                              : "https://www.linkedin.com/in/toticellbd"
                          }
                          target="_blanl"
                        >
                          <div class="icon-fa">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </div>
                        </a>
                      </div>
                      <div class="social-items">
                        <a
                          // rel="noreferrer"
                          rel={
                            footerRelAttribute?.facebook
                              ? footerRelAttribute?.facebook
                              : "noreferrer"
                          }
                          href={
                            footer?.facebook
                              ? footer?.facebook
                              : "https://www.facebook.com/toticell.bd"
                          }
                          target="_blank"
                        >
                          <div class="icon-fa">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                          </div>
                        </a>
                      </div>
                      <div class="social-items">
                        <a
                          // rel="noreferrer"
                          rel={
                            footerRelAttribute?.twitter
                              ? footerRelAttribute?.twitter
                              : "noreferrer"
                          }
                          href={
                            footer?.twitter
                              ? footer?.twitter
                              : "https://twitter.com/ToticellR"
                          }
                          target="_blank"
                        >
                          <div class="icon-fa">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </div>
                        </a>
                      </div>
                      <div class="social-items">
                        <a
                          // rel="noreferrer"
                          rel={
                            footerRelAttribute?.instagram
                              ? footerRelAttribute?.instagram
                              : "noreferrer"
                          }
                          href={
                            footer?.insta
                              ? footer?.insta
                              : "https://www.instagram.com/toticellbd/"
                          }
                          target="_blank"
                        >
                          <div class="icon-fa">
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </div>
                        </a>
                      </div>
                      <div class="social-items">
                        <a
                          // rel="noreferrer"
                          rel={
                            footerRelAttribute?.youtube
                              ? footerRelAttribute?.youtube
                              : "noreferrer"
                          }
                          href={
                            footer?.youtube
                              ? footer?.youtube
                              : "https://www.youtube.com/channel/UCSkTmrgEzVvjTcBF0fsb4Og"
                          }
                          target="_blank"
                        >
                          <div class="icon-fa">
                            <i
                              class="fa fa-youtube-play"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class={isDesktop ? "col-md-4" : "col-md-6"}>
                  <h6
                    style={{ color: "#fff", fontSize: "18px" }}
                    className="text-left mt-2"
                  >
                    {footer?.quicklink
                      ? footer?.quicklink
                      : t("footer.footertxt1")}
                  </h6>
                  <div class="footer-categories">
                    <div className="row">
                      <div className={"col-sm-5"}>
                        <div class="categories-list text-left ">
                          <div class="categoties-items ">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                            <Link to="/abouttoti">
                              {footer?.whytoti
                                ? footer?.whytoti
                                : t("footer.footertxt2")}
                            </Link>
                          </div>
                          <div class="categoties-items">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                            <Link to="/facilities">
                              {footer?.facilities
                                ? footer?.facilities
                                : t("footer.footertxt3")}
                            </Link>
                          </div>
                          <div class="categoties-items">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                            <a href="/statics/Brochure.pdf" download>
                              {footer?.brocher
                                ? footer?.brocher
                                : t("footer.footertxt4")}
                            </a>
                          </div>
                          <div class="categoties-items">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                            <Link to="/our-doctors">
                              {footer?.team
                                ? footer?.team
                                : t("footer.footertxt6")}
                            </Link>
                          </div>
                          <div class="categoties-items">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                            <Link to="/stories">
                              {footer?.storeis
                                ? footer?.storeis
                                : t("footer.footertxt7")}
                            </Link>
                          </div>
                          <div class="categoties-items">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                            <Link to="/privacy-policy">
                              {footer?.privacy
                                ? footer?.privacy
                                : "Privacy and Policy"}
                            </Link>
                          </div>
                          <div class="categoties-items">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                            <Link to="/delivery-policy">
                              {footer?.delivery
                                ? footer?.delivery
                                : "Delivery Policy"}
                            </Link>
                          </div>
                          <div class="categoties-items">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                            <Link to="/terms-conditions">
                              {footer?.terms
                                ? footer?.terms
                                : "Terms And Conditions"}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className={"col-sm-6"}>
                        <div class="categories-list-2 text-left ">
                          <div class="categoties-items ">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                            <Link to="/knowledge">
                              {footer?.kc ? footer?.kc : t("footer.footertxt8")}
                            </Link>
                          </div>
                          <div class="categoties-items">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                            <Link to="/career">
                              {footer?.career
                                ? footer?.career
                                : t("footer.footertxt9")}
                            </Link>
                          </div>
                          <div class="categoties-items">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                            <Link to="/media">
                              {footer?.media
                                ? footer?.media
                                : t("footer.footertxt10")}
                            </Link>
                          </div>
                          <div class="categoties-items">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                            <Link to="/internationalpatient">
                              {footer?.internationalp
                                ? footer?.internationalp
                                : t("footer.footertxt11")}
                            </Link>
                          </div>
                          <div class="categoties-items">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                            <Link to="/privacy-refund-policy">
                              {footer?.payment
                                ? footer?.payment
                                : "Payment And Refund Policy"}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {isDesktop ? (
                  <div class="col-md-4 text-left mt-2">
                    <div class="subscribe-footer">
                      <h6
                        style={{ color: "#fff", fontSize: "18px" }}
                        className="text-left"
                      >
                        {footer?.newsletter
                          ? footer?.newsletter
                          : t("footer.footertxt12")}
                      </h6>
                      <p>
                        {footer?.subscribe
                          ? footer?.subscribe
                          : t("footer.footertxt13")}
                      </p>
                      <div class="newsletter-box">
                        <input
                          type="email"
                          class="input"
                          id="email"
                          name="email"
                          placeholder={t("footer.footerplaceholder")}
                          required=""
                          value={NewsLetter}
                          onChange={(e) => {
                            setNewsLetter(e.target.value);
                          }}
                        />
                        <input
                          type="button"
                          class="button"
                          id="submit"
                          onClick={() => {
                            newsLetterHandler();
                          }}
                          value={
                            footer?.singupbutton
                              ? footer?.singupbutton
                              : t("footer.footerbtn")
                          }
                        />
                      </div>
                      <p class="font-italic">
                        {footer?.spamcon
                          ? footer?.spamcon
                          : t("footer.footertxt14")}
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="mt-2 text-center">
                <div className="col-12">
                  <img
                    src={footer?.imagePayment ? footer?.imagePayment : payp}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </div>
            </div>
          </footer>

          <div>
            <div></div>
          </div>
        </div>
      </div>

      <div className="FooterlastContainer">
        <div
          className="container"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <p className="FooterlastContainercolor m-1 text-white">
            {footer?.copyright ? footer?.copyright : t("footer.footertxt15")}

            <Link to="#" style={{ textDecoration: "none" }}>
              <span className="ml-1 text-white">
                {footer?.copyright ? null : t("footer.footertxt16")}
              </span>
            </Link>
          </p>
          <p className="FooterlastContainercolor m-1 text-white">
            <Link to="/about" className="text-white" href="about.php">
              {footer?.aboutbottom
                ? footer?.aboutbottom
                : t("footer.footertxt17")}
            </Link>{" "}
            &nbsp;/&nbsp;{" "}
            <Link to="/privacy-policy" className="text-white" href="#">
              {footer?.privacybotom ? (
                footer?.privacybotom
              ) : (
                <span>
                  {t("footer.footertxt18")} &amp; {t("footer.footertxt19")}
                </span>
              )}
            </Link>{" "}
            &nbsp;/&nbsp;{" "}
            <Link to="/contact-us" className="text-white">
              {footer?.contactbottom
                ? footer?.contactbottom
                : t("footer.footertxt20")}
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
