import "./App.css";
import "react-phone-input-2/lib/style.css";

import PatientDashboard from "./dashboard/patientdashboard/PatientDashboard";
import DoctorDashboard from "./dashboard/doctordashboard/DoctorDashboard";
import AppointmentDetails from "./dashboard/doctordashboard/AppointmentDetails";
import Prescription from "./dashboard/doctordashboard/PrescriptionDetails";
import AdministratorDashboard from "./dashboard/administrator/AdministratorDashboard";
import Pharmacy from "./dashboard/pharmacy/Pharmacy";
import PharmacyOrderDetails from "./dashboard/pharmacy/PaharmacyOrderDetail";
import PatientAppointmentDetails from "./dashboard/administrator/AppointmentDetails";
import PatientAppointmentDetail from "./dashboard/administrator/PatientAppointmentDetails";
import AdministratorUserProfile from "./dashboard/administrator/AdministratorProfile";
import DoctorUserProfile from "./dashboard/doctordashboard/DoctorProfile";
import PatientUserProfile from "./dashboard/patientdashboard/PatientProfile";
import NurseUserProfile from "./dashboard/labAdministrator/LabAdminProfile";
// import PharmacyUserProfile from "./dashboard/pharmacy/PharmacyProfile";

import "./style/index.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./components/reuseablecomponents/scrollTop";
import Home from "./pages/home/Home";
import Navbar from "./components/layouts/navbar/Navbar";
import Footer from "./components/layouts/footer/Footer";
import About from "./pages/about/About";
import AboutToticell from "./pages/about/aboutInnerPages/AboutToticell";
import Management from "./pages/about/aboutInnerPages/Management";
import Mission from "./pages/about/aboutInnerPages/Mission";
import OurDoctors from "./pages/about/aboutInnerPages/OurDoctors";
import Treatment from "./pages/treatment/Treatment";
import PRP from "./pages/treatment/treatmentInnerPages/PRP";
import SVC from "./pages/treatment/treatmentInnerPages/SVC";
import MSC from "./pages/treatment/treatmentInnerPages/MSC";
import CT from "./pages/treatment/treatmentInnerPages/CT";
import Speciality from "./pages/speciality/Speciality";
import CovidManagement from "./pages/speciality/specialityInnerPages/covidManagement/CovidManagement";
import MolecularDiagnosis from "./pages/speciality/specialityInnerPages/covidManagement/covidManagementInnerPage/MolecularDiagnosis";
import Serology from "./pages/speciality/specialityInnerPages/covidManagement/covidManagementInnerPage/Serology";
import CPT from "./pages/speciality/specialityInnerPages/covidManagement/covidManagementInnerPage/CPT";
import PainManagement from "./pages/speciality/specialityInnerPages/painManagement/PainManagement";
import KneePain from "./pages/speciality/specialityInnerPages/painManagement/painManagementInnerPages/KneePain";
import BackPain from "./pages/speciality/specialityInnerPages/painManagement/painManagementInnerPages/BackPain";
import ShoulderPain from "./pages/speciality/specialityInnerPages/painManagement/painManagementInnerPages/ShoulderPain";
import HeelPain from "./pages/speciality/specialityInnerPages/painManagement/painManagementInnerPages/HeelPain";
import AstheticMedicine from "./pages/speciality/specialityInnerPages/astheticMedicine/AstheticMedicine";
import ErectileDysfunction from "./pages/speciality/specialityInnerPages/astheticMedicine/astheticMedicineInnerPages/ErectileDysfunction";
import WomenIntimateHealth from "./pages/speciality/specialityInnerPages/astheticMedicine/astheticMedicineInnerPages/WomenIntimateHealth";
import HairLoss from "./pages/speciality/specialityInnerPages/astheticMedicine/astheticMedicineInnerPages/HairLoss";
import FacialRejuvenation from "./pages/speciality/specialityInnerPages/astheticMedicine/astheticMedicineInnerPages/FacialRejuvenation";
import NeurologicalRehabilitation from "./pages/speciality/specialityInnerPages/neurologicalRehabilitation/NeurologicalRehabilitation";
import Autism from "./pages/speciality/specialityInnerPages/neurologicalRehabilitation/neurologicalRehabilitationInnerPages/Autism";
import CerebralPalsy from "./pages/speciality/specialityInnerPages/neurologicalRehabilitation/neurologicalRehabilitationInnerPages/CerebralPalsy";
import MuscularDystrophy from "./pages/speciality/specialityInnerPages/neurologicalRehabilitation/neurologicalRehabilitationInnerPages/MuscularDystrophy";
import SpinalCordInjury from "./pages/speciality/specialityInnerPages/neurologicalRehabilitation/neurologicalRehabilitationInnerPages/SpinalCordInjury";
import DownSyndrome from "./pages/speciality/specialityInnerPages/neurologicalRehabilitation/neurologicalRehabilitationInnerPages/DownSyndrome";
import StemCellBanking from "./pages/speciality/specialityInnerPages/stemCellBanking/StemCellBanking";
import CordBloodBanking from "./pages/speciality/specialityInnerPages/stemCellBanking/stemCellBankingInnerPages/CordBloodBanking";
import CordTissueBanking from "./pages/speciality/specialityInnerPages/stemCellBanking/stemCellBankingInnerPages/CordTissueBanking";
import CommunityBanking from "./pages/speciality/specialityInnerPages/stemCellBanking/stemCellBankingInnerPages/CommunityBanking";
import FamilyBanking from "./pages/speciality/specialityInnerPages/stemCellBanking/stemCellBankingInnerPages/FamilyBanking";
import Diabetes from "./pages/speciality/specialityInnerPages/diabetes/Diabetes";
import DiabetesFootUlcer from "./pages/speciality/specialityInnerPages/diabetes/diabetesInnerPages/DiabetesFootUlcer";
import DryEyeDisease from "./pages/speciality/specialityInnerPages/diabetes/diabetesInnerPages/DryEyeDisease";
import BMC from "./pages/treatment/treatmentInnerPages/BMC";
import MNC from "./pages/treatment/treatmentInnerPages/MNC";
import ESWT from "./pages/treatment/treatmentInnerPages/ESWT";
import HBOT from "./pages/treatment/treatmentInnerPages/HBOT";
import StemCellTherapy from "./pages/speciality/specialityInnerPages/covidManagement/covidManagementInnerPage/StemCellTherapy";
import Evidence from "./pages/evidence/Evidence";
import Stories from "./pages/stories/Stories";
import ContactUs from "./pages/contactUs/ContactUs";
// import NotFound from "./pages/404/NotFound";
import PrivateRoute from "./utils/PrivateRoute";
import LoginPage from "./pages/authPages/loginPage/LoginPage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "./store/actions/authActions";
import PatientDashboardAppointmentDetails from "./dashboard/patientdashboard/PatientDashboardAppointmentDetails";
import PatientDashboardDoctorDetails from "./dashboard/patientdashboard/PatientDashboardDoctorDetails";
import Header from "./components/layouts/header/Header";
import PatientDetails from "./dashboard/doctordashboard/PatientDetails";
import FixedRL from "./components/layouts/fixedComponents/fixedRL";
import NurseDashboard from "./dashboard/labAdministrator/NurseDashboard";
import ViewTestDetails from "./dashboard/labAdministrator/ViewTestDetails";
import ViewPrescriptionDetails from "./dashboard/patientdashboard/ViewPrescriptionDetails";
import AddDoctorPrescription from "./dashboard/doctordashboard/DoctorAddPrescription";
import AdministratorDoctor from "./dashboard/administrator/AdministratoDoctor";
import Knowledgecenter from "./pages/knowledgecenter/Knowledgecenter";
import InternationalPatient from "./pages/InternationalPatients/InternationalPatient";
import Careers from "./pages/careers/Careers";
import Media from "./pages/Media/Media";
import SignUpPage from "./pages/authPages/signupPage/SignUpPage";
import ForgotPass from "./pages/authPages/forgotPass/forgotPass";
import ResetPassword from "./pages/authPages/ResetPassword";
import VideoCall from "./components/reuseablecomponents/VideoCall";
import PatientOrderDetails from "./dashboard/patientdashboard/PatientOrderDetails";
import { test, messaging } from "./init-fcm";
import PaymentFaild from "./pages/paymentMessage/PaymentFaild";
import PaymentSuccess from "./pages/paymentMessage/PymentSuccess";
import PrivacyAndPolicy from "./pages/privacy/privacy";
import NotFound from "./pages/404/NotFound";
import TargetDetails from "./dashboard/administrator/TargetDetails";
import { myDoctorBoard } from "./store/actions/docotorActions";
import BoardDetails from "./dashboard/doctordashboard/BoardDetails";
import MyBoard from "./dashboard/doctordashboard/MyBoard";
import PaymentCancelled from "./pages/paymentMessage/PaymentCancelled";
import useScript from "./components/reuseablecomponents/useScript";
import { useTranslation } from "react-i18next";
import OurFacilities from "./pages/facilities/OurFacilities";
import { getCmscomponent, SelectedLanguage } from "./store/actions/cmsAction";
import PatientOrderDetail from "./dashboard/administrator/OrderDetail";
import GoogleOtp from "./pages/GoogleOtp";
import GoogleSignup from "./pages/GoogleSignUp";
import FaceBookOpt from "./pages/FaceBookOpt";
import FaceBookSignup from "./pages/FaceBookSignup";
import PlateletLysate from "./pages/treatment/treatmentInnerPages/PlateletLysate";
import HyperthermiaTherapy from "./pages/treatment/treatmentInnerPages/HyperthermiaTherapy";
import CarpalTunnelSyndrome from "./pages/speciality/specialityInnerPages/painManagement/painManagementInnerPages/CarpalTunnelSyndrome";
import RheumatoidArthritis from "./pages/speciality/specialityInnerPages/painManagement/painManagementInnerPages/RheumatoidArthritis";
import TennisElbow from "./pages/speciality/specialityInnerPages/painManagement/painManagementInnerPages/TennisElbow";
import AlzheimerDisease from "./pages/speciality/specialityInnerPages/neurologicalRehabilitation/neurologicalRehabilitationInnerPages/AlzheimerDisease";
import MotorNeuronDisease from "./pages/speciality/specialityInnerPages/neurologicalRehabilitation/neurologicalRehabilitationInnerPages/MotorNeuronDisease";
import Type2DiabetesMellitus from "./pages/speciality/specialityInnerPages/diabetes/diabetesInnerPages/Type2DiabetesMellitus";
import RespiratoryDiseases from "./pages/speciality/specialityInnerPages/respiratorydiseases/RespiratoryDiseases";
import Asthma from "./pages/speciality/specialityInnerPages/respiratorydiseases/respiratorydiseasesInnerPages/Asthma";
import LungFibrosis from "./pages/speciality/specialityInnerPages/respiratorydiseases/respiratorydiseasesInnerPages/LungFibrosis";
import AnkylosingSpondylitis from "./pages/speciality/specialityInnerPages/respiratorydiseases/respiratorydiseasesInnerPages/AnkylosingSpondylitis";
import MultipleSclerosis from "./pages/speciality/specialityInnerPages/respiratorydiseases/respiratorydiseasesInnerPages/MultipleSclerosis";
import UlcerativeColitis from "./pages/speciality/specialityInnerPages/respiratorydiseases/respiratorydiseasesInnerPages/UlcerativeColitis";
import PaymentAndRefundPolicy from "./pages/PaymentAndRefundPolicy";
import TermsAndCondition from "./pages/TermsAndCondition";
import ReturnRequestDetail from "./dashboard/administrator/ReturnRequestDetail";
import PatientBookAppointmentWeb from "./dashboard/patientdashboard/PatientBookAppointmentWeb";
import DoctorsPatientMedicalRecords from "./dashboard/doctordashboard/PatientMedicalRecord";
import DeliveryPolicy from "./pages/delivery/delivery";
import Blogs from "./pages/blogs/Blogs";
import BlogDetails from "./pages/blogs/BlogDetails";

function App() {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  // useScript('revolution/js/scriptproperties.js')
  const langs = useSelector((state) => state?.cmsReducer?.languages);
  useEffect(() => {
    // dispatch(SelectedLanguage("en-US"));
    dispatch(getCmscomponent());
    // window.onbeforeunload = () => {
    //   localStorage.removeItem('lang');
    // }
  }, [langs]);

  useEffect(() => {
    dispatch(getUser());
    localStorage.setItem("lang", "en-US");

    if ("serviceWorker" in navigator) {
      navigator?.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then(function (registration) {
          console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });
    }

    if ("Notification" in window && "serviceWorker" in navigator) {
      Notification.requestPermission()
        .then(async function () {
          const token = await test?.getToken(messaging);
          // console.log(token);
          localStorage.setItem("toticellfcm", token);
        })
        .catch(function (err) {
          console.log("Unable to get permission to notify.", err);
        });
      navigator?.serviceWorker?.addEventListener("message", (message) => {
        console.log(message);
        if (!("Notification" in window)) {
          toast.error("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
          new Notification(
            message?.data?.["firebase-messaging-msg-data"]?.notification?.title,
            {
              icon: message?.data?.["firebase-messaging-msg-data"]?.notification
                ?.image,
              body: message?.data?.["firebase-messaging-msg-data"]?.notification
                ?.body,
            }
          );
        }
      });
    }
    window.scrollTo(0, 0);
  }, [dispatch]);

  const user = useSelector((state) => state?.logIn?.user);

  return (
    <div>
      {/* Daniyals Work */}
      {/* <PatientDashboard /> */}

      {/* Shahzaib Work Routing */}
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <ScrollToTop />

        <Route exact path="/" render={(props) => <Header {...props} />} />
        <Route
          exact
          path="/:page/:someParam?"
          render={(props) => <Header {...props} />}
        />

        {/*          Navbar             */}
        <Route exact path="/" render={(props) => <Navbar {...props} />} />
        <Route
          exact
          path="/:page/:someParam?"
          render={(props) => <Navbar {...props} />}
        />

        {/* Switch === Start */}
        <Switch>
          {/* HomepageRoute */}
          <Route exact path="/" render={(props) => <Home {...props} />} />
          <Route exact path="/home" render={(props) => <Home {...props} />} />

          {/* AboutpageRoute */}
          <Route exact path="/about" render={(props) => <About {...props} />} />

          {/* AboutInner ------------->pagesRoute */}
          <Route
            exact
            path="/abouttoti"
            render={(props) => <AboutToticell {...props} />}
          />
          <Route
            exact
            path="/our-management"
            render={(props) => <Management {...props} />}
          />
          <Route
            exact
            path="/our-mission"
            render={(props) => <Mission {...props} />}
          />
          <Route
            exact
            path="/our-doctors"
            render={(props) => <OurDoctors {...props} />}
          />

          {/* TreatmentpageRoute */}
          <Route
            exact
            path="/treatment"
            render={(props) => <Treatment {...props} />}
          />
          {/* Treatment ------------->pagesRoute */}
          <Route
            exact
            path="/prp-therapy"
            render={(props) => <PRP {...props} />}
          />
          <Route
            exact
            path="/bmc-therapy"
            render={(props) => <BMC {...props} />}
          />
          <Route
            exact
            path="/platelet-lysate"
            render={(props) => <PlateletLysate {...props} />}
          />
          <Route
            exact
            path="/hyperthermia-therapy"
            render={(props) => <HyperthermiaTherapy {...props} />}
          />
          <Route
            exact
            path="/svf-therapy"
            render={(props) => <SVC {...props} />}
          />
          <Route
            exact
            path="/msc-therapy"
            render={(props) => <MSC {...props} />}
          />
          <Route
            exact
            path="/mnc-therapy"
            render={(props) => <MNC {...props} />}
          />
          <Route
            exact
            path="/eswt-therapy"
            render={(props) => <ESWT {...props} />}
          />
          <Route
            exact
            path="/hbot-therapy"
            render={(props) => <HBOT {...props} />}
          />
          <Route
            exact
            path="/ct-therapy"
            render={(props) => <CT {...props} />}
          />

          {/* SpecialitypageRoute */}
          <Route
            exact
            path="/Speciality"
            render={(props) => <Speciality {...props} />}
          />
          {/* Treatment ------------->pagesRoute */}

          <Route
            exact
            path="/covid-19-management"
            render={(props) => <CovidManagement {...props} />}
          />
          {/* Covide-19 ------------->subpagesRoute */}
          <Route
            exact
            path="/molecular-diagnosis"
            render={(props) => <MolecularDiagnosis {...props} />}
          />
          <Route
            exact
            path="/serology"
            render={(props) => <Serology {...props} />}
          />
          <Route
            exact
            path="/convalescent-plasma-therapy"
            render={(props) => <CPT {...props} />}
          />
          <Route
            exact
            path="/stem-cell-therapy"
            render={(props) => <StemCellTherapy {...props} />}
          />

          <Route
            exact
            path="/pain-management"
            render={(props) => <PainManagement {...props} />}
          />
          {/* PainManagement ------------->subpagesRoute */}
          <Route
            exact
            path="/knee-pain"
            render={(props) => <KneePain {...props} />}
          />
          <Route
            exact
            path="/back-pain"
            render={(props) => <BackPain {...props} />}
          />
          <Route
            exact
            path="/frozen-shoulder-shoulder-pain"
            render={(props) => <ShoulderPain {...props} />}
          />
          <Route
            exact
            path="/plantar-fasciitis"
            render={(props) => <HeelPain {...props} />}
          />
          <Route
            exact
            path="/tennis-elbow"
            render={(props) => <TennisElbow {...props} />}
          />
          <Route
            exact
            path="/rheumatoid-arthritis"
            render={(props) => <RheumatoidArthritis {...props} />}
          />
          <Route
            exact
            path="/carpal-tunnel-syndrome"
            render={(props) => <CarpalTunnelSyndrome {...props} />}
          />

          <Route
            exact
            path="/asthetic-medicine"
            render={(props) => <AstheticMedicine {...props} />}
          />
          {/* Asthetic ------------->subpagesRoute */}
          <Route
            exact
            path="/erectile-dysfunction"
            render={(props) => <ErectileDysfunction {...props} />}
          />
          <Route
            exact
            path="/womens-intimate-health"
            render={(props) => <WomenIntimateHealth {...props} />}
          />
          <Route
            exact
            path="/hair-loss"
            render={(props) => <HairLoss {...props} />}
          />
          <Route
            exact
            path="/facial-rejuvenation"
            render={(props) => <FacialRejuvenation {...props} />}
          />

          <Route
            exact
            path="/neurological-rehabilitation"
            render={(props) => <NeurologicalRehabilitation {...props} />}
          />
          {/* Neurological ------------->subpagesRoute */}
          <Route
            exact
            path="/autism"
            render={(props) => <Autism {...props} />}
          />
          <Route
            exact
            path="/muscular-dystrophy"
            render={(props) => <MuscularDystrophy {...props} />}
          />
          <Route
            exact
            path="/cerebral-palsy"
            render={(props) => <CerebralPalsy {...props} />}
          />
          <Route
            exact
            path="/spinal-cord-injury"
            render={(props) => <SpinalCordInjury {...props} />}
          />
          <Route
            exact
            path="/downs-syndrome"
            render={(props) => <DownSyndrome {...props} />}
          />
          <Route
            exact
            path="/alzheimer-disease"
            render={(props) => <AlzheimerDisease {...props} />}
          />
          <Route
            exact
            path="/motor-neuron-disease"
            render={(props) => <MotorNeuronDisease {...props} />}
          />

          <Route
            exact
            path="/stem-cell-banking"
            render={(props) => <StemCellBanking {...props} />}
          />
          {/* Stem Cell Banking ------------->subpagesRoute */}
          <Route
            exact
            path="/cord-blood-banking"
            render={(props) => <CordBloodBanking {...props} />}
          />
          <Route
            exact
            path="/cord-tissue-banking"
            render={(props) => <CordTissueBanking {...props} />}
          />
          <Route
            exact
            path="/community-banking"
            render={(props) => <CommunityBanking {...props} />}
          />
          <Route
            exact
            path="/family-banking"
            render={(props) => <FamilyBanking {...props} />}
          />

          <Route
            exact
            path="/diabetes"
            render={(props) => <Diabetes {...props} />}
          />
          {/* Diabetes ------------->subpagesRoute */}
          <Route
            exact
            path="/diabetic-foot-ulcer"
            render={(props) => <DiabetesFootUlcer {...props} />}
          />
          <Route
            exact
            path="/type2-diabetes-mellitus"
            render={(props) => <Type2DiabetesMellitus {...props} />}
          />
          <Route
            exact
            path="/dry-eye-disease"
            render={(props) => <DryEyeDisease {...props} />}
          />
          <Route
            exact
            path="/respiratory-diseases"
            render={(props) => <RespiratoryDiseases {...props} />}
          />
          <Route
            exact
            path="/asthma"
            render={(props) => <Asthma {...props} />}
          />
          <Route
            exact
            path="/lungfibrosis"
            render={(props) => <LungFibrosis {...props} />}
          />
          <Route
            exact
            path="/ankylosingspondylitis"
            render={(props) => <AnkylosingSpondylitis {...props} />}
          />
          <Route
            exact
            path="/multiplesclerosis"
            render={(props) => <MultipleSclerosis {...props} />}
          />
          <Route
            exact
            path="/ulcerativecolitis"
            render={(props) => <UlcerativeColitis {...props} />}
          />

          {/* EvidencepageRoute */}
          <Route
            exact
            path="/evidence"
            render={(props) => <Evidence {...props} />}
          />

          {/* KnowledgepageRoute */}
          <Route
            exact
            path="/knowledge"
            render={(props) => <Knowledgecenter {...props} />}
          />

          {/* KnowledgepageRoute */}
          <Route
            exact
            path="/internationalpatient"
            render={(props) => <InternationalPatient {...props} />}
          />
          {/* Blogpageroute */}
          <Route exact path="/blogs" render={(props) => <Blogs {...props} />} />
          <Route
            exact
            path="/blog-details"
            render={(props) => <BlogDetails {...props} />}
          />

          {/* MediaRoute */}
          <Route exact path="/media" render={(props) => <Media {...props} />} />

          {/* CareerRoute */}
          <Route
            exact
            path="/career"
            render={(props) => <Careers {...props} />}
          />

          {/* StoiespageRoute */}
          <Route
            exact
            path="/stories"
            render={(props) => <Stories {...props} />}
          />
          <Route
            exact
            path="/facilities"
            render={(props) => <OurFacilities {...props} />}
          />
          <Route
            exact
            path="/privacy-policy"
            render={(props) => <PrivacyAndPolicy {...props} />}
          />
          <Route
            exact
            path="/delivery-policy"
            render={(props) => <DeliveryPolicy {...props} />}
          />
          <Route
            exact
            path="/privacy-refund-policy"
            component={PaymentAndRefundPolicy}
          />
          <Route exact path="/terms-conditions" component={TermsAndCondition} />
          {/* Daniyal Bhai Dashboards Routs */}
          {/* PatientDashboardRoute */}

          {/* <Route exact path="/doctor-dashboard" render={(props) => <DoctorDashboard {...props} />} />
        <Route exact path="/AppointmentDetails" render={(props) => <DetailPage {...props} />}  />    
        <Route exact path="/administrator-dashboard" render={(props) => <AdministratorDashboard {...props} />}  />    
        <Route exact path="/prescription-details" render={(props) => <PrescriptionDetails {...props} />}  />    
        <Route exact path="/patient-details" render={(props) => <PatientDetails {...props} />}  />    
        <Route exact path="/appointment-details" render={(props) => <AppointmentDetails {...props} />}  />    
        <Route exact path="/patient-appointment-details" render={(props) => <PatientAppointmentDetails {...props} />}  />    
        <Route exact path="/pharmacy-dashboard" render={(props) => <Pharmacy {...props} />}  />     */}

          <Route
            exact
            path="/auth-number-verification"
            render={(props) => <GoogleOtp {...props} user={user} />}
          />
          <Route
            exact
            path="/auth-signup-verification"
            render={(props) => <GoogleSignup {...props} user={user} />}
          />
          <Route
            exact
            path="/auth-number-verification-facebook"
            render={(props) => <FaceBookOpt {...props} user={user} />}
          />
          <Route
            exact
            path="/auth-signup-verification-facebook"
            render={(props) => <FaceBookSignup {...props} user={user} />}
          />

          {/* ContactpageRoute */}
          <Route
            exact
            path="/contact-us"
            render={(props) => <ContactUs {...props} />}
          />
          {/* Auth */}

          {/* LOGIN PAGE */}
          <Route
            exact
            path="/login"
            render={(props) => <LoginPage {...props} />}
          />
          {/* SIGN UP PAGE */}
          <Route
            exact
            path="/signup"
            render={(props) => <SignUpPage {...props} />}
          />
          <Route
            exact
            path="/forgotpass"
            render={(props) => <ForgotPass {...props} />}
          />
          <Route
            exact
            path="/reset-password/:id/:token"
            render={(props) => <ResetPassword {...props} />}
          />
          <Route exact path="/call/:id" component={VideoCall} />
          <Route
            exact
            path="/payment/:id/success"
            render={(props) => <PaymentSuccess {...props} />}
          />
          <Route
            exact
            path="/payment/:id/fail"
            render={(props) => <PaymentFaild {...props} />}
          />
          <Route
            exact
            path="/payment/:id/cancel"
            render={(props) => <PaymentCancelled {...props} />}
          />
          {/* Daniyal Bhai Dashboards Routs */}
          {/* PatientDashboardRoute */}
          <PrivateRoute
            routelink={"patient"}
            path="/patient-dashboard"
            component={PatientDashboard}
          />
          <PrivateRoute
            routelink={"patient"}
            path="/patient-book-appointment"
            component={PatientBookAppointmentWeb}
          />
          <PrivateRoute
            routelink={"patient"}
            path="/patient-dashboard-appointment-details"
            component={PatientDashboardAppointmentDetails}
          />
          <PrivateRoute
            routelink={"patient"}
            path="/patient-dashboard-doctor-details"
            component={PatientDashboardDoctorDetails}
          />
          {/* profile routes */}
          <PrivateRoute
            routelink={"administrator"}
            path="/administrator-dashboard-profile"
            component={AdministratorUserProfile}
          />
          <PrivateRoute
            routelink={"doctor"}
            path="/doctor-dashboard-profile"
            component={DoctorUserProfile}
          />
          <PrivateRoute
            routelink={"doctor"}
            path="/my-board"
            component={MyBoard}
          />
          <PrivateRoute
            routelink={"doctor"}
            path="/boards-details"
            component={BoardDetails}
          />
          <PrivateRoute
            routelink={"patient"}
            path="/patient-dashboard-profile"
            component={PatientUserProfile}
          />
          <PrivateRoute
            routelink={"nurse"}
            path="/nurse-dashboard-profile"
            component={NurseUserProfile}
          />
          {/* <PrivateRoute
            routelink={"pharmacy"}
            path="/pharmacy-dashboard-profile"
            component={PharmacyUserProfile}
          /> */}
          {/* profile routes */}

          <PrivateRoute
            routelink={"patient"}
            path="/patient-prescription-details"
            component={ViewPrescriptionDetails}
          />
          <PrivateRoute
            routelink={"doctor"}
            path="/patient-details"
            component={PatientDetails}
          />
          {/* <PrivateRoute
            routelink={"pharmacy"}
            path="/pharmacy-dashboard"
            component={Pharmacy}
          /> */}
          <PrivateRoute
            routelink={"pharmacy"}
            path="/pharmacy-dashboard"
            component={Pharmacy}
          />
          <PrivateRoute
            routelink={"administrator"}
            path="/administrator-dashboard"
            component={AdministratorDashboard}
          />
          <PrivateRoute
            routelink={"administrator"}
            path="/administrator-doctor-details"
            component={AdministratorDoctor}
          />

          <PrivateRoute
            routelink={"doctor"}
            path="/doctor-dashboard"
            component={DoctorDashboard}
          />
          <PrivateRoute
            routelink={"doctor"}
            path="/prescription-details"
            component={Prescription}
          />
          <PrivateRoute
            routelink={"doctor"}
            path="/appointment-detail"
            component={AppointmentDetails}
          />
          <PrivateRoute
            routelink={"doctor"}
            path="/patient-medical-records"
            component={DoctorsPatientMedicalRecords}
          />

          <PrivateRoute
            routelink={"pharmacy"}
            path="/pharmacy-Order-details"
            component={PharmacyOrderDetails}
          />
          <PrivateRoute
            routelink={"patient"}
            path="/Order-details"
            component={PatientOrderDetails}
          />
          <PrivateRoute
            routelink={"administrator"}
            path="/appointment-details"
            component={PatientAppointmentDetails}
          />
          <PrivateRoute
            routelink={"administrator"}
            path="/patient-appointment-details"
            component={PatientAppointmentDetail}
          />
          <PrivateRoute
            routelink={"administrator"}
            path="/target-details"
            component={TargetDetails}
          />

          <PrivateRoute
            routelink={"administrator"}
            path="/patient-order-details"
            component={PatientOrderDetail}
          />

          <PrivateRoute
            routelink={"administrator"}
            path="/return-request-details"
            component={ReturnRequestDetail}
          />

          <PrivateRoute
            routelink={"nurse"}
            path="/nurse-dashboard"
            component={NurseDashboard}
          />
          <PrivateRoute
            routelink={"nurse"}
            path="/nurse-test-details"
            component={ViewTestDetails}
          />

          <PrivateRoute
            routelink={"doctor"}
            path="/doctor-add-prescriptions"
            component={AddDoctorPrescription}
          />
          {/* 404 Page */}
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        {/* Switch === End */}

        {/*          Footer             */}

        <Route exact path="/" render={(props) => <FixedRL {...props} />} />
        {/* <Route
          exact
          path="/:page/:someParam?"
          render={(props) => <FixedRL {...props} />}
        /> */}

        <Route exact path="/" render={(props) => <Footer {...props} />} />
        <Route
          exact
          path="/:page/:someParam?"
          render={(props) => <Footer {...props} />}
        />
      </Router>
    </div>
  );
}

export default App;
