import React from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "../../reuseablecomponents/MediaQueryHook";
import { useForm } from "react-hook-form";
import { conatctUse } from "../../../store/actions/staticActions";
import { useDispatch } from "react-redux";

const Toggleform = (props) => {
  const { t } = useTranslation("common");
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const dispatch = useDispatch();

  const { register, handleSubmit, reset } = useForm();
  const onSubmitHandler = async (data) => {
    const apiData = {
      email_body: `<Span>Name: ${data?.username}</Span> <br/> <Span>Email: ${data?.user_email}</Span> <br /> 
         <Span>Phone: ${data?.user_phone}</Span>   <Span>Message: ${data?.user_messages}</Span> <br />`,
    };
    const res = await dispatch(conatctUse(apiData));
  };

  return (
    <>
      <div
        class="gradient-box inquiryfsects "
        style={{
          overflow: "hidden",
          height: props.toggle ? (isDesktop ? 350 : 530) : 0,
          transition: "1s",
        }}
      >
        <div class="section">
          <form
            id="contact-form"
            class="inqforms"
            novalidate="true"
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <div class="messages"></div>
            <div class="container controls">
              <div class="row">
                <div class="col-lg-4 col-md-12">
                  <div class="form-group">
                    <input
                      id="username"
                      type="text"
                      name="username"
                      class="form-control customize"
                      placeholder={
                        props?.data?.name
                          ? props?.data?.name
                          : t("makeanenquiry.placname")
                      }
                      required="required"
                      data-error="Firstname is required."
                      {...register("username", {
                        required: {
                          value: false,
                          message: "this field is required field",
                        },
                      })}
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12">
                  <div class="form-group">
                    <input
                      id="user_email"
                      type="email"
                      name="user_email"
                      class="form-control customize"
                      placeholder={
                        props?.data?.email
                          ? props?.data?.email
                          : t("makeanenquiry.placemail")
                      }
                      required="required"
                      data-error="Valid email is required."
                      {...register("user_email", {
                        required: {
                          value: false,
                          message: "this field is required field",
                        },
                      })}
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12">
                  <div class="form-group">
                    <input
                      id="user_phone"
                      type="tel"
                      name="user_phone"
                      class="form-control customize"
                      placeholder={
                        props?.data?.number
                          ? props?.data?.number
                          : t("makeanenquiry.placphone")
                      }
                      {...register("user_phone", {
                        required: {
                          value: false,
                          message: "this field is required field",
                        },
                      })}
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <textarea
                      id="form_message"
                      name="message"
                      class="form-control customize2"
                      placeholder={
                        props?.data?.message
                          ? props?.data?.message
                          : t("makeanenquiry.placmsg")
                      }
                      rows="6"
                      required="required"
                      data-error="Please,leave us a message."
                      {...register("user_messages", {
                        required: {
                          value: false,
                          message: "this field is required field",
                        },
                      })}
                    ></textarea>
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 center-children-class ">
                  <p>
                    <input
                      type="submit"
                      class="btnRoundedAbout px-4 py-2"
                      value={
                        props?.data?.sendmessage
                          ? props?.data?.sendmessage
                          : t("makeanenquiry.submit")
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Toggleform;
