import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { filterComponentData } from "../utils/functions";

const PaymentAndRefundPolicy = () => {
  const componetcms = useSelector((state) => state.cmsReducer.components);

  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const privacy_policy_refund = filterComponentData(
    componetcms,
    "privacy_and_refund_policy",
    lang
  );

  const footerMetaTitles = filterComponentData(
    componetcms,
    "footer_meta_titles",
    lang
  );
  const footerSchemaMarkups = filterComponentData(
    componetcms,
    "footer_schema_markups",
    lang
  );
  const footerMetaDesc = filterComponentData(
    componetcms,
    "footer_meta_descriptions",
    lang
  );
  const footerMetaKeywords = filterComponentData(
    componetcms,
    "footer_meta_keywords",
    lang
  );
  const footerVerification = filterComponentData(
    componetcms,
    "footer_verification_meta_tags",
    lang
  );
  const verificationsTags = footerVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.paymentRefundName,
      content: item?.paymentRefundContent,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}
        {footerSchemaMarkups?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.paymentRefund ? item?.paymentRefund : "[]")
            )}
          </script>
        ))}
        <title>
          {footerMetaTitles?.paymentRefund
            ? footerMetaTitles?.paymentRefund
            : "Payment And Refund Policy"}
        </title>
        <meta
          name="description"
          content={
            footerMetaDesc?.paymentRefund
              ? footerMetaDesc?.paymentRefund
              : "Payment And Refund Policy"
          }
        />
        <meta
          name="keywords"
          content={
            footerMetaKeywords?.paymentRefund
              ? footerMetaKeywords?.paymentRefund
              : "Payment And Refund Policy"
          }
        />
      </Helmet>
      <div className="container">
        <div className="row">
          {privacy_policy_refund.length < 1 ? (
            <div className="col-12 ">
              <p style={{ marginLeft: "48px" }}>
                <span style={{ fontSize: "11pt" }}>
                  <span
                    className="text-center"
                    style={{ fontFamily: "Calibri,sans-serif" }}
                  >
                    <h1 className="blue-heading ">
                      Returns, Cancellation &amp; Refunds Policy
                    </h1>
                  </span>
                </span>
              </p>
              <p style={{ marginLeft: "144px" }}>&nbsp;</p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <h4
                      className="blue-heading text-center"
                      style={{ fontSize: "18.0pt" }}
                    >
                      ***********Return policy of medicine************
                    </h4>
                  </span>
                </span>
              </p>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span
                          className="blue-heading"
                          style={{ fontSize: "14.0pt" }}
                        >
                          <span className="blue-heading ">
                            How to return Medicine
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <ol>
                <li style={{ textAlign: "justify" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span>
                          Sign in to your <strong>toticell</strong> account,
                          select orders
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li style={{ textAlign: "justify" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ backgroundColor: "white" }}>
                          <span>
                            Select the orders (medicine) you wish to return and
                            click on the Manage Order button
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li style={{ textAlign: "justify" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ backgroundColor: "white" }}>
                          <span>
                            Fill in the return form and the available return
                            options (pickup/drop-off) will be displayed in this
                            form.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li style={{ textAlign: "justify" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ backgroundColor: "white" }}>
                          <span>
                            Pack your return package securely, with the product
                            in&nbsp;
                            <strong>
                              <span
                                style={{ fontFamily: '"Calibri",sans-serif' }}
                              >
                                the original and undamaged manufacturer's
                                packaging&nbsp;
                              </span>
                            </strong>
                            as delivered to you. Write your order number &amp;
                            return tracking number clearly on the outer side of
                            the package
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ol>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "14.0pt" }}>
                          <span className="blue-heading">
                            Acceptance Conditions for Medicine Returns
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <ol>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span>
                        The product must be unused and without any flaws. If a
                        product is returned to us in an inadequate condition, we
                        reserve the right to send it back to you.
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span>
                        The product must include the original tags, user manual,
                        warranty cards, freebies and accessories whatever sends.
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span>
                        The product must be returned in the original and
                        undamaged manufacturer packaging / box. Do not put tape
                        or stickers on the manufacturers’ box.
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span>
                        If your returned item does not meet the above
                        requirements, we reserve the right to reject any request
                        for a refund.
                      </span>
                    </span>
                  </span>
                </li>
              </ol>

              <h3>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: '"Calibri Light",sans-serif' }}>
                      <span style={{ color: "#5b9bd5" }}>
                        <strong>
                          <span style={{ fontSize: "14.0pt" }}>
                            <span
                              style={{ fontFamily: '"Calibri",sans-serif' }}
                            >
                              <span className="blue-heading">
                                Rejection for return request of Medicine
                              </span>
                            </span>
                          </span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
              </h3>
              <ol>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span>
                        <span style={{ fontFamily: "Calibri,sans-serif" }}>
                          If your product is damaged, defective, incorrect or
                          incomplete at the time of delivery, please raise a
                          return request on <strong>TotiCell Ltd.</strong> app
                          or website.&nbsp;Return request must be raised within
                          4 hrs from the date of delivery; otherwise request
                          will not be accepted.
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span>
                        <span style={{ fontFamily: "Calibri,sans-serif" }}>
                          If your returned item does not meet the acceptance
                          condition for returned medicine, we reserve the right
                          to reject any request for a refund
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ol>
              <h3>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: '"Calibri Light",sans-serif' }}>
                      <span style={{ color: "#5b9bd5" }}>
                        <strong>
                          <span style={{ fontSize: "14.0pt" }}>
                            <span
                              style={{ fontFamily: '"Calibri",sans-serif' }}
                            >
                              <span className="blue-heading">
                                Valid reasons to return medicine item
                              </span>
                            </span>
                          </span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
              </h3>
              <ol>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span>
                          Delivered medicine product is damaged (i.e. physically
                          destroyed or broken) / defective (e.g. unable to
                          switch on)
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span>
                          Delivered medicine product is incomplete (i.e. has
                          missing items and/or accessories)
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span>
                          Delivered medicine product is incorrect (i.e. wrong
                          product/size/colour, fake item, or expired)
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span>
                          Delivered medicine product is does not match product
                          description or picture (i.e product not as
                          selected/ordered)
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ol>
              <p>
                <span style={{ fontSize: "12pt" }}>
                  <span style={{ fontFamily: '"Times New Roman",serif' }}>
                    <strong>
                      <span style={{ fontSize: "11.0pt" }}>
                        <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                          <span className="blue-heading">Note:&nbsp;</span>
                        </span>
                      </span>
                    </strong>
                  </span>
                </span>
              </p>
              <ol>
                <li>
                  <span style={{ fontSize: "12pt" }}>
                    <span style={{ fontFamily: '"Times New Roman",serif' }}>
                      <span style={{ fontSize: "11.0pt" }}>
                        <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                          <span>
                            Delivery charge 100 Tk must have to be added for
                            each order (This is applicable only for Dhaka
                            metropolitan city).
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "12pt" }}>
                    <span style={{ fontFamily: '"Times New Roman",serif' }}>
                      <span style={{ fontSize: "11.0pt" }}>
                        <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                          <span>
                            If user has a valid reason, s/he can return medicine
                            with complementary delivery charge.*
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "12pt" }}>
                    <span style={{ fontFamily: '"Times New Roman",serif' }}>
                      <span style={{ fontSize: "11.0pt" }}>
                        <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                          <span>
                            If user would like to cancel the placed order
                            without valid reason (change of mind) then 5% price
                            will be deducted from the total medicine price as
                            transactional charge
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "12pt" }}>
                    <span style={{ fontFamily: '"Times New Roman",serif' }}>
                      <span style={{ fontSize: "11.0pt" }}>
                        <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                          <span>
                            After product dispatched &nbsp;but not delivered, in
                            this case only delivery charge will be deducted*
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ol>
              <p>
                <span style={{ fontSize: "12pt" }}>
                  <span style={{ fontFamily: '"Times New Roman",serif' }}>
                    <span style={{ fontSize: "11.0pt" }}>
                      <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                        <span>
                          *Transaction charge or card processing charge will be
                          deducted only when online payment is done
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <h4
                      className="blue-heading text-center"
                      style={{ fontSize: "18.0pt" }}
                    >
                      ************Appointment &amp; Test/Package booking
                      cancellation policy ************
                    </h4>
                  </span>
                </span>
              </p>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "14.0pt" }}>
                          <span className="blue-heading">
                            How to cancel appointment booking
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span>
                        1. Sign in to your <strong>toticell</strong> account,
                        select orders
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span>2.</span>{" "}
                      <span style={{ backgroundColor: "white" }}>
                        <span>
                          Select Book Appointment order you wish to cancel and
                          click on the Manage Order button for cancellation
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <h3>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: '"Calibri Light",sans-serif' }}>
                    <span style={{ color: "#5b9bd5" }}>
                      <strong>
                        <span style={{ fontSize: "14.0pt" }}>
                          <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                            <span className="blue-heading">
                              Conditions for Cancellation of appointment booking
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </h3>
              <ol>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      Appointment booking can be canceled for unusual cases such
                      as if patient expire or succumb to fatal injuries or
                      hospitalize for being stroke & Cardiac arrest. For this
                      case, family members/relatives of the user (patient) have
                      to inform this incident within 6hrs from the time of the
                      incident either calling 10670 or{" "}
                      <strong>9606213234</strong> or mail to
                      <a
                        href="mailto:info@toticell.com"
                        style={{
                          color: "#0563c1",
                          textDecoration: "underline",
                        }}
                      >
                        info@toticell.com
                      </a>{" "}
                      In this connection, creditable evidence (as applicable)
                      must have to submit/ show/share to{" "}
                      <strong>Toticell Ltd</strong> and after evidence
                      verification & completing the refund process, 100 % of the
                      total appointment booking charge will be refunded*.
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      If user would like to cancel appointment, then request has
                      to be submitted a minimum of 6 hours before of appointment
                      scheduling start time, otherwise the cancellation request
                      will not be accepted and the booking charge will be
                      forfeited. If the cancellation request is accepted, 90%
                      refund will be made of total appointment booking charge.
                    </span>
                  </span>
                </li>
              </ol>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span
                    style={{ fontFamily: "Calibri,sans-serif" }}
                    className="blue-heading"
                  >
                    Note:{" "}
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    *Refund claim acceptance and rejection solely depend on
                    management discretion.
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span>
                      *Transaction charge or card processing charge will be
                      deducted only when online payment done
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <strong>
                      <span style={{ fontSize: "14.0pt" }}>
                        <span
                          className="blue-heading"
                          style={{ fontFamily: "Calibri,sans-serif" }}
                        >
                          How to cancel Test / Package Booking
                        </span>
                      </span>
                    </strong>
                  </span>
                </span>
              </p>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span>
                        1. Sign in to your <strong>toticell</strong> account,
                        select orders
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span>2.</span>{" "}
                      <span style={{ backgroundColor: "white" }}>
                        <span>
                          Select Book Appointment order you wish to cancel and
                          click on the Manage Order button for cancellation
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <h3>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: '"Calibri Light",sans-serif' }}>
                    <span style={{ color: "#5b9bd5" }}>
                      <strong>
                        <span style={{ fontSize: "14.0pt" }}>
                          <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                            <span className="blue-heading">
                              Conditions for Cancellation of{" "}
                            </span>
                          </span>
                        </span>
                        <span style={{ fontSize: "14.0pt" }}>
                          <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                            <span className="blue-heading">
                              Test / Package Booking{" "}
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </h3>

              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    1. If patient expires or is unable to provide specimen for
                    succumbing fatal injuries or emergency hospitalization for
                    being stroke & cardiac arrest and then family
                    members/relative of user (patient) must have to inform this
                    incident within 12 hrs from the time of incident either
                    calling 10670 or <strong>9606213234</strong> or mail to
                    <a
                      href="mailto:info@toticell.com"
                      style={{ color: "#0563c1", textDecoration: "underline" }}
                    >
                      info@toticell.com
                    </a>{" "}
                    for cancellation of booking. In this connection, creditable
                    evidence (as applicable) must have to submit/ show to
                    <strong>TotiCell Ltd.</strong> After evidence verification
                    &amp; completing refund process, 100% of the total package
                    price will be refunded*.{" "}
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    2. For usual case, package booking can be cancelled by
                    submitting request before minimum 1 day of schedule date
                    &amp; time of specimen collection of test/packages. For
                    usual case, 90% of total package/test prices will be
                    refunded*
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    3. If patient can’t do test or package test for natural
                    calamity such as cyclone, earthquake and flooding 100% of
                    total test or package price can be refunded*.
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    4. If patient can’t do test for unrest situation&nbsp; such
                    as political strike, mutiny or any other emergency situation
                    when life is at risk for physical movement, in this
                    case&nbsp; max 100% of total price can be refunded*.
                  </span>
                </span>
              </p>

              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    * TSC &amp; SC will be deducted
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    *But refund claim acceptance and rejection solely depends on
                    management discretion.
                  </span>
                </span>
              </p>

              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"Arial",sans-serif' }}>
                            <h4 className="blue-heading text-center">
                              ********************Refunds
                              policy**********************
                            </h4>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"Arial",sans-serif' }}>
                            <span className="blue-heading">
                              Issuance of Refunds
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <ol>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      The processing time of your refund depends on the type of
                      refund and the payment method you used.
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      The refund period / process start when{" "}
                      <strong>TotiCell Ltd</strong> has processed your refund
                      according to your refund type.
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      In case of “medicine” cost refund, the refund amount
                      covers the medicine price only – product shipping cost
                      will be excluded.
                    </span>
                  </span>
                </li>
              </ol>
              <p style={{ marginLeft: "48px" }}>&nbsp;</p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <strong>
                      <span style={{ fontSize: "14.0pt" }}>
                        <span className="blue-heading">Refund Types</span>
                      </span>
                    </strong>
                  </span>
                </span>
              </p>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span>
                        <strong>TotiCell Ltd</strong> will process your refund
                        according to the following refund types
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <ol>
                <li style={{ textAlign: "justify" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span>
                          Refund from returns - Refund is processed once your
                          item (medicine) is returned to the warehouse/Pharmacy
                          and QC is completed (successful). To learn how to
                          return an item, read our Return Policy.
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li style={{ textAlign: "justify" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span>
                          Refunds from cancelled orders - Refund are
                          automatically triggered once cancelation is
                          successfully processed.
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li style={{ textAlign: "justify" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span>
                          Refunds from failed deliveries - Refund process starts
                          when the item has reached the seller. Please take note
                          that this may take more time depending on the area of
                          your shipping address.{" "}
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ol>

              <table
                cellSpacing={0}
                className="MsoTableGrid"
                style={{ borderCollapse: "collapse", border: "none" }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        borderTop: "1px solid black",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <strong>Payment Method</strong>
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "1px solid black",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <strong>Refund Option</strong>
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "1px solid black",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <strong>Refund Time</strong>
                          </span>
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            Debit or Credit Card(local &amp; International
                            Cards)
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <span style={{ backgroundColor: "white" }}>
                              <span>Debit or Credit Card Payment Reversal</span>
                            </span>
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            10 working days
                          </span>
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            Mobile Banking
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            Mobile Wallet Reversal
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            7 working days
                          </span>
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            Internet Banking
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            Card Payment Reversal
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            7 working days
                          </span>
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            E-Wallet
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            E-Wallet Reversal
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            7 working days
                          </span>
                        </span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <strong>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ backgroundColor: "white" }}>
                          <span style={{ fontFamily: '"Arial",sans-serif' }}>
                            <span className="blue-heading">Note:</span>
                          </span>
                        </span>
                      </span>
                    </strong>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ backgroundColor: "white" }}>
                        <span style={{ fontFamily: '"Arial",sans-serif' }}>
                          <span>
                            &nbsp;Maximum refund timeline excludes weekends and
                            public holidays
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>

              <table
                cellSpacing={0}
                className="MsoTableGrid"
                style={{ borderCollapse: "collapse", border: "none" }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        borderTop: "1px solid black",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <strong>Modes of Refund</strong>
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "1px solid black",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <strong>Description</strong>
                          </span>
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            Bank Deposit
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            The bank account details provided must be correct.
                            The account must&nbsp; be active and should hold
                            some balance
                          </span>
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            Debit Card or Credit Card
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <span style={{ backgroundColor: "white" }}>
                              <span>
                                If the refunded amount is not reflecting in your
                                card statement after the refund is completed and
                                you have received a notification by{" "}
                                <strong>TotiCell Ltd,</strong> please contact
                                your personal bank.
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            Mobile Wallet (bKash/Rocket/Nagad/Upay/SureCash
                            etc.)
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <span style={{ backgroundColor: "white" }}>
                              <span>
                                Similar to bank deposit, the amount will be
                                refunded to the same mobile account details
                                which you inserted at the time of payment
                              </span>
                            </span>
                            <span style={{ fontSize: "10.5pt" }}>
                              <span style={{ backgroundColor: "white" }}>
                                <span
                                  style={{ fontFamily: '"Arial",sans-serif' }}
                                >
                                  <span>.</span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            Internet Banking
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <span style={{ backgroundColor: "white" }}>
                              <span>
                                If the refunded amount is not reflecting in your
                                card statement after the refund is completed and
                                you have received a notification by{" "}
                                <strong>TotiCell Ltd.,</strong> please contact
                                your personal bank
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            E-Wallet(Dmoney/iPAY/OkWallet etc.)
                          </span>
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRight: "1px solid black",
                        borderTop: "none",
                        verticalAlign: "top",
                        padding: "8px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <span style={{ backgroundColor: "white" }}>
                              <span>
                                Similar to bank deposit, the amount will be
                                refunded to the same e-wallet account details
                                which you inserted at the time of E-Wallet
                                profile creation.
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <em>
                      <span style={{ fontFamily: '"Arial Narrow",sans-serif' }}>
                        *** Authority has the complete right to take any sort of
                        decision based on situation
                      </span>
                    </em>
                    <span style={{ fontFamily: '"Arial Narrow",sans-serif' }}>
                      .{" "}
                    </span>
                  </span>
                </span>
              </p>
            </div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: privacy_policy_refund?.description,
              }}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentAndRefundPolicy;
