import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getAllTests } from "../../../../store/actions/docotorActions";

const AddTestModal = (props) => {
  const {t} = useTranslation("common")
  const dispatch = useDispatch();
  const allTests = useSelector((state) => state?.doctorReducer?.allTests);
  const testLoader = useSelector((state) => state?.doctorReducer?.testLoader);

  const [testSelected, setTestSelected] = useState([]);
  const [testError, settestError] = useState([]);

  useEffect(() => {
    dispatch(getAllTests());
  }, [dispatch]);
  const {
    // eslint-disable-next-line
    register,
    handleSubmit,
    // eslint-disable-next-line
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (testSelected.length === 0) {
      settestError("This Is A Required Field");
    } else {
      const testData = {
        id: testSelected[0]?.id,
        name: testSelected[0]?.name,
      };
      props.setSelectedTest([...props?.selectedTest, testData]);
      setTestSelected([]);
      props.onHide();
    }
  };
  // {t("dashboards.alldashboardkeys.tablekeys.addmedicine")}
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">{t("dashboards.alldashboardkeys.tablekeys.addtest")}</Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true">×</span>
            <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-12">
                  <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.selectedtest")}</span>
                  <Form.Group>
                  {testLoader ? <div className="text-center"><Loader type='Oval' height={30} width={60} color="#af5689" /></div>
                      : <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        placeholder=""
                        selected={testSelected}
                        onChange={(selected) => {
                          setTestSelected(selected);
                        }}
                        options={allTests}
                      />
                    }

                    {testError ? (
                      <div className="text-error">{testError}</div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
              </div>

              <input
                type="submit"
                value={t("dashboards.alldashboardkeys.tablekeys.addtest")}
                className="btnSquare btn-block mx-auto mt-3"
                style={{ width: "85%", textAlign: "center" }}
              />
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddTestModal;
