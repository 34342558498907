import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardBtn from "../../components/reuseablecomponents/DashboardBtn";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import PatientPastAppointment from "./PatientPastAppointment";
import PatientTodayAppointment from "./PatientTodayAppointment";
import PatientUpcomingAppointment from "./PatientUpcomingAppointment";
import { patientAppointments } from "../../store/actions/patientActions";
import { useTranslation } from "react-i18next";

const PatientAppointmentContainer = (props) => {
  const {t} = useTranslation("common");
  const [selectedTab, setselectedTab] = useState(t("dashboards.alldashboardkeys.tablekeys.today"));
  const loader = useSelector((state) => state?.patient?.patientLoader);
  const user = useSelector((state) => state?.logIn?.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(patientAppointments({ id: user?.id }));
    // eslint-disable-next-line
  }, [dispatch,user]);
  const tabHandler = (item) => {
    setselectedTab(item);
  };
  return (
    <div>
      {loader ? (
        <LoderContainer />
      ) : (
        <>
          <div className="row">
            {
            [
              t("dashboards.alldashboardkeys.tablekeys.today")
              ,
              t("dashboards.alldashboardkeys.tablekeys.upcoming")
              ,
              t("dashboards.alldashboardkeys.tablekeys.past")
            ]?.map((item, index) => (
              <div className="col-lg-3 col-md-4 col-12 mt-2" key={index + 1}>
                <DashboardBtn
                  label={item}
                  active={selectedTab === item}
                  onClick={tabHandler}
                />
              </div>
            ))}
          </div>
          <div className="row">
            {selectedTab === t("dashboards.alldashboardkeys.tablekeys.today") ? (
              <PatientTodayAppointment {...props}/>
            ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.upcoming") ? (
              <PatientUpcomingAppointment {...props}/>
            ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.past") ? (
              <PatientPastAppointment {...props}/>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default PatientAppointmentContainer;
