import React from "react";
import logo from "../../statics/icons/Toticell_logo1.png";

const NotificationTab = (props) => {
  return (
    <div className="notificationContainer m-3 p-2">
      <div className="row ">
        <div className="col-3 notificationImg pt-3 px-3">
          <img
            src={logo ? logo  : props.pic}
            alt="logo"
            width="100px"
            height="60px"
            style={{ borderRadius: "1px solid lightgray" }}
          />
        </div>
        <div className="col-9">
          <li style={{listStyle:'none'}}>
            <strong> {props?.title} </strong>
          </li>
          <li style={{listStyle:'none'}}>
            {props?.decription}
          </li>
        </div>
      </div>
    </div>
  );
};

export default NotificationTab;
