import { toast } from "react-toastify";
import apiServices from "../../services/requestHandler";
import { logout, saveUser, setToken, setUserRole } from "../../utils/auth.util";
import { signInConstansts } from "../constants/authConstants";
import i18next  from "i18next";

export const loginUser = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.SET_LOADER,
    payload: true,
  });
  try {
    const response = await apiServices.login(data);
    if (response?.data?.response_code === 200 && response?.data?.response_data?.session_token_type !== "super_admin") {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      setToken({
        key: response?.data?.response_data?.session_token,
        type: response?.data?.response_data?.session_token_type,
      });
      saveUser(response?.data?.response_data?.user);
      setUserRole(response?.data?.response_data?.session_token_type);
      dispatch({
        type: signInConstansts.SET_ERROR,
        payload: null,
      });
      dispatch({
        type: signInConstansts.SIGN_IN,
        payload: response?.data?.response_data?.user,
      });
      dispatch({
        type: signInConstansts.USER_TYPE,
        payload: response?.data?.response_data?.session_token_type,
      });
      toast.info(i18next.t("common:toastmessages.loginSuccess"));
      return response?.data?.response_data?.session_token_type;
    } else if (response?.data?.response_code === 1000) {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      if (
        response?.data?.response_data?.session_token_type === "patient" &&
        !response?.data?.response_data?.user?.email_address_is_verified
      ) {
        dispatch({
          type: signInConstansts.EMAIL_MODAL,
          payload: true,
        });
        dispatch({
          type: signInConstansts.SIGN_IN,
          payload: response?.data?.response_data?.user,
        });
        dispatch({
          type: signInConstansts.USER_TYPE,
          payload: response?.data?.response_data?.session_token_type,
        });
      }
      if (
        response?.data?.response_data?.user?.email_address_is_verified &&
        !response?.data?.response_data?.user?.phone_number_is_verified
      ) {
        dispatch({
          type: signInConstansts.SET_LOADER,
          payload: false,
        });
        dispatch(
          resendPhoneOtp({ id: response?.data?.response_data?.user?.id })
        );
        dispatch({
          type: signInConstansts.PHONE_MODAL,
          payload: true,
        });
        dispatch({
          type: signInConstansts.USER_TYPE,
          payload: response?.data?.response_data?.session_token_type,
        });
        dispatch({
          type: signInConstansts.SIGN_IN,
          payload: response?.data?.response_data?.user,
        });
      }

      return "error";
    } else {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });

      if(response?.data?.response_data?.session_token_type === "super_admin"){
        toast.info(i18next.t("common:toastmessages.invalidusernamepassword"));
        dispatch({
          type: signInConstansts.SET_ERROR,
          payload: "Invalid username/password",
        });
      } else {
        toast.error(response?.data?.response_message);
        dispatch({
          type: signInConstansts.SET_ERROR,
          payload: response?.data?.response_message,
        });
      }


      return "error";
    }
  } catch (error) {
    console.log("Login error", error);
  }
};
export const forgotPassword = (data) => async (dispatch) => {
  try {
    const response = await apiServices.forgotPassword(data);
    if (response?.data?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.emailhasbeensenttoemail"));
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (error) { }
};
export const resetPassword = (data) => async (dispatch) => {
  try {
    const response = await apiServices.resetPassword(data);
    if (response?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.successfullpasswordreset"));
      return "success";
    } else {
      toast.error(response?.response_message);
      return "fail";
    }
  } catch (error) {
    toast.error(error);
  }
};
export const getUser = () => async (dispatch) => {
  const getUserFromLocal = localStorage.getItem("toticelluser");
  let user = JSON.parse(getUserFromLocal);
  const getUserTypeFromLocal = localStorage.getItem("toticelluserRole");
  let userRole = JSON.parse(getUserTypeFromLocal);
  dispatch({
    type: signInConstansts.GET_USER_FROM_LOCAL,
    payload: user,
  });
  dispatch({
    type: signInConstansts.USER_TYPE,
    payload: userRole,
  });
};
export const setUser = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.SIGN_IN,
    payload: data,
  });
  saveUser(data);
};
export const emailModalToggle = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.EMAIL_MODAL,
    payload: data,
  });
};
export const phoneModalToggle = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.PHONE_MODAL,
    payload: data,
  });
};

export const logoutUser = () => async (dispatch) => {
  try {
    await apiServices.logout();
    logout();
    dispatch({
      type: signInConstansts.LOG_OUT_USER,
      payload: null,
    });
    toast.info(i18next.t("common:toastmessages.userlogout"));
  } catch (error) {
    console.log("Login error", error);
  }
};

export const verifyEmail = (data) => async (dispatch) => {
  try {
    const response = await apiServices.verifyEmail(data);
    console.log(response);
    if (response?.data?.response_code === 200) {
      setToken({
        key: response?.data?.response_data?.session_token,
        type: response?.data?.response_data?.session_token_type,
      });
      saveUser(response?.data?.response_data?.user);
      dispatch({
        type: signInConstansts.EMAIL_VERIFY,
        payload: response?.data?.response_data?.user,
      });
      dispatch(resendPhoneOtp({ id: response?.data?.response_data?.user?.id }));
      toast.info(i18next.t("common:toastmessages.emailverified"));
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (error) {
    console.log("EMAIL_VERIFY ERROR", error);
  }
};
export const verifyNumber = (data) => async (dispatch) => {
  try {
    const response = await apiServices.verifyNumber(data);
    if (response?.data?.response_code === 200) {
      setToken({
        key: response?.data?.response_data?.session_token,
        type: response?.data?.response_data?.session_token_type,
      });
      saveUser(response?.data?.response_data?.user);
      setUserRole(response?.data?.response_data?.session_token_type);
      dispatch(getUser())
      dispatch({
        type: signInConstansts.Number_VERIFY,
        payload: response?.data?.response_data?.user,
      });
      toast.info(i18next.t("common:toastmessages.phoneverified"));
      return "success";
    } else {
      toast.error(response?.data?.response_message);
      return "fail";
    }
  } catch (error) {
    console.log("verify Number error", error);
  }
};

export const resendPhoneOtp = (data) => async (dispatch) => {
  try {
    const response = await apiServices.resendPhoneOtp(data);
    if (response?.data?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.phoneotpresend"));
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (error) {
    console.log("verify Number error", error);
  }
};

export const resendemailOtp = (data) => async (dispatch) => {
  try {
    const response = await apiServices.resendemailOtp(data);
    if (response?.data?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.emailotpresend"));
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (error) {
    console.log("verify Number error", error);
  }
};

export const registerUser = (data) => async (dispatch) => {
  try {
    const response = await apiServices.registerPatient(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: signInConstansts.SIGN_IN,
        payload: response?.data?.response_data?.user,
      });
      saveUser(response?.data?.response_data?.user);
      setUserRole(response?.data?.response_data?.session_token_type);
      dispatch(getUser())

      if (response?.data?.response_data?.user?.email_address_is_verified) {
        dispatch(resendPhoneOtp({ id: response?.data?.response_data?.user?.id }));
        dispatch(phoneModalToggle(true));
      } else {
        dispatch(resendemailOtp({ id: response?.data?.response_data?.user?.id }));
        dispatch(emailModalToggle(true));
      }
      
      return "true";
    } else {
      toast.error(response?.data?.response_message);
      return "fail";
    }
  } catch (error) {
    console.log(error);
  }
};

export const changePassword = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: true,
    });
    const response = await apiServices.changePassword(data);
    if (response?.response_code === 200) {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      

      toast.info(i18next.t("common:toastmessages.passwordchangedsuccessfully"));

      return "true";
    } else {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast.error(response?.response_message);
      return "fail";
    }
  } catch (error) {
    console.log(error);
  }
};
export const appointmodal = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.APPOINT_MODAL,
    payload: data,
  });
};





export const googleSignIn = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: false,
    });
    const response = await apiServices.googlesignin(data);
    localStorage.setItem("response", JSON.stringify(response))
    if (response?.data?.response_code === 200 && response?.data?.response_data?.session_token_type == "patient") {

      saveUser(response?.data?.response_data?.user);
      setUserRole(response?.data?.response_data?.session_token_type);
      dispatch({
        type: signInConstansts.GOOGLE_USER_LOGIN,
        payload: response?.data?.response_data?.user,
      });
      setToken({
        key: response?.data?.response_data?.session_token,
        type: response?.data?.response_data?.session_token_type,
      });

      dispatch({
        type: signInConstansts.SET_ERROR,
        payload: null,
      });
      dispatch({
        type: signInConstansts.SIGN_IN,
        payload: response?.data?.response_data?.user,
      });
      dispatch({
        type: signInConstansts.USER_PHONE_NUMBER,
        payload: response?.data?.response_data?.user?.phone_number,
      });
      dispatch({
        type: signInConstansts.USER_TYPE,
        payload: response?.data?.response_data?.session_token_type,
      });
      toast.info(i18next.t("common:toastmessages.loginSuccess"));
      return [response?.data?.response_data?.session_token_type, response?.data?.response_data?.user];

    } else if (response?.data?.response_code == 7002) {
      return "Invalid";
    }
    else {
      toast.error(response?.data?.response_message);
      return "Invalid";
    }
  } catch (error) {
    toast.error(error);
  }
};

export const facebookSignIn = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: false,
    });
    const response = await apiServices.facebooksignin(data);
    localStorage.setItem("response", JSON.stringify(response))
    if (response?.data?.response_code === 200 && response?.data?.response_data?.session_token_type == "patient") {

      saveUser(response?.data?.response_data?.user);
      setUserRole(response?.data?.response_data?.session_token_type);
      dispatch({
        type: signInConstansts.GOOGLE_USER_LOGIN,
        payload: response?.data?.response_data?.user,
      });
      setToken({
        key: response?.data?.response_data?.session_token,
        type: response?.data?.response_data?.session_token_type,
      });

      dispatch({
        type: signInConstansts.SET_ERROR,
        payload: null,
      });
      dispatch({
        type: signInConstansts.SIGN_IN,
        payload: response?.data?.response_data?.user,
      });
      dispatch({
        type: signInConstansts.USER_PHONE_NUMBER,
        payload: response?.data?.response_data?.user?.phone_number,
      });
      dispatch({
        type: signInConstansts.USER_TYPE,
        payload: response?.data?.response_data?.session_token_type,
      });
      toast.info(i18next.t("common:toastmessages.loginSuccess"));
      return [response?.data?.response_data?.session_token_type, response?.data?.response_data?.user];

    } else if (response?.data?.response_code == 7002) {
      return "invalid"
    }
    else {
      toast.error(response?.data?.response_message);
      return "fail";
    }
  } catch (error) {
    toast.error(error);
  }
};

export const registerUserGoogle = (data) => async (dispatch) => {
  try {
    const response = await apiServices.googlesignup(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: signInConstansts.SIGN_IN,
        payload: response?.data?.response_data?.user,
      });
      saveUser(response?.data?.response_data?.user);
      setUserRole(response?.data?.response_data?.session_token_type);
      setToken({
        key: response?.data?.response_data?.session_token,
        type: response?.data?.response_data?.session_token_type,
      });

      if (response?.data?.response_data?.user?.phone_number_is_verified) {
        dispatch(sendPhoneOtp({ id: response?.data?.response_data?.user?.id, phone_number: response?.data?.response_data?.user?.phone_number }));
        dispatch(phoneModalToggle(true));
      }
      return response?.data?.response_data?.user
    } else if (response?.data?.response_code == 8001) {
      return "exist"
    } else {
      toast.error(response?.data?.response_message);
      return "fail"
    }
  } catch (error) {
    console.log(error);
  }
};
export const registerUserFaceBook = (data) => async (dispatch) => {
  try {
    const response = await apiServices.facebooksignup(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: signInConstansts.SIGN_IN,
        payload: response?.data?.response_data?.user,
      });
      saveUser(response?.data?.response_data?.user);
      setUserRole(response?.data?.response_data?.session_token_type);
      setToken({
        key: response?.data?.response_data?.session_token,
        type: response?.data?.response_data?.session_token_type,
      });

      if (response?.data?.response_data?.user?.phone_number_is_verified) {
        dispatch(sendPhoneOtp({ id: response?.data?.response_data?.user?.id, phone_number: response?.data?.response_data?.user?.phone_number }));
        dispatch(phoneModalToggle(true));
      }
      return response?.data?.response_data?.user
    } else if (response?.data?.response_code == 8001) {
      return "exist"
    } else {
      toast.error(response?.data?.response_message);
      return "fail"
    }
  } catch (error) {
    console.log(error);
  }
};

export const addPhoneModalToggle = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.ADD_PHONE_MODAL,
    payload: data,
  });
};
export const sendPhoneOtp = (data) => async (dispatch) => {
  // const {t} = useTranslation("common")
  try {
    const response = await apiServices.resendPhoneOtp(data);
    if (response?.data?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.phoneotpresend"));
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (error) {
    console.log("verify Number error", error);
  }
};

export const setUserRoleFromLocal = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.SET_USER_ROLE_FROM_LOCAL,
    payload: data,
  });
};