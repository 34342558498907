import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch } from "react-redux";
import {
  addAppointment,
  getAllDepartment,
  getDoctorByDepartment,
  getSlotByDateAndDoctor,
  readLocation,
  readLocationOfDoctor,
} from "../../store/actions/patientActions";
import { Form, InputGroup } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { useForm } from "react-hook-form";
import { disablePastDate } from "../../utils/functions";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/auth.util";
import axios from "axios";
import { BASEURL } from "../../services/HttpProvider";
import moment from "moment";

const PatientBookAppointment = (props) => {
  const { t } = useTranslation("common");
  const user = useSelector((state) => state?.logIn?.user);
  const departments = useSelector((state) => state?.patient?.departments);
  const loader = useSelector((state) => state?.patient?.patientLoader);
  const doctors = useSelector((state) => state?.patient?.doctorsByDepartment);
  const locations = useSelector((state) => state?.patient?.doctorLocation);

  const slots = useSelector((state) => state?.patient?.slotsByDate);
  const staticData = useSelector((state) => state?.static?.staticData);
  const [date, setdate] = useState("");
  const [singleSelections, setSingleSelections] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [filLocation, setFilLocation] = useState([]);
  let filteredLocationOptions = [];
  const [slot, setSlot] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const new_date = new Date();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    // watch,
    reset,
    formState: { errors },
  } = useForm();
  // const date = watch("date", props.date);
  useEffect(() => {
    dispatch(getAllDepartment());
    // dispatch(readLocationOfDoctor())

    // eslint-disable-next-line
  }, []);
  const getLocation = async (id) => {
    let token = await getToken();
    let res = await axios.get(
      BASEURL + `doctor_location/read?doctor_id=${id}`,
      {
        headers: {
          "x-session-key": token.key,
          "x-session-type": token.type,
        },
      }
    );
    if (res?.data?.response_code === 200) {
      res?.data?.response_data?.locations?.length < 1
        ? setFilLocation([])
        : res?.data?.response_data?.locations?.forEach((element) => {
            filteredLocationOptions.push({
              label: element?.name,
              value: element?.id,
              key: element?.id,
            });
          });
      setFilLocation(res?.data?.response_data?.locations);
    } else {
      setFilLocation([]);
    }
  };

  const changeHanler = (selected) => {
    if (selected.length > 0) {
      const apiData = {
        department_id: selected[0]?.department_id,
      };
      dispatch(getDoctorByDepartment(apiData));
    }
  };
  const changeDoctorHanler = (selected, dateSelected, selectedLocation) => {
    if ((selected?.length > 0 || doctor?.length > 0) && dateSelected != "") {
      let today = new Date();
      let time = today.getHours() + ":" + today.getMinutes();
      if (selectedLocation?.length < 1) {
        const apiData = {
          id: selected[0]?.id,
          date: dateSelected,
          time: time,
          timestamp: moment(new_date)
            .add(moment(new_date).utcOffset(), "minutes")
            .utc()
            .unix(),
        };
        // G7MJFaYaRmLP9udjJ8Bd
        if (selected.length > 0 && dateSelected !== "") {
          dispatch(getSlotByDateAndDoctor(apiData));
        }
      } else {
        const apiData = {
          id: selected[0]?.id,
          date: dateSelected,
          time: time,
          location_id: selectedLocation[0]?.id,
          timestamp: moment(new_date)
            .add(moment(new_date).utcOffset(), "minutes")
            .utc()
            .unix(),
        };
        if (selected.length > 0 && dateSelected !== "") {
          dispatch(getSlotByDateAndDoctor(apiData));
        }
      }
    }
  };

  const onSubmit = async (data) => {
    // const consultation = staticData?.appointment_consultation_type?.filter(
    //   (item) => item.name === data.consultation
    // );
    if (data?.consultation == "" || data?.condition == "") {
      data.consultation = "walk-in";
      data.condition = "fair";
    }
    const condition = staticData?.appointment_patient_condition?.filter(
      (item) => item?.name === data?.condition
    );

    const apiData = {
      city: data.city,
      consultation_type:
        data?.consultation == "walk-in"
          ? { id: 1, name: "walk-in" }
          : { id: 2, name: "online" },
      date: date,
      slot_id: slot[0]?.slot_id,
      description: data.description,
      doctor_id: doctor[0]?.id,
      condition: condition[0] || [],

      patient_id: user?.id,
    };

    const res = await dispatch(addAppointment(apiData));
    if (res === "true") {
      const item = { key: "FromViewAppointment" };
      props?.history?.push("/patient-dashboard", item);
      window.scrollTo(0, 0);
      reset();
    }
  };
  return (
    <>
      <div className="w-100 containerWithShadow p-2 mb-5">
        <div className="p-3 ">
          <span className="dashboardHeadingText">
            {t("dashboards.alldashboardkeys.tablekeys.bookappointment")}
          </span>
        </div>
        <p className="px-3" style={{ fontWeight: "bold" }}>
          {t("dashboards.alldashboardkeys.tablekeys.patientdetail")}
        </p>
        <div className="row px-4">
          <div className="col-lg-6 col-md-6 col-12">
            <label for="firstName">
              {t("dashboards.alldashboardkeys.tablekeys.firstname")}
            </label>
            <input
              type="text"
              className="dashboards-input"
              id="firstName"
              disabled
              value={user?.name}
            />
          </div>
          {/* <div className="col-lg-4 col-md-6 col-12">
            <label for="middleName">
              {t("dashboards.alldashboardkeys.tablekeys.middlename")}
            </label>
            <input
              type="text"
              className="dashboards-input"
              id="middleName"
              disabled
              value={user?.name}
            />
          </div> */}
          {/* {t("dashboards.alldashboardkeys.tablekeys.lastname")} */}
          {/* <div className="col-lg-4 col-md-6 col-12 ">
            <label for="lastName">
              {t("dashboards.alldashboardkeys.tablekeys.lastname")}
            </label>
            <input
              type="text"
              className="dashboards-input"
              id="lastName"
              disabled
              value={user?.name}
            />
          </div> */}
          <div className="col-lg-6 col-md-12 col-12 ">
            <label for="dob">
              {t("dashboards.alldashboardkeys.tablekeys.dateofbirth")}
            </label>
            <input
              type="date"
              className="dashboards-input"
              id="dob"
              disabled
              value={user?.date_of_birth}
            />
          </div>
          <div className="col-lg-6 col-md-12 col-12 mt-3">
            <label for="gender">
              {t("dashboards.alldashboardkeys.tablekeys.gender")}
            </label>
            <input
              type="text"
              className="dashboards-input"
              id="gender"
              disabled
              value={user?.gender?.name}
            />
          </div>
          <div className="col-lg-6 col-md-12 col-12 mt-3">
            <label for="mobile">
              {t("dashboards.alldashboardkeys.tablekeys.mobilenumber")}
            </label>
            <input
              type="text"
              className="dashboards-input"
              id="mobile"
              disabled
              value={user?.phone_number}
            />
          </div>
          <div className="col-lg-6 col-md-12 col-12 mt-3">
            <label for="Email">
              {t("dashboards.alldashboardkeys.tablekeys.email")}
            </label>
            <input
              type="text"
              className="dashboards-input"
              id="Email"
              disabled
              value={user?.email_address}
            />
          </div>
          <div className="col-lg-6 mt-3">
            <span className="label-name-login">Condition</span>
            <Form.Control
              as="select"
              className="dashboards-input"
              id="consultation"
              custom
              style={{ height: "46px" }}
              {...register("condition", {})}
            >
              {staticData?.appointment_patient_condition?.map((item, index) => (
                <option value={item?.name} key={index + 1}>
                  {item?.name}
                </option>
              ))}
            </Form.Control>
            {errors?.condition?.message ? (
              <div className="text-error">{errors?.condition?.message}</div>
            ) : (
              ""
            )}
          </div>
        </div>
        <p className="px-3 mt-4" style={{ fontWeight: "bold" }}>
          {/* {t("dashboards.alldashboardkeys.tablekeys.email")} */}
          {t("dashboards.alldashboardkeys.tablekeys.appointmentinformation")}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row px-4">
            <div className="col-lg-6 col-md-12 col-12 mt-3">
              <label for="city">
                {t("dashboards.alldashboardkeys.tablekeys.city")}
              </label>
              <input
                type="text"
                className="dashboards-input"
                id="city"
                {...register("city", {
                  required: {
                    value: true,
                    message: "this field is required field",
                  },
                })}
              />
              {errors?.city?.message ? (
                <div className="text-error">{errors?.city?.message}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-3">
              <label for="consultation">
                {t("dashboards.alldashboardkeys.tablekeys.consultationtype")}
              </label>
              <Form.Control
                as="select"
                className="dashboards-input"
                id="consultation"
                custom
                style={{ height: "46px" }}
                {...register("consultation", {})}
              >
                {staticData?.appointment_consultation_type?.map(
                  (item, index) => (
                    <option value={item?.name} key={index + 1}>
                      {item?.name}
                    </option>
                  )
                )}
              </Form.Control>
              {errors?.consultation?.message ? (
                <div className="text-error">
                  {errors?.consultation?.message}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-3">
              {/* {t("dashboards.alldashboardkeys.tablekeys.consultationtype")} */}
              <label for="speciality">
                {t("dashboards.alldashboardkeys.tablekeys.department")}{" "}
              </label>
              {/* <input type="text" className="dashboards-input" id="speciality" /> */}
              <Typeahead
                id="basic-typeahead-single"
                labelKey="name"
                onChange={(selected) => {
                  setSingleSelections(selected);
                  changeHanler(selected);
                }}
                options={departments}
                placeholder=""
                selected={singleSelections}
              />
            </div>{" "}
            <div className="col-lg-6 col-md-12 col-12 mt-3">
              <label for="doctor">
                {t("dashboards.alldashboardkeys.tablekeys.doctortab")}{" "}
              </label>
              {/* <input type="text" className="dashboards-input" id="doctor" /> */}
              {loader ? (
                <>
                  <Loader height={20} width={20} type="Oval" color="#1a4774" />

                  <Form.Control type="text" disabled />
                </>
              ) : (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  onChange={(selected) => {
                    setDoctor(selected);  
                    getLocation(selected[0]?.id);
                    changeDoctorHanler(selected, date, selectedLocation);

                    // changeDoctorHanler(selected, date);
                  }}
                  options={doctors}
                  placeholder=""
                  selected={doctor}
                />
              )}
            </div>{" "}
            <div className="col-lg-6 col-md-12 col-12 mt-3">
              <label for="appointmentDate">
                {t("dashboards.alldashboardkeys.tablekeys.appointmentdate")}
              </label>
              <input
                type="date"
                className="dashboards-input"
                id="appointmentDate"
                min={disablePastDate()}
                onChange={(e) => {
                  setdate(e.target.value);
                  changeDoctorHanler(doctor, e.target.value, selectedLocation);

                  // changeDoctorHanler(doctor, e.target.value);
                }}
                // {...register("date", {
                //   required: {
                //     value: true,
                //     message: "this field is required field",
                //   },
                // })}
              />
              {errors?.date?.message ? (
                <div className="text-error">{errors?.date?.message}</div>
              ) : (
                ""
              )}
            </div>{" "}
            <div className="col-lg-6 col-md-12 col-12 mt-3">
              <label for="slot">Location </label>
              <Form.Group>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  onChange={(selected) => {
                    setSelectedLocation(selected);
                    changeDoctorHanler(doctor, date, selected);
                  }}
                  options={filLocation}
                  placeholder=""
                  // selected={selectedLocation}
                />
              </Form.Group>
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-3">
              <label for="slot">Slot </label>
              <Form.Group>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="duration"
                  onChange={(selected) => {
                    setSlot(selected);
                    // changeDoctorHanler(selected);
                  }}
                  options={slots}
                  placeholder=""
                  selected={slot}
                />
              </Form.Group>
            </div>
            <div className="col-12 mt-3">
              {/* {t("dashboards.alldashboardkeys.tablekeys.Slot")} */}
              <label for="reson">
                {t(
                  "dashboards.alldashboardkeys.tablekeys.reasonforappointment"
                )}
              </label>
              <textarea
                type="text"
                className="dashboards-input"
                id="reson"
                style={{ minHeight: "160px" }}
                {...register("description", {
                  required: {
                    value: false,
                    message: "this field is required field",
                  },
                })}
              />
            </div>
            <div className="col-4 my-4">
              <input
                className="dashboardBtn"
                type="submit"
                value={t(
                  "dashboards.alldashboardkeys.tablekeys.bookappointment"
                )}
                style={{ border: "transparent" }}
                // active={selectedTab === item}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PatientBookAppointment;
