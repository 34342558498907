import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../components/layouts/bannerImgComponents/BannerImgComponents";
import PageMenuCard from "../../components/layouts/cards/PageMenuCard/PageMenuCard";
import {
  aboutcard,
  Aboutcard1,
  Aboutcard2,
  banner,
  LinksBanAbout,
} from "../../data";
import { filterComponentData } from "../../utils/functions";

const About = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const about = filterComponentData(component, "about_us_cards", lang);
  const banners = filterComponentData(component, "banner", lang);
  const AboutMetaTitles = filterComponentData(
    component,
    "about_meta_titles",
    lang
  );
  const aboutSchemaMarkups = filterComponentData(
    component,
    "about_schema_markups",
    lang
  );
  const AboutMetaDesc = filterComponentData(
    component,
    "about_meta_descriptions",
    lang
  );
  const AboutMetaKeywords = filterComponentData(
    component,
    "about_meta_keywords",
    lang
  );
  const AboutVerifications = filterComponentData(
    component,
    "about_verification_meta_tags",
    lang
  );
  const { t } = useTranslation("common");
  const aboutcard = [
    {
      id: 0,
      imageURL: Aboutcard2,
      text: t("about.pagemenucard.card1.text"),
      link: "/abouttoti",
    },
    {
      id: 1,
      imageURL: Aboutcard2,
      text: t("about.pagemenucard.card2.text"),
      link: "/our-management",
    },
    {
      id: 2,
      imageURL: Aboutcard1,
      text: t("about.pagemenucard.card3.text"),
      link: "/our-mission",
    },
    {
      id: 3,
      imageURL: Aboutcard1,
      text: t("about.pagemenucard.card4.text"),
      link: "/our-doctors",
    },
  ];

  // About Main Page Banner Data
  const LinksBanAbout = [
    {
      subLinkName: banners[0]?.subtitle?.split(">")[0]
        ? banners[0]?.subtitle?.split(">")[0]
        : t("about.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[0]?.subtitle?.split(">")[1]
        ? banners[0]?.subtitle?.split(">")[1]
        : t("about.banner.title"),
      subDash: "",
      subLink: "/about",
    },
  ];

  const verificationsTags = AboutVerifications?.map((item) =>
    React.createElement("meta", {
      name: item?.mainAboutName,
      content: item?.mainAboutContent,
    })
  );

  return (
    <>
      <Helmet>
        {verificationsTags}
        {aboutSchemaMarkups?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.mainAbout ? item?.mainAbout : "[]")
            )}
          </script>
        ))}

        <title>
          {AboutMetaTitles?.mainAbout
            ? AboutMetaTitles?.mainAbout
            : t("about.maintitle")}
        </title>
        <meta
          name="description"
          content={
            AboutMetaDesc?.mainAbout
              ? AboutMetaDesc?.mainAbout
              : t("about.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            AboutMetaKeywords?.mainAbout
              ? AboutMetaKeywords?.mainAbout
              : t("about.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[0]?.heading ? banners[0]?.heading : t("about.banner.title")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[0]?.image ? banners[0]?.image : banner
          })`}
          imgSrc={banners[0]?.image ? banners[0]?.image : banner}
          LinksBan={LinksBanAbout}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container">
          <div className="row ">
            {about?.length < 1
              ? aboutcard.map((item, index) => (
                  <PageMenuCard
                    className="col-lg-3 col-md-6"
                    key={index + 1}
                    img={item?.imageURL}
                    classNamemenucard={"pt-4"}
                    heading={item?.text}
                    linkroute={item?.link}
                  />
                ))
              : about?.map((item, index) => (
                  <PageMenuCard
                    className="col-lg-3 col-md-6"
                    key={index + 1}
                    img={item?.image}
                    classNamemenucard={"pt-4"}
                    heading={item?.text}
                    linkroute={item?.link}
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
