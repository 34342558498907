/* eslint-disable */
import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TermsNCondition = (props) => {
  const {t} = useTranslation("common")
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="dashboardBtnList-item-active">
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <span className="text-white">{t("dashboards.alldashboardkeys.tablekeys.termsandcondition")}</span>
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true" className="text-white">
              ×
            </span>
            <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>
          </button>
        </Modal.Header>

        <Modal.Body>
            <div className="container">
          <div className="text-justify">
          {t("dashboards.alldashboardkeys.tablekeys.termstxt")}
            
          </div>
          <div className="col-12 text-center mt-2">
                    <button
                      type="submit"
                      className="loginBtn mr-3"
                    >
                     {t("dashboards.alldashboardkeys.tablekeys.cancel")}
                     
                    </button>
                    <button
                      type="submit"
                      className="loginBtn"
                    >
                     
                     {t("dashboards.alldashboardkeys.tablekeys.accept")}
                     
                    </button>
                  </div>
            </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TermsNCondition;
