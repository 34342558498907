import React, { useEffect, useState } from "react";
import carousel1 from "../../../statics/toticellimages/Slider/1.jpg";
import carousel2 from "../../../statics/toticellimages/Slider/2.jpg";
import carousel3 from "../../../statics/toticellimages/Slider/3.jpg";
import carousel4 from "../../../statics/toticellimages/Slider/4.jpg";
import carousel5 from "../../../statics/toticellimages/Slider/5.jpg";
import ReactDOM from "react-dom";

import { Link } from "react-router-dom";
import useScript from "../../reuseablecomponents/useScript";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../utils/functions";
const Slider = (props) => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  let data = filterComponentData(component, "home_main_slider", lang);
  let homeBannerAltTags = filterComponentData(
    component,
    "home_page_image_alt_tags",
    lang
  );

  const { t } = useTranslation("common");
  useScript("revolution/js/scriptproperties.js");
  function test() {
    return !lang ? "English" : "Bangla";
  }

  return (
    <>
      {!lang || lang ? (
        <div className="main-slider">
          <section className="example">
            <article className="content">
              <div
                id="rev_slider_486_1_wrapper"
                className="rev_slider_wrapper fullwidthbanner-container"
                data-alias="news-gallery36"
                data-source="gallery"
                style={{
                  margin: "0px auto",
                  backgroundColor: "#ffffff",
                  padding: "0px",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                <div
                  id="rev_slider_486_1"
                  className="rev_slider fullwidthabanner"
                  style={{ display: "none" }}
                  data-version="5.4.1"
                >
                  <ul style={{ height: "350px!important", overflow: "hidden" }}>
                    <li
                      style={{ height: "10px!important" }}
                      data-index="rs-1687"
                      data-transition="parallaxvertical"
                      data-slotamount="default"
                      data-hideafterloop="0"
                      data-hideslideonmobile="off"
                      data-easein="default"
                      data-easeout="default"
                      data-masterspeed="default"
                      data-thumb="assets/images/newscarousel1-100x50.jpg"
                      data-rotate="0"
                      data-fstransition="fade"
                      data-fsmasterspeed="1500"
                      data-fsslotamount="7"
                      data-saveperformance="off"
                      data-title={
                        data[0]?.sideheading
                          ? data[0]?.sideheading
                          : t("home.mainslider.ourphysicians")
                      }
                      data-param1=""
                      data-param2=""
                      data-param3=""
                      data-param4=""
                      data-param5=""
                      data-param6=""
                      data-param7=""
                      data-param8=""
                      data-param9=""
                      data-param10=""
                      data-description={
                        data[0]?.sidedescription
                          ? data[0]?.sidedescription
                          : t("home.mainslider.totiregenerativeclinic")
                      }
                    >
                      <img
                        // alt="carousal pic"
                        alt={
                          homeBannerAltTags?.classRegenrative
                            ? homeBannerAltTags?.classRegenrative
                            : "carousal pic"
                        }
                        src={data[0]?.image ? data[0]?.image : carousel1}
                        data-bgposition="center center"
                        data-bgfit="cover"
                        data-bgrepeat="no-repeat"
                        data-bgparallax="10"
                        className="rev-slidebg"
                        data-no-retina
                      />
                      <div
                        className="tp-caption tp-shape tp-shapewrapper   tp-resizeme"
                        id="slide-1687-layer-3"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['0','0','0','0']"
                        data-width="full"
                        data-height="full"
                        data-whitespace="normal"
                        data-type="shape"
                        data-basealign="slide"
                        data-responsive_offset="on"
                        data-frames='[{"from":"opacity:0;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: "5",
                          textTransform: "left",
                          backgroundColor: "rgba(0, 0, 0, 0.4)",
                          borderColor: "rgba(0, 0, 0, 1.00)",
                          borderWidth: "0px",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="tp-caption Newspaper-Title   tp-resizeme"
                        id="slide-1687-layer-1"
                        data-x="['left','left','left','left']"
                        data-hoffset="['50','50','50','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['165','135','105','130']"
                        data-fontsize="['26','26','26','36']"
                        data-lineheight="['30','55','55','35']"
                        data-width="['600','600','600','420']"
                        data-height="none"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[10,10,10,10]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: "6",
                          minWidth: "600px",
                          textTransform: "left",
                          whiteSpace: "normal",
                          marginTop: "20px",
                          fontSize: "20px!important",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "26px !important",
                            lineHeight: "20px !important",
                          }}
                        >
                          {data[0]?.heading
                            ? data[0]?.heading
                            : t("home.mainslider.ourphysicians1")}
                        </span>
                      </div>
                      <div
                        className="tp-caption Newspaper-Subtitle   tp-resizeme"
                        id="slide-1687-layer-2"
                        data-x="['left','left','left','left']"
                        data-hoffset="['50','50','50','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['130','110','80','100']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: "7",
                          textTransform: "left",
                          whiteSpace: "nowrap",
                          marginTop: "30px",
                          fontWeight: "600px!important",
                        }}
                      >
                        {data[0]?.description
                          ? data[0]?.description
                          : t("home.mainslider.toticellregenerativeclinic")}
                      </div>
                      <a
                        href="#ourDoc"
                        className="tp-caption Newspaper-Button rev-btn "
                        id="slide-1687-layer-5"
                        data-x="['left','left','left','left']"
                        data-hoffset="['53','53','53','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['290','290','260','225']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="button"
                        data-responsive_offset="on"
                        data-responsive="off"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[12,12,12,12]"
                        data-paddingright="[35,35,35,35]"
                        data-paddingbottom="[12,12,12,12]"
                        data-paddingleft="[35,35,35,35]"
                        style={{
                          zIndex: "8",
                          textTransform: "left",
                          whiteSpace: "nowrap",
                          outline: "none",
                          marginTop: "0px",
                          fontWeight: "600px!important",
                          boxShadow: "none",
                          boxSizing: "border-box",
                          cursor: "pointer",
                        }}
                      >
                        {data[0]?.buttonname
                          ? data[0]?.buttonname
                          : t("home.mainslider.readmore")}
                      </a>
                    </li>
                    <li
                      data-index="rs-1688"
                      data-transition="parallaxvertical"
                      data-slotamount="default"
                      data-hideafterloop={0}
                      data-hideslideonmobile="off"
                      data-easein="default"
                      data-easeout="default"
                      data-masterspeed="default"
                      data-thumb="assets/images/newscarousel2-100x50.jpg"
                      data-rotate={0}
                      data-saveperformance="off"
                      data-title={
                        data[1]?.sideheading
                          ? data[1]?.sideheading
                          : t("home.mainslider.totiregenerativehealthcare")
                      }
                      data-param1
                      data-param2
                      data-param3
                      data-param4
                      data-param5
                      data-param6
                      data-param7
                      data-param8
                      data-param9
                      data-param10
                      data-description={
                        data[1]?.sidedescription
                          ? data[1]?.sidedescription
                          : t("home.mainslider.ourcorevaluebaseon")
                      }
                    >
                      {/* MAIN IMAGE */}
                      <img
                        src={data[1]?.image ? data[1]?.image : carousel2}
                        // alt="slider pic 2"
                        alt={
                          homeBannerAltTags?.advancedRegenerative
                            ? homeBannerAltTags?.advancedRegenerative
                            : "slider pic 2"
                        }
                        data-bgposition="center center"
                        data-bgfit="cover"
                        data-bgrepeat="no-repeat"
                        data-bgparallax={10}
                        className="rev-slidebg"
                        data-no-retina
                      />
                      {/* LAYERS */}
                      {/* LAYER NR. 5 */}
                      <div
                        className="tp-caption tp-shape tp-shapewrapper   tp-resizeme"
                        id="slide-1688-layer-3"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['0','0','0','0']"
                        data-width="full"
                        data-height="full"
                        data-whitespace="normal"
                        data-type="shape"
                        data-basealign="slide"
                        data-responsive_offset="on"
                        data-frames='[{"from":"opacity:0;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 9,
                          textTransform: "left",
                          backgroundColor: "rgba(0, 0, 0, 0.4)",
                          borderColor: "rgba(0, 0, 0, 1.00)",
                          borderWidth: "0px",
                        }}
                      >
                        {" "}
                      </div>
                      {/* LAYER NR. 6 */}
                      <div
                        className="tp-caption Newspaper-Title   tp-resizeme"
                        id="slide-1688-layer-1"
                        data-x="['left','left','left','left']"
                        data-hoffset="['50','50','50','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['165','135','105','130']"
                        data-fontsize="['26','26','26','20']"
                        data-lineheight="['30','30','55','35']"
                        data-width="['600','600','600','420']"
                        data-height="none"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[10,10,10,10]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 10,
                          minWidth: "600px",
                          maxWidth: "600px",
                          whiteSpace: "normal",
                          textTransform: "left",
                          marginTop: "20px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "26px!important",
                            lineHeight: "20px!important",
                          }}
                        >
                          {data[1]?.heading
                            ? data[1]?.heading
                            : t(
                                "home.mainslider.ourcorevalueinformulationofcell"
                              )}
                        </span>
                      </div>
                      {/* LAYER NR. 7 */}
                      <div
                        className="tp-caption Newspaper-Subtitle   tp-resizeme"
                        id="slide-1688-layer-2"
                        data-x="['left','left','left','left']"
                        data-hoffset="['50','50','50','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['130','110','80','100']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 11,
                          whiteSpace: "nowrap",
                          textTransform: "left",
                          marginTop: "30px",
                          fontWeight: "600!important",
                        }}
                      >
                        {data[1]?.description
                          ? data[1]?.description
                          : t(
                              "home.mainslider.totiregenerativehealthcarecenter"
                            )}
                      </div>
                      {/* LAYER NR. 8 */}
                      <a
                        href="#ourFac"
                        className="tp-caption Newspaper-Button rev-btn "
                        id="slide-1688-layer-5"
                        data-x="['left','left','left','left']"
                        data-hoffset="['53','53','53','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['260','290','270','245']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="button"
                        data-responsive_offset="on"
                        data-responsive="off"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[12,12,12,12]"
                        data-paddingright="[35,35,35,35]"
                        data-paddingbottom="[12,12,12,12]"
                        data-paddingleft="[35,35,35,35]"
                        style={{
                          zIndex: 12,
                          whiteSpace: "nowrap",
                          textTransform: "left",
                          outline: "none",
                          boxShadow: "none",
                          boxSizing: "border-box",
                          MozBoxSizing: "border-box",
                          WebkitBoxSizing: "border-box",
                          cursor: "pointer",
                          marginTop: "0px",
                        }}
                      >
                        {data[1]?.buttonname
                          ? data[1]?.buttonname
                          : t("home.mainslider.readmore")}
                      </a>
                    </li>
                    ;{/* SLIDE  */}
                    <li
                      data-index="rs-1689"
                      data-transition="parallaxvertical"
                      data-slotamount="default"
                      data-hideafterloop={0}
                      data-hideslideonmobile="off"
                      data-easein="default"
                      data-easeout="default"
                      data-masterspeed="default"
                      data-thumb="assets/images/newscarousel6-100x50.jpg"
                      data-rotate={0}
                      data-saveperformance="off"
                      data-title={
                        data[2]?.sideheading
                          ? data[2]?.sideheading
                          : t(
                              "home.mainslider.cellulartherapyforpainmanagement"
                            )
                      }
                      data-param1
                      data-param2
                      data-param3
                      data-param4
                      data-param5
                      data-param6
                      data-param7
                      data-param8
                      data-param9
                      data-param10
                      data-description={
                        data[2]?.sidedescription
                          ? data[2]?.sidedescription
                          : t("home.mainslider.regwaytotreatmsk")
                      }
                    >
                      {/* MAIN IMAGE */}
                      <img
                        src={data[2]?.image ? data[2]?.image : carousel3}
                        // alt="slider pic 3"
                        alt={
                          homeBannerAltTags?.cellularPain
                            ? homeBannerAltTags?.cellularPain
                            : "slider pic 3"
                        }
                        data-bgposition="center center"
                        data-bgfit="cover"
                        data-bgrepeat="no-repeat"
                        data-bgparallax={10}
                        className="rev-slidebg"
                        data-no-retina
                      />
                      {/* LAYERS */}
                      {/* LAYER NR. 9 */}
                      <div
                        className="tp-caption tp-shape tp-shapewrapper   tp-resizeme"
                        id="slide-1689-layer-3"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['0','0','0','0']"
                        data-width="full"
                        data-height="full"
                        data-whitespace="normal"
                        data-type="shape"
                        data-basealign="slide"
                        data-responsive_offset="on"
                        data-frames='[{"from":"opacity:0;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 13,
                          textTransform: "left",
                          backgroundColor: "rgba(0, 0, 0, 0.4)",
                          borderColor: "rgba(0, 0, 0, 1.00)",
                          borderWidth: "0px",
                        }}
                      >
                        {" "}
                      </div>
                      {/* LAYER NR. 10 */}
                      <div
                        className="tp-caption Newspaper-Title   tp-resizeme"
                        id="slide-1689-layer-1"
                        data-x="['left','left','left','left']"
                        data-hoffset="['50','50','50','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['165','135','105','130']"
                        data-fontsize="['26','26','26','20']"
                        data-lineheight="['30','55','55','35']"
                        data-width="['600','600','600','420']"
                        data-height="none"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[10,10,10,10]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 14,
                          minWidth: "600px",
                          maxWidth: "600px",
                          whiteSpace: "normal",
                          textTransform: "left",
                          marginTop: "20px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "26px!important",
                            lineHeight: "20px!important",
                          }}
                        >
                          {data[2]?.heading
                            ? data[2]?.heading
                            : t("home.mainslider.regwaytotreatmsk")}
                        </span>
                      </div>
                      {/* LAYER NR. 11 */}
                      <div
                        className="tp-caption Newspaper-Subtitle   tp-resizeme"
                        id="slide-1689-layer-2"
                        data-x="['left','left','left','left']"
                        data-hoffset="['50','50','50','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['130','110','80','100']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 15,
                          whiteSpace: "nowrap",
                          textTransform: "left",
                          marginTop: "30px",
                          fontWeight: "600!important",
                        }}
                      >
                        {data[2]?.description
                          ? data[2]?.description
                          : t(
                              "home.mainslider.cellulartherapyforpainmanagement"
                            )}
                      </div>
                      {/* LAYER NR. 12 */}
                      <Link
                        to="/pain-management"
                        className="tp-caption Newspaper-Button rev-btn "
                        id="slide-1689-layer-5"
                        data-x="['left','left','left','left']"
                        data-hoffset="['53','53','53','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['240','280','260','225']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="button"
                        data-responsive_offset="on"
                        data-responsive="off"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[12,12,12,12]"
                        data-paddingright="[35,35,35,35]"
                        data-paddingbottom="[12,12,12,12]"
                        data-paddingleft="[35,35,35,35]"
                        style={{
                          zIndex: 16,
                          whiteSpace: "nowrap",
                          textTransform: "left",
                          outline: "none",
                          boxShadow: "none",
                          boxSizing: "border-box",
                          MozBoxSizing: "border-box",
                          WebkitBoxSizing: "border-box",
                          cursor: "pointer",
                          marginTop: "0px",
                        }}
                      >
                        {data[2]?.buttonname
                          ? data[2]?.buttonname
                          : t("home.mainslider.readmore")}
                      </Link>
                    </li>
                    ;{/* SLIDE  */}
                    <li
                      data-index="rs-1690"
                      data-transition="parallaxvertical"
                      data-slotamount="default"
                      data-hideafterloop={0}
                      data-hideslideonmobile="off"
                      data-easein="default"
                      data-easeout="default"
                      data-masterspeed="default"
                      data-thumb="assets/images/newscarousel5-100x50.jpg"
                      data-rotate={0}
                      data-saveperformance="off"
                      data-title={
                        data[3]?.sideheading
                          ? data[3]?.sideheading
                          : t("home.mainslider.cellulartherapyforintimate")
                      }
                      data-param1
                      data-param2
                      data-param3
                      data-param4
                      data-param5
                      data-param6
                      data-param7
                      data-param8
                      data-param9
                      data-param10
                      data-description={
                        data[3]?.sidedescription
                          ? data[3]?.sidedescription
                          : t("home.mainslider.naturalwaytosex")
                      }
                    >
                      {/* MAIN IMAGE */}
                      <img
                        src={data[3]?.image ? data[3]?.image : carousel4}
                        data-bgposition="center center"
                        // alt="carousalpic"
                        alt={
                          homeBannerAltTags?.cellularIntimate
                            ? homeBannerAltTags?.cellularIntimate
                            : "carousalpic"
                        }
                        data-bgfit="cover"
                        data-bgrepeat="no-repeat"
                        data-bgparallax={10}
                        className="rev-slidebg"
                        data-no-retina
                      />
                      {/* LAYERS */}
                      {/* LAYER NR. 13 */}
                      <div
                        className="tp-caption tp-shape tp-shapewrapper   tp-resizeme"
                        id="slide-1690-layer-3"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['0','0','0','0']"
                        data-width="full"
                        data-height="full"
                        data-whitespace="normal"
                        data-type="shape"
                        data-basealign="slide"
                        data-responsive_offset="on"
                        data-frames='[{"from":"opacity:0;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 17,
                          textTransform: "left",
                          backgroundColor: "rgba(0, 0, 0, 0.4)",
                          borderColor: "rgba(0, 0, 0, 1.00)",
                          borderWidth: "0px",
                        }}
                      >
                        {" "}
                      </div>
                      {/* LAYER NR. 14 */}
                      <div
                        className="tp-caption Newspaper-Title   tp-resizeme"
                        id="slide-1690-layer-1"
                        data-x="['left','left','left','left']"
                        data-hoffset="['50','50','50','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['165','135','105','130']"
                        data-fontsize="['26','26','26','20']"
                        data-lineheight="['20','20','20','20']"
                        data-width="['600','600','600','420']"
                        data-height="none"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[10,10,10,10]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 18,
                          minWidth: "600px",
                          maxWidth: "600px",
                          whiteSpace: "normal",
                          textTransform: "left",
                          marginTop: "20px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "26px!important",
                            lineHeight: "20px!important",
                          }}
                        >
                          {data[3]?.heading
                            ? data[3]?.heading
                            : t("home.mainslider.naturalwaytosex")}
                        </span>
                      </div>
                      {/* LAYER NR. 15 */}
                      <div
                        className="tp-caption Newspaper-Subtitle   tp-resizeme"
                        id="slide-1690-layer-2"
                        data-x="['left','left','left','left']"
                        data-hoffset="['50','50','50','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['130','110','80','100']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 19,
                          whiteSpace: "nowrap",
                          textTransform: "left",
                          marginTop: "30px",
                          fontWeight: "600!important",
                        }}
                      >
                        {data[3]?.description
                          ? data[3]?.description
                          : t(
                              "home.mainslider.cellulartherapyforpainmanagement"
                            )}
                      </div>
                      {/* LAYER NR. 16 */}
                      <Link
                        to="/asthetic-medicine"
                        className="tp-caption Newspaper-Button rev-btn "
                        id="slide-1690-layer-5"
                        data-x="['left','left','left','left']"
                        data-hoffset="['53','53','53','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['240','280','260','225']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="button"
                        data-responsive_offset="on"
                        data-responsive="off"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[12,12,12,12]"
                        data-paddingright="[35,35,35,35]"
                        data-paddingbottom="[12,12,12,12]"
                        data-paddingleft="[35,35,35,35]"
                        style={{
                          zIndex: 20,
                          whiteSpace: "nowrap",
                          textTransform: "left",
                          outline: "none",
                          boxShadow: "none",
                          boxSizing: "border-box",
                          MozBoxSizing: "border-box",
                          WebkitBoxSizing: "border-box",
                          cursor: "pointer",
                          marginTop: "0px",
                        }}
                      >
                        {data[3]?.buttonname
                          ? data[3]?.buttonname
                          : t("home.mainslider.readmore")}
                      </Link>
                    </li>
                    ;{/* SLIDE  */}
                    <li
                      data-index="rs-1691"
                      data-transition="parallaxvertical"
                      data-slotamount="default"
                      data-hideafterloop={0}
                      data-hideslideonmobile="off"
                      data-easein="default"
                      data-easeout="default"
                      data-masterspeed="default"
                      data-thumb="assets/images/newscarousel5-100x50.jpg"
                      data-rotate={0}
                      data-saveperformance="off"
                      data-title={
                        data[4]?.sideheading
                          ? data[4]?.sideheading
                          : t("home.mainslider.cellulartherapyforaestheticrej")
                      }
                      data-param1
                      data-param2
                      data-param3
                      data-param4
                      data-param5
                      data-param6
                      data-param7
                      data-param8
                      data-param9
                      data-param10
                      data-description={
                        data[4]?.sidedescription
                          ? data[4]?.sidedescription
                          : t("home.mainslider.provenaesthetictreatment")
                      }
                    >
                      {/* MAIN IMAGE */}
                      <img
                        src={data[4]?.image ? data[4]?.image : carousel5}
                        alt={
                          homeBannerAltTags?.cellularAesthetic
                            ? homeBannerAltTags?.cellularAesthetic
                            : "carousalpic"
                        }
                        data-bgposition="center center"
                        data-bgfit="cover"
                        data-bgrepeat="no-repeat"
                        data-bgparallax={10}
                        className="rev-slidebg"
                        data-no-retina
                      />
                      {/* LAYERS */}
                      {/* LAYER NR. 13 */}
                      <div
                        className="tp-caption tp-shape tp-shapewrapper   tp-resizeme"
                        id="slide-1690-layer-3"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['0','0','0','0']"
                        data-width="full"
                        data-height="full"
                        data-whitespace="normal"
                        data-type="shape"
                        data-basealign="slide"
                        data-responsive_offset="on"
                        data-frames='[{"from":"opacity:0;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 17,
                          textTransform: "left",
                          backgroundColor: "rgba(0, 0, 0, 0.4)",
                          borderColor: "rgba(0, 0, 0, 1.00)",
                          borderWidth: "0px",
                        }}
                      >
                        {" "}
                      </div>
                      {/* LAYER NR. 14 */}
                      <div
                        className="tp-caption Newspaper-Title   tp-resizeme"
                        id="slide-1690-layer-1"
                        data-x="['left','left','left','left']"
                        data-hoffset="['50','50','50','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['165','135','105','130']"
                        data-fontsize="['26','26','26','20']"
                        data-lineheight="['20','20','20','20']"
                        data-width="['600','600','600','420']"
                        data-height="none"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[10,10,10,10]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 18,
                          minWidth: "600px",
                          maxWidth: "600px",
                          whiteSpace: "normal",
                          textTransform: "left",
                          marginTop: "20px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "26px!important",
                            lineHeight: "20px!important",
                          }}
                        >
                          {data[4]?.heading
                            ? data[4]?.heading
                            : t("home.mainslider.provenaesthetictreatment")}
                        </span>
                      </div>
                      {/* LAYER NR. 15 */}
                      <div
                        className="tp-caption Newspaper-Subtitle   tp-resizeme"
                        id="slide-1690-layer-2"
                        data-x="['left','left','left','left']"
                        data-hoffset="['50','50','50','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['130','110','80','100']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 19,
                          whiteSpace: "nowrap",
                          textTransform: "left",
                          marginTop: "30px",
                          fontWeight: "600!important",
                        }}
                      >
                        {data[4]?.description
                          ? data[4]?.description
                          : t("home.mainslider.cellulartherapyforaestheticrej")}
                      </div>
                      {/* LAYER NR. 16 */}
                      <Link
                        to="/facial-rejuvenation"
                        className="tp-caption Newspaper-Button rev-btn "
                        id="slide-1690-layer-5"
                        data-x="['left','left','left','left']"
                        data-hoffset="['53','53','53','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['240','280','260','225']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="button"
                        data-responsive_offset="on"
                        data-responsive="off"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[12,12,12,12]"
                        data-paddingright="[35,35,35,35]"
                        data-paddingbottom="[12,12,12,12]"
                        data-paddingleft="[35,35,35,35]"
                        style={{
                          zIndex: 20,
                          whiteSpace: "nowrap",
                          textTransform: "left",
                          outline: "none",
                          boxShadow: "none",
                          boxSizing: "border-box",
                          MozBoxSizing: "border-box",
                          WebkitBoxSizing: "border-box",
                          cursor: "pointer",
                          marginTop: "0px",
                        }}
                      >
                        {data[4]?.buttonname
                          ? data[4]?.buttonname
                          : t("home.readmore")}
                      </Link>
                    </li>
                  </ul>
                  <div
                    className="tp-bannertimer tp-bottom"
                    style={{
                      height: "5px",
                      backgroundColor: "rgba(166, 216, 236, 1.00)",
                    }}
                  ></div>
                </div>
              </div>
            </article>
          </section>
        </div>
      ) : null}
    </>
  );
};

export default Slider;
