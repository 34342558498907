import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
    addMedicine,
    updateMedicine,
} from "../../../../store/actions/pharmacyActions";
import { fetchStaticData } from "../../../../store/actions/staticActions";

const EditPharmacyMedicine = (props) => {
    const { t } = useTranslation("common")
    const selectedMedi = useSelector((state) => state?.pharmacy?.selectedMedi);
    const [stateMedi, setstateMedi] = useState({});
    const [checkBox, setcheckBox] = useState(false);
    const [route, setRoute] = useState([]);
    const [measure, setMeasure] = useState([]);
    const [type, setType] = useState([]);

    const dispatch = useDispatch();
    const mediTypeStaticData = useSelector((state) => state?.static?.staticData?.medicine_type);
    const mediRouteStaticData = useSelector((state) => state?.static?.staticData?.medicine_route);
    const mediUnitStaticData = useSelector((state) => state?.static?.staticData?.medicine_unit_of_measure);
    useEffect(() => {
        dispatch(fetchStaticData());
        setRoute([props?.data?.route])
        setMeasure([props?.data?.unit_of_measure])
        setType([props?.data?.medicine_type])
        if (selectedMedi?.show) {
            setstateMedi(selectedMedi?.data);
            setcheckBox(selectedMedi?.data?.discountable);
        } else {
            setstateMedi({});
            setcheckBox(false);
        }
    }, [selectedMedi]);

    const onSubmit = (data) => {
        const apiData = {
            name: stateMedi?.name,
            formula: stateMedi?.formula,
            currency: { id: 1, name: "bdt" },
            quantity: Number(stateMedi?.quantity),
            item_id: stateMedi?.item_id,
            unit_of_measure: measure[0]?.name,
            route: route[0]?.name,
            medicine_type: type[0]?.name,
            sales_price: Number(stateMedi?.sales_price),
            purchase_price: Number(stateMedi?.purchase_price),
            discountable: checkBox,
            manufacturer_name: stateMedi?.manufacturer_name


        };
        if (selectedMedi?.show) {
            dispatch(updateMedicine({ ...apiData, id: stateMedi?.id }));
            setstateMedi({});
        } else if (!selectedMedi?.show) {
            dispatch(addMedicine(apiData));
        }
        props.onHide();
    };
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        {selectedMedi?.show ? t("dashboards.alldashboardkeys.tablekeys.updatemedicine") : t("dashboards.alldashboardkeys.tablekeys.addmedicine")}
                    </Modal.Title>
                    <button type="button" class="close" onClick={props?.onHide}>
                        <span aria-hidden="true">×</span>
                        <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row px-3">
                        <div className="row">
                            <div className="col-lg-12">
                                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.medname")}</span>
                                <input
                                    className="input-login-modal"
                                    type="text"
                                    value={stateMedi?.name || ""}
                                    onChange={(e) =>
                                        setstateMedi({ ...stateMedi, name: e.target.value })
                                    }
                                />
                            </div>
                            <div className="col-lg-12">
                                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.medformulaname")}</span>
                                <input
                                    className="input-login-modal"
                                    type="text"
                                    value={stateMedi?.formula || ""}
                                    onChange={(e) =>
                                        setstateMedi({
                                            ...stateMedi,
                                            formula: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="col-lg-6">
                                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.medquantity")}</span>
                                <input
                                    className="input-login-modal"
                                    type="number"
                                    value={stateMedi?.quantity || ""}
                                    onChange={(e) =>
                                        setstateMedi({
                                            ...stateMedi,
                                            quantity: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="col-lg-6">
                                <span className="label-name-login">
                                    {t("dashboards.alldashboardkeys.tablekeys.medtypetablet")}
                                </span>
                                {
                                    type[0] == undefined && type?.length > 0 ? <Loader
                                        height={20}
                                        width={20}
                                        type="Oval"
                                        color="#1a4774"
                                    /> : <Typeahead
                                        id="basic-typeahead-single"
                                        labelKey="name"
                                        placeholder=""
                                        selected={type || []}
                                        onChange={(selected) => {
                                            setType(selected)
                                            // setstateMedi({
                                            //     ...stateMedi,
                                            //     medicine_type: selected,
                                            // });
                                        }}
                                        options={mediTypeStaticData}
                                    />
                                }
                                {/*  */}
                            </div>
                            <div className="col-lg-6">
                                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.purchaseprice")}</span>
                                <input
                                    className="input-login-modal"
                                    value={stateMedi?.purchase_price || ""}
                                    type="number"
                                    onChange={(e) =>
                                        setstateMedi({
                                            ...stateMedi,
                                            purchase_price: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="col-lg-6">
                                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.salesprice")}</span>
                                <input
                                    className="input-login-modal"
                                    type="number"
                                    value={stateMedi?.sales_price || ""}
                                    onChange={(e) =>
                                        setstateMedi({
                                            ...stateMedi,
                                            sales_price: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="col-lg-12 ">
                                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.routeformedicine")}</span>
                                {route[0] == undefined && route?.length > 0 ? <Loader
                                    height={20}
                                    width={20}
                                    type="Oval"
                                    color="#1a4774"
                                /> : <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="name"
                                    placeholder=""
                                    selected={route || ""}
                                    onChange={(selected) => {
                                        setRoute(selected)
                                    }}
                                    options={mediRouteStaticData}
                                />}

                            </div>
                            <div className="col-lg-6 mt-3">
                                <span className="label-name-login">{"Manufacturer Name"}</span>
                                <input
                                    className="input-login-modal"
                                    type="text"
                                    value={stateMedi?.manufacturer_name || ""}
                                    onChange={(e) =>
                                        setstateMedi({
                                            ...stateMedi,
                                            manufacturer_name: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="col-lg-6 mt-3">
                                <span className="label-name-login">
                                    {t("dashboards.alldashboardkeys.tablekeys.unitofmeasure")}
                                </span>
                                {measure[0] == undefined && measure?.length > 0 ? <Loader
                                    height={20}
                                    width={20}
                                    type="Oval"
                                    color="#1a4774"
                                /> : <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="name"
                                    placeholder=""
                                    selected={measure || []}
                                    onChange={(selected) => {
                                        setMeasure(selected)
                                    }}
                                    options={mediUnitStaticData}
                                />}
                            </div>
                            <div className="col-lg-6">
                                <span className="label-name-login">{"ID"}</span>
                                <input
                                    className="input-login-modal"
                                    type="text"
                                    value={stateMedi?.item_id || ""}
                                    onChange={(e) =>
                                        setstateMedi({
                                            ...stateMedi,
                                            item_id: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row px-3">
                        <div className="form-check">
                            <input
                                type="checkbox"
                                checked={checkBox}
                                value={checkBox}
                                onChange={() => setcheckBox(!checkBox)}
                                className="form-check-input"
                                id="exampleCheck1"
                                style={{ width: "15px", height: "15px" }}
                            />
                            <label className="form-check-label" htmlFor="exampleCheck1">
                                {t("dashboards.alldashboardkeys.tablekeys.discountable")}
                            </label>
                        </div>
                    </div>
                    <input
                        type="submit"
                        value={selectedMedi?.show ? t("dashboards.alldashboardkeys.tablekeys.updatemedicine") : t("dashboards.alldashboardkeys.tablekeys.addmedicine")}
                        className="btn btnSquare w-100"
                        style={{ textAlign: "center" }}
                        onClick={onSubmit}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditPharmacyMedicine;
