import React, { useState } from "react";
import BannerWithText from "../../components/layouts/bannerImgComponents/BannerImgComponents";
import { banner, LinksBanContact } from "../../data";

import email from "../../statics/icons/email.png";
import phone from "../../statics/icons/call.png";
import location from "../../statics/icons/location-2.png";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { conatctUse } from "../../store/actions/staticActions";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { filterComponentData } from "../../utils/functions";

const ContactUs = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const contact = filterComponentData(component, "contact_us", lang);
  const contactMetaTitle = filterComponentData(
    component,
    "contact_meta_title",
    lang
  );
  const contactSchemaMarkup = filterComponentData(
    component,
    "contact_schema_markups",
    lang
  );
  const contactMetaDesc = filterComponentData(
    component,
    "contact_meta_description",
    lang
  );
  const contactMetaKeywords = filterComponentData(
    component,
    "contact_meta_keyword",
    lang
  );
  const contactVerification = filterComponentData(
    component,
    "contact_verification_meta_tags",
    lang
  );
  const { t } = useTranslation("common");
  const banners = filterComponentData(component, "banner", lang);

  const LinksBanContact = [
    {
      subLinkName: banners[54]?.subtitle?.split(">")[0]
        ? banners[54]?.subtitle?.split(">")[0]
        : t("contact.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[54]?.subtitle?.split(">")[1]
        ? banners[54]?.subtitle?.split(">")[1]
        : t("contact.mainheading1"),
      subDash: "",
      subLink: "/contact-us",
    },
  ];

  const dispatch = useDispatch();
  const [disableBtn, setdisableBtn] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const onSubmitHandler = async (data) => {
    setdisableBtn(true);
    const apiData = {
      email_body: `<Span>Name: ${data?.username}</Span> <br/> <Span>Email: ${data?.user_email}</Span> <br /> 
       <Span>Phone: ${data?.user_phone}</Span> <br />  <Span>Message: ${data?.user_messages}</Span> <br />`,
    };

    const res = await dispatch(conatctUse(apiData));
    if (res === "true") {
      reset();
      setdisableBtn(false);
    } else if (res === "false") {
      setdisableBtn(false);
    }
  };
  const verificationsTags = contactVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.name,
      content: item?.content,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}
        {contactSchemaMarkup?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(JSON.stringify(item?.contact ? item?.contact : "[]"))}
          </script>
        ))}
        <title>
          {contactMetaTitle?.contactMetaTitle
            ? contactMetaTitle?.contactMetaTitle
            : t("contact.maintitle")}
        </title>
        <meta
          name="description"
          content={
            contactMetaDesc?.contact
              ? contactMetaDesc?.contact
              : t("contact.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            contactMetaKeywords?.contact
              ? contactMetaKeywords?.contact
              : t("contact.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[54]?.heading
              ? banners[54]?.heading
              : t("contact.mainheading1")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[54]?.image ? banners[54]?.image : banner
          })`}
          imgSrc={banners[54]?.image ? banners[54]?.image : banner}
          LinksBan={LinksBanContact}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container">
          <div className="row ">
            <div class="col-md-7">
              <div class="contact-right-2">
                <form
                  id="contact-form"
                  onSubmit={handleSubmit(onSubmitHandler)}
                >
                  <div class="messages"></div>
                  <div class="controls">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <input
                            id="username"
                            type="text"
                            name="username"
                            class="form-control customize"
                            placeholder={t("contact.contatform.field1")}
                            required="required"
                            data-error="Firstname is required."
                            {...register("username", {
                              required: {
                                value: false,
                                message: "this field is required field",
                              },
                            })}
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <input
                            id="user_email"
                            type="email"
                            name="user_email"
                            class="form-control customize"
                            placeholder={t("contact.contatform.field2")}
                            required="required"
                            data-error="Valid email is required."
                            {...register("user_email", {
                              required: {
                                value: false,
                                message: "this field is required field",
                              },
                            })}
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <input
                            id="user_phone"
                            type="tel"
                            name="user_phone"
                            class="form-control customize"
                            required="required"
                            placeholder={t("contact.contatform.field3")}
                            {...register("user_phone", {
                              required: {
                                value: false,
                                message: "this field is required field",
                              },
                            })}
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <textarea
                            id="user_messages"
                            name="user_messages"
                            class="form-control customize"
                            placeholder={t("contact.contatform.field5")}
                            rows="9"
                            required="required"
                            data-error="Please,leave us a message."
                            {...register("user_messages", {
                              required: {
                                value: false,
                                message: "this field is required field",
                              },
                            })}
                          ></textarea>
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>
                            <input
                              type="checkbox"
                              checked="checked"
                              name="subscribe"
                              className="mr-2"
                            />
                            {contact?.privacy
                              ? contact?.privacy
                              : t("contact.contatform.field6")}
                          </label>
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>
                            <input
                              type="checkbox"
                              checked="checked"
                              name="subscribe"
                              className="mr-2"
                            />
                            {contact?.advancments
                              ? contact?.advancments
                              : t("contact.contatform.field7")}
                          </label>
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 center-children-class ">
                        <p>
                          <input
                            type="submit"
                            class="btnRoundedAbout px-4 py-2"
                            value={
                              contact?.button
                                ? contact?.button
                                : t("contact.contatform.submit")
                            }
                            style={{ cursor: "pointer" }}
                            disabled={disableBtn}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="col-md-5">
              <div class="contact-left-2">
                <div class="inner-contact">
                  <div class="span-left-contact">
                    <div class="contact-icon">
                      <img src={location} alt="location icon" />
                    </div>
                    <div class="contact-info">
                      <h6>
                        {contact?.addresslabel
                          ? contact?.addresslabel
                          : t("contact.contactinfo.add")}
                      </h6>
                      <p>
                        {contact?.address
                          ? contact?.address
                          : t("contact.contactinfo.address")}
                      </p>
                    </div>
                  </div>
                  <div class="span-left-contact">
                    <div class="contact-icon">
                      <img src={email} alt="email icon" />
                    </div>
                    <div class="contact-info">
                      <h6>
                        {" "}
                        {contact?.email
                          ? contact?.email
                          : t("contact.contactinfo.emai")}
                      </h6>
                      <p>
                        <a href="mailto:info.@toticell.com">
                          {contact?.emailaddress
                            ? contact?.emailaddress
                            : "info@toticell.com"}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div class="span-left-contact">
                    <div class="contact-icon">
                      <img
                        src={phone}
                        alt="phone icon"
                        height="30px"
                        width="50px"
                      />
                    </div>
                    <div class="contact-info">
                      <h6>
                        {contact?.contact
                          ? contact?.contact
                          : t("contact.contactinfo.contact")}
                      </h6>
                      <p class="roboto">
                        {contact?.phone ? contact?.phone : "01958073604"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="map-container-bottom">
          <iframe
            title="myFrame"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58428.22300923743!2d90.33988817910156!3d23.755796000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b93e8cdfc3d5%3A0x18078b8482e449ee!2sTotiCell%20Health%20%26%20Research!5e0!3m2!1sen!2sin!4v1621068359784!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
