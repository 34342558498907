import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  phoneModalToggle,
  resendPhoneOtp,
  verifyNumber,
} from "../../../../store/actions/authActions";
import { logout } from "../../../../utils/auth.util";
const PhoneConfirmModal = (props) => {
  const {t} = useTranslation("common")
  const user = useSelector((state) => state?.logIn?.user);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const apiData = {
      id: user?.id,
      otp: data.otpMobile,
    };
    const getResponse = await dispatch(verifyNumber(apiData));
    if (getResponse === "success") {
      dispatch(phoneModalToggle(true));
      if (props.register) {
        logout()
        window.location.replace('/')
      }else{
        window.location.replace('/patient-dashboard')

      }
    }
    props.onHide();
  };
  // {t("dashboards.alldashboardkeys.tablekeys.recentnotification")}
  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="dashboardBtnList-item-active">
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-white"> {t("dashboards.alldashboardkeys.tablekeys.numberverification")} </span>
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true" className="text-white">
              ×
            </span>
            <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            <p style={{ fontSize: "13px" }}>
            {t("dashboards.alldashboardkeys.tablekeys.pleaseprovideotpformobile")}
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-12">
                  <span className="label-name-login"> {t("dashboards.alldashboardkeys.tablekeys.otp")}</span>
                  <input
                    className="input-login-modal"
                    type="number"
                    {...register("otpMobile", {
                      required: {
                        value: true,
                        message: "this field is required field",
                      },
                      min: {
                        value: 6,
                        message: "please provide the six digit OTP",
                      },
                    })}
                  />
                  {errors?.otpMobile?.message ? (
                    <div className="text-error">
                      {errors?.otpMobile?.message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <input
                type="submit"
                value={t("dashboards.alldashboardkeys.tablekeys.verify")}
                className="btnSquare btn-block mx-auto"
                style={{ width: "85%", textAlign: "center" }}
              />
            </form>
          </div>
          <div
            className="signIn-link mt-4 "
            to={""}
            style={{
              fontWeight: "500",
              fontSize: "14px",
              color: "black",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(resendPhoneOtp({ id: user?.id,phone_number:user?.phone_number }));
            }}
          >
            {t("dashboards.alldashboardkeys.tablekeys.resendphoneverificationotp")}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PhoneConfirmModal;
