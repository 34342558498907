import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Specialitycard from "../../../../components/layouts/cards/SpeciallityCard/Specialitycard";
import {
  neuro1,
  banner,
  nemenucard1,
  nemenucard2,
  nemenucard3,
  nemenucard4,
  nemenucard5,
  nemenucard6,
  nemenucard7,
} from "../../../../data";
import { filterComponentData } from "../../../../utils/functions";

const NeurologicalRehabilitation = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const cards = filterComponentData(
    component,
    "speciality_neurological_rehabilitation_cards",
    lang
  );
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );

  const { t } = useTranslation("common");
  const LinksBanSpecialityNeuro = [
    {
      subLinkName: banners[32]?.subtitle?.split(">")[0]
        ? banners[32]?.subtitle?.split(">")[0]
        : t("neurologicalrehabilitation.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[32]?.subtitle?.split(">")[1]
        ? banners[32]?.subtitle?.split(">")[1]
        : t("neurologicalrehabilitation.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[32]?.subtitle?.split(">")[2]
        ? banners[32]?.subtitle?.split(">")[2]
        : t("neurologicalrehabilitation.banner.title1"),
      subDash: "",
      subLink: "/neurological-rehabilitation",
    },
  ];

  const neuro1 = [
    {
      img: nemenucard1,
      head1: t("neurologicalrehabilitation.pagemenucard.card1.text"),
      linkroute: "/autism",
    },
    {
      img: nemenucard2,
      head1: t("neurologicalrehabilitation.pagemenucard.card2.text"),
      linkroute: "/muscular-dystrophy",
    },
    {
      img: nemenucard3,
      head1: t("neurologicalrehabilitation.pagemenucard.card3.text"),
      linkroute: "/cerebral-palsy",
    },
    {
      img: nemenucard4,
      head1: t("neurologicalrehabilitation.pagemenucard.card4.text"),
      linkroute: "/spinal-cord-injury",
    },
    {
      img: nemenucard5,
      head1: t("neurologicalrehabilitation.pagemenucard.card5.text"),
      linkroute: "/downs-syndrome",
    },
    {
      img: nemenucard6,
      head1: "Alzheimer's Disease",
      linkroute: "/alzheimer-disease",
    },
    {
      img: nemenucard7,
      head1: "Motor Neuron Disease",
      linkroute: "/motor-neuron-disease",
    },
  ];

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(
                item?.neurologicalMain ? item?.neurologicalMain : "[]"
              )
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.neurologicalMain
            ? specialityMetaTitles?.neurologicalMain
            : t("neurologicalrehabilitation.maintitle")}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.neurologicalMain
              ? specialityMetaDesc?.neurologicalMain
              : t("neurologicalrehabilitation.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.neurologicalMain
              ? specialityMetaKeywords?.neurologicalMain
              : t("neurologicalrehabilitation.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[32]?.heading
              ? banners[32]?.heading
              : t("neurologicalrehabilitation.banner.title1")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[32]?.image ? banners[32]?.image : banner
          })`}
          imgSrc={banners[32]?.image ? banners[32]?.image : banner}
          LinksBan={LinksBanSpecialityNeuro}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container">
          <div className="row ">
            {cards?.length < 1
              ? neuro1.map((item, index) => (
                  <Specialitycard
                    img={item?.img}
                    alt={
                      specialityAltTags?.neurologicalMain
                        ? specialityAltTags?.neurologicalMain
                        : ""
                    }
                    head1={item?.head1}
                    linkroute={item?.linkroute}
                  />
                ))
              : cards?.map((item, index) => (
                  <Specialitycard
                    img={item?.image}
                    alt={
                      specialityAltTags?.neurologicalMain
                        ? specialityAltTags?.neurologicalMain
                        : ""
                    }
                    head1={item?.heading}
                    linkroute={item?.route}
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NeurologicalRehabilitation;
