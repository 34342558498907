import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../components/layouts/bannerImgComponents/BannerImgComponents";
import { banner, LinksBanStories } from "../../data";
import { filterComponentData } from "../../utils/functions";
import painManagementImg from "../../../src/statics/toticellimages/Pain-Management.jpg";
import TextAndImageSection from "../../components/reuseablecomponents/TextAndImageSection";

const BlogDetails = (props) => {
  let data = props?.location?.state;
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const stories = filterComponentData(component, "our_stories", lang);
  const banners = filterComponentData(component, "banner", lang);
  const storiesMetaTitle = filterComponentData(
    component,
    "stories_meta_title",
    lang
  );
  const { t } = useTranslation("common");

  const LinksBanStories = [
    {
      subLinkName: banners[66]?.subtitle?.split(">")[0]
        ? banners[66]?.subtitle?.split(">")[0]
        : t("blog.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[66]?.subtitle?.split(">")[1]
        ? banners[66]?.subtitle?.split(">")[1]
        : t("blog.banner.title"),
      subDash: "",
      subLink: "/blogs",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          {/* {storiesMetaTitle?.stories
            ? storiesMetaTitle?.stories
            : t("stories.maintitle")} */}
          Blogs - Toticell
        </title>
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[66]?.heading ? banners[66]?.heading : t("blog.banner.title")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[66]?.image ? banners[66]?.image : banner
          })`}
          imgSrc={banners[66]?.image ? banners[66]?.image : banner}
          LinksBan={LinksBanStories}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={data?.cardImage}
          // alt={
          //   specialityAltTags?.covidMolecular
          //     ? specialityAltTags?.covidMolecular
          //     : ""
          // }

          imgStyle={{
            height: "90%",
            width: "400px",
          }}
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={data?.blogTitile}
          // headingFirst={
          //   molecular?.heading
          //     ? molecular?.heading
          //     : t("moleculardiagnostic.textrev.headfirst")
          // }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {data?.mainDesc}
              </p>
            </>
          }
          reverse={false}
        />
        <TextAndImageSection
          textCol="col-lg-12"
          // headingFirst={
          //   about?.heading ? about?.heading : t("abouttoti.textrev.headfirst")
          // }
          headingFirst={data?.cardHeading}
          // headingSec={about?.heading ? null : t("abouttoti.textrev.headsecond")}
          discription={
            <>
              <p>{data?.desc1}</p>

              <p>{data?.desc2}</p>

              <p>{data?.desc3}</p>

              <p>{data?.desc4}</p>

              {/* <p>{about?.desc1 ? about?.desc1: t("abouttoti.textrev.discriptiontxt5")}</p> */}
            </>
          }
        />
      </div>
    </>
  );
};

export default BlogDetails;
