import React, { useEffect } from "react";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import { patientPrescriptions } from "../../store/actions/patientActions";
import { useDispatch, useSelector } from "react-redux";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import { useTranslation } from "react-i18next";
const DoctorPrescriptionContainer = (props) => {
  const {t} = useTranslation("common")
  const dispatch = useDispatch();

  const handleView = (value, item) => {
    if (value === t("dashboards.alldashboardkeys.tablekeys.viewbtn")) {
      props.history.push("/prescription-details", item);
    }
  };
  const user = useSelector((state) => state?.logIn?.user);
  const prescription = useSelector((state) => state?.patient?.prescriptions);
  const loading = useSelector((state) => state?.patient?.patientLoader);
  useEffect(() => {
    dispatch(patientPrescriptions({ id: user?.id }));
    // eslint-disable-next-line
  }, []);
  return (
    <div className="w-100">
      <div className="p-3">
        <span className="dashboardHeadingText">{t("dashboards.alldashboardkeys.tablekeys.myprescription")}</span>
      </div>
      {loading ? (
        <LoderContainer />
      ) : (
        <BootstrapCustomTable
          columns={[
            { dataField: "doctor_details.name", text: t("dashboards.alldashboardkeys.tablekeys.doctorname") },
            { dataField: "date", text: t("dashboards.alldashboardkeys.tablekeys.date") },
            { dataField: "diagnosis", text: t("dashboards.alldashboardkeys.tablekeys.diagnoses") },
            {
              formatter: (cell, row, index) => {
                return (
                  <ActionBtn
                    options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                    handleChange={handleView}
                    data={row}
                  />
                  // <div className="w-100  center-children-class">
                  //   <select
                  //     className="action-select-border col-12 col-md-6 w-100"
                  //     onChange={() => handleView(row)}
                  //   >
                  //     <option disabled selected>
                  //       Actions:
                  //     </option>
                  //     <option value="1">View</option>
                  //   </select>
                  // </div>
                );
              },
              text: t("dashboards.alldashboardkeys.tablekeys.action"),
            },
          ]}
          tabledata={prescription}
        />
      )}
    </div>
  );
};

export default DoctorPrescriptionContainer;
