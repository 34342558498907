import React from "react";

const DashBoardMainHading = (props) => {
  return (
    <div className="dashboardMainHaeding-container mb-5">
      <div className="py-4">
        <div className="col-12 text-center p-5 ">
          <h2 className="dashboardMainHeading-text">{props.title}</h2>
        </div>
      </div>
    </div>
  );
};

export default DashBoardMainHading;
