import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import BannerWithText from "../../components/layouts/bannerImgComponents/BannerImgComponents";
import FacilitiesCard from "../../components/layouts/cards/facilityCards/FacilitiesCard";
import Testemonials from "../../components/layouts/testimonials/Testemonials";
import NotificationTab from "../../components/reuseablecomponents/NotificationTab";
import TextAndImageSection from "../../components/reuseablecomponents/TextAndImageSection";
import { banner, LinksBanStories } from "../../data";
import logo from "../../statics/icons/fav.png";

import icon1 from "../../statics/icons/Operation Theater.png";
import icon2 from "../../statics/icons/Patient Appointment.png";
import icon3 from "../../statics/icons/Patient Day Care.png";
import icon4 from "../../statics/icons/Primary Screening.png";
import icon5 from "../../statics/icons/Specialized Consultation.png";
import icon6 from "../../statics/icons/Stem Cell Banking.png";
import icon7 from "../../statics/icons/cGMP Laboratory.png";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../utils/functions";

const OurFacilities = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);
  const our_facilities = filterComponentData(component, "our_facilities", lang);
  const our_facilities_heading = filterComponentData(
    component,
    "our_facilities_heading",
    lang
  );
  const banners = filterComponentData(component, "banner", lang);
  const footerMetaTitles = filterComponentData(
    component,
    "footer_meta_titles",
    lang
  );
  const footerSchemaMarkups = filterComponentData(
    component,
    "footer_schema_markups",
    lang
  );
  const footerMetaDesc = filterComponentData(
    component,
    "footer_meta_descriptions",
    lang
  );
  const footerMetaKeywords = filterComponentData(
    component,
    "footer_meta_keywords",
    lang
  );
  const footerVerification = filterComponentData(
    component,
    "footer_verification_meta_tags",
    lang
  );
  const { t } = useTranslation("common");

  const facility = [
    {
      id: 0,
      imageURL: icon2,
      head: t("facilities.facilitiescard.card1.head"),
      text: t("facilities.facilitiescard.card1.text"),
    },
    {
      id: 1,
      imageURL: icon4,
      head: t("facilities.facilitiescard.card2.head"),
      text: t("facilities.facilitiescard.card2.text"),
    },
    {
      id: 2,
      imageURL: icon5,
      head: t("facilities.facilitiescard.card3.head"),
      text: t("facilities.facilitiescard.card3.text"),
    },
    {
      id: 3,
      imageURL: icon7,
      head: t("facilities.facilitiescard.card4.head"),
      text: t("facilities.facilitiescard.card4.text"),
    },
    {
      id: 3,
      imageURL: icon3,
      head: t("facilities.facilitiescard.card5.head"),
      text: t("facilities.facilitiescard.card5.text"),
    },
    {
      id: 4,
      imageURL: icon1,
      head: t("facilities.facilitiescard.card6.head"),
      text: t("facilities.facilitiescard.card6.text"),
    },
    {
      id: 5,
      imageURL: icon6,
      head: t("facilities.facilitiescard.card7.head"),
      text: t("facilities.facilitiescard.card7.text"),
    },
  ];

  const LinksBanStories = [
    {
      subLinkName: banners[63]?.subtitle?.split(">")[0]
        ? banners[63]?.subtitle?.split(">")[0]
        : t("facilities.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[63]?.subtitle?.split(">")[1]
        ? banners[63]?.subtitle?.split(">")[1]
        : t("facilities.banner.title"),
      subDash: "",
      subLink: "/facilities",
    },
  ];
  const verificationsTags = footerVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.facilitiesName,
      content: item?.facilitiesContent,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}
        {footerSchemaMarkups?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(item?.facilities ? item?.facilities : "[]")
            )}
          </script>
        ))}
        <title>
          {footerMetaTitles?.facilities
            ? footerMetaTitles?.facilities
            : t("facilities.maintitle")}
        </title>
        <meta
          name="description"
          content={
            footerMetaDesc?.facilities
              ? footerMetaDesc?.facilities
              : t("facilities.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            footerMetaKeywords?.facilities
              ? footerMetaKeywords?.facilities
              : t("facilities.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[63]?.heading
              ? banners[63]?.heading
              : t("facilities.banner.title")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[63]?.image ? banners[63]?.image : banner
          })`}
          imgSrc={banner}
          LinksBan={LinksBanStories}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />
        <TextAndImageSection
          stylehead={"#970165"}
          imgopt={
            our_facilities_heading?.image ? our_facilities_heading?.image : logo
          }
          hrTrue={true}
          textCol="col-lg-8"
          headingFirst={
            our_facilities_heading?.title
              ? our_facilities_heading?.title
              : t("facilities.head")
          }
          discription={
            <>
              {our_facilities?.length < 1 ? (
                <div className="mt-5">
                  {facility.map((item) => {
                    return (
                      <FacilitiesCard
                        imgSrc={item?.imageURL}
                        head={item?.head}
                        text={item?.text}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className="mt-5">
                  {our_facilities.map((item) => {
                    return (
                      <FacilitiesCard
                        imgSrc={item?.image}
                        head={item?.heading}
                        text={item?.text}
                      />
                    );
                  })}
                </div>
              )}
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default OurFacilities;
