import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useDispatch} from "react-redux";
import { useForm } from "react-hook-form";
import { conatctUse } from "../../../../store/actions/staticActions";
import { useTranslation } from "react-i18next";
const BookAppointmentModal = (props) => {
  const {t} = useTranslation("common")
  const dispatch = useDispatch();
  const [disableBtn, setdisableBtn] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const onSubmitHandler = async (data) => {
    setdisableBtn(true);
    const apiData = {
      email_body: `<Span>Name: ${data?.username}</Span> <br/> <Span>Email: ${data?.user_email}</Span> <br /> 
         <Span>Phone: ${data?.user_phone}</Span> <br /> <Span>Date: ${data?.date}</Span> <br /> <Span>Doctor: ${data?.doctor}</Span> <br /> <Span>Department: ${data?.department}</Span> <br />  <Span>Message: ${data?.user_messages}</Span> <br />`,
    };
    const res = await dispatch(conatctUse(apiData));
    if (res === "true") {
      reset();
      setdisableBtn(false);
      props?.onHide();
    } else if (res === "false") {
      setdisableBtn(false);
    }
  };

  // {t("dashboards.alldashboardkeys.tablekeys.dispatch")}
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="dashboardBtnList-item-active">
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-white">{t("dashboards.alldashboardkeys.tablekeys.makeanappointment")}</span>
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true" className="text-white">
              ×
            </span>
            <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            <form id="contact-form" onSubmit={handleSubmit(onSubmitHandler)}>
              <div class="messages"></div>
              <div class="controls">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        id="username"
                        type="text"
                        name="username"
                        class="form-control customize"
                        placeholder={t("dashboards.alldashboardkeys.tablekeys.name")}
                        required="required"
                        data-error="Firstname is required."
                        {...register("username", {
                          required: {
                            value: false,
                            message: "this field is required field",
                          },
                        })}
                      />
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        id="user_email"
                        type="email"
                        name="user_email"
                        class="form-control customize"
                        placeholder={t("dashboards.alldashboardkeys.tablekeys.emailaddress")}
                        required="required"
                        data-error="Valid email is required."
                        {...register("user_email", {
                          required: {
                            value: false,
                            message: "this field is required field",
                          },
                        })}
                      />
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        id="user_phone"
                        type="tel"
                        name="user_phone"
                        class="form-control customize"
                        required="required"
                        placeholder={t("dashboards.alldashboardkeys.tablekeys.pleaseenteryourphone")}
                        {...register("user_phone", {
                          required: {
                            value: false,
                            message: "this field is required field",
                          },
                        })}
                      />
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        id="user_date"
                        type="date"
                        name="user_date"
                        class="form-control customize"
                        required="required"
                        placeholder={t("dashboards.alldashboardkeys.tablekeys.pleaseenterdate")}
                        {...register("date", {
                          required: {
                            value: true,
                            message: "this field is required field",
                          },
                        })}
                      />
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-6">
              <Form.Control
                as="select"
                className="form-group dashboards-input"
                id="department"
                custom
                style={{ height: "46px" }}
                {...register("department", {

                    required: {
                        value: true,
                        message: "this field is required field",
                      },

                })}
              >
              
                    <option disabled selected>
                    {t("dashboards.alldashboardkeys.tablekeys.selectdepartment")}
                    </option>
                    <option value={t("dashboards.alldashboardkeys.tablekeys.regenerativenbiologicalmedicine")} >
                    {t("dashboards.alldashboardkeys.tablekeys.regenerativenbiologicalmedicine")}
                    </option>
                    <option value={t("dashboards.alldashboardkeys.tablekeys.aestheticdepartment")} >
                    {t("dashboards.alldashboardkeys.tablekeys.aestheticdepartment")}
                    </option>
              
              </Form.Control>
              <div class="help-block with-errors"></div>

              
            </div>
                  <div className="col-lg-6">
              <Form.Control
                as="select"
                className="form-group dashboards-input"
                id="doctor"
                custom
                style={{ height: "46px" }}
                {...register("doctor", {

                    required: {
                        value: true,
                        message: "this field is required field",
                      },

                })}
              >
              
                    <option disabled selected>
                    {t("dashboards.alldashboardkeys.tablekeys.selectdoctor")}
                    </option>
                    <option value={t("dashboards.alldashboardkeys.tablekeys.drrezaulkarim")} >
                    {t("dashboards.alldashboardkeys.tablekeys.drrezaulkarim")}
                    </option>
                    <option value={t("dashboards.alldashboardkeys.tablekeys.drkhaledaadibbinteabdullah")} >
                    {t("dashboards.alldashboardkeys.tablekeys.drkhaledaadibbinteabdullah")}
                    </option>
                    <option value={t("dashboards.alldashboardkeys.tablekeys.drasmatasnimkhan")} >
                    {t("dashboards.alldashboardkeys.tablekeys.drasmatasnimkhan")}
                    </option>
              
              </Form.Control>
              <div class="help-block with-errors"></div>

              
            </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <textarea
                        id="user_messages"
                        name="user_messages"
                        class="form-control customize"
                        placeholder={t("dashboards.alldashboardkeys.tablekeys.yourmessage")}
                        rows="9"
                        required="required"
                        data-error="Please,leave us a message."
                        {...register("user_messages", {
                          required: {
                            value: false,
                            message: "this field is required field",
                          },
                        })}
                      ></textarea>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 center-children-class ">
                    <p>
                      <input
                        type="submit"
                        class="btnRoundedAbout px-4 py-2"
                        value={t("dashboards.alldashboardkeys.tablekeys.submit")}
                        style={{ cursor: "pointer" }}
                        disabled={disableBtn}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BookAppointmentModal;
