import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import { readTestReport } from "../../store/actions/patientActions";
export default function MyReports() {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.logIn?.user);
  const reports = useSelector((state) => state?.patient?.reports);
  const loader = useSelector((state) => state?.patient?.patientLoader);


  useEffect(() => {
    dispatch(readTestReport({ id: user?.id }));
    // eslint-disable-next-line
  }, [dispatch]);
  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">
            {t("dashboards.alldashboardkeys.tablekeys.myreport")}
          </h4>
        </div>
      </div>

      <div className="row">
        {loader ? (
          <LoderContainer />
        ) : (
          <BootstrapCustomTable
            columns={[
              {
                formatter: (cell, row) => {
                  return <span>{moment(row.date).format("DD/MM/YYYY")}</span>;
                },
                text: t("dashboards.alldashboardkeys.tablekeys.date"),
              },
              {
                dataField: "test.name",
                text: t("dashboards.alldashboardkeys.tablekeys.testname"),
              },
              {
                dataField: "test.price",
                text: t("dashboards.alldashboardkeys.tablekeys.price"),
              },
              {
                formatter: (cell, row) => {
                  return (
                    <span
                      style={{
                        color:
                          row?.status?.name == "pending"
                            ? "red"
                            : row?.status?.name == "report uploaded"
                            ? "green"
                            : "#1a4774",
                      }}
                    >
                      {row?.status?.name?.toUpperCase()}
                    </span>
                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.teststatus"),
              },

              {
                formatter: (cell, row, index) => {
                  return (
                    <a
                      className={
                        row?.status?.name === "report uploaded" &&
                        row?.url != ""
                          ? "btn btnSquare w-100"
                          : "btn btn-block btn-light  disableBtn"
                      }
                      disable
                      href={row?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.viewreport")}
                    </a>
                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.action"),
              },
            ]}
            tabledata={reports}
          />
        )}
      </div>
    </div>
  );
}
