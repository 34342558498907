import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { BASEURL } from "../../../../services/HttpProvider";
import sample_pharmacy_data from "../../../../statics/files/sample_pharmacy_data.csv";

const UploadMedicineModal = (props) => {
  const {t} = useTranslation("common")
  const [file, setFile] = useState();
  const uploadFile = async (event) => {
    event.preventDefault();

    let formdata = new FormData();
    const authToken = JSON.parse(localStorage.getItem("toticelltoken"));
    if (file === undefined || file === null) {
      toast.error(t("toastmessages.pleaseuploadreport"));
      return;
    } else {
      formdata.append("file", file[0]);
      let res = await axios.post(
        `${BASEURL}medicine/insert-file`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-session-key": authToken.key,
            "x-session-type": authToken.type,
          },
        }
      );
      if (res?.data?.response_code === 200) {
        toast.success(t("toastmessages.medicineuploadedsuccess"));
        props?.onHide();
      } else {
        toast.success(res?.data?.response_message);
      }
    }
  };
  // {t("dashboards.alldashboardkeys.tablekeys.accept")}
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
          {t("dashboards.alldashboardkeys.tablekeys.uploadmedicinefile")}
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true">×</span>
            <span class="sr-only"> {t("dashboards.alldashboardkeys.tablekeys.close")}</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 w-100 d-flex justify-content-end">
            <a
              className="btn btnSquare"
              href={sample_pharmacy_data}
              download={sample_pharmacy_data}
            >
              {t("dashboards.alldashboardkeys.tablekeys.downloadsampledate")}
            </a>
          </div>
          <input
            type="file"
            className="form-control"
            name="upload_file"
            onChange={(e) => {
              setFile(e.target.files);
            }}
          />
          <div className="w-100 d-flex justify-content-end">
            <div
              className="btn btnSquare mt-3"
              onClick={(e) => {
                uploadFile(e);
              }}
            >
              {t("dashboards.alldashboardkeys.tablekeys.upload")}
            </div>
            <div
              className="btn btnSquare mt-3 ml-3"
              onClick={() => {
                props?.onHide();
              }}
            >
              {t("dashboards.alldashboardkeys.tablekeys.cancel")}
              
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadMedicineModal;
