import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerWithText from "../../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Testemonials from "../../../../../components/layouts/testimonials/Testemonials";
import TabBtnList from "../../../../../components/layouts/tabbuttons/DashboardBtnList";
import TabHandlerTextwithImage from "../../../../../components/reuseablecomponents/TabHandlerTextwithImage";
import TextAndImageSection from "../../../../../components/reuseablecomponents/TextAndImageSection";
import {
  banner,
  cttreatment,
  imghy1,
  res2,
  LinksBanTreatmentCt,
  tabhandctc1,
  tabhandctc2,
  tabhandctc3,
  tabhandctc4,
} from "../../../../../data";
import HeadingWithBottomBar from "../../../../../components/reuseablecomponents/HeadingWithBottomBar";
import useMediaQuery from "../../../../../components/reuseablecomponents/MediaQueryHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Toggleform from "../../../../../components/layouts/toggleform/toggleform";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../../../utils/functions";

const LungFibrosis = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const stories = filterComponentData(component, "our_stories", lang);
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const enquiry = filterComponentData(
    component,
    "cupping_therapy_ct_make_an_enquiry_",
    lang
  );
  const therapy = filterComponentData(component, "lung_fibrosis", lang);
  const { t } = useTranslation("common");

  const LinksBanTreatmentCt = [
    {
      subLinkName: banners[48]?.subtitle?.split(">")[0]
        ? banners[48]?.subtitle?.split(">")[0]
        : t("dryeyedisease.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[48]?.subtitle?.split(">")[1]
        ? banners[48]?.subtitle?.split(">")[1]
        : t("dryeyedisease.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[48]?.subtitle?.split(">")[2]
        ? banners[48]?.subtitle?.split(">")[2]
        : "Respiratory Diseases",
      subDash: ">",
      subLink: "/respiratory-diseases",
    },
    {
      subLinkName: banners[48]?.subtitle?.split(">")[3]
        ? banners[48]?.subtitle?.split(">")[3]
        : "Lung Fibrosis",
      subDash: "",
      subLink: "/lungfibrosis",
    },
  ];

  const isDesktoplg = useMediaQuery("(min-width: 992px)");

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(
              JSON.stringify(
                item?.respiratoryLung ? item?.respiratoryLung : "[]"
              )
            )}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.respiratoryLung
            ? specialityMetaTitles?.respiratoryLung
            : "LungFibrosis"}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.respiratoryLung
              ? specialityMetaDesc?.respiratoryLung
              : "LungFibrosis"
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.respiratoryLung
              ? specialityMetaKeywords?.respiratoryLung
              : "LungFibrosis"
          }
        />
      </Helmet>
      <div className="">
        <BannerWithText
          heading={
            banners[48]?.heading ? banners[48]?.heading : "Lung Fibrosis"
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[48]?.image ? banners[48]?.image : banner
          })`}
          imgSrc={banners[48]?.image ? banners[48]?.image : banner}
          LinksBan={LinksBanTreatmentCt}
          height={isDesktoplg ? "300px" : "330px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <TextAndImageSection
          imgSrc={therapy?.image ? therapy?.image : res2}
          alt={
            specialityAltTags?.respiratoryLung
              ? specialityAltTags?.respiratoryLung
              : ""
          }
          imgCol="col-lg-5"
          textCol="col-lg-7"
          headingFirst={therapy?.name ? therapy?.name : "What is Lung Fibrosis"}
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  marginTop: "20px",
                }}
                className="light-Color-Para"
              >
                {therapy?.desc ? (
                  <div dangerouslySetInnerHTML={{ __html: therapy?.desc }} />
                ) : (
                  <>
                    <p class="text-justify">
                      Pulmonary fibrosis is a lung disease that occurs when lung
                      tissue becomes damaged and scarred. This thickened, stiff
                      tissue makes it more difficult for your lungs to work
                      properly. As pulmonary fibrosis worsens, you become
                      progressively shorter of breath.
                    </p>
                    <h2
                      style={{
                        textTransform: "capitalize",
                        color: "#333333",
                      }}
                      className="gray-headin"
                    >
                      Stem Cell Treatment for Lung Fibrosis
                    </h2>

                    <hr
                      style={{
                        borderBottom: "3px solid #AF5689",
                        margin: "10px 0px 15px 0px",
                        width: "80px",
                      }}
                    ></hr>
                    <p class="text-justify">
                      At TotiCell, we bring Autologous Stromal Vascular Fraction
                      Stem Cell Therapy, Platelet Lysate & Allogeneic
                      Mesenchymal Stem cell therapy for lung fibrosis. At first
                      our expert physicians examine patients and then he/she
                      advised him for further treatment.
                      <br />
                      Stem Cell Therapy is proven to:
                    </p>

                    <div>
                      <div class="span-department">
                        <ul>
                          <li>
                            <i class="fa fa-check"></i>
                            Decrease the level of inflammation
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Secrete anti-inflammatory protein (IL-10)
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Improve pulmonary activity in COVID-19 pneumonia
                          </li>
                          <li>
                            <i class="fa fa-check"></i>
                            Release of nutrients and oxygen
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
    </>
  );
};

export default LungFibrosis;
