import moment from "moment";
import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DoctorAppointmentReschedule from "../../components/layouts/modal/doctorAppointmentReschedule/DoctorAppointmentReschedule";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";

const DoctorUpcomingAppointment = (props) => {
  const { t } = useTranslation("common")
  const loader = useSelector((state) => state?.patient?.patientLoader);
  const appointment = useSelector((state) => state?.patient?.appointments);
  const [rescheduleModal, setrescheduleModal] = useState({
    show: false,
    data: {},
  });
  const handleView = (value, row) => {
    if (value == "View" && row?.status?.name == "confirmed") {
      props?.history?.push("/appointment-detail", { row, from: "upcoming" })
    } else if (value == "View" && row?.status?.name == "booked") {
      props?.history?.push("/appointment-detail", { row, from: "upcoming" })

    } else if (value == "Reschedule" && (row?.status?.name == "booked" || row?.status?.name == "confirmed")) {
      setrescheduleModal({ show: true, data: row });

    } else {
      props?.history?.push("/appointment-detail", { row, from: "upcoming" })

    }
  };
  const defaultSorting = [{
    dataField: "date",
    order: "asc",
  },]
  return (
    <div className="w-100">
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.upcomingappointment")}</h4>
        </div>
      </div>
      <div className="row">
        {loader ? (
          <LoderContainer />
        ) : (
          <BootstrapCustomTable
            columns={[
              {
                formatter: (cell, row, index) => {
                  return <span>{index + 1}</span>;
                },
                text: "Sr.",
              },
              {
                formatter: (cell, row) => {
                  return <span>{moment(row.date).format("DD/MM/YYYY")}</span>;
                },
                text: t("dashboards.alldashboardkeys.tablekeys.date"),
              },
              {
                formatter: (cell, row) => {
                  return <span>{`${row.slot.from} - ${row.slot.to}`}</span>;
                },
                text: t("dashboards.alldashboardkeys.tablekeys.date"),
              },

              { dataField: "patient_details.name", text: t("dashboards.alldashboardkeys.tablekeys.patientname") },
              { dataField: "condition.name", text: t("dashboards.alldashboardkeys.tablekeys.condition") },
              { dataField: "consultation_type.name", text: t("dashboards.alldashboardkeys.tablekeys.consultationtype") },
              {
                formatter: (cell, row) => {
                  return <span style={{ color: row?.status?.name == "booked" ? 'red' : row?.status?.name == "confirmed" ? 'green' : '#af5689' }}>{row?.status?.name?.toUpperCase()}</span>;
                },
                text: "Status",
              },
              {
                formatter: (cell, row, index) => {
                  return (
                    row?.status?.name == "booked" ?
                      <ActionBtn
                        options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn"), "Reschedule"]}
                        handleChange={handleView}
                        data={row}
                      />
                      : row?.status?.name == "confirmed" ? <ActionBtn
                        options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn"), "Reschedule"]}
                        handleChange={handleView}
                        data={row}
                      /> : <ActionBtn
                        options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                        handleChange={handleView}
                        data={row}
                      />
                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.action"),
              },
            ]}
            tabledata={
              appointment?.upcoming?.length > 0 ? appointment?.upcoming : []
            }
          />
        )}
      </div>
      <DoctorAppointmentReschedule
        show={rescheduleModal?.show}
        data={rescheduleModal?.data}
        onHide={() => setrescheduleModal({ show: false, data: {} })}
        {...props}
      />
    </div>
  );
};
export default DoctorUpcomingAppointment;
