import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashBoardMainHading from "../../components/reuseablecomponents/DashboardBtnList";
import ChatIframe from "../../services/twillioChat/ChatIframe";
import { videoCallToken } from "../../store/actions/staticActions";
import ProfileTable from "../../components/reuseablecomponents/DetailTable";
import {
  CloseBoardStatus,
  myDoctorBoard,
  updateBoardStatus,
} from "../../store/actions/docotorActions";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import DoctorBoardModal from "../../components/layouts/modal/doctorBoardModal/DoctorBoardModal";
import DashboardBtn from "../../components/reuseablecomponents/DashboardBtn";
import RecomendedDoctorBoardModal from "../../components/layouts/modal/doctorBoardModal/RecomendedDoctorBoard";

const BoardDetails = (props) => {
  const [toggle, setToggle] = useState(false);
  const loader = useSelector((state) => state?.logIn?.authLoader);
  const [doctorModal, setdoctorModal] = useState(false);
  const [doctorList, setdoctorList] = useState([]);

  const viewData = props.location.state;
  let doctors = viewData?.doctors?.map((item) => {
    return item?.name;
  });
  const doctorAddHandler = (item) => {
    setdoctorList([...doctorList, ...item]);
  };
  doctors = doctors.join(" | ");
  const user = useSelector((state) => state?.logIn?.user);
  const item = { key: "FromBoardDetails" };
  const dispatch = useDispatch();
  // const callHandler = () => {

  // };
  const callHandler = async () => {
    const res = await dispatch(videoCallToken(true, { id: viewData?.id }));
    if (res === "true") {
      const win = window.open(`/call/${viewData?.id}`, "_blank");
      win.focus();
    } else {
    }
  };
  const closeBoard = async () => {
    const ids = uniqueDoctor?.map((item) => item?.id);
    // setdoctorModal(true)
    const apiData = {
      board_id: viewData?.id,
      // status: { id: 2, name: "closed" },
      doctor_ids: ids,
    };
    const res = await dispatch(CloseBoardStatus(apiData));
    if (res) {
      dispatch(myDoctorBoard({ id: user?.id }));

      toast.success("Successfully Closed", "Success");
      props.history.push("doctor-dashboard");
    }
  };
  const uniqueDoctor = doctorList.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      doctorList.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
  const openBoard = async () => {
    const apiData = {
      board_id: viewData?.id,
      status: { id: 1, name: "open" },
    };
    const res = await dispatch(updateBoardStatus(apiData));
    if (res) {
      dispatch(myDoctorBoard({ id: user?.id }));

      toast.success("Successfully Opened", "Success");

      props.history.push("doctor-dashboard");
    }
  };
  return (
    <>
      <div style={{ backgroundColor: "#f9f9f9" }}>
        <DashBoardMainHading title={"Board Details"} />
        {toggle ? (
          <>
            <div className="container ">
              <div className="row center-children-class">
                <div className="col-lg-10 p-0">
                  <div
                    className="btn btnSquare"
                    style={{ width: "150px" }}
                    onClick={() => {
                      setToggle(false);
                    }}
                  >
                    <i className="fas fa-arrow-left"></i> BACK
                  </div>
                </div>
              </div>

              <ChatIframe
                user_id={user?.id}
                appointment_id={viewData?.id}
                board={true}
                name={user?.name}
              />
            </div>
          </>
        ) : (
          <div className="container center-childrens">
            <div className="row center-children-class">
              <div className="col-xl-8 col-md-8 col-12 px-0">
                <div
                  className="btn btnSquare"
                  style={{ width: "150px" }}
                  onClick={() => {
                    props.history.push("/doctor-dashboard");
                  }}
                >
                  <i className="fas fa-arrow-left"></i> BACK
                </div>
              </div>
            </div>
            <div className="row center-childrens mt-3 pb-5 center-children-class">
              <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
                <div className="d-flex justify-content-between">
                  <h5>Patient Details</h5>
                  <span>
                    Payment Status :{" "}
                    <strong
                      style={{
                        color:
                          viewData?.status?.name === "booked" ? "red" : "green",
                      }}
                    >
                      {viewData?.status?.name === "booked" ? "UNPAID" : "PAID"}
                    </strong>
                  </span>
                </div>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      PATIENT NAME
                    </span>
                    <p>{viewData?.appointment?.patient_details?.name}</p>
                  </li>{" "}
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      PATIENT EMAIL
                    </span>
                    <p>
                      {viewData?.appointment?.patient_details?.email_address}
                    </p>
                  </li>{" "}
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      AGE.
                    </span>
                    <p>{viewData?.appointment?.patient_details?.age}</p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      DESCRIPTION
                    </span>
                    <p>{viewData?.appointment?.description}</p>
                  </li>
                </ul>
                {viewData?.from === "New Boards" ? (
                  <div className="row">
                    <div className="col-12 d-flex justify-content-start">
                      <div
                        className="btn btn-success mr-2"
                        style={{ float: "right" }}
                        onClick={callHandler}
                      >
                        Call
                      </div>

                      <div
                        className="btn btn-success"
                        style={{ float: "right" }}
                        onClick={() => {
                          setToggle(true);
                        }}
                      >
                        Chat
                      </div>
                    </div>
                  </div>
                ) : null}

                {viewData?.doctors?.length > 0 ? (
                  <div>
                    <h5>Doctor Board</h5>
                    <ProfileTable
                      tableHead={["Name", "Speciality", "Email", "Departments"]}
                      data={viewData?.doctors?.map((item) => {
                        return [
                          item?.name,
                          item?.speciality?.map((_item, index) => {
                            return (
                              <li
                                style={{
                                  listStyle:
                                    item?.speciality?.length > 1 ? "" : "none",
                                }}
                                key={index}
                              >
                                {_item?.name}
                              </li>
                            );
                          }),
                          item?.email_address,
                          item?.departments?.map((_item, index) => {
                            return (
                              <li
                                style={{
                                  listStyle:
                                    item?.departments?.length > 1 ? "" : "none",
                                }}
                                key={index}
                              >
                                {_item?.name}
                              </li>
                            );
                          }),
                        ];
                      })}
                    />
                  </div>
                ) : (
                  ""
                )}
                {doctorList?.length > 0 ? (
                  <div>
                    <h5>Recomended Doctors</h5>
                    <ProfileTable
                      tableHead={["Name", "Speciality", "Email", "Remove"]}
                      data={uniqueDoctor?.map((item, index) => {
                        return [
                          item?.name,
                          item?.speciality?.map((_item, index) => {
                            return (
                              <li
                                style={{
                                  listStyle:
                                    item?.speciality?.length > 1 ? "" : "none",
                                }}
                                key={index}
                              >
                                {_item?.name}
                              </li>
                            );
                          }),
                          item?.email_address,

                          <>
                            {" "}
                            <div className="row">
                              <div className="col">
                                <DashboardBtn
                                  label={"Remove"}
                                  onClick={async () => {
                                    const test = [...uniqueDoctor];
                                    test.splice(index, 1);
                                    await setdoctorList(test);
                                  }}
                                />
                              </div>
                            </div>
                          </>,
                        ];
                      })}
                    />
                  </div>
                ) : (
                  ""
                )}
                {viewData?.from == "New Boards" ? (
                  loader ? (
                    <Loader
                      height={20}
                      width={20}
                      type="Oval"
                      color="#1a4774"
                    />
                  ) : viewData?.is_owner ? (
                    <div className="row">
                      <div className="col-2">
                        <button className="btn btnSquare" onClick={closeBoard}>
                          Close Board
                        </button>
                      </div>
                      <div className="col-2">
                        <button
                          className="btn btnSquare"
                          onClick={() => setdoctorModal(true)}
                        >
                          Recommended Doctor
                        </button>
                      </div>
                    </div>
                  ) : null
                ) : loader ? (
                  <Loader height={20} width={20} type="Oval" color="#1a4774" />
                ) : viewData?.is_owner ? (
                  <button className="btn btnSquare" onClick={openBoard}>
                    Open Board
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
      <RecomendedDoctorBoardModal
        show={doctorModal}
        onHide={() => setdoctorModal(!doctorModal)}
        setdoctorList={doctorAddHandler}
        doctor={viewData?.doctors}
        {...props}
      />
    </>
  );
};

export default BoardDetails;
