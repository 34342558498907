import { React, useState } from "react";
import DashboardHeaderAndTile from "../../components/layouts/dashboardheaderandtile/DashboardHeaderAndTile";
import prescription from "../../statics/icons/prescription.svg";
import appointment from "../../statics/icons/date.png";
import patient from "../../statics/icons/patient.svg";
import board from "../../statics/icons/first-aid-kit.svg";



import DoctorAppointmentContainer from "./DoctorAppointmentContainer";
import DoctorMyPatient from "./DoctorMyPatient";
import DoctorPrescriptionContainer from "./DoctorPrescriptionContainer";
import { Helmet } from "react-helmet";
import MyBoard from "./MyBoard";
import { useTranslation } from "react-i18next";

const DoctorDashboard = (props) => {
  const {t} = useTranslation("common")
  const [selectedtile, setselectedtile] = useState(t("dashboards.alldashboardkeys.tablekeys.appointment"));
  const tabHandler = (item) => {
    setselectedtile(item);
  };
  return (
    <div>
      <Helmet>
        <title>Dashboard - Toticell</title>
      </Helmet>
      <DashboardHeaderAndTile
        heading={"Doctor Dashboard"}
        options={["Appointments", "Prescription", "My Patient","My Board"]}
        icons={[appointment, prescription, patient,board]}
        tabHandler={tabHandler}
        value={selectedtile}
      />
      
      <div className="container mt-5">
        {selectedtile === t("dashboards.alldashboardkeys.tablekeys.appointment") ? (
          <DoctorAppointmentContainer {...props} />
        ) : selectedtile === "Prescription" ? (
          <DoctorPrescriptionContainer {...props} />
        ) : selectedtile === "My Patient" ? (
          <DoctorMyPatient {...props} />
        ) : selectedtile === "My Board" ?
          <MyBoard {...props} />
          : null}
      </div>
    </div>
  );
};

export default DoctorDashboard;
