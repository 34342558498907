import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BannerWithText from "../../../../components/layouts/bannerImgComponents/BannerImgComponents";
import Specialitycard from "../../../../components/layouts/cards/SpeciallityCard/Specialitycard";
import {
  pain1,
  banner,
  pamenucard1,
  pamenucard2,
  pamenucard3,
  pamenucard4,
  pamenucard5,
  pamenucard6,
  pamenucard7,
} from "../../../../data";
import { filterComponentData } from "../../../../utils/functions";

const PainManagement = () => {
  const component = useSelector((state) => state?.cmsReducer?.components);

  const lang = useSelector((state) => state?.cmsReducer?.language);
  const cards = filterComponentData(
    component,
    "speciality_pain_managment_cards",
    lang
  );
  const { t } = useTranslation("common");
  const banners = filterComponentData(component, "banner", lang);
  const specialityMetaTitles = filterComponentData(
    component,
    "speciality_meta_titles",
    lang
  );
  const specialitySchemaMarkUp = filterComponentData(
    component,
    "speciality_schema_markups",
    lang
  );
  const specialityMetaDesc = filterComponentData(
    component,
    "speciality_meta_descriptions",
    lang
  );
  const specialityMetaKeywords = filterComponentData(
    component,
    "speciality_meta_keywords",
    lang
  );
  const specialityAltTags = filterComponentData(
    component,
    "speciality_alt_tags",
    lang
  );
  const LinksBanSpecialityPain = [
    {
      subLinkName: banners[22]?.subtitle?.split(">")[0]
        ? banners[22]?.subtitle?.split(">")[0]
        : t("painmanagement.banner.subHeading"),
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banners[22]?.subtitle?.split(">")[1]
        ? banners[22]?.subtitle?.split(">")[1]
        : t("painmanagement.banner.title"),
      subDash: ">",
      subLink: "/Speciality",
    },
    {
      subLinkName: banners[22]?.subtitle?.split(">")[2]
        ? banners[22]?.subtitle?.split(">")[2]
        : t("painmanagement.banner.title1"),
      subDash: "",
      subLink: "/pain-management",
    },
  ];

  const pain1 = [
    {
      img: pamenucard1,
      head1: t("painmanagement.pagemenucard.card1.text"),
      linkroute: "/knee-pain",
    },
    {
      img: pamenucard2,
      head1: t("painmanagement.pagemenucard.card2.text"),
      linkroute: "/back-pain",
    },
    {
      img: pamenucard3,
      head1: t("painmanagement.pagemenucard.card3.text"),
      linkroute: "/frozen-shoulder-shoulder-pain",
    },
    {
      img: pamenucard4,
      head1: t("painmanagement.pagemenucard.card4.text"),
      linkroute: "/plantar-fasciitis",
    },
    {
      img: pamenucard5,
      head1: "Tennis Elbow",
      linkroute: "/tennis-elbow",
    },
    {
      img: pamenucard6,
      head1: "Rheumatoid Arthritis",
      linkroute: "/rheumatoid-arthritis",
    },
    {
      img: pamenucard7,
      head1: "Carpal Tunnel Syndrome",
      linkroute: "/carpal-tunnel-syndrome",
    },
  ];

  return (
    <>
      <Helmet>
        {specialitySchemaMarkUp?.map((item) => (
          <script type="application/ld+json">
            {JSON.parse(JSON.stringify(item?.painMain ? item?.painMain : "[]"))}
          </script>
        ))}
        <title>
          {specialityMetaTitles?.painMain
            ? specialityMetaTitles?.painMain
            : t("painmanagement.maintitle")}
        </title>
        <meta
          name="description"
          content={
            specialityMetaDesc?.painMain
              ? specialityMetaDesc?.painMain
              : t("painmanagement.maintitle")
          }
        />
        <meta
          name="keywords"
          content={
            specialityMetaKeywords?.painMain
              ? specialityMetaKeywords?.painMain
              : t("painmanagement.maintitle")
          }
        />
      </Helmet>
      <div className="mb-5">
        <BannerWithText
          heading={
            banners[22]?.heading
              ? banners[22]?.heading
              : t("painmanagement.banner.title1")
          }
          backimg={`linear-gradient(rgba(20, 20, 19, 0.1), rgba(20, 20, 19, 0.2)),url(${
            banners[22]?.image ? banners[22]?.image : banner
          })`}
          imgSrc={banners[22]?.image ? banners[22]?.image : banner}
          LinksBan={LinksBanSpecialityPain}
          height={"300px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container">
          <div className="row ">
            {cards?.length < 1
              ? pain1.map((item, index) => (
                  <Specialitycard
                    alt={
                      specialityAltTags?.painMain
                        ? specialityAltTags?.painMain
                        : ""
                    }
                    img={item?.img}
                    head1={item?.head1}
                    linkroute={item?.linkroute}
                  />
                ))
              : cards.map((item, index) => (
                  <Specialitycard
                    alt={
                      specialityAltTags?.painMain
                        ? specialityAltTags?.painMain
                        : ""
                    }
                    img={item?.image}
                    head1={item?.heading}
                    linkroute={item?.link}
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PainManagement;
