import localStore from "./localstore.util";
import { updateHeaders } from "../services/HttpProvider";

export const getToken = () => localStore.get_data("toticelltoken");

export const setToken = (token) => localStore.store_data("toticelltoken", token);
export const setUserRole = (token) => localStore.store_data("toticelluserRole", token);
export const getUser = () => localStore.get_data("toticelluser");

export const saveUser = (user) => localStore.store_data("toticelluser", user);

export const logout = async () => {
  await localStore.remove_data("toticelltoken");
  await localStore.remove_data("toticelluser");
  await localStore.remove_data("toticelluserRole");
  await localStore.remove_data("fcm");
  await localStore.remove_data("roomToken");
  await localStore.remove_data("ChatUser");
  await localStore.remove_data("chatToken");
  await updateHeaders();
  return true;
};

class Auth {
  constructor() {
    this.user = {};
  }

  async setUserFromLocal() {
    const user = await getToken();
    this.user = user ? user : {};
  }

  set setUser(user) {
    this.user = user;
  }

  get getUser() {
    return this.user;
  }

  async logout() {
    await logout();
    this.user = {};
  }
}

export const authClass = new Auth();


