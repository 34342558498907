import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../statics/icons/Toticell_logo.png";
import { appointmodal, registerUser } from "../../../store/actions/authActions";
import Loader from "react-loader-spinner";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import TermsNCondition from "../../../components/layouts/modal/TermNConditon/TermsNCondition";
import useMediaQuery from "../../../components/reuseablecomponents/MediaQueryHook";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Helmet } from "react-helmet";
import { getUser } from "../../../utils/auth.util";
import { useTranslation } from "react-i18next";
import { filterComponentData } from "../../../utils/functions";
const SignUpPage = (props) => {
  const { t } = useTranslation("common");
  const [check, setCheck] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [relativePhoneNumber, setRelativPhoneNumber] = useState("");

  const component = useSelector((state) => state?.cmsReducer?.components);
  const lang = useSelector((state) => state?.cmsReducer?.language);

  const loader = useSelector((state) => state?.logIn?.authLoader);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const namedata = props?.location?.state;
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const small = useMediaQuery("(min-width: 1180px)");
  const vsmall = useMediaQuery("(min-width: 380px)");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const signupMetaTitle = filterComponentData(
    component,
    "signup_meta_title",
    lang
  );
  const signupMetaDesc = filterComponentData(
    component,
    "signup_meta_description",
    lang
  );
  const signupMetaKeywords = filterComponentData(
    component,
    "sign_up_meta_keyword",
    lang
  );
  const signupRelAttribute = filterComponentData(
    component,
    "sign_up_rel_attribute_",
    lang
  );
  const signupVerification = filterComponentData(
    component,
    "signup_verification_meta_tags",
    lang
  );
  const onSubmit = (data) => {
    if (data.password === data.confirmPassword) {
      const apiData = {
        name: data.name,
        phone_number: "+" + phoneNumber,
        gender:
          data.gender === "male"
            ? { id: 1, name: "male" }
            : { id: 2, name: "female" },
        // date_of_birth: "1998-08-05",
        registration_type:
          data.registrationType === "patient"
            ? { id: 1, name: "I am a patient" }
            : { id: 2, name: "I am registering on behalf of patient" },
        email_address: data.email,
        relative:
          data.registrationType !== "patient"
            ? {
                name: data?.relativeName || "-",
                relation: data?.relativeRelation || "-",
                contact_number: "+" + relativePhoneNumber || "-",
              }
            : {},
        password: data.password,
        profession: data.profession,
        date_of_birth: data?.date_of_birth,
      };
      let res;
      if (phoneNumber?.length >= 12 && phoneNumber?.length <= 15) {
        res = dispatch(registerUser(apiData));
      } else {
        toast.error("Phone Number is Invalid");
      }
      if (res === "true") {
        dispatch(getUser());
      }
    } else {
      toast.error(t("toastmessages.passwordnotmatch"));
    }
  };

  const registerationTye = watch("registrationType", props.registrationType);

  const showModal = () => setShow(!show);
  const verificationsTags = signupVerification?.map((item) =>
    React.createElement("meta", {
      name: item?.name,
      content: item?.content,
    })
  );
  return (
    <>
      <Helmet>
        {verificationsTags}
        <title>
          {signupMetaTitle?.signupMetaTitle
            ? signupMetaTitle?.signupMetaTitle
            : t("dashboards.alldashboardkeys.tablekeys.signuptoticell")}
        </title>
        <meta
          name="description"
          content={
            signupMetaDesc?.signup
              ? signupMetaDesc?.signup
              : t("dashboards.alldashboardkeys.tablekeys.signuptoticell")
          }
        />
        <meta
          name="keywords"
          content={
            signupMetaKeywords?.signup
              ? signupMetaKeywords?.signup
              : t("dashboards.alldashboardkeys.tablekeys.signuptoticell")
          }
        />
      </Helmet>
      <div className="container-fluid loginContainer ">
        <div className="row">
          <div
            className={`col-xl-4 col-lg-4 col-md-6  login-left-banner d-none d-md-flex ${
              isDesktop ? "rightborder" : ""
            }`}
          >
            <img
              className="mx-auto"
              src={logo}
              alt="Toticell Logo"
              width={250}
              height={250}
            />
            <p
              style={{
                textAlign: "justify",
                color: "#21496a",
                fontSize: "16px",
              }}
            >
              {t("dashboards.alldashboardkeys.tablekeys.authtxt")}
            </p>
          </div>
          <div className="col-lg-8 col-md-6 col-12  center-children-class px-5 pb-3">
            <div className="container  center-children-class">
              <div className="col-lg-10">
                <h2 className="blue-heading">
                  {t("dashboards.alldashboardkeys.tablekeys.welcometototicell")}{" "}
                  {namedata ? namedata : ""}
                </h2>
                <strong>
                  {t("dashboards.alldashboardkeys.tablekeys.fillfortostarted")}
                </strong>
                <div className="">
                  <span
                    style={{
                      color: "#0066b3",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                    onClick={() => dispatch(appointmodal(true))}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.continueasguest")}
                  </span>
                  <br />
                  <br />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <span className="label-name-login">
                        {t("dashboards.alldashboardkeys.tablekeys.name")}
                      </span>
                      <input
                        className="input-login-modal"
                        type="text"
                        {...register("name", {
                          required: {
                            value: true,
                            message: "this field is required field",
                          },
                        })}
                      />
                      {errors?.name?.message ? (
                        <div className="text-error">
                          {errors?.name?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-lg-6 col-12">
                      <span className="label-name-login">
                        {t(
                          "dashboards.alldashboardkeys.tablekeys.emailaddress"
                        )}
                      </span>
                      <input
                        className="input-login-modal"
                        type="email"
                        {...register("email", {
                          required: {
                            value: true,
                            message: "this field is required field",
                          },
                        })}
                      />
                      {errors?.email?.message ? (
                        <div className="text-error">
                          {errors?.email?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-6 col-12">
                      <span className="label-name-login">
                        {"Date Of Birth"}
                      </span>
                      <input
                        className="input-login-modal"
                        type="date"
                        {...register("date_of_birth", {
                          required: {
                            value: true,
                            message: "this field is required field",
                          },
                        })}
                      />
                      {errors?.date_of_birth?.message ? (
                        <div className="text-error">
                          {errors?.date_of_birth?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-6 col-12">
                      <span className="label-name-login">
                        {t("dashboards.alldashboardkeys.tablekeys.gender")}
                      </span>
                      <Form.Control
                        as="select"
                        className="input-login-modal"
                        custom
                        defaultValue="male"
                        {...register("gender", {
                          required: {
                            value: true,
                            message: "this field is required field",
                          },
                        })}
                      >
                        <option value="male">
                          {t("dashboards.alldashboardkeys.tablekeys.male")}
                        </option>
                        <option value="female">
                          {t("dashboards.alldashboardkeys.tablekeys.female")}
                        </option>
                      </Form.Control>
                      {errors?.gender?.message ? (
                        <div className="text-error">
                          {errors?.gender?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-6 col-12">
                      <span className="label-name-login">
                        {t("dashboards.alldashboardkeys.tablekeys.phonenumber")}
                      </span>
                      <PhoneInput
                        className=""
                        country={"bd"}
                        value={phoneNumber}
                        onChange={(phone) => setPhoneNumber(phone)}
                      />
                      {errors?.phoneNumber?.message ? (
                        <div className="text-error">
                          {errors?.phoneNumber?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-6 col-12">
                      <span className="label-name-login">
                        {t("dashboards.alldashboardkeys.tablekeys.profession")}
                      </span>
                      <input
                        className="input-login-modal"
                        type="text"
                        {...register("profession", {})}
                      />
                    </div>
                    <div className="col-lg-6 col-12">
                      <span className="label-name-login">
                        {t(
                          "dashboards.alldashboardkeys.tablekeys.registerationtype"
                        )}
                      </span>
                      <Form.Control
                        as="select"
                        className="input-login-modal"
                        defaultValue="patient"
                        custom
                        {...register("registrationType", {})}
                      >
                        <option value="patient">
                          {t("dashboards.alldashboardkeys.tablekeys.patient")}
                        </option>
                        <option value="relative">
                          {t("dashboards.alldashboardkeys.tablekeys.relative")}
                        </option>
                      </Form.Control>
                      {errors?.gender?.message ? (
                        <div className="text-error">
                          {errors?.gender?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {registerationTye === "relative" ? (
                      <>
                        <div className="col-lg-6 col-12">
                          <span className="label-name-login">
                            {t(
                              "dashboards.alldashboardkeys.tablekeys.relativename"
                            )}
                          </span>
                          <input
                            className="input-login-modal"
                            type="text"
                            {...register("relativeName", {
                              required: {
                                value: true,
                                message: "this field is required field",
                              },
                            })}
                          />
                          {errors?.relativeName?.message ? (
                            <div className="text-error">
                              {errors?.relativeName?.message}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-6 col-12">
                          <span className="label-name-login">
                            {t(
                              "dashboards.alldashboardkeys.tablekeys.relativewithpatient"
                            )}
                          </span>
                          <input
                            className="input-login-modal"
                            type="text"
                            {...register("relativeRelation", {
                              required: {
                                value: true,
                                message: "this field is required field",
                              },
                            })}
                          />
                          {errors?.relativeRelation?.message ? (
                            <div className="text-error">
                              {errors?.relativeRelation?.message}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-6 col-12">
                          <span className="label-name-login">
                            {t(
                              "dashboards.alldashboardkeys.tablekeys.relativewithmobilenumber"
                            )}
                          </span>
                          <PhoneInput
                            country={"bd"}
                            value={relativePhoneNumber}
                            onChange={(phone) => setRelativPhoneNumber(phone)}
                          />
                          {errors?.relativeNumber?.message ? (
                            <div className="text-error">
                              {errors?.relativeNumber?.message}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : null}
                    <div className="col-lg-6 col-12">
                      <span className="label-name-login">
                        {t("dashboards.alldashboardkeys.tablekeys.password")}
                      </span>
                      <input
                        className="input-login-modal"
                        type="password"
                        {...register("password", {
                          required: {
                            value: true,
                            message: "this field is required field",
                          },
                        })}
                      />
                      {errors?.password?.message ? (
                        <div className="text-error">
                          {errors?.password?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-6 col-12">
                      <span className="label-name-login">
                        {t(
                          "dashboards.alldashboardkeys.tablekeys.confirmpassword"
                        )}
                      </span>
                      <input
                        className="input-login-modal"
                        type="password"
                        {...register("confirmPassword", {
                          required: {
                            value: true,
                            message: "this field is required field",
                          },
                        })}
                      />
                      {errors?.confirmPassword?.message ? (
                        <div className="text-error">
                          {errors?.confirmPassword?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-12">
                      <input
                        type="checkbox"
                        name="terms"
                        id="terms"
                        value={check}
                        onChange={() => {
                          setCheck(!check);
                        }}
                      />
                      &nbsp;
                      {t("dashboards.alldashboardkeys.tablekeys.iagreeto")}
                      &nbsp;
                      <span
                        style={{ color: "#0066b3", cursor: "pointer" }}
                        onClick={() => showModal()}
                      >
                        {t(
                          "dashboards.alldashboardkeys.tablekeys.termsandcondition"
                        )}
                      </span>
                      <br />
                      <br />
                    </div>

                    <div className="col-lg-4 mb-3  text-left">
                      {!small ? (
                        ""
                      ) : (
                        <button
                          type="submit"
                          className="loginBtn"
                          disabled={!check}
                        >
                          {loader ? (
                            <Loader
                              height={20}
                              width={20}
                              type="ThreeDots"
                              color="#000"
                            />
                          ) : (
                            t("dashboards.alldashboardkeys.tablekeys.signup")
                          )}
                        </button>
                      )}
                    </div>

                    {!small ? (
                      <>
                        <div class="container-fluid full-width">
                          <div
                            class="row-fluid"
                            style={{ display: !vsmall ? "grid" : "" }}
                          >
                            <button
                              type="submit"
                              className="btn pull-left loginBtn mb-2 mr-2"
                              disabled={!check}
                            >
                              {loader ? (
                                <Loader
                                  height={20}
                                  width={20}
                                  type="ThreeDots"
                                  color="#000"
                                />
                              ) : (
                                t(
                                  "dashboards.alldashboardkeys.tablekeys.signup"
                                )
                              )}
                            </button>

                            <button class="btn pull-right loginBtn mx-2 mb-2">
                              <a
                                href="https://accounts.google.com/o/oauth2/v2/auth?client_id=847803953515-v5kvci1k4h661cc1jeivsbkjmnkcujk8.apps.googleusercontent.com&redirect_uri=https://www.toticell.com/auth-signup-verification&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile&response_type=code&access_type=offline&prompt=consent"
                                rel={
                                  signupRelAttribute?.google
                                    ? signupRelAttribute?.google
                                    : ""
                                }
                                className="p-0 float-left"
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "#AF5689",
                                }}
                              >
                                <img
                                  width="15px"
                                  style={{
                                    marginBottom: "3px",
                                    marginRight: "5px",
                                  }}
                                  alt="Google login"
                                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                                />
                                Google
                              </a>
                            </button>
                            <button class="btn pull-right loginBtn mx-2 mb-2">
                              <a
                                href="https://www.facebook.com/v12.0/dialog/oauth?client_id=1186216795452228&redirect_uri=https://www.toticell.com/auth-signup-verification-facebook/&scope=email,user_friends&response_type=token&auth_type=rerequest&display=popup"
                                rel={
                                  signupRelAttribute?.facebook
                                    ? signupRelAttribute?.facebook
                                    : ""
                                }
                                className="p-0 float-left"
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "#AF5689",
                                }}
                              >
                                <img
                                  width="15px"
                                  style={{
                                    marginBottom: "3px",
                                    marginRight: "5px",
                                  }}
                                  alt="Google login"
                                  src="https://upload.wikimedia.org/wikipedia/commons/f/fb/Facebook_icon_2013.svg"
                                />
                                Facebook
                              </a>
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="col-lg-8 my-3 text-right px-4">
                        <a
                          href="https://accounts.google.com/o/oauth2/v2/auth?client_id=847803953515-v5kvci1k4h661cc1jeivsbkjmnkcujk8.apps.googleusercontent.com&redirect_uri=https://www.toticell.com/auth-signup-verification&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile&response_type=code&access_type=offline&prompt=consent"
                          className="loginBtn ml-2"
                          target="_blank"
                          rel={
                            signupRelAttribute?.google
                              ? signupRelAttribute?.google
                              : ""
                          }
                          style={{ textDecoration: "none", color: "#AF5689" }}
                        >
                          <img
                            width="15px"
                            style={{ marginBottom: "3px", marginRight: "5px" }}
                            alt="Google login"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                          />
                          Google
                        </a>
                        <a
                          href="https://www.facebook.com/v12.0/dialog/oauth?client_id=1186216795452228&redirect_uri=https://www.toticell.com/auth-signup-verification-facebook/&scope=email,user_friends&response_type=token&auth_type=rerequest&display=popup"
                          className="loginBtn mx-2"
                          target="_blank"
                          rel={
                            signupRelAttribute?.facebook
                              ? signupRelAttribute?.facebook
                              : ""
                          }
                          style={{ textDecoration: "none", color: "#AF5689" }}
                        >
                          <img
                            width="15px"
                            style={{ marginBottom: "3px", marginRight: "5px" }}
                            alt="Google login"
                            src="https://upload.wikimedia.org/wikipedia/commons/f/fb/Facebook_icon_2013.svg"
                          />
                          Facebook
                        </a>
                      </div>
                    )}

                    <div className="col-lg-12">
                      {t(
                        "dashboards.alldashboardkeys.tablekeys.alreadyregistered"
                      )}{" "}
                      <Link to="/login" style={{ textDecoration: "none" }}>
                        {t("dashboards.alldashboardkeys.tablekeys.signin")}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <TermsNCondition show={show} onHide={showModal} {...props} />
      </div>
    </>
  );
};

export default SignUpPage;
