import { React, useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddMedicinesPrescriptionModal from "../../components/layouts/modal/addMedicinePrescription/AddPrescriptionMedicineModal";
import AddTestModal from "../../components/layouts/modal/addTestModal/AddTestModal";
// import { Form } from "react-bootstrap";
// import { Typeahead } from "react-bootstrap-typeahead";

// import AddMedicinesPrescriptionModal from "../../components/layouts/modal/addMedicinePrescription/AddPrescriptionMedicineModal";
import DashboardBtn from "../../components/reuseablecomponents/DashboardBtn";
// import DashboardBtnList from "../../components/reuseablecomponents/DashboardBtnList";

import DashBoardMainHading from "../../components/reuseablecomponents/DashBoardMainHading";
import DetailTable from "../../components/reuseablecomponents/DetailTable";
import ChatIframe from "../../services/twillio/ChatIframe";
import { addprescription, getAllMedicine } from "../../store/actions/docotorActions";
import { patientAppointments } from "../../store/actions/patientActions";
import { useTranslation } from "react-i18next";

import {
  selectedAppointment,
  videoCallToken,
} from "../../store/actions/staticActions";
// import FollowUpTab from "./FollowUpTab";
import MedicineTab from "./MedicineTab";
import TestTab from "./TestTab";

const DetailPage = (props) => {
  const { t } = useTranslation("common");

  const [selectedTest, setSelectedTest] = useState([]);
  const [selectedMedi, setSelectedMedi] = useState([]);
  const [showMedi, setShowMedi] = useState(false);

  const [selectedFollowup, setSelectedFollowup] = useState([]);
  const [diagnosis, setDiagnosis] = useState()
  const viewData = props.location.state?.row;
  const key = props.location.state?.from;
  let defaultMedi = []
  const [showTest, setShowTest] = useState(false);

  const [showchat, setshowchat] = useState(false);
  const [loadingChat, setloadingChat] = useState(false);

  const user = useSelector((state) => state?.logIn?.user);
  const modalHandler = (value) => {
    setShowMedi(value);
  };
  const item = { key: "AppointmentDetails" };
  const dispatch = useDispatch();
  const callHandler = () => {
    dispatch(videoCallToken(false, { id: viewData?.id }));
    const win = window.open(`/call/${viewData?.id}`, "_blank");
    win.focus();
  };
  useEffect(() => {
    if ((key == "past" || key == "today") && viewData?.prescription?.length != 0) {
      dispatch(getAllMedicine())
      viewData?.prescription?.medicines?.map((item) => {
        defaultMedi?.push({
          dose: [...item?.dose],
          duration: item?.duration,
          id: item?.medicine?.id,
          name: item?.medicine?.name
        })
      })
      setSelectedMedi(defaultMedi)

      setSelectedTest([...viewData?.prescription?.tests])
      setSelectedFollowup([...viewData?.prescription?.follow_up_dates])
      setDiagnosis(...viewData?.prescription?.diagnosis)
    }
  }, []);


  const [selectedTab, setselectedTab] = useState(t("dashboards.alldashboardkeys.tablekeys.medicine"));
  const tabHandler = (item) => {
    setselectedTab(item);
  };

  const chatHandler = async () => {
    setshowchat(true);
  };

  const clickHandlerPrescription = async () => {
    if (selectedTest?.length < 1 && selectedMedi?.length < 1) {
      toast.error("Please Add Some Medicine Or Test", "Error")
    } else {
      const apiData = {
        appointment_id: viewData?.id,
        tests: selectedTest.map((item) => item?.id),
        medicines: selectedMedi,
      };
      const response = await dispatch(addprescription(apiData));
      if (response === "success") {
        const apiData = {
          id: user?.id,
        };
        dispatch(patientAppointments(apiData));
        props.history.push("/doctor-dashboard", item);
      }
    }
  }
  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading title={t("dashboards.alldashboardkeys.tablekeys.appointmentdetail")} />

      {showchat ? (
        <>
          <div className="container">
            <div className="row center-children-class">
              <div className="col-lg-10 col-md-12">
                <div
                  className="btn btnSquare"
                  style={{ width: "150px" }}
                  onClick={() => {
                    setshowchat(false);
                  }}
                >
                  <i className="fas fa-arrow-left"></i> {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
                </div>
              </div>
            </div>

            <ChatIframe
              user_id={user?.id}

              appointment_id={viewData?.id}
              board={false}
              name={user?.name}
            />
          </div>
        </>
      ) : (
        <>

          <div className="container">
            <div className="row center-children-class">
              <div className="col-xl-8 col-md-8 col-12 px-0">
                <div
                  className="btn btnSquare"
                  style={{ width: "150px" }}
                  onClick={() => {
                    props.history.push("/doctor-dashboard");
                  }}
                >
                  <i className="fas fa-arrow-left"></i>{t("dashboards.alldashboardkeys.tablekeys.backbtn")}
                </div>
              </div>
            </div>
            <div className="row center-childrens mt-3 pb-5 center-children-class">
              <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
                <div className="d-flex justify-content-between">
                  <h5>{t("dashboards.alldashboardkeys.tablekeys.patientdetail")}</h5>
                  <span>
                    {t("dashboards.alldashboardkeys.tablekeys.paymentstatus")} :
                    <strong
                      style={{
                        color:
                          viewData?.status?.name === "booked" ? "red" : "green",
                      }}
                    >
                      {viewData?.status?.name === "booked" ? "UNPAID" : "PAID"}
                    </strong>
                  </span>
                </div>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.datantime")}
                    </span>
                    <p style={{ fontWeight: '600' }}>
                      {viewData?.date} @ {viewData?.slot.time?.from} -
                      {viewData?.slot.time?.to}
                    </p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.patientname")}
                    </span>
                    <p>{viewData?.patient_details?.name}</p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.patientemail")}
                    </span>
                    <p>{viewData?.patient_details?.email_address}</p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.age")}
                    </span>
                    <p>{viewData?.patient_details?.age}</p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.description")}
                    </span>
                    <p>{viewData?.description}</p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.consultationtype")}
                    </span>
                    <p style={{ fontWeight: '600' }}>{viewData?.consultation_type?.name?.toUpperCase()}</p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.city")}
                    </span>
                    <p style={{ fontWeight: '600' }}>{viewData?.city?.toUpperCase()}</p>
                  </li>
                </ul>

                <div className="row">
                  <div className="col-12 d-flex justify-content-start">
                    {key === "today" && viewData?.status?.name=="confirmed" ? (
                      <div
                        className="btn btn-success mr-2"
                        style={{ float: "right" }}
                        onClick={callHandler}
                      >
                        {t("dashboards.alldashboardkeys.tablekeys.callbtn")}
                      </div>
                    ) : null}
                    {(key === "today" ||
                      key === "past") && (viewData?.status?.name == "confirmed" || viewData?.status?.name == "completed") ? (
                      // <div
                      //   className="btn btn-success"
                      //   style={{ float: "right" }}
                      //   onClick={() => {

                      //     dispatch(selectedAppointment(viewData));
                      //     const win = window.open(
                      //       `/chat/${viewData?.id}/${viewData?.patient_details?.name}/false`,
                      //       "_blank"
                      //     );
                      //     win.focus();
                      //   }}
                      // >
                      //   Chat
                      // </div>
                      <div
                        className="btn btn-success ml-2"
                        style={{ float: "right" }}
                        onClick={chatHandler}
                      >
                        {loadingChat ? (
                          <Loader
                            height={20}
                            width={20}
                            type="ThreeDots"
                            color="#fff"
                          />
                        ) : (
                          <>
                            <i
                              className="fas fa-comment-alt"
                              style={{ fontSize: "13px" }}
                            >
                              {""}
                            </i>
                            {t("dashboards.alldashboardkeys.tablekeys.chatbtn")}
                          </>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>


                {(key == "past" || key == "today") && (viewData?.status?.name == "confirmed" || viewData?.status?.name == "completed") ?
                  <div>
                    <div className="row">
                      {[t("dashboards.alldashboardkeys.tablekeys.medicine"), t("dashboards.alldashboardkeys.tablekeys.tes")].map((item, index) => (
                        <div
                          className="col-lg-4 mt-2"
                          key={index + 1}
                          onClick={() => tabHandler(item)}
                        >
                          <DashboardBtn
                            label={item}
                            labelStyle={selectedTab === item ? { color: "#fff" } : ""}
                            active={selectedTab === item}
                            className={
                              selectedTab === item
                                ? "dashboardBtnList-item-active py-2"
                                : "default-color-and-hover py-2"
                            }
                            onClick={() => tabHandler(item)}
                          />
                        </div>
                      ))}
                    </div>
                    {selectedTab === t("dashboards.alldashboardkeys.tablekeys.medicine") ? (
                      <>
                        <h5 className="mt-3"> {t("dashboards.alldashboardkeys.tablekeys.medicine")}</h5>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => modalHandler(true)}
                          className="btn btn-outline-success mb-2"
                        >
                          {`+ ${t("dashboards.alldashboardkeys.tablekeys.addmedicine")}`}
                        </span>
                        <br />
                        {selectedMedi.length > 0 ? (
                          <>

                            <DetailTable
                              tableHead={[
                                t("dashboards.alldashboardkeys.tablekeys.medname"),
                                t("dashboards.alldashboardkeys.tablekeys.morningdose"),
                                t("dashboards.alldashboardkeys.tablekeys.afternoondose"),
                                t("dashboards.alldashboardkeys.tablekeys.nightdose"),
                                t("dashboards.alldashboardkeys.tablekeys.duration"),
                                t("dashboards.alldashboardkeys.tablekeys.action"),

                              ]}
                              data={selectedMedi.map((item, index) => {
                                return [
                                  item?.name,
                                  item?.dose[0],
                                  item?.dose[1],
                                  item?.dose[2],
                                  item?.duration,
                                  <>

                                    <div className="row">
                                      <div className="col">
                                        <DashboardBtn
                                          label={t("dashboards.alldashboardkeys.tablekeys.remove")}
                                          className="bg-danger"
                                          onClick={() => {
                                            setSelectedMedi(
                                              selectedMedi?.filter(
                                                (_item) => _item !== item
                                              )
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>,
                                ];
                              })}
                            />
                          </>
                        ) : null}
                      </>
                    ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.tes") ? (
                      <>
                        <h5 className="mt-3">{t("dashboards.alldashboardkeys.tablekeys.tes")}</h5>

                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowTest(true)}
                          className="btn btn-outline-success"
                        >
                          {`+ ${t("dashboards.alldashboardkeys.tablekeys.addtest")}`}
                        </span>
                        <div className="w-100">
                          <DetailTable
                            tableHead={["SNo .",
                              t("dashboards.alldashboardkeys.tablekeys.testname"),
                              t("dashboards.alldashboardkeys.tablekeys.action"),
                            ]}
                            data={selectedTest.map((item, index) => {
                              return [
                                index,
                                item?.name,
                                <>

                                  <div className="row">
                                    <div className="col">
                                      <DashboardBtn
                                        label={t("dashboards.alldashboardkeys.tablekeys.remove")}
                                        className="bg-danger"
                                        onClick={() => {
                                          setSelectedTest(
                                            selectedTest?.filter(
                                              (_item) => _item !== item
                                            )
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>,
                              ];
                            })}
                          />
                        </div>
                      </>
                    ) : // selectedTab === "Follow Up" ? (
                      //   <FollowUpTab {...props} />
                      // )
                      //  :
                      null}
                  </div> : null}
                  {viewData?.recommended_doctors?.length > 0 ? (
                    <>
                      <span
                        className="light-Color-Para ml-2"
                        style={{ fontSize: "18px", fontWeight: "500" }}
                      >
                        {"Recomended Doctors"}
                      </span>
                      <DetailTable
                        tableHead={["S.No", "Name"]}
                        data={viewData?.recommended_doctors?.map(
                          (item, index) => {
                            return [index, item?.name];
                          }
                        )}
                      />
                    </>
                  ) : (
                    ""
                  )}

                {(key == "past" || key == "today") && (viewData?.status?.name == "confirmed" || viewData?.status?.name == "completed") ? (
                  <button className="btn btnSquare" onClick={clickHandlerPrescription}>{"Update Prescription"}</button>
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}

      <AddMedicinesPrescriptionModal
        show={showMedi}
        onHide={() => modalHandler(!showMedi)}
        selectedMedi={selectedMedi}
        setSelectedMedi={setSelectedMedi}
        {...props}
      />
      <AddTestModal
        show={showTest}
        onHide={() => setShowTest(!showTest)}
        selectedTest={selectedTest}
        setSelectedTest={setSelectedTest}
        {...props}
      />
    </div>
  );
};

export default DetailPage;
