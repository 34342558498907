import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import { getadministratorPatients } from "../../store/actions/administratorActions";

const AdministrationPatientsList = (props) => {
  const { t } = useTranslation("common")
  const dispatch = useDispatch();
  const administratorPatients = useSelector(
    (state) => state?.administrator?.patients
  );
  const loader = useSelector(
    (state) => state?.administrator?.administrator_loader
  );

  useEffect(() => {
    dispatch(getadministratorPatients());
    // eslint-disable-next-line
  }, []);
  const handleChange = (value, item) => {
    if (value === t("dashboards.alldashboardkeys.tablekeys.viewbtn")) {
      props.history.push("/appointment-details", item);
    }
  };
  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.allpatients")}</h4>
        </div>
      </div>
      {loader ? (
        <LoderContainer />
      ) : (
        <div className="row">
          <BootstrapCustomTable
            columns={[
              {
                formatter: (cell, row, index) => {
                  return <span>{index + 1}</span>;
                },
                text: "Sr.",
              },
              { dataField: "name", text: t("dashboards.alldashboardkeys.tablekeys.patientname") },
              { dataField: "patient_id", text: t("dashboards.alldashboardkeys.tablekeys.patientid") },
              { dataField: "email_address", text: t("dashboards.alldashboardkeys.tablekeys.patientemail") },

              {
                formatter: (cell, row, index) => {
                  return (
                    <ActionBtn
                      options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                      handleChange={handleChange}
                      data={row}
                    />
                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.action"),
              },
            ]}
            tabledata={administratorPatients}
          />
        </div>
      )}
    </div>
  );
};

export default AdministrationPatientsList;
