import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddPharmacyMedicine from "../../components/layouts/modal/addPharmacyMedicine/AddPharmacyMedicine";
import {
  getAllMedicines,
  SelectedMedicinde,
  // updateMedicine,
} from "../../store/actions/pharmacyActions";
import UploadMedicineModal from "../../components/layouts/modal/uploadMedicine/UploadMedicineModal";
import LoderContainer from "../../components/reuseablecomponents/LoderContainer";
import BootstrapCustomTable from "../../components/reuseablecomponents/BootstrapCustomTable";
import ConfirmAlertModal from "../../components/layouts/modal/confirmAlertModal/ConfirmAlertModal";
import ActionBtn from "../../components/reuseablecomponents/ActionBtn";
import { checkPaidOrUnpaid } from "../../utils/functions";
import EditPharmacyMedicine from "../../components/layouts/modal/addPharmacyMedicine/EditMedicineModal";
import { useTranslation } from "react-i18next";
import useMediaQuery from "../../components/reuseablecomponents/MediaQueryHook";
const PharmacyMedicines = (props) => {
  const { t } = useTranslation("common");
  const loader = useSelector((state) => state?.pharmacy?.loader);
  const medicines = useSelector((state) => state?.pharmacy?.medicines);
  const selectedMedi = useSelector((state) => state?.pharmacy?.selectedMedi);
  const [deleteSelectedMedi, setDeleteSelectedMedi] = useState("");
  const [selectedMedicine, setSelectedMedi] = useState({});

  const [addmedicinemodal, setaddmedicinemodal] = useState(false);
  const [addBul, setaddBul] = useState(false);
  const [deleteMedi, setDeleteMedi] = useState(false);

  const isSmall = useMediaQuery("(min-width: 330px)");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllMedicines());
  }, [dispatch]);

  const editHandler = async (item) => {
    await dispatch(SelectedMedicinde({ show: true, data: item }));
  };
  const handleChange = (value, item) => {
    if (value === t("dashboards.alldashboardkeys.tablekeys.edit")) {
      setSelectedMedi(item);
      editHandler(item);
    } else if (value === t("dashboards.alldashboardkeys.tablekeys.delete")) {
      setDeleteSelectedMedi(item);
      setDeleteMedi(true);
      // deleteHandler(item);
    }
  };
  // {t("dashboards.alldashboardkeys.tablekeys.delete")}
  return (
    <>
      <div className="row mt-4">
        <div className="col-lg-6">
          <h4 className="patient-dashboard-heading">
            {t("dashboards.alldashboardkeys.tablekeys.medicines")}
          </h4>
        </div>

        <div className="col-lg-6">
          <div className="row text-right">
            <div className="col d-flex justify-content-end mb-2">
              <button
                className="btn btnSquare"
                style={{ padding: "6px 10px" }}
                onClick={() => setaddBul(true)}
              >
                {t("dashboards.alldashboardkeys.tablekeys.addmedicinebulk")}
              </button>
            </div>

            <div className="col-lg-3 ml-2 mb-2">
              <button
                className="btn btnSquare"
                onClick={() => {
                  setaddmedicinemodal(true);
                }}
              >
                {t("dashboards.alldashboardkeys.tablekeys.addmedicine")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {loader ? (
          <div className="w-100 center-children-class">
            <LoderContainer />
          </div>
        ) : (
          <BootstrapCustomTable
            columns={[
              {
                dataField: "name",
                text: t("dashboards.alldashboardkeys.tablekeys.medname"),
              },
              {
                dataField: "manufacturer_name",
                text: t(
                  "dashboards.alldashboardkeys.tablekeys.manufacturername"
                ),
              },
              {
                dataField: "medicine_type",
                text: t("dashboards.alldashboardkeys.tablekeys.medtype"),
              },
              {
                dataField: "quantity",
                text: t("dashboards.alldashboardkeys.tablekeys.medquantity"),
              },

              {
                dataField: "sales_price",
                text: t("dashboards.alldashboardkeys.tablekeys.salesprice"),
              },
              {
                formatter: (cell, row, index) => {
                  return (
                    <span
                      style={{
                        color: row?.status?.name == "active" ? "green" : "red",
                      }}
                    >
                      {row?.status?.name?.toUpperCase()}
                    </span>
                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.paymentstatus"),
              },

              {
                formatter: (cell, row, index) => {
                  return (
                    <ActionBtn
                      options={[
                        t("dashboards.alldashboardkeys.tablekeys.edit"),
                        t("dashboards.alldashboardkeys.tablekeys.delete"),
                      ]}
                      handleChange={handleChange}
                      data={row}
                    />
                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.action"),
              },
            ]}
            tabledata={medicines ? medicines : []}
          />
        )}
      </div>
      <AddPharmacyMedicine
        show={addmedicinemodal}
        onHide={() => setaddmedicinemodal(false)}
        {...props}
      />
      <EditPharmacyMedicine
        show={selectedMedi?.show}
        data={selectedMedicine}
        onHide={() => dispatch(SelectedMedicinde({ show: false, data: {} }))}
        {...props}
      />
      {/* <AddPharmacyMedicine
        show={selectedMedi?.show}
        onHide={() => dispatch(SelectedMedicinde({ show: false, data: {} }))}
        {...props}
      /> */}
      <UploadMedicineModal
        show={addBul}
        onHide={() => setaddBul(false)}
        {...props}
      />
      <ConfirmAlertModal
        show={deleteMedi}
        onHide={() => setDeleteMedi(false)}
        data={deleteSelectedMedi}
        {...props}
      />
    </>
  );
};

export default PharmacyMedicines;
