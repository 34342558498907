import React, {  useState ,useEffect} from "react";

import PharmacyMedicines from "./PharmacyMedicines";
import PharmacyOrders from "./PharmacyOrders";
import prescription from "../../statics/icons/prescription.svg";
import cart from "../../statics/icons/cart.svg";

import DashboardHeaderAndTile from "../../components/layouts/dashboardheaderandtile/DashboardHeaderAndTile";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Pharmacy = (props) => {
  const {t} = useTranslation("common")
  const [selectedTab, setSelectedTab] = useState(t("dashboards.alldashboardkeys.tablekeys.medicine"));
  let FromView = props?.location?.state?.key;
  const tabHandler = (item) => {
    setSelectedTab(item);
  };
  useEffect(() => {
    if (FromView === "fromOrderDetails") {
      setSelectedTab(t("dashboards.alldashboardkeys.tablekeys.orders"));
    }
  }, [FromView]);
     // {t("dashboards.alldashboardkeys.tablekeys.todayappointment")}
  return (
    <>
       <Helmet>
        <title>{t("dashboards.alldashboardkeys.maintitle")}</title>
      </Helmet>
    <div style={{ backgroundColor: "#f9f9f9" }}>
      
      <DashboardHeaderAndTile
        heading={t("dashboards.alldashboardkeys.pharmacymainheading1")}
        options={[
          t("dashboards.alldashboardkeys.tablekeys.medicine")
          ,
          t("dashboards.alldashboardkeys.tablekeys.orders")        
        ]}
        icons={[prescription, cart]}
        tabHandler={tabHandler}
        value={selectedTab}
        active={selectedTab}
      />
      <div className="container mt-5">
        {selectedTab === t("dashboards.alldashboardkeys.tablekeys.medicine") ? (
          <PharmacyMedicines {...props} />
        ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.orders") ? (
          <PharmacyOrders {...props} />
        ) : null}
      </div>
    </div>
    </>
  );
};

export default Pharmacy;
